export class TargetingDetail {
  id: number = 0;
  targeting_id: number = 0;
  status: number = 0;
  include: number = 0;
  exclude: number = 0;
  include_exclude: number = this.formatIncludeExclude();
  conditions: [
    {
      condition_type: number | string;
      fields:
        | [{ field_id: string | number; comparison: string; val: string }]
        | [];
      fields_grouped: [];
    },
  ] = [
    {
      condition_type: 1,
      fields: [],
      fields_grouped: [],
    },
  ];
  constructor(targetingDetail: TargetingDetail | any = {}) {
    Object.assign(this, targetingDetail);
  }
  formatIncludeExclude(): number {
    return this.exclude == 1 ? 2 : 1;
  }
}
