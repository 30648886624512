import { Injectable } from '@angular/core';
import { DataTablesParameters } from '../interfaces/optionen.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LeadBackend } from '../models/lead-backend';
import { HttpHandlerService } from './http-handler.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class LeadBackendService {
  endpoint: string = 'lead_backends/';

  constructor(
    private httpHandler: HttpHandlerService,
    private environmentService: EnvironmentService,
    private http: HttpClient,
  ) {}

  datatable(dataTablesParams: DataTablesParameters): Observable<any> {
    return this.httpHandler.datatable(this.endpoint, dataTablesParams).pipe(
      map((response) => {
        return {
          recordsFiltered: response.recordsFiltered,
          recordsTotal: response.recordsTotal,
          data: response.data.map((item: LeadBackend) => new LeadBackend(item)),
        };
      }),
    );
  }

  getLeadBackendListByLeadTypeId(leadTypeId: number): Observable<LeadBackend> {
    let params = new HttpParams().set(
      'filter',
      JSON.stringify({ lead_type_id: leadTypeId }),
    );

    // @ts-ignore
    return this.http
      .get<{ data: LeadBackend }>(
        `${this.environmentService.apiUrl}${this.endpoint}`,
        { params },
      )
      .pipe(
        map(
          (response) =>
            // @ts-ignore
            response?.data && response.data[0] ? response.data[0] : response, // der Leadmanager gibt data zurück, der Salesrunner nicht. Deshalb diese Logik
        ),
      );
  }

  list(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  getList(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  show(id: number): Observable<any> {
    return this.httpHandler.show(this.endpoint, id);
  }

  create(leadBackend: LeadBackend): Observable<any> {
    return this.httpHandler.create(this.endpoint, leadBackend);
  }

  update(leadBackend: LeadBackend): Observable<any> {
    return this.httpHandler.update(this.endpoint, leadBackend);
  }

  delete(id: number): Observable<any> {
    return this.httpHandler.delete(this.endpoint, id);
  }
}
