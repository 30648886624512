import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {
  SnackbarComponent,
  SnackbarData,
} from '../../components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  openSnackBar(message: string, type: string, duration: number = 3000) {
    const config = new MatSnackBarConfig();
    config.duration = duration;
    config.horizontalPosition = 'right';
    config.verticalPosition = 'top';
    config.politeness = 'assertive';
    if (type == 'error') {
      config.panelClass = ['snackbar-error'];
    } else if (type == 'warn') {
      config.panelClass = ['snackbar-warning'];
    } else if (type == 'success') {
      config.panelClass = ['snackbar-success'];
    } else {
      config.panelClass = ['snackbar-info'];
    }
    this.snackBar.open(message, 'X', config);
  }

  showAngularSnackbar(config: {
    data: SnackbarData;
    duration?: number;
    horizontalPosition?: 'start' | 'center' | 'end' | 'left' | 'right';
    verticalPosition?: 'top' | 'bottom';
  }) {
    return this.snackBar.openFromComponent(SnackbarComponent, {
      duration: config.duration || 5000,
      horizontalPosition: config.horizontalPosition || 'start',
      verticalPosition: config.verticalPosition || 'bottom',
      data: config.data,
      panelClass: 'snackbar-container',
    });
  }
}
