import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Comment } from '../../../models/comment';
import { ApplicationHelper } from '../../../helpers/application-helper';
import { Application } from '../../../enum-collection';
import { LeadService } from '../../../services/lead/lead.service';
import { Lead } from '../../../models/leads/lead';
import { FeedService } from '../../../services/feed.service';
import { CommentSummary } from '../../../models/comment-summary';

@Component({
  selector: 'lib-feed-comment-summary',
  templateUrl: './feed-comment-summary.component.html',
  styleUrls: ['./feed-comment-summary.component.scss'],
})
export class FeedCommentSummaryComponent implements OnInit, OnDestroy {
  @Input() commentSummary: CommentSummary = new CommentSummary();
  @Input() lead?: Lead = new Lead({});
  private destroy$ = new Subject<void>();

  protected readonly ApplicationHelper = ApplicationHelper;
  protected readonly Application = Application;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
