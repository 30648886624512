<ng-container *ngIf="isLoggedIn$ | async; else loginTemplate">
  <div class="app-container">
    <lib-toolbar></lib-toolbar>

    <lib-sidenav [menuItems]="menuItemsFiltered">
      <router-outlet></router-outlet>
    </lib-sidenav>
  </div>
</ng-container>

<ng-template #loginTemplate>
  <app-login (changeLoggedIn)="handleLoggedInChange($event)"></app-login>
</ng-template>
