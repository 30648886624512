import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, Subject, switchMap, take } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PanelStateService } from '../../services/panel-state.service';
import { SplitViewStateService } from '../../services/split-view-state.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-chance-list',
  templateUrl: './chance-list.component.html',
  styleUrls: ['./chance-list.component.scss'],
})
export class ChanceListComponent implements OnInit, OnDestroy {
  selectedChance$ = this.splitViewStateService.selectedChance$;
  private destroy$ = new Subject<void>();

  get initialPanelWidth(): number {
    return this.panelStateService.getCurrentWidth();
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private splitViewStateService: SplitViewStateService,
    private panelStateService: PanelStateService,
  ) {}

  ngOnInit() {
    this.splitViewStateService.partnerChances$
      .pipe(
        filter((chances) => chances.length > 0),
        take(1),
        switchMap(() => this.route.queryParams),
        takeUntil(this.destroy$),
      )
      .subscribe((params) => {
        if (params['id']) {
          const chance = this.splitViewStateService
            .getPartnerChances()
            .find((c) => c.id === Number(params['id']));

          if (chance) {
            this.splitViewStateService.setSelectedChance(chance);
            this.panelStateService.setWidth(
              this.panelStateService.getCurrentWidth(),
              true,
            );
          }
        } else {
          this.splitViewStateService.setSelectedChance(null);
        }
      });

    this.selectedChance$.pipe(takeUntil(this.destroy$)).subscribe((chance) => {
      if (chance !== null) {
        this.panelStateService.ensureDetailViewWidth();
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { id: chance.id },
          queryParamsHandling: 'merge',
        });
      } else {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {},
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  onWidthChange(width: number) {
    this.panelStateService.setWidth(width);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
