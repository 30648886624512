import { LeadField } from './leads/lead-field';
import { EmailTemplate } from './email-template';
import { LeadBackend } from './lead-backend';

export class LeadType {
  id: number = 0;
  name: string = '';
  active: number = 1;
  description: string = '';
  forwarding_type: number = 1;
  max_sales_count: number = 3;
  caller_number: string = '';
  lead_response_url: string = '';
  lead_field: LeadField[] = [];
  email_templates: EmailTemplate[] = [];
  lead_backend: LeadBackend = new LeadBackend({});

  constructor(lead_type: LeadType | {}) {
    Object.assign(this, lead_type);

    if (this.lead_backend == null) {
      Object.assign(this, {
        ...lead_type,
        lead_backend: new LeadBackend(this.lead_backend || {}),
      });
    }
  }
}
