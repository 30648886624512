import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';

export interface ErrorDetails {
  message: string;
  stack?: string;
  timestamp: string;
  userAgent: string;
  url: string;
  type: string;
  zone?: string;
  componentStack?: string;
}

interface ErrorMetadata {
  count: number;
  windowStart: number;
  lastOccurrence: number;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorLoggingService {
  private readonly API_URL = `${environment.apiUrl}log-error`;
  private readonly ERROR_STORAGE_PREFIX = 'error_tracking_';
  private readonly RATE_LIMIT_KEY = 'error_rate_limit';

  private readonly MAX_ERRORS_PER_WINDOW = 3; // Maximale gleiche Fehler pro Zeitfenster
  private readonly ERROR_WINDOW_MS = 60000; // 1 Minute für Fehler-Zeitfenster
  private readonly RATE_LIMIT_MAX = 10; // Maximale Gesamt-Fehler
  private readonly RATE_LIMIT_WINDOW_MS = 60000; // 1 Minute für globales Rate Limit

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) {
    // Regelmäßiges Cleanup von abgelaufenen Fehlern
    setInterval(() => this.cleanupExpiredErrors(), this.ERROR_WINDOW_MS);
  }

  logError(error: ErrorDetails): Observable<any> {
    // Prüfe globales Rate Limit
    if (this.isRateLimited()) {
      return throwError(() => new Error('Global rate limit exceeded'));
    }

    const errorKey = this.generateErrorKey(error);
    const storageKey = this.ERROR_STORAGE_PREFIX + errorKey;
    const errorMetadata = this.getErrorMetadata(storageKey);
    const now = Date.now();

    if (errorMetadata) {
      // Prüfe ob das aktuelle Zeitfenster abgelaufen ist
      if (now - errorMetadata.windowStart >= this.ERROR_WINDOW_MS) {
        // Zeitfenster ist abgelaufen - starte neues Fenster
        this.createErrorMetadata(storageKey);
      } else {
        // Zeitfenster läuft noch - prüfe Limit
        if (errorMetadata.count >= this.MAX_ERRORS_PER_WINDOW) {
          return throwError(
            () =>
              new Error('Similar error limit reached for current time window'),
          );
        }
        // Erhöhe Zähler im aktuellen Fenster
        this.updateErrorMetadata(storageKey, errorMetadata);
      }
    } else {
      // Erster Fehler dieser Art - erstelle neues Zeitfenster
      this.createErrorMetadata(storageKey);
    }

    // Tracking für globales Rate Limit
    this.trackRequest();

    // Sende Fehler ans Backend
    return this.http.post(this.API_URL, error);
  }

  private generateErrorKey(error: ErrorDetails): string {
    // Erstelle eindeutigen Schlüssel aus relevanten Fehlerdaten
    const errorString = `${error.type}:${error.message}:${error.zone || ''}`;
    return btoa(encodeURIComponent(errorString)).slice(0, 32);
  }

  private getErrorMetadata(key: string): ErrorMetadata | null {
    return this.storageService.getFromServiceStorage(key);
  }

  private createErrorMetadata(key: string): void {
    const now = Date.now();
    const metadata: ErrorMetadata = {
      count: 1,
      windowStart: now,
      lastOccurrence: now,
    };
    this.storageService.setInServiceStorage(key, metadata);
  }

  private updateErrorMetadata(key: string, metadata: ErrorMetadata): void {
    metadata.count += 1;
    metadata.lastOccurrence = Date.now();
    this.storageService.setInServiceStorage(key, metadata);
  }

  private isRateLimited(): boolean {
    const rateData =
      this.storageService.getFromServiceStorage(this.RATE_LIMIT_KEY) || [];
    const now = Date.now();

    // Filtere alte Requests
    const recentRequests = rateData.filter(
      (timestamp: number) => now - timestamp < this.RATE_LIMIT_WINDOW_MS,
    );

    // Speichere gefilterte Liste zurück
    if (recentRequests.length !== rateData.length) {
      this.storageService.setInServiceStorage(
        this.RATE_LIMIT_KEY,
        recentRequests,
      );
    }

    return recentRequests.length >= this.RATE_LIMIT_MAX;
  }

  private trackRequest(): void {
    const rateData =
      this.storageService.getFromServiceStorage(this.RATE_LIMIT_KEY) || [];
    const now = Date.now();

    // Filtere alte Requests und füge neuen hinzu
    const recentRequests = [
      ...rateData.filter(
        (timestamp: number) => now - timestamp < this.RATE_LIMIT_WINDOW_MS,
      ),
      now,
    ];

    this.storageService.setInServiceStorage(
      this.RATE_LIMIT_KEY,
      recentRequests,
    );
  }

  private cleanupExpiredErrors(): void {
    // Hole alle Error-Keys aus dem Storage
    const now = Date.now();
    const allKeys = Object.keys(localStorage).filter((key) =>
      key.startsWith(this.ERROR_STORAGE_PREFIX),
    );

    // Prüfe und bereinige jeden Error-Eintrag
    allKeys.forEach((key) => {
      const metadata = this.getErrorMetadata(key);
      if (metadata && now - metadata.windowStart >= this.ERROR_WINDOW_MS) {
        // Lösche abgelaufene Einträge
        this.storageService.setInServiceStorage(key, null);
      }
    });
  }
}
