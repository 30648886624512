import { Component } from '@angular/core';
import { Comment } from '../../../../../models/comment';
import { Lead } from '../../../../../models/leads/lead';

@Component({
  selector: 'lib-basic-comment-example',
  template: BasicCommentExample.HTML_CODE,
})
export class BasicCommentExample {
  static HTML_CODE = `
    <div class="example-container">
      <lib-comment
        [relatableId]="1"
        [relatableType]="'Lead'"
        [mainPost]="true"
        [lead]="lead"
        [partnerLead]="null"
        [disableCommentInput]="false"
        [displayVisibleInSalesrunnerButton]="true"
        (commentAdded)="onCommentAdded($event)"
      >
      </lib-comment>

      <div class="mt-4" *ngIf="lastComment">
        <p class="font-bold">Letzter Kommentar:</p>
        <p>{{ lastComment.comment }}</p>
        <p class="text-sm text-gray-500">
          Erstellt am: {{ lastComment.created_at | date : 'medium' }}
        </p>
      </div>
    </div>
  `;

  lastComment: Comment | null = null;
  lead = new Lead({
    id: 1,
    status: 1,
  });

  onCommentAdded(comment: Comment): void {
    this.lastComment = comment;
    console.log('Neuer Kommentar hinzugefügt:', comment);
  }
}
