import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Lead } from '../../models/leads/lead';
import { ReminderService } from '../../services/reminder.service';
import { CommentService } from '../../services/comment.service';
import { Reminder } from '../../models/reminder';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { ModalComponent } from '../modal/modal.component';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Comment } from '../../models/comment';

@Component({
  selector: 'lib-feed-header',
  templateUrl: './feed-header.component.html',
  styleUrls: ['./feed-header.component.scss'],
})
export class FeedHeaderComponent implements OnInit, OnDestroy {
  @Input() lead$: Observable<Lead> = new Observable<Lead>();
  @Output() feedUpdated = new EventEmitter<void>();

  private destroy$ = new Subject<void>();
  lead?: Lead;

  constructor(
    private dialog: MatDialog,
    private reminderService: ReminderService,
    private snackBarService: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.lead$.pipe(takeUntil(this.destroy$)).subscribe((lead) => {
      this.lead = lead;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addReminder(existingReminder?: Reminder): void {
    const inputData = this.reminderService.getReminderModalInputData(
      existingReminder,
      null,
      this.lead,
    );

    this.dialog
      .open(ModalComponent, { disableClose: true, data: inputData })
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap((modalResponse) => {
          const reminder = this.reminderService.createOrUpdateReminderObject(
            modalResponse,
            existingReminder,
            this.lead,
          );

          const operation =
            existingReminder && reminder.id
              ? this.reminderService.updateReminder(reminder.id, reminder)
              : this.reminderService.createReminder(reminder);

          return operation.pipe(
            filter((reminderRet: Reminder) => !!reminderRet?.id),
            switchMap((reminderRet: Reminder) =>
              this.reminderService.handleCommentCreation(
                reminderRet,
                modalResponse,
                null,
              ),
            ),
            map((result) => {
              if (result instanceof Comment) {
                return reminder;
              }
              return result as Reminder;
            }),
          );
        }),
        tap(
          () => {
            this.snackBarService.openSnackBar(
              'Erinnerung gespeichert',
              'success',
            );
            this.feedUpdated.emit();
          },
          () =>
            this.snackBarService.openSnackBar(
              'Fehler beim Speichern der Erinnerung',
              'error',
            ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
