import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SnackBarService } from '@lib/services/snack-bar/snack-bar.service';
import { HttpHandlerService } from '@lib/services/http-handler.service';

@Injectable({
  providedIn: 'root',
})
export class SipgateService {
  endpointInitCall: string = 'calls/init';
  endpointInitSms: string = 'calls/send_sms';

  constructor(
    private snackbarService: SnackBarService,
    private httpHandler: HttpHandlerService,
  ) {}

  callUser(callData: { leadTypeId: number; calleeNumber: string }) {
    return this.httpHandler
      .create(this.endpointInitCall, {
        lead_type_id: callData.leadTypeId,
        callee_number: callData.calleeNumber,
      })
      .pipe(
        tap(() => {
          this.snackbarService.openSnackBar(
            'Anruf erfolgreich gestartet',
            'success',
          );
        }),
        catchError((error) => {
          this.snackbarService.openSnackBar(
            'Fehler beim Aufbau eines Anrufs',
            'error',
          );
          return throwError(error);
        }),
      );
  }

  smsUser(smsData: {
    recipientNumber: string;
    message: string;
    sender: string;
  }) {
    return this.httpHandler
      .create(this.endpointInitSms, {
        receiver_phone: smsData.recipientNumber,
        sms_body: smsData.message,
        sms_device_id: smsData.sender,
      })
      .pipe(
        tap(() => {
          this.snackbarService.openSnackBar(
            'SMS erfolgreich gesendet',
            'success',
          );
        }),
        catchError((error) => {
          this.snackbarService.openSnackBar(
            'Fehler beim Versand der SMS',
            'error',
          );
          return throwError(error);
        }),
      );
  }
}
