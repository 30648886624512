import { Component, Input } from '@angular/core';
import { OptionlistOption } from '../../models/optionlist/optionlist-option';
import { PartnerLeadStatus } from '../../enum-collection';

@Component({
  selector: 'lib-partner-lead-status-badge',
  templateUrl: './partner-lead-status-badge.component.html',
})
export class PartnerLeadStatusBadgeComponent {
  @Input() status: OptionlistOption = new OptionlistOption({});

  getBadgeClass(): { [key: string]: boolean } {
    return {
      'bg-primary':
        this.status.option_id === PartnerLeadStatus.Neu ||
        this.status.option_id === PartnerLeadStatus['Warte auf Annahme'],
      'bg-warning':
        this.status.option_id === PartnerLeadStatus['Kontakt hergestellt'] ||
        this.status.option_id ===
          PartnerLeadStatus['Angebot versendet & Wiedervorlage'],
      'bg-danger':
        this.status.option_id === PartnerLeadStatus.Verloren ||
        this.status.option_id === PartnerLeadStatus['Lead abgelehnt'],
      'bg-success':
        this.status.option_id === PartnerLeadStatus['Auftrag erhalten'] ||
        this.status.option_id ===
          PartnerLeadStatus['Kundenseitige Auftragsmeldung'] ||
        this.status.option_id ===
          PartnerLeadStatus['Auftrag erhalten & Projekt beendet'],
    };
  }
}
