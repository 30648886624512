<h1 mat-dialog-title>Lead Feedback erfassen</h1>

<mat-dialog-content [formGroup]="feedbackForm">
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    formArrayName="feedbacks"
    fxLayoutGap="20px"
  >
    <div
      *ngFor="let feedback of lead?.lead_feedbacks; let i = index"
      [formGroupName]="i"
    >
      <app-card>
        <app-card-head
          [pageTitle]="getTitle(feedback.partner_lead_id)"
        ></app-card-head>
        <app-card-body>
          <div
            class="section"
            fxLayout="column"
            fxLayoutAlign="space-around start"
          >
            <mat-label>Hat sich der Anbieter bei Ihnen gemeldet?</mat-label>
            <mat-radio-group
              aria-label="Option wählen"
              formControlName="partnerContactedLead"
            >
              <mat-radio-button [value]="null"
                ><span class="radio-button-text"
                  >Keine Angabe</span
                ></mat-radio-button
              >
              <mat-radio-button class="radio-button-text" [value]="1"
                ><span class="radio-button-text">Ja</span></mat-radio-button
              >
              <mat-radio-button class="radio-button-text" [value]="2"
                ><span class="radio-button-text">Nein</span></mat-radio-button
              >
            </mat-radio-group>
          </div>

          <div
            class="section"
            fxLayout="column"
            fxLayoutAlign="space-around start"
          >
            <mat-label>Wer hat sich als erstes gemeldet?</mat-label>
            <mat-radio-group
              aria-label="Option wählen"
              formControlName="firstContactPartner"
            >
              <mat-radio-button [value]="null"
                ><span class="radio-button-text"
                  >Keine Angabe</span
                ></mat-radio-button
              >
              <mat-radio-button class="radio-button-text" [value]="1"
                ><span class="radio-button-text"
                  >Dieser Anbieter</span
                ></mat-radio-button
              >
              <mat-radio-button class="radio-button-text" [value]="2"
                ><span class="radio-button-text"
                  >Anderer Anbieter</span
                ></mat-radio-button
              >
            </mat-radio-group>
          </div>

          <div
            class="section"
            fxLayout="column"
            fxLayoutAlign="space-around start"
          >
            <mat-label
              >Für welchen Anbieter haben Sie sich entschieden?</mat-label
            >
            <mat-radio-group
              aria-label="Option wählen"
              formControlName="partnerLeadDecision"
            >
              <mat-radio-button [value]="null"
                ><span class="radio-button-text"
                  >Keine Angabe</span
                ></mat-radio-button
              >
              <mat-radio-button class="radio-button-text" [value]="1"
                ><span class="radio-button-text"
                  >Diesen Anbieter</span
                ></mat-radio-button
              >
              <mat-radio-button class="radio-button-text" [value]="2"
                ><span class="radio-button-text"
                  >Anderer Anbieter</span
                ></mat-radio-button
              >
              <mat-radio-button class="radio-button-text" [value]="3"
                ><span class="radio-button-text"
                  >Entscheidung steht noch aus</span
                ></mat-radio-button
              >
              <mat-radio-button class="radio-button-text" [value]="4"
                ><span class="radio-button-text"
                  >Projekt wurde abgesagt</span
                ></mat-radio-button
              >
            </mat-radio-group>
          </div>

          <mat-form-field class="section" fxFill>
            <mat-label
              >Wie hoch ist der Angebotswert (Netto) in Euro?</mat-label
            >
            <input matInput formControlName="offerAmount" />
            <mat-icon matSuffix>euro_symbol</mat-icon>
          </mat-form-field>

          <mat-form-field class="section" fxFill>
            <mat-label>Was war der Entscheidungsgrund?</mat-label>
            <mat-select formControlName="leadDecisionReason">
              <mat-option [value]="1">Preis</mat-option>
              <mat-option [value]="2">Verfügbarkeit</mat-option>
              <mat-option [value]="3">Technische Anforderungen</mat-option>
              <mat-option [value]="4">Geschwindigkeit</mat-option>
              <mat-option [value]="5">Entfernung</mat-option>
              <mat-option [value]="6">Beratung / Freundlichkeit</mat-option>
            </mat-select>
          </mat-form-field>
        </app-card-body>
      </app-card>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Abbrechen</button>

  <button
    (click)="save()"
    class="btn btn-success btn-success"
    [disabled]="!feedbackForm.valid"
  >
    Speichern
  </button>
</mat-dialog-actions>
