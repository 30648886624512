import { Component, Input } from '@angular/core';
import { SidenavElementInterface } from '../../interfaces/sidenav.interface';

@Component({
  selector: 'lib-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
  @Input() menuItems: SidenavElementInterface[] = [];
  isExpanded = false;

  handleMenuItemClick(menuItem: SidenavElementInterface): void {
    if (menuItem.children?.length) {
      menuItem.expanded = !menuItem.expanded;
    } else {
      this.isExpanded = false;
    }
  }
}
