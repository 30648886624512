import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

export interface ErrorDetails {
  message: string;
  stack?: string;
  timestamp: string;
  userAgent: string;
  url: string;
  type: string;
  zone?: string;
  componentStack?: string;
}

interface RateLimitConfig {
  maxRequests: number; // Maximale Anzahl Anfragen
  timeWindowMs: number; // Zeitfenster in Millisekunden
}

@Injectable({
  providedIn: 'root',
})
export class ErrorLoggingService {
  private readonly API_URL = `${environment.apiUrl}log-error`;
  private requestTimestamps: number[] = [];

  private config: RateLimitConfig = {
    maxRequests: 10, // 10 Anfragen
    timeWindowMs: 60000, // pro Minute (60.000 ms)
  };

  constructor(private http: HttpClient) {}

  logError(error: ErrorDetails): Observable<any> {
    if (this.isRateLimited()) {
      return throwError(
        () => new Error('Rate limit exceeded. Please try again later.'),
      );
    }

    this.trackRequest();
    return this.http.post(this.API_URL, error);
  }

  private isRateLimited(): boolean {
    const now = Date.now();
    // Entferne alte Timestamps außerhalb des Zeitfensters
    this.requestTimestamps = this.requestTimestamps.filter(
      (timestamp) => now - timestamp < this.config.timeWindowMs,
    );

    return this.requestTimestamps.length >= this.config.maxRequests;
  }

  private trackRequest(): void {
    this.requestTimestamps.push(Date.now());
  }
}
