<div *ngIf="!containsData" fxLayout="row" fxLayoutAlign="center center">
  <em>Mit den hinterlegten Filtern konnten keine Daten gefunden werden.</em>
</div>
<div *ngIf="containsData">
  <canvas
    baseChart
    class="chart"
    [data]="lineChartData"
    [options]="lineChartOptions"
    [type]="lineChartType"
  ></canvas>
</div>
