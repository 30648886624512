import { Component } from '@angular/core';

@Component({
  selector: 'lib-user-assignment-example',
  template: UserAssignmentExample.HTML_CODE,
})
export class UserAssignmentExample {
  static HTML_CODE = `
    <div class="example-container">
      <div class="mb-4">
        <p class="text-lg font-medium mb-2">Standard User Assignment</p>
        <p class="text-gray-600 mb-4">
          Die Komponente zeigt einen Avatar für die Benutzerzuweisung an. Bei keiner Zuweisung wird ein leerer Avatar mit gestricheltem Rand angezeigt.
        </p>

        <div class="flex gap-4">
          <div class="flex flex-col items-center">
            <lib-user-assignment
              [users]="exampleUsers"
              [assignedUser]="null"
              (assignUser)="onAssignUser($event)"
              (removeUser)="onRemoveUser()"
            ></lib-user-assignment>
            <span class="text-sm mt-2">Nicht zugewiesen</span>
          </div>
          
          <div class="flex flex-col items-center">
            <lib-user-assignment
              [users]="exampleUsers"
              [assignedUser]="exampleUsers[0]"
              (assignUser)="onAssignUser($event)"
              (removeUser)="onRemoveUser()"
            ></lib-user-assignment>
            <span class="text-sm mt-2">Max Mustermann</span>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <p class="text-lg font-medium mb-2">Integration in Listen</p>
        <p class="text-gray-600 mb-4">
          Die User-Assignment-Komponente kann in Tabellen und Listen verwendet werden.
        </p>

        <table class="min-w-full border-collapse border border-gray-300">
          <thead>
            <tr class="bg-gray-100">
              <th class="border border-gray-300 p-3 text-left">Lead</th>
              <th class="border border-gray-300 p-3 text-left">Status</th>
              <th class="border border-gray-300 p-3 text-left">Zugewiesen an</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border border-gray-300 p-3">Musterprojekt GmbH</td>
              <td class="border border-gray-300 p-3">Neu</td>
              <td class="border border-gray-300 p-3">
                <lib-user-assignment
                  [users]="exampleUsers"
                  [assignedUser]="null"
                  (assignUser)="onAssignUser($event)"
                  (removeUser)="onRemoveUser()"
                ></lib-user-assignment>
              </td>
            </tr>
            <tr>
              <td class="border border-gray-300 p-3">Beispielfirma AG</td>
              <td class="border border-gray-300 p-3">In Bearbeitung</td>
              <td class="border border-gray-300 p-3">
                <lib-user-assignment
                  [users]="exampleUsers"
                  [assignedUser]="exampleUsers[1]"
                  (assignUser)="onAssignUser($event)"
                  (removeUser)="onRemoveUser()"
                ></lib-user-assignment>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mt-4 p-4 bg-gray-100 rounded">
        <p class="text-sm text-gray-600">Letztes Ereignis:</p>
        <pre class="mt-2 text-sm">{{ lastEvent }}</pre>
      </div>
    </div>
  `;

  // Beispiel-Benutzer für die Demo
  exampleUsers = [
    { id: 1, first_name: 'Max', last_name: 'Mustermann' },
    { id: 2, first_name: 'Anna', last_name: 'Schmidt' },
    { id: 3, first_name: 'Thomas', last_name: 'Weber' },
    { id: 4, first_name: 'Julia', last_name: 'Fischer' },
  ];

  lastEvent: string = 'Noch keine Aktion durchgeführt';

  onAssignUser(user: any): void {
    this.lastEvent = `Benutzer zugewiesen: ${user.first_name} ${user.last_name}`;
    console.log('Benutzer zugewiesen:', user);
  }

  onRemoveUser(): void {
    this.lastEvent = 'Benutzerzuweisung aufgehoben';
    console.log('Benutzerzuweisung aufgehoben');
  }
}
