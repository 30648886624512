import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PartnerLead } from '@lib/models/partner-lead';

@Injectable({
  providedIn: 'root',
})
export class SplitViewStateService {
  // Lead State
  private selectedLeadId = new BehaviorSubject<number | null>(null);
  selectedLeadId$ = this.selectedLeadId.asObservable();

  private partnerLeads = new BehaviorSubject<PartnerLead[]>([]);
  partnerLeads$ = this.partnerLeads.asObservable();

  private leadUpdated = new Subject<void>();
  leadUpdated$ = this.leadUpdated.asObservable();

  // Chance State
  private selectedChance = new BehaviorSubject<PartnerLead | null>(null);
  selectedChance$ = this.selectedChance.asObservable();

  private partnerChances = new BehaviorSubject<PartnerLead[]>([]);
  partnerChances$ = this.partnerChances.asObservable();

  private reloadTableSubject = new Subject<void>();
  reloadTable$ = this.reloadTableSubject.asObservable();

  // Lead Methods
  setSelectedLead(id: number | null) {
    this.selectedLeadId.next(id);
  }

  updatePartnerLeads(leads: PartnerLead[]) {
    this.partnerLeads.next(leads);
  }

  getPartnerLeads(): PartnerLead[] {
    return this.partnerLeads.getValue();
  }

  updateSingleLead(updatedLead: PartnerLead) {
    const currentLeads = this.partnerLeads.getValue();
    const updatedLeads = currentLeads.map((lead) =>
      lead.id === updatedLead.id ? updatedLead : lead,
    );
    this.partnerLeads.next(updatedLeads);
    this.notifyLeadUpdated();
  }

  notifyLeadUpdated() {
    this.leadUpdated.next();
  }

  // Chance Methods
  setSelectedChance(chance: PartnerLead | null) {
    this.selectedChance.next(chance);
  }

  updatePartnerChances(chances: PartnerLead[]) {
    this.partnerChances.next(chances);
  }

  getPartnerChances(): PartnerLead[] {
    return this.partnerChances.getValue();
  }

  triggerTableReload() {
    this.reloadTableSubject.next();
  }
}
