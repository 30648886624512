import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentFormat',
})
export class PercentPipe implements PipeTransform {
  transform(value: any, decimals: number = 2): string {
    if (value === null || value === undefined || isNaN(value)) return '-';

    // Konvertiere den Wert in eine Prozentzahl und formatiere ihn
    let percent = Number(value);
    return percent.toFixed(decimals) + ' %';
  }
}
