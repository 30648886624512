<div *ngIf="dataLoaded" class="comment-container">
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    style="padding-bottom: 1rem"
  >
    <ng-container
      *ngIf="
        ApplicationHelper.applicationName === Application.Leadmanager &&
        displayVisibleInSalesrunnerSlideToggle
      "
    >
      <lib-slide-toggle
        [(value)]="visibleInSalesrunner"
        class="salesrunner-toggle"
      >
        Sichtbar im Salesrunner
      </lib-slide-toggle>
    </ng-container>

    <div *ngIf="relatableType === 'Lead' || relatableType === 'PartnerLead'">
      <button class="action-button" (click)="toggleFileUpload()">
        <mat-icon>attach_file</mat-icon>
        <span>{{
          displayFileUpload ? 'Dateianhang entfernen' : 'Datei anhängen'
        }}</span>
      </button>
    </div>
  </div>

  <div *ngIf="displayFileUpload" class="file-upload">
    <lib-file-upload
      (fileListEmitter)="onFileListChanged($event)"
    ></lib-file-upload>
  </div>

  <form [formGroup]="commentForm" (ngSubmit)="onSubmit()" class="comment-form">
    <div class="comment-input-wrapper">
      <textarea
        #commentTextarea
        formControlName="content"
        placeholder="Schreibe einen Kommentar..."
        rows="1"
        class="comment-input"
        (input)="autoGrow($event)"
        (mousedown)="onResizeStart()"
        (mouseup)="onResizeEnd($event)"
      ></textarea>
    </div>
    <button
      type="submit"
      [disabled]="!commentForm.valid"
      class="post-comment-button"
      aria-label="Kommentar senden"
    >
      <mat-icon>send</mat-icon>
    </button>
  </form>
</div>
