<app-card>
  <app-card-head [pageTitle]="this.pageTitle">
    <app-filter-bar [filterSettings]="filterBarSettings"></app-filter-bar>
  </app-card-head>
  <app-card-body>
    <div class="row" cdkDropListGroup>
      <div class="col-sm-3" *ngFor="let list of partnerLeadDragDrop">
        <div class="card mb-2">
          <div class="card-header bg-white" style="height: 95px">
            <h5 class="mb-2">{{ list.title }}</h5>
            <span class="text-gray-600">{{ formatCurrency(list.summe) }}</span>
          </div>

          <div class="card-body">
            <ul
              class="list-group"
              style="min-height: 60px; border: 1px solid rgb(222, 226, 230)"
              cdkDropList
              [cdkDropListData]="list.data"
              (cdkDropListDropped)="drop($event, list.status_fixed)"
            >
              <li
                class="list-group-item position-relative"
                *ngFor="let partnerLead of list.data"
                cdkDrag
              >
                <button
                  class="btn btn-sm btn-link position-absolute top-0 end-0 mt-1 me-1"
                  matTooltip="Status ändern"
                  (click)="changeStatus(partnerLead)"
                >
                  <mat-icon class="text-muted">how_to_vote</mat-icon>
                </button>

                <div *ngIf="partnerLead.address" class="space-y-2">
                  <ng-container *ngIf="partnerLead.address.company_name">
                    <b
                      class="text-primary"
                      style="cursor: pointer"
                      (click)="goToLeadDetail(partnerLead)"
                    >
                      {{ partnerLead.address.company_name }}
                    </b>
                  </ng-container>
                  <ng-container *ngIf="!partnerLead.address.company_name">
                    <b
                      class="text-primary"
                      style="cursor: pointer"
                      (click)="goToLeadDetail(partnerLead)"
                    >
                      {{ partnerLead.address.first_name }}
                      {{ partnerLead.address.last_name }}
                    </b>
                  </ng-container>

                  <div>
                    {{ partnerLead.address.postcode }}
                    {{ partnerLead.address.city }}
                  </div>

                  <ng-container
                    *ngIf="
                      (partnerLead?.reminders ?? []).length > 0;
                      else noReminder
                    "
                  >
                    <div>
                      Nächste Erinnerung:
                      {{ formatDate(partnerLead.reminders[0].reminder_date) }}
                      &nbsp;({{
                        partnerLead.reminders[0].reminder_type_option?.name
                      }})
                    </div>
                  </ng-container>

                  <ng-template #noReminder>
                    <em>Keine Erinnerung vorhanden</em>
                  </ng-template>

                  <app-partner-lead-offer-value
                    class="lead-list-input"
                    [partnerLead]="partnerLead"
                    (partnerLeadOfferValue)="
                      updatePartnerLeadOfferValue($event)
                    "
                  >
                  </app-partner-lead-offer-value>

                  <mat-form-field class="w-100">
                    <mat-label>
                      Referenznummer
                      <mat-icon
                        matTooltip="In diesem Feld können Sie z.B. die Kundennummer oder Ihre interne Angebotsnummer hinterlegen. Die Referenznummer wird im Export der Lead-Liste angezeigt und soll Ihnen die Zuordnung zu Ihrem System und Ihren Kundendaten erleichtern."
                      >
                        info
                      </mat-icon>
                    </mat-label>
                    <input
                      (change)="offerNumberChanged(partnerLead)"
                      matInput
                      class="w-100"
                      [(ngModel)]="partnerLead.offer_number"
                      placeholder="Referenznummer eingeben"
                    />
                  </mat-form-field>
                </div>

                <div *cdkDragPreview class="drag-preview-card">
                  <div class="preview-title">
                    {{
                      partnerLead.address.company_name ||
                        partnerLead.address.first_name +
                          ' ' +
                          partnerLead.address.last_name
                    }}
                  </div>
                  <div class="preview-subtitle">
                    {{ partnerLead.address.postcode }}
                    {{ partnerLead.address.city }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </app-card-body>
</app-card>
