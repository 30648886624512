import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private _show = new BehaviorSubject<boolean>(false);
  private _data: any;
  private _confirmCallback: (() => void) | undefined;

  public show$: Observable<boolean> = this._show.asObservable();

  public show(data: any, confirmCallback: () => void) {
    this._data = data;
    this._confirmCallback = confirmCallback;
    this._show.next(true);
  }

  public hide() {
    this._show.next(false);
  }

  public confirm() {
    if (this._confirmCallback) {
      this._confirmCallback();
    }
    this.hide();
  }

  public getData(): any {
    return this._data;
  }
}
