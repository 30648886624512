import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Call } from '../../../models/call';

@Component({
  selector: 'lib-feed-call',
  templateUrl: './feed-call.component.html',
  styleUrls: ['./feed-call.component.scss'],
})
export class FeedCallComponent implements OnInit, OnDestroy {
  @Input() call: Call = new Call();

  private destroy$ = new Subject<void>();
  protected readonly Math = Math;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
