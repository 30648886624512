<app-card>
  <app-card-head pageTitle="Targeting"></app-card-head>

  <app-card-body>
    <app-card>
      <app-card-head pageTitle="Geotargeting"></app-card-head>
      <app-card-body>
        <div class="row">
          <div class="col-8 pb-3">
            <lib-table
              [tableId]="'tablePartnerGeoTargeting'"
              [restriction]="restriction"
              [initialPageSize]="50"
              [tableMaxHeightPx]="490"
              [endpoint]="'partner_inspector_geo_targeting/'"
              (displayedDataChanged)="displayedTargetingsChangedEvent($event)"
              (hoveredEnterElementChanged)="
                hoveredEnterTargetingsChangedEvent($event)
              "
              (hoveredLeaveElementChanged)="
                hoveredLeaveTargetingsChangedEvent($event)
              "
            >
              <lib-table-column
                [isSortable]="false"
                [designPointer]="true"
                [columnName]="'lead_type.name'"
                [title]="'Leadart'"
              ></lib-table-column>

              <!--              <lib-table-column-->
              <!--                [isSortable]="false"-->
              <!--                [designPointer]="true"-->
              <!--                [columnName]="'targeting_budgets'"-->
              <!--                [title]="'Budget'"-->
              <!--                [customTemplate]="targetingBudgetTemplate"-->
              <!--              >-->
              <!--                <ng-template #targetingBudgetTemplate let-targeting>-->
              <!--                  <ng-container-->
              <!--                    *ngIf="-->
              <!--                      !targeting.targeting_budgets ||-->
              <!--                      targeting.targeting_budgets.length === 0-->
              <!--                    "-->
              <!--                  >-->
              <!--                    <em>Kein Budget hinterlegt.</em>-->
              <!--                  </ng-container>-->

              <!--                  <div-->
              <!--                    fxLayout="column"-->
              <!--                    fxLayoutAlign="space-around start"-->
              <!--                    fxLayoutGap="10px"-->
              <!--                    *ngFor="-->
              <!--                      let targeting_budget of targeting.targeting_budgets;-->
              <!--                      let i = index-->
              <!--                    "-->
              <!--                  >-->
              <!--                    <ng-container-->
              <!--                      *ngIf="-->
              <!--                        targeting.targeting_budgets &&-->
              <!--                        i === targeting.targeting_budgets.length - 1-->
              <!--                      "-->
              <!--                    >-->
              <!--                      &lt;!&ndash; Es soll nur das letzte Budget angezeigt werden&ndash;&gt;-->
              <!--                      <div>-->
              <!--                        <span-->
              <!--                          class="badge bg-success success-badge"-->
              <!--                          *ngIf="targeting_budget.status"-->
              <!--                        >-->
              <!--                          Aktiv-->
              <!--                        </span>-->
              <!--                        <span-->
              <!--                          class="badge bg-danger"-->
              <!--                          *ngIf="!targeting_budget.status"-->
              <!--                        >-->
              <!--                          Inaktiv-->
              <!--                        </span>-->
              <!--                      </div>-->
              <!--                      <div>-->
              <!--                        <span *ngIf="targeting_budget.daily_budget > 0">-->
              <!--                          {{ targeting_budget.daily_budget | priceFormat }}-->
              <!--                          Tagesbudget-->
              <!--                        </span>-->
              <!--                        <span *ngIf="targeting_budget.weekly_budget > 0">-->
              <!--                          {{ targeting_budget.weekly_budget | priceFormat }}-->
              <!--                          Wochenbudget-->
              <!--                        </span>-->
              <!--                        <span *ngIf="targeting_budget.monthly_budget > 0">-->
              <!--                          {{ targeting_budget.monthly_budget | priceFormat }}-->
              <!--                          Monatsbudget-->
              <!--                        </span>-->
              <!--                      </div>-->
              <!--                    </ng-container>-->
              <!--                  </div>-->
              <!--                </ng-template>-->
              <!--              </lib-table-column>-->

              <lib-table-column
                [isSortable]="false"
                [designPointer]="true"
                [columnName]="'targeting_details'"
                [title]="'hinterlegte Gutachten Fachgebiete'"
                [customTemplate]="targetingTypeTemplate"
              >
                <ng-template #targetingTypeTemplate let-targeting>
                  <div
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    fxLayoutGap="10px"
                    *ngFor="let targeting_detail of targeting.targeting_details"
                    style="margin-bottom: 10px"
                  >
                    <div
                      style="max-width: 500px"
                      fxLayout="column"
                      fxLayoutAlign="space-around start"
                      fxLayoutGap="5px"
                    >
                      <div *ngIf="targeting_detail.include === 1">
                        Wenn vom Fachgebiet:
                      </div>
                      <div *ngIf="targeting_detail.exclude === 1">
                        Wenn <b>NICHT</b> vom Fachgebiet:
                      </div>

                      <div
                        style="margin-left: 20px"
                        *ngFor="
                          let condition of targeting_detail.conditions;
                          let c = index
                        "
                        fxLayout="column"
                        fxLayoutAlign="space-around start"
                        fxLayoutGap="5px"
                      >
                        <ng-container
                          *ngFor="let field of condition.fields; let i = index"
                        >
                          <div *ngIf="targeting_detail.include === 1">
                            <span class="badge bg-success success-badge">
                              {{ getInspectorTypeNameByField(field) }}
                            </span>

                            <span *ngIf="i < condition.fields.length - 1">
                              oder
                            </span>
                          </div>

                          <div *ngIf="targeting_detail.exclude === 1">
                            <span class="badge bg-danger">
                              {{ getInspectorTypeNameByField(field) }}
                            </span>

                            <span *ngIf="i < condition.fields.length - 1">
                              oder
                            </span>
                          </div>
                        </ng-container>
                      </div>
                    </div>

                    <!--                    <div>-->
                    <!--                      <button-->
                    <!--                        *ngIf="-->
                    <!--                          targeting.unselected_inspector_types &&-->
                    <!--                          targeting.unselected_inspector_types.length > 0 &&-->
                    <!--                          targeting_detail.include === 1-->
                    <!--                        "-->
                    <!--                        class="btn btn-success btn-success"-->
                    <!--                        fxLayout="row"-->
                    <!--                        fxLayoutAlign="space-between center"-->
                    <!--                        fxLayoutGap="10px"-->
                    <!--                        (click)="-->
                    <!--                          openEditTargetingDetailModal(-->
                    <!--                            targeting,-->
                    <!--                            targeting_detail-->
                    <!--                          )-->
                    <!--                        "-->
                    <!--                      >-->
                    <!--                        <mat-icon>trending_up</mat-icon>-->
                    <!--                        <div>Potenzialsteigerung verfügbar</div>-->
                    <!--                      </button>-->

                    <!--                      <button-->
                    <!--                        *ngIf="-->
                    <!--                          !targeting.unselected_inspector_types ||-->
                    <!--                          targeting.unselected_inspector_types.length === 0 ||-->
                    <!--                          targeting_detail.exclude === 1-->
                    <!--                        "-->
                    <!--                        class="btn btn-outline-info btn-outline-info"-->
                    <!--                        fxLayout="row"-->
                    <!--                        fxLayoutAlign="space-between center"-->
                    <!--                        fxLayoutGap="10px"-->
                    <!--                        (click)="-->
                    <!--                          openEditTargetingDetailModal(-->
                    <!--                            targeting,-->
                    <!--                            targeting_detail-->
                    <!--                          )-->
                    <!--                        "-->
                    <!--                      >-->
                    <!--                        <mat-icon>edit</mat-icon>-->
                    <!--                        <div>Gutachten bearbeiten</div>-->
                    <!--                      </button>-->
                    <!--                    </div>-->
                  </div>
                </ng-template>
              </lib-table-column>

              <lib-table-column
                [designPointer]="true"
                [isSortable]="false"
                [defaultSort]="'desc'"
                [columnName]="'email_receivers'"
                [title]="'Empfänger'"
              ></lib-table-column>

              <ng-container *ngIf="partnerUserIsAdmin">
                <lib-table-column
                  [isSortable]="false"
                  [designPointer]="true"
                  [columnName]="'targeting_users'"
                  [title]="'Zugriffsberechtigung'"
                  [customTemplate]="targetingUsersTemplate"
                >
                  <ng-template #targetingUsersTemplate let-targeting>
                    <div *ngFor="let user of targeting.targeting_users">
                      {{ user.partner_user?.last_name }}
                    </div>
                  </ng-template>
                </lib-table-column>
              </ng-container>
            </lib-table>

            <div class="row call-hint">
              <em
                >Sollten Sie Änderungswünsche an Ihrem Geotargeting haben, dann
                rufen Sie uns bitte unter 0221/96880420 an.</em
              >
            </div>
          </div>
          <div class="col-4 pb-3">
            <div
              class="col-4"
              id="partner-geotargeting-map"
              style="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5)"
            ></div>
          </div>
        </div>
      </app-card-body>
    </app-card>
  </app-card-body>
</app-card>
