import { Injectable } from '@angular/core';
import { HttpHandlerService } from '@lib/services/http-handler.service';
import { map, Observable } from 'rxjs';
import { PartnerLead } from '../../models/partner-lead';

@Injectable({
  providedIn: 'root',
})
export class MapDataService {
  endpointLead = 'leads_for_map';
  endpointSale = 'sales_for_map';

  constructor(private httpHandler: HttpHandlerService) {}

  leadsForMap(httpParams: any = {}): Observable<PartnerLead[]> {
    return this.httpHandler
      .list(this.endpointLead, httpParams)
      .pipe(map((response) => response.data));
  }

  salesForMap(httpParams: any = {}): Observable<PartnerLead[]> {
    return this.httpHandler
      .list(this.endpointSale, httpParams)
      .pipe(map((response) => response.data));
  }
}
