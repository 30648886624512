import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { Observable, throwError } from 'rxjs';
import { SemanticSearchElement } from '../models/semantic-search-element';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class SemanticSearchService {
  endpoint: string = 'semantic_search/';

  constructor(
    private environmentService: EnvironmentService,
    private storageService: StorageService,
    private http: HttpClient,
  ) {}

  searchByString(
    searchString: string,
    searchIndex: string,
    searchOptinlistKeyword: string,
  ): Observable<SemanticSearchElement[]> {
    const params = new HttpParams()
      .set('search_index', searchIndex)
      .set('search_string', searchString)
      .set('search_optionlist_keyword', searchOptinlistKeyword);

    return this.http
      .get<SemanticSearchElement[]>(
        `${this.environmentService.apiUrl}${this.endpoint}`,
        {
          params,
        },
      )
      .pipe(
        catchError((error) => {
          console.error('Search API error:', error);
          return throwError(() => new Error('Fehler bei der Suchanfrage'));
        }),
      );
  }
}
