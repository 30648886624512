<div
  [ngClass]="{
    'user-select': assignedUser,
    'not-assigned': !assignedUser
  }"
  [ngStyle]="assignedUser ? getUserAvatarStyle(assignedUser) : {}"
  (click)="toggleDropdown($event, userDropdown)"
>
  <div class="user-select-content">
    <!-- Zeigt Initialen für zugewiesenen User an -->
    <span *ngIf="assignedUser" class="user-initials">
      {{ getUserInitials(assignedUser) }}
    </span>
    <!-- Zeigt User-Icon an, wenn kein User zugewiesen ist -->
    <span *ngIf="!assignedUser">
      <span class="user-icon">
        <i class="fa fa-user"></i>
      </span>
    </span>
  </div>

  <!-- Dropdown-Menü für User-Auswahl -->
  <div class="user-dropdown" #userDropdown>
    <!-- Option zum Entfernen des zugewiesenen Users -->
    <div
      *ngIf="assignedUser"
      class="dropdown-item remove-user not-assigned"
      (click)="onRemoveUser()"
    >
      <span class="user-icon">
        <i class="fa fa-user"></i>
      </span>
      <span>Zuweisung aufheben</span>
    </div>

    <!-- Trennlinie zwischen Entfernen-Option und Userliste -->
    <div
      class="dropdown-divider"
      *ngIf="sortedUsers?.length && assignedUser"
    ></div>

    <!-- Liste aller verfügbaren User -->
    <div
      *ngFor="let user of sortedUsers"
      class="dropdown-item"
      (click)="onAssignUser(user)"
    >
      <div class="user-select" [ngStyle]="getUserAvatarStyle(user)">
        <span class="user-initials">
          {{ getUserInitials(user) }}
        </span>
      </div>

      <span>{{ getUserName(user) }}</span>
    </div>
  </div>
</div>
