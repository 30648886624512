export enum SelectList {
  None = 'None',
  LeadType = 'LeadType',
  Partner = 'Partner',
  LeadStatus = 'LeadStatus',
  PartnerStatus = 'PartnerStatus',
  PartnerLeadStatus = 'PartnerLeadStatus',
  PartnerLeadCancelReason = 'PartnerLeadCancelReason',
  PartnerLeadDeclineReason = 'PartnerLeadDeclineReason', // Neu hinzugefügt
  TargetingStatus = 'TargetingStatus',
  LeadDetail = 'LeadDetail',
  LeadResponse = 'LeadResponse',
  PartnerResponse = 'PartnerResponse',
  PartnerBillStatus = 'PartnerBillStatus',
  Domain = 'Domain',
  BillDirection = 'BillDirection',
  BillStatus = 'BillStatus',
  AccountingAccount = 'AccountingAccount',
  AdminUser = 'AdminUser',
  ReminderStatus = 'ReminderStatus',
  PartnerUser = 'PartnerUser',
  TargetingUser = 'TargetingUser',
  PartnerLeadDetail = 'PartnerLeadDetail',
  PartnerAcquisitionStatus = 'PartnerAcquisitionStatus',
  PartnerCancelReason = 'PartnerCancelReason',
  PartnerUserSalesrunner = 'PartnerUserSalesrunner',
  LeadCalledStatus = 'LeadCalledStatus',
  AccountingStatus = 'AccountingStatus',
  AccountingType = 'AccountingType',
  EmailConfigurationConditionProperty = 'EmailConfigurationConditionProperty',
  EmailConfigurationConditionOperator = 'EmailConfigurationConditionOperator',
  EmailConfigurationStatusCode = 'EmailConfigurationStatusCode',
  EmailConfigurationServer = 'EmailConfigurationServer',
  EmailConfigurationResentStatus = 'EmailConfigurationResentStatus',
  EmailConfigurationSource = 'EmailConfigurationSource',
  EmailType = 'EmailType',
  EmailTemplate = 'EmailTemplate',
  WpFollowUp = 'WpFollowUp',
  DateRange = 'DateRange',
}
