import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
})
export class DeleteModalComponent {
  constructor(private modalService: ModalService) {}
  public data: any;
  public show$: Observable<boolean> = this.modalService.show$;
  confirm() {
    // Hier Logik zum Löschen einfügen
    this.modalService.confirm();
  }
  cancel() {
    this.modalService.hide();
  }
  ngOnInit() {
    this.show$.subscribe((isVisible) => {
      if (isVisible) {
        this.data = this.modalService.getData();
      }
    });
  }
}
