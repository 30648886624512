import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorLoggingService } from './services/error-logging.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private errorLoggingService: ErrorLoggingService) {}

  handleError(error: Error) {
    // Client-seitige Details extrahieren
    const errorDetails = {
      message: error.message,
      stack: error.stack,
      timestamp: new Date().toISOString(),
      userAgent: navigator.userAgent,
      url: window.location.href,
      type: error.name || 'Error',
      // Zusätzliche Kontext-Informationen
      zone: (error as any)?.ngDebugContext?.view?.component?.constructor?.name,
      componentStack: this.getComponentStack(error),
    };

    // Fehler ans Backend senden
    this.errorLoggingService.logError(errorDetails).subscribe(
      () => console.log('Error logged successfully'),
      (err) => console.error('Failed to log error:', err),
    );
  }

  private getComponentStack(error: any): string {
    if (error.ngDebugContext) {
      let componentStack = '';
      let currentContext = error.ngDebugContext;
      while (currentContext) {
        if (currentContext.component) {
          componentStack += `\n${currentContext.component.constructor.name}`;
        }
        currentContext = currentContext.parent;
      }
      return componentStack;
    }
    return '';
  }
}
