import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CallData, IncomingCall, IncomingCallUser } from '../../models/call';
import { SseService } from '../../services/sse.service';
import { Lead } from '../../models/leads/lead';
import { UrlHelper } from '../../helpers/url-helper';

@Component({
  selector: 'lib-phone-user-status',
  templateUrl: './phone-user-status.component.html',
  styleUrls: ['./phone-user-status.component.scss'],
})
export class PhoneUserStatusComponent implements OnInit, OnDestroy {
  users: IncomingCallUser[] = [];
  incomingCalls: IncomingCall[] = [];
  private callStatusSubscription: Subscription = new Subscription();
  private subscriptionCallData: Subscription = new Subscription();

  constructor(private sseService: SseService, private cdr: ChangeDetectorRef) {}

  get hasCallsWithLeads(): boolean {
    return (
      this.incomingCalls &&
      Array.isArray(this.incomingCalls) &&
      this.incomingCalls.some((call) => call.lead)
    );
  }

  ngOnInit() {
    this.callStatusSubscription = this.sseService
      .getCallStatus()
      .subscribe((callData: CallData) => {
        this.sseService.callDataSubject.next(callData);
      });
    this.getCallData();
    this.setCallDurationInterval();
    this.subscribeToUserStatusUpdates();
  }

  ngOnDestroy() {
    this.callStatusSubscription.unsubscribe();
    this.subscriptionCallData.unsubscribe();
  }

  subscribeToUserStatusUpdates() {
    this.subscriptionCallData.add(
      this.sseService.updateUserStatusAndData().subscribe((updatedUsers) => {
        this.updateUsersStatus(updatedUsers);
        this.cdr.detectChanges();
      }),
    );
  }

  updateUsersStatus(updatedUsers: IncomingCallUser[]) {
    updatedUsers.forEach((updatedUser) => {
      const index = this.users.findIndex(
        (user) => user.user_id === updatedUser.user_id,
      );
      if (index !== -1) {
        const currentUser = this.users[index];

        if (
          updatedUser.last_call_event_at &&
          updatedUser.status === 'available'
        ) {
          // Überprüfe, ob last_call vorhanden und nicht null ist
          const lastCallEventAt = updatedUser.last_call_event_at
            ? new Date(updatedUser.last_call_event_at)
            : null;
          const currentTime = new Date();

          this.users[index] = {
            ...currentUser,
            ...updatedUser,
            last_call_since: lastCallEventAt
              ? this.calculateTimeSince(lastCallEventAt, currentTime)
              : currentUser.last_call_since,
          };
        }

        if (
          updatedUser &&
          (updatedUser.status === 'active_call' ||
            updatedUser.status === 'editing' ||
            updatedUser.status === 'ringing') &&
          updatedUser.event_at
        ) {
          const currentTime = new Date();

          this.users[index] = {
            ...currentUser,
            ...updatedUser,
            duration_format: updatedUser.event_at
              ? this.calculateDuration(updatedUser.event_at, currentTime)
              : currentUser.duration_format,
          };
        }
      } else {
        this.users.push(updatedUser);
      }
    });
    this.cdr.markForCheck();
  }

  getCallData() {
    this.subscriptionCallData = this.sseService.callData$
      .pipe(
        tap((callData: CallData) => {
          if (callData?.users) {
            this.getUserChipColor(callData?.users[0]);
          }
        }),
      )
      .subscribe((callData: CallData) => {
        this.incomingCalls = callData?.incomming;
        if (callData && Array.isArray(callData.users)) {
          this.users = callData.users.map((newUser) => {
            const existingUser = this.users.find(
              (user) => user.name === newUser.name,
            );
            if (existingUser) {
              return {
                ...newUser,
                // Aktualisiere duration_format nur bei Statusänderung
                duration_format:
                  existingUser.status === newUser.status
                    ? existingUser.duration_format
                    : newUser.duration_format,
              };
            }
            return newUser;
          });
        }
      });
  }

  setCallDurationInterval() {
    interval(1000).subscribe(() => {
      const now = new Date();
      this.users = this.users.map((user) => {
        if (
          user.last_call &&
          user.last_call_event_at &&
          user.status === 'available'
        ) {
          user.last_call_since = this.calculateTimeSince(
            new Date(user.last_call_event_at),
            now,
          );
        }
        if (
          user &&
          (user.status === 'active_call' ||
            user.status === 'editing' ||
            user.status === 'ringing') &&
          user.event_at
        ) {
          user.duration_format = this.calculateDuration(user.event_at, now);
        }
        return user;
      });
      this.cdr.markForCheck();
    });
  }

  calculateTimeSince(eventAt: Date, now: Date): string {
    const diff = now.getTime() - eventAt.getTime();
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    // Hier kannst du weiter verfeinern, z.B. Wochen, Monate etc.

    if (days > 0) {
      return `${days} T.`;
    } else if (hours > 0) {
      return `${hours} Std.`;
    } else if (minutes > 0) {
      return `${minutes} Min.`;
    } else {
      return `${seconds} Sek.`;
    }
  }

  trackByUserId(index: number, user: any): number {
    return user.userId;
  }

  calculateDuration(eventAt: string, now: Date): string {
    const eventDate = new Date(eventAt);
    const diffInSeconds = Math.floor(
      (now.getTime() - eventDate.getTime()) / 1000,
    );
    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;
    return [hours, minutes, seconds]
      .map((val) => val.toString().padStart(2, '0'))
      .join(':');
  }

  getUserChipColor(user: IncomingCallUser): string {
    if (user?.presence !== 'online') {
      return 'color-offline';
    }

    switch (user?.status) {
      case 'available':
        return 'color-available';
      case 'editing':
        return 'color-editing';
      case 'active_call':
      case 'ringing':
        return 'color-active';
      default:
        return 'color-offline';
    }
  }

  generateHoverDetails(lead: Lead): string {
    let hoverDetails = '';

    if (lead) {
      if (lead.campaign_name) {
        hoverDetails += `Kampagne: ${lead.campaign_name}\n`;
      }
      if (lead.address) {
        let companyName = lead.address.company_name
          ? lead.address.company_name + ' - '
          : '';
        let firstName = lead.address.first_name ? lead.address.first_name : '';
        let lastName = lead.address.last_name ? lead.address.last_name : '';
        hoverDetails += `Name: ${companyName}${firstName} ${lastName}\n`;

        if (lead.address.address) {
          hoverDetails += `Adresse: ${lead.address.address}\n`;
        }
      }
    } else {
      hoverDetails += 'Kein Lead verknüpft\n';
    }

    return hoverDetails;
  }

  navigateToLead(lead: Lead) {
    if (lead && lead.id) {
      const url = UrlHelper.getUrlWithBasePath('/leads/edit/' + lead.id);
      window.open(url, '_blank');
    }
  }
}
