import { Component } from '@angular/core';

@Component({
  selector: 'lib-basic-tooltip-example',
  template: BasicTooltipExample.HTML_CODE,
})
export class BasicTooltipExample {
  static HTML_CODE = `
    <div class="example-container">
      <app-tooltip text="Dies ist ein einfacher Hilfetext">
        <button mat-button>Hover für Info</button>
      </app-tooltip>
    </div>
  `;
}
