import { Component } from '@angular/core';
import { FileUpload } from '../../../../../models/fileUpload';
import { Partner } from '../../../../../models/partner';

@Component({
  selector: 'lib-disabled-file-upload-example',
  template: DisabledFileUploadExample.HTML_CODE,
})
export class DisabledFileUploadExample {
  static HTML_CODE = `
    <div class="example-container">
      <lib-file-upload [disabled]="true" [existingFiles]="existingFiles">
      </lib-file-upload>
    </div>
  `;

  existingFiles: FileUpload[] = [
    {
      id: 1,
      lead_id: 0,
      partner_id: 0,
      partner_bill_id: 0,
      partner_lead_id: 0,
      size: 1024,
      document_token: 'token123',
      file_hash: 'hash123',
      original_file_name: 'beispiel.pdf',
      mime_type: 'application/pdf',
      deleted_at: '',
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      file_suffix: 'pdf',
      document_type: 'document',
      file_access_link: '/files/beispiel.pdf',
      related_documents: [],
      partner: new Partner({}),
      visible_in_salesrunner: false,
    },
  ];
}
