import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

export interface SnackbarData {
  title: string;
  content: string[];
  actionButton?: {
    label: string;
    callback: () => void;
  };
  gradientColor?: {
    color: string;
    opacity: number;
  };
}

@Component({
  selector: 'app-generic-snackbar',
  template: `
    <div
      class="snackbar-content"
      [ngStyle]="{ background: getGradientColor() }"
    >
      <div class="close-button-container">
        <button mat-icon-button class="close-button" (click)="dismiss()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div class="main-container">
        <div class="content">
          <div class="title">{{ data.title }}</div>
          <ng-container *ngFor="let line of data.content">
            {{ line }}<br />
          </ng-container>
        </div>

        <div class="action-container" *ngIf="data.actionButton">
          <button mat-button class="white-button" (click)="action()">
            {{ data.actionButton.label }}
          </button>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .snackbar-content {
        color: white;
        padding: 12px;
        position: relative;
      }

      .close-button-container {
        position: absolute;
        top: 0;
        right: 0;
      }

      .main-container {
        display: flex;
        justify-content: space-between;
        min-height: 60px;
      }

      .content {
        flex-grow: 1;
        margin-right: 24px;
      }

      .title {
        font-weight: bold;
        font-size: 110%;
        margin-bottom: 4px;
      }

      .action-container {
        display: flex;
        align-items: flex-end;
      }

      .white-button {
        color: white !important;
      }

      .close-button {
        color: white !important;
        min-width: 32px;
        height: 32px;
        padding: 0;
      }
    `,
  ],
})
export class SnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData,
    private snackBarRef: MatSnackBarRef<SnackbarComponent>,
  ) {}

  getGradientColor() {
    if (this.data.gradientColor) {
      return `rgba(${this.data.gradientColor.color}, ${this.data.gradientColor.opacity})`;
    }
    return 'rgba(60, 179, 113, 0.8)';
  }

  action() {
    if (this.data.actionButton?.callback) {
      this.data.actionButton.callback();
    }
    this.snackBarRef.dismissWithAction();
  }

  dismiss() {
    this.snackBarRef.dismiss();
  }
}
