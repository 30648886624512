import { OptionlistOption } from './optionlist-option';
import { LeadType } from '../lead-type';

export class Optionlist {
  id: number = 0;
  name: string = '';
  keyword: string = '';
  active: number = 1;
  frontend: number = 1;
  description: string = '';
  options: OptionlistOption[] = [];
  lead_types: LeadType[] = [];

  constructor(option_list: Optionlist | {}) {
    Object.assign(this, option_list);
  }
}
