import { Component, Input, OnInit } from '@angular/core';
import { CommentWebsite } from '../../../models/comment-website';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FeedService } from '../../../services/feed.service';
import { Lead } from '../../../models/leads/lead';

type DiffType = 'added' | 'removed' | 'unchanged';

interface DiffPart {
  text: string;
  type: DiffType;
}

@Component({
  selector: 'lib-feed-comment-website',
  templateUrl: './feed-comment-website.component.html',
  styleUrls: ['./feed-comment-website.component.scss'],
})
export class FeedCommentWebsiteComponent implements OnInit {
  @Input() commentWebsite: CommentWebsite = new CommentWebsite();
  @Input() lead?: Lead;

  showDiff = 1;
  diffParts: DiffPart[] = [];

  ngOnInit(): void {
    if (
      !this.commentWebsite.lead_comment ||
      this.commentWebsite.lead_comment === ''
    ) {
      this.showDiff = 0;
    }
    this.calculateDiff();
  }

  constructor(
    private snackBar: MatSnackBar,
    private feedService: FeedService,
  ) {}

  private splitText(text: string): string[] {
    if (!text) {
      return [];
    }
    return text.split(/(\s+)/).filter((s) => s.trim().length > 0);
  }

  private findLongestCommonSubsequence(
    text1: string[],
    text2: string[],
  ): number[][] {
    const dp: number[][] = Array.from({ length: text1.length + 1 }, () =>
      Array(text2.length + 1).fill(0),
    );

    for (let i = 1; i <= text1.length; i++) {
      for (let j = 1; j <= text2.length; j++) {
        dp[i][j] =
          text1[i - 1] === text2[j - 1]
            ? dp[i - 1][j - 1] + 1
            : Math.max(dp[i - 1][j], dp[i][j - 1]);
      }
    }
    return dp;
  }

  private calculateDiff(): void {
    const original = this.splitText(this.commentWebsite.original_ai_comment);
    const leadText = this.splitText(this.commentWebsite.lead_comment);
    const dp = this.findLongestCommonSubsequence(original, leadText);

    const temp: DiffPart[] = [];
    let i = original.length;
    let j = leadText.length;

    while (i > 0 || j > 0) {
      if (i > 0 && j > 0 && original[i - 1] === leadText[j - 1]) {
        temp.unshift({ text: `${original[i - 1]} `, type: 'unchanged' });
        i--;
        j--;
      } else if (j > 0 && (i === 0 || dp[i][j - 1] >= dp[i - 1][j])) {
        temp.unshift({ text: `${leadText[j - 1]} `, type: 'added' });
        j--;
      } else if (i > 0) {
        temp.unshift({ text: `${original[i - 1]} `, type: 'removed' });
        i--;
      }
    }

    this.diffParts = temp;
  }

  async copyToClipboard(text: string) {
    try {
      await navigator.clipboard.writeText(text);
      this.snackBar.open('In Zwischenablage kopiert!', '', {
        duration: 2000,
      });
    } catch (err) {
      console.error('Kopieren fehlgeschlagen:', err);
    }
  }

  getFeedSalesrunnerVisibilityHint(): string {
    return this.feedService.getFeedSalesrunnerVisibilityHint(
      this.commentWebsite.visible_in_salesrunner,
      undefined,
      this.lead,
    );
  }
}
