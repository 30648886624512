import { Component } from '@angular/core';

@Component({
  selector: 'lib-theme-colors-example',
  template: `
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <!-- Primary Colors -->
      <div class="color-card">
        <h3 class="text-lg font-semibold mb-4">Primary</h3>
        <div class="space-y-2">
          <div class="color-sample bg-primary-lighter p-4 rounded">
            <span class="font-mono text-sm">primary-lighter</span>
          </div>
          <div class="color-sample bg-primary p-4 rounded text-white">
            <span class="font-mono text-sm">primary</span>
          </div>
          <div class="color-sample bg-primary-darker p-4 rounded text-white">
            <span class="font-mono text-sm">primary-darker</span>
          </div>
        </div>
      </div>

      <!-- Accent Colors -->
      <div class="color-card">
        <h3 class="text-lg font-semibold mb-4">Accent</h3>
        <div class="space-y-2">
          <div class="color-sample bg-accent-lighter p-4 rounded">
            <span class="font-mono text-sm">accent-lighter</span>
          </div>
          <div class="color-sample bg-accent p-4 rounded text-white">
            <span class="font-mono text-sm">accent</span>
          </div>
          <div class="color-sample bg-accent-darker p-4 rounded text-white">
            <span class="font-mono text-sm">accent-darker</span>
          </div>
        </div>
      </div>

      <!-- Warning Colors -->
      <div class="color-card">
        <h3 class="text-lg font-semibold mb-4">Warning</h3>
        <div class="space-y-2">
          <div class="color-sample bg-warn p-4 rounded text-white">
            <span class="font-mono text-sm">warn</span>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .color-card {
        @apply p-4 bg-white rounded-lg shadow;
      }
      .color-sample {
        @apply flex items-center justify-between;
      }
    `,
  ],
})
export class ThemeColorsExample {}
