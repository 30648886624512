import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { first, Subject } from 'rxjs';
import { UserToken } from '../../models/user-token';
import { ApplicationHelper } from '../../helpers/application-helper';
import { AuthService } from '../../services/auth.service';
import { PartnersService } from '../../services/partners.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  form: any = {
    email: null,
    password: null,
  };
  pageTitle = ApplicationHelper.applicationName;

  forgotPasswordMode = false;
  resetPasswordMode = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  @Input() userTokenValue: UserToken = new UserToken({});
  @Output() changeLoggedIn = new EventEmitter<boolean>();
  @Output() changeSalesRunnerLoggedIn = new EventEmitter<string>();

  constructor(
    private authService: AuthService,
    private partnersService: PartnersService,
    private router: Router,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    if (window.location.href.includes('reset_password')) {
      this.resetPasswordMode = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit(): void {
    const { email, password } = this.form;

    if (this.forgotPasswordMode && email) {
      this.partnersService
        .changePassword({ email: email })
        .subscribe((data: any) => {
          if (data) {
            this.forgotPasswordMode = false;
            this.snackBarService.openSnackBar(
              'Email zum zurücksetzen des Passworts wurde gesendet',
              'success',
            );
          }
        });
    }

    if (!this.forgotPasswordMode) {
      if (this.form.email == null || this.form.password == null) {
        this.snackBarService.openSnackBar(
          'Ihre E-Mail-Adresse oder Ihr Passwort darf nicht leer sein.',
          'error',
        );
        return;
      }

      if (ApplicationHelper.applicationName == 'Salesrunner') {
        const resolution = this.getScreenResolution();

        this.authService
          .loginSalesrunner(email, password, resolution)
          .pipe(first())
          .subscribe({
            next: () => {
              this.changeSalesRunnerLoggedIn.emit('true');
              this.loginSuccess();
            },
            error: () => {
              this.loginFailed();
            },
          });
      } else {
        this.authService
          .login(email, password)
          .pipe(first())
          .subscribe({
            next: () => {
              this.changeLoggedIn.emit(true);
              this.loginSuccess();
            },
            error: () => {
              this.loginFailed();
            },
          });
      }
    }
  }

  getScreenResolution() {
    return window.screen.width + ' x ' + window.screen.height;
  }

  loginSuccess(): void {
    this.snackBarService.openSnackBar('Login erfolgreich', 'success');
    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'leads';
    this.router.navigateByUrl(returnUrl);
  }

  loginFailed(): void {
    this.snackBarService.openSnackBar(
      'Ihre E-Mail-Adresse oder Ihr Passwort ist nicht richtig, bitte versuchen Sie es erneut.',
      'error',
    );
  }

  reloadPage(): void {
    window.location.reload();
  }

  clickForgotPassword(): void {
    this.forgotPasswordMode = !this.forgotPasswordMode;
  }
}
