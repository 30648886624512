import { Component, Input } from '@angular/core';
import { Address } from '@lib/models/address';

@Component({
  selector: 'app-partner-lead-address',
  templateUrl: './partner-lead-address.component.html',
})
export class PartnerLeadAddressComponent {
  @Input() address: Address = new Address({});

  constructor() {}
}
