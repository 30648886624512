import { Reaction } from './reaction';
import { PartnerLead } from './partner-lead';
import { User } from './user';
import { Comment } from './comment';
import { PartnerUser } from './partners/partner-user';
import { OptionlistOption } from './optionlist/optionlist-option';

export class Reminder {
  id?: number = 0;
  lead_id?: number;
  partner_lead_id?: number;
  user_id?: number = 0;
  partner_user_id?: number = 0;
  reminder_type_option_id: number = 0;
  reminder_status_option_id: number = 0;
  visible_in_salesrunner: boolean = false;
  reminder_date: string = '';

  partner_lead?: PartnerLead = new PartnerLead({});
  comments?: Comment[] = [];
  user?: User = new User({});
  partner_user?: PartnerUser = new PartnerUser({});
  reminder_type_option?: OptionlistOption = new OptionlistOption({});
  reminder_status_option?: OptionlistOption = new OptionlistOption({});
  created_at?: string = '';
  updated_at?: string = '';
}
