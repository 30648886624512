import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PartnerUser } from '@lib/models/partners/partner-user';
import { EnvironmentService } from '@lib/services/environment.service';

@Injectable({
  providedIn: 'root',
})
export class PartnerUserService {
  endpoint = 'me';
  endpointChangePassword = 'change_password';

  constructor(
    protected http: HttpClient,
    private environmentService: EnvironmentService,
  ) {}

  changePassword(partnerUser: PartnerUser): any {
    return this.http.put(
      this.environmentService.apiUrl + this.endpointChangePassword,
      partnerUser,
      {
        responseType: 'json',
      },
    );
  }
}
