import { Component, Input } from '@angular/core';
import { CodeFile } from '../../shared/interfaces/demo-config.interface';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'lib-demo-example',
  templateUrl: './demo-example.component.html',
  styleUrls: ['./demo-example.component.scss'],
})
export class DemoExampleComponent {
  @Input() files: CodeFile[] = [];

  constructor(private clipboard: Clipboard) {}

  copyCode(content: string) {
    this.clipboard.copy(content);
  }
}
