import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectList } from '../../../models/select-list';
import { LeadBackendService } from '../../../services/lead-backend.service';
import { LeadBackend } from '../../../models/lead-backend';
import { firstValueFrom, Observable } from 'rxjs';
import { NewSelectSelection } from '../../../models/new-select-selection';
import { NewSelectOption } from '../../../models/new-select-option';
import { SelectService } from '../../../services/select.service';

@Component({
  selector: 'lib-lead-detail-filter-modal',
  templateUrl: './lead-detail-filter-modal.component.html',
  styleUrls: ['./lead-detail-filter-modal.component.scss'],
})
export class LeadDetailFilterModalComponent implements OnInit {
  selectedLeadTypes: NewSelectSelection = new NewSelectSelection();
  leadBackendList: LeadBackend[] = [];
  dataLoaded: boolean = true;
  selectedOptions: any[] = [];

  leadTypes$: Observable<NewSelectOption[]> = new Observable<
    NewSelectOption[]
  >();

  constructor(
    private leadBackendService: LeadBackendService,
    private selectService: SelectService,
    public dialogRef: MatDialogRef<LeadDetailFilterModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public inputData: {
      selectedLeadTypes: NewSelectSelection;
      selectedLeadDetails: NewSelectSelection;
    },
  ) {}

  ngOnInit(): void {
    this.leadTypes$ = this.selectService.getSelectOptionsBySelectList(
      SelectList.LeadType,
    );

    // negative Optionen sollen nicht übernommen werden
    if (!this.inputData?.selectedLeadTypes?.isNegative) {
      this.selectedOptions = this.inputData?.selectedLeadTypes?.options ?? null;

      if (this.inputData?.selectedLeadTypes) {
        this.selectedLeadTypes = this.inputData.selectedLeadTypes;

        this.loadLeadBackendList();
      }
    }
  }

  async loadLeadBackendList() {
    const temporaryLeadBackendList: LeadBackend[] = [];
    this.dataLoaded = false;

    const leadTypeIds = this.selectedLeadTypes.options.map((option) => {
      return option.value;
    });

    try {
      for (const leadTypeId of leadTypeIds) {
        const isAlreadyLoaded = temporaryLeadBackendList.some(
          (leadBackend) => leadBackend.lead_type_id === leadTypeId,
        );

        if (!isAlreadyLoaded) {
          const leadBackend = await firstValueFrom(
            this.leadBackendService.getLeadBackendListByLeadTypeId(leadTypeId),
          );

          if (leadBackend?.id) {
            const filteredLeadBackendElements =
              leadBackend.lead_backend_elements.filter(
                (element: any) => element.lead_field?.type === 5,
              );

            if (filteredLeadBackendElements.length > 0) {
              const enhancedElements = filteredLeadBackendElements.map(
                (element: any) => {
                  let existingSelection = null;

                  if (this.inputData?.selectedLeadDetails) {
                    existingSelection =
                      this.inputData.selectedLeadDetails.options.find(
                        (detail: any) =>
                          detail.value.lead_field_id === element.lead_field.id,
                      );
                  }

                  return {
                    ...element,
                    selectedOption: existingSelection
                      ? existingSelection?.value.option_id.toString()
                      : '',
                  };
                },
              );

              temporaryLeadBackendList.push({
                ...leadBackend,
                lead_backend_elements: enhancedElements,
              });
            }
          }
        }
      }

      // Aktualisiere die Hauptliste nur einmal am Ende des Ladevorgangs
      this.leadBackendList = temporaryLeadBackendList;
      this.dataLoaded = true;
    } catch (error) {
      this.dataLoaded = true;
      console.error('Error loading lead backend data', error);
    }
  }

  selectedLeadTypesChanged(selection: NewSelectSelection) {
    this.selectedLeadTypes = selection;

    this.loadLeadBackendList();
  }

  getLeadBackendElementFieldSize(fieldSize: number) {
    switch (fieldSize) {
      case 1:
        return 12;
      case 2:
        return 6;
      case 3:
        return 4;
      case 4:
        return 3;
      default:
        return 3;
    }
  }

  saveFilter() {
    const leadDetails = this.leadBackendList.flatMap((backend) =>
      backend.lead_backend_elements
        .filter((element: any) => element.selectedOption)
        .map((element: any) => {
          const newOption = new NewSelectOption();
          newOption.value = {
            lead_field_id: element.lead_field.id,
            lead_type_id: element.lead_field.lead_type_id,
            option_id: Number(element.selectedOption),
          };
          return newOption;
        }),
    );

    const leadDetailSelection = new NewSelectSelection();
    leadDetailSelection.isNegative = false;
    leadDetailSelection.options = leadDetails;

    this.dialogRef.close({
      leadDetails: leadDetailSelection,
      leadTypes: this.selectedLeadTypes,
    });
  }

  protected readonly SelectList = SelectList;
}
