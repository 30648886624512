<div class="row">
  <div class="col-md-6 mb-3">
    <label for="company_name" class="form-label mt-2">Firma</label>
    <input
      type="text"
      class="form-control"
      id="company_name"
      required
      [(ngModel)]="address.company_name"
      [disabled]="inputsDisabled"
      (change)="onDuplicateCheck()"
    />
  </div>
  <div class="col-md-6 mb-3">
    <label for="ust_id" class="form-label mt-2">Umsatzsteuer-ID </label>
    <input
      type="text"
      class="form-control"
      id="ust_id"
      required
      [(ngModel)]="address.ust_id"
      [disabled]="inputsDisabled"
    />
  </div>
  <div class="col-md-4 mb-3">
    <label for="salutation" class="form-label">Anrede</label>
    <select
      class="form-select partner-address"
      id="salutation"
      name="salutation"
      [(ngModel)]="address.salutation"
      [disabled]="inputsDisabled"
    >
      <option value="">Anrede</option>
      <option value="Herr">Herr</option>
      <option value="Frau">Frau</option>
    </select>
  </div>
  <div class="col-md-4 mb-3">
    <label for="first_name" class="form-label">Vorname</label>
    <input
      type="text"
      class="form-control"
      id="first_name"
      (change)="onNameChange()"
      [(ngModel)]="address.first_name"
      [disabled]="inputsDisabled"
      required
    />
  </div>

  <div class="col-md-4 mb-3">
    <label for="last_name" class="form-label">Nachname</label>
    <input
      type="text"
      class="form-control"
      id="last_name"
      (change)="onNameChange()"
      [(ngModel)]="address.last_name"
      [disabled]="inputsDisabled"
      required
    />
  </div>
  <div class="col-md-12">
    <div class="mb-1 form-check form-switch">
      <input
        type="checkbox"
        class="form-check-input"
        id="active"
        name="active"
        [checked]="separateAddress"
        [(ngModel)]="separateAddress"
      />
      <label class="form-check-label" for="active">{{
        separateAddress ? 'Zurücksetzen' : 'Trennen'
      }}</label>
    </div>
  </div>

  <div class="col-md-12 mb-3">
    <label for="address_autocomplete" class="form-label">Adresse</label>

    <input
      type="text"
      class="form-control"
      id="address_autocomplete"
      placeholder="Standort Adresse"
      [(ngModel)]="address.address"
      [matAutocomplete]="auto"
      [disabled]="separateAddress || inputsDisabled"
      (input)="onAutocompleteSearchInputChange()"
      (change)="onAutocompleteAddressChange()"
      (click)="onAutocompleteSearchInputChange()"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="onAutocompleteAddressChange()"
    >
      <mat-option
        *ngFor="let suggestion of autocompleteSuggestions"
        [value]="suggestion.address.label"
      >
        {{ suggestion.address.label }}
      </mat-option>
    </mat-autocomplete>
  </div>

  <div *ngIf="separateAddress" class="row mb-2">
    <div class="col-md-12 mb-3">
      <label for="street" class="form-label">Straße</label>
      <input
        type="text"
        class="form-control"
        id="street"
        [(ngModel)]="address.street"
        [disabled]="inputsDisabled"
        (change)="onAddressChange()"
        required
      />
    </div>
    <div class="col-md-4 mb-3">
      <label for="postcode" class="form-label">Postleitzahl</label>
      <input
        type="text"
        class="form-control"
        id="postcode"
        [(ngModel)]="address.postcode"
        [disabled]="inputsDisabled"
        (change)="onAddressChange()"
        required
      />
    </div>
    <div class="col-md-4 mb-3">
      <label for="city" class="form-label">Ort</label>
      <input
        type="text"
        class="form-control"
        id="city"
        [(ngModel)]="address.city"
        [disabled]="inputsDisabled"
        (change)="onAddressChange()"
        required
      />
    </div>
    <div class="col-md-4 mb-3">
      <label for="district" class="form-label">Ortsteil</label>
      <input
        type="text"
        class="form-control"
        id="district"
        [(ngModel)]="address.district"
        [disabled]="inputsDisabled"
        (change)="onAddressChange()"
        required
      />
    </div>
    <div class="col-md-12 mb-3">
      <label class="form-label" for="country_code">Land</label>
      <ng-select
        class=""
        [(ngModel)]="address.country_code"
        dropdownPosition="bottom"
        id="country_code"
        bindLabel="name"
        bindValue="code"
        [multiple]="false"
        [items]="countries"
        placeholder="Das Land auswählen"
        (change)="onAddressChange()"
      >
      </ng-select>
    </div>
    <div class="col-md-6 mb-3">
      <label for="lat" class="form-label">Breitengrad</label>
      <input
        type="text"
        class="form-control"
        id="lat"
        [(ngModel)]="address.lat"
        [disabled]="inputsDisabled"
        required
      />
    </div>
    <div class="col-md-6 mb-3">
      <label for="lng" class="form-label">Längengrad</label>
      <input
        type="text"
        class="form-control"
        id="lng"
        [(ngModel)]="address.lng"
        [disabled]="inputsDisabled"
        required
      />
    </div>
  </div>
  <div class="col-md-6 mb-3">
    <label for="address-email" class="form-label">E-Mail-Adresse</label>
    <div class="input-group">
      <button
        class="btn btn-outline-secondary"
        *ngIf="address.lead_id && appName == 'Leadmanager'"
        [disabled]="!address.email"
        (click)="onLeadEmailClick(address.email)"
        type="button"
      >
        <i class="bi bi-envelope-at"></i>
      </button>
      <a
        class="btn btn-outline-secondary"
        *ngIf="appName == 'Salesrunner'"
        href="mailto:{{ address.email }}"
        type="button"
      >
        <i class="bi bi-envelope-at"></i>
      </a>
      <input
        type="text"
        id="address-email"
        [(ngModel)]="address.email"
        [disabled]="inputsDisabled"
        class="form-control"
        placeholder=""
        aria-label="E-Mail Vorlagen öffnen"
        aria-describedby="emailHelp"
        (change)="onDuplicateCheck()"
        required
      />
    </div>
    <div id="emailHelp" class="form-text">(Mehrere Empfänger mit, trennen)</div>
  </div>
  <div class="col-md-3 mb-3">
    <label for="address-phone" class="form-label">Telefon</label>
    <div class="input-group mb-3">
      <ng-container *ngIf="address.lead_id && appName == 'Leadmanager'">
        <button
          class="btn btn-outline-secondary"
          type="button"
          [disabled]="!address.phone"
          (click)="onLeadCallClick(address.phone)"
        >
          <i class="bi bi-telephone"></i>
        </button>
        <button
          class="btn btn-outline-secondary"
          type="button"
          [disabled]="!address.phone"
          (click)="onLeadSmsClick(address.phone)"
        >
          <i class="bi bi-chat-left-text"></i>
        </button>
      </ng-container>
      <a
        class="btn btn-outline-secondary"
        *ngIf="appName == 'Salesrunner'"
        href="tel:{{ address.phone }}"
        type="button"
      >
        <i class="bi bi-telephone"></i>
      </a>

      <input
        type="text"
        id="address-phone"
        [(ngModel)]="address.phone"
        [disabled]="inputsDisabled"
        class="form-control"
        placeholder=""
        aria-label="Example text with two button addons"
        (change)="onDuplicateCheck()"
        required
      />
    </div>
  </div>
  <div class="col-md-3 mb-3">
    <label for="address-phone-2" class="form-label">Telefon 2</label>
    <div class="input-group mb-3">
      <ng-container *ngIf="address.lead_id && appName == 'Leadmanager'">
        <button
          class="btn btn-outline-secondary"
          type="button"
          [disabled]="!address.phone2"
          (click)="onLeadCallClick(address.phone2)"
        >
          <i class="bi bi-telephone"></i>
        </button>
        <button
          class="btn btn-outline-secondary"
          type="button"
          [disabled]="!address.phone2"
          (click)="onLeadSmsClick(address.phone2)"
        >
          <i class="bi bi-chat-left-text"></i>
        </button>
      </ng-container>
      <a
        class="btn btn-outline-secondary"
        *ngIf="appName == 'Salesrunner'"
        href="tel:{{ address.phone2 }}"
        type="button"
      >
        <i class="bi bi-telephone"></i>
      </a>
      <input
        type="text"
        id="address-phone-2"
        [(ngModel)]="address.phone2"
        [disabled]="inputsDisabled"
        class="form-control"
        placeholder=""
        aria-label="Example text with two button addons"
      />
    </div>
  </div>
</div>
