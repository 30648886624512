<div class="button-container" *ngIf="icon$ | async">
  <button
    [ngClass]="[
      'btn',
      variant === 'shadow' ? 'btn-shadow' : '',
      label ? 'has-label' : '',
      'btn-' + iconColor.toLowerCase()
    ]"
    [disabled]="disabled"
    [style.background-color]="currentColors.background"
  >
    <div class="btn-content">
      <div class="icon-wrapper">
        <div class="icon" *ngIf="!emoji" [innerHTML]="icon$ | async"></div>
        <div *ngIf="emoji" class="emoji-overlay">
          {{ emoji }}
        </div>
      </div>
      <span *ngIf="label" class="btn-label">{{ label }}</span>
      <span *ngIf="count" class="count-display">| {{ count }}</span>
    </div>
  </button>
</div>
