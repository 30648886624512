<ng-container *ngIf="address">
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="lead-list-headline"
  >
    {{ address.company_name }}
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    *ngIf="address.ust_id"
  >
    {{ address.ust_id }}
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    {{ address.salutation }} {{ address.first_name }}
    {{ address.last_name }}
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    {{ address.street }}
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    {{ address.postcode }} {{ address.city }} {{ address.district }}
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    {{ address.land }}
  </div>
  <div fxLayout="row" fxLayoutAlign="start center">
    Telefon:
    <a href="tel:'{{ address.phone }}'" style="margin-left: 5px">
      {{ address.phone }}</a
    >
  </div>
</ng-container>
