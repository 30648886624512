import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { Reaction } from '../models/reaction';
import { Feed } from '../models/feed';
import { EmojiPickerService } from './emoji-picker.service';

interface ReactionUser {
  emoji: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReactionService {
  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
  ) {}

  createReaction(reaction: Reaction): Observable<Reaction> {
    return this.http.post<Reaction>(
      `${this.environmentService.apiUrl}reactions`,
      reaction,
    );
  }

  getReactionsForModel(
    modelType: string,
    modelId: number,
  ): Observable<Reaction[]> {
    return this.http.get<Reaction[]>(
      `${this.environmentService.apiUrl}reactions/${modelType}/${modelId}`,
    );
  }

  deleteReaction(id: number | undefined): Observable<void> {
    return this.http.delete<void>(
      `${this.environmentService.apiUrl}reactions/${id}`,
      {
        responseType: 'json',
      },
    );
  }

  getReactionSummary(feed: Feed): string {
    const reactions = feed?.relatable?.reactions || [];
    const emojiCount = new Map<string, number>();

    reactions.forEach((reaction: Reaction) => {
      const count = emojiCount.get(reaction.emoji) || 0;
      emojiCount.set(reaction.emoji, count + 1);
    });

    return Array.from(emojiCount.entries())
      .map(([emoji, count]) => `${emoji} ${count}`)
      .join(' · ');
  }

  getReactionUsers(feed: Feed): ReactionUser[] {
    const reactions = feed?.relatable?.reactions || [];
    return reactions.map((reaction: Reaction) => {
      const user = reaction.user || reaction.partner_user;
      const name =
        user?.name ||
        (user?.first_name && user?.last_name
          ? `${user.first_name} ${user.last_name}`.trim()
          : 'Unbekannt');

      return {
        emoji: reaction.emoji,
        name: name,
      };
    });
  }

  hasReactions(feed: Feed): boolean {
    return feed?.relatable?.reactions?.length > 0;
  }

  getReactionTooltipText(feed: Feed): string {
    if (!this.hasReactions(feed)) return 'Reagieren';

    const reactionUsers = this.getReactionUsers(feed);
    const reactionSummary = this.getReactionSummary(feed);

    let tooltipText = `<b>${reactionSummary}</b><br><br>`;

    // Gruppiere Benutzer nach Emojis
    const groupedUsers = reactionUsers.reduce((acc, { emoji, name }) => {
      if (!acc[emoji]) {
        acc[emoji] = [];
      }
      acc[emoji].push(name);
      return acc;
    }, {} as Record<string, string[]>);

    // Erstelle formatierte Liste für jeden Emoji
    Object.entries(groupedUsers).forEach(([emoji, names]) => {
      tooltipText += `${emoji} ${names.join(', ')}<br>`;
    });

    return tooltipText;
  }
}
