<div fxFill>
  <input
    type="file"
    [disabled]="disabled"
    multiple
    (change)="selectFiles($event)"
    #fileInput
    hidden
  />
  <div
    class="drop-zone"
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    [class.active]="isDragging"
  >
    Ziehen Sie Dateien hierher oder
    <button
      (mousedown)="$event.preventDefault()"
      (mouseup)="fileInput.click()"
      [disabled]="disabled"
    >
      Wählen Sie Dateien
    </button>
  </div>
  <div *ngIf="filesToUpload.length > 0">
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="section-headline"
    >
      <span>ausgewählte Dateien</span>

      <button mat-icon-button *ngIf="!disabled" (click)="clearAllFiles()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-list *ngFor="let file of filesToUpload; let i = index">
      <mat-list-item>
        <button
          class="delete-button"
          mat-icon-button
          matListItemIcon
          *ngIf="!disabled"
          (click)="removeFileFromUploadList(i)"
        >
          <mat-icon>delete</mat-icon>
        </button>
        <div matListItemTitle>
          {{ file.name }}
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>

<mat-divider *ngIf="filesToUpload?.length"></mat-divider>

<div class="section-headline" *ngIf="existingFiles?.length">
  gespeicherte Dateien
</div>
<mat-list *ngFor="let file of existingFiles">
  <mat-list-item>
    <button
      class="delete-button"
      mat-icon-button
      matListItemIcon
      *ngIf="!disabled"
      (click)="deleteFile(file)"
    >
      <mat-icon>delete</mat-icon>
    </button>
    <div matListItemTitle>
      <a target="_blank" [href]="API_URL + file.file_access_link">
        {{ file.original_file_name }}</a
      >
    </div>
  </mat-list-item>
</mat-list>
