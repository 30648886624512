<app-card>
  <ng-container *ngIf="!(partnerLead$ | async)">
    <app-card-head pageTitle="Lead wird geladen...">
      <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </app-card-head>
    <app-card-body>
      <div class="loading-container">
        <mat-spinner diameter="40"></mat-spinner>
        <span class="loading-text">Daten werden geladen...</span>
      </div>
    </app-card-body>
  </ng-container>

  <ng-container *ngIf="partnerLead$ | async as partnerLead">
    <app-card-head
      pageTitle="{{
        '#' +
          partnerLead.lead_number +
          ' - ' +
          addressService.getDisplayName(
            partnerLead.address.company_name,
            partnerLead.address.name
          )
      }}"
    >
      <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </app-card-head>

    <app-card-body>
      <div class="bill-info-container">
        <div
          *ngIf="shouldShowBillInfo(partnerLead)"
          class="bill-info"
          [class.bill-warning]="isBillOverdue(partnerLead)"
        >
          <div class="bill-info-content">
            <mat-icon class="bill-info-icon">
              {{ isBillOverdue(partnerLead) ? 'warning' : 'info' }}
            </mat-icon>
            <span>{{ getBillInfo(partnerLead) }}</span>
          </div>

          <button
            *ngIf="isBillOverdue(partnerLead)"
            mat-stroked-button
            color="primary"
            (click)="enterInvoice(partnerLead)"
          >
            <mat-icon>add</mat-icon>
            Rechnung erfassen
          </button>
        </div>
      </div>

      <mat-accordion class="custom-accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="panel-icon">description</mat-icon>
              Lead Überblick
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="lead-detail-container">
            <div
              class="button-group"
              *ngIf="partnerLead.partner?.invoice_and_performance_data === 1"
            >
              <div class="save-button-wrapper">
                <button
                  mat-stroked-button
                  color="primary"
                  (click)="enterInvoice(partnerLead)"
                >
                  Neuen Umsatz erfassen
                </button>
              </div>
            </div>

            <form #f="ngForm" class="lead-form">
              <div class="form-grid">
                <div class="form-group">
                  <label class="form-label" for="assignedUser">
                    Zugewiesen an
                    <mat-icon
                      matTooltip="Weisen Sie den Lead einem bestimmten Benutzer zu, der für die Bearbeitung zuständig sein soll."
                      matTooltipClass="custom-tooltip"
                    >
                      info
                    </mat-icon>
                  </label>
                  <select
                    class="form-control form-control-sm"
                    id="assignedUser"
                    name="assignedUser"
                    [ngModel]="partnerLead.assigned_user_id"
                    (ngModelChange)="onAssignUserChange($event, partnerLead)"
                  >
                    <option [ngValue]="null">Nicht zugewiesen</option>
                    <option
                      *ngFor="let user of getTargetingUsers(partnerLead)"
                      [ngValue]="user.id"
                    >
                      {{ user.first_name }} {{ user.last_name }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label class="form-label" for="leadType">Leadart</label>
                  <select
                    class="form-control form-control-sm"
                    id="leadType"
                    name="leadType"
                    [(ngModel)]="partnerLead.lead_type.id"
                    (ngModelChange)="onFieldChange(partnerLead)"
                    disabled
                  >
                    <option *ngFor="let type of leadTypes" [value]="type.id">
                      {{ type.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="form-label" for="status">Status</label>
                  <select
                    class="form-control form-control-sm"
                    id="status"
                    name="status"
                    [ngModel]="partnerLead.status"
                    (ngModelChange)="onStatusChange($event, partnerLead)"
                  >
                    <option
                      *ngFor="let option of leadStatusOptionList"
                      [value]="option.option_id"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="form-label" for="request_at"
                    >Anfragezeitpunkt</label
                  >
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="request_at"
                    [value]="partnerLead.send_at | customDateTime"
                    disabled
                  />
                </div>

                <div class="form-group">
                  <label class="form-label" for="number">Leadnummer</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="number"
                    [(ngModel)]="partnerLead.lead_number"
                    (ngModelChange)="onFieldChange(partnerLead)"
                    name="leadNumber"
                    disabled
                  />
                </div>

                <div class="form-group">
                  <label class="form-label" for="offer_value"
                    >Angebotswert</label
                  >
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="offer_value"
                    name="offerValue"
                    [(ngModel)]="offerValue"
                    (ngModelChange)="onOfferValueChange(partnerLead)"
                    placeholder="0 €"
                  />
                </div>

                <div class="form-group">
                  <label class="form-label" for="offer_number">
                    Referenznummer
                    <mat-icon
                      matTooltip="In diesem Feld können Sie z.B. die Kundennummer oder Ihre interne Angebotsnummer hinterlegen. Die Referenznummer wird im Export der Lead-Liste angezeigt und soll Ihnen die Zuordnung zu Ihrem System und Ihren Kundendaten erleichtern."
                      matTooltipClass="custom-tooltip"
                    >
                      info
                    </mat-icon>
                  </label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="offer_number"
                    name="offerNumber"
                    [(ngModel)]="partnerLead.offer_number"
                    (ngModelChange)="onFieldChange(partnerLead)"
                  />
                </div>

                <ng-container
                  *ngIf="
                    partnerLead.status == PartnerLeadStatus.Verloren ||
                    partnerLead.status.option_id == PartnerLeadStatus.Verloren
                  "
                >
                  <div class="form-group">
                    <label class="form-label" for="cancel_reason"
                      >Grund für Verlust</label
                    >
                    <select
                      class="form-control form-control-sm"
                      id="cancel_reason"
                      name="cancel_reason_id"
                      [(ngModel)]="partnerLead.cancel_reason_id"
                      (ngModelChange)="onFieldChange(partnerLead)"
                    >
                      <option [value]="null">Bitte wählen</option>
                      <option
                        *ngFor="let reason of cancelReasons"
                        [value]="reason.option_id"
                      >
                        {{ reason.name }}
                      </option>
                    </select>
                  </div>

                  <div
                    class="form-group"
                    *ngIf="
                      partnerLead.cancel_reason_id ==
                      PartnerCancelReason['Sonstiges (manuelle Eingabe)']
                    "
                  >
                    <label class="form-label" for="manual_reason"
                      >Sonstiger Grund</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="manual_reason"
                      name="manual_reason"
                      [(ngModel)]="partnerLead.cancel_reason"
                      (ngModelChange)="onFieldChange(partnerLead)"
                    />
                  </div>

                  <div
                    class="form-group"
                    *ngIf="
                      partnerLead.cancel_reason_id ==
                      PartnerCancelReason['Wettbewerber beauftragt']
                    "
                  >
                    <label class="form-label" for="competitor_name"
                      >Name des Wettbewerbers</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="competitor_name"
                      name="competitor_name"
                      [(ngModel)]="partnerLead.cancel_reason"
                      (ngModelChange)="onFieldChange(partnerLead)"
                    />
                  </div>
                </ng-container>
              </div>
            </form>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="panel-icon">location_on</mat-icon>
              Adresse
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-addresses
            [addresses]="partnerLead.addresses"
            (addressesChanged)="onAddressChange($event, partnerLead)"
          ></app-addresses>
        </mat-expansion-panel>

        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="panel-icon">forum</mat-icon>
              Kommunikation
            </mat-panel-title>
          </mat-expansion-panel-header>
          <lib-feed
            [partnerLead$]="partnerLead$"
            (feedUpdated)="onFeedUpdated()"
          ></lib-feed>
        </mat-expansion-panel>

        <mat-expansion-panel
          *ngIf="
            (partnerUser$ | async) &&
            partnerLead.partner?.invoice_and_performance_data == 1
          "
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="panel-icon">receipt</mat-icon>
              Umsätze
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-partner-bills
            [showCreateButton]="true"
            [partnerLeadId]="partnerLead.id"
            [partnerLeadPrice]="partnerLead.price"
            [pageTitelChange]="'Umsätze'"
          ></app-partner-bills>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="panel-icon">settings</mat-icon>
              Projektdaten
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-partner-lead-backend
            [partnerLead]="partnerLead"
            (partnerLeadChanged)="onBackendDataChange($event)"
          ></app-partner-lead-backend>
        </mat-expansion-panel>
      </mat-accordion>
    </app-card-body>
  </ng-container>

  <ng-template #loading>
    <app-card-head pageTitle="Lead wird geladen...">
      <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </app-card-head>
    <app-card-body>
      <div class="loading-container">
        <mat-spinner diameter="40"></mat-spinner>
        <span class="loading-text">Daten werden geladen...</span>
      </div>
    </app-card-body>
  </ng-template>
</app-card>
