import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { TableComponent } from './table.component';
import { MatSortModule } from '@angular/material/sort';
import { SharedDirectiveModule } from '../../directives/shared-directive.module';
import { TableColumnComponent } from './table-column.component';
import { DynamicPipe } from '../../pipes/dynamic.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexModule } from '@angular/flex-layout';
import { CustomMatPaginatorIntl } from './custom-mat-paginator-intl .component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PricePipe } from '../../pipes/PricePipe';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    TableComponent,
    TableColumnComponent,
    DynamicPipe,
    DynamicPipe,
    PricePipe,
  ],
  providers: [
    DatePipe,
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
  ],
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    SharedDirectiveModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FlexModule,
    CdkDropList,
    CdkDrag,
  ],
  exports: [TableComponent, TableColumnComponent, DynamicPipe, PricePipe],
})
export class TableModule {}
