export class User {
  id: number = 0;
  email: string = '';
  email_verified_at: string = '';
  first_name: string = '';
  last_name: string = '';
  name: string = '';
  phone: string = '';
  remember_token: string = '';
  salutation: string = '';
  sipgate_auth: string = '';
  sipgate_device_id: string = '';
  sipgate_user_id: string = '';
  user_group: number = 0;
  password: string = '';
  password_confirmation: string = '';

  cost_type: string = '';
  has_inspector_targetings: boolean = false;
  constructor(user: User | {}) {
    Object.assign(this, user);
  }
}
