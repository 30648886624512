<ng-select
  *ngIf="options?.length"
  [(ngModel)]="selectedOptions"
  [items]="options"
  bindLabel="name"
  [clearable]="clearable"
  [multiple]="multiSelect"
  [placeholder]="placeholder"
  [appendTo]="appendClass ? 'body' : ''"
  (change)="select()"
  [ngClass]="{
    'ctrl-pressed-container': ctrlPressed,
    'custom-negative-container': hasNegativeValues
  }"
  class="select-design"
  [disabled]="disabled"
>
  <ng-option
    *ngFor="let option of options"
    [value]="option"
    [disabled]="option.disabled"
  >
    {{ option.name }}
  </ng-option>
</ng-select>
