import { Address } from './address';
import { LeadType } from './lead-type';
import { Partner } from './partner';
import { PartnerLeadHistory } from './partners/partner-lead-history';
import { OptionlistOption } from './optionlist/optionlist-option';
import { Targeting } from './targeting/targeting';
import { Price } from './prices';
import { Reminder } from './reminder';
import { CommentSummary } from './comment-summary';

export class PartnerLead {
  id: number = 0;
  partner_id: number = 0;
  address_id: string = '';
  address = new Address({});
  addresses: Address[] = [];
  aquised_at: string = '';
  billing_model: number = 0;
  bills: any = [];
  cancel_reason: string = '';
  cancel_reason_id: number = 0;
  created_at: string = '';
  decision_at: string = '';
  decision_user_agent: number = 0;
  offer_value: number = 0;
  offer_number: string = '';
  deleted_at: string = '';
  is_accepted: number = 0;
  email_notification_for_partner: number = 0;
  last_bill: boolean = false;
  lead_auth_token: string = '';
  lead_type_id: number = 0;
  lead_id: number = 0;
  lead_number: string = '';
  lead_offer_status: string = '';
  lead_offer_status_at: string = '';
  partner_response_at: string = '';
  send_at: string = '';
  targeting = new Targeting({});
  lead_type = new LeadType({});
  partner: Partner = new Partner({});
  partner_lead_history: PartnerLeadHistory[] = [];
  status: any;
  status_option: OptionlistOption = new OptionlistOption({});
  umsatz: number = 0;
  targeting_id: number = 0;
  partner_response: number = 0;
  cancel_name: OptionlistOption = new OptionlistOption({});
  price: Price = new Price({});
  lead_details: any[] = [];
  reminders: Reminder[] = [];
  decline_reason_name: OptionlistOption = new OptionlistOption({});

  lead_comment_summary: CommentSummary = new CommentSummary();
  has_lead_comment_summary: number = 0;

  constructor(partnerLead: PartnerLead | any = {}) {
    Object.assign(this, partnerLead);
  }
}
