import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PartnerLead } from '@lib/models/partner-lead';
import { AuthService } from '@lib/services/auth.service';
import { PartnerUserCostType } from '@lib/enum-collection';
import { PartnerUser } from '@lib/models/partners/partner-user';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-lead-project-finished-modal',
  templateUrl: './lead-project-finished-modal.component.html',
  styleUrls: ['./lead-project-finished-modal.component.scss'],
})
export class LeadProjectFinishedModalComponent implements OnInit, OnDestroy {
  partnerLead = new PartnerLead();
  billSum: number = 0;
  partnerUser$ = this.authService.partnerUser$;
  partnerUser: PartnerUser | null = null;

  private destroy$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<LeadProjectFinishedModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public inputData: { partnerLead: PartnerLead },
  ) {}

  ngOnInit(): void {
    if (this.inputData?.partnerLead) {
      this.partnerLead = this.inputData.partnerLead;
      this.calculateBillSum();
    }

    this.authService.partnerUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe((partnerUser: PartnerUser | null) => {
        this.partnerUser = partnerUser;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  calculateBillSum() {
    for (const bill of this.partnerLead.bills) {
      this.billSum += Number(bill.bill_sum);
    }
  }

  finishProject() {
    this.dialogRef.close(true);
  }

  protected readonly PartnerUserCostType = PartnerUserCostType;
}
