import { Injectable } from '@angular/core';
import { HttpHandlerService } from '../http-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PartnerLead } from '../../models/partner-lead';
import { StorageService } from '../storage.service';
import { EnvironmentService } from '../environment.service';

@Injectable({
  providedIn: 'root',
})
export class PartnerLeadService {
  endpoint: string = 'get_lead_partner_leads/';
  endpoint2: string = 'partner_leads/';
  endpointScore: string = 'partner_lead_score/';
  endpointMissingBills: string = 'check_missing_bills/';

  constructor(
    private httpHandler: HttpHandlerService,
    protected http: HttpClient,
    private environmentService: EnvironmentService,
  ) {}

  list(httpParams: any): Observable<any> {
    return this.httpHandler.list(this.endpoint, httpParams);
  }

  listPartnerLeads(httpParams: any): Observable<any> {
    return this.httpHandler.list(this.endpoint2, httpParams);
  }

  getList(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  show(id: number): Observable<any> {
    return this.httpHandler.show(this.endpoint2, id);
  }

  update(partnerLead: PartnerLead): Observable<any> {
    return this.httpHandler.update(this.endpoint2, partnerLead);
  }

  delete(id: number): Observable<any> {
    return this.httpHandler.delete(this.endpoint, id);
  }

  showPartnerLead(id: number): Observable<any> {
    return this.httpHandler.show(this.endpoint2, id);
  }

  hasMissingBills(): Observable<any> {
    return this.http.get(
      this.environmentService.apiUrl + this.endpointMissingBills + '?',
      {
        responseType: 'json',
      },
    );
  }

  export(httpParams: any): Observable<any> {
    return this.httpHandler.export(this.endpoint2, httpParams);
  }

  getPartnerLeadScoreByAuthenticatedPartnerUser(): Observable<any> {
    return this.http.get(
      this.environmentService.apiUrl + this.endpointScore + '?',
      {
        responseType: 'json',
      },
    );
  }

  acceptPartnerLead(partnerLead: PartnerLead): Observable<any> {
    return this.http.get(
      this.environmentService.apiUrl + 'accept_lead/' + partnerLead.id + '?',
      {
        responseType: 'json',
      },
    );
  }

  declinePartnerLead(
    partnerLead: PartnerLead,
    optionlist_option_id: number,
  ): Observable<any> {
    return this.http.get(
      this.environmentService.apiUrl +
        'decline_lead/' +
        partnerLead.lead_auth_token +
        '/' +
        partnerLead.id +
        '/' +
        optionlist_option_id +
        '?',
      {
        responseType: 'json',
      },
    );
  }

  getPartnerLeadChanceAmount(): Observable<any> {
    return this.http.get<{ count: number }>(
      this.environmentService.apiUrl + 'partner_lead_chances_amount/?',
      {
        responseType: 'json',
      },
    );
  }
}
