<div class="feed-list">
  <ng-container *ngFor="let feed of filteredItems$ | async">
    <lib-feed-item
      [lead]="lead"
      [feed]="FeedMapper.toNewFeed(feed)"
      [isExpanded]="expandedFeedId === feed.id"
      (toggleExpand)="onToggleExpand($event)"
    ></lib-feed-item>
  </ng-container>
</div>
