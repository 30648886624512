import { Component } from '@angular/core';

@Component({
  selector: 'lib-status-colors-example',
  template: `
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <!-- Status Colors -->
      <div class="color-card">
        <h3 class="text-lg font-semibold mb-4">Status-Farben</h3>
        <div class="space-y-2">
          <div class="color-sample success p-4 rounded text-white">
            <span class="font-mono text-sm">success</span>
            <span class="text-sm">#43a047</span>
          </div>
          <div class="color-sample error p-4 rounded text-white">
            <span class="font-mono text-sm">error</span>
            <span class="text-sm">#e53935</span>
          </div>
          <div class="color-sample warning p-4 rounded">
            <span class="font-mono text-sm">warning</span>
            <span class="text-sm">#ffc107</span>
          </div>
          <div class="color-sample info p-4 rounded text-white">
            <span class="font-mono text-sm">info</span>
            <span class="text-sm">#2196f3</span>
          </div>
        </div>
      </div>

      <!-- Usage Example -->
      <div class="color-card">
        <h3 class="text-lg font-semibold mb-4">Beispiel-Anwendung</h3>
        <div class="space-y-4">
          <div class="flex items-center gap-2 text-success">
            <mat-icon>check_circle</mat-icon>
            <span>Erfolgreich gespeichert</span>
          </div>
          <div class="flex items-center gap-2 text-error">
            <mat-icon>error</mat-icon>
            <span>Fehler beim Speichern</span>
          </div>
          <div class="flex items-center gap-2 text-warning">
            <mat-icon>warning</mat-icon>
            <span>Achtung: Ungespeicherte Änderungen</span>
          </div>
          <div class="flex items-center gap-2 text-info">
            <mat-icon>info</mat-icon>
            <span>Zusätzliche Information</span>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .color-card {
        @apply p-4 bg-white rounded-lg shadow;
      }
      .color-sample {
        @apply flex items-center justify-between;
      }
      .success {
        background-color: var(--success-color, #43a047);
      }
      .error {
        background-color: var(--error-color, #e53935);
      }
      .warning {
        background-color: var(--warning-color, #ffc107);
      }
      .info {
        background-color: var(--info-color, #2196f3);
      }
      .text-success {
        color: var(--success-color, #43a047);
      }
      .text-error {
        color: var(--error-color, #e53935);
      }
      .text-warning {
        color: var(--warning-color, #ffc107);
      }
      .text-info {
        color: var(--info-color, #2196f3);
      }
    `,
  ],
})
export class StatusColorsExample {}
