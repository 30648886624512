import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DateTimePickerService implements OnDestroy {
  private datetimeSubject = new Subject<any>();
  event$ = this.datetimeSubject.asObservable();
  private subscription: Subscription = new Subscription();

  triggerEvent(data: any) {
    this.datetimeSubject.next(data);
  }
  ngOnDestroy() {
    // Unsubscribe from the subscription when the service is destroyed
    this.subscription.unsubscribe();
  }
}
