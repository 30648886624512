<app-card>
  <ng-container *ngIf="!(chance$ | async)">
    <app-card-head pageTitle="Chance wird geladen...">
      <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </app-card-head>
    <app-card-body>
      <div class="loading-container">
        <mat-spinner diameter="40"></mat-spinner>
        <span class="loading-text">Daten werden geladen...</span>
      </div>
    </app-card-body>
  </ng-container>

  <ng-container *ngIf="chance$ | async as chance">
    <app-card-head [pageTitle]="getPageTitle(chance)">
      <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </app-card-head>

    <app-card-body>
      <div class="loading-overlay" *ngIf="loadingInProgress">
        <div class="loading-content">
          <mat-spinner diameter="40"></mat-spinner>
          <div class="loading-text">
            <div class="centered-text" *ngIf="loadingAction === 'accept'">
              Wird in Leadliste übertragen & per E-Mail gesendet<br />Viel
              Erfolg!
            </div>
            <div class="centered-text" *ngIf="loadingAction === 'decline'">
              Lead wird abgelehnt...<br />Bitte warten
            </div>
          </div>
        </div>
      </div>

      <div class="action-buttons">
        <button
          class="btn btn-success"
          (click)="$event.stopPropagation(); acceptChance(chance)"
        >
          Annehmen
        </button>
        <button
          *ngIf="chance.status === 7"
          class="btn btn-danger"
          (click)="$event.stopPropagation(); declineChance(chance)"
        >
          Ablehnen
        </button>
      </div>

      <div class="form-grid lead-overview">
        <div class="form-group">
          <label class="form-label">Anfragezeitpunkt</label>
          <input
            type="text"
            class="form-control form-control-sm"
            [value]="chance.send_at | date : 'dd.MM.yyyy HH:mm'"
            disabled
          />
        </div>

        <div class="form-group">
          <label class="form-label">Status</label>
          <input
            type="text"
            class="form-control form-control-sm"
            [value]="getStatusText(chance.status)"
            disabled
          />
        </div>

        <div class="form-group" *ngIf="chance?.decline_reason_name?.name">
          <label class="form-label">Ablehnungsgrund</label>
          <input
            type="text"
            class="form-control form-control-sm"
            [value]="chance.decline_reason_name.name"
            disabled
          />
        </div>
      </div>

      <mat-accordion class="custom-accordion" multi>
        <!-- Description Panel -->
        <mat-expansion-panel
          expanded
          *ngIf="chance.has_lead_comment_summary && chance.lead_comment_summary"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="panel-icon">view_headline</mat-icon>
              Zusammenfassung
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="lead-comment-summary">
            {{ chance.lead_comment_summary }}
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="chance.lead_details.length > 0" expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="panel-icon">settings</mat-icon>
              Projektdaten
            </mat-panel-title>
          </mat-expansion-panel-header>

          <app-partner-lead-backend
            [formDisabled]="true"
            [partnerLead]="chance"
          ></app-partner-lead-backend>
        </mat-expansion-panel>

        <!-- Additional Commission Panel -->
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="panel-icon">euro</mat-icon>
              Provisionen
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="commission-container">
            <table class="table">
              <thead>
                <tr>
                  <th>Typ</th>
                  <th>Provision</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nettorechnungssumme</td>
                  <td>{{ chance.price.price | percentFormat }}</td>
                </tr>
                <tr
                  *ngFor="let commission of chance.price.additional_commissions"
                >
                  <td>{{ commission.additional_price_name }}</td>
                  <td>
                    {{ commission.additional_price_commission | percentFormat }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </app-card-body>
  </ng-container>
</app-card>
