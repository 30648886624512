<div *ngIf="leadResponse">
  <div class="flex flex-col gap-3">
    <!-- Response Option -->
    <div class="response-section">
      <b style="margin-right: 10px">Lead Rückmeldung:</b>
      <ng-container [ngSwitch]="leadResponse.response">
        <span *ngSwitchCase="1" class="custom-badge badge-green">
          {{ leadResponse.lead_response_option.name }}
        </span>
        <span *ngSwitchCase="2" class="custom-badge badge-red">
          {{ leadResponse.lead_response_option.name }}
        </span>
        <span *ngSwitchCase="3" class="custom-badge badge-yellow">
          {{ leadResponse.lead_response_option.name }}
        </span>
        <span *ngSwitchCase="4" class="custom-badge badge-red">
          {{ leadResponse.lead_response_option.name }}
        </span>
        <span *ngSwitchCase="5" class="custom-badge badge-yellow">
          {{ leadResponse.lead_response_option.name }}
        </span>
        <span *ngSwitchDefault class="custom-badge badge-gray">
          {{ leadResponse.lead_response_option.name }}
        </span>
      </ng-container>
    </div>

    <!-- Partner Info -->
    <div
      class="partner-section"
      *ngIf="leadResponse.lead_response_success_partner_lead_id"
    >
      <b>Partner:</b>
      <span>
        {{ leadResponse.lead_response_success_partner_lead.partner.name }}</span
      >
    </div>
  </div>
</div>
