import { Component } from '@angular/core';

@Component({
  selector: 'lib-icon-badge-example',
  template: IconBadgeExample.HTML_CODE,
})
export class IconBadgeExample {
  static HTML_CODE = `
     <div class="flex gap-2 flex-wrap">
      <span class="custom-badge badge-blue">
        <mat-icon>info</mat-icon>
        Information
      </span>
      <span class="custom-badge badge-red">
        <mat-icon>warning</mat-icon>
        Critical
      </span>
      <span class="custom-badge badge-green">
        <mat-icon>check_circle</mat-icon>
        Complete
      </span>
    </div>
  `;
}
