import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SnackBarService } from '@lib/services/snack-bar/snack-bar.service';
import {
  BehaviorSubject,
  debounceTime,
  firstValueFrom,
  Observable,
  Subject,
  switchMap,
  take,
} from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { PartnerLead } from '@lib/models/partner-lead';
import { PartnerLeadService } from '@lib/services/partner-lead/partner-lead.service';
import { LeadStateService } from '../../../services/lead-state.service';
import { Router } from '@angular/router';
import { FeedComponent } from '@lib/components/feed/feed.component';
import { AddressService } from '@lib/services/address/address.service';
import { PriceNumberFormatHelper } from '@lib/helpers/price-number-format-helper';
import { LeadType } from '@lib/models/lead-type';
import { OptionlistOption } from '@lib/models/optionlist/optionlist-option';
import { AuthService } from '@lib/services/auth.service';
import { LeadTypeService } from '@lib/services/lead-type/lead-type.service';
import { OptionlistsService } from '@lib/services/option-lists/optionlists.service';
import { Address } from '@lib/models/address';
import { PartnerCancelReason, PartnerLeadStatus } from '@lib/enum-collection';
import { User } from '@lib/models/user';
import { PartnerUser } from '@lib/models/partners/partner-user';
import { DateHelper } from '@lib/helpers/date-helper';

@Component({
  selector: 'app-lead-list-detail-view',
  templateUrl: './lead-list-detail-view.component.html',
  styleUrls: ['./lead-list-detail-view.component.scss'],
})
export class LeadListDetailViewComponent implements OnInit, OnDestroy {
  @ViewChild(FeedComponent) feedComponent!: FeedComponent;
  @Input() leadId$: Observable<number | null> = new Observable<number | null>();

  partnerLead$: Observable<PartnerLead> = new Observable<PartnerLead>();
  cancelReasons: OptionlistOption[] = [];
  originalLead: PartnerLead | null = null;
  leadTypes: LeadType[] = [];
  offerValue: string = '';
  leadStatusOptionList: OptionlistOption[] = [];
  partnerUser$ = this.authService.partnerUser$;

  private autoSaveSubject = new Subject<PartnerLead>();
  private isAutoSaving = false;
  private lastSavedValue: string = '';

  authUser: User | PartnerUser | null = null;

  private readonly fieldsToCompare: (keyof PartnerLead)[] = [
    'status',
    'offer_value',
    'offer_number',
    'lead_type',
  ];

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly snackbarService: SnackBarService,
    private readonly partnerLeadService: PartnerLeadService,
    private readonly leadStateService: LeadStateService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly leadTypeService: LeadTypeService,
    private readonly optionlistService: OptionlistsService,
    public readonly addressService: AddressService,
  ) {
    this.initializeAutoSave();
  }

  ngOnInit(): void {
    this.initializeLeadSubscription();
    this.loadLeadTypes();
    this.loadLeadStatusOptions();
    this.loadCancelReasons();

    this.leadStateService.leadUpdated$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.refreshLeadData();
      });

    this.authService
      .getCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.authUser = user;
      });
  }

  private refreshLeadData(): void {
    this.leadId$
      .pipe(
        take(1),
        filter((leadId): leadId is number => leadId !== null),
        switchMap((leadId) => this.partnerLeadService.showPartnerLead(leadId)),
      )
      .subscribe((lead) => {
        this.partnerLead$ = new BehaviorSubject(lead);
      });
  }

  private initializeAutoSave(): void {
    this.autoSaveSubject
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        filter(() => !this.isAutoSaving),
      )
      .subscribe((lead) => {
        this.performAutoSave(lead);
      });
  }

  private async performAutoSave(lead: PartnerLead): Promise<void> {
    this.isAutoSaving = true;

    try {
      const leadToUpdate = { ...lead };
      const updatedLead = await firstValueFrom(
        this.partnerLeadService.update(leadToUpdate),
      );

      this.leadStateService.updateSingleLead(updatedLead);
    } catch (error) {
      this.snackbarService.openSnackBar(
        'Automatisches Speichern fehlgeschlagen',
        'error',
      );
      console.error('Auto-save error:', error);
    } finally {
      this.isAutoSaving = false;
    }
  }

  private loadLeadTypes(): void {
    this.leadTypeService
      .listPartnerLeadType()
      .pipe(takeUntil(this.destroy$))
      .subscribe((leadTypes) => {
        this.leadTypes = leadTypes;
      });
  }

  private loadCancelReasons(): void {
    this.optionlistService
      .optionlistByKeyword('cancel_reason')
      .pipe(takeUntil(this.destroy$))
      .subscribe((options) => {
        this.cancelReasons = options;
      });
  }

  private loadLeadStatusOptions(): void {
    this.optionlistService
      .optionlistByKeyword('partner_status')
      .pipe(takeUntil(this.destroy$))
      .subscribe((options) => {
        this.leadStatusOptionList = options;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async close(): Promise<void> {
    this.leadStateService.setSelectedLead(null);
    this.router.navigate(['/leads-new']);
  }

  onOfferValueChange(lead: PartnerLead): void {
    lead.offer_value = PriceNumberFormatHelper.prepareForDatabase(
      this.offerValue,
    );

    setTimeout(() => this.triggerAutoSave(lead), 0);
  }

  private triggerAutoSave(lead: PartnerLead): void {
    this.autoSaveSubject.next(lead);
  }

  onFieldChange(lead: PartnerLead): void {
    this.triggerAutoSave(lead);
  }

  onSubmit(lead: PartnerLead): void {
    this.performAutoSave(lead);
  }

  onFeedUpdated(): void {
    this.leadId$
      .pipe(
        take(1),
        filter((leadId): leadId is number => leadId !== null),
        switchMap((leadId) => this.partnerLeadService.showPartnerLead(leadId)),
      )
      .subscribe((updatedLead) => {
        this.leadStateService.updateSingleLead(updatedLead);
      });
  }

  enterInvoice(partnerLead: PartnerLead): void {
    this.router.navigate(['/partner_bills/create/' + partnerLead.id]);
  }

  private initializeLeadSubscription(): void {
    this.leadId$
      .pipe(
        takeUntil(this.destroy$),
        filter((leadId): leadId is number => leadId !== null),
      )
      .subscribe((leadId) => {
        this.partnerLead$ = this.partnerLeadService.showPartnerLead(leadId);

        this.partnerLead$.pipe(takeUntil(this.destroy$)).subscribe((lead) => {
          if (!this.originalLead) {
            this.lastSavedValue = this.fieldsToCompare
              .map((field) => JSON.stringify(lead[field]))
              .join('');
          }
          this.offerValue = PriceNumberFormatHelper.replaceDotWithComma(
            lead.offer_value,
          );
        });
      });
  }

  onAddressChange(updatedAddress: Address, lead: PartnerLead): void {
    const updatedLead = {
      ...lead,
      address: updatedAddress,
    };
    this.triggerAutoSave(updatedLead);
  }

  onBackendDataChange(updatedLead: PartnerLead): void {
    this.triggerAutoSave(updatedLead);
  }

  onStatusChange(newStatus: string | number, partnerLead: PartnerLead): void {
    this.partnerLeadService.handleStatusChange(
      newStatus,
      partnerLead,
      this.authUser,
      (updatedLead) => {
        this.leadStateService.updateSingleLead(updatedLead);

        this.leadId$
          .pipe(
            take(1),
            filter((leadId): leadId is number => leadId !== null),
            switchMap((leadId) =>
              this.partnerLeadService.showPartnerLead(leadId),
            ),
          )
          .subscribe((refreshedLead) => {
            this.partnerLead$ = this.partnerLeadService.showPartnerLead(
              refreshedLead.id,
            );
          });
      },
      () => {
        if (this.feedComponent) {
          this.feedComponent.loadFeed();
        }
        this.onFeedUpdated();
      },
    );
  }

  shouldShowBillInfo(partnerLead: PartnerLead): boolean {
    // Status 5 ist "Auftrag erhalten"
    return (
      partnerLead.status === 5 &&
      partnerLead.partner?.invoice_and_performance_data === 1
    );
  }

  isBillOverdue(partnerLead: PartnerLead): boolean {
    if (!this.shouldShowBillInfo(partnerLead)) {
      return false;
    }

    if (!partnerLead.bills || partnerLead.bills.length === 0) {
      return true;
    }

    const lastBillDate = this.getLastBillDate(partnerLead.bills);
    return lastBillDate < DateHelper.getFirstDayOfLastMonth();
  }

  getBillInfo(partnerLead: PartnerLead): string {
    if (!partnerLead.bills || partnerLead.bills.length === 0) {
      return 'Bitte Rechnung eintragen';
    }

    const lastBillDate = this.getLastBillDate(partnerLead.bills);
    const formattedDate = DateHelper.getFormattedDate(lastBillDate);

    if (this.isBillOverdue(partnerLead)) {
      return `Bitte Rechnung eintragen (Letzte Rechnung: ${formattedDate})`;
    }

    return `Letzte Rechnung: ${formattedDate}`;
  }

  private getLastBillDate(bills: Array<{ bill_date: string }>): Date {
    return bills.reduce((latest: Date, bill) => {
      const billDate = new Date(bill.bill_date);
      return billDate > latest ? billDate : latest;
    }, new Date(0));
  }
  protected readonly PartnerCancelReason = PartnerCancelReason;
  protected readonly PartnerLeadStatus = PartnerLeadStatus;
}
