import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { SemanticSearchService } from '../../services/semantic-search.service';
import { OptionlistsService } from '../../services/option-lists/optionlists.service';
import { OptionlistOption } from '../../models/optionlist/optionlist-option';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Lead } from '../../models/leads/lead';
import { LeadDetail } from '../../models/leads/lead-detail';
import { LeadBackendElement } from '../../models/lead-backend-element';
import { TargetingDetail } from '../../models/targeting/targeting-detail';
import { LeadField } from '../../models/leads/lead-field';
import { LeadFieldService } from '../../services/leadfields/leadfields.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'lib-semantic-search-selection',
  templateUrl: './semantic-search-selection.component.html',
  styleUrls: ['./semantic-search-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SemanticSearchSelectionComponent implements OnInit, OnDestroy {
  @Input() searchIndex: string = '';
  @Input() searchOptionlistKeyword: string = '';
  @Input() lead: Lead = new Lead({}); // kann bei Bedarf übergeben werden. Wenn er übergeben wird, dann werden die vorausgewählten Optionen aus dem Lead übernommen
  @Input() targetingDetail: TargetingDetail = new TargetingDetail({}); // kann bei Bedarf übergeben werden. Wenn er übergeben wird, dann werden die vorausgewählten Optionen aus dem Lead übernommen

  @Output() selectedOptions = new EventEmitter<OptionlistOption[]>();

  semanticSearchForm: FormGroup = new FormGroup({});
  allOptions: OptionlistOption[] = [];
  loadingInProgress = false;
  private destroy$ = new Subject<void>();
  optionlistOptions$: BehaviorSubject<OptionlistOption[]> = new BehaviorSubject<
    OptionlistOption[]
  >([]);

  constructor(
    private leadFieldService: LeadFieldService,
    private formBuilder: FormBuilder,
    private semanticSearchService: SemanticSearchService,
    private optionlistService: OptionlistsService,
    private storageService: StorageService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.initSubscriptions();
    this.setDefaultOptionsByLeadDetails();
    this.setDefaultOptionsByTargetingDetails();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm(): void {
    this.semanticSearchForm = this.formBuilder.group({
      searchType: ['semantic'],
      userSemanticSearchString: [],
      semanticSearchStream: [],
      filterSearchString: [],
      selectedOptions: [[]],
      selectedOptionsGroups: [[]],
    });
  }

  initSubscriptions(): void {
    // Subscription für die Filterung der Optionen
    this.semanticSearchForm
      .get('filterSearchString')
      ?.valueChanges.pipe(
        debounceTime(200),
        map((value) => {
          return this.stringFilter(value);
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((filteredOptions) => {
        this.optionlistOptions$.next(filteredOptions);
      });

    // Subscription für die semantische Suche
    this.semanticSearchForm
      .get('semanticSearchStream')
      ?.valueChanges.pipe(
        distinctUntilChanged(),
        map((term) => {
          this.semanticFilter(term);
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    // Subscription für die Auswahl der Optionen
    this.loadInspectorSpecialFields()
      .pipe(takeUntil(this.destroy$))
      .subscribe((options) => {
        options = options.sort((a, b) => a.name.localeCompare(b.name));

        this.allOptions = options; // Speichern der initialen Liste
        this.optionlistOptions$.next(options); // Initialen Zustand setzen
      });

    // Subscription für die Auswahl der Optionen
    this.semanticSearchForm
      .get('selectedOptions')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((selectedOptions: OptionlistOption[]) => {
        this.updateSelectedOptionsGroups(selectedOptions);
      });

    // Subscription für die Auswahl der Suchart
    this.semanticSearchForm
      .get('searchType')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.optionlistOptions$.next(this.allOptions);
      });
  }

  setDefaultOptionsByTargetingDetails() {
    if (
      !this.targetingDetail?.conditions?.length ||
      this.targetingDetail?.conditions[0]?.condition_type !== 3 ||
      !this.targetingDetail?.conditions[0]?.fields?.length
    )
      return;

    const fieldId = this.targetingDetail.conditions[0].fields[0].field_id;

    this.leadFieldService
      .show(Number(fieldId))
      .subscribe((leadField: LeadField) => {
        let savedOptionlistOptions: OptionlistOption[] = [];

        const selectedOptionIds = new Set(
          this.targetingDetail.conditions[0].fields.map(
            (leadField: any) => leadField.val,
          ),
        );

        for (const optionId of selectedOptionIds) {
          const option = this.allOptions.filter(
            (option) => option.option_id === Number(optionId),
          );

          if (option) {
            savedOptionlistOptions.push(option[0]);
          }
        }

        if (savedOptionlistOptions?.length > 0 && savedOptionlistOptions[0]) {
          this.semanticSearchForm
            .get('selectedOptions')
            ?.setValue(savedOptionlistOptions);
        }
      });
  }

  setDefaultOptionsByLeadDetails() {
    if (!this.lead?.lead_details?.length) return;

    const distinctLeadFieldIds = new Set(
      this.lead.lead_details.map((item: LeadDetail) => item.lead_field_id),
    );
    let savedOptionlistOptions: OptionlistOption[] = [];

    for (const leadFieldId of distinctLeadFieldIds) {
      const leadBackendElement =
        this.lead.lead_backend.lead_backend_elements.find(
          (leadBackendElement1: LeadBackendElement) =>
            leadBackendElement1.lead_field.id === leadFieldId,
        );

      // Nur semantische Suchfelder sind relevant
      if (!leadBackendElement || leadBackendElement.lead_field.type !== 6)
        continue;

      const leadFieldType6 = this.lead.lead_details.filter(
        (detail: LeadDetail) => detail.lead_field_id === leadFieldId,
      );
      const selectedOptionIdsFromLeadFieldType6 = new Set(
        leadFieldType6.map((leadDetail: LeadDetail) => leadDetail.value),
      );

      for (const optionId of selectedOptionIdsFromLeadFieldType6) {
        const option = this.allOptions.filter(
          (option) => option.option_id === Number(optionId),
        );

        if (option) {
          savedOptionlistOptions.push(option[0]);
        }
      }

      if (savedOptionlistOptions?.length > 0 && savedOptionlistOptions[0]) {
        this.semanticSearchForm
          .get('selectedOptions')
          ?.setValue(savedOptionlistOptions);
      }
    }
  }

  updateSelectedOptionsGroups(selectedOptions: OptionlistOption[]): void {
    const selectedOptionsGroupsControl = this.semanticSearchForm.get(
      'selectedOptionsGroups',
    );
    let groups: OptionlistOption[][] =
      selectedOptionsGroupsControl?.value || []; // Ein Array von Arrays

    if (selectedOptions) {
      // Bestimme den aktuellen Zustand, um neue und entfernte Optionen zu identifizieren
      const currentOptions = groups.flat();
      const addedOptions = selectedOptions.filter(
        (option) => !currentOptions.includes(option),
      );
      const removedOptions = currentOptions.filter(
        (option) => !selectedOptions.includes(option),
      );

      // Entferne nicht mehr gewählte Optionen aus allen Gruppen
      groups = groups
        .map((group) =>
          group.filter((option) => !removedOptions.includes(option)),
        )
        .filter((group, index) => group.length > 0 || index === 0); // Behalte leere Standardgruppe

      // Prüfen, ob Gruppen richtig initialisiert sind
      if (
        groups.length === 0 ||
        (groups.length === 1 &&
          groups[0].length === 0 &&
          addedOptions.length > 1)
      ) {
        // Initialisierung bei ersten mehreren Optionen
        groups.push([]); // Neue leere Standardgruppe einfügen
      }

      // Hinzufügen der neuen Optionen
      if (addedOptions.length === 1) {
        // Einzelnes Optionen immer zur Standardgruppe hinzufügen
        groups[0].push(addedOptions[0]);
      } else if (addedOptions.length > 1) {
        // Mehrere Optionen in eine neue Gruppe einfügen
        groups.push(addedOptions); // Neue Gruppe am Ende hinzufügen
      }

      // Aktualisiere das Array im Formular
      selectedOptionsGroupsControl?.setValue(groups);
    }

    this.emitSelectedOptions(selectedOptions);
  }

  emitSelectedOptions(selectedOptions: OptionlistOption[]): void {
    const selectedOptionsGroups = this.semanticSearchForm.get(
      'selectedOptionsGroups',
    )?.value;

    if (!selectedOptions) {
      this.selectedOptions.emit([]);
    } else {
      this.selectedOptions.emit(selectedOptionsGroups.flat());
    }
  }

  // startet die semantische Suche
  semanticFilter(searchTerm: string): void {
    this.loadingInProgress = true;
    this.updateDisabledState();

    // Wenn kein Suchbegriff vorhanden ist, dann breche die Suche ab
    if (!searchTerm) {
      this.loadingInProgress = false;
      this.updateDisabledState();
      return;
    }

    // Starte die semantische Abfrage an das Backend
    this.semanticSearchService
      .searchByString(
        searchTerm,
        this.searchIndex,
        this.searchOptionlistKeyword,
      )
      .pipe(
        map((semanticSearchResultArray) => {
          // Erstelle ein Map-Objekt für die Scores
          const scoreMap = new Map(
            semanticSearchResultArray.map((item) => [
              item.option_id,
              item.score,
            ]),
          );

          // Filtere und transformiere die Optionen
          const newOptions = this.allOptions
            .filter((option) => scoreMap.has(option.option_id))
            .map((option) => ({
              ...option,
              pinecone_score: scoreMap.get(option.option_id),
            }));

          // Hole die aktuellen ausgewählten Optionen
          const currentOptions =
            this.semanticSearchForm.get('selectedOptions')?.value || [];

          // Kombiniere alte und neue Optionen und entferne Duplikate
          const combinedOptions = [...currentOptions, ...newOptions];
          const uniqueOptions = Array.from(
            new Set(combinedOptions.map((option) => option.option_id)),
          ).map((id) =>
            combinedOptions.find((option) => option.option_id === id),
          );

          // Aktualisiere die Liste der ausgewählten Optionen
          this.semanticSearchForm
            .get('selectedOptions')
            ?.setValue(uniqueOptions);
        }),
        finalize(() => {
          this.loadingInProgress = false;
          this.updateDisabledState();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  // setzt den disabled state der Formularfelder
  updateDisabledState() {
    const controls = [
      'userSemanticSearchString',
      'semanticSearchStream',
      'filterSearchString',
      'selectedOptions',
    ];
    controls.forEach((control) => {
      if (this.loadingInProgress) {
        this.semanticSearchForm.get(control)?.disable({ emitEvent: false });
      } else {
        this.semanticSearchForm.get(control)?.enable({ emitEvent: false });
      }
    });
  }

  removeOption(option: OptionlistOption): void {
    const currentOptions =
      this.semanticSearchForm.get('selectedOptions')?.value || [];

    const newOptions = currentOptions.filter(
      (currentOption: OptionlistOption) =>
        currentOption.option_id !== option.option_id,
    );

    this.semanticSearchForm.get('selectedOptions')?.setValue(newOptions);
  }

  // Textfilter
  stringFilter(searchString: string): OptionlistOption[] {
    let filteredOptions: OptionlistOption[] = [];
    if (!searchString) {
      filteredOptions = this.allOptions;
    } else {
      const normalizedSearchString = searchString.toLowerCase();
      filteredOptions = this.allOptions.filter((option) =>
        option.name.toLowerCase().includes(normalizedSearchString),
      );
    }

    // Hole die aktuellen ausgewählten Optionen und stelle sicher, dass sie in der Liste bleiben
    const currentSelectedOptions =
      this.semanticSearchForm.get('selectedOptions')?.value || [];
    const combinedOptions = [
      ...new Set([...filteredOptions, ...currentSelectedOptions]),
    ];

    return combinedOptions;
  }

  // leere das Filterinput
  clearFilter(): void {
    this.semanticSearchForm.get('filterSearchString')?.setValue('');
  }

  // Laden der Optionen aus dem Backend
  loadInspectorSpecialFields(): Observable<OptionlistOption[]> {
    const cachedOptions = this.storageService.getFromFromLocalStorage(
      'inspector_special_fields',
    );
    if (cachedOptions) {
      return of(cachedOptions);
    } else {
      return this.optionlistService
        .optionlistByKeyword('inspector_special_fields')
        .pipe(
          tap((options) =>
            this.storageService.setInLocalStorage(
              'inspector_special_fields',
              options,
            ),
          ),
          catchError((error) => {
            console.error(
              'Fehler beim Laden der Inspector-Special-Fields:',
              error,
            );
            return of([]);
          }),
        );
    }
  }

  trackById(index: number, item: OptionlistOption) {
    return item.id;
  }

  removeGroup(index: number) {
    const selectedOptionsGroupsControl = this.semanticSearchForm.get(
      'selectedOptionsGroups',
    );
    const selectedOptionsControl =
      this.semanticSearchForm.get('selectedOptions');

    const groups: OptionlistOption[][] =
      selectedOptionsGroupsControl?.value || [];
    const selectedOptions: OptionlistOption[] =
      selectedOptionsControl?.value || [];

    // Entferne die Optionen der Gruppe aus der Gesamtliste der ausgewählten Optionen
    const groupToRemove = groups[index];
    const updatedSelectedOptions = selectedOptions.filter(
      (option) => !groupToRemove.includes(option),
    );

    // Aktualisiere die ausgewählten Optionen im Formular
    selectedOptionsControl?.setValue(updatedSelectedOptions);

    // Entferne den Inhalt der Gruppe, behalte aber die Gruppe selbst, falls es die Standardgruppe ist
    if (index === 0) {
      groups[0] = [];
    } else {
      groups.splice(index, 1);
    }
    selectedOptionsGroupsControl?.setValue(groups);
  }

  startSearch() {
    if (
      this.semanticSearchForm.get('searchType')?.value === 'semantic' &&
      this.semanticSearchForm.get('userSemanticSearchString')?.value
    ) {
      this.semanticSearchForm
        .get('semanticSearchStream')
        ?.setValue(
          this.semanticSearchForm.get('userSemanticSearchString')?.value,
        );
    }
  }
}
