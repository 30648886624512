<app-card>
  <app-card-head pageTitle="Leads">
    <div class="headline-container">
      <!-- Linke Seite -->
      <div class="left-section">
        <div class="headline-element">
          <lib-slide-toggle
            style="margin-bottom: 10px"
            [value]="(useNewView$ | async) ?? false"
            (valueChange)="toggleView()"
          >
            Testen Sie die neue Lead-Liste
          </lib-slide-toggle>
        </div>
      </div>

      <div class="center-section" *ngIf="hasMissingBills$ | async">
        <div class="bill-info bill-warning">
          <mat-icon class="bill-info-icon">warning</mat-icon>
          <span>Es gibt Leads mit fehlenden Rechnungen</span>
        </div>
      </div>

      <!-- Rechte Seite -->
      <div class="right-section">
        <div class="headline-element">
          <button mat-stroked-button color="primary" (click)="onExportExcel()">
            <i class="bi bi-cloud-download"></i> Excel herunterladen
          </button>
        </div>

        <div class="headline-element">
          <app-tooltip
            [text]="
              'Der Score zeigt für welches Leadpotenzial Sie sich qualifizieren.<br /><br />Je höher der Score, desto mehr Leads erhalten Sie von uns.<br /><br />Den Score können Sie erhöhen durch schnelle Reaktionszeiten und Rückmeldungen zu den einzelnen Leads.<br /><br /><b>Leads mit dem Status -Neu- wirken sich negativ auf Ihren Score aus</b>'
            "
          >
            <div class="lead-list-headline-element">
              <mat-progress-bar
                mode="determinate"
                [value]="partnerScore$ | async"
                color="primary"
              ></mat-progress-bar>
              <div>{{ partnerScore$ | async }}%</div>
            </div>
          </app-tooltip>
        </div>
      </div>
    </div>

    <!-- Filter Bar -->
    <lib-filter-bar [filterSettings]="filterBarSettings"></lib-filter-bar>
  </app-card-head>
  <app-card-body>
    <lib-table
      [filter]="filter"
      [tableId]="'partnerLeadListNew'"
      [initialPageSize]="50"
      [endpoint]="'partner_leads/'"
      [showSearchbar]="true"
      (displayedDataChanged)="onDisplayedDataChanged($event)"
      (clickedElementChanged)="onLeadSelected($event)"
      [additionalSearchFields]="[
        'addresses.full_text_index',
        'lead_number',
        'offer_number'
      ]"
    >
      <lib-table-column
        [columnName]="'lead_number'"
        [isSearchable]="true"
        [isSortable]="false"
        [designPointer]="true"
        [title]="'Leadnummer'"
        [customTemplate]="leadNumberTemplate"
      >
        <ng-template #leadNumberTemplate let-partnerLead>
          <div class="lead-number-container">
            <div class="warning-wrapper" *ngIf="isBillOverdue(partnerLead)">
              <mat-icon
                class="bill-warning-icon"
                [matTooltip]="getBillWarningTooltip(partnerLead)"
                matTooltipClass="multiline-tooltip"
              >
                warning
              </mat-icon>
            </div>
            <span class="lead-number">#{{ partnerLead.lead_number }}</span>
          </div>
        </ng-template>
      </lib-table-column>
      <lib-table-column
        [defaultSort]="'desc'"
        [columnName]="'send_at'"
        [pipe]="{ name: 'date', args: ['dd.MM.yyyy HH:mm'] }"
        [suffix]="'Uhr'"
        [isSearchable]="false"
        [designPointer]="true"
        [title]="'Eingangsdatum'"
      >
      </lib-table-column>

      <lib-table-column
        [columnName]="'status'"
        [title]="'Status'"
        [isSortable]="true"
        [isSearchable]="false"
        [designPointer]="true"
        [customTemplate]="statusTemplate"
      >
        <ng-template #statusTemplate let-partnerLead>
          <div class="status-select-wrapper">
            <div
              [class]="
                'status-select ' + getStatusClass(partnerLead.status.option_id)
              "
              [matMenuTriggerFor]="statusMenu"
              (click)="$event.stopPropagation()"
            >
              <div class="status-trigger">
                <span class="status-dot"></span>
                {{ partnerLead.status.name }}
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>

            <mat-menu #statusMenu="matMenu" class="status-dropdown">
              <button
                mat-menu-item
                *ngFor="let option of statusOptions$ | async"
                (click)="
                  $event.stopPropagation(); setLeadStatus(option, partnerLead)
                "
                [class]="getStatusClass(option.value)"
              >
                <div class="status-option">
                  <span class="status-dot"></span>
                  {{ option.name }}
                </div>
              </button>
            </mat-menu>
          </div>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [columnName]="'address.company_name'"
        [title]="'Name'"
        [isSortable]="true"
        [isSearchable]="false"
        [designPointer]="true"
        [customTemplate]="nameTemplate"
      >
        <ng-template #nameTemplate let-partnerLead>
          <span>
            {{
              addressService.getDisplayName(
                partnerLead.address.company_name,
                partnerLead.address.name
              )
            }}
          </span>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [columnName]="'lead_type.name'"
        [isSearchable]="false"
        [isSortable]="true"
        [title]="'Leadart'"
        [designPointer]="true"
      >
      </lib-table-column>

      <lib-table-column
        [columnName]="'address.postcode'"
        [title]="'PLZ'"
        [isSortable]="true"
        [isSearchable]="true"
        [designPointer]="true"
        [customTemplate]="postcodeTemplate"
      >
        <ng-template #postcodeTemplate let-partnerLead>
          {{ partnerLead.address.postcode }} {{ partnerLead.address.city }}
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [columnName]="'address.street'"
        [title]="'Straße'"
        [isSortable]="true"
        [isSearchable]="true"
        [designPointer]="true"
      >
      </lib-table-column>

      <lib-table-column
        [columnName]="'next_reminder_date'"
        [title]="'Nächste Erinnerung'"
        [isSortable]="true"
        [isSearchable]="false"
        [designPointer]="true"
        [customTemplate]="remindersTemplate"
      >
        <ng-template #remindersTemplate let-partnerLead>
          <ng-container
            *ngIf="partnerLead.reminders?.length > 0; else noReminder"
          >
            <div
              class="reminder-badge"
              [ngClass]="getBadgeClass(partnerLead.reminders[0])"
            >
              <div class="reminder-content">
                <mat-icon [inline]="true">
                  {{ getReminderIcon(partnerLead.reminders[0]) }}
                </mat-icon>
                {{ formatDate(partnerLead.reminders[0].reminder_date) }}
                &nbsp;({{ partnerLead.reminders[0].reminder_type_option.name }})
              </div>
            </div>
          </ng-container>

          <ng-template #noReminder>
            <div class="reminder-badge no-reminder">
              <div class="reminder-content">
                <mat-icon [inline]="true">notifications_off</mat-icon>
                Keine Erinnerung
              </div>
            </div>
          </ng-template>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [columnName]="'offer_number'"
        [title]="'Referenznummer'"
        [isSortable]="true"
        [isSearchable]="true"
        [designPointer]="true"
      >
      </lib-table-column>
    </lib-table>
  </app-card-body>
</app-card>
