<lib-split-view
  [initialLeftPanelWidth]="initialPanelWidth"
  [minLeftPanelWidth]="250"
  [maxLeftPanelWidth]="800"
  [rightPanelMinWidth]="700"
  [showRightPanel]="!!(selectedLeadId$ | async)"
  (leftPanelWidthChange)="onWidthChange($event)"
>
  <app-lead-list-overview leftPanel></app-lead-list-overview>
  <app-lead-list-detail-view
    rightPanel
    *ngIf="selectedLeadId$ | async"
    [leadId$]="selectedLeadId$"
  >
  </app-lead-list-detail-view>
</lib-split-view>
