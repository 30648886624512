import { Component } from '@angular/core';

@Component({
  selector: 'lib-basic-badge-example',
  template: AngularIconsExample.HTML_CODE,
})
export class AngularIconsExample {
  static HTML_CODE = `
    <div class="flex gap-4 items-center">
  <!-- Standard Icon -->
  <mat-icon>home</mat-icon>

  <!-- Icon mit benutzerdefinierter Farbe -->
  <mat-icon class="text-primary">favorite</mat-icon>

  <!-- Icon mit benutzerdefinierter Größe -->
  <mat-icon class="text-3xl">account_circle</mat-icon>

  <!-- Icon mit Animation -->
  <mat-icon class="animate-spin">refresh</mat-icon>

  <!-- Icon mit Badge -->
  <mat-icon matBadge="8" matBadgeColor="accent">notifications</mat-icon>
</div>

<!-- Icon Gruppe mit Beschriftung -->
<div class="grid grid-cols-4 gap-4 mt-8">
  <div class="flex flex-col items-center">
    <mat-icon>dashboard</mat-icon>
    <span class="text-sm mt-2">Dashboard</span>
  </div>
  <div class="flex flex-col items-center">
    <mat-icon>settings</mat-icon>
    <span class="text-sm mt-2">Einstellungen</span>
  </div>
  <div class="flex flex-col items-center">
    <mat-icon>person</mat-icon>
    <span class="text-sm mt-2">Profil</span>
  </div>
  <div class="flex flex-col items-center">
    <mat-icon>help</mat-icon>
    <span class="text-sm mt-2">Hilfe</span>
  </div>
</div>
  `;
}
