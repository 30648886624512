import { Injectable } from '@angular/core';
import { HttpHandlerService } from '../http-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PartnerLeadBackendService {
  endpoint: string = 'get_lead_backend_by_lead_type';

  constructor(
    private httpHandler: HttpHandlerService,
    protected http: HttpClient,
  ) {}

  list(httpParams: any = {}): Observable<any> {
    return this.httpHandler
      .list(this.endpoint, httpParams)
      .pipe(map((response) => response.data));
  }
}
