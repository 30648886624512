import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-head',
  templateUrl: './card-head.component.html',
  styleUrls: ['./card-head.component.scss'],
})
export class CardHeadComponent {
  @Input() pageTitle: string = '';
  @Input() pageSubtitle: string = '';

  constructor() {}
}
