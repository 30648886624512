<app-card>
  <app-card-head [pageTitle]="this.pageTitle">
    <div class="p-1" *ngIf="showCreateButton">
      <button (click)="enterInvoice()" class="btn btn-success btn-success">
        Neuen Umsatz erfassen
      </button>
    </div>
  </app-card-head>
  <app-card-body *ngIf="partnerLeadPrice.id > 0">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">PROVIONSART</th>
          <th scope="col">PROVIONSSATZ</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td scope="row">Nettorechnungssumme</td>
          <td>{{ partnerLeadPrice.price }} %</td>
        </tr>
        <ng-container *ngIf="partnerLeadPrice.additional_commissions">
          <tr
            *ngFor="
              let additionalCommission of partnerLeadPrice.additional_commissions
            "
          >
            <td>{{ additionalCommission.additional_price_name }}</td>
            <td>{{ additionalCommission.additional_price_commission }} %</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </app-card-body>
  <app-card-body>
    <lib-table
      [filter]="filter"
      [restriction]="restriction"
      [tableId]="'partnerBillList'"
      [initialPageSize]="50"
      [endpoint]="'partner_bills/'"
      [showSearchbar]="true"
      [additionalSearchFields]="['partner_lead.address.name']"
    >
      <lib-table-column
        [defaultSort]="'desc'"
        [columnName]="'created_at'"
        [title]="'Erstellt am'"
        [isSearchable]="false"
        [pipe]="{ name: 'date', args: ['dd.MM.yyyy HH:mm'] }"
        [suffix]="'Uhr'"
      ></lib-table-column>

      <lib-table-column
        [columnName]="'bill_date'"
        [title]="'Rechnungsdatum'"
        [isSearchable]="false"
        [pipe]="{ name: 'date', args: ['dd.MM.yyyy'] }"
      ></lib-table-column>

      <lib-table-column
        [columnName]="'bill_number'"
        [title]="'Rechnungsnummer'"
        [isSortable]="false"
      ></lib-table-column>

      <lib-table-column
        [isSortable]="false"
        [columnName]="'partner_lead.address.company_name'"
        [title]="'Kunde'"
        [customTemplate]="partnerBillCustomerTemplate"
      >
        <ng-template #partnerBillCustomerTemplate let-partnerBill>
          <ng-container *ngIf="partnerBill.partner_lead.address.company_name">
            <span> {{ partnerBill.partner_lead.address.company_name }}</span>
          </ng-container>
          <ng-container *ngIf="!partnerBill.partner_lead.address.company_name">
            {{ partnerBill.partner_lead.address.name }}
          </ng-container>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [columnName]="'comment'"
        [title]="'Kommentar'"
        [isSortable]="false"
      ></lib-table-column>

      <lib-table-column
        [columnName]="'bill_status'"
        [title]="'Rechnungsstatus'"
        [customTemplate]="partnerBillStatusTemplate"
      >
        <ng-template #partnerBillStatusTemplate let-partnerBill>
          <div
            class="partner-bill-list-entry-margin"
            *ngIf="partnerBill.bill_status === 1"
          >
            <span class="badge fs-7 bg-primary">Erfasst</span>
          </div>
          <div
            class="partner-bill-list-entry-margin"
            *ngIf="partnerBill.bill_status === 2"
          >
            <span class="badge fs-7 bg-success">Abgerechnet</span>
          </div>
          <div
            class="partner-bill-list-entry-margin"
            *ngIf="partnerBill.bill_status === 3"
          >
            <span class="badge fs-7 bg-danger">Storniert</span>
          </div>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [columnName]="'bill_sum'"
        [title]="'Rechnungssumme'"
        [summableColumnName]="'bill_sum'"
        [summableColumnUnit]="'price'"
        [customTemplate]="partnerBillSummeTemplate"
      >
        <ng-template #partnerBillSummeTemplate let-partnerBill>
          <span
            [style.color]="partnerBill.bill_sum < 0 ? 'red' : null"
            [style.font-weight]="partnerBill.bill_sum < 0 ? 'bold' : null"
          >
            {{
              partnerBill.bill_sum
                | dynamicPipe : { name: 'currency', args: ['€'] }
            }}
          </span>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [isSortable]="false"
        [isSearchable]="false"
        [title]="'Aktion'"
        [customTemplate]="aktionTemplate"
      >
        <ng-template #aktionTemplate let-partnerBill>
          <div class="partner-bill-list-entry-margin">
            <button
              class="btn btn-success btn-sm mb-1 mx-2 btn-primary"
              type="button"
              (click)="editPartnerBill(partnerBill)"
            >
              <span *ngIf="partnerBill.bill_status === BillStatus.Abgerechnet"
                >Anzeigen</span
              >
              <span *ngIf="partnerBill.bill_status !== BillStatus.Abgerechnet"
                >Bearbeiten</span
              >
            </button>
          </div>
          <button
            style="margin-bottom: 5px"
            *ngIf="partnerBill.bill_status == BillStatus.Erfasst"
            class="btn btn-danger btn-sm mx-2"
            (click)="deleteBill(partnerBill)"
          >
            Löschen
          </button>
        </ng-template>
      </lib-table-column>
    </lib-table>
  </app-card-body>
</app-card>
