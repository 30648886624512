import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { map, Subject, Subscription, takeUntil } from 'rxjs';
import { FilterSetting } from '@lib/models/filter-setting';
import { SelectList } from '@lib/models/select-list';
import { SnackBarService } from '@lib/services/snack-bar/snack-bar.service';
import { PartnerLeadService } from '@lib/services/partner-lead/partner-lead.service';
import { NewFilterService } from '@lib/services/filter/new-filter.service';
import { TableComponent } from '@lib/components/table/table.component';
import { SplitViewStateService } from '../../services/split-view-state.service';
import { PartnerLead } from '@lib/models/partner-lead';
import { FormField, ModalInputData } from '@lib/models/modal';
import { CreateFormGroupHelper } from '@lib/helpers/create-form-group-helper';
import { ModalComponent } from '@lib/components/modal/modal.component';
import { ModalFormFieldType } from '@lib/enum-collection';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-chance-list-overview',
  templateUrl: './chance-list-overview.component.html',
  styleUrls: ['./chance-list-overview.component.scss'],
})
export class ChanceListOverviewComponent implements OnInit, OnDestroy {
  @ViewChild(TableComponent) tableComponent!: TableComponent;

  filterBarSettings: FilterSetting[] = [];
  private destroy$ = new Subject<void>();
  public filterSubscription: Subscription = new Subscription();

  selectedId$ = this.splitViewStateService.selectedChance$.pipe(
    map((chance) => chance?.id),
  );

  filter = {
    lead_type_id: null,
  };

  constructor(
    private splitViewStateService: SplitViewStateService,
    private filterService: NewFilterService,
    private snackbarService: SnackBarService,
    private partnerLeadService: PartnerLeadService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.initFilter();

    this.splitViewStateService.reloadTable$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.reloadTable();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initFilter() {
    this.filterSubscription = this.filterService.filter$
      .pipe(takeUntil(this.destroy$))
      .subscribe((filter) => {
        this.setFilter(filter);
      });

    this.filterBarSettings = [
      new FilterSetting({
        filter: SelectList.LeadType,
        multiSelect: true,
        placeholder: 'Leadart',
      }),
    ];

    this.filterService.getFilter();
  }

  setFilter(filter: any): void {
    const filterData = filter?.filter ?? filter;

    this.filter = {
      ...this.filter,
      lead_type_id: filterData?.[SelectList.LeadType],
    };
  }

  onChanceSelected(chance: any) {
    this.splitViewStateService.setSelectedChance(chance);
  }

  onDisplayedDataChanged(chances: PartnerLead[]) {
    this.splitViewStateService.updatePartnerChances(chances);
  }

  acceptLead(lead: any) {
    this.partnerLeadService.acceptPartnerLead(lead).subscribe({
      next: () => {
        this.snackbarService.openSnackBar(
          'Lead erfolgreich angenommen',
          'success',
        );
        this.tableComponent.reloadData();
      },
      error: () => {
        this.snackbarService.openSnackBar(
          'Fehler beim Annehmen des Leads',
          'error',
        );
      },
    });
  }

  declineLead(partnerLead: PartnerLead) {
    const formFields = this.getDeclineModalFormFields();

    const inputData: ModalInputData = {
      headline: 'Lead ablehnen - Verlustgrund auswählen',
      formGroup: CreateFormGroupHelper.createFormGroupForModal(formFields),
      formFields: formFields,
      acceptButtonText: 'Speichern',
      declineButtonText: 'Abbrechen',
    };

    this.dialog
      .open(ModalComponent, {
        disableClose: true,
        data: inputData,
      })
      .afterClosed()
      .subscribe((modalResponse) => {
        if (!modalResponse) return;

        if (modalResponse['decline_reasons']) {
          this.partnerLeadService
            .declinePartnerLead(partnerLead, modalResponse['decline_reasons'])
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: () => {
                this.snackbarService.openSnackBar(
                  'Lead wurde erfolgreich abgelehnt.',
                  'success',
                );

                this.reloadTable();
              },
              error: (err) => {
                console.error(err);
                this.snackbarService.openSnackBar(
                  'Lead konnte nicht abgelehnt werden. Bitte versuchen Sie es erneut.',
                  'warning',
                );
              },
            });
        }
      });
  }

  reloadTable() {
    this.tableComponent.reloadData();
  }

  getDeclineModalFormFields(): FormField[] {
    return [
      {
        type: ModalFormFieldType.select,
        label: 'Ablehnungsgrund auswählen',
        name: 'decline_reasons',
        validation: { required: true },
        optionlistKeyword: 'decline_reasons',
      },
    ];
  }
}
