import { Component, OnInit } from '@angular/core';
import { Lead } from '../../../../../models/leads/lead';
import { LeadService } from '../../../../../services/lead/lead.service';

@Component({
  selector: 'lib-basic-badge-example',
  template: BasicContextMenuExample.HTML_CODE,
})
export class BasicContextMenuExample implements OnInit {
  static HTML_CODE = `
    <lib-context-menu [lead]="lead"></lib-context-menu>
  `;

  lead: Lead = new Lead({});

  constructor(private leadService: LeadService) {}

  ngOnInit() {
    this.leadService.show(1).subscribe((lead) => {
      this.lead = lead;
    });
  }
}
