import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private _production: boolean = false;
  private _apiUrl: string = '';
  private _appVersion: string = '';

  constructor() {}

  get apiUrl(): string {
    return this._apiUrl;
  }

  set apiUrl(value: string) {
    this._apiUrl = value;
  }
  get production(): boolean {
    return this._production;
  }

  set production(value: boolean) {
    this._production = value;
  }

  get appVersion(): string {
    return this._appVersion;
  }

  set appVersion(value: string) {
    this._appVersion = value;
  }
}
