import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() text: string = '';
  visible: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  showTooltip() {
    this.visible = true;
  }

  hideTooltip() {
    this.visible = false;
  }
}
