<app-card>
  <app-card-head pageTitle="Historie">
    <lib-feed-header
      (feedUpdated)="loadFeed()"
      [lead$]="lead$"
    ></lib-feed-header>
  </app-card-head>

  <app-card-body>
    <lib-feed-filter-bar
      [currentFilter]="(currentFilter$ | async) || undefined"
      (filterChanged)="applyFilter($event)"
    ></lib-feed-filter-bar>

    <lib-comment
      #comment
      [relatableId]="(lead$ | async)?.id ?? (partnerLead$ | async)?.id ?? 0"
      [relatableType]="getRelatableTypeForPost()"
      [displayVisibleInSalesrunnerButton]="(lead$ | async)?.status !== 3"
      [lead]="lead$ | async"
      [mainPost]="true"
      [partnerLead]="partnerLead$ | async"
      (commentAdded)="onComponentChanged()"
      (fileAdded)="onComponentChanged()"
    ></lib-comment>

    <lib-feed-item-list
      [lead]="(lead$ | async) || undefined"
      [feeds]="(feedItems$ | async) ?? []"
      [currentFilter]="(currentFilter$ | async) || undefined"
    >
    </lib-feed-item-list>

    <lib-feed-forwarded-list
      *ngIf="
        (lead$ | async)?.status === 3 &&
        ApplicationHelper.applicationName === Application.Leadmanager
      "
      [lead]="(lead$ | async) || undefined"
      [feeds]="(feedItems$ | async) ?? []"
      [currentFilter]="(currentFilter$ | async) || undefined"
    >
    </lib-feed-forwarded-list>
  </app-card-body>
</app-card>
