<div
  class="reminder-content"
  fxLayout="row"
  fxLayoutAlign="space-between start"
  *ngIf="reminder"
>
  <div class="reminder-info">
    <div
      *ngIf="reminder.reminder_status_option?.name"
      [ngClass]="getBadgeClass(reminder)"
      class="badge fs-7 fw-normal d-flex align-items-center mb-2"
    >
      <mat-icon class="badge-icon">info</mat-icon>
      <span>{{ reminder.reminder_status_option?.name }}</span>
      <span *ngIf="reminder.reminder_status_option_id === 2">
        am {{ formatDate(reminder.updated_at) }}</span
      >
    </div>

    <div *ngIf="reminder.reminder_date" class="info-item">
      <mat-icon>event</mat-icon>
      <span>{{ formatDate(reminder.reminder_date) }}</span>
    </div>
    <div *ngIf="reminder.reminder_type_option?.name" class="info-item">
      <mat-icon>label</mat-icon>
      <span>{{ reminder.reminder_type_option?.name }}</span>
    </div>
    <div
      *ngIf="
        reminder.partner_user?.first_name || reminder.partner_user?.last_name
      "
      class="info-item"
    >
      <mat-icon>person</mat-icon>
      <span
        >{{ reminder.partner_user?.first_name }}
        {{ reminder.partner_user?.last_name }}</span
      >
    </div>
    <div *ngIf="reminder.user?.name" class="info-item">
      <mat-icon>person</mat-icon>
      <span>{{ reminder.user?.name }}</span>
    </div>
  </div>

  <div class="chip-container">
    <mat-chip
      *ngIf="
        ApplicationHelper.applicationName === Application.Leadmanager &&
        getFeedSalesrunnerVisibilityHintByComment()
      "
      class="custom-chip"
    >
      <span [innerHTML]="getFeedSalesrunnerVisibilityHintByComment()"></span>
    </mat-chip>
  </div>
</div>
