import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, from, throwError } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { io, Socket } from 'socket.io-client';
import { Lead } from '../models/leads/lead';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class SocketIoService implements OnDestroy {
  private socket: Socket | null = null;
  private destroy$ = new Subject<void>();
  private socketReady$ = new BehaviorSubject<boolean>(false);

  // Öffentliche Event-Subjekte
  public newLeadEntry = new Subject<any>();
  public blockedLeads = new Subject<any>();
  public supportUpdates = new Subject<any>();
  public adminUpdates = new Subject<any>();

  // Verfolge abonnierte Kanäle und ihre Listener
  private channelListeners: Map<string, boolean> = new Map();

  private _isConnected = false;
  public get isConnected(): boolean {
    return this._isConnected;
  }

  constructor(private environmentService: EnvironmentService) {
    this.initializeSocketWhenEnvironmentReady();
  }

  // 1. INITIALISIERUNG
  private initializeSocketWhenEnvironmentReady(): void {
    this.environmentService.ready$
      .pipe(
        filter((ready) => ready),
        switchMap(() => this.initializeSocket()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private initializeSocket(): Observable<boolean> {
    if (!this.environmentService.socketIoUrl) {
      console.error('Socket.io URL ist nicht verfügbar!');
      return throwError(() => new Error('Socket.io URL nicht verfügbar'));
    }

    try {
      console.log(
        'Initialisiere Socket.io mit URL:',
        this.environmentService.socketIoUrl,
      );
      this.socket = io(this.environmentService.socketIoUrl);
      this.setupSocketEvents();
      return from(Promise.resolve(true)).pipe(
        tap(() => this.socketReady$.next(true)),
      );
    } catch (error) {
      console.error('Fehler beim Initialisieren des Socket.io-Clients:', error);
      return throwError(() => new Error('Socket.io Initialisierungsfehler'));
    }
  }

  private setupSocketEvents(): void {
    if (!this.socket) return;

    this.socket.on('connect', () => {
      this._isConnected = true;
      console.log('Verbindung zum WebSocket-Server hergestellt');
    });

    this.socket.on('disconnect', () => {
      this._isConnected = false;
      console.log('Verbindung zum WebSocket-Server getrennt');
    });

    this.socket.on('connect_error', (error) => {
      console.error('Socket.io Verbindungsfehler:', error);
    });
  }

  private async ensureSocketReady(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.socket) {
        resolve();
      } else {
        this.socketReady$
          .pipe(
            filter((ready) => ready),
            takeUntil(this.destroy$),
          )
          .subscribe({
            next: () => resolve(),
            error: (err) => reject(err),
          });
      }
    });
  }

  // 2. GENERISCHE KANAL-FUNKTIONEN
  private async connectToChannel<T>(
    channelName: string,
    subject: Subject<T>,
    logMessage: string,
    roomAction?: () => void,
  ): Promise<void> {
    await this.ensureSocketReady();
    if (!this.socket) return;

    if (this.channelListeners.has(channelName)) {
      this.socket.off(channelName);
      console.log(`Alter Listener für ${channelName} entfernt`);
    }

    console.log(logMessage);
    if (roomAction) roomAction();

    this.socket.on(channelName, (data: T) => {
      console.log(`Empfang auf Kanal ${channelName}:`, data);
      subject.next(data);
    });

    this.channelListeners.set(channelName, true);
  }

  private async disconnectFromChannel(
    channelName: string,
    roomAction?: () => void,
  ): Promise<void> {
    await this.ensureSocketReady();
    if (!this.socket) return;

    if (roomAction) roomAction();
    this.socket.off(channelName);
    this.channelListeners.delete(channelName);
    console.log(`Listener für ${channelName} entfernt`);
  }

  // 3. KANAL-SPEZIFISCHE METHODEN
  async connectLeadChannel(): Promise<void> {
    await this.connectToChannel<Lead>(
      'new-lead-entry',
      this.newLeadEntry,
      'Verbinde mit Lead-Kanal',
    );
  }

  async disconnectLeadChannel(): Promise<void> {
    await this.disconnectFromChannel('new-lead-entry');
  }

  async connectBlockedLeadsChannel(): Promise<void> {
    await this.connectToChannel<any>(
      'blocked-leads',
      this.blockedLeads,
      'Verbinde mit Block-Kanal',
    );
  }

  async disconnectBlockedLeadsChannel(): Promise<void> {
    await this.disconnectFromChannel('blocked-leads');
  }

  async connectSupportChannel(
    partnerUserId: number,
    customPrefix: string = 'support-channel',
  ): Promise<void> {
    const channelName = `${customPrefix}-${partnerUserId}`;
    const subject =
      customPrefix === 'support-channel'
        ? this.supportUpdates
        : this.adminUpdates;
    await this.connectToChannel<any>(
      channelName,
      subject,
      `Verbinde mit Kanal ${channelName}`,
      () => this.socket?.emit('join-room', channelName),
    );
  }

  async disconnectSupportChannel(
    partnerUserId: number,
    customPrefix: string = 'support-channel',
  ): Promise<void> {
    const channelName = `${customPrefix}-${partnerUserId}`;
    await this.disconnectFromChannel(channelName, () =>
      this.socket?.emit('leave-room', channelName),
    );
  }

  // 4. VERBINDUNGSSTEUERUNG
  async connect(): Promise<void> {
    await this.ensureSocketReady();
    if (this.socket && !this._isConnected) {
      this.socket.connect();
    }
  }

  async disconnect(): Promise<void> {
    await this.ensureSocketReady();
    if (this.socket && this._isConnected) {
      this.socket.disconnect();
    }
  }

  // 5. CLEANUP
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.newLeadEntry.complete();
    this.blockedLeads.complete();
    this.supportUpdates.complete();
    this.adminUpdates.complete();

    if (this.socket) {
      this.channelListeners.forEach((_, channel) => this.socket?.off(channel));
      this.channelListeners.clear();
      this.socket.disconnect();
      this.socket = null;
    }
  }
}
