import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Lead } from '../../models/leads/lead';
import { PartnerLead } from '../../models/partner-lead';
import { PartnerBill } from '../../models/partners/partner-bill';
import {
  ContextMenuItem,
  MenuBuilderParams,
} from '../../models/context-menu-item';
import { ContextMenuBuilderService } from '../../services/context-menu-builder.service';

@Component({
  selector: 'lib-context-menu-items',
  templateUrl: './context-menu-items.component.html',
  styleUrls: ['./context-menu-items.component.scss'],
})
export class ContextMenuItemsComponent implements OnChanges {
  @Input() untyped?: any;
  @Input() lead?: Lead | number;
  @Input() partner_lead?: PartnerLead | number;
  @Input() partner_bill?: PartnerBill | number;

  protected menuItems: ContextMenuItem[] = [];
  protected selectedFirstChildren: ContextMenuItem[] = [];
  protected selectedSecondChildren: ContextMenuItem[] = [];

  constructor(private readonly menuBuilder: ContextMenuBuilderService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasRelevantChanges(changes)) {
      const params: MenuBuilderParams = {
        untyped: this.untyped,
        lead: this.lead,
        partner_lead: this.partner_lead,
        partner_bill: this.partner_bill,
      };

      this.menuItems = this.menuBuilder.buildMenu(params);
      this.resetChildSelections();
    }
  }

  setFirstChildren(children: ContextMenuItem[]): void {
    this.selectedFirstChildren = children || [];
    this.selectedSecondChildren = [];
  }

  setSecondChildren(children: ContextMenuItem[]): void {
    this.selectedSecondChildren = children || [];
  }

  protected handleMenuClick(event: MouseEvent, item: ContextMenuItem): void {
    if (item.action) {
      item.action(event);
    }
  }

  protected handleMouseDown(event: MouseEvent, item: ContextMenuItem): void {
    if (event.button === 1) {
      event.preventDefault();
      if (item.action) {
        item.action(event);
      }
    }
  }

  private hasRelevantChanges(changes: SimpleChanges): boolean {
    return ['lead', 'partner_lead', 'partner_bill', 'untyped'].some(
      (key) => key in changes,
    );
  }

  private resetChildSelections(): void {
    this.selectedFirstChildren = [];
    this.selectedSecondChildren = [];
  }
}
