<app-card>
  <app-card-head [pageTitle]="pageTitle">
    <div class="p-1">
      <button
        (click)="onSubmit()"
        class="btn btn-success btn-success"
        [disabled]="!partnerUserForm.valid"
      >
        Speichern
      </button>
    </div>
  </app-card-head>
  <app-card-body [formGroup]="partnerUserForm">
    <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px">
      <mat-form-field fxFlex="grow">
        <mat-label>Passwort</mat-label>
        <input matInput type="password" formControlName="password" />
        <mat-hint align="start"
          >Neues Passwort (mindestens 8 Zeichen).
        </mat-hint>
      </mat-form-field>

      <mat-form-field fxFlex="grow">
        <mat-label>Passwort bestätigen</mat-label>
        <input
          matInput
          type="password"
          formControlName="passwordConfirmation"
        />
        <mat-hint align="start">Neues Passwort erneut eingeben.</mat-hint>
        <mat-error
          *ngIf="partnerUserForm.get('passwordConfirmation')?.errors?.['mustMatchPassword']"
        >
          Passwörter stimmen nicht überein!
        </mat-error>
      </mat-form-field>
    </div>
  </app-card-body>
</app-card>
