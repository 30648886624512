import { Component } from '@angular/core';

@Component({
  selector: 'lib-basic-daterange-example',
  template: BasicDaterangeExample.HTML_CODE,
})
export class BasicDaterangeExample {
  static HTML_CODE = `
    <div class="example-container">
      <app-daterange-picker-helper
        [startDate]="startDate"
        [endDate]="endDate"
        [isSetDefaultDateTime]="true"
        (dateRange)="onDateRangeChange($event)"
      >
      </app-daterange-picker-helper>

      <div class="mt-4" *ngIf="selectedRange">
        <p>Ausgewählter Zeitraum:</p>
        <p>Von: {{ selectedRange.start | date }}</p>
        <p>Bis: {{ selectedRange.end | date }}</p>
      </div>
    </div>
  `;

  startDate: Date = new Date();
  endDate: Date = new Date();
  selectedRange: any;

  onDateRangeChange(event: { start: Date; end: Date }) {
    this.selectedRange = event;
  }
}
