import {
  Component,
  Input,
  OnChanges,
  ViewChild,
  AfterViewInit,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'lib-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements OnChanges, AfterViewInit {
  @ViewChild(GoogleMap) map: GoogleMap | undefined;
  @Input() lat: number = 0;
  @Input() lng: number = 0;

  mapOptions: google.maps.MapOptions = {
    zoom: 15,
    center: { lat: 0, lng: 0 },
  };
  markerPosition: google.maps.LatLngLiteral | null = null;
  mapLoaded = false;
  marker: google.maps.Marker | null = null;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['lat'] || changes['lng']) {
      const numLat = Number(this.lat);
      const numLng = Number(this.lng);

      if (!isNaN(numLat) && !isNaN(numLng)) {
        this.mapOptions.center = { lat: numLat, lng: numLng };
        this.markerPosition = { lat: numLat, lng: numLng };

        if (this.map?.googleMap && this.mapLoaded) {
          this.updateMapAndMarker();
        }
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.map?.googleMap) {
        this.updateMapAndMarker();
      }
    }, 300);
  }

  onMapInitialized(map: google.maps.Map) {
    this.mapLoaded = true;

    const numLat = Number(this.lat);
    const numLng = Number(this.lng);

    if (!isNaN(numLat) && !isNaN(numLng)) {
      this.mapOptions.center = { lat: numLat, lng: numLng };
      this.markerPosition = { lat: numLat, lng: numLng };
      this.updateMapAndMarker();

      this.cdr.detectChanges();
    }
  }

  private updateMapAndMarker() {
    if (!this.map?.googleMap || !this.markerPosition) {
      return;
    }

    try {
      this.map.googleMap.setCenter(this.markerPosition);

      if (this.marker) {
        this.marker.setMap(null);
      }

      // google.maps.Marker ist veraltet, aber funktioniert weiterhin - soll nicht abgeschaltet werden
      this.marker = new google.maps.Marker({
        map: this.map.googleMap,
        position: this.markerPosition,
        title: 'Standort',
      });
    } catch (error) {
      console.error(
        'Fehler beim Aktualisieren der Karte oder des Markers:',
        error,
      );
    }
  }
}
