import { Component, OnInit } from '@angular/core';
import { FilterSetting } from '../../../../../models/filter-setting';
import { SelectList } from '../../../../../models/select-list';
import { NewFilterService } from '../../../../../services/filter/new-filter.service';

@Component({
  selector: 'lib-basic-filter-example',
  template: BasicFilterExample.HTML_CODE,
})
export class BasicFilterExample implements OnInit {
  static HTML_CODE = `
    <div class="example-container">
      <lib-filter-bar [filterSettings]="filterSettings" [dateRange]="dateRange">
      </lib-filter-bar>

      <div class="mt-4" *ngIf="currentFilter">
        <p class="font-bold mb-2">Aktiver Filter:</p>
        <pre>{{ currentFilter | json }}</pre>
      </div>
    </div>
  `;

  filterSettings: FilterSetting[] = [
    new FilterSetting({
      filter: SelectList.LeadType,
      placeholder: 'Lead-Art',
      multiSelect: true,
    }),
    new FilterSetting({
      filter: SelectList.Partner,
      placeholder: 'Partner',
      multiSelect: true,
    }),
  ];

  dateRange = {
    start: '',
    end: '',
  };

  currentFilter: any;

  constructor(private filterService: NewFilterService) {}

  ngOnInit() {
    this.filterService.filter$.subscribe((filter) => {
      this.currentFilter = filter;
    });
  }
}
