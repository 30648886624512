import { FormGroup } from '@angular/forms';

export function mustMatchPassword(
  passwordControlName: string,
  confirmPasswordControlName: string,
) {
  return (formGroup: FormGroup) => {
    const password = formGroup.controls[passwordControlName];
    const confirmPassword = formGroup.controls[confirmPasswordControlName];

    if (
      confirmPassword.errors &&
      !confirmPassword.errors['mustMatchPassword']
    ) {
      return;
    }

    if (password.value !== confirmPassword.value) {
      confirmPassword.setErrors({ mustMatch: true });
    } else {
      confirmPassword.setErrors(null);
    }
  };
}
