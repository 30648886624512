import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { NewSelectOption } from '../../models/new-select-option';
import { ConditionGroup } from '../../models/condition-group';
import { Condition } from '../../models/condition';

@Component({
  selector: 'lib-condition-groups',
  templateUrl: './condition-groups.component.html',
  styleUrls: ['./condition-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConditionGroupsComponent implements OnDestroy {
  @Input() set groups(value: ConditionGroup[]) {
    this._groups = value || [];
    this.updateForm();
  }
  get groups(): ConditionGroup[] {
    return this._groups;
  }

  @Input() propertyOptions: NewSelectOption[] = [];
  @Input() operatorOptions: NewSelectOption[] = [];
  @Input() leadDetailValueOptions: [] = [];
  @Input() displayCopyGroup: boolean = false;

  @Output() groupsChange = new EventEmitter<ConditionGroup[]>();
  @Output() groupAdded = new EventEmitter<void>();
  @Output() groupRemoved = new EventEmitter<number>();
  @Output() groupCopied = new EventEmitter<number>();
  @Output() conditionAdded = new EventEmitter<number>();
  @Output() conditionRemoved = new EventEmitter<{
    groupIndex: number;
    conditionIndex: number;
  }>();

  form: FormGroup;
  private _groups: ConditionGroup[] = [];
  private destroy$ = new Subject<void>();

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    this.form = this.createForm();
  }

  ngOnInit(): void {
    this.initFormSubscription();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get conditionGroups(): FormArray {
    return this.form.get('conditionGroups') as FormArray;
  }

  getConditionsArray(groupIndex: number): FormArray {
    return this.conditionGroups.at(groupIndex).get('conditions') as FormArray;
  }

  isLastCondition(groupIndex: number, conditionIndex: number): boolean {
    return conditionIndex === this.getConditionsArray(groupIndex).length - 1;
  }

  isLastGroup(groupIndex: number): boolean {
    return groupIndex === this.conditionGroups.length - 1;
  }

  addGroup(event?: Event): void {
    event?.preventDefault();
    const newGroup: ConditionGroup = {
      name: 'Group',
      conditions: [
        {
          property: '',
          operator: '',
          value: '',
          disabled: false,
        },
      ],
    };

    this._groups = [...this._groups, newGroup];

    this.updateForm();

    this.groupAdded.emit();
  }

  removeGroup(index: number, event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.groupRemoved.emit(index);
  }

  copyGroup(index: number, event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.groupCopied.emit(index);
  }

  addCondition(groupIndex: number): void {
    this.conditionAdded.emit(groupIndex);
  }

  removeCondition(groupIndex: number, conditionIndex: number): void {
    this.conditionRemoved.emit({ groupIndex, conditionIndex });
  }

  onConditionChanged(
    condition: Condition,
    groupIndex: number,
    conditionIndex: number,
  ): void {
    const conditionsArray = this.getConditionsArray(groupIndex);
    const conditionFormGroup = conditionsArray.at(conditionIndex) as FormGroup;

    conditionFormGroup.patchValue(condition, { emitEvent: false });
    this.emitGroupsChange();
  }

  trackByFn(index: number): number {
    return index;
  }

  private createForm(): FormGroup {
    return this.fb.group({
      conditionGroups: this.fb.array([]),
    });
  }

  private updateForm(): void {
    if (!this.form) return;

    const groupsArray = this.fb.array(
      this._groups.map((group) => this.createGroupFormGroup(group)),
    );
    this.form.setControl('conditionGroups', groupsArray);

    this.cdr.markForCheck();
  }

  private createGroupFormGroup(group: ConditionGroup): FormGroup {
    return this.fb.group({
      conditions: this.fb.array(
        group.conditions.map((condition) =>
          this.createConditionFormGroup(condition),
        ),
      ),
    });
  }

  private createConditionFormGroup(condition: Condition): FormGroup {
    return this.fb.group({
      property: [condition.property, Validators.required],
      operator: [condition.operator, Validators.required],
      value: [condition.value],
      disabled: [condition.disabled],
      id: [condition.id],
      group_id: [condition.group_id],
    });
  }

  private initFormSubscription(): void {
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(() => {
        if (this.form.valid) {
          this.emitGroupsChange();
        }
      });
  }

  private emitGroupsChange(): void {
    const updatedGroups = this.conditionGroups.controls.map((group) => ({
      name: 'Group',
      conditions: (group.get('conditions') as FormArray).controls.map(
        (control) => control.value,
      ),
    }));

    this.groupsChange.emit(updatedGroups);
  }
}
