import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { StorageService } from './services/storage.service';
import { catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { LoadingService } from './services/loading/loading.service';
import { EnvironmentService } from './services/environment.service';
import { SnackBarService } from './services/snack-bar/snack-bar.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private storageService: StorageService,
    private router: Router,
    private snackbarServices: SnackBarService,
    private loadingService: LoadingService,
    private authService: AuthService,
    private environmentService: EnvironmentService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const skipLoading = ['heartbeat', 'targeting_geos', 'sse_channel'];
    const skipAuth = ['nominatim.lindenfield.de', 'logout'];

    if (!skipLoading.some((url) => request.url.includes(url))) {
      this.loadingService.showGlobal();
    }

    if (!this.environmentService.production) {
      request = this.addDebugParam(request);
    }

    if (skipAuth.some((url) => request.url.includes(url))) {
      return this.handleRequest(next, request);
    }

    const token = this.storageService.getToken();
    if (
      !token &&
      !request.url.includes('login') &&
      !request.url.includes('reset_password') &&
      !request.url.includes('forgot_password')
    ) {
      this.authService.logout();
      return throwError(() => new Error('No token found'));
    }

    if (token) {
      request = this.addAuthHeader(request, token);
    }

    return this.handleRequest(next, request);
  }

  private addDebugParam(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const params = request.params.append(
      'XDEBUG_SESSION_START',
      'XDEBUG_ECLIPSE',
    );
    return request.clone({ params });
  }

  private addAuthHeader(
    request: HttpRequest<unknown>,
    token: string,
  ): HttpRequest<unknown> {
    return request.clone({
      setHeaders: { Authorization: `Bearer ${token}` },
    });
  }

  private handleRequest(
    next: HttpHandler,
    request: HttpRequest<unknown>,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => this.handleError(error)),
      finalize(() => this.loadingService.hideGlobal()),
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (
      error.status === 401 &&
      error?.error?.message === 'fehlender Leadmanager Token'
    ) {
      this.snackbarServices.openSnackBar('Sie sind nicht autorisiert', 'warn');
      this.storageService.removeToken();
      this.authService.logout();
      this.router.navigate(['/login']);
    } else if (error.status === 500) {
      this.snackbarServices.openSnackBar(
        'Ups, etwas ist schief gelaufen',
        'warn',
      );
    }
    return throwError(() => error);
  }
}
