import { Component } from '@angular/core';
import { SmsEditorComponent } from '../../../../communication/sms-editor/sms-editor.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'lib-sms-editor-example',
  template: SmsEditorExample.HTML_CODE,
})
export class SmsEditorExample {
  static HTML_CODE = `
    <div class="example-container">
      <button mat-raised-button color="primary" (click)="openSmsEditor()">
        SMS Editor öffnen
      </button>

      <div class="mt-4" *ngIf="lastSentSMS">
        <p>Letzte gesendete SMS:</p>
        <pre>{{ lastSentSMS | json }}</pre>
      </div>
    </div>
  `;

  lastSentSMS: any = null;

  constructor(private dialog: MatDialog) {}

  openSmsEditor() {
    const dialogRef = this.dialog.open(SmsEditorComponent, {
      width: '400px',
      data: { phoneNumber: '+49123456789' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.lastSentSMS = result;
      }
    });
  }
}
