import { Component } from '@angular/core';
import { NewSelectOption } from '../../../../../models/new-select-option';
import { NewSelectSelection } from '../../../../../models/new-select-selection';

@Component({
  selector: 'lib-negative-select-example',
  template: NegativeSelectExample.HTML_CODE,
})
export class NegativeSelectExample {
  static HTML_CODE = `
     <div class="example-container">
      <lib-select
        [options]="options"
        [multiSelect]="true"
        [allowNegative]="true"
        placeholder="STRG + Klick für negative Auswahl"
        (valueChanged)="onValueChanged($event)"
      >
      </lib-select>

      <div class="mt-4" *ngIf="selection">
        <p>Status der Auswahl:</p>
        <p *ngIf="selection.isNegative" class="text-red-600">
          Negative Auswahl aktiv
        </p>
        <ul>
          <li *ngFor="let option of selection.options">
            {{ option.name }}
          </li>
        </ul>
      </div>
    </div>
  `;

  options: NewSelectOption[] = [
    { name: 'Option 1', value: '1' },
    { name: 'Option 2', value: '2' },
    { name: 'Option 3', value: '3' },
    { name: 'Option 4', value: '4' },
  ];

  selection: NewSelectSelection | null = null;

  onValueChanged(event: NewSelectSelection): void {
    this.selection = event;
    console.log('Auswahl geändert:', event);
  }
}
