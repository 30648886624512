<div>
  <div
    class="timeline-controls"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div class="seller-values">{{ getSellerValue() }}</div>
    <button mat-stroked-button (click)="resetTimelineView()">
      Ansicht zurücksetzen
    </button>
  </div>

  <div class="partner-headers-container">
    <div class="partner-headers">
      <div class="partner-header" *ngFor="let partner of partners">
        <div class="partner-header-name">{{ partner.name }}</div>
      </div>
    </div>
  </div>

  <div
    #timelineContainer
    class="timeline-container"
    [style.height.px]="containerHeight"
    (mousedown)="onMouseDown($event)"
    (mousemove)="onMouseMove($event)"
    (mouseup)="onMouseUp()"
    (mouseleave)="onMouseUp()"
    (wheel)="onWheel($event)"
    [class.cursor-grab]="!isDragging"
    [class.cursor-grabbing]="isDragging"
  >
    <!-- Vertikale Linien -->
    <div class="vertical-lines-container">
      <div class="vertical-line" *ngFor="let partner of partners"></div>
    </div>

    <!-- Horizontale Zeitlinien -->
    <div class="horizontal-lines-container">
      <ng-container *ngFor="let line of horizontalLines">
        <div class="horizontal-line-wrapper" [style.top.px]="line.y">
          <div class="horizontal-line"></div>
          <div class="line-time-label">{{ line.label }}</div>
        </div>
      </ng-container>
    </div>

    <!-- Timeline-Inhalt -->
    <div class="timeline-content">
      <div class="partner-column" *ngFor="let partner of partners">
        <div class="partner-timeline">
          <ng-container *ngFor="let group of partnerTimelineMap[partner.id]">
            <div class="event-group" [style.top.px]="group.baseY">
              <!-- Mehrere Events in einer Gruppe -->
              <ng-container *ngIf="group.events.length > 1">
                <div *ngFor="let event of group.events">
                  <div class="event-icon">
                    <lib-icon
                      [attr.data-feed-id]="event.feed.id"
                      (click)="onToggleExpand(event.feed, $event)"
                      [badges]="event.badge"
                      [name]="event.icon"
                      [animated]="true"
                    >
                    </lib-icon>
                  </div>

                  <lib-feed-item-detail
                    *ngIf="
                      expandedFeedId === event.feed.id &&
                      feedDetailTriggerElement
                    "
                    [feed]="event.feed"
                    [lead]="lead"
                    [withOverlay]="true"
                    [triggerElement]="feedDetailTriggerElement"
                    (closeOverlay)="onToggleExpand(event.feed, $event)"
                  >
                  </lib-feed-item-detail>
                </div>
              </ng-container>

              <!-- Einzelnes Event -->
              <ng-container *ngIf="group.events.length === 1">
                <div class="event-icon">
                  <lib-icon
                    [attr.data-feed-id]="group.events[0].feed.id"
                    (click)="onToggleExpand(group.events[0].feed, $event)"
                    [badges]="group.events[0].badge"
                    [name]="group.events[0].icon"
                    [animated]="true"
                  >
                  </lib-icon>
                </div>

                <lib-feed-item-detail
                  *ngIf="
                    expandedFeedId === group.events[0].feed.id &&
                    feedDetailTriggerElement
                  "
                  [feed]="group.events[0].feed"
                  [lead]="lead"
                  [withOverlay]="true"
                  [triggerElement]="feedDetailTriggerElement"
                  (closeOverlay)="onToggleExpand(group.events[0].feed, $event)"
                >
                </lib-feed-item-detail>
              </ng-container>

              <!-- Zähler für versteckte Events -->
              <div class="hidden-count" *ngIf="group.hiddenCount > 0">
                +{{ group.hiddenCount }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
