<ng-select
  [(ngModel)]="this.selectedValue"
  (change)="emitValueChanged()"
  bindLabel="name"
  [clearable]="this.clearable"
  [multiple]="this.multiSelect"
  [disabled]="disabled"
  [compareWith]="compareFn"
  placeholder="{{ this.placeholder }}"
  [appendTo]="appendClass ? 'body' : ''"
  (add)="onAdd($event)"
  (remove)="onRemove($event)"
>
  <ng-container *ngIf="this.list == SelectList.LeadDetail">
    <ng-option
      class="form-control"
      *ngFor="let option of this.optionsTemps"
      [value]="option"
    >
      {{ option.value.lead_type_name }} <br />
      <small style="white-space: break-spaces"
        >{{ option.value.lead_field_name }} |
        {{ option.value.option_name }}</small
      >
    </ng-option>
  </ng-container>
  <ng-container *ngIf="this.list != SelectList.LeadDetail">
    <ng-option
      class="form-control"
      *ngFor="let option of this.options"
      [value]="option"
      disabled="{{ option.disabled }}"
      [innerHTML]="option.name"
    >
    </ng-option>
  </ng-container>
</ng-select>
