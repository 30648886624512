import { User } from './user';
import { PartnerUser } from './partners/partner-user';
import { Partner } from './partner';

export class CommentSummary {
  id: number = 0;
  summary: string = '';
  visible_in_salesrunner: boolean = false;
  relatable_type: string = '';
  relatable_id: number = 0;
  edited_at?: string = '';
  created_at?: string = '';
  updated_at?: string = '';

  showCommentInput: boolean = false;
}
