export class DomainHelper {
  private static domainToCompanyName: { [key: string]: string } = {
    'www.fluessiggas1.de': 'Flüssiggas1',
    'www.deutsche-thermo.de': 'Deutsche-Thermo.de',
    'www.mobile-heizung-mieten.de': 'Mobile-Heizung-mieten.de',
    'www.gasido.de': 'Gasido',
    'www.heizoeltank-entsorgen.de': 'Heizöltank-entsorgen.de',
    'www.gutachter-gesellschaft.de': 'Gutachter-Gesellschaft.de',
    'www.deutsche-mietwaesche.de': 'Deutsche-Mietwäsche.de',
    'www.gastank-mieten.de': 'Gastank-mieten.de',
    'www.gasflasche.de': 'Gasflasche.de',
    'www.stromerzeuger-mieten24.de': 'Stromerzeuger-mieten24.de',
    'www.kuehlcontainer-mieten.de': 'Kühlcontainer-mieten.de',
    'www.baustellenueberwachung-mieten.de': 'Baustellenüberwachung-mieten.de',
  };

  private static domainToEmail: { [key: string]: string } = {
    'www.fluessiggas1.de': 'info@fluessiggas1.de',
    'www.deutsche-thermo.de': 'info@deutsche-thermo.de',
    'www.gasflasche.de': 'info@gasflasche.de',
    'www.gasido.de': 'info@gasido.de',
    'www.heizoeltank-entsorgen.de': 'info@heizoeltank-entsorgen.de',
    'www.deutsche-mietwaesche.de': 'info@deutsche-mietwaesche.de',
    // 'www.mobile-heizung-mieten.de': '',
    // 'www.gutachter-gesellschaft.de': '',
    // 'www.gastank-mieten.de': '',
    // 'www.stromerzeuger-mieten24.de': '',
    // 'www.kuehlcontainer-mieten.de': '',
    // 'www.baustellenueberwachung-mieten.de': '',
  };

  private static domainToPhoneNumber: { [key: string]: string } = {
    'www.fluessiggas1.de': '+49 221 98651220',
    'www.deutsche-thermo.de': '+49 221 96880453',
    'www.gasflasche.de': '+49 221 98651225',
    'www.gasido.de': '+49 221 96880451',
    'www.heizoeltank-entsorgen.de': '+49 221 96880455',
  };

  private static domainToPhoneSender: { [key: string]: string } = {
    'www.fluessiggas1.de': 's0',
    'www.deutsche-thermo.de': 's4',
    'www.gasido.de': 's2',
  };

  static getCompanyNameByDomain(domain?: string): string {
    return domain
      ? this.domainToCompanyName[domain.toLowerCase()] || 'Lindenfield'
      : 'Lindenfield';
  }

  static getEmailByDomain(domain?: string): string {
    return domain
      ? this.domainToEmail[domain.toLowerCase()] || 'info@lindenfield.de'
      : 'info@lindenfield.de';
  }

  static getPhoneNumberByDomain(domain?: string): string {
    return domain
      ? this.domainToPhoneNumber[domain.toLowerCase()] || '+49 221 96880455'
      : '+49 221 96880455';
  }

  static getPhoneSenderByDomain(domain?: string): string {
    return domain
      ? this.domainToPhoneSender[domain.toLowerCase()] || 's4'
      : 's4';
  }
}
