// countries.const.ts
import { PartnerLead } from '../models/partner-lead';

const PartnerLeads: PartnerLead[] = [];
export const PartnerLeadDragDrop = [
  {
    title: 'Neu',
    status_fixed: 1,
    summe: 0,
    data: PartnerLeads,
  },
  {
    title: 'Kontakt hergestellt',
    status_fixed: 2,
    summe: 0,
    data: PartnerLeads,
  },
  {
    title: 'Angebot versendet',
    status_fixed: 3,
    summe: 0,
    data: PartnerLeads,
  },
  {
    title: 'Auftrag erhalten & Projekt aktiv',
    status_fixed: 5,
    summe: 0,
    data: PartnerLeads,
  },
];
