import { User } from './user';
import { PartnerUser } from './partners/partner-user';
import { Partner } from './partner';

export class Comment {
  id?: number = 0;
  comment: string = '';
  user_id?: number = 0;
  partner_user_id?: number = 0;
  visible_in_salesrunner: boolean = false;
  relatable_type: string = '';
  relatable_id: number = 0;
  edited_at?: string = '';
  created_at?: string = '';
  updated_at?: string = '';

  partner?: Partner = new Partner({});
  user?: User = new User({});
  partner_user?: PartnerUser = new PartnerUser({});

  showCommentInput?: boolean = false;
}
