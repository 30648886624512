import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataTablesParameters } from '@lib/interfaces/optionen.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHandlerService } from '@lib/services/http-handler.service';
import { StorageService } from '@lib/services/storage.service';
import { Targeting } from '@lib/models/targeting/targeting';
import { EnvironmentService } from '@lib/services/environment.service';

@Injectable({
  providedIn: 'root',
})
export class TargetingsService {
  endpoint = 'targetings/';
  endpointTargetingGeo = 'delete_geo_targetings/';
  endpointBulkUpdate = 'targeting_detail_targetings/';
  endpointDuplicate = 'targetings/duplicate/';

  constructor(
    private httpHandler: HttpHandlerService,
    protected http: HttpClient,
    private environmentService: EnvironmentService,
  ) {}

  datatable(dataTablesParams: DataTablesParameters): Observable<any> {
    return this.httpHandler.datatable(this.endpoint, dataTablesParams).pipe(
      map((response) => {
        return {
          recordsFiltered: response.recordsFiltered,
          recordsTotal: response.recordsTotal,
          data: response.data.map((item: Targeting) => new Targeting(item)),
        };
      }),
    );
  }

  list(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  getList(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  show(id: number): Observable<any> {
    return this.httpHandler.show(this.endpoint, id);
  }

  create(targeting: Targeting): Observable<any> {
    return this.httpHandler.create(this.endpoint, targeting);
  }

  update(targeting: Targeting): Observable<any> {
    return this.httpHandler.update(this.endpoint, targeting);
  }

  delete(id: number): Observable<any> {
    return this.httpHandler.delete(this.endpoint, id);
  }

  deleteGeoTargetings(id: number): Observable<any> {
    return this.httpHandler.delete(this.endpointTargetingGeo, id);
  }

  bulkUpdateLead(targetingUpdate: any) {
    return this.http.post(
      this.environmentService.apiUrl + this.endpointBulkUpdate,
      targetingUpdate,
      { responseType: 'json' },
    );
  }

  duplicate(targeting: Targeting): Observable<any> {
    return this.http.post(
      this.environmentService.apiUrl + this.endpointDuplicate + targeting.id,
      targeting,
      {
        responseType: 'json',
      },
    );
  }
}
