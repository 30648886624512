<form
  [formGroup]="partnerBudgetForm"
  (ngSubmit)="onSubmit()"
  class="budget-layout"
  [ngClass]="{ 'border-layout': displayBorder }"
>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <mat-slide-toggle
      formControlName="budgetStatus"
      (change)="onBudgetStatusChange()"
      >Budget festlegen</mat-slide-toggle
    >

    <button
      *ngIf="displaySaveButton"
      [disabled]="disableForm"
      class="btn btn-success btn-success"
    >
      Speichern
    </button>
  </div>

  <ng-container *ngIf="partnerBudgetForm.get('budgetStatus')?.value">
    <mat-radio-group formControlName="budgetType" class="element-margin">
      <mat-radio-button class="example-radio-button" [value]="'daily'"
        >Tagesbudget</mat-radio-button
      >
      <mat-radio-button class="example-radio-button" [value]="'weekly'"
        >Wochenbudget</mat-radio-button
      >
      <mat-radio-button class="example-radio-button" [value]="'monthly'"
        >Monatsbudget</mat-radio-button
      >
    </mat-radio-group>

    <mat-form-field fxFill class="element-margin">
      <mat-label>Budget</mat-label>
      <input matInput formControlName="budgetAmount" />
    </mat-form-field>
  </ng-container>
</form>
