import { Component } from '@angular/core';

@Component({
  selector: 'lib-header-card-example',
  template: HeaderCardExample.HTML_CODE,
})
export class HeaderCardExample {
  static HTML_CODE = `
    <app-card>
      <app-card-head
        pageTitle="Projektübersicht"
        pageSubtitle="Aktuelle Projektinformationen"
      >
      </app-card-head>
      <app-card-body>
        <p>
          Diese Card demonstriert die Verwendung der Kopfzeile mit Titel und
          Untertitel.
        </p>
        <ul>
          <li>Projekt A: In Bearbeitung</li>
          <li>Projekt B: Abgeschlossen</li>
          <li>Projekt C: Geplant</li>
        </ul>
      </app-card-body>
    </app-card>
  `;
}
