import { Reaction } from './reaction';
import { PartnerLead } from './partner-lead';
import {
  ButtonIconColor,
  ButtonType,
} from '../components/button/button.component';

export class Feed {
  id?: number = 0;
  lead_id?: number = 0;
  partner_lead_id?: number = 0;
  partner_lead?: PartnerLead = new PartnerLead({});
  relatable_type: string = '';
  relatable_id: number = 0;
  relatable: any = 0;
  reactions: Reaction[] = [];
  visible_in_salesrunner: boolean = false;
  event_at: string = '';
  description?: string = '';
  created_at?: string = '';
  updated_at?: string = '';
  flag: boolean = false;
  showCommentInput?: boolean = false;
  minimizeComments?: boolean = false;
}

export interface FilterOption {
  type: string;
  icon: string;
  label: string;
}

export interface FeedItemAction {
  type: string;
  icon: string;
  label: string;
  visible?: boolean;
  disabled?: boolean;
}

export interface FeedItemBadge {
  type: string;
  text: string;
  color: string;
}

export type FeedType =
  | 'App\\Comment'
  | 'App\\CommentSummary'
  | 'App\\CommentWebsite'
  | 'App\\LeadResponseHistory'
  | 'App\\Call'
  | 'App\\Email'
  | 'App\\Document'
  | 'App\\LeadFeedback'
  | 'App\\PartnerLeadHistory'
  | 'App\\LeadHistory'
  | 'App\\Reminder';

export interface NewFeed {
  id?: number;
  lead_id?: number;
  partner_lead_id?: number;
  partner_lead?: PartnerLead;
  relatable_type: FeedType;
  relatable_id: number;
  relatable: any;
  reactions: Reaction[];
  visible_in_salesrunner: boolean;
  flag: boolean;
  event_at: string;
  description?: string;
  created_at?: string;
  updated_at?: string;
}

export interface FeedItemActionButton {
  type: ButtonType;
  iconColor: ButtonIconColor;
  label?: string;
  emoji?: string;
  disabled?: boolean;
  count?: number;
  onClick?: () => void;
}

export interface FeedItemAction {
  type: string;
  icon: string;
  label: string;
  visible?: boolean;
  disabled?: boolean;
}

export interface FeedItemBadge {
  type: string;
  text: string;
  color: string;
}

export interface FeedInteractionCounts {
  commentCount: number;
  reactionCount: number;
}

export class FeedMapper {
  static toNewFeed(feed: Feed): NewFeed {
    // Type Assertion für relatable_type
    const relatable_type = feed.relatable_type as FeedType;

    return {
      id: feed.id,
      lead_id: feed.lead_id,
      partner_lead_id: feed.partner_lead_id,
      partner_lead: feed.partner_lead,
      relatable_type,
      relatable_id: feed.relatable_id,
      relatable: feed.relatable,
      reactions: feed.reactions,
      visible_in_salesrunner: feed.visible_in_salesrunner,
      event_at: feed.event_at,
      description: feed.description,
      flag: feed.flag,
      created_at: feed.created_at,
      updated_at: feed.updated_at,
    };
  }
}
