<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
  <span class="comment-edited" *ngIf="comment?.edited_at">
    <em>bearbeitet</em>
  </span>

  <mat-chip
    *ngIf="
      ApplicationHelper.applicationName === Application.Leadmanager &&
      getFeedSalesrunnerVisibilityHint()
    "
  >
    <span [innerHTML]="getFeedSalesrunnerVisibilityHint()"></span>
  </mat-chip>
</div>
<div [innerHTML]="comment.comment"></div>
