import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd, Event } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { AuthService } from '@lib/services/auth.service';
import { PartnerUserService } from '@lib/services/partner/partner-user.service';

@Injectable({
  providedIn: 'root',
})
export class LeadListViewService {
  private useNewViewSubject = new BehaviorSubject<boolean>(true);
  useNewView$ = this.useNewViewSubject.asObservable();

  constructor(
    private router: Router,
    private authService: AuthService,
    private partnerUserService: PartnerUserService,
  ) {
    this.authService.partnerUser$.subscribe((partnerUser) => {
      if (partnerUser) {
        const useNewView = partnerUser.use_new_lead_list ?? true;
        this.useNewViewSubject.next(useNewView);
        this.updateRouteIfNeeded(this.router.url, useNewView);
      }
    });

    this.router.events
      .pipe(
        filter(
          (event: Event): event is NavigationEnd =>
            event instanceof NavigationEnd,
        ),
      )
      .subscribe((event: NavigationEnd) => {
        const currentValue = this.useNewViewSubject.value;
        this.updateRouteIfNeeded(event.url, currentValue);
      });
  }

  private updateRouteIfNeeded(url: string, useNewView: boolean) {
    if (url.includes('/leads/edit') || url.includes('leads-new?id=')) {
      return;
    }

    if (url.startsWith('/leads') || url.startsWith('/leads-new')) {
      const currentIsNewView = url.startsWith('/leads-new');
      if (useNewView !== currentIsNewView) {
        const baseRoute = useNewView ? '/leads-new' : '/leads';
        this.router.navigate([baseRoute]);
      }
    }
  }

  toggleView() {
    const newValue = !this.useNewViewSubject.value;
    this.useNewViewSubject.next(newValue);

    this.authService.partnerUser$.pipe(take(1)).subscribe((partnerUser) => {
      if (partnerUser) {
        this.partnerUserService
          .update(
            {
              use_new_lead_list: newValue,
              first_name: partnerUser.first_name,
              last_name: partnerUser.last_name,
              email: partnerUser.email,
            },
            partnerUser.id,
          )
          .subscribe({
            next: () => {
              this.authService.getCurrentPartnerUser(true).subscribe();
            },
            error: (error) => {
              this.useNewViewSubject.next(!newValue);
            },
          });
      }
    });

    const baseRoute = newValue ? '/leads-new' : '/leads';
    this.router.navigate([baseRoute]);
  }

  openLeadById(id: number) {
    if (this.useNewViewSubject.value) {
      this.router.navigate(['/leads-new'], {
        queryParams: { id: id },
      });
    } else {
      this.router.navigate(['/leads/edit', id]);
    }
  }
}
