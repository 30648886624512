import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Targeting } from '@lib/models/targeting/targeting';
import { TargetingDetail } from '@lib/models/targeting/targeting-detail';
import { OptionlistOption } from '@lib/models/optionlist/optionlist-option';
import { TargetingDetailService } from '@lib/services/targeting-detail.service';
import { SnackBarService } from '@lib/services/snack-bar/snack-bar.service';
import { PartnerBudgetComponent } from '@lib/components/partner-budget/partner-budget.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'edit-targeting-detail-modal',
  templateUrl: './edit-targeting-detail-modal.component.html',
  styleUrls: ['./edit-targeting-detail-modal.component.scss'],
})
export class EditTargetingDetailModalComponent implements OnInit {
  @ViewChild(PartnerBudgetComponent)
  partnerBudgetComponent!: PartnerBudgetComponent;

  targetingDetailForm: FormGroup = new FormGroup({});

  targeting: Targeting = new Targeting({});
  targetingDetail: TargetingDetail = new TargetingDetail({});
  inspectorTypeOptionlist: OptionlistOption[] = [];
  partnerUserIsAdmin = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditTargetingDetailModalComponent>,
    private targetingDetailService: TargetingDetailService,
    private snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA)
    public inputData: {
      partnerUserIsAdmin: boolean;
      targetingDetail: TargetingDetail;
      targeting: Targeting;
      inspectorTypeOptionlist: OptionlistOption[];
    },
  ) {}

  ngOnInit(): void {
    this.setInputData();
    this.initForm();
  }

  setInputData() {
    if (this.inputData?.partnerUserIsAdmin) {
      this.partnerUserIsAdmin = this.inputData.partnerUserIsAdmin;
    }
    if (this.inputData?.targeting) {
      this.targeting = this.inputData.targeting;
    }
    if (this.inputData?.targetingDetail) {
      this.targetingDetail = this.inputData.targetingDetail;
    }
    if (this.inputData?.inspectorTypeOptionlist) {
      this.inspectorTypeOptionlist = this.inputData.inspectorTypeOptionlist;
    }
  }

  initForm() {
    this.targetingDetailForm = this.formBuilder.group({
      targetingDetailList: [
        {
          value: this.targeting.selected_inspector_types,
          disabled: !this.partnerUserIsAdmin,
        },
        [Validators.required],
      ],
    });
  }

  save() {
    const selectedOptions = this.targetingDetailForm.get(
      'targetingDetailList',
    )?.value;

    if (
      this.targetingDetailForm.valid &&
      this.partnerUserIsAdmin &&
      (this.partnerBudgetComponent.partnerBudgetForm.valid ||
        // Überprüfen, ob das Budget-Formular gültig ist oder ob das Budget ausgeschaltet ist
        !this.partnerBudgetComponent.partnerBudgetForm.get('budgetStatus')
          ?.value)
    ) {
      this.partnerBudgetComponent.onSubmit(true);

      // Filtert Optionen basierend auf ausgewählten Optionen
      const newOptions = this.inspectorTypeOptionlist.filter((option) =>
        selectedOptions.some(
          (selectedOption: string) => selectedOption === option.name,
        ),
      );

      this.targetingDetail.conditions.forEach((condition) => {
        // Erstelle eine Liste der option_ids aus newOptions
        const newOptionIds = newOptions.map((option) => option.option_id);

        // Filter entfernt alle Felder, deren val nicht in newOptionIds enthalten ist
        // @ts-ignore
        condition.fields = condition.fields.filter((field) =>
          newOptionIds.includes(Number(field.val)),
        );

        // Hinzufügen neuer Optionen, die noch nicht in fields enthalten sind
        newOptionIds.forEach((optionId) => {
          if (
            !condition.fields.some((field) => Number(field.val) === optionId)
          ) {
            // @ts-ignore
            condition.fields.push({
              field_id: '119', // das hier dynamisch setzen aus environment
              comparison: '=',
              val: optionId.toString(),
            });
          }
        });
      });

      this.targetingDetailService.update(this.targetingDetail).subscribe({
        next: (response) => {
          if (response.error) {
            this.snackBarService.openSnackBar(response.error, 'warn');
          } else {
            this.dialogRef.close();
            this.snackBarService.openSnackBar(
              'Die Daten wurden erfolgreich gespeichert',
              'success',
            );
          }
        },
        error: (err) => {
          console.error('API Error:', err);
          let errorMessage = 'Es ist ein Fehler aufgetreten';
          // Prüfe, ob der Fehler eine Nachricht enthält
          if (err.error && err.error.message) {
            errorMessage = err.error.message;
          }
          this.snackBarService.openSnackBar(errorMessage, 'warn');
        },
      });
    }
  }
}
