import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DaterangePickerComponent } from '../daterange-picker/daterange-picker.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateTimePickerService } from '../../../services/date-time-picker/date-time-picker.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-daterange-picker-helper',
  templateUrl: './daterange-picker-helper.component.html',
  styleUrls: ['./daterange-picker-helper.component.scss'],
})
export class DaterangePickerHelperComponent
  implements OnDestroy, OnInit, OnChanges
{
  @Output() dateRange = new EventEmitter<{ start: any; end: any }>();
  @Input() startDate: any = '';
  @Input() endDate: any = '';
  @Input() isSetDefaultDateTime = true;

  protected readonly DaterangePickerComponent = DaterangePickerComponent;
  rangeForm: FormGroup;
  private subscription: Subscription = new Subscription();
  private lastEmitterDaterange: { start: any; end: any } = {
    start: '',
    end: '',
  };

  constructor(
    private formBuilder: FormBuilder,
    protected dateTimePickerService: DateTimePickerService,
  ) {
    this.rangeForm = this.formBuilder.group({
      start: [''],
      end: [''],
    });
  }

  ngOnInit() {
    this.initializeForm();
    this.subscription.add(
      this.dateTimePickerService.event$.subscribe(() => {
        this.applyRange();
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['startDate'] || changes['endDate']) {
      this.rangeFormBuilder();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  rangeFormBuilder() {
    this.rangeForm.patchValue({
      start: this.startDate,
      end: this.endDate,
    });
  }

  initializeForm() {
    if (!this.startDate || !this.endDate) {
      if (this.isSetDefaultDateTime) {
        const today = new Date();
        this.startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        this.endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      }
    }
    this.rangeFormBuilder();
  }

  applyRange() {
    this.startDate = this.formatDate(this.rangeForm.value.start);
    this.endDate = this.formatDate(this.rangeForm.value.end) || this.startDate;

    if (
      this.lastEmitterDaterange.start === this.startDate &&
      this.lastEmitterDaterange.end === this.endDate
    ) {
      return;
    }

    this.rangeFormBuilder();

    this.lastEmitterDaterange = { start: this.startDate, end: this.endDate };
    this.dateRange.emit({ start: this.startDate, end: this.endDate });
  }

  dateRangeChanged() {
    this.performActions();
  }

  requiredDaterangePicker() {
    if (this.isSetDefaultDateTime) {
      const { start, end } = this.rangeForm.value;
      if (!start) this.rangeForm.patchValue({ start: new Date() });
      if (!end) this.rangeForm.patchValue({ end: new Date() });

      if (start && end && start.isAfter && start.isAfter(end)) {
        this.rangeForm.patchValue({ end: start.clone() });
      }
    }
  }

  private performActions() {
    this.requiredDaterangePicker();
    this.applyRange();
  }

  private formatDate(date: any): string {
    return date && date.format ? date.format('YYYY-MM-DD') : date;
  }
}
