import { Address } from './address';

export class Partner {
  id: number = 0;
  address_id: number = 0;
  active: number = 0;
  acquisition_status_id: number = 0;
  cancel_reason_id: number = 0;
  required_invoice: number = 0;
  active_email_link_spam_protection: number = 0;
  invoice_and_performance_data: number = 0;
  competition_success_rate_365_days: number = 0;
  complain_rate: number = 0;
  easybill_customer_id: number = 0;
  max_competitors: number = 2;
  lead_count: number = 0;
  external_competition_success_rate_365_days: string = '';
  internal_comment: string = '';
  lead_types: string = '';
  name: string = '';
  score_multiplier: string = '';
  offer_rate: number = 0;
  partner_leads_count: number = 0;
  partner_leads_reaction_time_seconds: number = 0;
  accepted_partner_leads_rate: number = 0;
  partner_leads_offer_rate: number = 0;
  partner_leads_complain_rate: number = 0;
  partner_leads_success_rate: number = 0;
  partner_leads_competition_success_rate: number = 0;
  partner_leads_external_competition_success_rate: number = 0;
  mailserver: string | null = '';
  address = new Address({});
  partner_partner_group: any = [];
  partner_partner_group_selected: any = [];
  constructor(partner: Partner | {}) {
    Object.assign(this, partner);
  }

  get activeName(): string {
    if (this.active == 1) {
      return 'Aktiv';
    } else {
      return 'Inaktiv';
    }
  }
}
