import { Component, Input, OnInit } from '@angular/core';
import { DemoConfig } from '../../shared/interfaces/demo-config.interface';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-demo-wrapper',
  templateUrl: './demo-wrapper.component.html',
  styleUrls: ['./demo-wrapper.component.scss'],
})
export class DemoWrapperComponent implements OnInit {
  @Input() config!: DemoConfig;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.config = this.route.snapshot.data['config'];
  }
}
