import { Component } from '@angular/core';

@Component({
  selector: 'lib-basic-split-view-example',
  template: BasicSplitViewExample.HTML_CODE,
})
export class BasicSplitViewExample {
  static HTML_CODE = `
    <lib-split-view
      [initialLeftPanelWidth]="200"
      [minLeftPanelWidth]="250"
      [maxLeftPanelWidth]="800"
      [rightPanelMinWidth]="50"
      [showRightPanel]="showRightPanel"
      (leftPanelWidthChange)="onWidthChange($event)"
    >
      <div leftPanel class="p-4 bg-gray-100">
        <h2 class="text-lg font-semibold mb-4">Linkes Panel</h2>
        <div class="space-y-2">
          <div
            *ngFor="let item of items"
            class="p-3 bg-white rounded shadow cursor-pointer hover:bg-gray-50"
            (click)="selectItem(item)"
          >
            Item {{ item }}
          </div>
        </div>
      </div>

      <div rightPanel class="p-4" *ngIf="showRightPanel">
        <div class="flex items-center mb-4">
          <button mat-icon-button (click)="showRightPanel = false">
            <mat-icon>arrow_back</mat-icon>
          </button>
          <h2 class="text-lg font-semibold">Details</h2>
        </div>
        <p>Ausgewähltes Item: {{ selectedItem }}</p>
      </div>
    </lib-split-view>
  `;

  items = [1, 2, 3, 4, 5];
  selectedItem: number | null = null;
  showRightPanel = false;

  selectItem(item: number) {
    this.selectedItem = item;
    this.showRightPanel = true;
  }

  onWidthChange(width: number) {
    console.log('Panel-Breite geändert:', width);
  }
}
