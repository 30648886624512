<div
  class="tooltip-container"
  (mouseenter)="showTooltip()"
  (mouseleave)="hideTooltip()"
>
  <ng-content></ng-content>
  <div
    *ngIf="visible"
    [innerHTML]="text"
    class="tooltip-content"
    [class.visible]="isPositioned"
  ></div>
</div>
