import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PartnerUser } from '../../models/partners/partner-user';

@Component({
  selector: 'lib-user-assignment',
  templateUrl: './user-assignment.component.html',
  styleUrls: ['./user-assignment.component.scss'],
})
export class UserAssignmentComponent {
  @Input() users: any[] = [];
  @Input() assignedUser?: any | null;
  @Output() assignUser = new EventEmitter<any>();
  @Output() removeUser = new EventEmitter<void>();

  /**
   * Sortiert Benutzer alphabetisch nach Vornamen
   */
  get sortedUsers(): PartnerUser[] {
    return [...this.users].sort((a, b) =>
      a.first_name.localeCompare(b.first_name),
    );
  }

  /**
   * Öffnet/schließt das Dropdown-Menü und positioniert es
   */
  toggleDropdown(event: Event, dropdownElement: HTMLElement): void {
    event.stopPropagation();
    const userSelectElement = event.currentTarget as HTMLElement;

    // Schließe andere offene Dropdowns
    const allActive = document.querySelectorAll(
      '.user-select.active, .not-assigned.active',
    );
    allActive.forEach((el) => {
      if (el !== userSelectElement) {
        el.classList.remove('active');
      }
    });

    userSelectElement.classList.toggle('active');

    if (userSelectElement.classList.contains('active')) {
      const rect = userSelectElement.getBoundingClientRect();
      const dropdown = userSelectElement.querySelector(
        '.user-dropdown',
      ) as HTMLElement;

      if (dropdown) {
        const dropdownWidth = dropdown.offsetWidth;
        const dropdownHeight = dropdown.offsetHeight;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        // Horizontale Positionierung: Prüfe, ob das Dropdown rechts überläuft
        if (rect.left + dropdownWidth > windowWidth) {
          dropdown.style.left = 'auto';
          dropdown.style.right = '0';
        } else {
          dropdown.style.left = '0';
          dropdown.style.right = 'auto';
        }

        // Vertikale Positionierung: Prüfe, ob das Dropdown unten überläuft
        const spaceBelow = windowHeight - rect.bottom;
        if (spaceBelow < dropdownHeight) {
          dropdown.style.top = 'auto';
          dropdown.style.bottom = '100%';
        } else {
          dropdown.style.top = '100%';
          dropdown.style.bottom = 'auto';
        }
      }

      setTimeout(() => {
        document.addEventListener('click', this.closeDropdown);
      }, 0);
    }
  }

  /**
   * Schließt das Dropdown-Menü bei Klick außerhalb
   */
  closeDropdown = (event: Event): void => {
    const activeDropdowns = document.querySelectorAll(
      '.user-select.active, .not-assigned.active',
    );
    activeDropdowns.forEach((dropdown) => {
      if (!dropdown.contains(event.target as Node)) {
        dropdown.classList.remove('active');
      }
    });
    document.removeEventListener('click', this.closeDropdown);
  };

  /**
   * Gibt den vollständigen Namen eines Benutzers zurück
   */
  getUserName(user?: PartnerUser | null): string {
    return user ? `${user.first_name} ${user.last_name}` : '';
  }

  /**
   * Gibt die Initialen eines Benutzers zurück (erste Buchstaben von Vor- und Nachname)
   */
  getUserInitials(user?: PartnerUser | null): string {
    return user
      ? `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`
      : '';
  }

  /**
   * Erzeugt einen Hashwert aus einem String
   */
  private hashString(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash |= 0; // Konvertiere zu 32-Bit-Integer
    }
    return hash;
  }

  /**
   * Generiert eine eindeutige Farbe basierend auf dem Namen des Benutzers
   */
  getUserColor(user?: PartnerUser | null): string {
    if (!user) return '#e57f31'; // Standardfarbe, falls kein Benutzer

    const fullName = (user.first_name + ' ' + user.last_name)
      .toLowerCase()
      .trim();
    const hash = this.hashString(fullName);
    const hue = Math.abs(hash) % 360; // Farbton zwischen 0 und 360

    // Fixiere Sättigung und Helligkeit für bessere Unterscheidbarkeit
    const saturation = 70; // 70% Sättigung
    const lightness = 50; // 50% Helligkeit

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  }

  /**
   * Generiert eine dunklere Randfarbe basierend auf demselben Namen
   */
  getUserBorderColor(user?: PartnerUser | null): string {
    if (!user) return '#ff6e00'; // Standard-Randfarbe

    const fullName = (user.first_name + ' ' + user.last_name)
      .toLowerCase()
      .trim();
    const hash = this.hashString(fullName);
    const hue = Math.abs(hash) % 360;

    // Dunklerer Rand mit niedrigerer Helligkeit
    const saturation = 75;
    const lightness = 40; // Dunkler als die Hauptfarbe

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  }

  /**
   * Erzeugt Inline-Styles für den User-Avatar
   */
  getUserAvatarStyle(user?: PartnerUser | null): object {
    if (!user) return {};

    return {
      'background-color': this.getUserColor(user),
      'border-color': this.getUserBorderColor(user),
    };
  }

  /**
   * Event-Handler für Benutzer-Zuweisung
   */
  onAssignUser(user: PartnerUser): void {
    this.assignUser.emit(user);
  }

  /**
   * Event-Handler zum Entfernen der Benutzer-Zuweisung
   */
  onRemoveUser(): void {
    this.removeUser.emit();
  }
}
