import { Component } from '@angular/core';
import { Condition } from '../../../../../models/condition';
import { NewSelectOption } from '../../../../../models/new-select-option';
import { SelectService } from '../../../../../services/select.service';

@Component({
  selector: 'lib-basic-condition-example',
  template: BasicConditionExample.HTML_CODE,
})
export class BasicConditionExample {
  static HTML_CODE = `
    <div class="example-container">
      <lib-condition
        [condition]="condition"
        [propertyOptions]="propertyOptions"
        [operatorOptions]="operatorOptions"
        [leadDetailValueOptions]="leadDetailValueOptions"
        (conditionChange)="onConditionChange($event)"
      >
      </lib-condition>

      <div class="mt-4" *ngIf="lastChange">
        <p class="font-semibold">Letzte Änderung:</p>
        <pre class="bg-gray-100 p-2 rounded">{{ lastChange | json }}</pre>
      </div>
    </div>
  `;

  propertyOptions: NewSelectOption[] = [
    { name: 'Status', value: 'status' },
    { name: 'Lead Typ', value: 'lead_type_id' },
    { name: 'Email Typ', value: 'email_type_id' },
  ];

  operatorOptions: NewSelectOption[] = [
    { name: 'Gleich', value: '==' },
    { name: 'Ungleich', value: '!=' },
    { name: 'Größer als', value: '>' },
    { name: 'Kleiner als', value: '<' },
  ];

  leadDetailValueOptions = [];

  condition: Condition = {
    property: 'status',
    operator: '==',
    value: '',
    disabled: false,
  };

  lastChange: Condition | null = null;

  constructor(private selectService: SelectService) {}

  onConditionChange(event: Condition) {
    this.lastChange = event;
  }
}
