import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TableService } from '../../services/table.service';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { of, Subject, Subscription } from 'rxjs';
import {
  TableColumn,
  TableData,
  TableDataStorage,
} from './table-data.interface';
import { tableServiceFactory } from '../../services/table.service.factory';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StorageService } from '../../services/storage.service';
import { EnvironmentService } from '../../services/environment.service';
import { TABLE_ID_TOKEN } from '../../services/table-injection-token';
import { moveItemInArray } from '@angular/cdk/drag-drop';

/**
 * Anleitung zur Verwendung der zentralen Tabelle:
 *
 * 1. Erstelle eine neue Tabelle in deinem Modul (<lib-table></lib-table>)
 *    Pflichtfelder:
 *      tableId = freie Bezeichnung, wird benötigt, um Tabelle speichern zu können und Konflikte zu vermeiden
 *      endpoint = Endpunkt für die Datenabfrage z.B. 'leads/'
 *    Optionale Felder:
 *      filter = enthält das gesamte Filterobjekt und wird unverändert an das Backend übergeben
 *      showSearchbar = (boolean) Suchleiste für die Tabelle, Standardwert ist false
 *      initialPageSize = Anzahl der Einträge pro Seite, Standardwert ist 50
 *      designStriped = (boolean) Zebramuster für die Tabellenzeilen, Standardwert ist true
 *      designHoverEffect = (boolean) Hover-Effekt für die Tabellenzeilen, Standardwert ist true
 *      tableWithSelection = (boolean) Tabelle mit Auswahlmöglichkeit, Standardwert ist false - selectionChanged wird aufgerufen, wenn sich etwas an der Auswahl ändert
 *      restriction = damit kann die Auswahl der Daten eingeschränkt werden z.B. { number: 'DPQ3Q2' }
 *      additionalSearchFields = damit können Felder definiert werden, in denen zusätzlich gesucht werden soll. Ansonsten würden nur die gezeigten Spalten berücksichtig werden.
 *      tableMaxHeightPx = damit kann ich maximale Höhe der Tabelle festgelegt werden. Danach würde ein Scrollbalken erscheinen
 *      initialSelection = enthält ein Array mit vorausgewählten Elementen bei einer Tabelle mit Auswahlliste
 *      httpParams = kann beliebige httpParams enthalten, die zusätzlich übergeben werden sollen this.additionalHttpParams = this.additionalHttpParams.set('performance','1',);
 *      disableSelectionFn = hier kann eine Funktion übergeben werden, die prüft, ob eine Zeile selektierbar ist oder nicht z.B. (row: any) => row.status === 'active'
 *
 *      Beispiel:
 *      <lib-table
 *        [tableId]="'leadList'"
 *        [initialPageSize]="5"
 *        [filter]="filter"
 *        [endpoint]="'leads/'"
 *        [httpParams]="additionalHttpParams"
 *        [tableWithSelection]="true"
 *        [additionalSearchFields]="
 *         'addresses.email',
 *         'addresses.phone',
 *         'addresses.address'
 *         "
 *        (selectionChanged)="onLeadSelectionChanged($event)"
 *      >
 *
 * 2. Erstelle die Spalten in der Tabelle (<lib-table-column></lib-table-column>)
 *    Pflichtfelder:
 *      title = Enthält den Spaltentitel
 *    Optionale Felder:
 *      columnName = enthält den Spaltennamen, wird benötigt, um die Spalte zu identifizieren z.B. request_at, wenn das Model Lead ist.
 *      pipe = enthält den Namen des Pipes, der auf die Spalte angewendet werden soll, z.B. 'date' oder 'currency' ({{ 5.0 | dynamicPipe : { name: 'currency', args: ['EUR'] } }})
 *      isSortable = (boolean) Zeigt an, ob die Spalte sortierbar ist, Standardwert ist true
 *      isSearchable = (boolean) Zeigt an, ob die Spalte suchbar ist, Standardwert ist true
 *      designPointer = (boolean) Zeigt an, ob der Cursor bei Hover über die Spalte zu einem Pointer wird, Standardwert ist false
 *      defaultSort = 'asc' | 'desc' - eine Spalte der Tabelle kann dieses Kennzeichen haben. Das ist die Spalte, nach der standardmäßig sortiert wird.
 *      prefix = Wenn ein columnName gesetzt ist, kann damit ein prefix übergeben werden
 *      suffix = Wenn ein columnName gesetzt ist, kann damit ein suffix übergeben werden
 *      backgroundColorFn = hier keine eine Funktion, zur Formatierung der Hintergrundfarbe, übergeben werden
 *      customTedmplate = Wenn kein Spaltenname gesetzt ist, kann hier ein vollständiges Template übergeben werden
 *      summableColumnName = Wenn die Spalte summiert werden soll, dann muss hier der Spaltenname übergeben werden. Die Zeile wird der Tabelle automatisch zugefügt
 *      headlineTooltipText = Enthält den Informationstext, der in der Überschrift der Spalte angezeigt werden soll
 *
 *      Beispiel 1 - Verwendung einer columnName:
 *
 *      <lib-table-column
 *         [defaultSort]="'desc'"
 *         [columnName]="'request_at'"
 *         [title]="'Anfragedatum'"
 *         [sortableColumnName]="'request_at'"
 *         [designPointer]="true"
 *         [backgroundColorFn]="getBackgroundColor"
 *         [pipe]="{ name: 'date', args: ['dd.MM.yyyy HH:mm'] }"
 *         [suffix]="'Uhr'"
 *       ></lib-table-column>
 *
 *       Beispiel 2 - Verwendung eines customTemplates:
 *
 *        <lib-table-column
 *         [title]="'Leadnummer'"
 *         [sortableColumnName]="'id'"
 *         [customTemplate]="leadNumberTemplate"
 *       >
 *         <ng-template #leadNumberTemplate let-lead>
 *           <a [routerLink]="['/leads/edit', lead.id]">{{ lead.number }}</a>
 *           <span class="badge bg-danger fs-7 fw-normal" *ngIf="lead.is_blocked"
 *             >Gesperrt</span
 *           >
 *         </ng-template>
 *       </lib-table-column>
 *
 *
 * 3. Diese Anpassungen sind in der Komponente notwendig:
 *
 *   Nur notwendig, wenn es eine Tabelle mit Auswahlmöglichkeit ist, ein Clickevent, ein Enter oder Leave-Event abgefangen werden sollen:
 *
 *      onLeadSelectionChanged(selectedItems: Lead[]) {
 *        this.selection = new SelectionModel<Lead>(true, selectedItems);
 *      }
 *
 *   Kopieren des Filters, da sonst das Change-Event in der Tabelle nicht ausgelöst wird:
 *
 *      setFilter(filter: any) {
 *        this.filter = {
 *          ...this.filter, // Kopiert alle vorhandenen Filter
 *          'partner_leads.status': filter.filter?.partnerLeadStatus,
 *          lead_type_id: filter.filter?.leadTypeId,
 *          status: filter.filter?.leadStatus,
 *          'partner_leads.partner_id': filter.filter?.partner,
 *          lead_details: filter.filter?.leadDetail,
 *        };
 *
 *        this.reloadTableData();
 *      }
 *
 *   Bei Bedarf kann ein Trigger angestoßen werden, damit die Tabelle neu geladen wird:
 *
 *      @ViewChild(TableComponent) tableComponent!: TableComponent;
 *
 *      reloadTable() {
 *        this.tableComponent.reloadData();
 *      }
 */

@Component({
  selector: 'lib-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [
    {
      provide: TableService,
      useFactory: (
        http: HttpClient,
        storageService: StorageService,
        environmentService: EnvironmentService,
        tableId: string,
      ) =>
        tableServiceFactory(http, storageService, environmentService, tableId),
      deps: [HttpClient, StorageService, EnvironmentService, TABLE_ID_TOKEN],
    },
    {
      provide: TABLE_ID_TOKEN,
      useValue: 'table_id',
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements AfterViewInit, OnDestroy, OnChanges {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Input() tableId!: string;
  @Input() endpoint!: string;
  @Input() filter: any;
  @Input() restriction: any;
  @Input() initialPageSize: number = 50;
  @Input() designStriped: boolean = true;
  @Input() showSearchbar: boolean = false;
  @Input() additionalSearchFields: string[] = [];
  @Input() designHoverEffect: boolean = true;
  @Input() tableWithSelection: boolean = false;
  @Input() dragDropAble: boolean = false;
  @Input() tableMaxHeightPx: number = 99999999999;
  @Input() httpParams: HttpParams = new HttpParams();
  @Input() initialSelection = new SelectionModel<any>(true, []);
  @Input() disableSelectionFn: ((row: any) => boolean) | undefined;

  @Output() selectionChanged = new EventEmitter<any[]>();
  @Output() allTableDataChanged = new EventEmitter<any>();
  @Output() displayedDataChanged = new EventEmitter<any[]>();
  @Output() hoveredEnterElementChanged = new EventEmitter<any>();
  @Output() hoveredLeaveElementChanged = new EventEmitter<any>();
  @Output() clickedElementChanged = new EventEmitter<any>();
  @Output() dragDropChanged = new EventEmitter<any>();
  @Output() sumCallbackDataChanged = new EventEmitter<any>();

  private _data: any[] = [];
  selection = new SelectionModel<any>(true, []);

  isLoadingResults: boolean = true;
  dataSource = new MatTableDataSource();
  pageSizeOptions: number[] = [5, 10, 20, 50];
  searchValue: string = '';
  sumCallbackData: any = [];
  searchTimeout: any;

  private destroy$ = new Subject<void>();

  constructor(private tableService: TableService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filter']) {
      this.resetPaginator();
      this.loadTableData();
    }

    if (changes['initialSelection'] && this.selection) {
      this.selection.clear();
      this.selection = this.initialSelection;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.subscribeToPaginatorChanges();
      this.subscribeToSortChanges();
      this.initializeTable();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  emitDropDragTable(event: any) {
    const currentStatusIndex = event.currentIndex;
    const previousStatusIndex = event.previousIndex;

    const containerData = event.container.data;
    moveItemInArray(containerData, previousStatusIndex, currentStatusIndex);
    this.dragDropChanged.emit(event.container.data);
  }

  private updateTableState() {
    this.tableService.updateTableState(this.tableId, {
      pageSize: this.paginator.pageSize,
      pageIndex: this.paginator.pageIndex,
      sortDirection: this.sort?.direction,
      sortColumn: this.sort?.active,
    });
  }

  private subscribeToPaginatorChanges() {
    this.paginator?.page.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      this.paginator.pageSize = event.pageSize;
      this.paginator.pageIndex = event.pageIndex;

      this.loadTableData();
    });
  }

  private subscribeToSortChanges() {
    this.sort?.sortChange.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      this.sort.active = event.active;
      this.sort.direction = event.direction;

      const state = this.tableService.getTableState(this.tableId);
      if (
        state?.sortColumn !== this.sort.active ||
        state.sortDirection !== this.sort.direction
      ) {
        this.resetPaginator();
      }

      this.loadTableData();
    });
  }

  private resetPaginator() {
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
  }

  private initializeTable() {
    const state = this.tableService.getTableState(this.tableId);

    if (state) {
      // Wenn es bereits gespeicherte Daten gibt, dann lade diese
      this.applyTableState(state);
    } else {
      // erstelle die Tabelle neu
      this.initializeDataSource();
    }

    this.initializeSelection();
  }

  private initializeSelection() {
    if (this.initialSelection && this.tableWithSelection) {
      this.selection = this.initialSelection;
    }
  }

  private applyTableState(state: TableDataStorage) {
    this.paginator.pageIndex = state.pageIndex;
    this.paginator.pageSize = state.pageSize;

    this.dataSource.paginator = this.paginator;

    if (this.sort) {
      this.sort.active = state.sortColumn;
      this.sort.direction = state.sortDirection;

      const sortEvent = {
        active: this.sort.active,
        direction: this.sort.direction,
      };

      this.sort.sortChange.emit(sortEvent);
    }
  }

  loadTableData() {
    if (this.paginator && this.sort && this.sort.active) {
      this.isLoadingResults = true;

      this.tableService
        .loadTableData(
          this.endpoint,
          this.paginator.pageIndex + 1,
          this.paginator.pageSize,
          this.sort.active,
          this.sort.direction,
          this.filter,
          this.restriction,
          this.searchValue,
          this.getSearchableColumnNames(),
          this.httpParams,
        )
        .pipe(
          catchError((error) => {
            console.error('Ein Fehler ist aufgetreten', error);
            return of([]);
          }),
          finalize(() => {
            this.isLoadingResults = false;
          }),
        )
        .subscribe((response: TableData) => {
          this.isLoadingResults = false;
          if (response.sumCallback) {
            this.sumCallbackData = response.sumCallback;
            this.sumCallbackDataChanged.emit(response.sumCallback);
          }
          this.setPaginationResponse(response);
          this.emitDisplayedData();
          this.updateTableState();
          this.emitAllData(response);
        });
    }
  }

  getSearchableColumnNames() {
    const defaultSearchableColumns = this.columns
      .filter(
        (column) => !column.key.includes('empty-column') && column.isSearchable,
      )
      .map((column) => column.key);

    return [
      ...new Set([...defaultSearchableColumns, ...this.additionalSearchFields]),
    ];
  }

  setPaginationResponse(response: TableData) {
    this.dataSource = new MatTableDataSource(response.data);
    this.paginator.length = response.total; // Gesamtanzahl der Einträge
    this.paginator.pageIndex = response.currentPage - 1; // Aktuelle Seite (pageIndex ist 0-basiert)
    this.paginator.pageSize = this.paginator.pageSize; // Seitengröße
  }

  initializeDataSource() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.applyInitialSort();
  }

  resolveNestedPath(obj: any, path: string): any {
    return path.split('.').reduce((prev, curr) => {
      return prev ? prev[curr] : null;
    }, obj);
  }

  applyInitialSort() {
    if (this.sort) {
      const defaultSortColumn = this.tableService.columns.find(
        (c) => c.defaultSort,
      );

      if (defaultSortColumn) {
        this.sort.active = defaultSortColumn.key;
        this.sort.direction = defaultSortColumn.defaultSort || '';

        const sortEvent = {
          active: this.sort.active,
          direction: this.sort.direction,
        };

        this.sort.sortChange.emit(sortEvent);
      }
    }
  }

  get data(): any[] {
    return this._data;
  }

  get columns(): TableColumn[] {
    return this.tableService.columns;
  }

  get columnKeys(): string[] {
    const keys = this.columns.map((c) => c.key);
    // Wenn es eine Selection Tabelle ist, dann muss die Spalte ergänzt werden
    if (this.tableWithSelection) {
      return ['select', ...keys];
    }
    return keys;
  }

  trackByFn(index: number, item: TableColumn): string {
    return item.key;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  isRowSelectable(row: any): boolean {
    if (this.disableSelectionFn) {
      return !this.disableSelectionFn(row);
    }
    return true;
  }

  toggleSingleRow(row: any) {
    if (this.isRowSelectable(row)) {
      this.selection.toggle(row);
      this.emitSelection();
    }
  }

  toggleAllRows() {
    const selectableRows = this.dataSource.data.filter((row) =>
      this.isRowSelectable(row),
    );
    if (this.areAllSelectableRowsSelected(selectableRows)) {
      this.selection.clear();
    } else {
      this.selection.select(...selectableRows);
    }
    this.emitSelection();
  }

  areAllSelectableRowsSelected(selectableRows: any[]): boolean {
    const selectedSelectableRows = selectableRows.filter((row) =>
      this.selection.isSelected(row),
    );
    return (
      selectedSelectableRows.length === selectableRows.length &&
      selectableRows.length > 0
    );
  }

  private emitSelection() {
    this.selectionChanged.emit(this.selection.selected);
  }

  private emitDisplayedData() {
    this.displayedDataChanged.emit(this.dataSource.data);
  }

  private emitAllData(response: TableData) {
    this.allTableDataChanged.emit(response);
  }

  emitClickedElement(element: any) {
    if (!this.isLoadingResults) {
      this.clickedElementChanged.emit(element);
    }
  }

  emitHoveredEnterElement(element: any) {
    this.hoveredEnterElementChanged.emit(element);
  }

  emitHoveredLeaveElement(element: any) {
    this.hoveredLeaveElementChanged.emit(element);
  }

  public reloadData() {
    this.selection.clear();
    this.loadTableData();
  }

  public applySort(active: string, direction: SortDirection) {
    this.sort.active = active;
    this.sort.direction = direction;
  }

  applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchValue = filterValue.trim().toLowerCase();
    this.resetPaginator();
    this.isLoadingResults = true;

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.loadTableData();
    }, 1000);
  }

  hasSummableColumns(): boolean {
    return (
      this.columns?.some((column) => column?.summableColumnName !== '') ?? false
    );
  }
}
