import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EnvironmentService } from '../environment.service';
import { Address } from '../../models/address';
import { HttpHandlerService } from '../http-handler.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  endpoint: string = 'address/';

  private lang = 'de-DE';
  private apiKey = 'nhxOlVcTyGc781_EGwuC23CrLLVcfwW2o90gaoPo_KA';

  constructor(
    private httpHandler: HttpHandlerService,
    protected http: HttpClient,
    private httpClient: HttpClient,
    private environmentService: EnvironmentService,
  ) {}

  datatable(dataTablesParams: any): Observable<any> {
    return this.httpHandler.datatable(this.endpoint, dataTablesParams).pipe(
      map((response: any) => {
        return {
          response: response,
          recordsFiltered: response.recordsFiltered,
          recordsTotal: response.recordsTotal,
          data: response.data.map((item: Address) => new Address(item)),
        };
      }),
    );
  }

  list(httpParams: any): Observable<any> {
    return this.httpHandler.list(this.endpoint, httpParams);
  }

  getList(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  show(id: number): Observable<any> {
    return this.httpHandler.show(this.endpoint, id);
  }

  create(address: Address): Observable<any> {
    return this.httpHandler.create(this.endpoint, address);
  }

  update(address: Address): Observable<any> {
    return this.httpHandler.update(this.endpoint, address);
  }

  delete(id: number): Observable<any> {
    return this.httpHandler.delete(this.endpoint, id);
  }

  search(searchTerm: string): Observable<any> {
    const params = new HttpParams()
      .set('q', searchTerm)
      .set('format', 'geojson')
      .set('lang', this.lang)

      .set('apiKey', this.apiKey);

    return this.httpClient.get(
      'https://autocomplete.search.hereapi.com/v1/autocomplete',
      {
        params,
      },
    );
  }

  search2(searchTerm: string): Observable<any> {
    // @ts-ignore

    return this.http.get(
      this.environmentService.apiUrl +
        this.endpoint +
        'searchAutoComplete?' +
        'q=' +
        searchTerm,
      {
        responseType: 'json',
      },
    );
  }

  geoCodeSearch(searchTerm: string, countryCode: string): Observable<any> {
    // @ts-ignore

    return this.http.get(
      this.environmentService.apiUrl +
        this.endpoint +
        'searchGeoCode?' +
        'q=' +
        searchTerm +
        '&country_code=' +
        countryCode,
      {
        responseType: 'json',
      },
    );
  }

  getDisplayName(
    companyName: string | null | undefined,
    name: string | null | undefined,
  ): string {
    if (companyName && name) {
      return `${companyName} (${name})`;
    }

    if (companyName) {
      return companyName;
    }

    if (name) {
      return name;
    }

    return '-';
  }
}
