import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { Feed, FeedMapper, NewFeed } from '../../models/feed';
import { DateHelper } from '../../helpers/date-helper';
import { Lead } from '../../models/leads/lead';
import { IconBadge } from '../icon/icon.component';
import { FeedItemService } from '../../services/feed-item.service';

/**
 * berechnete Events in der Timeline
 */
interface ComputedEvent {
  feed: NewFeed;
  partnerId: number;
  date: Date;
  y: number;
  icon: string;
  badge: IconBadge[];
}

/**
 *  gruppierte Events in der Timeline
 */
interface PartnerTimelineGroup {
  baseY: number;
  events: ComputedEvent[];
  hiddenCount: number;
  groupTime: number;
}

@Component({
  selector: 'lib-feed-forwarded-list',
  templateUrl: './feed-forwarded-list.component.html',
  styleUrls: ['./feed-forwarded-list.component.scss'],
})
export class FeedForwardedListComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() feeds: Feed[] = [];
  @Input() lead?: Lead = new Lead({});
  @Input() currentFilter?: string;

  @ViewChild('timelineContainer', { static: false })
  timelineContainer!: ElementRef;

  private readonly TIMELINE_PADDING = 20;
  private readonly ICON_HALF_HEIGHT = 20;
  private readonly INNER_PADDING =
    this.TIMELINE_PADDING + this.ICON_HALF_HEIGHT;
  private readonly MIN_TIME_SPAN = 60 * 1000; // 1 Minute
  private readonly MIN_EVENT_SPACING = 40;

  public isDragging = false;
  private dragStartY = 0;
  private initialTimeOffset = -200;
  private destroy$ = new Subject<void>();
  expandedFeedId: number | null | undefined = null;
  feedDetailTriggerElement?: ElementRef;

  // Timeline-Konfiguration
  containerHeight = 800;
  zoomLevel = 1;
  timeOffset = 0;

  // Datenstrukturen
  partners: { id: number; name: string }[] = [];
  partnerTimelineMap: { [partnerId: number]: PartnerTimelineGroup[] } = {};
  horizontalLines: { y: number; label: string }[] = [];

  constructor(
    private feedItemService: FeedItemService,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    this.processFeeds();
  }

  ngOnChanges(): void {
    const previousExpandedFeedId = this.expandedFeedId;
    this.expandedFeedId = null;

    // Wenn es ein vorher expandiertes Feed gab, finden und wieder öffnen
    if (previousExpandedFeedId) {
      const feed = this.feeds.find(
        (feed) => feed.id === previousExpandedFeedId,
      );
      if (feed) {
        // Warten bis View aktualisiert wurde
        setTimeout(() => {
          const iconElement = this.elementRef.nativeElement.querySelector(
            `lib-icon[data-feed-id="${feed.id}"]`,
          );

          if (iconElement) {
            this.onToggleExpand(feed, {
              stopPropagation: () => {},
              currentTarget: iconElement,
            });
          }
        });
      }
    }

    this.processFeeds();
    this.calculateTimeline();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Verarbeitet die Feed-Daten und extrahiert Partner-Informationen
   */
  private processFeeds(): void {
    const partnerMap = new Map<number, string>();

    this.feeds.forEach((feed) => {
      const partnerId = this.getPartnerId(feed);
      const partnerName = this.getPartnerName(feed);
      if (partnerId && partnerName) {
        partnerMap.set(partnerId, partnerName);
      }
    });

    this.partners = Array.from(partnerMap.entries())
      .map(([id, name]) => ({ id, name }))
      .sort((a, b) => a.name.localeCompare(b.name));

    this.resetTimelineView();
  }

  /**
   * Berechnet die Timeline-Positionen und Gruppierungen
   */
  private calculateTimeline(): void {
    if (!this.feeds || this.feeds.length === 0) {
      this.resetTimelineData();
      return;
    }

    const sortedFeeds = this.getSortedFeeds();
    const { minTimeRounded, maxTimeRounded, totalTimeSpan } =
      this.calculateTimeSpans(sortedFeeds);
    const effectiveZoomFactor = this.calculateZoomFactor(totalTimeSpan);

    const computedEvents = this.computeEventPositions(
      sortedFeeds,
      minTimeRounded,
      maxTimeRounded,
      effectiveZoomFactor,
    );

    this.createPartnerTimelines(computedEvents);
    this.updateHorizontalLines(computedEvents);
  }

  /**
   * Behandelt Mausrad-Events für Zoom
   */
  onWheel(event: WheelEvent): void {
    event.preventDefault();
    this.expandedFeedId = null;
    const mouseY = this.getMouseY(event);
    const timeUnderPointer = this.calculateTimeUnderPointer(mouseY);

    this.updateZoomLevel(event.deltaY < 0);
    this.updateTimeOffset(mouseY, timeUnderPointer);
    this.calculateTimeline();
  }

  /**
   * Event-Handler für Maus-Bewegungen während des Dragging
   */
  onMouseMove(event: MouseEvent): void {
    if (!this.isDragging) return;

    const mouseY = this.getMouseY(event);
    const deltaY = mouseY - this.dragStartY;

    const { totalTimeSpan } = this.calculateTimeSpans(this.getSortedFeeds());
    const effectiveZoomFactor = this.calculateZoomFactor(totalTimeSpan);

    this.timeOffset = this.initialTimeOffset - deltaY / effectiveZoomFactor;
    this.calculateTimeline();
  }

  /**
   * Hilfsmethoden für die Timeline-Berechnung
   */
  private getSortedFeeds(): Feed[] {
    return this.feeds
      .slice()
      .sort(
        (a, b) =>
          new Date(a.event_at).getTime() - new Date(b.event_at).getTime(),
      );
  }

  /**
   * Berechnet die verschiedenen Zeitspannen für die Timeline
   */
  private calculateTimeSpans(sortedFeeds: Feed[]) {
    const firstDate = new Date(sortedFeeds[0].event_at);
    const lastDate = new Date(sortedFeeds[sortedFeeds.length - 1].event_at);
    firstDate.setSeconds(0, 0);
    lastDate.setSeconds(0, 0);

    const minTimeRounded = firstDate.getTime();
    const maxTimeRounded = lastDate.getTime();
    const actualTimeSpan = maxTimeRounded - minTimeRounded;
    const totalTimeSpan = Math.max(actualTimeSpan, this.MIN_TIME_SPAN);

    return {
      minTimeRounded,
      maxTimeRounded,
      totalTimeSpan,
      allSameTime: maxTimeRounded === minTimeRounded,
    };
  }

  /**
   * Berechnet den effektiven Zoom-Faktor
   */
  private calculateZoomFactor(totalTimeSpan: number): number {
    const effectiveHeight = this.containerHeight - 2 * this.INNER_PADDING;
    const baseZoomFactor =
      totalTimeSpan > 0 ? effectiveHeight / totalTimeSpan : 1;
    return baseZoomFactor * this.zoomLevel;
  }

  /**
   * Berechnet die Y-Positionen für alle Events
   */
  private computeEventPositions(
    sortedFeeds: Feed[],
    minTimeRounded: number,
    maxTimeRounded: number,
    effectiveZoomFactor: number,
  ): ComputedEvent[] {
    const allSameTime = minTimeRounded === maxTimeRounded;

    return sortedFeeds
      .map((feed) => {
        const partnerId = this.getPartnerId(feed);
        if (partnerId === null) return null;

        const date = new Date(feed.event_at);
        date.setSeconds(0, 0);
        const eventTime = date.getTime();

        const y = allSameTime
          ? this.containerHeight / 2
          : this.INNER_PADDING +
            (eventTime - (minTimeRounded + this.timeOffset)) *
              effectiveZoomFactor;

        return {
          feed,
          partnerId,
          date,
          y,
          icon: this.getIcon(feed),
          badge: this.getBadges(feed),
        };
      })
      .filter((event): event is ComputedEvent => event !== null);
  }

  /**
   * Erstellt die Partner-Timelines mit gruppierten Events
   */
  private createPartnerTimelines(computedEvents: ComputedEvent[]): void {
    this.partnerTimelineMap = {};

    this.partners.forEach((partner) => {
      const partnerEvents = computedEvents
        .filter(
          (e) =>
            e.partnerId === partner.id &&
            e.y >= this.INNER_PADDING &&
            e.y <= this.containerHeight - this.INNER_PADDING,
        )
        .sort((a, b) => a.y - b.y);

      const groups: PartnerTimelineGroup[] = [];

      partnerEvents.forEach((event) => {
        if (groups.length === 0) {
          groups.push(this.createNewGroup(event));
        } else {
          const lastGroup = groups[groups.length - 1];
          this.addEventToGroups(event, lastGroup, groups);
        }
      });

      this.partnerTimelineMap[partner.id] = groups;
    });
  }

  /**
   * Erstellt eine neue Event-Gruppe
   */
  private createNewGroup(event: ComputedEvent): PartnerTimelineGroup {
    return {
      baseY: event.y,
      events: [event],
      hiddenCount: 0,
      groupTime: event.date.getTime(),
    };
  }

  /**
   * Fügt ein Event zu existierenden Gruppen hinzu
   */
  private addEventToGroups(
    event: ComputedEvent,
    lastGroup: PartnerTimelineGroup,
    groups: PartnerTimelineGroup[],
  ): void {
    if (event.date.getTime() === lastGroup.groupTime) {
      // Gleiches Zeitfenster - Event zur Gruppe hinzufügen
      lastGroup.events.push(event);
    } else if (event.y - lastGroup.baseY < this.MIN_EVENT_SPACING) {
      // Zu geringer Abstand - Event verstecken
      lastGroup.hiddenCount++;
    } else {
      // Neue Gruppe erstellen
      groups.push(this.createNewGroup(event));
    }
  }

  /**
   * Aktualisiert die horizontalen Zeitlinien
   */
  private updateHorizontalLines(computedEvents: ComputedEvent[]): void {
    const lineMap = new Map<number, string>();

    computedEvents.forEach((event) => {
      if (
        event.y >= this.INNER_PADDING &&
        event.y <= this.containerHeight - this.INNER_PADDING
      ) {
        const yKey = Math.round(event.y);
        if (!lineMap.has(yKey)) {
          const timeLabel = DateHelper.getFormattedDateTime(event.date);
          lineMap.set(yKey, timeLabel);
        }
      }
    });

    this.horizontalLines = Array.from(lineMap.entries())
      .map(([y, label]) => ({ y, label }))
      .sort((a, b) => a.y - b.y);
  }

  /**
   * Ermittelt die Y-Position des Mauszeigers
   */
  private getMouseY(event: MouseEvent | WheelEvent): number {
    let mouseY = event.offsetY;

    if (!mouseY && this.timelineContainer) {
      const containerRect =
        this.timelineContainer.nativeElement.getBoundingClientRect();
      mouseY = event.clientY - containerRect.top;
    }

    return Math.max(
      this.INNER_PADDING,
      Math.min(mouseY, this.containerHeight - this.INNER_PADDING),
    );
  }

  /**
   * Berechnet die Zeit unter dem Mauszeiger
   */
  private calculateTimeUnderPointer(mouseY: number): number {
    const sortedFeeds = this.getSortedFeeds();
    const minTime = new Date(sortedFeeds[0].event_at).getTime();
    const { totalTimeSpan } = this.calculateTimeSpans(sortedFeeds);
    const effectiveZoomFactor = this.calculateZoomFactor(totalTimeSpan);

    return (
      minTime +
      this.timeOffset +
      (mouseY - this.INNER_PADDING) / effectiveZoomFactor
    );
  }

  /**
   * Aktualisiert den Zoom-Level
   */
  private updateZoomLevel(zoomIn: boolean): void {
    this.zoomLevel *= zoomIn ? 1.25 : 0.8;
  }

  /**
   * Aktualisiert den Time-Offset basierend auf der Mausposition
   */
  private updateTimeOffset(mouseY: number, timeUnderPointer: number): void {
    const sortedFeeds = this.getSortedFeeds();
    const minTime = new Date(sortedFeeds[0].event_at).getTime();
    const { totalTimeSpan } = this.calculateTimeSpans(sortedFeeds);
    const effectiveZoomFactor = this.calculateZoomFactor(totalTimeSpan);

    this.timeOffset =
      timeUnderPointer -
      (mouseY - this.INNER_PADDING) / effectiveZoomFactor -
      minTime;
  }

  /**
   * Setzt die Timeline-Position zurück
   */
  private resetTimelinePosition(midTime: number, minTimeRounded: number): void {
    const { totalTimeSpan } = this.calculateTimeSpans(this.getSortedFeeds());
    const effectiveZoomFactor = this.calculateZoomFactor(totalTimeSpan);

    this.timeOffset =
      midTime -
      minTimeRounded -
      (this.containerHeight / 2 - this.INNER_PADDING) / effectiveZoomFactor;
  }

  /**
   * Setzt die Timeline-Daten zurück
   */
  private resetTimelineData(): void {
    this.partnerTimelineMap = {};
    this.horizontalLines = [];
  }

  /**
   * Setzt die Timeline-Ansicht zurück
   */
  public resetTimelineView(): void {
    if (!this.feeds || this.feeds.length === 0) return;

    const sortedFeeds = this.getSortedFeeds();
    const { minTimeRounded, maxTimeRounded } =
      this.calculateTimeSpans(sortedFeeds);

    this.zoomLevel = 1;
    const midTime = (minTimeRounded + maxTimeRounded) / 2;

    this.resetTimelinePosition(midTime, minTimeRounded);
    this.calculateTimeline();
  }

  onMouseDown(event: MouseEvent): void {
    event.preventDefault();
    this.isDragging = true;
    this.dragStartY = this.getMouseY(event);
    this.initialTimeOffset = this.timeOffset;
  }

  onMouseUp(): void {
    this.isDragging = false;
  }

  // Hilfsmethoden für Icon und Partner-Informationen
  private getIcon(feed: Feed): string {
    const newFeed = FeedMapper.toNewFeed(feed);
    return this.feedItemService.getIcon(newFeed.relatable_type);
  }

  private getPartnerId(feed: Feed): number | null {
    if (!feed.relatable) return null;
    return this.searchPartnerIdInObject(feed);
  }

  private searchPartnerIdInObject(obj: any): number | null {
    if (obj.partner_id) return obj.partner_id;
    if (obj.partner?.id) return obj.partner.id;
    if (obj.partner_lead?.partner?.id) return obj.partner_lead.partner.id;

    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        const result = this.searchPartnerIdInObject(obj[key]);
        if (result !== null) return result;
      }
    }
    return null;
  }

  private getPartnerName(feed: Feed): string | null {
    const typeMap: { [key: string]: (feed: Feed) => string | null } = {
      'App\\Comment': (f) => f.relatable?.partner_user?.partner?.name || null,
      'App\\LeadFeedback': (f) => f.relatable?.partner?.name || null,
      'App\\PartnerLeadHistory': (f) => f.relatable?.partner?.name || null,
      'App\\Document': (f) => f.relatable?.partner?.name || null,
      'App\\Reminder': (f) => f.relatable?.partner_user?.partner?.name || null,
    };

    return typeMap[feed.relatable_type]?.(feed) || null;
  }

  getBadges(feed: any): IconBadge[] {
    return this.feedItemService.getBadges(feed);
  }

  onToggleExpand(feed: Feed, event?: any): void {
    event?.stopPropagation();

    if (this.expandedFeedId === feed.id) {
      this.expandedFeedId = null;
      this.feedDetailTriggerElement = undefined;
      return;
    }

    if (event?.currentTarget instanceof HTMLElement) {
      this.feedDetailTriggerElement = new ElementRef(event.currentTarget);
      this.expandedFeedId = feed.id;
    }
  }

  getSellerValue(): string {
    if (this.lead?.seller) {
      return `${this.lead.seller.name}, ${DateHelper.getFormattedDateTime(
        new Date(this.lead.sold_at),
      )}`;
    }

    return '';
  }
}
