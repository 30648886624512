import { Component } from '@angular/core';
import { StyleguideNavItem } from './shared/interfaces/stylguide';

@Component({
  selector: 'lib-styleguide',
  templateUrl: './styleguide.component.html',
  styleUrls: ['./styleguide.component.scss'],
})
export class StyleguideComponent {
  componentItems: StyleguideNavItem[] = [
    { name: 'Button', route: 'buttons', icon: 'check_box_outline_blank' },
    { name: 'Icons', route: 'icons', icon: 'format_paint' },
    { name: 'Select', route: 'select', icon: 'arrow_drop_down' },
    { name: 'Badges', route: 'badge', icon: 'local_offer' },
    { name: 'Card', route: 'card', icon: 'dashboard' },
    { name: 'File Upload', route: 'file-upload', icon: 'upload' },
    { name: 'Slide Toggle', route: 'slide-toggle', icon: 'toggle_on' },
    { name: 'Comment', route: 'comment', icon: 'comment' },
    { name: 'Messaging', route: 'messaging', icon: 'message' },
    { name: 'Condition', route: 'condition', icon: 'pages' },
    { name: 'Datumsbereich', route: 'daterange', icon: 'calendar_today' },
    { name: 'Filter Bar', route: 'filter-bar', icon: 'filter_list' },
    { name: 'Modal', route: 'modal', icon: 'open_in_new' },
    { name: 'Reaction', route: 'reaction', icon: 'emoji_emotions' },
    { name: 'Split View', route: 'split-view', icon: 'vertical_split' },
    { name: 'Tabelle', route: 'table', icon: 'table' },
    { name: 'Tooltip', route: 'tooltip', icon: 'info' },
  ];

  otherItems: StyleguideNavItem[] = [
    { name: 'Colors', route: 'colors', icon: 'palette' },
  ];
}
