import { FormField } from '../models/modal';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

export class CreateFormGroupHelper {
  static createFormGroupForModal(formFields: FormField[]): FormGroup {
    const group: any = {};

    formFields.forEach((field) => {
      const validators: ValidatorFn[] = [];
      if (field.validation?.required) {
        validators.push(Validators.required);
      }
      if (field.validation?.maxLength) {
        validators.push(Validators.maxLength(field.validation.maxLength));
      }
      group[field.name] = new FormControl(
        field.validation?.defaultValue || '',
        validators,
      );
    });

    return new FormGroup(group);
  }
}
