<app-card>
  <app-card-head pageTitle="Adressen">
    <div fxLayoutGap="10px">
      <button
        mat-stroked-button
        [matMenuTriggerFor]="addressMenu"
        [disabled]="!hasAvailableAddressTypes() || inputsDisabled"
      >
        Adresse hinzufügen
      </button>
      <button
        mat-stroked-button
        *ngIf="additionalAddresses.length > 0 || hasAvailableAddressTypes()"
        (click)="copyDefaultToAdditional()"
        [disabled]="inputsDisabled"
        class="ml-2"
      >
        <mat-icon>content_copy</mat-icon> Adresse kopieren
      </button>
    </div>
  </app-card-head>
  <app-card-body>
    <div class="address-block">
      <app-address
        [address]="defaultAddress"
        [inputsDisabled]="inputsDisabled"
        (addressChanged)="onAddressChanged($event)"
      ></app-address>
    </div>

    <!-- Zusatzadressen -->
    <div *ngFor="let address of additionalAddresses">
      <app-address
        [address]="address"
        [inputsDisabled]="inputsDisabled"
        (addressChanged)="onAddressChanged($event)"
        (addressDeleted)="onAddressDeleted($event)"
      ></app-address>
    </div>

    <div fxLayout="row" fxLayoutAlign="end end">
      <!--      <button-->
      <!--        mat-stroked-button-->
      <!--        [matMenuTriggerFor]="addressMenu"-->
      <!--        [disabled]="!hasAvailableAddressTypes()"-->
      <!--      >-->
      <!--        Adresse hinzufügen-->
      <!--      </button>-->
      <mat-menu #addressMenu="matMenu">
        <button
          mat-menu-item
          *ngFor="let type of availableAddressTypes"
          [disabled]="hasAddressType(type)"
          (click)="addAddress(type)"
        >
          {{ getAddressTypeLabel(type) }}
        </button>
      </mat-menu>
    </div>
  </app-card-body>
</app-card>
