import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ElementRef,
  HostListener,
  ViewEncapsulation,
} from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { ReactionService } from '../../services/reaction.service';
import { Reaction } from '../../models/reaction';
import { ApplicationHelper } from '../../helpers/application-helper';
import { Application } from '../../enum-collection';
import { User } from '../../models/user';
import { PartnerUser } from '../../models/partners/partner-user';

interface EmojiData {
  emoji: string;
  count: number;
  users: { id: number; name: string }[];
}

@Component({
  selector: 'lib-reaction',
  templateUrl: './reaction.component.html',
  styleUrls: ['./reaction.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReactionComponent implements OnInit {
  @Input() relatableId = 0;
  @Input() relatableType = '';
  @Output() reactionChanged = new EventEmitter<EmojiData[]>();

  emojiDataArray: EmojiData[] = [];
  showEmojiPicker = false;
  isTooltipVisible = false;
  currentUserReaction: Reaction | null = null;

  constructor(
    private elementRef: ElementRef,
    private authService: AuthService,
    private reactionService: ReactionService,
  ) {}

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.showEmojiPicker = false;
    }
  }

  ngOnInit() {
    this.loadReactionsAndUpdateState();
  }

  loadReactionsAndUpdateState() {
    combineLatest([this.loadReactions(), this.authService.getCurrentUser()])
      .pipe(
        tap(([reactions, user]) => {
          this.updateEmojiData(reactions);
          this.setCurrentUserReaction(reactions, user);
        }),
      )
      .subscribe();
  }

  loadReactions(): Observable<Reaction[]> {
    return this.relatableId && this.relatableType
      ? this.reactionService.getReactionsForModel(
          this.relatableType,
          this.relatableId,
        )
      : of([]);
  }

  updateEmojiData(reactions: Reaction[]) {
    const emojiMap = new Map<string, EmojiData>();
    reactions.forEach((reaction) => {
      const data = emojiMap.get(reaction.emoji) || {
        emoji: reaction.emoji,
        count: 0,
        users: [],
      };
      data.count++;
      let userId: number | undefined;
      let user: User | PartnerUser | null;

      userId = reaction.user_id || reaction.partner_user_id;
      user = reaction.user || reaction.partner_user;

      if (userId && user && user.first_name && user.last_name) {
        const existingUserIndex = data.users.findIndex((u) => u.id === userId);
        const userName = `${user.first_name} ${user.last_name}`;
        if (existingUserIndex === -1) {
          data.users.push({ id: userId, name: userName });
        } else {
          data.users[existingUserIndex].name = userName;
        }
      }
      emojiMap.set(reaction.emoji, data);
    });
    this.emojiDataArray = Array.from(emojiMap.values());
    this.reactionChanged.emit(this.emojiDataArray);
  }

  setCurrentUserReaction(reactions: Reaction[], user: User | null) {
    if (ApplicationHelper.applicationName === Application.Leadmanager) {
      this.currentUserReaction =
        reactions.find((r) => r.user_id === user?.id) ?? null;
    }

    if (ApplicationHelper.applicationName === Application.Salesrunner) {
      this.currentUserReaction =
        reactions.find((r) => r.partner_user_id === user?.id) ?? null;
    }
  }

  showTooltip() {
    this.isTooltipVisible = true;
  }

  hideTooltip() {
    this.isTooltipVisible = false;
  }

  getAllEmojis(): string {
    return this.emojiDataArray.map((data) => data.emoji).join('');
  }

  getTotalCount(): number {
    return this.emojiDataArray.reduce((sum, data) => sum + data.count, 0);
  }

  getAllUsers(): { name: string; emoji: string }[] {
    return this.emojiDataArray.flatMap((data) =>
      data.users.map((user) => ({ name: user.name, emoji: data.emoji })),
    );
  }

  toggleEmojiPicker(event: Event) {
    event.stopPropagation();
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event: any) {
    this.authService
      .getCurrentUser()
      .pipe(
        switchMap((user) => {
          const reaction = new Reaction();

          if (ApplicationHelper.applicationName === Application.Leadmanager) {
            reaction.user_id = user?.id;
          } else {
            reaction.partner_user_id = user?.id;
          }

          reaction.emoji = event.emoji.native;
          reaction.relatable_type = this.relatableType;
          reaction.relatable_id = this.relatableId;
          return this.reactionService.createReaction(reaction);
        }),
        tap(() => {
          this.loadReactionsAndUpdateState();
          this.showEmojiPicker = false;
        }),
      )
      .subscribe();
  }

  getEmojiSummary(): string {
    return this.emojiDataArray
      .map((data) => `${data.emoji} ${data.count}`)
      .join(' · ');
  }

  removeReaction() {
    if (!this.currentUserReaction || !this.currentUserReaction.id) {
      return;
    }

    this.authService
      .getCurrentUser()
      .pipe(
        switchMap((user) => {
          if (!user) {
            throw new Error('User not authenticated');
          }
          return this.reactionService.deleteReaction(
            this.currentUserReaction?.id,
          );
        }),
        tap(() => {
          this.loadReactionsAndUpdateState();
        }),
      )
      .subscribe();
  }
}
