import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Lead } from '../../../models/leads/lead';
import { SipgateService } from '../../../services/sipgate/sipgate.service';
import { LeadService } from '../../../services/lead/lead.service';
import { ValidationPatterns } from '../../../validations/validation-pattern';
import { DomainHelper } from '../../../helpers/domain-helper';

@Component({
  selector: 'app-sms-editor',
  templateUrl: './sms-editor.component.html',
  styleUrls: ['./sms-editor.component.scss'],
})
export class SmsEditorComponent implements OnInit, OnDestroy {
  smsForm: FormGroup = new FormGroup({});
  lead: Lead = new Lead({});
  private loadLeadSubscription = new Subscription();
  private updateLeadSubscription = new Subscription();

  constructor(
    private sipgateService: SipgateService,
    public dialogRef: MatDialogRef<SmsEditorComponent>,
    @Inject(MAT_DIALOG_DATA)
    public inputData: { phoneNumber: string },
    private formBuilder: FormBuilder,
    private leadService: LeadService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getLead();
  }

  ngOnDestroy() {
    this.loadLeadSubscription.unsubscribe();
    this.updateLeadSubscription.unsubscribe();
    console.log('SMS Observables beendet!');
  }

  private initForm() {
    this.smsForm = this.formBuilder.group({
      senderName: [
        {
          value: '',
          disabled: true,
        },
        Validators.required,
      ],
      senderNumber: [
        {
          value: '',
          disabled: true,
        },
        [
          Validators.required,
          Validators.pattern(ValidationPatterns.phoneNumberPattern),
        ],
      ],
      recipientNumber: [
        {
          value: this.inputData?.phoneNumber,
          disabled: true,
        },
        [
          Validators.required,
          Validators.pattern(ValidationPatterns.phoneNumberPattern),
        ],
      ],
      message: ['', Validators.required],
    });
  }

  getLead() {
    this.loadLeadSubscription = this.leadService.leadObservable.subscribe(
      (lead: Lead) => {
        // Nicht schön, aber notwendig. Im Backend werden sonst nicht die aktuellen Daten verwendet.
        this.updateLeadSubscription = this.leadService
          .update(lead)
          .subscribe(() => {
            this.lead = lead;

            this.smsForm.patchValue({
              senderName: DomainHelper.getCompanyNameByDomain(
                this.lead?.domain,
              ),
              senderNumber: DomainHelper.getPhoneNumberByDomain(
                this.lead?.domain,
              ),
            });
          });
      },
    );
  }

  sendSms() {
    if (this.smsForm.valid) {
      this.sipgateService
        .smsUser({
          recipientNumber: this.smsForm.get('recipientNumber')?.value,
          message: this.smsForm.get('message')?.value,
          sender: DomainHelper.getPhoneSenderByDomain(this.lead?.domain),
        })
        .subscribe((response: any) => {
          this.dialogRef.close();
        });
    }
  }
}
