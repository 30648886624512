import { Injectable } from '@angular/core';
import { DataTablesParameters } from '../interfaces/optionen.interface';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { HttpHandlerService } from './http-handler.service';
import { Partner } from '../models/partner';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class PartnersService {
  endpoint: string = 'partners/';

  constructor(
    private http: HttpClient,
    private httpHandler: HttpHandlerService,
    private environmentService: EnvironmentService,
  ) {}

  datatable(dataTablesParams: DataTablesParameters): Observable<any> {
    return this.httpHandler.datatable(this.endpoint, dataTablesParams).pipe(
      map((response) => {
        return {
          recordsFiltered: response.recordsFiltered,
          recordsTotal: response.recordsTotal,
          data: response.data.map((item: Partner) => new Partner(item)),
        };
      }),
    );
  }

  list(httpParams: any = {}): Observable<any> {
    return this.httpHandler
      .list(this.endpoint, httpParams)
      .pipe(map((response) => response.data));
  }

  selectPartnerNames(httpParams: any = {}): Observable<any> {
    return this.httpHandler
      .list('partners/select', httpParams)
      .pipe(map((response) => response));
  }

  show(id: any): Observable<any> {
    return this.http.get(this.environmentService.apiUrl + 'partners/' + id, {
      responseType: 'json',
    });
  }

  update(formData: any, leadBackendId: number): Observable<any> {
    return this.http.put(
      this.environmentService.apiUrl + 'partners/' + leadBackendId,
      formData,
      { responseType: 'json' },
    );
  }

  create(formData: any): Observable<any> {
    return this.http.post(
      this.environmentService.apiUrl + 'partners',
      formData,
      {
        responseType: 'json',
      },
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete(this.environmentService.apiUrl + 'partners/' + id, {
      responseType: 'json',
    });
  }

  changePassword(formData: any): Observable<any> {
    return this.http.post(
      this.environmentService.apiUrl + 'forgot_password',
      formData,
      {
        responseType: 'json',
      },
    );
  }

  sendRegistrationEmail(partnerUserId: number): Observable<any> {
    return this.http.get(
      this.environmentService.apiUrl + 'email/verify/' + partnerUserId,
      {
        responseType: 'json',
      },
    );
  }
}
