<div *ngIf="!containsData" fxLayout="row" fxLayoutAlign="center center">
  <em>Mit den hinterlegten Filtern konnten keine Daten gefunden werden.</em>
</div>
<div *ngIf="containsData">
  <canvas
    baseChart
    [height]="this.height"
    [type]="'pie'"
    id="pie-chart"
    [datasets]="pieChartDatasets"
    [labels]="pieChartLabels"
    [options]="pieChartOptions"
    [plugins]="pieChartPlugins"
    [legend]="pieChartLegend"
  >
  </canvas>
</div>
