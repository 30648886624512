<div *ngIf="resetPasswordMode">
  <lib-reset-password
    (newPasswordSavedEmitter)="resetPasswordMode = !resetPasswordMode"
  ></lib-reset-password>
</div>

<div
  class="d-flex justify-content-center align-items-center vh-100"
  *ngIf="!resetPasswordMode"
>
  <div class="col-sm-8 col-md-6 col-lg-4 col-xl-3">
    <div class="card">
      <div class="card-body p-5">
        <div class="text-center mb-4">
          <h4 class="fw-bolder fs-3">{{ pageTitle }}</h4>
        </div>
        <div id="sc-login-form">
          <!-- Angular Material Form -->
          <form
            name="form"
            (ngSubmit)="f.form.valid && onSubmit()"
            #f="ngForm"
            novalidate
          >
            <div class="form-group mb-3" *ngIf="forgotPasswordMode">
              Für welche E-Mailadresse soll das Passwort zurückgesetzt werden?
            </div>

            <div class="form-group mb-3">
              <label for="email" class="fw-normal fs-5">Email-Adresse</label>
              <input
                type="text"
                id="email"
                class="form-control"
                name="email"
                [(ngModel)]="form.email"
                required
                #email="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && email.errors }"
              />
              <div *ngIf="email.errors && f.submitted" class="invalid-feedback">
                Email ist erforderlich!
              </div>
            </div>
            <div class="form-group mb-3" *ngIf="!forgotPasswordMode">
              <label for="password" class="fw-normal fs-5">Passwort</label>
              <input
                id="password"
                type="password"
                class="form-control"
                name="password"
                [(ngModel)]="form.password"
                required
                minlength="4"
                #password="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && password.errors }"
              />
              <div
                *ngIf="password.errors && f.submitted"
                class="invalid-feedback"
              >
                <div *ngIf="password.errors['required']">
                  Password ist erforderlich
                </div>
                <div *ngIf="password.errors['minlength']">
                  Das Passwort muss mindestens 4 Zeichen lang sein.
                </div>
              </div>
            </div>
            <div class="form-group text-center">
              <button class="btn btn-primary btn-block btn-full-width">
                <span *ngIf="!forgotPasswordMode">Anmelden</span>
                <span *ngIf="forgotPasswordMode">Passwort zurücksetzen</span>
              </button>
            </div>
            <ng-container *ngIf="pageTitle === 'Salesrunner'">
              <div
                class="form-group text-end"
                style="margin-top: 10px; cursor: pointer"
                *ngIf="!forgotPasswordMode"
                (click)="clickForgotPassword()"
              >
                Passwort vergessen
              </div>

              <div
                class="form-group text-end"
                style="margin-top: 10px; cursor: pointer"
                *ngIf="forgotPasswordMode"
                (click)="clickForgotPassword()"
              >
                Zurück zum Login
              </div>
            </ng-container>

            <div class="form-group mt-3">
              <div
                *ngIf="f.submitted && isLoginFailed"
                class="alert alert-danger"
                role="alert"
              >
                Etwas schief gelaufen: {{ errorMessage }}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
