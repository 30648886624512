import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHandlerService } from '@lib/services/http-handler.service';
import { Lead } from '@lib/models/leads/lead';

@Injectable({
  providedIn: 'root',
})
export class LeadFeedbackService {
  endpoint: string = 'get_lead_feedbacks/';
  endpointCreateOrUpdate: string = 'lead_feedback/';
  constructor(
    private httpHandler: HttpHandlerService,
    protected http: HttpClient,
  ) {}

  datatable(dataTablesParams: any): Observable<any> {
    return this.httpHandler.datatable(this.endpoint, dataTablesParams).pipe(
      map((response) => {
        return {
          recordsFiltered: response.recordsFiltered,
          recordsTotal: response.recordsTotal,
          data: response.data.map((item: Lead) => new Lead(item)),
        };
      }),
    );
  }
  list(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  show(id: number): Observable<any> {
    return this.httpHandler.show(this.endpoint, id);
  }

  create(httpParams: any): Observable<any> {
    return this.httpHandler.create(this.endpointCreateOrUpdate, httpParams);
  }

  update(httpParams: any): Observable<any> {
    return this.httpHandler.update(this.endpointCreateOrUpdate, httpParams);
  }

  delete(id: number): Observable<any> {
    return this.httpHandler.delete(this.endpointCreateOrUpdate, id);
  }
}
