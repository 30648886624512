export class TargetingBudget {
  id: number = 0;
  targeting_id: number = 0;
  status: number = 0;
  daily_budget: number = 0;
  weekly_budget: number = 0;
  monthly_budget: number = 0;

  [key: string]: number | undefined;

  constructor() {}
}
