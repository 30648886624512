<div class="dropdown-menu" cdkMenu>
  <ng-container *ngFor="let item of menuItems">
    <button
      class="menu-item"
      cdkMenuItem
      [cdkMenuTriggerFor]="item.children ? firstChildrenTemplate : null"
      (mouseenter)="
        item.children ? setFirstChildren(item.children) : setFirstChildren([])
      "
      (click)="handleMenuClick($event, item)"
      (mousedown)="handleMouseDown($event, item)"
    >
      <mat-icon *ngIf="item.icon" class="menu-icon">{{ item.icon }}</mat-icon>
      <span class="menu-label">{{ item.label }}</span>
      <span *ngIf="item.children" class="menu-arrow">›</span>
    </button>
  </ng-container>
</div>

<ng-template #firstChildrenTemplate>
  <div class="dropdown-menu submenu" cdkMenu>
    <ng-container *ngFor="let child of selectedFirstChildren">
      <button
        class="menu-item"
        cdkMenuItem
        [cdkMenuTriggerFor]="child.children ? secondChildrenTemplate : null"
        (mouseenter)="
          child.children
            ? setSecondChildren(child.children)
            : setSecondChildren([])
        "
        (click)="handleMenuClick($event, child)"
        (mousedown)="handleMouseDown($event, child)"
      >
        <mat-icon *ngIf="child.icon" class="menu-icon">{{
          child.icon
        }}</mat-icon>
        <span class="menu-label">{{ child.label }}</span>
        <span *ngIf="child.children" class="menu-arrow">›</span>
      </button>
    </ng-container>
  </div>
</ng-template>

<ng-template #secondChildrenTemplate>
  <div class="dropdown-menu submenu" cdkMenu>
    <ng-container *ngFor="let subChild of selectedSecondChildren">
      <button
        class="menu-item"
        cdkMenuItem
        (click)="handleMenuClick($event, subChild)"
        (mousedown)="handleMouseDown($event, subChild)"
      >
        <mat-icon *ngIf="subChild.icon" class="menu-icon">{{
          subChild.icon
        }}</mat-icon>
        <span class="menu-label">{{ subChild.label }}</span>
      </button>
    </ng-container>
  </div>
</ng-template>
