import { Component } from '@angular/core';

@Component({
  selector: 'lib-complex-card-example',
  template: ComplexCardExample.HTML_CODE,
  styles: [
    `
      .task-list {
        padding: 16px 0;
      }
    `,
  ],
})
export class ComplexCardExample {
  static HTML_CODE = `
    <app-card>
      <app-card-head pageTitle="Aufgabenverwaltung">
        <button mat-raised-button color="primary">
          <mat-icon>add</mat-icon> Neue Aufgabe
        </button>
      </app-card-head>
      <app-card-body>
        <div class="task-list">
          <mat-list>
            <mat-list-item>
              <mat-icon matListItemIcon>check_circle</mat-icon>
              <span matListItemTitle>Dashboard erstellen</span>
              <span matListItemLine>Erledigt am 20.01.2025</span>
            </mat-list-item>
            <mat-list-item>
              <mat-icon matListItemIcon>schedule</mat-icon>
              <span matListItemTitle>API-Integration</span>
              <span matListItemLine>In Bearbeitung</span>
            </mat-list-item>
          </mat-list>
        </div>
      </app-card-body>
    </app-card>
  `;
}
