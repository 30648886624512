<button
  mat-icon-button
  [cdkMenuTriggerFor]="mainMenu"
  aria-label="Context menu"
>
  <mat-icon>more_vert</mat-icon>
</button>

<ng-template #mainMenu>
  <lib-context-menu-items
    class="context-menu"
    cdkMenu
    [untyped]="untyped"
    [lead]="lead"
    [partner_lead]="partner_lead"
    [partner_bill]="partner_bill"
  >
  </lib-context-menu-items>
</ng-template>
