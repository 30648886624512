<div
  class="icon-container"
  [ngClass]="['icon-' + size, 'icon-' + variant, animated ? 'animated' : '']"
>
  <div class="icon-wrapper" [innerHTML]="icon$ | async"></div>

  <ng-container *ngFor="let badge of badges">
    <div class="badge-wrapper" [ngClass]="badge.position">
      <div
        class="badge-icon"
        [ngStyle]="{
          width: getBadgeSize(),
          height: getBadgeSize()
        }"
        [innerHTML]="getBadgeIcon$(badge) | async"
      ></div>
    </div>
  </ng-container>
</div>
