<div class="filter-container">
  <ng-container *ngFor="let config of filterSettings">
    <ng-container [ngSwitch]="config.filter">
      <div *ngSwitchCase="SelectList.DateRange" class="filter-item date-range">
        <app-daterange-picker-helper
          [startDate]="dateRange.start"
          [endDate]="dateRange.end"
          (dateRange)="setDateRange($event)"
        ></app-daterange-picker-helper>
      </div>

      <ng-container *ngSwitchDefault>
        <ng-container *ngIf="getOptions(config.filter) | async as options">
          <div *ngIf="options.length > 0" class="filter-item">
            <lib-select
              class="lib-select"
              [placeholder]="getPlaceholderValue(config)"
              [multiSelect]="getMultiSelectValue(config)"
              [disabled]="getDisabledValue(config)"
              [allowNegative]="getAllowNegativeValue(config)"
              [options]="options"
              [selectedOptions]="getSelectedOptions(config.filter)"
              [hasNegativeValues]="getHasNegativeValues(config.filter)"
              [clearable]="getClearableValue(config)"
              (valueChanged)="updateSelectOutput($event, config.filter)"
              [selectList]="config.filter"
            ></lib-select>

            <button
              *ngIf="isDetailFilterButton(config.filter)"
              mat-mini-fab
              color="primary"
              class="detail-filter-button"
              (click)="showLeadDetailFilterModal(config.filter)"
            >
              <mat-icon>filter_list</mat-icon>
            </button>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
