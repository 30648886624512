import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { LeadStateService } from '../../../services/lead-state.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PanelStateService } from '../../../services/panel-state.service';

@Component({
  selector: 'app-lead-list-new',
  templateUrl: './lead-list-new.component.html',
  styleUrls: ['./lead-list-new.component.scss'],
})
export class LeadListNewComponent implements OnInit, OnDestroy {
  selectedLeadId$ = this.leadStateService.selectedLeadId$;
  private destroy$ = new Subject<void>();

  get initialPanelWidth(): number {
    return this.panelStateService.getCurrentWidth();
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private leadStateService: LeadStateService,
    private panelStateService: PanelStateService,
  ) {}

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        if (params['id']) {
          this.leadStateService.setSelectedLead(Number(params['id']));
          this.panelStateService.setWidth(
            this.panelStateService.getCurrentWidth(),
            true,
          );
        } else {
          this.leadStateService.setSelectedLead(null);
        }
      });

    this.selectedLeadId$.pipe(takeUntil(this.destroy$)).subscribe((id) => {
      if (id !== null) {
        this.panelStateService.ensureDetailViewWidth();
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { id },
          queryParamsHandling: 'merge',
        });
      } else {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {},
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onWidthChange(width: number) {
    this.panelStateService.setWidth(width);
  }
}
