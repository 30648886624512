import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHandlerService } from '@lib/services/http-handler.service';
import { EnvironmentService } from '@lib/services/environment.service';

export interface FreeFibuResult {
  id: string;
  type: string;
  description: string;
  error?: boolean;
  message?: string;
  success?: boolean;
}

export interface FreeFibuResponse {
  results: FreeFibuResult[];
  hasErrors: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AccountingService {
  endpoint: string = 'accounting/';

  constructor(
    private httpHandler: HttpHandlerService,
    protected http: HttpClient,
    private environmentService: EnvironmentService,
  ) {}

  list(httpParams: any = {}): Observable<any> {
    return this.httpHandler
      .list(this.endpoint, httpParams)
      .pipe(map((response) => response.data));
  }

  tags(httpParams: any = {}): Observable<any> {
    return this.httpHandler
      .list(this.endpoint + 'tags', httpParams)
      .pipe(map((response) => response.data));
  }

  sendAccountingEntriesToFreeFibu(
    documents: any[],
  ): Observable<FreeFibuResponse> {
    return this.http.post<FreeFibuResponse>(
      this.environmentService.apiUrl + this.endpoint + 'entries',
      { documents },
    );
  }

  /**
   * Lädt ein Dokument herunter und öffnet es im Browser
   * @param documentId Die ID des Dokuments
   */
  downloadDocument(documentId: string): Observable<void> {
    return this.http
      .get(
        `${this.environmentService.apiUrl}${this.endpoint}document/${documentId}`,
        { responseType: 'blob' },
      )
      .pipe(
        map((response: Blob) => {
          const url = window.URL.createObjectURL(response);
          window.open(url, '_blank');
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
          }, 100);
        }),
      );
  }
}
