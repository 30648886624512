import { LeadType } from '../lead-type';
import { TargetingGeo } from './targeting-geo';
import { TargetingUser } from './targeting-user';
import { TargetingDetail } from './targeting-detail';
import { TargetingBudget } from './targeting-budget';

export class Targeting {
  id: number = 0;
  area: string = '';
  bid_adjustment: string = '';
  contact_data: string = '';
  description: string = '';
  description_partner: string = '';
  email_receivers: string = '';
  excluded_area: string = '';
  excluded_area_feature_collection: any = {};
  included_area_feature_collection: any = {};
  included_area: string = '';
  lead_csv_attachment: number = 0;
  lead_type: LeadType = new LeadType({});
  lead_type_id: number = 0;
  partner_id: number = 0;
  partner_leads_count: number = 0;
  geo_targeting_address_type: number = 1;
  potential: number = 0;
  status: string = '0';
  sms_receivers: string = '';
  targeting_geos: TargetingGeo[] = [];
  targeting_users: TargetingUser[] = [];
  targeting_details: TargetingDetail[] = [];
  targeting_budgets: TargetingBudget[] = [];
  selected_inspector_types: string[] = [];
  unselected_inspector_types: string[] = [];

  constructor(targeting: Targeting | {}) {
    Object.assign(this, targeting);
  }
}
