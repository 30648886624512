import { Component, Input, OnInit } from '@angular/core';
import { TargetingBudgetService } from '../../services/targeting-budget.service';
import { TargetingBudget } from '../../models/targeting/targeting-budget';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-partner-budget-form',
  templateUrl: './partner-budget-form.component.html',
  styleUrls: ['./partner-budget-form.component.scss'],
})
export class PartnerBudgetFormComponent implements OnInit {
  @Input() targetingId: number = 0;
  targetingBudgets$: Observable<TargetingBudget[]> = new Observable();

  constructor(private targetingBudgetService: TargetingBudgetService) {}

  ngOnInit() {
    this.targetingBudgets$ = this.targetingBudgetService.getListByTargetingId(
      this.targetingId,
    );

    this.targetingBudgets$.subscribe((targetingBudgets) => {});
  }
}
