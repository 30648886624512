import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Lead } from '../../models/leads/lead';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ApplicationHelper } from '../../helpers/application-helper';
import { Application } from '../../enum-collection';
import { FeedService } from '../../services/feed.service';
import { PartnerLead } from '../../models/partner-lead';
import { Feed } from '../../models/feed';
import { FeedItemService } from '../../services/feed-item.service';
import { CommentComponent } from '../comment/comment.component';

@Component({
  selector: 'lib-feed-container',
  templateUrl: './feed-container.component.html',
  styleUrls: ['./feed-container.component.scss'],
})
export class FeedContainerComponent implements OnInit, OnDestroy {
  @Input() lead$: Observable<Lead> = new Observable<Lead>();
  @Input() partnerLead$: Observable<PartnerLead> =
    new Observable<PartnerLead>();
  @Output() feedUpdated = new EventEmitter<void>();

  @ViewChild('comment') commentComponent?: CommentComponent;
  @ViewChildren(CommentComponent)
  commentComponents!: QueryList<CommentComponent>;

  currentFilter$ = new BehaviorSubject<string | undefined>(undefined);
  feedItems$ = new BehaviorSubject<Feed[]>([]);

  private destroy$ = new Subject<void>();

  constructor(
    private feedService: FeedService,
    private feedItemService: FeedItemService,
  ) {}

  ngOnInit(): void {
    this.loadFeed();

    this.feedItemService
      .getFeedUpdated()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loadFeed();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  applyFilter(filter: string | undefined): void {
    this.currentFilter$.next(filter);
  }

  loadFeed() {
    const source$ =
      ApplicationHelper.applicationName === Application.Leadmanager
        ? this.lead$.pipe(
            switchMap((lead) =>
              lead?.id ? this.feedService.getLeadFeed(lead.id) : [],
            ),
          )
        : this.partnerLead$.pipe(
            switchMap((partnerLead) =>
              partnerLead?.id
                ? this.feedService.getPartnerLeadFeed(partnerLead.id)
                : [],
            ),
          );

    source$.pipe(takeUntil(this.destroy$)).subscribe((feeds) => {
      this.feedItems$.next(feeds);
      this.feedUpdated.emit();
    });
  }

  getRelatableTypeForPost(): string {
    if (ApplicationHelper.applicationName === Application.Leadmanager) {
      return 'Lead';
    }

    return 'PartnerLead';
  }

  onComponentChanged(): void {
    this.feedItemService.emitFeedUpdated();
  }

  async checkForUnsavedComments(): Promise<boolean> {
    return this.hasUnsavedCommentsSync();
  }

  hasUnsavedCommentsSync(): boolean {
    if (this.commentComponent?.hasUnsavedChanges) {
      return true;
    }

    return (
      this.commentComponents?.some(
        (component: CommentComponent) => component.hasUnsavedChanges,
      ) ?? false
    );
  }

  protected readonly Lead = Lead;
  protected readonly Application = Application;
  protected readonly ApplicationHelper = ApplicationHelper;
}
