import { User } from './user';

export class LeadHistory {
  id: number = 0;
  lead_id: number = 0;
  user_id: number = 0;
  status: number = 0;
  user: User = new User({});
  created_at: Date = new Date();
  updated_at: Date = new Date();
  status_option: LeadHistoryStatusOption = new LeadHistoryStatusOption();
}

export class LeadHistoryStatusOption {
  id: number = 0;
  optionlist_id: number = 0;
  option_id: number = 0;
  name: string = '';
  created_at: Date = new Date();
  updated_at: Date = new Date();
  order: number = 0;
  disabled: number = 0;
}
