<div class="map-container">
  <div *ngIf="!mapLoaded" class="map-loading">Karte wird geladen...</div>
  <google-map
    height="400px"
    width="100%"
    [options]="mapOptions"
    (mapInitialized)="onMapInitialized($event)"
  >
  </google-map>
</div>
