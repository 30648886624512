<mat-toolbar
  [ngClass]="{ 'lead-toolbar-dev': !environment.production }"
  class="lead-toolbar fs-6"
>
  <ng-container
    *ngIf="ApplicationHelper.applicationName === Application.Leadmanager"
  >
    <span>{{ ApplicationHelper.applicationName }}</span>
    <lib-phone-user-status></lib-phone-user-status>
    <span class="example-spacer"></span>
    <mat-icon>person</mat-icon>
    <ng-container *ngIf="user$ | async as user">
      {{ user?.name }}
    </ng-container>
  </ng-container>

  <ng-container
    *ngIf="ApplicationHelper.applicationName === Application.Salesrunner"
  >
    <mat-icon>directions_run</mat-icon>
    <span>{{ ApplicationHelper.applicationName }}</span>
    <span class="example-spacer"></span>
    <mat-icon>person</mat-icon>
    <ng-container *ngIf="partnerUser$ | async as user">
      {{ user?.first_name + ' ' + user?.last_name }}
    </ng-container>
  </ng-container>

  <button
    (click)="logout()"
    aria-label="Abmelden"
    class="logout-area example-icon favorite-icon"
    mat-icon-button
  >
    <mat-icon>logout</mat-icon>
  </button>

  <mat-progress-bar
    *ngIf="loading$ | async"
    class="full-width-loader"
    color="accent"
    mode="query"
  ></mat-progress-bar>
</mat-toolbar>

<mat-toolbar *ngIf="isNewAppVersionAvailable" class="lead-second-toolbar">
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-icon>verified_user</mat-icon>
    <span class="mx-2">
      <b>Update verfügbar!</b> Bitte speichern Sie Ihre Arbeit und klicken Sie
      dann
    </span>
    <button mat-flat-button color="primary" (click)="refreshApp()">hier</button>
    <span>um die Seite neu zu laden.</span>
    <mat-icon>update</mat-icon>
  </div>
</mat-toolbar>
