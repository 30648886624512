import { TableService } from './table.service';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';
import { EnvironmentService } from './environment.service';

export function tableServiceFactory(
  http: HttpClient,
  storageService: StorageService,
  environmentService: EnvironmentService,
  tableId: string,
) {
  return new TableService(http, storageService, environmentService, tableId);
}
