import { Partner } from '../partner';

export class LeadFeedBacks {
  id: number = 0;
  lead_id: number = 0;
  partner_lead_id: number = 0;
  partner_id: number = 0;
  user_id: number = 0;
  contact: any = null;
  first_contact: any = null;
  offer: any = null;
  offer_amount: any = null;
  decision: any = null;
  decision_reason: string = '';
  created_at: string = '';

  partner?: Partner | null = null;

  constructor(lead_feed_backs: LeadFeedBacks | {}) {
    Object.assign(this, lead_feed_backs);
  }
}
