import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AuthService } from './auth.service';
import { TargetingsService } from '../../../leadmanager/src/app/_services/targetings/targetings.service';
import {
  MenuBuilderParams,
  MenuContext,
  ContextMenuItem,
} from '../models/context-menu-item';
import { PartnerLead } from '../models/partner-lead';
import { UrlHelper } from '../helpers/url-helper';
import { PartnerBill } from '../models/partners/partner-bill';
import { Lead } from '../models/leads/lead';
import { Partner } from '../models/partner';

type EntityTypes = {
  leads: Set<Lead>;
  partner_leads: Set<PartnerLead>;
  partners: Set<Partner>;
  bills: Set<PartnerBill>;
};

@Injectable({
  providedIn: 'root',
})
export class ContextMenuBuilderService {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly targetingService: TargetingsService,
  ) {}

  buildMenu(params: MenuBuilderParams): ContextMenuItem[] {
    const context = this.createContext(params);

    const items: (ContextMenuItem | null)[] = [];

    if (context.leadId) {
      items.push(this.buildLeadMenuItem(context.leadId));
    }

    if (context.currentPartnerLead) {
      items.push(
        this.buildPartnerMenuItem(context.currentPartnerLead),
        this.buildSelectedTargetingMenuItem(context.currentPartnerLead),
        this.buildOpenInSalesrunnerMenuItem(context.currentPartnerLead),
        this.buildBillMenuItem(context.currentPartnerLead),
      );
    } else if (context.partnerLeads.length > 0) {
      items.push(this.buildPartnerLeadsGroupMenuItem(context.partnerLeads));
    }

    return items.filter(
      (item): item is ContextMenuItem => item !== null && item.visible === true,
    );
  }

  private buildPartnerMenuItem(
    partnerLead: PartnerLead,
  ): ContextMenuItem | null {
    if (!partnerLead.partner_id) return null;

    return {
      id: `show-partner-${partnerLead.id}`,
      icon: 'work',
      label: 'Partner zeigen',
      visible: true,
      action: (event) =>
        this.navigate('/partners/edit/:id', [partnerLead.partner_id], event),
    };
  }

  private buildSelectedTargetingMenuItem(
    partnerLead: PartnerLead,
  ): ContextMenuItem | null {
    if (!partnerLead.partner_id || !partnerLead.targeting_id) return null;

    return {
      id: `show-partner-${partnerLead.id}`,
      icon: 'location_searching',
      label: 'Verwendetes Targeting zeigen',
      visible: true,
      action: (event) =>
        this.navigate(
          '/partners/edit/:partnerId/targeting/edit/:targetingId',
          [partnerLead.partner_id, partnerLead.targeting_id],
          event,
        ),
    };
  }

  private buildOpenInSalesrunnerMenuItem(
    partnerLead: PartnerLead,
  ): ContextMenuItem | null {
    if (!partnerLead.id) return null;

    const shouldShowInChanceList =
      partnerLead.status &&
      (partnerLead.status === 8 || partnerLead.status === 7);

    return {
      id: `show-in-salesrunner-${partnerLead.id}`,
      icon: 'open_in_new',
      label: 'Im Salesrunner zeigen',
      visible: true,
      action: (event) =>
        this.navigateToSalesrunner(
          shouldShowInChanceList ? 'chance_list' : 'leads-new',
          partnerLead,
          undefined,
          event,
        ),
    };
  }

  private buildBillMenuItem(partnerLead: PartnerLead): ContextMenuItem | null {
    if (!partnerLead.id) return null;

    if (
      partnerLead.status &&
      (partnerLead.status === 7 || partnerLead.status === 8)
    )
      return null;

    return {
      id: `create-bill-${partnerLead.id}`,
      icon: 'euro_symbol',
      label: 'Rechnungen (erstellen)',
      visible: true,
      action: (event) =>
        this.navigateToSalesrunner(
          'partner_bills/create',
          partnerLead,
          undefined,
          event,
        ),
    };
  }

  private buildLeadMenuItem(leadId?: number): ContextMenuItem | null {
    if (!leadId) return null;

    return {
      id: `show-lead`,
      icon: 'person',
      label: 'Lead öffnen',
      visible: true,
      action: (event) => this.navigate('/leads/edit/:id', [leadId], event),
    };
  }

  private buildPartnerLeadsGroupMenuItem(
    partnerLeads: PartnerLead[],
  ): ContextMenuItem {
    return {
      id: 'partner-leads',
      icon: 'group',
      label: 'Partner Leads',
      visible: true,
      children: partnerLeads.map((pl) => ({
        id: `partner-lead-${pl.id}`,
        icon: 'account_circle',
        label: pl.partner?.name ?? 'Unbenannter Partner',
        visible: true,
        children: [
          this.buildPartnerMenuItem(pl),
          this.buildSelectedTargetingMenuItem(pl),
          this.buildOpenInSalesrunnerMenuItem(pl),
          this.buildBillMenuItem(pl),
        ].filter(
          (item): item is ContextMenuItem =>
            item !== null && item.visible === true,
        ),
      })),
    };
  }

  private async navigate(
    path: string,
    params: number[],
    event?: MouseEvent,
  ): Promise<void> {
    if (!params.length) return;

    let paramIndex = 0;
    const routeParts: string[] = [];
    const pathParts = path.replace(/^\//, '').split('/');

    pathParts.forEach((part) => {
      if (part.startsWith(':')) {
        if (paramIndex < params.length) {
          routeParts.push(params[paramIndex].toString());
          paramIndex++;
        }
      } else {
        routeParts.push(part);
      }
    });

    const url = this.router.serializeUrl(this.router.createUrlTree(routeParts));
    const finalUrl = UrlHelper.getUrlWithBasePath(url);

    if (event?.ctrlKey || event?.metaKey || event?.button === 1) {
      window.open(finalUrl, '_blank');
      return;
    }

    await this.router.navigate(routeParts);
  }

  private async navigateToSalesrunner(
    path: string,
    partnerLead?: PartnerLead,
    billId?: number,
    event?: MouseEvent,
  ): Promise<void> {
    if (!partnerLead?.targeting_id) return;

    const targeting = await firstValueFrom(
      this.targetingService.show(partnerLead.targeting_id),
    );

    if (!targeting?.targeting_users?.length) return;

    const baseUrl = '/salesrunner/#/';
    const pathMap = {
      'partner_bills/create': `partner_bills/create/${partnerLead.id}`,
      'partner_bills/view': billId ? `partner_bills/${billId}` : '',
      'leads-new': `leads-new?id=${partnerLead.id}`,
      chance_list: `chance_list?id=${partnerLead.id}`,
    };

    const url = baseUrl + (pathMap[path as keyof typeof pathMap] || '');
    const openInNewTab =
      event?.ctrlKey || event?.metaKey || event?.button === 1;

    await this.authService.loginAsPartnerUser(
      targeting.targeting_users[0].partner_user_id,
      url,
      openInNewTab,
    );
  }

  private createContext(params: MenuBuilderParams): MenuContext {
    const context: MenuContext = {
      leadId: undefined,
      partnerLeadId: undefined,
      partnerBillId: undefined,
      partnerId: undefined,
      partnerLeads: [],
      currentPartnerLead: undefined,
    };

    const inputObject = this.getInputObject(params);
    if (!inputObject) return context;

    const entities = this.findEntities(inputObject);
    return this.buildContextFromEntities(entities, context);
  }

  private getInputObject(params: MenuBuilderParams): any {
    if (params.untyped) return params.untyped;

    const keys: (keyof MenuBuilderParams)[] = [
      'lead',
      'partner_lead',
      'partner_bill',
    ];
    for (const key of keys) {
      if (params[key] && typeof params[key] === 'object') {
        return params[key];
      }
    }

    return keys
      .map((key) => params[key])
      .find((value) => value !== null && value !== undefined);
  }

  private findEntities(obj: any): EntityTypes {
    const entities: EntityTypes = {
      leads: new Set<Lead>(),
      partner_leads: new Set<PartnerLead>(),
      partners: new Set<Partner>(),
      bills: new Set<PartnerBill>(),
    };

    this.searchObject(obj, entities);
    return entities;
  }

  private searchObject(
    obj: any,
    entities: EntityTypes,
    visited = new Set(),
  ): void {
    if (!obj || typeof obj !== 'object' || visited.has(obj)) return;
    visited.add(obj);

    // Alle Type-Checks durchführen und entsprechend in entities speichern
    if (this.isLead(obj)) entities.leads.add(obj);
    if (this.isPartnerLead(obj)) entities.partner_leads.add(obj);
    if (this.isPartner(obj)) entities.partners.add(obj);
    if (this.isPartnerBill(obj)) entities.bills.add(obj);

    // Rekursiv durch alle Properties gehen
    for (const key in obj) {
      if (obj[key] && typeof obj[key] === 'object') {
        if (Array.isArray(obj[key])) {
          obj[key].forEach((item: any) =>
            this.searchObject(item, entities, visited),
          );
        } else {
          this.searchObject(obj[key], entities, visited);
        }
      }
    }
  }

  private buildContextFromEntities(
    entities: EntityTypes,
    context: MenuContext,
  ): MenuContext {
    const bills = Array.from(entities.bills);
    const partnerLeads = Array.from(entities.partner_leads);
    const partners = Array.from(entities.partners);
    const leads = Array.from(entities.leads);

    // Wenn wir eine Bill haben
    if (bills.length > 0) {
      const bill = bills[0];
      context.partnerBillId = bill.id;

      // Finde den zugehörigen PartnerLead
      const relatedPartnerLead = partnerLeads.find(
        (pl) => pl.id === bill.partner_lead_id,
      );
      if (relatedPartnerLead) {
        context.currentPartnerLead = relatedPartnerLead;
        context.partnerLeadId = relatedPartnerLead.id;

        // Setze auch die Lead ID wenn vorhanden
        if (relatedPartnerLead.lead_id) {
          context.leadId = relatedPartnerLead.lead_id;
        }
      }
    }

    // Wenn wir Partner haben
    if (partners.length > 0 && !context.partnerId) {
      context.partnerId = partners[0].id;
    }

    // Wenn wir Leads haben
    if (leads.length > 0 && !context.leadId) {
      context.leadId = leads[0].id;
    }

    // Sammle alle PartnerLeads
    if (partnerLeads.length > 0) {
      context.partnerLeads = partnerLeads.filter((pl) => pl?.partner);
    }

    return context;
  }

  private isLead(obj: any): obj is Lead {
    return (
      obj &&
      typeof obj === 'object' &&
      'request_at' in obj &&
      'number' in obj &&
      'lead_type_id' in obj
    );
  }

  private isPartnerLead(obj: any): obj is PartnerLead {
    return (
      obj &&
      typeof obj === 'object' &&
      'offer_value' in obj &&
      'targeting_id' in obj &&
      'partner_id' in obj
    );
  }

  private isPartner(obj: any): obj is Partner {
    return (
      obj &&
      typeof obj === 'object' &&
      'easybill_customer_id' in obj &&
      'acquisition_status_id' in obj
    );
  }

  private isPartnerBill(obj: any): obj is PartnerBill {
    return (
      obj &&
      typeof obj === 'object' &&
      'bill_number' in obj &&
      'bill_date' in obj
    );
  }
}
