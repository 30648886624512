import { Lead } from './leads/lead';

export class Call {
  id: number = 0;
  lead_id: number = 0;
  callId: string = '';
  direction: string = '';
  duration: number = 0;
  event: string = '';
  event_at: string = '';
  hangUpCause: string = '';
  answeringNumber: string = '';
  from: string = '';

  constructor(call: Call | {}) {
    // Initialize any other properties or perform additional setup if needed
    Object.assign(this, call);
  }
}

export class CallData {
  incomming: IncomingCall[] = [];
  users: IncomingCallUser[] = [];
}

export class IncomingCall {
  id: number = 0;
  event: string = '';
  from: string = '';
  to: string = '';
  direction: string = '';
  call_id: string = '';
  user: string = '';
  userId: string = '';
  fullUserId: string = '';
  answeringNumber: string = '';
  hangUpCause: string = '';
  event_at: string = '';
  duration: number = 0;
  lead_id: number = 0;
  user_id: number = 0;
  user_name: string = '';
  time_to_first_call: string = '';
  lead: Lead = new Lead({});
}

export class IncomingCallUser {
  name: string = '';
  first_name: string = '';
  user_id: number = 0;
  last_name: string = '';
  presence: string = '';
  status: string = '';
  direction: string = '';
  duration: string = '';
  event_at: string = '';
  leadmanager_status: string = '';
  duration_format: string = '';
  last_call_since: string = '';
  last_call_since_format: string = '';
  last_call_event_at: string = '';
  last_call: LastCall = new LastCall();
  lead: Lead = new Lead({});
}

export class LastCall {
  id: number = 0;
  event: string = '';
  from: string = '';
  to: string = '';
  direction: string = '';
  callId: string = '';
  user: string = '';
  userId: string = '';
  fullUserId: string = '';
  answeringNumber: string = '';
  hangUpCause: string = '';
  event_at: string = '';
  duration: number = 0;
  lead_id: number = 0;
  user_id: number = 0;
  user_name: string = '';
  time_to_first_call: string = '';
  lead: Lead = new Lead({});
}
