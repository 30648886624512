import { Injectable } from '@angular/core';
import { Feed } from '../models/feed';
import { AuthService } from './auth.service';
import { ReactionService } from './reaction.service';
import { Reaction } from '../models/reaction';
import { ApplicationHelper } from '../helpers/application-helper';
import { Application } from '../enum-collection';
import { User } from '../models/user';
import { PartnerUser } from '../models/partners/partner-user';

@Injectable({
  providedIn: 'root',
})
export class EmojiPickerService {
  constructor(
    private authService: AuthService,
    private reactionService: ReactionService,
  ) {}

  removeReaction(
    currentUserReaction: Reaction | null,
    onSuccess: () => void,
  ): void {
    if (!currentUserReaction?.id) return;

    this.reactionService
      .deleteReaction(currentUserReaction.id)
      .subscribe(() => {
        onSuccess();
      });
  }

  onEmojiSelect(
    event: any,
    relatableId: number,
    relatableType: string,
    onSuccess: () => void,
  ): void {
    if (!relatableId) return;

    this.authService.getCurrentUser().subscribe((user) => {
      if (!user) return;

      const reaction = new Reaction();
      reaction.emoji = event.emoji.native;
      reaction.relatable_type = relatableType;
      reaction.relatable_id = relatableId;

      if (ApplicationHelper.applicationName === Application.Leadmanager) {
        reaction.user_id = user.id;
      } else {
        reaction.partner_user_id = user.id;
      }

      this.reactionService.createReaction(reaction).subscribe(() => {
        onSuccess();
      });
    });
  }

  private getRelatableType(feed: Feed): string {
    return feed.relatable_type.split('App\\')[1];
  }
}
