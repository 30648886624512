import { Component, OnInit, ViewChild } from '@angular/core';
import { Targeting } from '@lib/models/targeting/targeting';
import { PartnerUser } from '@lib/models/partners/partner-user';
import { PartnerUserService } from '@lib/services/partner/partner-user.service';
import { LeadField } from '@lib/models/leads/lead-field';
import { LeadFieldService } from '@lib/services/leadfields/leadfields.service';
import { OptionlistsService } from '@lib/services/option-lists/optionlists.service';
import { Optionlist } from '@lib/models/optionlist/optionlist';
import { EditTargetingDetailModalComponent } from './edit-targeting-detail-modal/edit-targeting-detail-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TargetingDetail } from '@lib/models/targeting/targeting-detail';
import { TableComponent } from '@lib/components/table/table.component';

@Component({
  selector: 'app-targeting-list',
  templateUrl: './targeting-list.component.html',
  styleUrls: ['./targeting-list.component.scss'],
})
export class TargetingListComponent implements OnInit {
  @ViewChild(TableComponent) tableComponent!: TableComponent;

  restriction = { lead_type_id: 14, status: 1 };
  targetings: Targeting[] = [];
  leadFields: LeadField[] = [];
  partnerUserIsAdmin = false;
  inspectorTypeOptionlist: Optionlist[] = [];
  map: any;

  constructor(
    private optionlistsService: OptionlistsService,
    private partnerUserService: PartnerUserService,
    public dialog: MatDialog,
    private leadFieldService: LeadFieldService,
  ) {}

  ngOnInit() {
    this.leadFieldService
      .list({ filter: { lead_type_id: 14, type: 6 } })
      .subscribe((leadFields: LeadField[]) => {
        this.leadFields = leadFields;
      });

    this.partnerUserService
      .getList()
      .subscribe((partnerUsers: PartnerUser[]) => {
        if (partnerUsers && partnerUsers.length) {
          this.partnerUserIsAdmin = true;
        }
      });

    this.optionlistsService
      .optionlistByKeyword('inspector_special_fields')
      .subscribe((optionlist) => {
        this.inspectorTypeOptionlist = optionlist;
      });

    this.initializeMap();
  }

  initializeMap() {
    const defaultOptions = {
      container: 'partner-geotargeting-map',
      style: 'https://maps.lindenfield.de/maps/style-cdn.json',
      attributionControl: true,
      hash: false,
      zoom: 5,
      center: [10.451526, 51.165691],
      preserveDrawingBuffer: true,
    };

    // @ts-ignore
    this.map = new mapboxgl.Map(defaultOptions);
    // @ts-ignore
    const scale = new mapboxgl.ScaleControl({ maxWidth: 80, unit: 'metric' });
    this.map.addControl(scale);
  }

  addTargetingsAreaToMap(targetings: Targeting[]) {
    if (this.map && this.map.loaded()) {
      this.updateTargetingAreas(targetings);
    } else {
      this.map.on('load', () => {
        this.updateTargetingAreas(targetings);
      });
    }
  }

  updateTargetingAreas(targetings: Targeting[]) {
    targetings.forEach((targeting) => {
      if (!targeting.area) return;

      const feature_collection = JSON.parse(targeting.area);
      const opacity = targeting.status == '1' ? 0.4 : 0.0;
      this.renderMap(
        'targeting_' + targeting.id,
        feature_collection,
        '#0080ff',
        opacity,
      );
    });
  }

  renderMap(
    id: string,
    feature_collection: any,
    color: string,
    fillOpacity: number,
  ) {
    const sourceId = id + '_source';
    const outlineLayerId = id + '_outline';

    // Prüfen, ob die Quelle bereits existiert, um Doppelungen zu vermeiden.
    if (!this.map.getSource(sourceId)) {
      this.map.addSource(sourceId, {
        type: 'geojson',
        data: feature_collection,
      });
    }

    // Prüfen, ob der Füll-Layer bereits existiert, um Doppelungen zu vermeiden.
    if (!this.map.getLayer(id)) {
      this.map.addLayer({
        id: id,
        type: 'fill',
        source: sourceId,
        layout: {},
        paint: {
          'fill-color': color,
          'fill-opacity': fillOpacity,
        },
      });
    }

    // Prüfen, ob der Umriss-Layer bereits existiert, um Doppelungen zu vermeiden.
    if (!this.map.getLayer(outlineLayerId)) {
      this.map.addLayer({
        id: outlineLayerId,
        type: 'line',
        source: sourceId,
        layout: {},
        paint: {
          'line-color': '#000',
          'line-width': 1,
        },
      });
    }
  }

  highlightTargetingArea(highlightTargeting: Targeting) {
    if (this.targetings && highlightTargeting) {
      this.targetings.forEach((targeting) => {
        const opacity = targeting.id === highlightTargeting.id ? 0.4 : 0.0;
        if (this.map.getLayer('targeting_' + targeting.id)) {
          this.map.setPaintProperty(
            'targeting_' + targeting.id,
            'fill-opacity',
            opacity,
          );
        }
      });
    }
  }

  unHighlightTargetingArea(unHighlightTargeting: Targeting) {
    if (this.targetings && unHighlightTargeting) {
      if (this.map.getLayer('targeting_' + unHighlightTargeting.id)) {
        this.map.setPaintProperty(
          'targeting_' + unHighlightTargeting.id,
          'fill-opacity',
          0.0,
        );
        this.targetings
          .filter((t) => t.area && t.status == '1')
          .forEach((targeting) => {
            this.map.setPaintProperty(
              'targeting_' + targeting.id,
              'fill-opacity',
              0.4,
            );
          });
      }
    }
  }

  displayedTargetingsChangedEvent(targetings: Targeting[]) {
    this.targetings = targetings;
    this.getTargetingDetailTypes();
    this.addTargetingsAreaToMap(targetings);
  }

  getTargetingDetailTypes() {
    if (!this.targetings) {
      return;
    }

    // Diese Funktion prüft, ob noch Gutachten im Targeting fehlen
    for (const targeting of this.targetings) {
      const namesSet = new Set<string>();

      // Sammle alle Typenamen der Felder in einem Set
      targeting.targeting_details
        .flatMap((detail) => detail.conditions)
        .flatMap((condition) => condition.fields)
        .forEach((field) => {
          const typeName = this.getInspectorTypeNameByField(field);
          namesSet.add(typeName);
        });

      // Initialisierung der Akkumulator-Struktur mit expliziten Typen
      const initialAcc = {
        selected_inspector_types: [] as string[],
        unselected_inspector_types: [] as string[],
      };

      // Erstellen einer Liste von Gutachten, sortiert nach ausgewählt oder nicht ausgewählt
      const { selected_inspector_types, unselected_inspector_types } =
        this.inspectorTypeOptionlist
          .map((option) => option.name)
          .reduce((acc, name) => {
            if (namesSet.has(name)) {
              acc.selected_inspector_types.push(name);
            } else {
              acc.unselected_inspector_types.push(name);
            }
            return acc;
          }, initialAcc);

      // Setze die entsprechenden Arrays in den Targetings
      targeting.selected_inspector_types = selected_inspector_types;
      targeting.unselected_inspector_types = unselected_inspector_types;
    }
  }

  hoveredEnterTargetingsChangedEvent(targeting: Targeting) {
    this.highlightTargetingArea(targeting);
  }

  hoveredLeaveTargetingsChangedEvent(targeting: Targeting) {
    this.unHighlightTargetingArea(targeting);
  }

  getInspectorTypeNameByField(item_field: any): string {
    if (!item_field?.field_id) return '';

    const field = this.leadFields.find(
      (field) => field.id == item_field.field_id,
    );
    if (!field?.optionlist?.options) return '';

    const option = field.optionlist.options.find(
      (option) => option.option_id == item_field.val,
    );
    if (!option) {
      return '';
    }

    return option.name;
  }

  openEditTargetingDetailModal(
    targeting: Targeting,
    targetingDetail: TargetingDetail | null,
  ) {
    const dialogRef = this.dialog.open(EditTargetingDetailModalComponent, {
      width: '50vw',
      maxWidth: '100vw',
      minHeight: '300px',
      disableClose: true,
      data: {
        targeting: targeting,
        targetingDetail: targetingDetail,
        inspectorTypeOptionlist: this.inspectorTypeOptionlist,
        partnerUserIsAdmin: this.partnerUserIsAdmin,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.tableComponent.reloadData();
    });
  }
}
