import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { Lead } from '../models/leads/lead';
import { Reminder } from '../models/reminder';
import { Comment } from '../models/comment';
import { FormField, ModalInputData } from '../models/modal';
import { CreateFormGroupHelper } from '../helpers/create-form-group-helper';
import { switchMap } from 'rxjs/operators';
import { Application, ModalFormFieldType } from '../enum-collection';
import { DateHelper } from '../helpers/date-helper';
import * as moment from 'moment/moment';
import { ApplicationHelper } from '../helpers/application-helper';
import { User } from '../models/user';
import { PartnerUser } from '../models/partners/partner-user';
import { CommentService } from './comment.service';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ReminderService {
  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private commentService: CommentService,
    private datePipe: DatePipe,
  ) {}

  getReminder(id: number): Observable<Reminder> {
    return this.http.get<Reminder>(
      `${this.environmentService.apiUrl}reminders/${id}`,
    );
  }

  createReminder(reminder: Reminder): Observable<Reminder> {
    return this.http.post<Reminder>(
      `${this.environmentService.apiUrl}reminders`,
      reminder,
    );
  }

  updateReminder(
    id: number,
    reminder: Partial<Reminder>,
  ): Observable<Reminder> {
    return this.http.put<Reminder>(
      `${this.environmentService.apiUrl}reminders/${id}`,
      reminder,
    );
  }

  getRemindersForUser(filter?: any): Observable<any> {
    let params = new HttpParams().set(
      'filter',
      filter ? JSON.stringify(filter) : JSON.stringify({}),
    );

    return this.http.get<any>(
      `${this.environmentService.apiUrl}reminders/user_reminder`,
      {
        params,
      },
    );
  }

  deleteReminder(id: number): Observable<void> {
    return this.http.delete<void>(
      `${this.environmentService.apiUrl}reminders/${id}`,
    );
  }

  public getReminderModalInputData(
    reminder?: Reminder,
    user?: User | PartnerUser | null,
    lead?: Lead,
  ): ModalInputData {
    const formFields = this.getReminderModalFormFields(reminder, user, lead);

    return {
      headline: reminder ? 'Erinnerung bearbeiten' : 'Erinnerung hinzufügen',
      formGroup: CreateFormGroupHelper.createFormGroupForModal(formFields),
      formFields,
      body: ' ',
    };
  }

  private getReminderModalFormFields(
    reminder?: Reminder,
    user?: User | PartnerUser | null,
    lead?: Lead,
  ): FormField[] {
    const fields: FormField[] = [
      {
        type: ModalFormFieldType.date,
        label: 'Datum der Erinnerung',
        name: 'reminder_date',
        validation: {
          required: true,
          defaultValue: reminder?.reminder_date,
        },
      },
      {
        type: ModalFormFieldType.time,
        label: 'Uhrzeit der Erinnerung',
        name: 'reminder_time',
        validation: { defaultValue: this.getExistingReminderTime(reminder) },
      },
      {
        type: ModalFormFieldType.select,
        label: 'Erinnerungsgrund',
        name: 'reminder_type_option_id',
        optionlistKeyword: 'reminder_types',
        validation: {
          required: true,
          defaultValue: reminder?.reminder_type_option_id,
        },
      },
      {
        type: ModalFormFieldType.textarea,
        label: 'Kommentar',
        name: 'reminder_comment',
        validation: { defaultValue: this.getExistingComment(reminder, user) },
      },
    ];

    if (
      ApplicationHelper.applicationName === Application.Leadmanager &&
      lead?.status !== 3
    ) {
      fields.unshift({
        type: ModalFormFieldType.checkbox,
        label: 'Sichtbar im Salesrunner',
        name: 'visible_in_salesrunner',
        validation: {
          defaultValue: reminder ? reminder.visible_in_salesrunner : true,
        },
      });
    }

    return fields;
  }

  private getExistingReminderTime(reminder?: Reminder): string {
    if (reminder && reminder.reminder_date) {
      const dateTime = new Date(reminder.reminder_date);

      const time = dateTime.toTimeString().split(' ')[0].substr(0, 5);

      if (time === '00:00') {
        return '';
      }

      return time;
    }
    return '';
  }

  private getExistingComment(
    reminder?: Reminder,
    user?: User | PartnerUser | null,
  ): string {
    if (!reminder?.comments?.length) return '';
    const firstComment = reminder.comments[0];
    const isCurrentUserComment =
      ApplicationHelper.applicationName === Application.Leadmanager
        ? firstComment?.user_id === user?.id
        : firstComment?.partner_user_id === user?.id;
    return isCurrentUserComment ? firstComment.comment : '';
  }

  public createOrUpdateReminderObject(
    modalResponse: any,
    existingReminder?: Reminder,
    lead?: Lead,
  ): Reminder {
    const reminder = existingReminder || new Reminder();
    reminder.reminder_status_option_id = 1;
    reminder.reminder_type_option_id = modalResponse.reminder_type_option_id;
    reminder.reminder_date = DateHelper.prepareDateTimeForDatabase(
      this.createLocalDateFromResponse(modalResponse),
    );

    if (ApplicationHelper.applicationName === Application.Leadmanager) {
      reminder.lead_id = lead?.id;
      reminder.visible_in_salesrunner =
        modalResponse.visible_in_salesrunner ?? false;
    }

    return reminder;
  }

  private createLocalDateFromResponse(modalResponse: any): Date {
    let localDate: Date;

    if (moment.isMoment(modalResponse.reminder_date)) {
      // Wenn es ein Moment-Objekt ist, konvertiere es zu lokalem Datum
      localDate = modalResponse.reminder_date.local().toDate();
    } else if (typeof modalResponse.reminder_date === 'string') {
      // Wenn es ein String ist
      // @ts-ignore
      localDate = moment(
        modalResponse.reminder_date,
        'YYYY-MM-DD HH:mm:ss',
      ).toDate();
    } else {
      // Fallback, falls es weder ein Moment-Objekt noch ein String ist
      localDate = new Date(modalResponse.reminder_date);
    }

    // Wenn eine separate Zeitangabe vorhanden ist, überschreibe die Zeit
    if (modalResponse.reminder_time) {
      const [reminderHours, reminderMinutes] = modalResponse.reminder_time
        .split(':')
        .map(Number);
      localDate.setHours(reminderHours, reminderMinutes, 0, 0);
    } else {
      // Wenn keine separate Zeit angegeben ist, setze die Zeit auf Mitternacht
      localDate.setHours(0, 0, 0, 0);
    }

    return localDate;
  }

  public handleCommentCreation(
    reminder: Reminder,
    modalResponse: any,
    user?: User | PartnerUser | null,
  ): Observable<Reminder | Comment> {
    if (modalResponse.reminder_comment && reminder?.id) {
      return this.commentService
        .getCommentsForModel('Reminder', reminder.id)
        .pipe(
          switchMap((comments) => {
            const existingComment = comments.find(
              (comment) =>
                (ApplicationHelper.applicationName ===
                  Application.Leadmanager &&
                  comment.user_id === user?.id) ||
                (ApplicationHelper.applicationName ===
                  Application.Salesrunner &&
                  comment.partner_user_id === user?.id),
            );

            const commentData = new Comment();
            commentData.comment = modalResponse.reminder_comment;
            commentData.user_id = reminder.user_id;
            commentData.partner_user_id = reminder.partner_user_id;
            commentData.visible_in_salesrunner =
              reminder.visible_in_salesrunner;
            commentData.relatable_type = 'App\\Reminder';
            commentData.relatable_id = reminder.id ?? 0;

            if (existingComment?.id) {
              // Update existing comment
              return this.commentService.updateComment(
                existingComment.id,
                commentData,
              );
            } else {
              // Create new comment
              return this.commentService.createComment(commentData);
            }
          }),
        );
    }
    return of(reminder);
  }

  formatDate(date: Date | string | undefined): string {
    if (!date) return '';
    const d = new Date(date);
    const timeString = this.datePipe.transform(d, 'HH:mm');
    if (timeString === '00:00') {
      return this.datePipe.transform(d, 'dd.MM.yyyy') + '';
    } else {
      return this.datePipe.transform(d, 'dd.MM.yyyy HH:mm') + ' Uhr';
    }
  }
}
