import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { SidenavElementInterface } from '../../interfaces/sidenav.interface';
import { StorageService } from '../../services/storage.service';

interface SidenavState {
  isLocked: boolean;
  width: number;
}

@Component({
  selector: 'lib-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, AfterViewInit {
  @Input() menuItems: SidenavElementInterface[] = [];
  @ViewChild('sidenav') sidenav!: ElementRef<HTMLElement>;

  private readonly STORAGE_KEY = 'sidenav_state';
  private readonly DEFAULT_WIDTH = 300;

  isExpanded = false;
  isLocked = false;
  isDragging = false;
  currentWidth = this.DEFAULT_WIDTH;

  constructor(private storageService: StorageService) {}

  ngOnInit(): void {
    this.loadSidenavState();
  }

  ngAfterViewInit(): void {
    if (this.isLocked) {
      this.applyStyles();
    }
  }

  private loadSidenavState(): void {
    const savedState = this.storageService.getFromFromLocalStorage(
      this.STORAGE_KEY,
    ) as SidenavState | null;

    if (savedState) {
      this.isLocked = savedState.isLocked;
      this.currentWidth = savedState.width;
      this.isExpanded = this.isLocked;

      if (this.isLocked) {
        this.applyStyles();
      }
    }
  }

  private saveSidenavState(): void {
    const state: SidenavState = {
      isLocked: this.isLocked,
      width: this.currentWidth,
    };
    this.storageService.setInLocalStorage(this.STORAGE_KEY, state);
  }

  private applyStyles(): void {
    if (this.sidenav?.nativeElement) {
      this.sidenav.nativeElement.style.width = `${this.currentWidth}px`;
    }
    const contentWrapper = document.querySelector(
      '.content-wrapper',
    ) as HTMLElement;
    if (contentWrapper) {
      contentWrapper.style.marginLeft = `${this.currentWidth}px`;
    }
  }

  handleMenuItemClick(menuItem: SidenavElementInterface): void {
    if (menuItem.children?.length) {
      menuItem.expanded = !menuItem.expanded;
    } else if (!this.isLocked) {
      this.isExpanded = false;
    }
  }

  toggleLock(): void {
    this.isLocked = !this.isLocked;
    if (this.isLocked) {
      this.isExpanded = true;
      this.applyStyles();
    } else {
      this.isExpanded = false;
      this.resetToDefaultWidth();
    }
    this.saveSidenavState();
  }

  resetToDefaultWidth(): void {
    this.currentWidth = this.DEFAULT_WIDTH;
    if (this.sidenav?.nativeElement) {
      this.sidenav.nativeElement.style.width = '';
    }
    const contentWrapper = document.querySelector(
      '.content-wrapper',
    ) as HTMLElement;
    if (contentWrapper) {
      contentWrapper.style.marginLeft = '';
    }
    this.saveSidenavState();
  }

  handleMouseEnter(): void {
    if (!this.isLocked) {
      this.isExpanded = true;
    }
  }

  handleMouseLeave(): void {
    if (!this.isLocked) {
      this.isExpanded = false;
    }
  }

  startResize(event: MouseEvent): void {
    if (!this.isLocked) return;

    this.isDragging = true;
    document.addEventListener('mousemove', this.resize.bind(this));
    document.addEventListener('mouseup', this.stopResize.bind(this));
  }

  resize(event: MouseEvent): void {
    if (!this.isDragging) return;

    const newWidth = event.clientX;

    if (newWidth >= 200 && newWidth <= 400) {
      this.currentWidth = newWidth;
      this.applyStyles();
      this.saveSidenavState();
    }
  }

  stopResize(): void {
    this.isDragging = false;
    document.removeEventListener('mousemove', this.resize.bind(this));
    document.removeEventListener('mouseup', this.stopResize.bind(this));
  }
}
