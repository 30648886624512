import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from '@lib/components/login/login.component';
import { PartnerBillFormComponent } from './components/partner-bill/partner-bill-form/partner-bill-form.component';
import { PartnerUsersComponent } from './components/partner-users/partner-users.component';
import { PartnerBillsComponent } from './components/partner-bill/partner-bills/partner-bills.component';
import { AuthGuard } from '@lib/services/auth-guard/auth.guard';
import { LeadListComponent } from './components/leads/lead-list.component';
import { TaskBoardComponent } from './components/task-board/task-board.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LeadEditComponent } from './components/leads/lead-edit/lead-edit.component';
import { ChanceListComponent } from './components/chances/chance-list.component';
import { CostTypeSaleGuard } from './cost-type-sale.guard';
import { TargetingListComponent } from './components/targeting/targeting-list.component';
import { InspectorGuard } from './inspector.guard';
import { ResetPasswordComponent } from '@lib/components/login/reset-password/reset-password.component';
import { NextBestActionListComponent } from './components/leads/next-best-action/next-best-action-list.component';
import { LeadListOverviewComponent } from './components/leads/lead-list-new/lead-list-overview.component';
import { LeadListNewComponent } from './components/leads/lead-list-new/lead-list-new.component';

const routes: Routes = [
  { path: '', redirectTo: 'leads', pathMatch: 'full' },

  { path: 'login', component: LoginComponent },
  { path: 'reset_password', component: ResetPasswordComponent },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: DashboardComponent,
  },
  {
    path: 'leads',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: LeadListComponent },
      { path: 'edit/:id', component: LeadEditComponent },
    ],
  },
  {
    path: 'leads-new',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: LeadListNewComponent },
      { path: 'edit/:id', component: LeadEditComponent },
    ],
  },
  {
    path: 'partner_bills',
    canActivate: [AuthGuard, CostTypeSaleGuard],
    children: [
      { path: '', component: PartnerBillsComponent },
      {
        path: 'edit/:partnerLeadId/:partnerBillId',
        component: PartnerBillFormComponent,
      },
      {
        path: 'create/:partnerLeadId',
        component: PartnerBillFormComponent,
      },
    ],
  },
  {
    path: 'chance_list',
    canActivate: [AuthGuard, CostTypeSaleGuard],
    children: [{ path: '', component: ChanceListComponent }],
  },
  {
    path: 'targeting',
    canActivate: [AuthGuard, InspectorGuard],
    children: [{ path: '', component: TargetingListComponent }],
  },
  {
    path: 'partner_user',
    canActivate: [AuthGuard],
    children: [{ path: '', component: PartnerUsersComponent }],
  },
  {
    path: 'task_board',
    canActivate: [AuthGuard],
    children: [{ path: '', component: TaskBoardComponent }],
  },
  {
    path: 'next_best_action',
    canActivate: [AuthGuard],
    children: [{ path: '', component: NextBestActionListComponent }],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
