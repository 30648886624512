<div class="row">
  <div class="col">
    <h2 mat-dialog-title class="mb-0 fw-medium fs-3 px-4">
      {{ data.dialogTitle }}
    </h2>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <p class="text-black">Möchten Sie das Dokument wirklich löschen?</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-raised-button
    mat-button
    mat-dialog-close
    class="btn btn-primary mx-2"
  >
    Abbrechen
  </button>
  <button
    mat-raised-button
    mat-dialog-close
    color="warn"
    class="btn btn-outline-danger btn-sm text-light"
    (click)="deleteFile()"
    mat-button
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    Löschen
  </button>
</mat-dialog-actions>
