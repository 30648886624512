import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { LeadType } from '@lib/models/lead-type';
import { LeadTypeService } from '@lib/services/lead-type/lead-type.service';
import { OptionlistsService } from '@lib/services/option-lists/optionlists.service';
import { SnackBarService } from '@lib/services/snack-bar/snack-bar.service';
import { ActivatedRoute } from '@angular/router';
import { OptionlistOption } from '@lib/models/optionlist/optionlist-option';
import { PartnerLead } from '@lib/models/partner-lead';
import { PartnerLeadBackend } from '@lib/models/partners/partner-lead-backend';
import { PriceNumberFormatHelper } from '@lib/helpers/price-number-format-helper';
import { AuthService } from '@lib/services/auth.service';
import { PartnerUserCostType } from '@lib/enum-collection';
import { PartnerLeadService } from '@lib/services/partner-lead/partner-lead.service';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Lead } from '@lib/models/leads/lead';
import { FeedComponent } from '@lib/components/feed/feed.component';
import { Address } from '@lib/models/address';

@Component({
  selector: 'app-edit-form',
  templateUrl: './lead-edit.component.html',
  styleUrls: ['./lead-edit.component.scss'],
})
export class LeadEditComponent implements OnInit, OnDestroy {
  @ViewChild(FeedComponent) feedComponent!: FeedComponent;

  pageTitle = 'Lead bearbeiten';
  partnerLead: PartnerLead = new PartnerLead({});
  leadTypes: LeadType[] = [];
  inputsDisabled: boolean = false;
  offerValue: string = '';
  leadStatusOptionList: OptionlistOption[] = [];
  partnerUser$ = this.authService.partnerUser$;
  partnerLead$: Observable<PartnerLead> = new Observable<PartnerLead>();

  private destroy$ = new Subject<void>();

  constructor(
    private leadTypeService: LeadTypeService,
    private optionlistService: OptionlistsService,
    private route: ActivatedRoute,
    private snackbarService: SnackBarService,
    private partnerLeadService: PartnerLeadService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    const partnerLeadId = this.route.snapshot.params['id'];

    if (partnerLeadId) {
      this.partnerLeadService
        .showPartnerLead(partnerLeadId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((partnerLead: PartnerLead) => {
          this.partnerLead = partnerLead;

          this.offerValue = PriceNumberFormatHelper.replaceDotWithComma(
            this.partnerLead.offer_value,
          );
        });

      this.partnerLead$ = this.partnerLeadService
        .showPartnerLead(partnerLeadId)
        .pipe(takeUntil(this.destroy$));
    }

    this.leadTypeService
      .listPartnerLeadType()
      .pipe(takeUntil(this.destroy$))
      .subscribe((leadTypes: LeadType[]) => {
        this.leadTypes = leadTypes;
      });

    this.optionlistService
      .optionlistByKeyword('partner_status')
      .pipe(takeUntil(this.destroy$))
      .subscribe((lead_status_optionlist: OptionlistOption[]) => {
        this.leadStatusOptionList = lead_status_optionlist;
      });
  }

  onSubmit() {
    this.partnerLeadService
      .update(this.partnerLead)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.feedComponent.loadFeed();

        this.snackbarService.openSnackBar(
          'Lead erfolgreich bearbeitet',
          'success',
        );
      });
  }

  onOfferValueChange() {
    this.partnerLead.offer_value = PriceNumberFormatHelper.prepareForDatabase(
      this.offerValue,
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.partnerLead$) {
      this.partnerLead$ = new Observable<PartnerLead>();
    }
  }

  onAddressesChanged(updatedAddresses: Address[]) {
    this.partnerLead.addresses = updatedAddresses;
  }

  updateFeed(): void {
    this.feedComponent.loadFeed();
  }

  protected readonly PartnerUserCostType = PartnerUserCostType;
}
