import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { Feed } from '../models/feed';
import { Lead } from '../models/leads/lead';
import { Partner } from '../models/partner';

@Injectable({
  providedIn: 'root',
})
export class FeedService {
  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
  ) {}

  getFeed(id: number): Observable<Feed> {
    return this.http.get<Feed>(`${this.environmentService.apiUrl}feeds/${id}`);
  }

  getLeadFeed(leadId: number): Observable<Feed[]> {
    return this.http.get<Feed[]>(
      `${this.environmentService.apiUrl}feeds/lead/${leadId}`,
    );
  }

  getPartnerLeadFeed(partnerLeadId: number): Observable<Feed[]> {
    return this.http.get<Feed[]>(
      `${this.environmentService.apiUrl}feeds/partner_lead/${partnerLeadId}`,
    );
  }

  getFeedsForModel(modelType: string, modelId: number): Observable<Feed[]> {
    return this.http.get<Feed[]>(
      `${this.environmentService.apiUrl}feeds/${modelType}/${modelId}`,
    );
  }

  getFeedLabel(relatable_type: string): string {
    switch (relatable_type) {
      case 'App\\Comment':
        return 'Kommentar';
      case 'App\\PartnerLeadHistory':
        return 'Status Partner';
      case 'App\\LeadHistory':
        return 'Status Lead';
      case 'App\\Call':
        return 'Anruf';
      case 'App\\Email':
        return 'E-Mail';
      case 'App\\Document':
        return 'Dokument';
      case 'App\\LeadFeedback':
        return 'Feedback';
      case 'App\\CommentSummary':
        return 'Zusammenfassung';
      case 'App\\Reminder':
        return 'Wiedervorlage';
      default:
        return 'Unbekannt';
    }
  }

  getFeedIcon(relatable_type: string): string {
    switch (relatable_type) {
      case 'App\\Comment':
        return 'comment';
      case 'App\\PartnerLeadHistory':
        return 'history';
      case 'App\\LeadHistory':
        return 'history';
      case 'App\\Call':
        return 'call';
      case 'App\\Email':
        return 'email';
      case 'App\\Document':
        return 'file_copy';
      case 'App\\LeadFeedback':
        return 'feedback';
      case 'App\\CommentSummary':
        return 'grade';
      case 'App\\Reminder':
        return 'update';
      default:
        return 'rss_feed';
    }
  }

  getEventTypeIconClass(relatable_type: string): string {
    switch (relatable_type) {
      case 'App\\Comment':
        return 'comment-icon';
      case 'App\\PartnerLeadHistory':
        return 'history-icon-partner';
      case 'App\\LeadHistory':
        return 'history-icon-lead';
      case 'App\\Call':
        return 'call-icon';
      case 'App\\Email':
        return 'email-icon';
      case 'App\\Document':
        return 'file_copy-icon';
      case 'App\\LeadFeedback':
        return 'feedback-icon';
      case 'App\\CommentSummary':
        return 'comment-summary-icon';
      case 'App\\Reminder':
        return 'reminder-icon';
      default:
        return 'feed-icon';
    }
  }

  getFeedSalesrunnerVisibilityHint(
    visibleInSalesrunner: boolean,
    partner: Partner | undefined,
    lead: Lead | undefined,
  ): string {
    // Wenn es nicht sichtbar im Salesrunner ist, dann soll der Hinweis nicht gezeigt werden
    if (!visibleInSalesrunner) {
      return '';
    }

    // Der Hinweis soll nur bei weitergeleiteten Leads angezeigt werden
    if (lead?.status != 3) {
      return 'sichtbar im Salesrunner';
    }

    if (partner?.name) {
      return 'bei <b>' + partner.name + '</b>';
    }

    return '';
  }
}
