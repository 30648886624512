import { Component } from '@angular/core';
import { IconColor } from '../../../../icon/icon.component';

@Component({
  selector: 'lib-basic-badge-example',
  template: FeedIconsExample.HTML_CODE,
})
export class FeedIconsExample {
  IconColor = IconColor;

  static HTML_CODE = `
    <!-- Grundlegende Icons -->
   <section class="space-y-4">
  <h3 class="text-lg font-medium">Standard Icons</h3>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="calendar"></lib-icon>
      <span>calendar</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="call-in"></lib-icon>
      <span>call-in</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="call-out"></lib-icon>
      <span>call-out</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="comment"></lib-icon>
      <span>comment</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="document"></lib-icon>
      <span>document</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="email"></lib-icon>
      <span>email</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="feedback"></lib-icon>
      <span>feedback</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="status"></lib-icon>
      <span>status</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="summary"></lib-icon>
      <span>summary</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="website"></lib-icon>
      <span>website</span>
    </div>
  </div>
</section>

<!-- Icons mit verschiedenen Größen -->
<section class="space-y-4">
  <h3 class="text-lg font-medium">Icon Größen</h3>
 <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="email" size="sm"></lib-icon>
      <span>Small</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="email" size="md"></lib-icon>
      <span>Medium</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="email" size="lg"></lib-icon>
      <span>Large</span>
    </div>
  </div>
</section>

<!-- Icons mit verschiedenen Varianten -->
<section class="space-y-4">
  <h3 class="text-lg font-medium">Icon Varianten</h3>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="feedback" variant="default"></lib-icon>
      <span>Default</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="feedback" variant="circle"></lib-icon>
      <span>Circle</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="feedback" variant="shadow"></lib-icon>
      <span>Shadow</span>
    </div>
  </div>
</section>

<!-- Hilfsicons -->
<section class="space-y-4">
  <h3 class="text-lg font-medium">Hilfsicons in dynamischen Farben</h3>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="bell" variant="default"></lib-icon>
      <span>Default</span>
    </div>

    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon
        name="bell"
        variant="default"
        [color]="IconColor.SUCCESS"
      ></lib-icon>
      <span>IconColor.SUCCESS</span>
    </div>

    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon
        name="bell"
        variant="default"
        [color]="IconColor.DANGER"
      ></lib-icon>
      <span>IconColor.DANGER</span>
    </div>

    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon
        name="bell"
        variant="default"
        [color]="IconColor.WARNING"
      ></lib-icon>
      <span>IconColor.WARNING</span>
    </div>
  </div>
</section>

<section class="space-y-4">
  <h3 class="text-lg font-medium">Hilfsicons Liste</h3>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="bell" variant="default"></lib-icon>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="check" variant="default"></lib-icon>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="conversion" variant="default"></lib-icon>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="dollar" variant="default"></lib-icon>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="error" variant="default"></lib-icon>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="eye" variant="default"></lib-icon>      
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="refresh" variant="default"></lib-icon>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="thumbs-up" variant="default"></lib-icon>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon name="x" variant="default"></lib-icon>
    </div>
  </div>
</section>

<section class="space-y-4">
  <h3 class="text-lg font-medium">Hilfsicon mit zusätzlichen Icon</h3>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon
        name="email"
        [badges]="[
          { name: 'check', position: 'top-right' },
          { name: 'bell', position: 'bottom-left' },
          { name: 'bell', position: 'top-left' },
          { name: 'bell', position: 'bottom-right' },
          { name: 'bell', position: 'center-right' }
        ]"
      >
      </lib-icon>
    </div>

    <div fxLayout="column" fxLayoutAlign="space-around center" >
      <lib-icon
        name="email"
        [badges]="[
          { name: 'conversion', position: 'bottom-left' },
          { name: 'thumbs-up', position: 'top-left' },
          { name: 'bell', position: 'center-right' }
        ]"
      >
      </lib-icon>
    </div>
  </div>
</section>
  `;
}
