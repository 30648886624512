<h1 mat-dialog-title>E-Mail senden</h1>
<mat-dialog-content [formGroup]="mailForm" class="mat-typography">
  <mat-form-field fxFill>
    <mat-label>E-Mail Vorlage</mat-label>
    <mat-select
      formControlName="emailTemplate"
      (selectionChange)="onTemplateSelected($event.value)"
    >
      <mat-option [value]="51">Nicht erreicht</mat-option>
      <mat-option [value]="52">Rufnummer falsch</mat-option>
      <mat-option [value]="55">Absage an Lead</mat-option>
      <mat-option [value]="56">Noch aktuell?</mat-option>
      <mat-option [value]="57">Schlussrechnung</mat-option>
      <mat-option [value]="58">Vertriebsstellensuche</mat-option>
      <mat-option [value]="59">Nachfrage Produktalternativen</mat-option>
      <mat-option [value]="60">Fragestellungen per E-Mail</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field fxFill>
    <mat-label>Absender Name</mat-label>
    <input matInput formControlName="senderName" />
    <mat-error *ngIf="mailForm.get('senderName')?.hasError('required')">
      Absendername ist <strong>erforderlich</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFill>
    <mat-label>Absender E-Mail</mat-label>
    <input matInput formControlName="senderEmail" />
    <mat-error *ngIf="mailForm.get('senderEmail')?.hasError('required')">
      E-Mail ist <strong>erforderlich</strong>
    </mat-error>
    <mat-error *ngIf="mailForm.get('senderEmail')?.hasError('pattern')">
      Ungültige <strong>E-Mail</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFill>
    <mat-label>Empfänger E-Mail</mat-label>
    <input matInput formControlName="recipientEmail" />
    <mat-error *ngIf="mailForm.get('recipientEmail')?.hasError('required')">
      E-Mail ist <strong>erforderlich</strong>
    </mat-error>
    <mat-error *ngIf="mailForm.get('recipientEmail')?.hasError('pattern')">
      Ungültige <strong>E-Mail</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFill>
    <mat-label>Betreff</mat-label>
    <input matInput formControlName="messageSubject" />
    <mat-error *ngIf="mailForm.get('messageSubject')?.hasError('required')">
      Betreff ist <strong>erforderlich</strong>
    </mat-error>
  </mat-form-field>

  <div class="editor">
    <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
    <ngx-editor [editor]="editor" formControlName="messageBody"></ngx-editor>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Abbrechen</button>
  <button
    mat-button
    (click)="sendMail()"
    class="btn btn-success btn-success"
    [disabled]="!mailForm.valid"
  >
    Senden
  </button>
</mat-dialog-actions>
