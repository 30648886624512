import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { SelectList } from '../../models/select-list';
import { FilterSetting } from '../../models/filter-setting';
import { NewSelectOption } from '../../models/new-select-option';
import { NewSelectSelection } from '../../models/new-select-selection';
import { DateHelper } from '../../helpers/date-helper';
import { NewFilterService } from '../../services/filter/new-filter.service';
import { SelectService } from '../../services/select.service';
import { NewSelectComponent } from '../select/new-select.component';
import { LeadDetailFilterModalComponent } from './lead-detail-filter-modal/lead-detail-filter-modal.component';
import { AuthService } from '../../services/auth.service';
import { Application } from '../../enum-collection';
import { ApplicationHelper } from '../../helpers/application-helper';

@Component({
  selector: 'lib-filter-bar',
  templateUrl: './new-filter-bar.component.html',
  styleUrls: ['./new-filter-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewFilterBarComponent implements AfterViewInit, OnDestroy {
  @ViewChildren(NewSelectComponent)
  selectComponents!: QueryList<NewSelectComponent>;
  @Input() filterSettings: FilterSetting[] = [];

  readonly SelectList = SelectList;
  filter: any;
  @Input() dateRange = {
    start: DateHelper.getStartOfMonth(),
    end: DateHelper.getEndOfMonth(),
  };

  private destroy$ = new Subject<void>();
  private optionsMap = new Map<
    SelectList,
    BehaviorSubject<NewSelectOption[]>
  >();

  constructor(
    private filterService: NewFilterService,
    private dialog: MatDialog,
    private selectService: SelectService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    this.initializeFilter();
    this.initializeOptionsMap();
    this.filterService.initDaterange(this.dateRange);
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateSelectOutput(selection: NewSelectSelection, filter: SelectList) {
    this.filterLeadDetails(selection, filter);
    this.filterPartners(selection, filter);
    if (!this.selectService.hasSingleOption(filter)) {
      this.filterService.setFilter({ filter: { [filter]: selection } });
    }
  }

  setDateRange(dateRange: { start: Date | string; end: Date | string }) {
    this.filterService.setDaterange({
      start: DateHelper.formatDate(dateRange.start.toString()),
      end: DateHelper.formatDate(dateRange.end.toString()),
    });
  }

  showLeadDetailFilterModal(list: SelectList) {
    const dialogRef = this.dialog.open(LeadDetailFilterModalComponent, {
      width: '95vw',
      maxWidth: '100vw',
      minHeight: '300px',
      data: this.getLeadDetailFilterModalData(list),
    });

    dialogRef
      .afterClosed()
      .subscribe(this.handleLeadDetailFilterModalClose(list));
  }

  getOptions(selectList: SelectList): Observable<NewSelectOption[]> {
    return (
      this.optionsMap.get(selectList) ||
      new BehaviorSubject<NewSelectOption[]>([])
    );
  }

  getSelectedOptions(
    filterList: SelectList,
  ): NewSelectOption | NewSelectOption[] | null {
    return this.filter?.filter?.[filterList]?.options || null;
  }

  getHasNegativeValues(filterList: SelectList): boolean {
    return this.filter?.filter?.[filterList]?.isNegative || false;
  }

  getFilterSetting(filter: SelectList): FilterSetting | undefined {
    return this.filterSettings.find((setting) => setting.filter === filter);
  }

  getPlaceholderValue(config: any): string {
    return (
      this.getFilterSetting(config.filter)?.placeholder ?? config.placeholder
    );
  }

  getMultiSelectValue(config: any): boolean {
    return (
      this.getFilterSetting(config.filter)?.multiSelect ?? config.multiSelect
    );
  }

  getDisabledValue(config: any): boolean {
    return (
      this.getFilterSetting(config.filter)?.disabled ?? config.disabled ?? false
    );
  }

  getClearableValue(config: any): boolean {
    return (
      this.getFilterSetting(config.filter)?.clearable ??
      config.clearable ??
      false
    );
  }

  getAllowNegativeValue(config: any): boolean {
    return (
      this.getFilterSetting(config.filter)?.allowNegative ??
      config.allowNegative ??
      false
    );
  }

  isDetailFilterButton(filter: SelectList): boolean {
    return (
      filter === SelectList.LeadDetail ||
      filter === SelectList.PartnerLeadDetail
    );
  }

  private initializeFilter() {
    this.filter = this.filterService.getFilter();
    this.filterService.filter$
      .pipe(takeUntil(this.destroy$))
      .subscribe((filter) => this.updateSelectedOptions(filter));
  }

  public initializeOptionsMap() {
    this.filterSettings.forEach((setting) => {
      this.optionsMap.set(
        setting.filter,
        new BehaviorSubject<NewSelectOption[]>([]),
      );
      this.loadOptions(setting.filter);
    });
  }

  private loadOptions(selectList: SelectList) {
    this.selectService
      .getSelectOptionsBySelectList(selectList)
      .pipe(
        takeUntil(this.destroy$),
        tap((options) => {
          const subject = this.optionsMap.get(selectList);
          if (subject) {
            subject.next(options);
            this.cdr.detectChanges();
          }
        }),
      )
      .subscribe();
  }

  private updateSelectedOptions(filter: any) {
    this.filterSettings.forEach((setting, index) => {
      const options = filter?.filter?.[setting.filter]?.options;
      if (options?.length) {
        const component = this.selectComponents.toArray()[index];
        if (component) {
          component.selectedOptions = options;
        }
      }
    });
  }

  private filterPartners(selection: NewSelectSelection, filter: SelectList) {
    if (
      filter !== SelectList.LeadType ||
      ApplicationHelper.applicationName === Application.Salesrunner
    )
      return;

    this.selectService
      .getSelectOptionsBySelectList(SelectList.Partner, {
        'targetings.lead_type_id': selection,
      })
      .pipe(
        takeUntil(this.destroy$),
        tap((options) => {
          const subject = this.optionsMap.get(SelectList.Partner);
          if (subject) {
            subject.next(options);
          }
        }),
      )
      .subscribe();
  }

  private filterLeadDetails(selection: NewSelectSelection, filter: SelectList) {
    if (filter !== SelectList.LeadType) return;

    const targetList = this.filterSettings.some(
      (setting) => setting.filter === SelectList.LeadDetail,
    )
      ? SelectList.LeadDetail
      : SelectList.PartnerLeadDetail;

    this.selectService
      .getSelectOptionsBySelectList(targetList, { lead_type_id: selection })
      .pipe(
        takeUntil(this.destroy$),
        tap((options) => {
          const subject = this.optionsMap.get(targetList);
          if (subject) {
            subject.next(options);
          }
        }),
      )
      .subscribe();
  }

  private getLeadDetailFilterModalData(list: SelectList) {
    return {
      selectedLeadTypes:
        this.filterService.getFilter()?.filter[SelectList.LeadType],
      selectedLeadDetails: this.filterService.getFilter()?.filter[list],
    };
  }

  private handleLeadDetailFilterModalClose(list: SelectList) {
    return (detailFilter: any) => {
      if (!detailFilter) return;

      if (detailFilter.leadDetails) {
        this.updateLeadDetails(detailFilter, list);
      }

      if (detailFilter.leadTypes) {
        this.updateLeadTypes(detailFilter);
      }

      this.filter = this.filterService.getFilter();
    };
  }

  private updateLeadDetails(detailFilter: any, list: SelectList) {
    this.selectService
      .getSelectOptionsBySelectList(list, {
        lead_type_id: detailFilter.leadTypes,
      })
      .pipe(
        takeUntil(this.destroy$),
        tap((options) => {
          const filteredOptions = this.filterLeadDetailOptions(
            options,
            detailFilter.leadDetails.options,
          );
          this.filterService.setFilter({
            filter: {
              [list]: {
                options: filteredOptions,
                isNegative: detailFilter.leadDetails.isNegative,
              },
            },
          });
          this.filterLeadDetails(detailFilter.leadTypes, SelectList.LeadType);
        }),
      )
      .subscribe();
  }

  private filterLeadDetailOptions(
    options: NewSelectOption[],
    detailOptions: NewSelectOption[],
  ): NewSelectOption[] {
    return options.filter((option) =>
      detailOptions.some(
        (detailOption) =>
          option.value.lead_field_id === detailOption.value.lead_field_id &&
          option.value.lead_type_id === detailOption.value.lead_type_id &&
          option.value.option_id === detailOption.value.option_id,
      ),
    );
  }

  private updateLeadTypes(detailFilter: any) {
    this.filterService.setFilter({
      filter: {
        [SelectList.LeadType]: {
          options: detailFilter.leadTypes.options,
          isNegative: detailFilter.leadTypes.isNegative,
        },
      },
    });
  }
}
