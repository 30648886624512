<div class="demo-wrapper" *ngIf="config">
  <lib-demo-section>
    <h1>{{ config.title }}</h1>
    <p>{{ config.description }}</p>
  </lib-demo-section>

  <lib-demo-section *ngIf="config.guidelines?.length" title="Guidelines">
    <ul>
      <li *ngFor="let guideline of config.guidelines">
        {{ guideline }}
      </li>
    </ul>
  </lib-demo-section>

  <lib-demo-section
    *ngFor="let example of config.examples"
    [title]="example.title"
  >
    <p>{{ example.description }}</p>

    <div class="example-container">
      <ng-container *ngComponentOutlet="example.component"></ng-container>
    </div>

    <lib-demo-example [files]="example.sourceCode"></lib-demo-example>
  </lib-demo-section>

  <lib-demo-section title="API Reference">
    <lib-api-reference [properties]="config.apiReference"></lib-api-reference>
  </lib-demo-section>
</div>
