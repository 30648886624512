import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationPatterns } from '@lib/validations/validation-pattern';
import { SnackBarService } from '@lib/services/snack-bar/snack-bar.service';
import { PriceNumberFormatHelper } from '@lib/helpers/price-number-format-helper';
import { PartnerLead } from '@lib/models/partner-lead';
import { PartnerLeadService } from '@lib/services/partner-lead/partner-lead.service';

@Component({
  selector: 'app-partner-lead-offer-value',
  templateUrl: './partner-lead-offer-value.component.html',
})
export class PartnerLeadOfferValueComponent implements OnInit {
  partnerLeadForm: FormGroup = new FormGroup({});

  @Input() partnerLead: PartnerLead = new PartnerLead();
  @Output() partnerLeadOfferValue = new EventEmitter<PartnerLead>();

  constructor(
    private partnerLeadService: PartnerLeadService,
    private snackbarService: SnackBarService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.partnerLeadForm = this.formBuilder.group({
      offer_value: [
        PriceNumberFormatHelper.replaceDotWithComma(
          this.partnerLead.offer_value,
        ) || null,
        [
          //Validators.required,
          Validators.pattern(ValidationPatterns.pricePattern),
        ],
      ],
    });
  }

  onSubmit() {
    const formattedPrice = PriceNumberFormatHelper.formatUserInputPrice(
      this.partnerLeadForm.get('offer_value')?.value,
    );

    this.partnerLeadForm
      .get('offer_value')
      ?.setValue(PriceNumberFormatHelper.replaceDotWithComma(formattedPrice));

    //if (this.partnerLeadForm.valid) {
    // Der PartnerLead muss noch mal neu geladen werden. Von der INDEX Funktion wird ein Element übergeben, das nicht gespeichert werden kann
    this.partnerLeadService
      .showPartnerLead(this.partnerLead.id)
      .subscribe((partnerLead) => {
        // das darf nicht formatiert werden. Wird sonst im Backend falsch gespeichert
        partnerLead.offer_value = PriceNumberFormatHelper.prepareForDatabase(
          this.partnerLeadForm.get('offer_value')?.value,
        );

        this.partnerLeadService.update(partnerLead).subscribe(
          () => {
            this.snackbarService.openSnackBar(
              'Angebotswert erfolgreich aktualisiert.',
              'success',
            );
            this.partnerLead = partnerLead;
            this.partnerLeadOfferValue.emit(partnerLead);
          },
          () => {
            this.snackbarService.openSnackBar(
              'Angebotswert konnte nicht aktualisiert werden.',
              'warn',
            );
          },
        );
      });
    //}
  }
}
