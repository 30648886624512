import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-form-slide-toggle-example',
  template: FormSlideToggleExample.HTML_CODE,
})
export class FormSlideToggleExample implements OnInit {
  static HTML_CODE = `
    <div class="example-container">
      <form [formGroup]="form">
        <lib-slide-toggle formControlName="active">
          Benutzer aktiv
        </lib-slide-toggle>

        <div class="mt-4">
          <p>Formular Wert: {{ form.value | json }}</p>
        </div>
      </form>
    </div>
  `;

  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      active: [0],
    });
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe((value) => {
      console.log('Formular Update:', value);
    });
  }
}
