export function calculateSumForColumn(
  data: any,
  columnNumerator: string,
  unit: string,
  columnDenominator: string,
) {
  const sum = data.reduce((accumulator: any, row: any) => {
    const columnValue = parseFloat(row[columnNumerator]);
    if (!isNaN(columnValue)) {
      return accumulator + columnValue;
    }
    return accumulator; // If it's not a valid number, return the accumulator as it is
  }, 0);

  if (isNaN(sum) || typeof sum !== 'number') {
    // If it's NaN or not a valid number, set it to 0
    return 0;
  }
  if (unit == 'percent') {
    const sumDenominator = data.reduce(
      (sum: any, row: any) => sum + parseFloat(row[columnDenominator]),
      0,
    );
    return calculatePercentage(sum, sumDenominator);
  } else if (unit == 'currency') {
    return formatCurrency(sum);
  } else {
    return formatNummer(sum);
  }
}

export function calculatePercentage(a: number, b: number): string {
  const percent = a / b;
  if (isNaN(percent)) {
    return '0%';
  } else {
    return new Intl.NumberFormat('de-DE', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(percent);
  }
}

export function formatNummer(data: number): number {
  if (data === null) {
    return 0;
  } else {
    return Math.round(data);
  }
}

export function formatCurrency(data: any): string {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(data);
}
