// countries.const.ts
export const CountriesConst = [
  { name: 'Deutschland', code: 'de' },
  { name: 'Albanien', code: 'al' },
  { name: 'Andorra', code: 'ad' },
  { name: 'Belarus', code: 'by' },
  { name: 'Belgien', code: 'be' },
  { name: 'Bosnien und Herzegowina', code: 'ba' },
  { name: 'Bulgarien', code: 'bg' },
  { name: 'Dänemark', code: 'dk' },
  { name: 'Estland', code: 'ee' },
  { name: 'Finnland', code: 'fi' },
  { name: 'Frankreich', code: 'fr' },
  { name: 'Griechenland', code: 'gr' },
  { name: 'Irland', code: 'ie' },
  { name: 'Island', code: 'is' },
  { name: 'Italien', code: 'it' },
  { name: 'Kroatien', code: 'hr' },
  { name: 'Lettland', code: 'lv' },
  { name: 'Liechtenstein', code: 'li' },
  { name: 'Litauen', code: 'lt' },
  { name: 'Luxemburg', code: 'lu' },
  { name: 'Malta', code: 'mt' },
  { name: 'Moldau, Republik', code: 'md' },
  { name: 'Monaco', code: 'mc' },
  { name: 'Montenegro', code: 'me' },
  { name: 'Niederlande', code: 'nl' },
  { name: 'Nordmazedonien', code: 'mk' },
  { name: 'Norwegen', code: 'no' },
  { name: 'Österreich', code: 'at' },
  { name: 'Polen', code: 'pl' },
  { name: 'Portugal', code: 'pt' },
  { name: 'Rumänien', code: 'ro' },
  { name: 'Russische Föderation', code: 'ru' },
  { name: 'San Marino', code: 'sm' },
  { name: 'Schweden', code: 'se' },
  { name: 'Schweiz', code: 'ch' },
  { name: 'Serbien', code: 'rs' },
  { name: 'Slowakei', code: 'sk' },
  { name: 'Slowenien', code: 'si' },
  { name: 'Spanien', code: 'es' },
  { name: 'Tschechien', code: 'cz' },
  { name: 'Türkei', code: 'tr' },
  { name: 'Ukraine', code: 'ua' },
  { name: 'Ungarn', code: 'hu' },
  { name: 'Vatikanstadt', code: 'va' },
  { name: 'Vereinigtes Königreich', code: 'uk' },
  { name: 'Zypern', code: 'cy' },
];
