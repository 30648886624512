import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'lib-split-view',
  templateUrl: './split-view.component.html',
  styleUrls: ['./split-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplitViewComponent implements OnDestroy, AfterViewInit, OnInit {
  @Input() set initialLeftPanelWidth(value: number) {
    if (value !== this._initialWidth) {
      this._initialWidth = value;
      this.leftPanelWidth = value;
      if (this.cdr) {
        this.cdr.detectChanges();
      }
    }
  }
  get initialLeftPanelWidth(): number {
    return this._initialWidth;
  }

  @Input() minLeftPanelWidth = 400;
  @Input() maxLeftPanelWidth = window.innerWidth - 400;
  @Input() showRightPanel = true;
  @Input() rightPanelMinWidth = 400;
  @Output() leftPanelWidthChange = new EventEmitter<number>();

  @ViewChild('container') containerRef!: ElementRef;

  leftPanelWidth: number;
  containerWidth: number = 0;
  isResizing = false;
  isMobile = false;
  private startX: number = 0;
  private startLeft: number = 0;
  private destroy$ = new Subject<void>();
  private rafId: number | null = null;
  private _initialWidth: number;

  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {
    this._initialWidth = window.innerWidth;
    this.leftPanelWidth = this._initialWidth;
  }

  ngOnInit() {
    this.containerWidth = window.innerWidth;
    this.maxLeftPanelWidth = this.containerWidth - this.rightPanelMinWidth;
    this.checkMobileView();
  }

  ngAfterViewInit() {
    this.ngZone.run(() => {
      this.containerWidth = this.elementRef.nativeElement.offsetWidth;
      this.maxLeftPanelWidth = this.containerWidth - this.rightPanelMinWidth;
      this.checkMobileView();
      if (this.showRightPanel) {
        this.adjustLeftPanelWidth(true);
      }
      this.cdr.detectChanges();
    });
    this.setupEventListeners();
  }

  private checkMobileView() {
    const wasMobile = this.isMobile;
    this.isMobile = window.innerWidth <= 768;

    if (this.isMobile) {
      this.leftPanelWidth = this.showRightPanel ? 0 : this.containerWidth;
    } else if (wasMobile !== this.isMobile) {
      this.adjustLeftPanelWidth();
    }
  }

  private adjustLeftPanelWidth(isInitial = false) {
    if (this.isMobile) return;

    // Berechne den verfügbaren Platz
    const availableWidth = this.containerWidth - this.rightPanelMinWidth;

    // Beim initialen Setup verwenden wir den initialLeftPanelWidth-Wert ohne Einschränkung
    let targetWidth = isInitial ? this._initialWidth : this.leftPanelWidth;

    // Stelle sicher, dass die Breite nicht kleiner als minLeftPanelWidth ist
    targetWidth = Math.max(targetWidth, this.minLeftPanelWidth);

    // Stelle sicher, dass genug Platz für das rechte Panel bleibt
    targetWidth = Math.min(targetWidth, availableWidth);

    if (targetWidth !== this.leftPanelWidth) {
      this.leftPanelWidth = targetWidth;
      this.leftPanelWidthChange.emit(this.leftPanelWidth);
      this.cdr.detectChanges();
    }
  }

  private setupEventListeners() {
    this.ngZone.runOutsideAngular(() => {
      document.addEventListener('mousemove', this.handleMouseMove.bind(this), {
        passive: true,
      });
      document.addEventListener('mouseup', this.stopResize.bind(this));
      document.addEventListener('touchmove', this.handleTouchMove.bind(this), {
        passive: true,
      });
      document.addEventListener('touchend', this.stopResize.bind(this));
    });
  }

  startResize(event: MouseEvent) {
    event.preventDefault();
    if (this.isMobile) return;
    this.isResizing = true;
    this.startX = event.pageX;
    this.startLeft = this.leftPanelWidth;
    document.body.style.userSelect = 'none';
  }

  startTouchResize(event: TouchEvent) {
    event.preventDefault();
    if (this.isMobile) return;
    this.isResizing = true;
    this.startX = event.touches[0].pageX;
    this.startLeft = this.leftPanelWidth;
    document.body.style.userSelect = 'none';
  }

  private handleMouseMove = (event: MouseEvent) => {
    if (!this.isResizing) return;
    if (this.rafId) {
      cancelAnimationFrame(this.rafId);
    }

    this.rafId = requestAnimationFrame(() => {
      const diff = event.pageX - this.startX;
      this.updatePosition(this.startLeft + diff);
    });
  };

  private handleTouchMove = (event: TouchEvent) => {
    if (!this.isResizing) return;
    if (this.rafId) {
      cancelAnimationFrame(this.rafId);
    }

    this.rafId = requestAnimationFrame(() => {
      const diff = event.touches[0].pageX - this.startX;
      this.updatePosition(this.startLeft + diff);
    });
  };

  private updatePosition(newLeft: number) {
    const maxLeft = this.containerWidth - this.rightPanelMinWidth;
    const clampedLeft = Math.min(
      Math.max(newLeft, this.minLeftPanelWidth),
      maxLeft,
    );

    if (clampedLeft !== this.leftPanelWidth) {
      this.leftPanelWidth = clampedLeft;
      this.ngZone.run(() => {
        this.leftPanelWidthChange.emit(clampedLeft);
        this.cdr.detectChanges();
      });
    }
  }

  private stopResize = () => {
    if (!this.isResizing) return;
    this.isResizing = false;
    document.body.style.userSelect = '';
    if (this.rafId) {
      cancelAnimationFrame(this.rafId);
      this.rafId = null;
    }
  };

  @HostListener('window:resize')
  onWindowResize() {
    this.ngZone.run(() => {
      this.containerWidth = this.elementRef.nativeElement.offsetWidth;
      this.maxLeftPanelWidth = this.containerWidth - this.rightPanelMinWidth;
      this.checkMobileView();
      if (this.showRightPanel) {
        this.adjustLeftPanelWidth();
      }
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.rafId) {
      cancelAnimationFrame(this.rafId);
    }

    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.stopResize);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('touchend', this.stopResize);
  }
}
