import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Email, EmailObject } from '../../../models/email';
import { ModalComponent } from '../../modal/modal.component';
import { EmailConfigurationService } from '../../../../../leadmanager/src/app/_services/email/email-configuration.service';
import { MatDialog } from '@angular/material/dialog';
import { map, takeUntil } from 'rxjs/operators';
import { SnackBarService } from '../../../services/snack-bar/snack-bar.service';
import { SelectService } from '../../../services/select.service';
import { SelectList } from '../../../models/select-list';
import { Feed } from '../../../models/feed';

@Component({
  selector: 'lib-feed-email',
  templateUrl: './feed-email.component.html',
  styleUrls: ['./feed-email.component.scss'],
})
export class FeedEmailComponent implements OnInit, OnDestroy {
  @Input() email: EmailObject = {} as EmailObject;
  @Input() feed?: Feed;

  emailType$: Observable<string> = new Observable<string>();
  private destroy$ = new Subject<void>();

  constructor(
    private emailConfigurationService: EmailConfigurationService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private selectService: SelectService,
  ) {}

  ngOnInit(): void {
    this.emailType$ = this.getEmailType(this.email.email_type_id);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getRecipients(type: 'to' | 'cc'): string {
    const recipients = JSON.parse(
      this.email[type === 'to' ? 'mail_to' : 'mail_cc'],
    );
    return recipients.join(', ');
  }

  getEmailType(emailTypeId: string): Observable<string> {
    return this.selectService
      .getSelectOptionsBySelectList(SelectList.EmailType)
      .pipe(
        takeUntil(this.destroy$),
        map((options) => {
          const option = options.find((opt) => opt.value === emailTypeId);
          return option ? option.name : 'Kein Standard-Template';
        }),
      );
  }

  resendEmail(emailObject: EmailObject) {
    const inputData =
      this.emailConfigurationService.getResendEmailData(emailObject);

    this.dialog
      .open(ModalComponent, {
        disableClose: true,
        data: inputData,
      })
      .afterClosed()
      .subscribe((modalResponse) => {
        if (!modalResponse) return;

        this.send(emailObject);
      });
  }

  private send(emailObject: EmailObject) {
    const email = {
      email_id: emailObject.id,
    };

    this.emailConfigurationService
      .sendTestEmail(email)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          if (response.success) {
            this.snackBarService.openSnackBar(
              `Erfolgreich gesendet.`,
              'success',
            );
          } else {
            this.snackBarService.openSnackBar(
              `Fehler: ${response.message}`,
              'error',
            );
          }
        },
        error: (error) => {
          this.snackBarService.openSnackBar(
            `Ein Fehler ist aufgetreten: ${error.message}`,
            'error',
          );
        },
      });
  }
}
