import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { Feed, FeedMapper } from '../../models/feed';
import { Lead } from '../../models/leads/lead';

@Component({
  selector: 'lib-feed-item-list',
  templateUrl: './feed-item-list.component.html',
  styleUrls: ['./feed-item-list.component.scss'],
})
export class FeedItemListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() lead?: Lead = new Lead({});
  @Input() feeds: Feed[] = [];
  @Input() currentFilter?: string;

  expandedFeedId: number | null | undefined = null;
  filteredItems$: Observable<Feed[]>;
  private destroy$ = new Subject<void>();
  private itemsSubject = new BehaviorSubject<Feed[]>([]);
  private filterSubject = new BehaviorSubject<string | undefined>(undefined);

  constructor() {
    this.filteredItems$ = combineLatest([
      this.itemsSubject,
      this.filterSubject,
    ]).pipe(
      map(([feeds, filter]) => {
        let filteredFeeds = feeds.filter(
          (feed) => feed.partner_lead_id === null,
        );

        if (filter) {
          filteredFeeds = filteredFeeds.filter(
            (feed) => feed.relatable_type === filter,
          );
        }

        return filteredFeeds.sort((a, b) => {
          if (a.flag && !b.flag) return -1;
          if (!a.flag && b.flag) return 1;

          const dateA = new Date(a.event_at).getTime();
          const dateB = new Date(b.event_at).getTime();
          return dateB - dateA;
        });
      }),
      distinctUntilChanged(),
      takeUntil(this.destroy$),
    );
  }

  ngOnInit(): void {
    this.itemsSubject.next(this.feeds);
    this.filterSubject.next(this.currentFilter);
  }

  ngOnChanges(): void {
    this.itemsSubject.next(this.feeds);
    this.filterSubject.next(this.currentFilter);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onToggleExpand(feed: Feed): void {
    this.expandedFeedId = this.expandedFeedId === feed.id ? null : feed.id;
  }

  protected readonly FeedMapper = FeedMapper;
}
