<div class="address-container">
  <div class="address-header">
    <h3 class="address-title">{{ getAddressTypeLabel(address.type) }}</h3>
    <div class="address-actions" *ngIf="!inputsDisabled">
      <div *ngIf="address.type !== 1">
        <button
          mat-stroked-button
          (click)="removeAddress(address)"
          class="ml-2"
        >
          <mat-icon>delete</mat-icon> Adresse löschen
        </button>
      </div>

      <div class="form-check">
        <input
          type="checkbox"
          [id]="'separateAddress_' + address.type"
          [(ngModel)]="separateAddress"
          [disabled]="inputsDisabled"
          (change)="onSeparateAddressChange()"
        />
        <label
          class="form-check-label"
          [for]="'separateAddress_' + address.type"
        >
          Adresse trennen
        </label>
      </div>
    </div>
  </div>

  <div class="address-content">
    <!-- Unternehmensinformationen und Personendaten -->
    <div class="section-row">
      <div class="form-field">
        <input
          type="text"
          placeholder="Firma"
          [(ngModel)]="address.company_name"
          [disabled]="inputsDisabled"
          (change)="onDuplicateCheck()"
        />
      </div>
      <div class="form-field">
        <input
          type="text"
          placeholder="Umsatzsteuer-ID"
          [(ngModel)]="address.ust_id"
          [disabled]="inputsDisabled"
          (change)="notifyChange()"
        />
      </div>
    </div>

    <div class="section-row">
      <div class="form-field">
        <select
          [(ngModel)]="address.salutation"
          [disabled]="inputsDisabled"
          (change)="notifyChange()"
        >
          <option value="">Anrede</option>
          <option value="Herr">Herr</option>
          <option value="Frau">Frau</option>
        </select>
      </div>
      <div class="form-field">
        <input
          type="text"
          placeholder="Vorname"
          [(ngModel)]="address.first_name"
          [disabled]="inputsDisabled"
          (change)="onNameChange()"
        />
      </div>
      <div class="form-field">
        <input
          type="text"
          placeholder="Nachname"
          [(ngModel)]="address.last_name"
          [disabled]="inputsDisabled"
          (change)="onNameChange()"
        />
      </div>
    </div>

    <!-- Adressdaten mit alter Autocomplete-Logik -->
    <div class="form-field full-width">
      <div class="input-with-autocomplete">
        <input
          type="text"
          placeholder="Standort Adresse"
          [(ngModel)]="address.address"
          [matAutocomplete]="auto"
          [disabled]="separateAddress || inputsDisabled"
          (input)="onAutocompleteSearchInputChange()"
          (change)="onAutocompleteAddressChange()"
          (click)="onAutocompleteSearchInputChange()"
          [ngClass]="{ 'invalid-input': isFieldInvalid('address') }"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="onAutocompleteAddressChange()"
        >
          <mat-option
            *ngFor="let suggestion of autocompleteSuggestions"
            [value]="suggestion.address.label"
          >
            {{ suggestion.address.label }}
          </mat-option>
        </mat-autocomplete>
      </div>
    </div>

    <div *ngIf="separateAddress">
      <div class="section-row">
        <div class="form-field">
          <input
            type="text"
            placeholder="Straße"
            [(ngModel)]="address.street"
            [disabled]="inputsDisabled"
            (change)="onAddressChange()"
            [ngClass]="{ 'invalid-input': isFieldInvalid('street') }"
          />
        </div>
        <div class="form-field">
          <input
            type="text"
            placeholder="Postleitzahl"
            [(ngModel)]="address.postcode"
            [disabled]="inputsDisabled"
            (change)="onAddressChange()"
            [ngClass]="{ 'invalid-input': isFieldInvalid('postcode') }"
          />
        </div>
        <div class="form-field">
          <input
            type="text"
            placeholder="Ort"
            [(ngModel)]="address.city"
            [disabled]="inputsDisabled"
            (change)="onAddressChange()"
            [ngClass]="{ 'invalid-input': isFieldInvalid('city') }"
          />
        </div>
      </div>

      <div class="form-field">
        <select
          [(ngModel)]="address.country_code"
          [disabled]="inputsDisabled"
          (change)="onAddressChange()"
        >
          <option value="">Land auswählen</option>
          <option *ngFor="let country of countries" [value]="country.code">
            {{ country.name }}
          </option>
        </select>
      </div>
    </div>

    <!-- Kontaktdaten -->
    <div class="section-row">
      <div class="form-field">
        <div class="input-group">
          <input
            type="text"
            placeholder="Telefon"
            [(ngModel)]="address.phone"
            [disabled]="inputsDisabled"
            [ngClass]="{
              'input-invalid':
                address.phone_checked_at && !address.phone_is_valid
            }"
            (change)="onDuplicateCheck()"
          />
          <div class="input-actions">
            <mat-icon
              *ngIf="
                address.phone &&
                ApplicationHelper.applicationName === Application.Leadmanager
              "
              [matTooltip]="getIconTooltipText('phone')"
              [ngClass]="{
                valid: address.phone_checked_at && address.phone_is_valid,
                invalid: address.phone_checked_at && !address.phone_is_valid
              }"
            >
              {{
                !address.phone_checked_at
                  ? 'help_outline'
                  : address.phone_is_valid
                  ? 'check_circle_outline'
                  : 'cancel'
              }}
            </mat-icon>
            <button
              class="action-btn"
              *ngIf="
                address.lead_id &&
                ApplicationHelper.applicationName === Application.Leadmanager
              "
              [disabled]="!address.phone"
              (click)="onLeadCallClick(address.phone)"
              matTooltip="Anrufen"
            >
              <i class="bi bi-telephone"></i>
            </button>
            <button
              class="action-btn"
              *ngIf="
                address.lead_id &&
                ApplicationHelper.applicationName === Application.Leadmanager
              "
              [disabled]="!address.phone"
              (click)="onLeadSmsClick(address.phone)"
              matTooltip="SMS senden"
            >
              <i class="bi bi-chat-left-text"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="input-group">
          <input
            type="text"
            placeholder="Telefon 2"
            [(ngModel)]="address.phone2"
            [disabled]="inputsDisabled"
            [ngClass]="{
              'input-invalid':
                address.phone2_checked_at && !address.phone2_is_valid
            }"
            (change)="notifyChange()"
          />
          <div class="input-actions">
            <mat-icon
              *ngIf="
                address.phone2 &&
                ApplicationHelper.applicationName === Application.Leadmanager
              "
              [matTooltip]="getIconTooltipText('phone2')"
              [ngClass]="{
                valid: address.phone2_checked_at && address.phone2_is_valid,
                invalid: address.phone2_checked_at && !address.phone2_is_valid
              }"
            >
              {{
                !address.phone2_checked_at
                  ? 'help_outline'
                  : address.phone2_is_valid
                  ? 'check_circle_outline'
                  : 'cancel'
              }}
            </mat-icon>
            <button
              class="action-btn"
              *ngIf="
                address.lead_id &&
                ApplicationHelper.applicationName === Application.Leadmanager
              "
              [disabled]="!address.phone2"
              (click)="onLeadCallClick(address.phone2)"
              matTooltip="Anrufen"
            >
              <i class="bi bi-telephone"></i>
            </button>
            <button
              class="action-btn"
              *ngIf="
                address.lead_id &&
                ApplicationHelper.applicationName === Application.Leadmanager
              "
              [disabled]="!address.phone2"
              (click)="onLeadSmsClick(address.phone2)"
              matTooltip="SMS senden"
            >
              <i class="bi bi-chat-left-text"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-field full-width">
      <div class="input-group">
        <input
          type="text"
          placeholder="E-Mail-Adresse"
          [(ngModel)]="address.email"
          [disabled]="inputsDisabled"
          [ngClass]="{
            'input-invalid': address.email_checked_at && !address.email_is_valid
          }"
          (change)="onDuplicateCheck()"
        />
        <div class="input-actions">
          <mat-icon
            *ngIf="
              address.email &&
              ApplicationHelper.applicationName === Application.Leadmanager
            "
            [matTooltip]="getIconTooltipText('email')"
            [ngClass]="{
              valid: address.email_checked_at && address.email_is_valid,
              invalid: address.email_checked_at && !address.email_is_valid
            }"
          >
            {{
              !address.email_checked_at
                ? 'help_outline'
                : address.email_is_valid
                ? 'check_circle_outline'
                : 'cancel'
            }}
          </mat-icon>
          <button
            class="action-btn"
            *ngIf="
              address.lead_id &&
              ApplicationHelper.applicationName === Application.Leadmanager
            "
            [disabled]="!address.email"
            (click)="onLeadEmailClick(address.email)"
            matTooltip="E-Mail senden"
          >
            <i class="bi bi-envelope-at"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- Replace the existing Google Maps section with this improved version -->
    <div *ngIf="address.type === 1 && address.lat && address.lng">
      <lib-google-map [lat]="address.lat" [lng]="address.lng"></lib-google-map>
    </div>
  </div>
</div>
