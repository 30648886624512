<div *ngIf="loading" class="loading-overlay">
  <div fxLayout="column" fxLayoutAlign="space-around center">
    <mat-spinner></mat-spinner>
    Lädt...
  </div>
</div>

<div *ngIf="progress > 0 && progress < 100" class="progress-overlay">
  <div
    class="progress-container"
    fxLayout="column"
    fxLayoutAlign="space-around center"
  >
    <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
    <span class="progress-percentage">{{ progress | number : '1.0-0' }} %</span>
  </div>
</div>
