import { Component } from '@angular/core';

@Component({
  selector: 'lib-basic-card-example',
  template: BasicCardExample.HTML_CODE,
})
export class BasicCardExample {
  static HTML_CODE = `
    <app-card>
      <app-card-body>
        <p>Dies ist eine einfache Card mit Beispielinhalt.</p>
        <p>
          Sie eignet sich besonders gut für die Darstellung von Text und
          einfachen Informationen.
        </p>
      </app-card-body>
    </app-card>
  `;
}
