import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import html2canvas from 'html2canvas';
import { BehaviorSubject, interval, Subscription, take } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { AuthService } from './auth.service';
import { SocketIoService } from './socket-io.service';

export interface Screenshot {
  filename: string;
  path: string;
  created_at: number;
  data: string;
}

export interface ScreenshotDialogData {
  screenshot: any;
  currentIndex: number;
  total: number;
}

@Injectable({
  providedIn: 'root',
})
export class ScreenshotService implements OnDestroy {
  private screenshotInterval: Subscription | null = null;
  private supportSubscription: Subscription | null = null;
  private adminSubscription: Subscription | null = null;
  private currentInterval: number = 10000;

  private screenshotsSubject = new BehaviorSubject<Screenshot[]>([]);
  public screenshots$ = this.screenshotsSubject.asObservable();

  private mousePosition = { x: 0, y: 0 };
  private mouseMoveHandler = (event: MouseEvent) => {
    this.mousePosition = { x: event.clientX, y: event.clientY };
  };

  private dialogDataSubject = new BehaviorSubject<ScreenshotDialogData | null>(
    null,
  );
  public dialogData$ = this.dialogDataSubject.asObservable();

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private authService: AuthService,
    private socketIoService: SocketIoService,
  ) {
    document.addEventListener('mousemove', this.mouseMoveHandler);
  }

  init() {
    this.authService.partnerUser$.pipe(take(1)).subscribe((partnerUser) => {
      if (partnerUser?.id) {
        this.socketIoService
          .connectSupportChannel(partnerUser.id)
          .then(() => {
            this.initializeSupportUpdates();
          })
          .catch((err) =>
            console.error('Fehler beim Verbinden des Support-Kanals:', err),
          );
      } else {
        console.warn('Kein PartnerUser verfügbar bei init');
      }
    });
  }

  updateDialogData(data: ScreenshotDialogData) {
    this.dialogDataSubject.next(data);
  }

  private initializeSupportUpdates() {
    if (this.supportSubscription) return;
    this.supportSubscription = this.socketIoService.supportUpdates.subscribe({
      next: (update: any) => {
        if (update?.data?.type === 'screenshot_command') {
          switch (update.data.action) {
            case 'start':
              this.startScreenshotCapture(
                update.data.interval || this.currentInterval,
              );
              break;
            case 'stop':
              this.stopScreenshotCapture();
              break;
            case 'single':
              this.captureAndSendScreenshot();
              break;
          }
        }
      },
      error: (err) => console.error('Support-Update Fehler:', err),
    });
  }

  private async captureAndSendScreenshot(): Promise<void> {
    try {
      // Größe des sichtbaren Bereichs (Viewport)
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      if (viewportWidth === 0 || viewportHeight === 0) {
        throw new Error(
          'Viewport-Dimensionen sind 0 – Fenster möglicherweise minimiert',
        );
      }

      // Aktuelle Scroll-Position
      const scrollX = window.scrollX || window.pageXOffset;
      const scrollY = window.scrollY || window.pageYOffset;

      const canvas = await html2canvas(document.documentElement, {
        logging: true,
        useCORS: true,
        allowTaint: true,
        scrollX: 0,
        scrollY: 0,
        x: scrollX,
        y: scrollY,
        width: viewportWidth,
        height: viewportHeight,
        windowWidth: document.documentElement.scrollWidth,
        windowHeight: document.documentElement.scrollHeight,
        removeContainer: true,
        scale: window.devicePixelRatio,
        onclone: (clonedDoc) => {
          const icons = clonedDoc.querySelectorAll('i, svg, [class*="icon"]');
          icons.forEach((icon) => {
            if (icon instanceof HTMLElement) {
              icon.style.visibility = 'visible';
              icon.style.display = getComputedStyle(icon).display;
            }
          });
        },
        ignoreElements: (element) =>
          element.classList.contains('ignore-screenshot'),
      });

      const ctx = canvas.getContext('2d');
      if (ctx) {
        const mouseX = this.mousePosition.x - scrollX;
        const mouseY = this.mousePosition.y - scrollY;

        ctx.beginPath();
        ctx.arc(mouseX, mouseY, 10, 0, Math.PI * 2);
        ctx.fillStyle = 'red';
        ctx.fill();
      } else {
        console.warn('Kein 2D-Kontext verfügbar');
      }

      const screenshot = canvas.toDataURL('image/jpeg', 0.9);

      // Abschließend an Backend senden
      this.authService.partnerUser$
        .pipe(take(1))
        .subscribe(async (partnerUser) => {
          if (!partnerUser?.id) {
            console.warn('Kein PartnerUser ID verfügbar');
            return;
          }
          const response = await this.sendScreenshot(
            screenshot,
            partnerUser.id,
          ).toPromise();
        });
    } catch (error) {
      console.error('Fehler beim Erfassen des Screenshots:', error);
    }
  }

  private sendScreenshot(screenshot: string, partnerUserId: number) {
    const payload = {
      screenshot,
      timestamp: new Date().toISOString(),
      partner_user_id: partnerUserId,
      mouse_position: this.mousePosition,
    };
    const url = `${this.environmentService.apiUrl}screenshots`;
    return this.http.post(url, payload);
  }

  startScreenshotCapture(intervalMs: number = 10000): void {
    this.stopScreenshotCapture();
    this.currentInterval = intervalMs;
    this.screenshotInterval = interval(this.currentInterval).subscribe(() => {
      this.captureAndSendScreenshot().catch((err) =>
        console.error('Fehler im Intervall-Screenshot:', err),
      );
    });
  }

  stopScreenshotCapture(): void {
    if (this.screenshotInterval) {
      this.screenshotInterval.unsubscribe();
      this.screenshotInterval = null;
    }
  }

  initializeScreenshotNotifications(partnerUserId: number) {
    if (this.adminSubscription) {
      return;
    }
    this.socketIoService
      .connectSupportChannel(partnerUserId, 'screenshot-admin')
      .then(() => {
        this.adminSubscription = this.socketIoService.adminUpdates.subscribe({
          next: (update: any) => {
            if (update?.data?.type === 'new_screenshot') {
              this.fetchSingleScreenshot(update.data.filename, partnerUserId);
            }
          },
          error: (err) => console.error('Fehler bei Admin-Updates:', err),
        });
      })
      .catch((err) =>
        console.error('Fehler beim Verbinden des Admin-Kanals:', err),
      );
  }

  private fetchSingleScreenshot(filename: string, partnerUserId: number) {
    const url = `${this.environmentService.apiUrl}screenshots/partner_user_${partnerUserId}/${filename}`;
    this.http.get(url).subscribe({
      next: (newScreenshot: any) => {
        const currentScreenshots = this.screenshotsSubject.value;

        const exists = currentScreenshots.some((s) => {
          return s.filename === filename;
        });
        if (!exists) {
          const updatedScreenshots = [
            newScreenshot,
            ...currentScreenshots,
          ].sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime(),
          );
          this.screenshotsSubject.next(updatedScreenshots);
        }
      },
      error: (err) =>
        console.error('Fehler beim Abrufen des Screenshots:', err),
    });
  }

  loadScreenshots(fromDate?: string, toDate?: string, partnerUserId?: number) {
    if (!partnerUserId) return;

    const params: any = { partner_user_id: partnerUserId };
    if (fromDate) params.from_date = fromDate;
    if (toDate) params.to_date = toDate;

    const url = `${this.environmentService.apiUrl}screenshots`;
    this.http.get<Screenshot[]>(url, { params }).subscribe(
      (screenshots) => this.screenshotsSubject.next(screenshots),
      (error) => this.screenshotsSubject.next([]),
    );
  }

  ngOnDestroy() {
    this.stopScreenshotCapture();
    this.supportSubscription?.unsubscribe();
    this.adminSubscription?.unsubscribe();
    document.removeEventListener('mousemove', this.mouseMoveHandler);

    this.authService.partnerUser$.pipe(take(1)).subscribe((partnerUser) => {
      if (partnerUser?.id) {
        this.socketIoService.disconnectSupportChannel(partnerUser.id);
        this.socketIoService.disconnectSupportChannel(
          partnerUser.id,
          'screenshot-admin',
        );
      }
    });
  }
}
