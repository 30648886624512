import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { TableService } from '../../services/table.service';

@Component({
  selector: 'lib-table-column',
  template: '',
})
export class TableColumnComponent implements OnInit {
  @Input() columnName: string = '';
  @Input() title?: string = '';
  @Input() headlineTooltipText?: string = '';
  @Input() summableColumnName?: string = '';
  @Input() pipe: string | { name: string; args: any[] } | undefined;
  @Input() isSortable: boolean = true;
  @Input() isSearchable: boolean = true;
  @Input() designPointer: boolean = false;
  @Input() defaultSort?: 'asc' | 'desc' | '';
  @Input() prefix?: string;
  @Input() suffix?: string;
  @Input() width?: string = '';
  @Input() verticalAlign?: string = '';
  @Input() backgroundColorFn?: any;
  @Input() customTemplate?: TemplateRef<any>;
  @Input() summableColumnUnit?: string = 'dynamic';
  constructor(private tableService: TableService) {}

  ngOnInit() {
    this.tableService.registerColumn({
      key: this.columnName,
      title: this.title,
      headlineTooltipText: this.headlineTooltipText,
      pipe: this.pipe,
      isSortable: this.isSortable,
      isSearchable: this.isSearchable,
      designPointer: this.designPointer,
      prefix: this.prefix,
      suffix: this.suffix,
      defaultSort: this.defaultSort || '',
      width: this.width,
      verticalAlign: this.verticalAlign,
      customTemplate: this.customTemplate,
      summableColumnName: this.summableColumnName,
      summableColumnUnit: this.summableColumnUnit,
      backgroundColorFn: this.backgroundColorFn,
    });
  }
}
