import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FileUpload } from '../../models/fileUpload';
import { FileUploadService } from '../../services/file-upload/file-upload.service';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { EnvironmentService } from '../../services/environment.service';
import { ModalComponent } from '../modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalInputData } from '../../models/modal';

const MAX_FILE_SIZE = 5000000; // 5MB
const VALID_FILE_TYPES = ['image/jpeg', 'image/png', 'application/pdf'];

@Component({
  selector: 'lib-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  @Input() existingFiles!: FileUpload[];
  @Input() disabled: boolean = false;
  @ViewChild('fileInput') fileInput!: ElementRef;

  @Output() fileListEmitter = new EventEmitter<File[]>();
  @Output() fileUploadError = new EventEmitter<string>();
  @Output() fileDeleted = new EventEmitter<FileUpload>();

  API_URL = this.environmentService.apiUrl;
  isDragging = false;
  filesToUpload: File[] = [];

  constructor(
    private fileUploadService: FileUploadService,
    private snackbarService: SnackBarService,
    private environmentService: EnvironmentService,
    private dialog: MatDialog,
  ) {}

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = false;
    if (
      event.dataTransfer &&
      event.dataTransfer.files.length > 0 &&
      !this.disabled
    ) {
      this.handleFileSelection(event.dataTransfer.files);
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = true;
  }

  onDragLeave(event: DragEvent): void {
    this.isDragging = false;
  }

  selectFiles(event: Event): void {
    const element = event.target as HTMLInputElement;
    let files = element.files;
    if (!files || files.length === 0) {
      this.fileUploadError.emit('Keine Dateien ausgewählt.');
      return;
    }
    this.handleFileSelection(files);
  }

  private handleFileSelection(files: FileList): void {
    const validFiles: File[] = [];
    const invalidFiles: File[] = [];

    Array.from(files).forEach((file) => {
      if (this.isValidFile(file)) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file);
      }
    });

    if (validFiles.length > 0) {
      this.filesToUpload = [...this.filesToUpload, ...validFiles]; // Fügt neue Dateien zur bestehenden Liste hinzu
      this.fileListEmitter.emit(this.filesToUpload);
    }

    if (invalidFiles.length > 0) {
      this.snackbarService.openSnackBar(
        'Bitte laden Sie die Datei in einem gültigen Format hoch (jpeg, png, pdf)',
        'warn',
      );
      this.fileUploadError.emit(
        `Einige Dateien wurden aufgrund ungültiger Typen oder Größen ignoriert.`,
      );
    }
  }

  private isValidFile(file: File): boolean {
    return file.size <= MAX_FILE_SIZE && VALID_FILE_TYPES.includes(file.type);
  }

  deleteFile(file: FileUpload): void {
    const inputData: ModalInputData = {
      headline: 'Datei löschen',
      body:
        'Möchten Sie <b><em>' +
        file.original_file_name +
        '</em></b> wirklich löschen?',
      acceptButtonText: 'Ja',
      declineButtonText: 'Nein',
    };

    this.dialog
      .open(ModalComponent, {
        disableClose: true,
        data: inputData,
      })
      .afterClosed()
      .subscribe((modalResponse) => {
        if (modalResponse) {
          this.fileUploadService.delete(file.id).subscribe(() => {
            this.existingFiles = this.existingFiles?.filter((f) => f !== file);
            this.snackbarService.openSnackBar('Datei gelöscht', 'success');
            this.fileDeleted.emit(file);
          });
        }
      });
  }

  removeFileFromUploadList(index: number): void {
    this.filesToUpload.splice(index, 1);
    this.fileListEmitter.emit(this.filesToUpload);
  }

  clearAllFiles(): void {
    this.filesToUpload = [];
    this.fileListEmitter.emit(this.filesToUpload);
  }
}
