export class ApplicationHelper {
  // Liste der gültigen Anwendungsnamen
  private static validAppNames = ['salesrunner', 'leadmanager', 'admin'];

  static get applicationName(): string {
    const pathParts = window.location.pathname
      .split('/')
      .filter((part) => part);

    for (let validAppName of this.validAppNames) {
      // Überprüfen, ob der extrahierte Name in der Liste der gültigen Namen ist
      if (pathParts.includes(validAppName)) {
        if (validAppName === 'admin') {
          validAppName = 'leadmanager';
        }

        return this.capitalizeFirstLetter(validAppName);
      }
    }

    return 'Salesrunner'; // Standardwert, falls kein gültiger Name gefunden wird
  }

  private static capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
