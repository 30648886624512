import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lib-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SlideToggleComponent),
      multi: true,
    },
  ],
})
export class SlideToggleComponent implements OnChanges, ControlValueAccessor {
  @Input() value: number | boolean = 0;
  @Output() valueChange = new EventEmitter<number>();

  booleanValue: boolean = false;

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value']) {
      this.booleanValue = this.value === 1 || this.value === true;
    }
  }

  onValueChange() {
    const newValue = this.booleanValue ? 1 : 0;
    this.value = newValue;
    this.valueChange.emit(newValue);
    this.onChangeCallback(newValue);
    this.onTouchedCallback();
  }

  // ControlValueAccessor methods
  writeValue(value: any): void {
    if (value !== undefined && value !== null) {
      this.value = value;
      this.booleanValue = value === 1 || value === true;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Implement if needed
  }
}
