<div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="10px">
  <lib-select
    class="condition-property"
    [appendClass]="'body'"
    [options]="propertyOptions"
    [clearable]="false"
    [selectedOptions]="selectedProperty"
    (valueChanged)="onPropertyChanged($event)"
  ></lib-select>

  <lib-select
    class="condition-operator"
    [appendClass]="'body'"
    [options]="filteredOperatorOptions"
    [clearable]="false"
    [selectedOptions]="selectedOperator"
    (valueChanged)="onOperatorChanged($event)"
  ></lib-select>

  <ng-container [ngSwitch]="true">
    <ng-container *ngSwitchCase="showValuesAsSelect(HelperType.LeadType)">
      <lib-select
        class="condition-value"
        [appendClass]="'body'"
        [options]="leadTypeList$ | async"
        [clearable]="false"
        [selectedOptions]="selectedLeadType"
        (valueChanged)="onValueSelectChanged($event, HelperType.LeadType)"
      ></lib-select>
    </ng-container>

    <ng-container *ngSwitchCase="showValuesAsSelect(HelperType.EmailTemplate)">
      <lib-select
        class="condition-value"
        [appendClass]="'body'"
        [options]="emailTemplateList$ | async"
        [clearable]="false"
        [selectedOptions]="selectedEmailTemplate"
        (valueChanged)="onValueSelectChanged($event, HelperType.EmailTemplate)"
      ></lib-select>
    </ng-container>

    <ng-container *ngSwitchCase="showValuesAsSelect(HelperType.LeadDetails)">
      <lib-select
        class="condition-value"
        [appendClass]="'body'"
        [options]="currentDetailOptions"
        [clearable]="false"
        [selectedOptions]="selectedLeadDetails"
        (valueChanged)="onValueSelectChanged($event, HelperType.LeadDetails)"
      ></lib-select>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <mat-form-field class="condition-value" appearance="fill">
        <mat-label>Wert</mat-label>
        <input matInput [formControl]="valueControl" />
      </mat-form-field>
    </ng-container>
  </ng-container>
</div>
