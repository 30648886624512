import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataTablesParameters } from '@lib/interfaces/optionen.interface';
import { map } from 'rxjs/operators';
import { User } from '@lib/models/user';
import { HttpHandlerService } from '@lib/services/http-handler.service';
import { EnvironmentService } from '@lib/services/environment.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  endpoint: string = 'users/';

  constructor(
    private httpHandler: HttpHandlerService,
    protected http: HttpClient,
    private environmentService: EnvironmentService,
  ) {}

  datatable(dataTablesParams: DataTablesParameters): Observable<any> {
    return this.httpHandler.datatable(this.endpoint, dataTablesParams).pipe(
      map((response) => {
        return {
          recordsFiltered: response.recordsFiltered,
          recordsTotal: response.recordsTotal,
          data: response.data.map((item: User) => new User(item)),
        };
      }),
    );
  }

  list(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  getList(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  show(id: number): Observable<any> {
    return this.httpHandler.show(this.endpoint, id);
  }

  create(user: User): Observable<any> {
    return this.httpHandler.create(this.endpoint, user);
  }

  update(user: User): Observable<any> {
    return this.httpHandler.update(this.endpoint, user);
  }

  delete(id: number): Observable<any> {
    return this.httpHandler.delete(this.endpoint, id);
  }

  register(formData: any): Observable<any> {
    return this.http.post(
      this.environmentService.apiUrl + 'register',
      formData,
      {
        responseType: 'json',
      },
    );
  }
}
