<table class="api-table">
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Default</th>
      <th>Description</th>
      <th>Required</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let prop of properties">
      <td class="font-mono">{{ prop.name }}</td>
      <td class="font-mono">{{ prop.type }}</td>
      <td class="font-mono">{{ prop.default }}</td>
      <td>{{ prop.description }}</td>
      <td>{{ prop.required ? 'Yes' : 'No' }}</td>
    </tr>
  </tbody>
</table>
