<mat-sidenav-container class="styleguide-container h-full">
  <mat-sidenav mode="side" opened class="styleguide-sidenav w-64">
    <mat-accordion>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="mr-2">widgets</mat-icon>
            Components
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-nav-list>
          <a
            mat-list-item
            *ngFor="let item of componentItems"
            [routerLink]="['components', item.route]"
            routerLinkActive="active"
          >
            <mat-icon matListItemIcon>{{ item.icon }}</mat-icon>
            <span matListItemTitle>{{ item.name }}</span>
          </a>
        </mat-nav-list>
      </mat-expansion-panel>

      <mat-nav-list>
        <a
          mat-list-item
          *ngFor="let item of otherItems"
          [routerLink]="[item.route]"
          routerLinkActive="active"
        >
          <mat-icon matListItemIcon>{{ item.icon }}</mat-icon>
          <span matListItemTitle>{{ item.name }}</span>
        </a>
      </mat-nav-list>
    </mat-accordion>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="p-6">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
