import { Component } from '@angular/core';

@Component({
  selector: 'lib-basic-table-example',
  template: BasicTableExample.HTML_CODE,
  styles: [
    `
      .lead-number-container {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    `,
  ],
})
export class BasicTableExample {
  static HTML_CODE = `
    <div class="example-container">
      <lib-table
        [tableId]="'example-lead-table'"
        [endpoint]="'leads/'"
        [initialPageSize]="10"
        [showSearchbar]="true"
        [designStriped]="true"
        [designHoverEffect]="true"
        [additionalSearchFields]="['address.email', 'address.phone']"
        (clickedElementChanged)="onLeadClick($event)"
      >
        <lib-table-column
          [columnName]="'number'"
          [title]="'Leadnummer'"
          [isSearchable]="true"
          [isSortable]="true"
          [designPointer]="true"
          [customTemplate]="leadNumberTemplate"
        >
          <ng-template #leadNumberTemplate let-lead>
            <div class="lead-number-container">
              <span class="lead-number">#{{ lead.number }}</span>
              <span class="badge bg-danger fs-7" *ngIf="lead.is_blocked"
                >Gesperrt</span
              >
            </div>
          </ng-template>
        </lib-table-column>

        <lib-table-column
          [columnName]="'created_at'"
          [defaultSort]="'desc'"
          [title]="'Erstellt am'"
          [pipe]="{ name: 'date', args: ['dd.MM.yyyy HH:mm'] }"
          [suffix]="'Uhr'"
          [isSearchable]="false"
          [designPointer]="true"
        >
        </lib-table-column>

        <lib-table-column
          [columnName]="'address.company_name'"
          [title]="'Name'"
          [isSortable]="true"
          [isSearchable]="false"
          [designPointer]="true"
          [customTemplate]="nameTemplate"
        >
          <ng-template #nameTemplate let-lead>
            {{ getDisplayName(lead.address) }}
          </ng-template>
        </lib-table-column>
      </lib-table>
    </div>
  `;

  onLeadClick(lead: any): void {
    console.log('Lead angeklickt:', lead);
  }

  getDisplayName(address: any): string {
    const company = address.company_name || '';
    const name = address.name || '';
    return company && name ? `${company} - ${name}` : company || name;
  }
}
