import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class HttpHandlerService {
  parameters: any = {};

  constructor(
    protected http: HttpClient,
    private environmentService: EnvironmentService,
  ) {}

  list(endpoint: string, httpParams: any = {}): Observable<any> {
    let params = new HttpParams();

    if (httpParams) {
      for (let key in httpParams) {
        params = params.set(key, JSON.stringify(httpParams[key]));
      }
    }

    return this.http.get(this.environmentService.apiUrl + endpoint, {
      responseType: 'json',
      params,
    });
  }

  datatable(endpoint: string, httpParams: any): Observable<any> {
    let params = new HttpParams();

    if (httpParams) {
      for (let key in httpParams) {
        params = params.set(key, JSON.stringify(httpParams[key]));
      }
    }

    return this.http.get(this.environmentService.apiUrl + endpoint, {
      responseType: 'json',
      params,
    });
  }

  show(endpoint: string, id: number): Observable<any> {
    return this.http.get(this.environmentService.apiUrl + endpoint + id, {
      responseType: 'json',
    });
  }

  get(endpoint: string, params: HttpParams | {}): Observable<any> {
    return this.http.get(this.environmentService.apiUrl + endpoint, {
      responseType: 'json',
      params,
    });
  }

  update(endpoint: string, object: any): Observable<any> {
    return this.http.put(
      this.environmentService.apiUrl + endpoint + object.id,
      object,
      {
        responseType: 'json',
      },
    );
  }

  create(endpoint: string, object: any): Observable<any> {
    return this.http.post(this.environmentService.apiUrl + endpoint, object, {
      responseType: 'json',
    });
  }

  delete(endpoint: string, id: number): Observable<any> {
    return this.http.delete(this.environmentService.apiUrl + endpoint + id, {
      responseType: 'json',
    });
  }

  export(endpoint: string, httpParams: any = {}): Observable<any> {
    const headers = new HttpHeaders({
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    let params = new HttpParams();

    if (httpParams) {
      for (let key in httpParams) {
        params = params.set(key, JSON.stringify(httpParams[key]));
      }
    }

    return this.http.get(this.environmentService.apiUrl + endpoint, {
      responseType: 'blob',
      headers,
      params,
      observe: 'response',
    });
  }

  updateBulk(endpoint: string, object: any): Observable<any> {
    return this.http.post(this.environmentService.apiUrl + endpoint, object, {
      responseType: 'json',
    });
  }
}
