<div class="example-form">
  <mat-form-field appearance="outline" class="custom-mat-field">
    <mat-date-range-input
      [formGroup]="rangeForm"
      [rangePicker]="picker"
      (keydown.enter)="dateRangeChanged()"
    >
      <input
        matStartDate
        formControlName="start"
        placeholder="Startdatum"
        (blur)="dateRangeChanged()"
      />
      <input
        matEndDate
        formControlName="end"
        placeholder="Enddatum"
        (blur)="dateRangeChanged()"
      />
    </mat-date-range-input>
    <mat-datepicker-toggle
      class="icon-datepicker"
      matSuffix
      [for]="picker"
      style="font-size: 16px"
    ></mat-datepicker-toggle>

    <mat-date-range-picker
      #picker
      [calendarHeaderComponent]="DaterangePickerComponent"
    >
      <mat-date-range-picker-actions>
        <button mat-button matDateRangePickerCancel>Abbrechen</button>
        <button
          mat-raised-button
          color="primary"
          matDateRangePickerApply
          (click)="applyRange()"
        >
          Übernehmen
        </button>
      </mat-date-range-picker-actions>
    </mat-date-range-picker>
  </mat-form-field>
</div>
