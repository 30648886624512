<h1 mat-dialog-title>Projekt beenden</h1>
<mat-dialog-content>
  <div class="title">Lead</div>

  <div class="content">
    #{{ partnerLead.lead_number }}

    <app-partner-lead-address
      [address]="partnerLead.address"
    ></app-partner-lead-address>

    <ng-container *ngFor="let address of partnerLead.addresses">
      <ng-container *ngIf="address.type === 2">
        <div class="delivery-address-title">
          Abweichende Angebots-/Rechnungsadresse
        </div>

        <app-partner-lead-address
          [address]="address"
        ></app-partner-lead-address>
      </ng-container>
    </ng-container>
  </div>

  <ng-container *ngIf="partnerUser$ | async as partnerUser">
    <ng-container *ngIf="partnerUser.invoice_and_performance_data === 1">
      <div class="title" style="margin-top: 20px">
        Nettorechnungssumme (Gesamt)
      </div>

      <div class="content">
        <span class="price">{{ billSum | priceFormat }}</span>
      </div>

      <div class="hint">
        Hiermit bestätige ich, dass das Projekt beendet ist und alle an den
        Kunden gestellten Rechnungen eine gesamte Nettorechnungssumme von
        <span class="price">{{ billSum | priceFormat }}</span> ergeben.
      </div>
    </ng-container>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>Abbrechen</button>
  <button
    (click)="finishProject()"
    class="btn btn-success btn-success"
    style="margin-left: 10px"
  >
    Projekt beenden | Benutzer {{ partnerUser?.first_name }}
    {{ partnerUser?.last_name }}
  </button>
</mat-dialog-actions>
