import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Observable, Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '@lib/services/auth.service';
import { SnackBarService } from '@lib/services/snack-bar/snack-bar.service';
import { EnvironmentService } from '@lib/services/environment.service';
import { environment } from '../../../lib/environments/environment';
import { SidenavElementInterface } from '@lib/interfaces/sidenav.interface';
import { MENU_DATA } from './models/menu-data';
import { Application } from '@lib/enum-collection';
import { PartnerUser } from '@lib/models/partners/partner-user';
import { PartnerLeadService } from '@lib/services/partner-lead/partner-lead.service';
import { ReminderService } from '@lib/services/reminder.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isLoggedIn$: Observable<boolean>;
  menuItems: SidenavElementInterface[] = MENU_DATA;
  private isInitialized = false;
  authUser: any = null;

  private destroy$ = new Subject<void>();

  private badgeCounts: Record<string, number> = {
    '/chance_list': 0,
    '/next_best_action': 0,
  };

  constructor(
    private authService: AuthService,
    private router: Router,
    private environmentService: EnvironmentService,
    private snackBarService: SnackBarService,
    private partnerLeadService: PartnerLeadService,
    private reminderService: ReminderService,
  ) {
    this.isLoggedIn$ = this.authService.isLoggedIn$;
    this.initializeEnvironment();
    this.authService.initializeAuth(Application.Salesrunner);
  }

  ngOnInit(): void {
    this.isLoggedIn$.pipe(takeUntil(this.destroy$)).subscribe((isLoggedIn) => {
      if (isLoggedIn && !this.isInitialized) {
        this.isInitialized = true;
        this.initializeLoggedInState();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initializeLoggedInState(): void {
    this.initializeMenuItems();
    this.setChancelistBadge();
    this.partnerUserRefresh();
    this.partnerUserRefreshIntervall();
    this.authService
      .getCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.authUser = user;
        this.setReminderBadge();
      });
  }

  partnerUserRefreshIntervall() {
    interval(15000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.partnerUserRefresh();
      });
  }

  partnerUserRefresh() {
    this.authService.getCurrentPartnerUser(true).subscribe(() => {
      this.filterMenuItem();
    });
  }

  logout(): void {
    this.authService.logout().subscribe({
      next: () => {
        this.router.navigate(['/login']);
        this.snackBarService.openSnackBar(
          'Sie wurden erfolgreich abgemeldet',
          'success',
        );
        this.destroy$.next();
        this.destroy$.complete();
      },
      error: (err) => console.error('Logout error:', err),
    });
  }

  private initializeEnvironment(): void {
    Object.assign(this.environmentService, {
      apiUrl: environment.apiUrl,
      production: environment.production,
      appVersion: require('../../../lib/package.json').version,
    });
  }

  private updateChancesBadge(chancesCount: number): void {
    this.badgeCounts['/chance_list'] = chancesCount;
    this.updateBadgeCountsOnly(this.menuItems, this.badgeCounts);
  }

  private updateRemindersBadge(remindersCount: number): void {
    this.badgeCounts['/next_best_action'] = remindersCount;
    this.updateBadgeCountsOnly(this.menuItems, this.badgeCounts);
  }

  handleLoggedInChange(isLoggedIn: boolean): void {
    if (isLoggedIn) {
      this.authService.getCurrentUser().subscribe({
        next: (user) => user && this.router.navigate(['/leads']),
        error: (err) => console.error('Error fetching user data:', err),
      });
    }
  }

  private updateBadgeCountsOnly(
    items: SidenavElementInterface[],
    badgeCounts: Record<string, number>,
  ): void {
    items.forEach((item) => {
      if (badgeCounts[item.route] !== undefined) {
        item.badgeCount = badgeCounts[item.route];
      }

      if (item.children?.length) {
        this.updateBadgeCountsOnly(item.children, badgeCounts);
        item.badgeCount = item.children.reduce(
          (sum, child) => sum + (child.badgeCount || 0),
          0,
        );
      }
    });
  }

  private initializeMenuItems(): void {
    this.menuItems = MENU_DATA.map((item) => ({
      ...item,
      expanded: false,
    }));
    this.filterMenuItem();
  }

  private filterMenuItem() {
    this.authService.partnerUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe((partnerUser: PartnerUser | null) => {
        this.menuItems = this.menuItems.filter((item) => {
          return (
            (partnerUser?.has_inspector_targetings ||
              item.route !== '/targeting') &&
            (partnerUser?.invoice_and_performance_data === 1 ||
              (item.route !== '/partner_bills' &&
                item.route !== '/chance_list'))
          );
        });

        // Update badge counts after filtering
        this.updateBadgeCountsOnly(this.menuItems, this.badgeCounts);
      });
  }

  setChancelistBadge() {
    const fetchAndUpdateChances = () => {
      this.partnerLeadService
        .getPartnerLeadChanceAmount()
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          this.updateChancesBadge(response.count);
        });
    };

    fetchAndUpdateChances();

    interval(15000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        fetchAndUpdateChances();
      });
  }

  setReminderBadge() {
    let intervalTimer = 15000;
    const filter = {
      'partner_user.id': this.authUser?.id,
      onlyOverdueReminders: true,
    };

    if (
      !this.environmentService.production &&
      !window.location.origin.includes('staging')
    ) {
      intervalTimer = 60000;
    }

    const fetchAndUpdateReminders = () => {
      this.reminderService
        .getRemindersForUser(filter)
        .pipe(takeUntil(this.destroy$))
        .subscribe((reminderData) => {
          this.updateRemindersBadge(reminderData?.data.length || 0);
        });
    };

    fetchAndUpdateReminders();

    interval(intervalTimer)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        fetchAndUpdateReminders();
      });
  }
}
