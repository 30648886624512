import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PartnerLeadBackend } from '@lib/models/partners/partner-lead-backend';
import { PartnerLead } from '@lib/models/partner-lead';
import { PartnerLeadBackendService } from '@lib/services/partner/partner-lead-backend.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-partner-lead-backend',
  templateUrl: './partner-lead-backend.component.html',
  styleUrls: ['./partner-lead-backend.component.scss'],
})
export class PartnerLeadBackendComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() inputsDisabled: boolean = false;
  @Input() formDisabled: boolean = false;
  @Input() partnerLead: PartnerLead = new PartnerLead({});

  @Output() partnerLeadChanged = new EventEmitter<PartnerLead>();

  partnerLeadBackend: PartnerLeadBackend = new PartnerLeadBackend({});

  private destroy$ = new Subject<void>();

  constructor(private partnerLeadBackendService: PartnerLeadBackendService) {}

  ngOnInit() {
    this.loadPartnerLeadBackend();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['partnerLead'] && !changes['partnerLead'].firstChange) {
      this.destroy$.next();
      this.loadPartnerLeadBackend();
    }
  }

  private loadPartnerLeadBackend() {
    this.partnerLeadBackendService
      .list({
        filter: {
          lead_type_id: this.partnerLead.lead_type_id,
        },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response) {
          this.partnerLeadBackend = response[0];

          this.partnerLeadBackend.lead_backend_elements =
            this.partnerLeadBackend.lead_backend_elements.filter(
              (element: any) =>
                element.lead_field?.type == 5 || element.lead_field?.type == 1,
            );

          this.partnerLeadBackend.lead_backend_elements =
            this.partnerLeadBackend.lead_backend_elements.map(
              (element: any) => {
                let existingSelection = null;

                // Suche nach einer Übereinstimmung
                if (this.partnerLead.lead_details) {
                  existingSelection = this.partnerLead.lead_details.find(
                    (detail: any) =>
                      detail.lead_field_id == element.lead_field.id,
                  );
                }

                return {
                  ...element,
                  selectedOption: existingSelection
                    ? existingSelection?.value
                    : '',
                };
              },
            );
        }
      });
  }

  onSelectedOptionChange(leadBackendElement: any) {
    if (!this.partnerLead.lead_details) {
      this.partnerLead.lead_details = [];
    }

    const existingDetailIndex = this.partnerLead.lead_details.findIndex(
      (detail: any) =>
        detail.lead_field_id === leadBackendElement.lead_field.id,
    );

    if (existingDetailIndex >= 0) {
      this.partnerLead.lead_details[existingDetailIndex].value =
        leadBackendElement.selectedOption;
    } else {
      this.partnerLead.lead_details.push({
        lead_field_id: leadBackendElement.lead_field.id,
        value: leadBackendElement.selectedOption,
      });
    }

    this.partnerLeadChanged.emit(this.partnerLead);
  }

  getLeadBackendElementFieldSize(fieldSize: number) {
    switch (fieldSize) {
      case 1:
        return 12;
      case 2:
        return 6;
      case 3:
        return 4;
      case 4:
        return 3;
      default:
        return 3;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
