import { Component, Input, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SnackBarService } from '@lib/services/snack-bar/snack-bar.service';
import { SplitViewStateService } from '../../services/split-view-state.service';
import { PartnerLeadService } from '@lib/services/partner-lead/partner-lead.service';
import { PartnerLead } from '@lib/models/partner-lead';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormField } from '@lib/models/modal';
import { ModalFormFieldType } from '@lib/enum-collection';
import { ModalComponent } from '@lib/components/modal/modal.component';
import { CreateFormGroupHelper } from '@lib/helpers/create-form-group-helper';
import { ChanceListOverviewComponent } from './chance-list-overview.component';
import { TableComponent } from '@lib/components/table/table.component';

@Component({
  selector: 'app-chance-detail-view',
  templateUrl: './chance-list-detail-view.component.html',
  styleUrls: ['./chance-list-detail-view.component.scss'],
})
export class ChanceListDetailViewComponent implements OnDestroy {
  @Input() chance$!: Observable<PartnerLead | null>;
  private destroy$ = new Subject<void>();

  constructor(
    private partnerLeadService: PartnerLeadService,
    private splitViewStateService: SplitViewStateService,
    private router: Router,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,
  ) {}

  close() {
    this.splitViewStateService.setSelectedChance(null);
    this.router.navigate(['/chance_list']);
  }

  getStatusText(status: number): string {
    switch (status) {
      case 7:
        return 'Warten auf Annahme';
      case 8:
        return 'Abgelehnt';
      default:
        return 'Unbekannt';
    }
  }

  acceptChance(chance: PartnerLead) {
    this.partnerLeadService.acceptPartnerLead(chance).subscribe({
      next: () => {
        this.snackBarService.openSnackBar(
          'Chance erfolgreich angenommen',
          'success',
        );
        this.close();
      },
      error: () => {
        this.snackBarService.openSnackBar(
          'Fehler beim Annehmen der Chance',
          'error',
        );
      },
    });
  }

  declineChance(chance: PartnerLead) {
    const formFields: FormField[] = [
      {
        type: ModalFormFieldType.select,
        label: 'Ablehnungsgrund auswählen',
        name: 'decline_reasons',
        validation: { required: true },
        optionlistKeyword: 'decline_reasons',
      },
    ];

    const dialogRef = this.dialog.open(ModalComponent, {
      data: {
        headline: 'Chance ablehnen - Grund auswählen',
        formGroup: CreateFormGroupHelper.createFormGroupForModal(formFields),
        formFields: formFields,
        acceptButtonText: 'Ablehnen',
        declineButtonText: 'Abbrechen',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.partnerLeadService
          .declinePartnerLead(chance, result.decline_reasons)
          .subscribe({
            next: () => {
              this.snackBarService.openSnackBar(
                'Chance erfolgreich abgelehnt',
                'success',
              );
              this.splitViewStateService.triggerTableReload();
              this.close();
            },
            error: () => {
              this.snackBarService.openSnackBar(
                'Fehler beim Ablehnen der Chance',
                'error',
              );
            },
          });
      }
    });
  }

  getPageTitle(chance: PartnerLead): string {
    return (
      '#' +
      chance.lead_number +
      ' - Standort: ' +
      chance.address.postcode +
      ' ' +
      chance.address.city
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
