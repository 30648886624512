<div #container class="split-container" [class.mobile]="isMobile">
  <div
    class="left-panel"
    [class.full-width]="!showRightPanel"
    [style.width]="isMobile ? '100%' : leftPanelWidth + 'px'"
    [style.transform]="
      isMobile && showRightPanel ? 'translateX(-100%)' : 'none'
    "
  >
    <ng-content select="[leftPanel]"></ng-content>
  </div>

  <div
    *ngIf="showRightPanel"
    class="right-panel"
    [class.mobile]="isMobile"
    [style.left]="isMobile ? '0' : leftPanelWidth + 150 + 'px'"
    [style.width]="
      isMobile ? '100%' : 'calc(100% - ' + (leftPanelWidth + 150) + 'px)'
    "
  >
    <div
      *ngIf="!isMobile"
      class="resizer"
      (mousedown)="startResize($event)"
      (touchstart)="startTouchResize($event)"
    ></div>
    <div class="right-content">
      <ng-content select="[rightPanel]"></ng-content>
    </div>
  </div>
</div>
