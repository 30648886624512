import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceFormat',
})
export class PricePipe implements PipeTransform {
  transform(value: any, decimals: number = 2): string {
    if (value === null || value === undefined || isNaN(value)) return '-';

    // Konvertiere den Wert in einen Preis und formatiere ihn im deutschen Format
    const options: Intl.NumberFormatOptions = {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    };
    return new Intl.NumberFormat('de-DE', options).format(value) + ' €';
  }
}
