<app-card>
  <app-card-head pageTitle="Projektdaten"></app-card-head>
  <app-card-body>
    <div class="row pb-3" fxLayout="row" fxLayoutAlign="start end">
      <div
        class="col-md-{{
          getLeadBackendElementFieldSize(leadBackendElement.field_size)
        }} pb-4 px-2"
        *ngFor="
          let leadBackendElement of partnerLeadBackend.lead_backend_elements
        "
      >
        <div>
          <div>
            <label class="form-label p-2 lead-backend-label">{{
              leadBackendElement.title
            }}</label>
            <div>
              <ng-container *ngIf="leadBackendElement.lead_field.type == 1">
                <input
                  type="text"
                  class="form-control"
                  (change)="onSelectedOptionChange(leadBackendElement)"
                  [(ngModel)]="leadBackendElement.selectedOption"
                  [disabled]="inputsDisabled"
                />
              </ng-container>
              <ng-container *ngIf="leadBackendElement.lead_field.type == 5">
                <select
                  class="form-select"
                  (change)="onSelectedOptionChange(leadBackendElement)"
                  [(ngModel)]="leadBackendElement.selectedOption"
                >
                  <option value="">Auswählen</option>
                  <option
                    *ngFor="
                      let option of leadBackendElement.lead_field?.optionlist
                        .options
                    "
                    [value]="option.option_id"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-card-body>
</app-card>
