import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private _production: boolean = false;
  private _apiUrl: string = '';
  private _appVersion: string = '';
  private _socketIoUrl: string = '';
  private _ready$ = new BehaviorSubject<boolean>(false);

  public get ready$(): Observable<boolean> {
    return this._ready$.asObservable();
  }

  constructor() {
    this.init();
  }

  private async init(): Promise<void> {
    try {
      this.socketIoUrl = environment.socketIoUrl;
      this.apiUrl = environment.apiUrl;
      this.production = environment.production;

      // Markieren, dass die Umgebung bereit ist
      this._ready$.next(true);
    } catch (error) {
      console.error(
        'Fehler beim Initialisieren der Umgebungsvariablen:',
        error,
      );
      // Bei einem Fehler trotzdem versuchen, mit Standardwerten fortzufahren
      this._ready$.next(true);
    }
  }

  get apiUrl(): string {
    return this._apiUrl;
  }

  set apiUrl(value: string) {
    this._apiUrl = value;
  }
  get production(): boolean {
    return this._production;
  }

  set production(value: boolean) {
    this._production = value;
  }

  get appVersion(): string {
    return this._appVersion;
  }

  set appVersion(value: string) {
    this._appVersion = value;
  }

  get socketIoUrl(): string {
    return this._socketIoUrl;
  }

  set socketIoUrl(value: string) {
    this._socketIoUrl = value;
  }

  /**
   * Wartet darauf, dass die Umgebungsvariablen bereit sind
   */
  public waitForReady(): Promise<boolean | undefined> {
    return this._ready$
      .pipe(
        filter((ready) => ready),
        take(1),
      )
      .toPromise();
  }
}
