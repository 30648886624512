import { Component } from '@angular/core';
import { NewSelectOption } from '../../../../../models/new-select-option';
import { ConditionGroup } from '../../../../../models/condition-group';

@Component({
  selector: 'lib-condition-groups-example',
  template: ConditionGroupsExample.HTML_CODE,
})
export class ConditionGroupsExample {
  static HTML_CODE = `
    <div class="example-container">
      <lib-condition-groups
        [groups]="groups"
        [propertyOptions]="propertyOptions"
        [operatorOptions]="operatorOptions"
        [displayCopyGroup]="true"
        (groupsChange)="onGroupsChange($event)"
        (groupAdded)="onGroupAdded()"
        (groupRemoved)="onGroupRemoved($event)"
        (groupCopied)="onGroupCopied($event)"
      >
      </lib-condition-groups>

      <div class="mt-4" *ngIf="lastChange">
        <p class="font-semibold">Aktuelle Konfiguration:</p>
        <pre class="bg-gray-100 p-2 rounded">{{ lastChange | json }}</pre>
      </div>
    </div>
  `;

  propertyOptions: NewSelectOption[] = [
    { name: 'Status', value: 'status' },
    { name: 'Lead Typ', value: 'lead_type_id' },
    { name: 'Email Typ', value: 'email_type_id' },
  ];

  operatorOptions: NewSelectOption[] = [
    { name: 'Gleich', value: '==' },
    { name: 'Ungleich', value: '!=' },
  ];

  groups: ConditionGroup[] = [
    {
      name: 'Gruppe 1',
      conditions: [
        {
          property: 'status',
          operator: '==',
          value: 'aktiv',
          disabled: false,
        },
      ],
    },
  ];

  lastChange: ConditionGroup[] | null = null;

  onGroupsChange(groups: ConditionGroup[]) {
    this.lastChange = groups;
  }

  onGroupAdded() {
    console.log('Neue Gruppe hinzugefügt');
  }

  onGroupRemoved(index: number) {
    console.log('Gruppe entfernt:', index);
  }

  onGroupCopied(index: number) {
    console.log('Gruppe kopiert:', index);
  }
}
