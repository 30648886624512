<div [formGroup]="semanticSearchForm">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <mat-radio-group formControlName="searchType">
      <mat-radio-button class="radio-button-element" value="semantic"
        >intelligente Suche</mat-radio-button
      >
      <mat-radio-button class="radio-button-element" value="classic"
        >klassische Suche</mat-radio-button
      >
    </mat-radio-group>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between start">
    <div
      fxLayout="column"
      fxLayoutAlign="space-between end"
      class="element-container"
    >
      <button
        class="btn btn-success btn-success"
        [disabled]="loadingInProgress"
        *ngIf="semanticSearchForm.get('searchType')?.value === 'semantic'"
        (click)="startSearch()"
      >
        Suchen...
      </button>

      <mat-form-field
        class="search-input"
        *ngIf="semanticSearchForm.get('searchType')?.value === 'semantic'"
      >
        <mat-label>intelligente Suche</mat-label>
        <textarea
          (keydown.enter)="startSearch()"
          matInput
          type="text"
          formControlName="userSemanticSearchString"
        ></textarea>
        <button
          *ngIf="
            semanticSearchForm.get('userSemanticSearchString')?.value &&
            !loadingInProgress
          "
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="
            semanticSearchForm.get('userSemanticSearchString')?.setValue('')
          "
        >
          <mat-icon>close</mat-icon>
        </button>

        <div matSuffix *ngIf="loadingInProgress">
          <mat-progress-spinner
            mode="indeterminate"
            diameter="20"
          ></mat-progress-spinner>
        </div>
      </mat-form-field>

      <mat-form-field
        class="search-input"
        *ngIf="semanticSearchForm.get('searchType')?.value === 'classic'"
      >
        <mat-label>Textfilter</mat-label>
        <input matInput type="text" formControlName="filterSearchString" />
        <button
          *ngIf="
            semanticSearchForm.get('filterSearchString')?.value &&
            !loadingInProgress
          "
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearFilter()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-selection-list
        class="selection-list"
        formControlName="selectedOptions"
      >
        <mat-list-option
          *ngFor="let option of optionlistOptions$ | async; trackBy: trackById"
          [value]="option"
        >
          {{ option.name }}
        </mat-list-option>
      </mat-selection-list>
    </div>

    <div
      class="element-container"
      *ngIf="
        semanticSearchForm.get('selectedOptions')?.value &&
        semanticSearchForm.get('selectedOptions')?.value.length > 0
      "
    >
      <div fxLayout="row" fxLayoutAlign="end start" style="margin-bottom: 10px">
        <button
          [disabled]="loadingInProgress"
          (click)="semanticSearchForm.get('selectedOptions')?.setValue(null)"
          class="btn btn-outline-danger me-2"
        >
          alle entfernen
        </button>
      </div>

      <ng-container
        *ngFor="
          let group of semanticSearchForm.get('selectedOptionsGroups')?.value;
          let i = index
        "
      >
        <div *ngIf="group.length > 0" class="border-layout">
          <ng-container *ngIf="group && group.length > 0">
            <button
              [disabled]="loadingInProgress"
              *ngIf="
                semanticSearchForm.get('selectedOptionsGroups')?.value.length >
                1
              "
              mat-icon-button
              (click)="removeGroup(i)"
              class="group-close-button"
            >
              <mat-icon>cancel</mat-icon>
            </button>
            <mat-chip-row class="chip-row" *ngFor="let selected of group">
              {{ selected.name }}
              <button
                [disabled]="loadingInProgress"
                matChipRemove
                (click)="removeOption(selected)"
              >
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
