<span
  [ngClass]="{
    'bg-primary': this.status.option_id === 1,
    'bg-warning': this.status.option_id === 2 || this.status.option_id === 5,
    'bg-success': this.status.option_id === 3,
    'bg-danger': this.status.option_id === 4
  }"
  class="badge fs-7 fw-normal"
>
  {{ this.status.name }}
</span>
