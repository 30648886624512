// modal.component.ts
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormField, FormFieldOption, ModalInputData } from '../../models/modal';
import { FormGroup } from '@angular/forms';
import { OptionlistOption } from '../../models/optionlist/optionlist-option';
import { Option } from '../../models/option';
import { OptionlistsService } from '../../services/option-lists/optionlists.service';
import { firstValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StorageService } from '../../services/storage.service';

/**
 * Anleitung zur Verwendung des zentralen Modals:
 *
 * Folgender Code kann in der Komponente verwendet werden, um das Modal zu öffnen. Die einzelnen Felder sind in modal.ts definiert. Dort ist eine Erklärung pro Feld enthalten.
 * Es ist auch möglich, simple Abhängigkeiten in dem Formular zu definieren (FormFieldCondition). Sehr komplexe Modals können aber zur Zeit noch nicht abgebildet werden.
 *
 * Der nachfolgende Code kann komplett kopiert werden, um ein Beispiel-Modal zu erstellen:

 const formFields: FormField[] = [
 {
 type: ModalFormFieldType.checkbox,
 label: 'Beispiel Checkbox',
 name: 'example-checkbox',
 validation: { defaultValue: true },
 },
 {
 type: ModalFormFieldType.radio,
 label: 'Beispiel Radio-Gruppe',
 name: 'example-radio',
 validation: { defaultValue: 2 },
 options: [
 { label: 'Option 1', value: 1 },
 { label: 'Option 2', value: 2 },
 { label: 'Option 3', value: 3 },
 ],
 },
 {
 type: ModalFormFieldType.text,
 label: 'Beispiel Textfeld',
 name: 'example-text',
 validation: { required: true, defaultValue: 'Ich bin Pflicht :-)' },
 },
 {
 type: ModalFormFieldType.textarea,
 label: 'Beispiel Textarea',
 name: 'example-textarea',
 validation: { required: true },
 },
 {
 type: ModalFormFieldType.date,
 label: 'Beispiel Datum',
 name: 'example-date',
 validation: { defaultValue: new Date() },
 },
 {
 type: ModalFormFieldType.select,
 label: 'Beispiel Select Optionsliste',
 name: 'example-select-optionlist',
 optionlistKeyword: 'cancel_reason',
 },
 {
 type: ModalFormFieldType.select,
 label: 'Beispiel Select',
 name: 'example-select',
 options: [
 { label: 'Option 1', value: 'option1' },
 { label: 'Option 2', value: 'option2' },
 { label: 'Option 3', value: 'option3' },
 ],
 },
 {
 type: ModalFormFieldType.text,
 label: 'Mit bestimmter Bedingung (Beispiel Select = option2)',
 name: 'example-condition',
 condition: {
 conditions: [{ dependsOn: 'example-select', value: 'option2' }],
 },
 },
 {
 type: ModalFormFieldType.text,
 label: 'Mit AND Bedingung (Beispiel Select = option2 UND Mit bestimmter Bedingung)',
 name: 'additional-field',
 condition: {
 logicalOperator: 'AND',
 conditions: [
 { dependsOn: 'example-select', value: 'option2' },
 { dependsOn: 'example-condition', hasValue: true },
 ],
 },
 },
 {
 type: ModalFormFieldType.text,
 label: 'Mit OR Bedingung (Beispiel Select = option1 OR Checkbox)',
 name: 'example-condition-or',
 condition: {
 logicalOperator: 'OR',
 conditions: [
 { dependsOn: 'example-select', value: 'option1' },
 { dependsOn: 'example-checkbox', hasValue: true },
 ],
 },
 },
 ];

 const inputData: ModalInputData = {
 headline: 'Beispiel Überschrift',
 formGroup: CreateFormGroupHelper.createFormGroupForModal(formFields),
 formFields: formFields,
 acceptButtonText: 'Senden',
 declineButtonText: 'Abbrechen',
 };

 this.dialog
 .open(ModalComponent, {
 disableClose: true,
 data: inputData,
 })
 .afterClosed()
 .subscribe((modalResponse) => {
 if (!modalResponse) return;
 console.log(modalResponse);
 });
 *
 */
@Component({
  selector: 'lib-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnDestroy, OnInit {
  headlineText: string;
  bodyText: string;
  acceptButtonText: string;
  declineButtonText: string;
  showDeclineButton: boolean = true;
  modalForm: FormGroup;
  formFields: FormField[];
  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    public storageService: StorageService,
    public optionlistService: OptionlistsService,
    @Inject(MAT_DIALOG_DATA) public inputData: ModalInputData,
  ) {
    this.showDeclineButton = inputData.showDeclineButton ?? true;
    this.headlineText = inputData.headline || 'Bestätigung';
    this.bodyText =
      inputData.body?.replace(/\n/g, '<br>') || 'Möchten Sie fortfahren?';
    this.acceptButtonText = inputData.acceptButtonText || 'Speichern';
    this.declineButtonText = inputData.declineButtonText || 'Abbrechen';
    this.modalForm = inputData.formGroup || new FormGroup({});
    this.formFields = inputData.formFields || [];

    this.loadOptions();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.initializeValueChanges();
  }

  initializeValueChanges() {
    // Jedes Feld, das Bedingungen hat, wird überwacht
    this.formFields.forEach((field) => {
      // Mehrere Bedingungen können vorhanden sein
      field.condition?.conditions?.forEach((cond) => {
        const control = this.modalForm.get(cond.dependsOn);
        if (control) {
          control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.checkFieldVisibility(field);
          });
        }
      });
    });
  }

  // mit dieser Funktion werden Felder ausgeblendet, die nicht mehr sichtbar sind.
  checkFieldVisibility(field: FormField) {
    if (!field.condition) {
      return;
    }

    const conditions = field.condition.conditions || [];
    let visible = field.condition.logicalOperator === 'AND';

    conditions.forEach((condition) => {
      const dependentControlValue = this.modalForm.get(
        condition.dependsOn,
      )?.value;
      let conditionMet = false;

      if (condition.value) {
        conditionMet = dependentControlValue === condition.value;
      } else if (condition.hasValue) {
        conditionMet = !!dependentControlValue;
      }

      if (field.condition?.logicalOperator === 'AND') {
        visible = visible && conditionMet;
      } else if (field.condition?.logicalOperator === 'OR') {
        visible = visible || conditionMet;
      }
    });

    // Zurücksetzen des Wertes, wenn das Feld nicht sichtbar sein sollte
    if (!visible && this.modalForm.get(field.name)) {
      this.modalForm.get(field.name)?.reset();
    }
  }

  async loadOptions() {
    for (const field of this.formFields) {
      if (field.optionlistKeyword) {
        const optionlistOptions = await this.getOptionsByKeyword(
          field.optionlistKeyword,
        );

        if (!field.options) {
          field.options = [];
        }

        for (const optionlistOption of optionlistOptions) {
          const option: FormFieldOption = {
            label: optionlistOption.name,
            value: optionlistOption.value,
          };
          field.options.push(option);
        }
      }
    }
  }

  async getOptionsByKeyword(keyword: string) {
    let options = this.storageService.getFromServiceStorage(
      'select_options_' + keyword,
    );

    if (!options) {
      try {
        const optionlistOptions: OptionlistOption[] = await firstValueFrom(
          this.optionlistService.optionlistByKeyword(keyword),
        );

        if (optionlistOptions.length > 0) {
          options = optionlistOptions.map(
            (optionlistOption) =>
              new Option({
                value: optionlistOption.option_id,
                name: optionlistOption.name,
              }),
          );
          this.storageService.setInServiceStorage(
            'select_options_' + keyword,
            options,
          );
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Options: ', error);
        return [];
      }
    }
    return options;
  }

  confirm() {
    // Markiert nur sichtbare Felder als touched
    this.formFields.forEach((field) => {
      const control = this.modalForm.get(field.name);
      if (control && this.isVisible(field)) {
        control.markAsTouched();
      }
    });

    // Prüft nur die sichtbaren, required Felder
    const isValid = this.formFields.every((field) => {
      const control = this.modalForm.get(field.name);
      if (!this.isVisible(field)) {
        return true; // Versteckte Felder immer valid
      }
      if (field.validation?.required && control) {
        return control.valid;
      }
      return true; // Felder ohne required sind immer valid
    });

    if (isValid) {
      this.dialogRef.close(this.modalForm.value);
    }
  }

  close() {
    this.dialogRef.close();
  }

  getErrorMessage(field: FormField): string {
    if (this.modalForm.get(field.name)?.hasError('required')) {
      return 'Dieses Feld ist erforderlich.';
    }
    return '';
  }

  isVisible(field: FormField): boolean {
    if (!field.condition) {
      return true;
    }
    const { conditions, logicalOperator } = field.condition;
    let result = logicalOperator === 'AND';

    conditions?.forEach((condition) => {
      const controlValue = this.modalForm.get(condition.dependsOn)?.value;
      let conditionMet = false;
      if (condition.value) {
        conditionMet = controlValue === condition.value;
      } else if (condition.hasValue) {
        conditionMet = !!controlValue;
      }

      if (logicalOperator === 'AND') {
        result = result && conditionMet;
      } else {
        result = result || conditionMet;
      }
    });

    return result;
  }
}
