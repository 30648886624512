import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FileUpload } from '../../../models/fileUpload';
import { Document } from '../../../models/document/document';
import { EnvironmentService } from '../../../services/environment.service';

@Component({
  selector: 'lib-feed-document',
  templateUrl: './feed-document.component.html',
  styleUrls: ['./feed-document.component.scss'],
})
export class FeedDocumentComponent implements OnInit, OnDestroy {
  @Input() document: FileUpload | null = null;

  private destroy$ = new Subject<void>();

  constructor(private environmentService: EnvironmentService) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFileIcon(): string {
    if (!this.document) return '<i class="fas fa-file fa-fw"></i>';

    switch (this.document.file_suffix.toLowerCase()) {
      case 'pdf':
        return '<i class="fas fa-file-pdf fa-fw"></i>';
      case 'doc':
      case 'docx':
        return '<i class="fas fa-file-word fa-fw"></i>';
      case 'xls':
      case 'xlsx':
        return '<i class="fas fa-file-excel fa-fw"></i>';
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return '<i class="fas fa-file-image fa-fw"></i>';
      default:
        return '<i class="fas fa-file fa-fw"></i>';
    }
  }

  formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(1)) + ' ' + sizes[i];
  }

  editFile(leadDocument: FileUpload) {
    window.open(this.environmentService.apiUrl + leadDocument.file_access_link);
  }
}
