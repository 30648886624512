<div class="email-container">
  <div class="email-subject">{{ email.subject }}</div>
  <div class="email-subject" *ngIf="feed?.partner_lead?.partner?.name">
    Partner: {{ feed?.partner_lead?.partner?.name }}
  </div>

  <div class="email-info">
    <p><strong>Empfänger:</strong> {{ getRecipients('to') }}</p>
    <p *ngIf="getRecipients('cc')">
      <strong>CC:</strong> {{ getRecipients('cc') }}
    </p>
    <p>
      <strong>Gesendet am:</strong>
      <span>
        {{
          email.created_at
            | dynamicPipe : { name: 'date', args: ['dd.MM.yyyy HH:mm'] }
        }}
        Uhr</span
      >
    </p>
    <p>
      <strong>Geöffnet:</strong>
      <span *ngIf="email.email_opened_at">
        {{ email.email_opened_at | customDateTime }}</span
      >
      <span *ngIf="!email.email_opened_at"><em> noch nicht geöffnet</em></span>
    </p>
    <p><strong>Email Vorlage:</strong> {{ emailType$ | async }}</p>
    <p>
      <strong>Antwort:</strong>
      <span
        *ngIf="email.response_code !== '200'"
        style="margin-left: 5px"
        class="badge fs-7 fw-normal bg-danger"
      >
        Fehler beim Mailversand
      </span>

      <span *ngIf="email.response_code === '200'" style="margin-left: 5px">
        Mailversand erfolgreich
      </span>
    </p>
    <p *ngIf="email.source"><strong>Quelle:</strong> {{ email.source }}</p>
  </div>
</div>
