<div class="modal" *ngIf="show$ | async" tabindex="-1">
  <div class="modal-content col-3">
    <div class="modal-header">
      <h5 class="modal-title" id="staticBackdropLabel">Eintrag löschen?</h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        (click)="cancel()"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <p>Möchten Sie diesen Eintrag wirklich löschen?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="cancel()">
        Abbrechen
      </button>
      <button
        type="button"
        class="btn btn-danger"
        data-bs-dismiss="modal"
        (click)="confirm()"
      >
        Löschen
      </button>
    </div>
  </div>
</div>
