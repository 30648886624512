import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { PartnerUser } from '../models/partners/partner-user';
import { User } from '../models/user';
import { EnvironmentService } from './environment.service';
import { Application } from '../enum-collection';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userSubject = new BehaviorSubject<User | null>(null);
  private partnerUserSubject = new BehaviorSubject<PartnerUser | null>(null);
  private loggedIn = new BehaviorSubject<boolean>(false);

  partnerUser$ = this.partnerUserSubject.asObservable();
  isLoggedIn$ = this.loggedIn.asObservable();

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    @Inject(StorageService) private storageService: StorageService,
  ) {}

  initializeAuth(application: Application): void {
    if (this.storageService.hasValidToken()) {
      this.loggedIn.next(true);
      const user = this.storageService.getUser(application);
      if (user) {
        if (application === Application.Leadmanager) {
          this.userSubject.next(user as User);
        } else {
          this.partnerUserSubject.next(user as PartnerUser);
        }
      } else {
        this.loadUserData(application);
      }
    }
  }

  get isLoggedIn(): boolean {
    return this.storageService.hasValidToken();
  }

  private loadUserData(application: Application) {
    if (application === Application.Leadmanager) {
      this.getCurrentUser().subscribe();
    } else if (application === Application.Salesrunner) {
      this.getCurrentPartnerUser().subscribe();
    }
  }

  getCurrentUser(): Observable<User | null> {
    return this.getOrFetchUser(this.userSubject, 'me');
  }

  getCurrentPartnerUser(forcedRefresh = false): Observable<PartnerUser | null> {
    return this.getOrFetchUser(
      this.partnerUserSubject,
      'me?guard=partner',
      forcedRefresh,
    );
  }

  private getOrFetchUser<T>(
    subject: BehaviorSubject<T | null>,
    endpoint: string,
    forcedRefresh = false,
  ): Observable<T | null> {
    const currentUser = subject.getValue();
    if (currentUser && !forcedRefresh) {
      return of(currentUser);
    } else {
      return this.fetchUserFromAPI<T>(endpoint, subject);
    }
  }

  private fetchUserFromAPI<T>(
    endpoint: string,
    subject: BehaviorSubject<T | null>,
  ): Observable<T | null> {
    const token = this.storageService.getToken();
    if (!token) {
      console.error('No token available');
      this.loggedIn.next(false);
      return of(null);
    }

    return this.http
      .get<T>(`${this.environmentService.apiUrl}${endpoint}`)
      .pipe(
        tap((userData: T) => {
          subject.next(userData);
          this.loggedIn.next(true);
        }),
        catchError((error) => {
          console.error('Error fetching user data:', error);
          this.loggedIn.next(false);
          this.storageService.removeToken();
          return of(null);
        }),
      );
  }

  login(email: string, password: string): Observable<any> {
    return this.http
      .post<any>(`${this.environmentService.apiUrl}login`, { email, password })
      .pipe(
        tap((data: any) =>
          this.handleSuccessfulLogin(data, Application.Leadmanager),
        ),
      );
  }

  loginSalesrunner(
    email: string,
    password: string,
    resolution: string,
  ): Observable<any> {
    return this.http
      .post<any>(`${this.environmentService.apiUrl}partner_login`, {
        email,
        password,
        resolution,
      })
      .pipe(
        tap((data: any) =>
          this.handleSuccessfulLogin(data, Application.Salesrunner),
        ),
      );
  }

  private handleSuccessfulLogin(data: any, application: Application) {
    this.storageService.setToken(data.access_token, application);
    this.loggedIn.next(true);

    if (application === Application.Leadmanager) {
      // Da der User beim Admin Login nicht mit zurückgegeben wird, holen wir ihn uns hier
      this.http
        .get<User>(`${this.environmentService.apiUrl}me`)
        .subscribe((user: User) => {
          this.userSubject.next(data.user_info);
          this.storageService.setUser(user, Application.Leadmanager);
        });
    } else {
      this.partnerUserSubject.next(data.user_info);
      this.storageService.setUser(data.user_info, Application.Salesrunner);
    }
  }

  logout(): Observable<any> {
    return this.performLogout();
  }

  private performLogout(): Observable<any> {
    const token = this.storageService.getToken();
    if (!token) {
      this.loggedIn.next(false);
      this.userSubject.next(null);
      this.partnerUserSubject.next(null);
      return of(null);
    }
    return this.http.post(`${this.environmentService.apiUrl}logout`, {}).pipe(
      tap(() => {
        this.loggedIn.next(false);
        this.userSubject.next(null);
        this.partnerUserSubject.next(null);
        this.storageService.removeToken();
        this.storageService.removeUser();
      }),
    );
  }

  loginAsPartnerUser(
    partnerUserId: number,
    redirectUrl = '/salesrunner/#/leads-new',
    openInNewTab = false,
  ) {
    const body = { id: partnerUserId };
    this.http
      .post(this.environmentService.apiUrl + 'login_as_partner_user', body)
      .subscribe({
        next: (response: any) => {
          this.handleSuccessfulLogin(response, Application.Salesrunner);

          let url = window.location.origin;

          if (!this.environmentService.production) {
            if (window.location.origin.includes('staging')) {
              url = 'http://cloud-staging.lindenfield.de';
            } else {
              url = 'http://localhost:4201/dist';
            }
          }

          const fullUrl = url + redirectUrl;

          if (openInNewTab) {
            window.open(fullUrl, '_blank');
          } else {
            window.location.href = fullUrl;
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  resetPassword(
    token: string,
    email: string,
    password: string,
    passwordConfirm: string,
  ) {
    return this.http.post(this.environmentService.apiUrl + 'reset_password', {
      token: token,
      email: email,
      password: password,
      password_confirmation: passwordConfirm,
    });
  }
}
