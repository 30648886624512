export class LeadBackend {
  id: number = 0;
  active: number = 1;
  lead_type_id: number = 0;
  lead_backend_elements: any = [];
  targeting_field_importance: any = [];
  lead_type: any = 0;
  constructor(lead_backend: LeadBackend | {}) {
    Object.assign(this, lead_backend);

    if (this.lead_backend_elements == null) {
      Object.assign(this, {
        ...lead_backend,
        lead_backend_elements: this.lead_backend_elements || {},
      });
    }
  }
}
