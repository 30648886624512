import { LeadType } from './lead-type';

export class Price {
  id: number = 0;
  lead_type_id: number | null = null;
  partner_id: number | null = null;
  lead_id: number | null = null;
  partner_lead_id: number | null = null;
  type: string = 'pay_per_sale_revenue_based';
  partner_price_source: string = '';
  status: any | null = null;
  conditions: any = [];
  price_unit: string = '';
  price: number = 0;
  additional_commissions: any = [];
  prioritize_targeting: number = 0;
  prioritize_partner: number = 0;
  custom_price: number = 0;
  easybill_article_number: number = 0;
  targeting_id: number | null = null;
  lead_type: LeadType = new LeadType({});

  constructor(price: Price | {}) {
    Object.assign(this, price);
  }
}
