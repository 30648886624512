import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTablesParameters } from '../interfaces/optionen.interface';
import { map } from 'rxjs/operators';
import { AccountingAccount } from '../models/accounting_account';
import { HttpHandlerService } from './http-handler.service';

@Injectable({
  providedIn: 'root',
})
export class AccountingAccountService {
  endpoint: string = 'accounting_accounts/';

  constructor(private httpHandler: HttpHandlerService) {}

  datatable(dataTablesParams: DataTablesParameters): Observable<any> {
    return this.httpHandler.datatable(this.endpoint, dataTablesParams).pipe(
      map((response) => {
        return {
          recordsFiltered: response.recordsFiltered,
          recordsTotal: response.recordsTotal,
          data: response.data.map(
            (item: AccountingAccount) => new AccountingAccount(item),
          ),
        };
      }),
    );
  }

  list(httpParams: any = {}): Observable<any> {
    return this.httpHandler
      .list(this.endpoint, httpParams)
      .pipe(map((response) => response.data));
  }

  getList(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  show(id: number): Observable<any> {
    return this.httpHandler.show(this.endpoint, id);
  }

  create(accountingAccount: AccountingAccount): Observable<any> {
    return this.httpHandler.create(this.endpoint, accountingAccount);
  }

  update(accountingAccount: AccountingAccount): Observable<any> {
    return this.httpHandler.update(this.endpoint, accountingAccount);
  }

  delete(id: number): Observable<any> {
    return this.httpHandler.delete(this.endpoint, id);
  }
}
