import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalFormFieldType } from '../../../../../enum-collection';
import { CreateFormGroupHelper } from '../../../../../helpers/create-form-group-helper';
import { ModalComponent } from '../../../../modal/modal.component';

@Component({
  selector: 'lib-basic-modal-example',
  template: BasicModalExample.HTML_CODE,
})
export class BasicModalExample {
  static HTML_CODE = `
    <button class="btn btn-primary" (click)="openModal()">Modal öffnen</button>
  `;

  constructor(private dialog: MatDialog) {}

  openModal() {
    const formFields = [
      {
        type: ModalFormFieldType.text,
        label: 'Name',
        name: 'name',
        validation: { required: true },
      },
      {
        type: ModalFormFieldType.textarea,
        label: 'Beschreibung',
        name: 'description',
      },
    ];

    const inputData = {
      headline: 'Neuen Eintrag erstellen',
      formGroup: CreateFormGroupHelper.createFormGroupForModal(formFields),
      formFields: formFields,
      acceptButtonText: 'Speichern',
      declineButtonText: 'Abbrechen',
    };

    this.dialog.open(ModalComponent, {
      data: inputData,
      disableClose: true,
    });
  }
}
