import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApplicationHelper } from '../../helpers/application-helper';
import { Application } from '../../enum-collection';
import { FilterOption } from '../../models/feed';
import { FeedItemService } from '../../services/feed-item.service';

@Component({
  selector: 'lib-feed-filter-bar',
  templateUrl: './feed-filter-bar.component.html',
  styleUrls: ['./feed-filter-bar.component.scss'],
})
export class FeedFilterBarComponent {
  @Input() currentFilter?: string;
  @Output() filterChanged = new EventEmitter<string | undefined>();

  constructor(private feedItemService: FeedItemService) {}

  get displayedFilters() {
    return this.feedItemService.getDisplayedFilters();
  }

  get isLeadmanager() {
    return ApplicationHelper.applicationName === Application.Leadmanager;
  }

  toggleFilter(filter: FilterOption): void {
    this.filterChanged.emit(
      this.currentFilter === filter.type ? undefined : filter.type,
    );
  }
}
