<div fxLayout="row" fxLayoutAlign="start center">
  <div class="label-container" *ngIf="users && users.length > 0">
    <!--    <span class="label">Telefon:</span>-->
    <mat-chip-set>
      <ng-container *ngFor="let user of users; trackBy: trackByUserId">
        <mat-chip
          [ngClass]="getUserChipColor(user)"
          class="chip"
          (click)="navigateToLead(user.lead)"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="{{ generateHoverDetails(user.lead) }}"
        >
          <mat-icon matChipAvatar *ngIf="user?.lead?.id"> person</mat-icon>
          <mat-icon matChipAvatar *ngIf="user.direction">
            {{ user.direction === 'in' ? 'call_received' : 'call_made' }}
          </mat-icon>
          <mat-icon matChipAvatar *ngIf="user.leadmanager_status !== 'online'">
            free_breakfast
          </mat-icon>
          <a tabindex="0">
            {{
              user.first_name +
                (user.status === 'available' && user.last_call_since
                  ? ' (vor ' + user.last_call_since + ') '
                  : '') +
                (user.duration_format &&
                (user.status === 'editing' ||
                  user.status === 'active_call' ||
                  user.status === 'ringing')
                  ? ' (' + user.duration_format + ') '
                  : '')
            }}
          </a>
        </mat-chip>
      </ng-container>
    </mat-chip-set>
  </div>

  <div class="label-container" *ngIf="hasCallsWithLeads">
    <span class="label">Eingehend:</span>
    <mat-chip-set>
      <ng-container *ngFor="let call of incomingCalls">
        <mat-chip
          class="color-new-call chip"
          (click)="navigateToLead(call.lead)"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="{{ generateHoverDetails(call.lead) }}"
        >
          <mat-icon matChipAvatar *ngIf="call?.lead?.id"> person</mat-icon>
          <mat-icon matChipAvatar>
            {{ call.direction === 'in' ? 'call_received' : 'call_made' }}
          </mat-icon>

          <ng-container *ngIf="call.lead?.address">
            <a tabindex="0" class="truncate">
              {{
                call.lead.address.company_name ||
                  call.lead.address.first_name +
                    ' ' +
                    call.lead.address.last_name
              }}
              - {{ call.from }}
            </a>
          </ng-container>

          <a *ngIf="!call.lead?.address">{{ call.from }}</a>
        </mat-chip>
      </ng-container>
    </mat-chip-set>
  </div>
</div>
