import { Component } from '@angular/core';
import { NewSelectOption } from '../../../../../models/new-select-option';

@Component({
  selector: 'lib-basic-select-example',
  template: BasicSelectExample.HTML_CODE,
})
export class BasicSelectExample {
  static HTML_CODE = `
    <div class="example-container">
      <lib-select
        [options]="options"
        placeholder="Wähle eine Option"
        (valueChanged)="onValueChanged($event)"
      >
      </lib-select>

      <div class="mt-4" *ngIf="selectedOption">
        <p>Ausgewählt: {{ selectedOption.value }}</p>
      </div>
    </div>
  `;

  options: NewSelectOption[] = [
    { name: 'Option 1', value: '1' },
    { name: 'Option 2', value: '2' },
    { name: 'Option 3', value: '3' },
  ];

  selectedOption: NewSelectOption | null = null;

  onValueChanged(event: any): void {
    console.log('Selection changed:', event);
  }
}
