import { Component } from '@angular/core';

@Component({
  selector: 'lib-basic-reaction-example',
  template: BasicReactionExample.HTML_CODE,
})
export class BasicReactionExample {
  static HTML_CODE = `
    <div class="example-container">
      <div class="mb-4">
        <p class="text-lg font-medium mb-2">Beispiel Kommentar</p>
        <p class="text-gray-600 mb-4">
          Dies ist ein Beispielkommentar, auf den Benutzer reagieren können.
        </p>

        <lib-reaction
          [relatableId]="123"
          relatableType="comment"
          (reactionChanged)="onReactionChanged($event)"
        >
        </lib-reaction>
      </div>

      <div class="mt-4 p-4 bg-gray-100 rounded">
        <p class="text-sm text-gray-600">Aktuelle Reaktionen:</p>
        <pre class="mt-2 text-sm">{{ currentReactions | json }}</pre>
      </div>
    </div>
  `;

  currentReactions: any[] = [];

  onReactionChanged(event: any): void {
    this.currentReactions = event;
    console.log('Reaktionen geändert:', event);
  }
}
