import { Optionlist } from './optionlist';

export class OptionlistOption {
  id: number = 0;
  name: string = '';
  option_id: number = 0;
  optionlist_id: number = 0;
  disabled: number = 0;
  order: number = 0;
  optionlist_option_lead_detail_count: number = 0;
  description: string = '';
  pinecone_score: number = 0;
  optionlist = new Optionlist({});

  constructor(option_list_option: OptionlistOption | {}) {
    Object.assign(this, option_list_option);
  }
}
