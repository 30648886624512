<h1 mat-dialog-title>Targeting bearbeiten</h1>
<mat-dialog-content>
  <form [formGroup]="targetingDetailForm" (ngSubmit)="save()">
    <p *ngIf="!partnerUserIsAdmin" style="color: darkred">
      Sie besitzen nicht die ausreichenden Berechtigungen, um das Targeting zu
      verändern. Bitte wenden Sie sich an Ihre*n Vorgesetzte*n.
    </p>

    <p
      *ngIf="
        targeting.unselected_inspector_types &&
        targeting.unselected_inspector_types.length > 0 &&
        targetingDetail.include === 1
      "
    >
      Für dieses Targeting ist eine Potenzialsteigerung verfügbar. In der im
      Targeting hinterlegten Region können Sie noch Leads in den folgenden
      Fachgebieten erhalten. Wählen Sie die zusätzlichen Fachgebiete aus und
      bestätigen Ihre Eingabe mit Klick auf Speichern.
    </p>

    <p>Wählen Sie die gewünschte Fachgebiete in dieser Region aus.</p>

    <div style="overflow-y: auto; max-height: 400px">
      <mat-selection-list formControlName="targetingDetailList">
        <mat-list-option
          *ngFor="let option of inspectorTypeOptionlist"
          [value]="option.name"
          >{{ option.name }}</mat-list-option
        >
      </mat-selection-list>
    </div>

    <lib-partner-budget
      [targetingId]="targeting.id"
      [targetingBudgets]="targeting.targeting_budgets"
      [disableForm]="!partnerUserIsAdmin"
      (onSave)="save()"
    ></lib-partner-budget>

    <em style="margin-top: 20px; margin-bottom: 20px">
      Bitte beachten Sie, dass durch den Versand von Leads Kosten entstehen.
      Sollten Sie Fragen haben, dann beraten wir Sie gerne. Kontaktieren Sie uns
      bitte unter 0221/96880420.
    </em>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>Abbrechen</button>
  <button
    (click)="save()"
    class="btn btn-success btn-success"
    style="margin-left: 10px"
    [disabled]="!(targetingDetailForm.valid && partnerUserIsAdmin)"
  >
    Speichern & anwenden
  </button>
</mat-dialog-actions>
