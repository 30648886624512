<app-card>
  <app-card-head [pageTitle]="'Rechnungen'">
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <button
        [disabled]="
          !partnerBillForm.valid ||
          partnerBill.bill_status === BillStatus.Abgerechnet
        "
        (click)="onSubmit(true)"
        class="btn btn-warning text-white mx-2"
      >
        Speichern & weitere Rechnung erfassen
      </button>

      <button
        [disabled]="
          !partnerBillForm.valid ||
          partnerBill.bill_status === BillStatus.Abgerechnet
        "
        (click)="onSubmit()"
        class="btn btn-success btn-success"
      >
        Speichern
      </button>
    </div>
  </app-card-head>
  <app-card-body>
    <div class="row">
      <div class="mb-0">
        <label class="form-label fw-bold">Kunde: </label>
        <span>
          {{
            partnerLead.address.company_name
              ? partnerLead.address.company_name
              : partnerLead.address.salutation
          }}
          |
          {{ partnerLead.address.first_name }}
          {{ partnerLead.address.last_name }}
        </span>
      </div>
      <div class="mb-0" *ngIf="partnerLead.offer_number">
        <label class="form-label fw-bold">Referenznummer: </label>
        <span>
          {{ partnerLead.offer_number }}
        </span>
      </div>
      <div class="mb-3">
        <label class="form-label fw-bold">Anschrift: </label>
        <span>
          {{ partnerLead.address.street }},
          {{ partnerLead.address.postcode }}
          {{ partnerLead.address.city }}
        </span>
      </div>
    </div>

    <form
      [formGroup]="partnerBillForm"
      (keydown.enter)="partnerBillForm.valid && onSubmit()"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="space-between stretch"
        fxLayoutGap="20px"
      >
        <div class="custom-mat-field">
          <mat-form-field fxFill>
            <mat-label>Status der Rechnung</mat-label>
            <mat-select formControlName="billStatus">
              <mat-option
                *ngFor="let status of billStatusOptionlist"
                [value]="status.option_id"
              >
                {{ status.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="custom-mat-field">
          <mat-form-field fxFill>
            <mat-label>Rechnungsdatum</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="billDate"
            />
            <mat-hint>DD.MM.YYYY</mat-hint>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="custom-mat-field">
          <mat-form-field fxFill>
            <mat-label>Nettorechnungssumme</mat-label>
            <input
              (change)="onBillSumChange()"
              matInput
              formControlName="billSum"
            />
            <mat-error
              *ngIf="partnerBillForm.get('billSum')?.hasError('required')"
            >
              Nettorechnungssumme ist <strong>erforderlich</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="custom-mat-field">
          <mat-form-field fxFill>
            <mat-label>Rechnungsnummer</mat-label>
            <input matInput formControlName="billNumber" />
          </mat-form-field>
        </div>
      </div>

      <div style="margin-top: 20px" fxLayout="row" fxLayoutGap="20px">
        <div class="custom-mat-field comment-textarea">
          <mat-form-field fxFill>
            <mat-label>Kommentar</mat-label>
            <textarea
              style="height: 100%"
              matInput
              formControlName="billComment"
              (keydown.enter)="$event.stopPropagation()"
            ></textarea>
          </mat-form-field>
        </div>

        <div class="custom-mat-field">
          <div>
            <em
              style="color: darkred"
              *ngIf="
                partnerLead?.partner?.required_invoice &&
                !partnerBillForm.get('billsUpload')?.valid
              "
              >* bitte laden Sie eine Rechnung hoch.</em
            >
            <lib-file-upload
              [existingFiles]="partnerBill.documents"
              (fileListEmitter)="onFileListChanged($event)"
              (fileDeleted)="onFileDeleted($event)"
              [disabled]="partnerBill.bill_status === BillStatus.Abgerechnet"
            ></lib-file-upload>
          </div>
        </div>

        <div
          style="width: 200%"
          *ngIf="billPositions.controls && billPositions.controls.length > 0"
        >
          <table formArrayName="billPositions">
            <thead>
              <tr>
                <th style="width: 500px">Rechnungspositionen</th>
                <th style="width: 180px">Provision (in %)</th>
                <th style="width: 180px">Provision (in €)</th>
                <th style="width: 300px">Betrag auf der Rechnung</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let position of billPositions.controls; let i = index"
                [formGroupName]="i"
              >
                <td>
                  <p class="mb-0">
                    {{ position.get('name')?.value }}
                  </p>
                </td>
                <td>
                  <mat-form-field>
                    <input matInput formControlName="commissionRate" />
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field>
                    <input matInput formControlName="commissionRateEuro" />
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field (change)="onAmountChange()">
                    <input matInput formControlName="amount" />
                  </mat-form-field>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
  </app-card-body>
</app-card>
<app-partner-bills
  [partnerLeadId]="partnerLead.id"
  [pageTitelChange]="'Umsätze'"
></app-partner-bills>
