import { OptionlistOption } from './optionlist/optionlist-option';
import { PartnerLead } from './partner-lead';

export class LeadResponse {
  id: number = 0;
  lead_id: number = 0;
  response: string = '';
  response_at: string = '';
  lead_response_success_partner_lead_id: number = 0;
  lead_response_option: OptionlistOption = new OptionlistOption({});
  lead_response_success_partner_lead: PartnerLead = new PartnerLead({});
}
