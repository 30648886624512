<div class="reaction-container">
  <ng-container *ngIf="!currentUserReaction; else userReactionTemplate">
    <button class="add-reaction" (click)="toggleEmojiPicker($event)">
      <svg width="18" height="18" viewBox="0 0 24 24">
        <path
          class="add-reaction-button"
          d="M17.5,12C20.5376,12,23,14.4624,23,17.5S20.5376,23,17.5,23S12,20.5376,12,17.5S14.4624,12,17.5,12 M12,2C17.5237,2,22.0016,6.4768,22.0016,12.0005C22.0016,12.2637,21.9914,12.5246,21.9714,12.7827C20.8065,11.6777,19.2324,11,17.5,11C14.2819,11,11.61,13.3387,11.0911,16.4091C10.0525,16.1974,9.1231,15.6232,8.4617,14.7838C8.2054,14.4585,7.7338,14.4025,7.4085,14.6589C7.0831,14.9152,7.0272,15.3868,7.2835,15.7122C8.2113,16.8897,9.5381,17.6767,11.0136,17.9211C11.113,19.4857,11.7676,20.9012,12.7825,21.9716C12.5248,21.9919,12.2636,22.0021,12,22.0021C6.4763,22.0021,1.9984,17.5242,1.9984,12.0005C1.9984,6.4768,6.4763,2,12,2 M17.5,14L17.4101,14.0073C17.206,14.0443,17.0451,14.2053,17.0081,14.4094L17,14.4992L16.9996,16.9992L14.4976,17L14.4078,17.0081C14.2037,17.0451,14.0427,17.206,14.0057,17.4101L13.9976,17.5L14.0057,17.5899C14.0427,17.794,14.2037,17.9549,14.4078,17.9919L14.4976,18L17.0006,17.9992L17.0011,20.5035L17.0092,20.5934C17.0462,20.7975,17.2071,20.9584,17.4112,20.9954L17.5011,21.0035L17.591,20.9954C17.7951,20.9584,17.956,20.7975,17.9931,20.5934L18.0011,20.5035L18.0006,17.9992L20.5046,18L20.5944,17.9919C20.7985,17.9549,20.9595,17.794,20.9965,17.5899L21.0046,17.5L20.9965,17.4101C20.9595,17.206,20.7985,17.0451,20.5944,17.0081L20.5046,17L17.9996,16.9992L18,14.4992L17.9919,14.4094C17.9549,14.2053,17.794,14.0443,17.5899,14.0073L17.5,14 M9.0004,8.7512C8.3105,8.7512,7.7512,9.3105,7.7512,10.0004C7.7512,10.6904,8.3105,11.2497,9.0004,11.2497C9.6904,11.2497,10.2497,10.6904,10.2497,10.0004C10.2497,9.3105,9.6904,8.7512,9.0004,8.7512 M15.0004,8.7512C14.3105,8.7512,13.7512,9.3105,13.7512,10.0004C13.7512,10.6904,14.3105,11.2497,15.0004,11.2497C15.6904,11.2497,16.2497,10.6904,16.2497,10.0004C16.2497,9.3105,15.6904,8.7512,15.0004,8.7512"
        />
      </svg>
    </button>
  </ng-container>

  <div
    class="reaction-summary"
    *ngIf="emojiDataArray?.length"
    (mouseenter)="showTooltip()"
    (mouseleave)="hideTooltip()"
  >
    <span class="emoji-list">{{ getAllEmojis() }}</span>
    <span class="reaction-count">{{ getTotalCount() }}</span>

    <div class="reaction-tooltip" [class.visible]="isTooltipVisible">
      <div class="tooltip-header">{{ getEmojiSummary() }}</div>
      <div class="tooltip-content">
        <div *ngFor="let user of getAllUsers()" class="user-reaction">
          <span class="user-emoji">{{ user.emoji }}</span>
          <span class="user-name">{{ user.name }}</span>
        </div>
      </div>
    </div>
  </div>

  <ng-template #userReactionTemplate>
    <button class="reaction-button active" (click)="removeReaction()">
      {{ currentUserReaction?.emoji }}
    </button>
  </ng-template>

  <emoji-mart
    *ngIf="showEmojiPicker"
    class="emoji-picker"
    [emojiSize]="20"
    [darkMode]="false"
    [set]="'apple'"
    (emojiSelect)="addEmoji($event)"
  ></emoji-mart>
</div>
