<app-card>
  <app-card-head pageTitle="Ihre offenen Chancen">
    <app-filter-bar [filterSettings]="filterBarSettings"></app-filter-bar>
  </app-card-head>

  <app-card-body>
    <lib-table
      [filter]="filter"
      [tableId]="'partnerLeadList'"
      [initialPageSize]="50"
      [endpoint]="'partner_lead_chances/'"
      [showSearchbar]="true"
    >
      <lib-table-column
        [columnName]="'send_at'"
        [title]="'Anfragedatum'"
        [designPointer]="true"
        [isSearchable]="false"
        [pipe]="{ name: 'date', args: ['dd.MM.yyyy HH:mm'] }"
        [suffix]="'Uhr'"
      ></lib-table-column>
      <lib-table-column
        [defaultSort]="'asc'"
        [columnName]="'status'"
        [title]="'Status'"
        [isSortable]="true"
        [customTemplate]="statusTemplate"
      >
        <ng-template #statusTemplate let-lead>
          <span *ngIf="lead.status === 8" class="badge bg-danger"
            >Abgelehnt</span
          >
          <span *ngIf="lead.status === 7" class="badge bg-warning"
            >Warten auf Annahme</span
          >
        </ng-template>
      </lib-table-column>
      <lib-table-column
        [columnName]="'decline_reason'"
        [title]="'Ablehnungsgrund'"
        [isSortable]="true"
        [customTemplate]="declineReasonTemplate"
      >
        <ng-template #declineReasonTemplate let-lead>
          <div *ngIf="lead.decline_reason_name?.name">
            {{ lead.decline_reason_name?.name }}
          </div>
        </ng-template>
      </lib-table-column>
      <lib-table-column
        [columnName]="'lead_number'"
        [title]="'Leadnummer'"
        [isSortable]="false"
      ></lib-table-column>
      <lib-table-column
        [columnName]="'lead_type.name'"
        [title]="'Leadart'"
        [isSortable]="false"
      ></lib-table-column>

      <lib-table-column
        [columnName]="'address.postcode'"
        [isSortable]="false"
        [title]="'Projektstandort'"
        [customTemplate]="addressTemplate"
      >
        <ng-template #addressTemplate let-lead>
          <div
            fxLayout="column"
            fxLayoutAlign="space-around start"
            class="chance-list-entry-margin"
          >
            {{ lead.address.postcode }} {{ lead.address.city }}
            {{ lead.address.district }}
          </div>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [columnName]="'price'"
        [isSortable]="false"
        [isSearchable]="false"
        [title]="'Provision'"
        [width]="'20%'"
        [customTemplate]="provisionTemplate"
      >
        <ng-template #provisionTemplate let-lead>
          <div
            fxLayout="column"
            fxLayoutAlign="space-around start"
            class="chance-list-entry-margin mt-3"
            style="margin-bottom: 5px"
          >
            <table class="table table-borderless">
              <tr>
                <td>Nettorechnungssumme</td>
                <td>{{ lead.price.price | percentFormat }}</td>
              </tr>

              <ng-container
                *ngFor="
                  let additionalCommissions of lead.price.additional_commissions
                "
              >
                <tr>
                  <td>
                    Abweichend auf
                    {{ additionalCommissions.additional_price_name }}
                  </td>
                  <td>
                    {{
                      additionalCommissions.additional_price_commission
                        | percentFormat
                    }}
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>
        </ng-template>
      </lib-table-column>
      <lib-table-column
        [columnName]="'lead_comment_summary'"
        [isSortable]="false"
        [isSearchable]="false"
        [title]="'Kommentar'"
        [width]="'20%'"
      >
      </lib-table-column>

      <lib-table-column
        [isSortable]="false"
        [isSearchable]="false"
        [title]="''"
        [customTemplate]="acceptLeadTemplate"
      >
        <ng-template #acceptLeadTemplate let-partnerLead>
          <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
            <button
              class="btn btn-success btn-success"
              (click)="acceptLead(partnerLead)"
            >
              Annehmen
            </button>
            <button
              *ngIf="partnerLead.status === 7"
              class="btn btn-success btn-danger"
              (click)="declineLead(partnerLead)"
            >
              Ablehnen
            </button>
          </div>
        </ng-template>
      </lib-table-column>
    </lib-table>
  </app-card-body>
</app-card>
