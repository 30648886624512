import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from './auth.service';
import { EmailConfigurationService } from '../../../leadmanager/src/app/_services/email/email-configuration.service';
import { SnackBarService } from './snack-bar/snack-bar.service';
import { CommentService } from './comment.service';
import { ReminderService } from './reminder.service';
import { LeadService } from './lead/lead.service';
import { FeedService } from './feed.service';
import { FileUploadService } from './file-upload/file-upload.service';
import { EnvironmentService } from './environment.service';
import {
  Feed,
  FeedInteractionCounts,
  FeedItemActionButton,
  FeedType,
  FilterOption,
  NewFeed,
} from '../models/feed';
import { Call } from '../models/call';
import { Comment } from '../models/comment';
import { EmailObject } from '../models/email';
import { Reminder } from '../models/reminder';
import { Lead } from '../models/leads/lead';
import { User } from '../models/user';
import { PartnerUser } from '../models/partners/partner-user';
import { Reaction } from '../models/reaction';
import { ModalInputData } from '../models/modal';
import { ModalComponent } from '../components/modal/modal.component';
import {
  BadgePosition,
  IconBadge,
  IconColor,
} from '../components/icon/icon.component';
import {
  ButtonIconColor,
  ButtonType,
} from '../components/button/button.component';
import { ApplicationHelper } from '../helpers/application-helper';
import { DateHelper } from '../helpers/date-helper';
import { Application } from '../enum-collection';

/**
 * Service zur Verwaltung von Feed-Elementen
 * Behandelt die Anzeige und Interaktion mit verschiedenen Feed-Typen wie Kommentare, E-Mails, Dokumente etc.
 */
@Injectable({
  providedIn: 'root',
})
export class FeedItemService {
  // Konstanten
  private readonly REMINDER_STATUS = {
    DONE: 2,
    PENDING: 1,
  };

  private readonly PARTNER_LEAD_STATUS_OPTIONS = {
    ACCEPTED: 1,
    REJECTED: 4,
    CANCELED: 8,
    PAID: 5,
    COMPLETED: 9,
  };

  // Observable für Feed-Updates
  private feedUpdated = new EventEmitter<void>();

  // Aktuell eingeloggter Benutzer
  private authUser: User | PartnerUser | null = null;

  // Filter-Definitionen für verschiedene Feed-Typen
  private readonly filters: FilterOption[] = [
    { type: 'App\\CommentSummary', icon: 'summary', label: 'Zusammenfassung' },
    { type: 'App\\CommentWebsite', icon: 'website', label: 'Webseite' },
    { type: 'App\\Comment', icon: 'comment', label: 'Kommentar' },
    { type: 'App\\Call', icon: 'call-in', label: 'Anruf' },
    { type: 'App\\Email', icon: 'email', label: 'E-Mail' },
    { type: 'App\\Document', icon: 'document', label: 'Dokumente' },
    {
      type: 'App\\PartnerLeadHistory',
      icon: 'status',
      label: 'Status Partner',
    },
    { type: 'App\\LeadHistory', icon: 'status', label: 'Status Lead' },
    { type: 'App\\Reminder', icon: 'calendar', label: 'Wiedervorlage' },
    { type: 'App\\LeadFeedback', icon: 'feedback', label: 'Feedback' },
    {
      type: 'App\\LeadResponseHistory',
      icon: 'feedback',
      label: 'Lead Rückmeldung',
    },
  ];

  constructor(
    private authService: AuthService,
    private emailConfigurationService: EmailConfigurationService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private commentService: CommentService,
    private fileUploadService: FileUploadService,
    private reminderService: ReminderService,
    private feedService: FeedService,
    private environmentService: EnvironmentService,
    private leadService: LeadService,
  ) {
    this.initializeAuthUser();
  }

  private initializeAuthUser(): void {
    this.authService.getCurrentUser().subscribe((user) => {
      this.authUser = user;
    });
  }

  // Feed Update Methoden
  getFeedUpdated(): Observable<void> {
    return this.feedUpdated.asObservable();
  }

  emitFeedUpdated(): void {
    this.feedUpdated.emit();
  }

  // Icon und Filter Methoden
  getIcon(feedType: FeedType): string {
    return this.filters.find((f) => f.type === feedType)?.icon || 'rss_feed';
  }

  getDisplayedFilters(): FilterOption[] {
    if (ApplicationHelper.applicationName === Application.Leadmanager) {
      return this.filters;
    }

    const salesrunnerTypes = [
      'App\\CommentSummary',
      'App\\Comment',
      'App\\Document',
      'App\\PartnerLeadHistory',
      'App\\Reminder',
    ];

    return this.filters.filter((filter) =>
      salesrunnerTypes.includes(filter.type),
    );
  }

  // Interaktions-Zähler
  getInteractionCounts(feed: Feed): FeedInteractionCounts {
    return {
      commentCount: this.countAllComments(feed.relatable?.comments || []),
      reactionCount: this.countAllReactions(feed),
    };
  }

  private countAllComments(comments: any[]): number {
    let count = comments.length;
    comments.forEach((comment) => {
      if (comment.comments) {
        count += this.countAllComments(comment.comments);
      }
    });
    return count;
  }

  private countAllReactions(feed: Feed): number {
    let count = feed.relatable?.reactions?.length || 0;

    const countReactionsInComments = (comments: any[]) => {
      comments.forEach((comment) => {
        count += comment.reactions?.length || 0;
        if (comment.comments) {
          countReactionsInComments(comment.comments);
        }
      });
    };

    if (feed.relatable?.comments) {
      countReactionsInComments(feed.relatable.comments);
    }

    return count;
  }

  // Überschriften und Beschreibungen
  getHeadline(feed: NewFeed): string {
    const handlers: Record<FeedType, (feed: NewFeed) => string> = {
      'App\\Comment': (feed) => this.getCommentHeadline(feed),
      'App\\CommentSummary': () => this.getCommentSummaryHeadline(feed),
      'App\\CommentWebsite': () => 'Angaben vom Interessenten',
      'App\\LeadResponseHistory': () => 'Angaben vom Interessenten',
      'App\\Call': (feed) => feed.relatable?.user_name || 'Unbekannt',
      'App\\Email': (feed) => feed.relatable?.subject || 'Unbekannt',
      'App\\Document': (feed) => this.getDocumentHeadline(feed),
      'App\\LeadFeedback': (feed) =>
        feed.relatable?.partner?.name || 'Unbekannt',
      'App\\PartnerLeadHistory': (feed) =>
        this.getPartnerLeadHistoryHeadline(feed),
      'App\\LeadHistory': (feed) => this.getLeadHistoryHeadline(feed),
      'App\\Reminder': (feed) => this.getReminderHeadline(feed),
    };

    return handlers[feed.relatable_type](feed);
  }

  getDescription(feed: NewFeed): string {
    const handlers: Partial<Record<FeedType, (feed: NewFeed) => string>> = {
      'App\\Comment': (feed) => feed.relatable?.comment,
      'App\\CommentSummary': () => feed.relatable?.summary,
      'App\\CommentWebsite': () => this.getCommentWebsiteDescription(feed),
      'App\\LeadResponseHistory': () =>
        feed.relatable?.lead_response_option?.name || 'Unbekannt',
      'App\\Call': (feed) => this.getCallDescription(feed),
      'App\\Email': (feed) => this.getEmailDetailDescription(feed),
      'App\\Document': (feed) => this.getDocumentDescription(feed),
      'App\\Reminder': (feed) => this.getReminderDescription(feed),
      'App\\PartnerLeadHistory': (feed) =>
        this.getPartnerLeadHistoryDescription(feed),
    };

    const handler = handlers[feed.relatable_type];
    return handler ? handler(feed) : feed.description || '';
  }

  getShortDescription(feed: NewFeed): string {
    const handlers: Partial<Record<FeedType, (feed: NewFeed) => string>> = {
      'App\\Comment': (feed) => feed.relatable?.comment,
      'App\\CommentSummary': () => feed.relatable?.summary,
      'App\\CommentWebsite': () =>
        feed.relatable?.lead_comment || 'Kein abweichender Kommentar.',
      'App\\LeadResponseHistory': () =>
        feed.relatable?.lead_response_option?.name || 'Unbekannt',
      'App\\Call': (feed) => this.getCallDescription(feed),
      'App\\Email': (feed) => this.getEmailDescription(feed),
      'App\\Document': (feed) => this.getDocumentDescription(feed),
      'App\\Reminder': (feed) => this.getReminderDescription(feed),
    };

    const handler = handlers[feed.relatable_type];
    return handler ? handler(feed) : feed.description || '';
  }

  // Badge Verwaltung
  getBadges(feed: Feed): IconBadge[] {
    const badges: IconBadge[] = [];
    this.addInteractionBadges(feed, badges);
    this.addTypeSpecificBadges(feed, badges);
    return badges;
  }

  private addInteractionBadges(feed: Feed, badges: IconBadge[]): void {
    if (feed?.relatable?.comments?.length > 0) {
      badges.push({ name: 'conversion', position: 'top-left' });
    }

    if (feed?.relatable?.reactions?.length > 0) {
      badges.push({ name: 'thumbs-up', position: 'bottom-left' });
    }
  }

  private addTypeSpecificBadges(feed: Feed, badges: IconBadge[]): void {
    const handlers: Partial<Record<FeedType, (feed: Feed) => void>> = {
      'App\\Reminder': (feed) => this.addReminderBadges(feed, badges),
      'App\\Email': (feed) => this.addEmailBadges(feed, badges),
      'App\\Call': (feed) => this.addCallBadges(feed, badges),
      'App\\PartnerLeadHistory': (feed) =>
        this.addPartnerLeadHistoryBadges(feed, badges),
    };

    const handler = handlers[feed.relatable_type as FeedType];
    if (handler) {
      handler(feed);
    }
  }

  // Aktionen und Berechtigungen
  getAvailableActions(feed: Feed, lead?: Lead): FeedItemActionButton[] {
    const actions: FeedItemActionButton[] = [];

    // Basis-Aktionen hinzufügen
    this.addBaseActions(feed, actions);

    // Typ-spezifische Aktionen hinzufügen
    this.addTypeSpecificActions(feed, lead, actions);

    // Salesrunner-spezifische Aktionen
    this.addSalesrunnerActions(feed, lead, actions);

    // Zusätzliche Aktionen
    this.addAdditionalActions(feed, actions);

    return actions;
  }

  // Hilfsmethoden für String-Formatierung
  private parseRecipients(recipients: string): string {
    try {
      const parsed = JSON.parse(recipients);
      return Array.isArray(parsed) ? parsed.join(', ') : recipients;
    } catch {
      return recipients || '';
    }
  }

  private formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(1)) + ' ' + sizes[i];
  }

  // Action Handler
  onAction(type: string, elementToEdit: any): void {
    const actionHandlers: Record<string, () => void> = {
      openSalesrunner: () => this.openInSalesrunner(elementToEdit),
      openMailWindow: () => this.openEmail(elementToEdit),
      deleteFeed: () => this.deleteFeedElement(elementToEdit),
      deleteDocument: () => this.deleteDocument(elementToEdit),
      deleteComment: () => this.deleteComment(elementToEdit),
      completeReminder: () => this.completeReminder(elementToEdit),
      editReminder: () => this.editReminder(elementToEdit),
      editComment: () => this.editComment(elementToEdit),
      updateCommentVisibility: () =>
        this.updateCommentVisibility(elementToEdit),
      changeVisibleInSalesrunner: () =>
        this.changeVisibleInSalesrunner(elementToEdit),
      changeVisibleInLeadEmail: () =>
        this.changeVisibleInLeadEmail(elementToEdit),
      flagFeedElement: () => this.handleFlagFeedElement(elementToEdit),
    };

    const handler = actionHandlers[type];
    if (handler) {
      handler();
    }
  }

  // Private Hilfsmethoden für die verschiedenen Aktionen
  private handleFlagFeedElement(elementToEdit: any): void {
    elementToEdit.flag = !elementToEdit.flag;
    this.feedService.update(elementToEdit).subscribe(() => {
      this.feedUpdated.emit();
      this.snackBarService.openSnackBar(
        'Markierung erfolgreich geändert',
        'success',
      );
    });
  }

  // Reminder Verwaltung
  private processReminderFromResponse(
    modalResponse: any,
    lead: Lead,
    existingReminder?: Reminder,
  ): Observable<Reminder> {
    const reminder = this.reminderService.createOrUpdateReminderObject(
      modalResponse,
      existingReminder,
      lead,
    );

    const operation =
      existingReminder && reminder.id
        ? this.reminderService.updateReminder(reminder.id, reminder)
        : this.reminderService.createReminder(reminder);

    this.feedUpdated.emit();

    return this.setPartnerLeadIfNeeded(reminder).pipe(
      switchMap(() => operation),
      filter((reminderRet: Reminder) => !!reminderRet?.id),
      switchMap((reminderRet: Reminder) =>
        this.reminderService.handleCommentCreation(
          reminderRet,
          modalResponse,
          this.authUser,
        ),
      ),
      map((result) => {
        if (result instanceof Comment) {
          return reminder;
        }
        return result as Reminder;
      }),
    );
  }

  private setPartnerLeadIfNeeded(reminder: Reminder): Observable<Reminder> {
    if (ApplicationHelper.applicationName === Application.Salesrunner) {
      // Partner Lead Logik hier implementieren
      return of(reminder);
    }
    return of(reminder);
  }

  // E-Mail Verwaltung
  private send(emailObject: EmailObject): void {
    const email = { email_id: emailObject.id };

    this.emailConfigurationService.sendTestEmail(email).subscribe({
      next: (response) => {
        if (response.success) {
          this.snackBarService.openSnackBar('Erfolgreich gesendet.', 'success');
          this.feedUpdated.emit();
        } else {
          this.snackBarService.openSnackBar(
            `Fehler: ${response.message}`,
            'error',
          );
        }
      },
      error: (error) => {
        this.snackBarService.openSnackBar(
          `Ein Fehler ist aufgetreten: ${error.message}`,
          'error',
        );
      },
    });
  }

  // Berechtigungsprüfungen
  private canShowSalesrunnerAction(feed: Feed): boolean {
    return (
      ApplicationHelper.applicationName === Application.Leadmanager &&
      !!feed.partner_lead_id &&
      !!feed.relatable?.partner_user
    );
  }

  private canComment(feed: Feed): boolean {
    return feed.relatable_type !== 'App\\CommentSummary';
  }

  private userCanEdit(relatable: any): boolean {
    if (!relatable) return false;

    if (ApplicationHelper.applicationName === Application.Leadmanager) {
      return relatable.user_id === this.authUser?.id;
    }

    if (ApplicationHelper.applicationName === Application.Salesrunner) {
      return relatable.partner_user_id === this.authUser?.id;
    }

    return false;
  }

  // Beschreibungs-Generator Methoden
  private getCommentSummaryHeadline(feed: Feed): string {
    return ApplicationHelper.applicationName === Application.Leadmanager
      ? 'Zusammenfassung in Lead-Email'
      : 'Zusammenfassung';
  }

  private getCommentHeadline(feed: Feed): string {
    const comment = feed.relatable as Comment;

    if (comment?.user?.name) {
      return comment.user.name;
    }

    if (comment?.partner_user) {
      const firstName = comment.partner_user.first_name || '';
      const lastName = comment.partner_user.last_name || '';
      return [firstName, lastName].filter(Boolean).join(' ');
    }

    return 'Unbekannt';
  }

  private getDocumentHeadline(feed: Feed): string {
    const document = feed.relatable;

    if (document?.partner_user) {
      return [document.partner_user.first_name, document.partner_user.last_name]
        .filter(Boolean)
        .join(' ');
    }

    if (document?.partner) {
      return document.partner.name;
    }

    if (document?.user) {
      return document.user.name;
    }

    return 'Unbekannt';
  }

  private splitText(text: string): string[] {
    if (!text) return [];
    return text.split(/(\s+)/).filter((s) => s.trim().length > 0);
  }

  private findLongestCommonSubsequence(
    text1: string[],
    text2: string[],
  ): number[][] {
    const dp: number[][] = Array.from({ length: text1.length + 1 }, () =>
      Array(text2.length + 1).fill(0),
    );

    for (let i = 1; i <= text1.length; i++) {
      for (let j = 1; j <= text2.length; j++) {
        dp[i][j] =
          text1[i - 1] === text2[j - 1]
            ? dp[i - 1][j - 1] + 1
            : Math.max(dp[i - 1][j], dp[i][j - 1]);
      }
    }
    return dp;
  }

  private getCommentWebsiteDescription(feed: NewFeed): string {
    const commentWebsite = feed.relatable;
    if (!commentWebsite) return '';

    const hasLeadComment =
      commentWebsite.lead_comment && commentWebsite.lead_comment.trim() !== '';
    const originalText = commentWebsite.original_ai_comment || '';

    if (!hasLeadComment) {
      return `Original KI-Text:\n${originalText}`;
    }

    // Textvergleich durchführen
    const original = this.splitText(originalText);
    const leadText = this.splitText(commentWebsite.lead_comment);
    const dp = this.findLongestCommonSubsequence(original, leadText);

    // Unterschiede mit HTML markieren
    let diffText = '';
    let i = original.length;
    let j = leadText.length;

    const temp = [];
    while (i > 0 || j > 0) {
      if (i > 0 && j > 0 && original[i - 1] === leadText[j - 1]) {
        temp.unshift(`${original[i - 1]} `);
        i--;
        j--;
      } else if (j > 0 && (i === 0 || dp[i][j - 1] >= dp[i - 1][j])) {
        temp.unshift(
          `<span style="background: #e6ffe6; color: #0b6623; border-radius: 2px;">${
            leadText[j - 1]
          }</span> `,
        );
        j--;
      } else if (i > 0) {
        temp.unshift(
          `<span style="background: #ffe6e6; color: #cc0000; text-decoration: line-through; border-radius: 2px;">${
            original[i - 1]
          }</span> `,
        );
        i--;
      }
    }

    diffText = temp.join('');

    return `<div>
      <div><b>Original:</b>
        <p style="white-space: pre-line;">${originalText}</p>
      </div>
      <div><b>Lead mit Änderungsmarkierungen:</b>
        <p style="white-space: pre-line;">${diffText}</p>
      </div>
    </div>`;
  }

  private getCallDescription(feed: Feed): string {
    const call = feed.relatable as Call;
    const statusMap: { [key: string]: string } = {
      normalClearing: 'Erreicht',
      cancel: 'Nicht erreicht',
      forwarded: 'Weitergeleitet',
      notFound: 'Falsche Telefonnummer',
      busy: 'Besetzt',
      noAnswer: 'Keine Antwort',
    };

    const status =
      statusMap[call.hangUpCause] || call.hangUpCause || 'Unbekannt';
    const duration = call.duration
      ? `${Math.round(call.duration / 60)} Min`
      : '0 Min';
    const phoneNumber =
      call.direction === 'in' ? call.from : call.answeringNumber;

    return `${phoneNumber} \n Status: ${status}, Dauer: ${duration}`;
  }

  private getEmailDetailDescription(feed: Feed): string {
    const email = feed.relatable as EmailObject;

    const parts = [
      `Empfänger: ${this.parseRecipients(email.mail_to)}`,
      `Gesendet am: ${DateHelper.getFormattedDateTime(
        new Date(feed.event_at),
      )} Uhr`,
      email.email_opened_at
        ? `Geöffnet: ${DateHelper.getFormattedDateTime(
            new Date(email.email_opened_at),
          )} Uhr`
        : 'Noch nicht geöffnet',
      `Antwort: ${
        email.response_code === '200'
          ? 'Mailversand erfolgreich'
          : 'Fehler beim Mailversand'
      }`,
      email.source ? `Quelle: ${email.source}` : null,
    ].filter(Boolean);

    return parts.join('\n');
  }

  private getEmailDescription(feed: Feed): string {
    const email = feed.relatable as EmailObject;
    const recipients = this.parseRecipients(email.mail_to);
    const openedText = email.email_opened_at
      ? `Geöffnet: ${DateHelper.getFormattedDateTime(
          new Date(email.email_opened_at),
        )}`
      : 'Noch nicht geöffnet';

    return `Empfänger: ${recipients} \n Status: ${feed.description} ${openedText}`;
  }

  private getDocumentDescription(feed: Feed): string {
    const document = feed.relatable;
    const baseUrl = this.environmentService.apiUrl;

    const mainDocumentLink = `<a href="${baseUrl}${
      document.file_access_link
    }" target="_blank">${
      document.original_file_name
    }</a> (${this.formatFileSize(document.size)})`;

    if (!document.related_documents?.length) {
      return mainDocumentLink;
    }

    const relatedDocumentsLinks = document.related_documents
      .map(
        (doc: any) =>
          `\n <a href="${baseUrl}${doc.file_access_link}" target="_blank">${
            doc.original_file_name
          }</a> (${this.formatFileSize(doc.size)})`,
      )
      .join('');

    return `${mainDocumentLink} ${relatedDocumentsLinks}`;
  }

  private getReminderDescription(feed: Feed): string {
    const reminder = feed.relatable as Reminder;
    const reminderDate = new Date(reminder.reminder_date);
    const showTime =
      reminderDate.getHours() !== 0 || reminderDate.getMinutes() !== 0;
    const date = showTime
      ? DateHelper.getFormattedDateTime(reminderDate)
      : DateHelper.getFormattedDate(reminderDate);
    const type = reminder.reminder_type_option?.name || '';

    return `${date} - ${type} \nStatus ${reminder.reminder_status_option?.name}`;
  }

  // Badge Hilfsmethoden
  private addReminderBadges(feed: Feed, badges: IconBadge[]): void {
    if (
      feed.relatable.reminder_status_option_id === this.REMINDER_STATUS.DONE
    ) {
      badges.push({ name: 'check', position: 'center-right' });
    } else {
      badges.push({
        name: 'bell',
        position: 'center-right',
        color: this.getReminderStatusColor(feed.relatable),
      });
    }
  }

  private addCallBadges(feed: Feed, badges: IconBadge[]): void {
    badges.push({
      name: feed.relatable.hangUpCause === 'normalClearing' ? 'check' : 'x',
      position: 'center-right',
    });
  }

  private addEmailBadges(feed: Feed, badges: IconBadge[]): void {
    if (feed.description === 'E-Mail wurde erneut versendet') {
      badges.push({
        name: 'refresh',
        color: IconColor.SUCCESS,
        position: 'center-right',
      });
    }

    if (feed.description === 'E-Mail wurde geöffnet') {
      badges.push({
        name: 'eye',
        color: IconColor.SUCCESS,
        position: 'center-right',
      });
    }

    if (feed?.relatable?.response_code !== '200') {
      badges.push({
        name: 'x',
        color: IconColor.DANGER,
        position: 'center-right',
      });
    }
  }

  private addPartnerLeadHistoryBadges(feed: Feed, badges: IconBadge[]): void {
    const optionId = feed?.relatable?.status_option?.option_id;
    if (!optionId) return;

    const badgeConfig: Record<
      number,
      { name: string; position: BadgePosition }[]
    > = {
      [this.PARTNER_LEAD_STATUS_OPTIONS.ACCEPTED]: [
        { name: 'check', position: 'center-right' },
      ],
      [this.PARTNER_LEAD_STATUS_OPTIONS.REJECTED]: [
        { name: 'x', position: 'center-right' },
      ],
      [this.PARTNER_LEAD_STATUS_OPTIONS.CANCELED]: [
        { name: 'x', position: 'center-right' },
      ],
      [this.PARTNER_LEAD_STATUS_OPTIONS.PAID]: [
        { name: 'dollar', position: 'center-right' },
      ],
      [this.PARTNER_LEAD_STATUS_OPTIONS.COMPLETED]: [
        { name: 'dollar', position: 'top-right' },
        { name: 'check', position: 'center-right' },
      ],
    };

    const config = badgeConfig[optionId];
    if (config) {
      badges.push(...config);
    }
  }

  private getReminderStatusColor(reminder: Reminder): string {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const reminderDate = new Date(reminder.reminder_date);
    reminderDate.setHours(0, 0, 0, 0);

    if (reminderDate.getTime() === today.getTime()) {
      return IconColor.WARNING;
    }
    if (reminderDate < today) {
      return IconColor.DANGER;
    }
    return IconColor.SUCCESS;
  }

  // Aktionen-Handler
  private addBaseActions(feed: Feed, actions: FeedItemActionButton[]): void {
    if (this.canComment(feed)) {
      const commentCount = this.countAllComments(
        feed.relatable?.comments || [],
      );
      actions.push({
        type: ButtonType.COMMENT,
        label: 'Kommentieren',
        onClick: () => this.onAction('comment', feed),
        iconColor: ButtonIconColor.DEFAULT,
        count: commentCount > 0 ? commentCount : undefined,
      });
    }
  }

  private getLeadHistoryHeadline(feed: Feed): string {
    return feed.relatable?.status_option?.name || 'Unbekannt';
  }

  private getPartnerLeadHistoryHeadline(feed: Feed): string {
    return feed.relatable?.status_option?.name || 'Unbekannt';
  }

  private getReminderHeadline(feed: Feed): string {
    const reminder = feed.relatable as Reminder;

    if (reminder?.user?.name) {
      return reminder.user.name;
    }

    if (reminder?.partner_user) {
      const firstName = reminder.partner_user.first_name || '';
      const lastName = reminder.partner_user.last_name || '';
      return [firstName, lastName].filter(Boolean).join(' ');
    }

    return 'System';
  }

  private getPartnerLeadHistoryDescription(feed: Feed): string {
    let status = feed.relatable?.status_option?.name || 'Unbekannt';
    if (feed.relatable?.cancel_reason_option?.name) {
      status = status + ' ' + feed.relatable?.cancel_reason_option?.name;
    }

    return status;
  }

  private addFeedbackActions(
    feed: Feed,
    actions: FeedItemActionButton[],
  ): void {
    actions.push({
      type: ButtonType.EYE,
      label: 'Details anzeigen',
      onClick: () => this.onAction('details', feed),
      iconColor: ButtonIconColor.DEFAULT,
    });
  }

  private addSalesrunnerActions(
    feed: Feed,
    lead: Lead | undefined,
    actions: FeedItemActionButton[],
  ): void {
    if (this.canShowSalesrunnerAction(feed)) {
      actions.push({
        type: ButtonType.EXTERNAL_LINK,
        label: 'Im Salesrunner anzeigen',
        onClick: () => this.onAction('openSalesrunner', feed),
        iconColor: ButtonIconColor.DEFAULT,
      });
    }

    if (ApplicationHelper.applicationName === Application.Leadmanager) {
      if (
        ['App\\Comment', 'App\\Reminder', 'App\\Document'].includes(
          feed.relatable_type,
        )
      ) {
        actions.push({
          type: ButtonType.EYE,
          disabled: lead?.status === 3,
          label: 'Sichtbar im SR',
          onClick: () => this.onAction('changeVisibleInSalesrunner', feed),
          iconColor: feed.visible_in_salesrunner
            ? ButtonIconColor.SUCCESS
            : ButtonIconColor.DEFAULT,
        });
      }
    }
  }

  private addAdditionalActions(
    feed: Feed,
    actions: FeedItemActionButton[],
  ): void {
    if (feed.partner_lead_id === null) {
      actions.push({
        type: ButtonType.FLAG,
        label: 'Markieren',
        onClick: () => this.onAction('flagFeedElement', feed),
        iconColor: feed.flag ? ButtonIconColor.DANGER : ButtonIconColor.DEFAULT,
      });
    }

    actions.push({
      type: ButtonType.THUMBS_UP,
      emoji: this.getCurrentUserReaction(feed?.relatable?.reactions),
      count: this.countAllReactions(feed),
      label: 'Reagieren',
      iconColor: ButtonIconColor.DEFAULT,
    });
  }

  // Dialog-Handler
  private openInSalesrunner(feed: Feed): void {
    this.authService.loginAsPartnerUser(
      feed.relatable?.partner_user?.id,
      '/salesrunner/#/leads/edit/' + feed.partner_lead_id,
    );
  }

  private openEmail(feed: Feed): void {
    const emailObject = feed.relatable;
    const inputData =
      this.emailConfigurationService.getResendEmailData(emailObject);

    this.dialog
      .open(ModalComponent, {
        disableClose: true,
        data: inputData,
      })
      .afterClosed()
      .subscribe((modalResponse) => {
        if (modalResponse) {
          this.send(emailObject);
        }
      });
  }

  private deleteFeedElement(feed: Feed): void {
    const modalData: ModalInputData = {
      headline:
        feed.relatable_type === 'App\\Comment'
          ? 'Kommentar löschen'
          : 'Eintrag löschen',
      body:
        feed.relatable_type === 'App\\Comment'
          ? 'Soll der Kommentar wirklich gelöscht werden?'
          : 'Soll der Eintrag wirklich gelöscht werden?',
      acceptButtonText: 'Ja',
      declineButtonText: 'Nein',
    };

    this.dialog
      .open(ModalComponent, {
        disableClose: true,
        data: modalData,
      })
      .afterClosed()
      .subscribe((modalResponse) => {
        if (!modalResponse || !feed.relatable.id) return;

        if (feed.relatable_type === 'App\\Comment') {
          this.commentService.deleteComment(feed.relatable.id).subscribe(() => {
            this.snackBarService.openSnackBar(
              'Kommentar erfolgreich gelöscht',
              'success',
            );
            this.feedUpdated.emit();
          });
        }
      });
  }

  private deleteDocument(feed: Feed): void {
    const modalData: ModalInputData = {
      headline: 'Dokumente löschen',
      body: 'Soll alle Dokumente und zugehörigen Kommentare von diesem Eintrag gelöscht werden?',
      acceptButtonText: 'Ja',
      declineButtonText: 'Nein',
    };

    this.dialog
      .open(ModalComponent, {
        disableClose: true,
        data: modalData,
      })
      .afterClosed()
      .subscribe((modalResponse) => {
        if (!modalResponse || !feed.id) return;

        this.fileUploadService.delete(feed.relatable_id).subscribe(() => {
          if (feed.relatable?.related_documents) {
            feed.relatable.related_documents.forEach((document: any) => {
              this.fileUploadService.delete(document.id).subscribe();
            });
          }

          this.snackBarService.openSnackBar(
            'Dokument erfolgreich gelöscht',
            'success',
          );
          this.feedUpdated.emit();
        });
      });
  }

  private completeReminder(feed: Feed): void {
    const modalData: ModalInputData = {
      headline: 'Erinnerung abschließen',
      body: 'Soll die Erinnerung wirklich als erledigt markiert werden?',
      acceptButtonText: 'Ja',
      declineButtonText: 'Nein',
    };

    this.dialog
      .open(ModalComponent, {
        disableClose: true,
        data: modalData,
      })
      .afterClosed()
      .subscribe((modalResponse) => {
        if (!modalResponse || !feed.relatable.id) return;

        feed.relatable.reminder_status_option_id = this.REMINDER_STATUS.DONE;
        this.reminderService
          .updateReminder(feed.relatable.id, feed.relatable)
          .subscribe(() => {
            this.snackBarService.openSnackBar(
              'Erinnerung abgeschlossen',
              'success',
            );
            this.feedUpdated.emit();
          });
      });
  }

  private editReminder(feed: Feed): void {
    if (!feed.lead_id) return;

    this.leadService.show(feed.lead_id).subscribe((lead) => {
      const inputData = this.reminderService.getReminderModalInputData(
        feed.relatable,
        this.authUser,
        lead,
      );

      this.dialog
        .open(ModalComponent, { disableClose: true, data: inputData })
        .afterClosed()
        .pipe(
          filter(Boolean),
          switchMap((modalResponse) =>
            this.processReminderFromResponse(
              modalResponse,
              lead,
              feed.relatable,
            ),
          ),
          tap(
            () =>
              this.snackBarService.openSnackBar(
                'Erinnerung gespeichert',
                'success',
              ),
            () =>
              this.snackBarService.openSnackBar(
                'Fehler beim Speichern der Erinnerung',
                'warn',
              ),
          ),
        )
        .subscribe(() => this.feedUpdated.emit());
    });
  }

  private changeVisibleInLeadEmail(feed: NewFeed): void {
    feed.relatable.visible_in_lead_email =
      !feed.relatable.visible_in_lead_email;

    this.commentService
      .updateComment(feed.relatable_id, feed.relatable)
      .subscribe(() => {
        this.feedUpdated.emit();
        this.snackBarService.openSnackBar(
          'Sichtbarkeit erfolgreich aktualisiert',
          'success',
        );
      });
  }

  private changeVisibleInSalesrunner(feed: NewFeed): void {
    feed.visible_in_salesrunner = !feed.visible_in_salesrunner;

    this.feedService.update(feed).subscribe((feed) => {
      if (
        feed.relatable_type === 'App\\Comment' &&
        feed.visible_in_salesrunner
      ) {
        feed.relatable.visible_in_lead_email = true;

        this.commentService
          .updateComment(feed.relatable_id, feed.relatable)
          .subscribe(() => {
            this.feedUpdated.emit();
            this.snackBarService.openSnackBar(
              'Sichtbarkeit erfolgreich aktualisiert',
              'success',
            );
          });
      } else {
        this.feedUpdated.emit();
        this.snackBarService.openSnackBar(
          'Sichtbarkeit erfolgreich aktualisiert',
          'success',
        );
      }
    });
  }

  getCurrentUserReaction(reactions: Reaction[]): string {
    if (!reactions || reactions.length === 0) return '';

    if (ApplicationHelper.applicationName === Application.Leadmanager) {
      return (
        reactions.find((r) => r.user_id === this.authUser?.id)?.emoji ?? ''
      );
    }

    if (ApplicationHelper.applicationName === Application.Salesrunner) {
      return (
        reactions.find((r) => r.partner_user_id === this.authUser?.id)?.emoji ??
        ''
      );
    }

    return '';
  }

  private addTypeSpecificActions(
    feed: Feed,
    lead: Lead | undefined,
    actions: FeedItemActionButton[],
  ): void {
    const actionHandlers: Partial<Record<FeedType, () => void>> = {
      'App\\Email': () => this.addEmailActions(feed, actions),
      'App\\Reminder': () => this.addReminderActions(feed, actions),
      'App\\Comment': () => this.addCommentActions(feed, lead, actions),
      'App\\Document': () => this.addDocumentActions(feed, actions),
      'App\\LeadFeedback': () => this.addFeedbackActions(feed, actions),
    };

    const handler = actionHandlers[feed.relatable_type as FeedType];
    if (handler) {
      handler();
    }
  }

  private addEmailActions(feed: Feed, actions: FeedItemActionButton[]): void {
    actions.push({
      type: ButtonType.MAIL,
      label: 'Anzeigen',
      onClick: () => this.onAction('openMailWindow', feed),
      iconColor: ButtonIconColor.DEFAULT,
    });
  }

  private addReminderActions(
    feed: Feed,
    actions: FeedItemActionButton[],
  ): void {
    const reminder = feed.relatable as Reminder;
    if (reminder?.reminder_status_option_id === this.REMINDER_STATUS.PENDING) {
      actions.push(
        {
          type: ButtonType.EDIT,
          label: 'Erinnerung bearbeiten',
          onClick: () => this.onAction('editReminder', feed),
          iconColor: ButtonIconColor.DEFAULT,
        },
        {
          type: ButtonType.CHECK,
          label: 'Als erledigt markieren',
          onClick: () => this.onAction('completeReminder', feed),
          iconColor: ButtonIconColor.DEFAULT,
        },
      );
    }
  }

  private addCommentActions(
    feed: Feed,
    lead: Lead | undefined,
    actions: FeedItemActionButton[],
  ): void {
    if (this.userCanEdit(feed.relatable)) {
      actions.push(
        {
          type: ButtonType.EDIT,
          label: 'Bearbeiten',
          onClick: () => this.onAction('edit', feed),
          iconColor: ButtonIconColor.DEFAULT,
        },
        {
          type: ButtonType.DELETE,
          label: 'Löschen',
          onClick: () => this.onAction('deleteFeed', feed),
          iconColor: ButtonIconColor.DEFAULT,
        },
      );
    }

    if (ApplicationHelper.applicationName === Application.Leadmanager) {
      actions.push({
        type: ButtonType.RSS,
        disabled: lead?.status === 3,
        label: 'In Lead Email',
        onClick: () => this.onAction('changeVisibleInLeadEmail', feed),
        iconColor: feed.relatable?.visible_in_lead_email
          ? ButtonIconColor.SUCCESS
          : ButtonIconColor.DEFAULT,
      });
    }
  }

  private addDocumentActions(
    feed: Feed,
    actions: FeedItemActionButton[],
  ): void {
    if (this.userCanEdit(feed.relatable)) {
      actions.push({
        type: ButtonType.DELETE,
        label: 'Löschen',
        onClick: () => this.onAction('deleteDocument', feed),
        iconColor: ButtonIconColor.DEFAULT,
      });
    }
  }

  private deleteComment(comment: Comment): void {
    const modalData: ModalInputData = {
      headline: 'Kommentar löschen',
      body: 'Soll der Kommentar wirklich gelöscht werden?',
      acceptButtonText: 'Ja',
      declineButtonText: 'Nein',
    };

    this.dialog
      .open(ModalComponent, {
        disableClose: true,
        data: modalData,
      })
      .afterClosed()
      .subscribe((modalResponse) => {
        if (!modalResponse || !comment.id) return;

        this.commentService.deleteComment(comment.id).subscribe(() => {
          this.snackBarService.openSnackBar(
            'Kommentar erfolgreich gelöscht',
            'success',
          );
          this.feedUpdated.emit();
        });
      });
  }

  private updateCommentVisibility(comment: Comment): void {
    comment.visible_in_salesrunner = !comment.visible_in_salesrunner;
    this.updateComment(comment);
  }

  private updateComment(comment: Comment): void {
    if (comment.id) {
      this.commentService.updateComment(comment.id, comment).subscribe(() => {
        this.emitFeedUpdated();
      });
    }
  }

  private editComment(comment: Comment): void {}

  public getAvailableCommentActions(
    comment: any,
    lead?: Lead,
  ): FeedItemActionButton[] {
    const actions: FeedItemActionButton[] = [];

    if (this.userCanEdit(comment)) {
      actions.push(
        {
          type: ButtonType.EDIT,
          label: 'Bearbeiten',
          onClick: () => this.onAction('editComment', comment),
          iconColor: ButtonIconColor.DEFAULT,
        },
        {
          type: ButtonType.DELETE,
          label: 'Löschen',
          onClick: () => this.onAction('deleteComment', comment),
          iconColor: ButtonIconColor.DEFAULT,
        },
      );
    }

    if (this.canComment(comment)) {
      const commentCount = this.countAllComments(comment.comments || []);
      actions.push({
        type: ButtonType.COMMENT,
        label: 'Kommentieren',
        onClick: () => this.onAction('comment', comment),
        iconColor: ButtonIconColor.DEFAULT,
        count: commentCount > 0 ? commentCount : undefined,
      });
    }

    if (ApplicationHelper.applicationName === Application.Leadmanager) {
      if (
        ['App\\Comment', 'App\\Reminder', 'App\\Document'].includes(
          comment.relatable_type,
        )
      ) {
        actions.push({
          type: ButtonType.EYE,
          disabled: lead?.status === 3,
          label: 'Sichtbar im SR',
          onClick: () => this.onAction('updateCommentVisibility', comment),
          iconColor: comment.visible_in_salesrunner
            ? ButtonIconColor.SUCCESS
            : ButtonIconColor.DEFAULT,
        });
      }
    }

    actions.push({
      type: ButtonType.THUMBS_UP,
      emoji: this.getCurrentUserReaction(comment.reactions),
      count: comment.reactions?.length,
      label: 'Reagieren',
      iconColor: ButtonIconColor.DEFAULT,
    });

    return actions;
  }
}
