import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TableComponent } from '@lib/components/table/table.component';
import { Subject, Subscription } from 'rxjs';
import { SelectList } from '@lib/models/select-list';
import { PartnerLead } from '@lib/models/partner-lead';
import { FilterSetting } from '@lib/models/filter-setting';

import { FilterService } from '@lib/services/filter/filter.service';
import { SnackBarService } from '@lib/services/snack-bar/snack-bar.service';
import { PartnerLeadService } from '@lib/services/partner-lead/partner-lead.service';
import { takeUntil } from 'rxjs/operators';
import { ModalComponent } from '@lib/components/modal/modal.component';
import { CreateFormGroupHelper } from '@lib/helpers/create-form-group-helper';
import { FormField, ModalInputData } from '@lib/models/modal';
import { MatDialog } from '@angular/material/dialog';
import { ModalFormFieldType } from '@lib/enum-collection';

@Component({
  selector: 'app-chance-list',
  templateUrl: './chance-list.component.html',
  styleUrls: ['./chance-list.component.scss'],
})
export class ChanceListComponent implements OnInit, OnDestroy {
  protected readonly SelectList = SelectList;
  @ViewChild(TableComponent) tableComponent!: TableComponent;
  filterBarSettings: FilterSetting[] = [];
  private filterSubscription: Subscription = new Subscription();
  partnerScore: number = 0;
  displayOnlyReminders: boolean = false;

  filter = {
    lead_type_id: null,
  };

  private destroy$ = new Subject<void>();

  constructor(
    private partnerLeadService: PartnerLeadService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private snackbarService: SnackBarService,
  ) {}

  ngOnInit() {
    this.initFilter();

    this.partnerLeadService
      .getPartnerLeadScoreByAuthenticatedPartnerUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((leadScore) => {
        this.partnerScore = leadScore;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initFilter(disabledFilter = false) {
    this.filterSubscription = this.filterService.filter$
      .pipe(takeUntil(this.destroy$))
      .subscribe((filter) => {
        this.setFilter(filter);
      });

    this.filterBarSettings = [
      new FilterSetting({
        filter: SelectList.LeadType,
        multiSelect: true,
        disabled: disabledFilter,
        placeholder: 'Lead-Art',
      }),
    ];

    this.filterService.getFilter();
  }

  setFilter(filter: any) {
    const filterData = filter?.filter ?? filter;

    this.filter = {
      ...this.filter,
      lead_type_id: filterData?.[SelectList.LeadType],
    };
  }

  reloadTable() {
    this.tableComponent.reloadData();
  }

  declineLead(partnerLead: PartnerLead) {
    const formFields = this.getDeclineModalFormFields();

    const inputData: ModalInputData = {
      headline: 'Lead ablehnen - Verlustgrund auswählen',
      formGroup: CreateFormGroupHelper.createFormGroupForModal(formFields),
      formFields: formFields,
      acceptButtonText: 'Speichern',
      declineButtonText: 'Abbrechen',
    };

    this.dialog
      .open(ModalComponent, {
        disableClose: true,
        data: inputData,
      })
      .afterClosed()
      .subscribe((modalResponse) => {
        if (!modalResponse) return;

        if (modalResponse['decline_reasons']) {
          this.partnerLeadService
            .declinePartnerLead(partnerLead, modalResponse['decline_reasons'])
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: () => {
                this.snackbarService.openSnackBar(
                  'Lead wurde erfolgreich abgelehnt.',
                  'success',
                );

                this.tableComponent.reloadData();
              },
              error: (err) => {
                console.error(err);
                this.snackbarService.openSnackBar(
                  'Lead konnte nicht abgelehnt werden. Bitte versuchen Sie es erneut.',
                  'warning',
                );
              },
            });
        }
      });
  }

  acceptLead(partnerLead: PartnerLead) {
    this.partnerLeadService
      .acceptPartnerLead(partnerLead)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.snackbarService.openSnackBar(
            'Lead wurde erfolgreich angenommen. Sie finden diesen nun in Ihrer Leadliste.',
            'success',
          );

          this.tableComponent.reloadData();
        },
        error: (err) => {
          console.error(err);
          this.snackbarService.openSnackBar(
            'Lead konnte nicht angenommen werden. Bitte versuchen Sie es erneut.',
            'warning',
          );
        },
      });
  }

  getDeclineModalFormFields(): FormField[] {
    return [
      {
        type: ModalFormFieldType.select,
        label: 'Ablehnungsgrund auswählen',
        name: 'decline_reasons',
        validation: { required: true },
        optionlistKeyword: 'decline_reasons',
      },
    ];
  }
}
