import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FeedService } from '../../services/feed.service';
import { Lead } from '../../models/leads/lead';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  Observable,
  of,
  Subject,
  take,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { Feed } from '../../models/feed';
import { AuthService } from '../../services/auth.service';
import { User } from '../../models/user';
import { Document } from '../../models/document/document';
import { Comment } from '../../models/comment';
import { PartnerUser } from '../../models/partners/partner-user';
import { ApplicationHelper } from '../../helpers/application-helper';
import { Application, ModalFormFieldType } from '../../enum-collection';
import { PartnerLead } from '../../models/partner-lead';
import { FeedEmailComponent } from './feed-details/feed-email.component';
import { PartnerLeadHistory } from '../../models/partners/partner-lead-history';
import { LeadHistory } from '../../models/lead-history';
import { LeadFeedBacks } from '../../models/leads/lead-feed-backs';
import { FeedFeedbackComponent } from './feed-details/feed-feedback.component';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommentSummary } from '../../models/comment-summary';
import { FormField, ModalInputData } from '../../models/modal';
import { ModalComponent } from '../modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { CreateFormGroupHelper } from '../../helpers/create-form-group-helper';
import { ReminderService } from '../../services/reminder.service';
import { Reminder } from '../../models/reminder';
import { DateHelper } from '../../helpers/date-helper';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { CommentService } from '../../services/comment.service';
import * as moment from 'moment';

@Component({
  selector: 'lib-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss'],
  animations: [
    trigger('dotState', [
      state(
        'inactive',
        style({
          opacity: 0.2,
          transform: 'scale(0.8)',
        }),
      ),
      state(
        'active',
        style({
          opacity: 1,
          transform: 'scale(1.2)',
        }),
      ),
      transition('inactive <=> active', animate('300ms ease-in-out')),
    ]),
  ],
})
export class FeedComponent implements OnInit, OnDestroy {
  @ViewChild(FeedEmailComponent) feedEmailComponent!: FeedEmailComponent;
  @ViewChild(FeedFeedbackComponent)
  feedLeadFeedbackComponent!: FeedFeedbackComponent;

  @Input() lead$: Observable<Lead> = new Observable<Lead>();
  @Input() partnerLead$: Observable<PartnerLead> =
    new Observable<PartnerLead>();

  filteredFeedItems$: Observable<Feed[]> = new Observable<Feed[]>();
  private feedItemsSubject = new BehaviorSubject<Feed[]>([]);
  protected currentFilter = new BehaviorSubject<string | null>(null);
  protected currentPartnerFilter = new BehaviorSubject<number | null>(null);

  partners: { id: number; name: string }[] = [];
  lead?: Lead = new Lead({});

  aiIsLoading: boolean = false;
  authUser: User | PartnerUser | null = null;
  private destroy$ = new Subject<void>();

  dotStates: string[] = ['inactive', 'inactive', 'inactive'];
  currentDot = 0;

  commentToEditId: number = 0;
  commentSummaryToEdit?: CommentSummary;

  readonly DISPLAYED_FILTER_LEADMANAGER = [
    'App\\CommentSummary',
    'App\\Comment',
    'App\\Call',
    'App\\Email',
    'App\\Document',
    'App\\PartnerLeadHistory',
    'App\\LeadHistory',
    'App\\Reminder',
    'App\\LeadFeedback',
  ];

  readonly DISPLAYED_FILTER_SALESRUNNER = [
    'App\\CommentSummary',
    'App\\Comment',
    'App\\Document',
    'App\\PartnerLeadHistory',
    'App\\Reminder',
  ];

  constructor(
    private feedService: FeedService,
    private authService: AuthService,
    private dialog: MatDialog,
    private reminderService: ReminderService,
    private snackBarService: SnackBarService,
    private commentService: CommentService,
  ) {}

  ngOnInit(): void {
    this.loadFeed();
    this.animateDots();

    this.authService
      .getCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.authUser = user;
      });

    this.setupFilteredFeed();
    this.extractPartners();

    this.commentService.aiIsLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => {
        this.aiIsLoading = isLoading;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  animateDots() {
    setInterval(() => {
      this.dotStates[this.currentDot] = 'inactive';
      this.currentDot = (this.currentDot + 1) % 3;
      this.dotStates[this.currentDot] = 'active';
    }, 300);
  }

  getDisplayedFilters(): string[] {
    if (ApplicationHelper.applicationName === Application.Leadmanager) {
      return this.DISPLAYED_FILTER_LEADMANAGER;
    } else {
      return this.DISPLAYED_FILTER_SALESRUNNER;
    }
  }

  private setupFilteredFeed() {
    this.filteredFeedItems$ = combineLatest([
      this.feedItemsSubject,
      this.currentFilter,
      this.currentPartnerFilter,
    ]).pipe(
      map(([items, typeFilter, partnerFilter]) =>
        items.filter(
          (item) =>
            (!typeFilter || item.relatable_type === typeFilter) &&
            (!partnerFilter || this.getPartnerId(item) === partnerFilter),
        ),
      ),
    );
  }

  private extractPartners() {
    this.feedItemsSubject
      .pipe(
        map((items) =>
          items.map((item) => ({
            id: this.getPartnerId(item),
            name: this.getPartnerName(item),
          })),
        ),
        map((partners) =>
          partners.filter(
            (
              partner,
            ): partner is {
              id: number;
              name: string;
            } => partner.id !== null && partner.name !== null,
          ),
        ),
        map((partners) =>
          Array.from(new Set(partners.map((p) => JSON.stringify(p)))).map(
            (s) =>
              JSON.parse(s) as {
                id: number;
                name: string;
              },
          ),
        ),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
        ),
      )
      .subscribe((partners) => {
        this.partners = partners;
      });
  }

  getPartnerName(feedItem: Feed): string | null {
    if (feedItem.relatable_type === 'App\\Comment') {
      return feedItem.relatable?.partner_user?.partner?.name || null;
    }
    if (feedItem.relatable_type === 'App\\LeadFeedback') {
      return feedItem.relatable?.partner?.name || null;
    }
    if (feedItem.relatable_type === 'App\\PartnerLeadHistory') {
      return feedItem.relatable?.partner?.name || null;
    }
    if (feedItem.relatable_type === 'App\\Document') {
      return feedItem.relatable?.partner?.name || null;
    }
    if (feedItem.relatable_type === 'App\\Reminder') {
      return feedItem.relatable?.partner_user?.partner?.name || null;
    }
    return null;
  }

  getPartnerId(feedItem: Feed): number | null {
    if (!feedItem.relatable) return null;

    const searchPartnerIdInObject = (obj: any): number | null => {
      if (obj.partner_id) return obj.partner_id;
      if (obj.partner?.id) return obj.partner.id;

      for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          const result = searchPartnerIdInObject(obj[key]);
          if (result !== null) return result;
        }
      }

      return null;
    };

    return searchPartnerIdInObject(feedItem.relatable);
  }

  applyPartnerFilter(partnerId: number | null): void {
    const currentFilter = this.currentPartnerFilter.value;
    if (currentFilter === partnerId) {
      this.currentPartnerFilter.next(null);
    } else {
      this.currentPartnerFilter.next(partnerId);
    }
  }

  userCanEditRelatable(relatable: any): boolean {
    if (!relatable) {
      return false;
    }

    if (ApplicationHelper.applicationName === Application.Leadmanager) {
      if (relatable.user_id === this.authUser?.id) {
        return true;
      }
    } else if (ApplicationHelper.applicationName === Application.Salesrunner) {
      if (relatable.partner_user_id === this.authUser?.id) {
        return true;
      }
    }
    return false;
  }

  loadFeed() {
    const source$ =
      ApplicationHelper.applicationName === Application.Leadmanager
        ? this.lead$.pipe(
            switchMap((lead) => {
              this.lead = lead;
              return lead?.id ? this.feedService.getLeadFeed(lead.id) : [];
            }),
          )
        : this.partnerLead$.pipe(
            switchMap((partnerLead) =>
              partnerLead?.id
                ? this.feedService.getPartnerLeadFeed(partnerLead.id)
                : [],
            ),
          );

    source$.pipe(takeUntil(this.destroy$)).subscribe((items) => {
      this.feedItemsSubject.next(items);
    });
  }

  getFeedIcon(relatable_type: string): string {
    return this.feedService.getFeedIcon(relatable_type);
  }

  getFeedLabel(relatable_type: string): string {
    return this.feedService.getFeedLabel(relatable_type);
  }

  getEventTypeIconClass(relatable_type: string): string {
    return this.feedService.getEventTypeIconClass(relatable_type);
  }

  getRelatableTypeForPost(): string {
    if (ApplicationHelper.applicationName === Application.Leadmanager) {
      return 'Lead';
    }

    return 'PartnerLead';
  }

  getRelatableType(feed: Feed): string {
    return feed?.relatable_type?.split('App\\')[1];
  }

  displayEmail(feed: Feed) {
    if (feed?.relatable) {
      this.feedEmailComponent.resendEmail(feed.relatable);
    }
  }

  showLeadFeedbackDetails(feed: Feed) {
    if (feed?.relatable) {
      this.feedLeadFeedbackComponent.openLeadFeedbackModal();
    }
  }

  toggleCommentInput(item: any): void {
    this.commentToEditId = 0;
    item.showCommentInput = !item.showCommentInput;
  }

  minimizeComments(item: any): void {
    item.minimizeComments = !item.minimizeComments;
  }

  editCommentSummary(item: any): void {
    item.showCommentInput = !item.showCommentInput;

    this.commentSummaryToEdit = item.relatable as CommentSummary;
  }

  editComment(item: any, typePost = false): void {
    item.showCommentInput = !item.showCommentInput;

    if (!item.showCommentInput) {
      this.commentToEditId = 0;
    }

    if (typePost) {
      this.commentToEditId = item.relatable.id;
    } else {
      this.commentToEditId = item.id;
    }
  }

  onCommentAdded(): void {
    this.loadFeed();
  }

  getFeedSalesrunnerVisibilityHint(feedItem: Feed): string {
    return this.feedService.getFeedSalesrunnerVisibilityHint(
      feedItem.visible_in_salesrunner,
      feedItem?.partner_lead?.partner,
      this.lead,
    );
  }

  getFeedSalesrunnerVisibilityHintByComment(comment: Comment): string {
    return this.feedService.getFeedSalesrunnerVisibilityHint(
      comment.visible_in_salesrunner,
      comment?.partner,
      this.lead,
    );
  }

  deleteComment(comment: Comment): void {
    const inputData: ModalInputData = {
      headline: 'Kommentar löschen',
      body: 'Soll der Kommentar wirklich gelöscht werden?',
      acceptButtonText: 'Ja',
      declineButtonText: 'Nein',
    };

    this.dialog
      .open(ModalComponent, {
        disableClose: true,
        data: inputData,
      })
      .afterClosed()
      .subscribe((modalResponse) => {
        if (!modalResponse || !comment.id) return;

        this.commentService.deleteComment(comment.id).subscribe(() => {
          this.snackBarService.openSnackBar(
            'Kommentar erfolgreich gelöscht',
            'success',
          );
          this.loadFeed();
        });
      });
  }

  getHeadlineName(feedItem: Feed): string {
    if (feedItem.relatable_type === 'App\\Comment') {
      const comment = feedItem.relatable as Comment;

      if (comment?.user && comment.user.name) {
        return comment.user.name;
      }

      if (comment?.partner_user) {
        const firstName = comment.partner_user.first_name || '';
        const lastName = comment.partner_user.last_name || '';

        if (firstName || lastName) {
          return `${firstName} ${lastName}`.trim();
        }
      }
    }

    if (feedItem.relatable_type === 'App\\CommentSummary') {
      return 'Zusammenfassung';
    }

    if (feedItem.relatable_type === 'App\\Call') {
      return feedItem.relatable?.user_name || 'Unbekannt';
    }

    if (feedItem.relatable_type === 'App\\Email') {
      return feedItem.relatable?.mail_from_name || 'Unbekannt';
    }

    if (feedItem.relatable_type === 'App\\Document') {
      const document = feedItem.relatable as Document;

      if (document?.partner_user) {
        const firstName = document.partner_user.first_name || '';
        const lastName = document.partner_user.last_name || '';

        if (firstName || lastName) {
          return `${firstName} ${lastName}`.trim();
        }
      }

      if (document?.partner) {
        return document.partner.name;
      }

      if (document?.user) {
        return document.user.name;
      }
    }

    if (feedItem.relatable_type === 'App\\LeadFeedback') {
      const leadFeedback = feedItem.relatable as LeadFeedBacks;

      if (leadFeedback.partner?.name) {
        return leadFeedback.partner?.name;
      }
    }

    if (feedItem.relatable_type === 'App\\PartnerLeadHistory') {
      const partnerLeadHistory = feedItem.relatable as PartnerLeadHistory;

      if (partnerLeadHistory.partner_user) {
        const firstName = partnerLeadHistory.partner_user.first_name || '';
        const lastName = partnerLeadHistory.partner_user.last_name || '';

        if (firstName || lastName) {
          return `${firstName} ${lastName}`.trim();
        }
      }

      if (partnerLeadHistory.partner) {
        return partnerLeadHistory.partner.name;
      }
    }

    if (feedItem.relatable_type === 'App\\LeadHistory') {
      const leadHistory = feedItem.relatable as LeadHistory;

      if (leadHistory.user) {
        const firstName = leadHistory.user.first_name || '';
        const lastName = leadHistory.user.last_name || '';

        if (firstName || lastName) {
          return `${firstName} ${lastName}`.trim();
        }
      }
    }

    if (feedItem.relatable_type === 'App\\Reminder') {
      const reminder = feedItem.relatable as Reminder;

      if (reminder?.user && reminder.user.name) {
        return reminder.user.name;
      }

      if (reminder?.partner_user) {
        const firstName = reminder.partner_user.first_name || '';
        const lastName = reminder.partner_user.last_name || '';

        if (firstName || lastName) {
          return `${firstName} ${lastName}`.trim();
        }
      }
    }

    return 'System';
  }

  getCommentUsername(comment: Comment) {
    if (comment.user && comment.user.name) {
      return comment.user.name;
    }

    if (comment.partner_user) {
      const firstName = comment.partner_user.first_name || '';
      const lastName = comment.partner_user.last_name || '';

      if (firstName || lastName) {
        return `${firstName} ${lastName}`.trim();
      }
    }

    return 'Unbekannt';
  }

  applyFilter(filter: string): void {
    const currentFilter = this.currentFilter.value;
    if (currentFilter === filter) {
      // If clicking on the same filter, remove it
      this.currentFilter.next(null);
    } else {
      // Apply the new filter
      this.currentFilter.next(filter);
    }
  }

  openInSalesrunner(feed: Feed): void {
    if (!feed || !feed.relatable || !feed.relatable.partner_user) {
      return;
    }

    this.authService.loginAsPartnerUser(
      feed.relatable?.partner_user?.id,
      '/salesrunner/#/leads/edit/' + feed.partner_lead_id,
    );
  }

  markReminderAsDone(reminder: Reminder): void {
    const inputData: ModalInputData = {
      headline: 'Erinnerung abschließen',
      body: 'Soll die Erinnerung wirklich als erledigt markiert werden?',
      acceptButtonText: 'Ja',
      declineButtonText: 'Nein',
    };

    this.dialog
      .open(ModalComponent, {
        disableClose: true,
        data: inputData,
      })
      .afterClosed()
      .subscribe((modalResponse) => {
        if (!modalResponse || !reminder.id) return;

        reminder.reminder_status_option_id = 2; // Done
        this.reminderService
          .updateReminder(reminder.id, reminder)
          .subscribe(() => {
            this.snackBarService.openSnackBar(
              'Erinnerung abgeschlossen',
              'success',
            );
            this.loadFeed();
          });
      });
  }

  createOrUpdateReminder(existingReminder?: Reminder): void {
    const inputData = this.reminderService.getReminderModalInputData(
      existingReminder,
      this.authUser,
      this.lead,
    );

    this.dialog
      .open(ModalComponent, { disableClose: true, data: inputData })
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap((modalResponse) =>
          this.processReminderFromResponse(modalResponse, existingReminder),
        ),
        tap(
          () =>
            this.snackBarService.openSnackBar(
              'Erinnerung gespeichert',
              'success',
            ),
          () =>
            this.snackBarService.openSnackBar(
              'Fehler beim Speichern der Erinnerung',
              'warn',
            ),
        ),
      )
      .subscribe(() => this.loadFeed());
  }

  private processReminderFromResponse(
    modalResponse: any,
    existingReminder?: Reminder,
  ): Observable<Reminder> {
    const reminder = this.reminderService.createOrUpdateReminderObject(
      modalResponse,
      existingReminder,
      this.lead,
    );
    const operation =
      existingReminder && reminder.id
        ? this.reminderService.updateReminder(reminder.id, reminder)
        : this.reminderService.createReminder(reminder);

    return this.setPartnerLeadIfNeeded(reminder).pipe(
      switchMap(() => operation),
      filter((reminderRet: Reminder) => !!reminderRet?.id),
      switchMap((reminderRet: Reminder) =>
        this.reminderService.handleCommentCreation(
          reminderRet,
          modalResponse,
          this.authUser,
        ),
      ),
      map((result) => {
        if (result instanceof Comment) {
          return reminder;
        }
        return result as Reminder;
      }),
    );
  }

  private setPartnerLeadIfNeeded(reminder: Reminder): Observable<Reminder> {
    if (ApplicationHelper.applicationName === Application.Salesrunner) {
      return this.partnerLead$.pipe(
        take(1),
        map((partnerLead) => {
          if (partnerLead) {
            reminder.partner_lead_id = partnerLead.id;
            reminder.visible_in_salesrunner = true;
          }
          return reminder;
        }),
      );
    }
    return of(reminder);
  }

  protected readonly Application = Application;
  protected readonly ApplicationHelper = ApplicationHelper;
  protected readonly Math = Math;
}
