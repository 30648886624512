import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DataTablesParameters } from '../../interfaces/optionen.interface';
import { map } from 'rxjs/operators';
import { HttpHandlerService } from '../http-handler.service';
import { LeadField } from '../../models/leads/lead-field';

@Injectable({
  providedIn: 'root',
})
export class LeadFieldService {
  endpoint: string = 'lead_fields/';

  constructor(
    private httpHandler: HttpHandlerService,
    protected http: HttpClient,
  ) {}

  datatable(dataTablesParams: DataTablesParameters): Observable<any> {
    return this.httpHandler.datatable(this.endpoint, dataTablesParams).pipe(
      map((response) => {
        return {
          recordsFiltered: response.recordsFiltered,
          recordsTotal: response.recordsTotal,
          data: response.data.map((item: LeadField) => new LeadField(item)),
        };
      }),
    );
  }

  list(httpParams: any = {}): Observable<any> {
    return this.httpHandler
      .list(this.endpoint, httpParams)
      .pipe(map((response) => response.data));
  }

  getList(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  show(id: number): Observable<any> {
    return this.httpHandler.show(this.endpoint, id);
  }

  create(leadField: LeadField): Observable<any> {
    return this.httpHandler.create(this.endpoint, leadField);
  }

  update(leadField: LeadField): Observable<any> {
    return this.httpHandler.update(this.endpoint, leadField);
  }

  delete(id: number): Observable<any> {
    return this.httpHandler.delete(this.endpoint, id);
  }
}
