<ng-container *ngIf="!withOverlay">
  <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</ng-container>

<ng-container *ngIf="withOverlay">
  <ng-template cdkPortal>
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </ng-template>
</ng-container>

<ng-template #contentTemplate>
  <div
    class="feed-item-detail-content"
    [class.feed-item-detail-content--overlay]="withOverlay"
  >
    <div>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between start"
        *ngIf="withOverlay"
      >
        <div [innerHTML]="sanitizedDescription"></div>
        <div class="feed-item-timestamp">
          {{ feed.event_at | customDateTime }}
        </div>
      </div>
      <div *ngIf="!withOverlay" [innerHTML]="sanitizedDescription"></div>
    </div>

    <div fxLayout="column" fxLayoutAlign="space-between end" fxLayoutGap="10px">
      <div
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="10px"
        class="feed-item-detail-overview"
      >
        {{ getInteractionText() }}
      </div>

      <div fxLayoutGap="5px">
        <ng-container *ngFor="let action of availableActions">
          <lib-button
            *ngIf="action.type !== ButtonType.THUMBS_UP"
            [type]="action.type"
            [iconColor]="action.iconColor"
            [disabled]="action.disabled ?? false"
            [emoji]="action.emoji"
            [label]="action.label"
            [count]="action.count"
            (click)="handleClick(action)"
          ></lib-button>

          <app-tooltip
            *ngIf="action.type === ButtonType.THUMBS_UP"
            [text]="getReactionTooltipText()"
          >
            <lib-button
              cdkOverlayOrigin
              #emojiTrigger="cdkOverlayOrigin"
              [type]="action.type"
              [iconColor]="action.iconColor"
              [disabled]="action.disabled ?? false"
              [emoji]="action.emoji"
              [count]="action.count"
              [label]="action.label"
              (click)="handleClick(action)"
            ></lib-button>
          </app-tooltip>
        </ng-container>
      </div>

      <ng-container *ngIf="emojiTrigger">
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="emojiTrigger"
          [cdkConnectedOverlayHasBackdrop]="true"
          [cdkConnectedOverlayOpen]="showEmojiPicker"
          (backdropClick)="closeEmojiPicker()"
        >
          <div class="emoji-container">
            <emoji-mart
              class="emoji-picker"
              [emojiSize]="20"
              [darkMode]="false"
              [set]="'apple'"
              (emojiSelect)="onEmojiSelect($event)"
            ></emoji-mart>
          </div>
        </ng-template>
      </ng-container>

      <lib-comment
        *ngIf="showCommentInput"
        class="feed-item-detail-comment"
        [commentToEditId]="commentToEditId"
        [relatableId]="feed.relatable_id"
        [relatableType]="getRelatableType()"
        [displayVisibleInSalesrunnerButton]="lead?.status !== 3"
        [lead]="lead || null"
        (commentAdded)="reloadFeed()"
      ></lib-comment>

      <lib-feed-comment-history
        class="feed-item-detail-comment-history"
        [comments]="feed.relatable?.comments"
        [lead]="lead"
      ></lib-feed-comment-history>
    </div>
  </div>
</ng-template>
