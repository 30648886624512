import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Address } from '../../../models/address';
import { Subject } from 'rxjs';
import { AddressService } from '../../../services/address/address.service';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss'],
})
export class AddressesComponent implements OnDestroy, OnChanges {
  @Input() addresses: Address[] = [];
  @Input() inputsDisabled: boolean = false;
  @Output() addressesChanged = new EventEmitter<Address[]>();

  isDuplicate = false;
  private destroy$ = new Subject<void>();

  constructor(private addressService: AddressService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['addresses'] && this.addresses) {
      this.ensureInvoiceAddress();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private ensureInvoiceAddress() {
    const defaultAddress = this.addresses.find((address) => address.type === 1);
    const invoiceAddress = this.addresses.find((address) => address.type === 2);

    if (!invoiceAddress && defaultAddress) {
      this.addresses.push(
        new Address({ type: 2, lead_id: defaultAddress.lead_id }),
      );
      this.emitAddressesChange();
    }
  }

  deleteAddress(address: Address) {
    const index = this.addresses.findIndex((item) => item.id === address.id);
    if (index !== -1) {
      this.addresses[index] = new Address({ id: 0, type: address.type });
      if (address.id !== 0) {
        this.addressService.delete(address.id).subscribe((data: any) => {
          this.emitAddressesChange();
        });
      }
    }
  }

  copyAddress() {
    const addressType1 = this.addresses.find((address) => address.type === 1);
    if (addressType1) {
      const addressType2Index = this.addresses.findIndex(
        (address) => address.type === 2,
      );
      if (addressType2Index !== -1) {
        this.addresses[addressType2Index] = { ...addressType1, id: 0, type: 2 };
      } else {
        this.addresses.push({ ...addressType1, id: 0, type: 2 });
      }
      this.emitAddressesChange();
    }
  }

  private emitAddressesChange() {
    this.addressesChanged.emit(this.addresses);
  }

  onAddressChanged(updatedAddress: any) {
    const index = this.addresses.findIndex(
      (addr) => addr.id === updatedAddress.id,
    );
    if (index !== -1) {
      this.addresses[index] = updatedAddress;
      this.emitAddressesChange();
    }
  }
}
