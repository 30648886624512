import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ChartConfiguration, ChartEvent, ChartType } from 'chart.js';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit, OnChanges {
  @Input() labels: any = [];
  @Input() datasets: any = [];
  @Input() isPercentage = false;
  containsData = false;
  lineChartData: ChartConfiguration['data'] = { datasets: [], labels: [] };

  constructor() {
    this.updateChartOptions();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['datasets'] || changes['labels']) {
      this.updateChartData();
    }
    if (changes['isPercentage']) {
      this.updateChartOptions();
    }
  }

  updateChartData(): void {
    if (this.isPercentage) {
      const formattedDatasets = this.datasets.map((dataset: any) => ({
        ...dataset,
        yAxisID: 'y',
      }));

      this.lineChartData = {
        datasets: formattedDatasets,
        labels: this.labels,
      };
    } else {
      this.lineChartData = {
        datasets: this.datasets,
        labels: this.labels,
      };
    }

    this.containsData = !!this.lineChartData?.datasets[0]?.data?.some(
      (value) => value !== 0,
    );

    // Aktualisiere die Skalierung bei % Angaben
    if (this.isPercentage) {
      this.updatePercentageScaleOptions();
    }
  }

  private updatePercentageScaleOptions(): void {
    // Dynamische Berechnung der Skalierung
    let maxValue = 0;
    let minValue = Number.MAX_VALUE;

    this.datasets.forEach((dataset: any) => {
      const dataMax = Math.max(...dataset.data);
      const dataMin = Math.min(
        ...dataset.data.filter((v: number) => v !== null && v !== undefined),
      );
      maxValue = Math.max(maxValue, dataMax);
      minValue = Math.min(minValue, dataMin);
    });

    // Füge 40% Abstand nach oben hinzu (bis max 100 %)
    maxValue = Math.min(Math.ceil(maxValue * 1.4), 100);
    minValue = Math.max(0, Math.floor(minValue * 0.6));

    this.updateChartOptions(minValue, maxValue);
  }

  private updateChartOptions(minValue?: number, maxValue?: number): void {
    const baseOptions: ChartConfiguration['options'] = {
      elements: {
        line: {
          tension: 0.5,
        },
      },
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
        },
      },
    };

    if (this.isPercentage) {
      this.lineChartOptions = {
        ...baseOptions,
        scales: {
          y: {
            position: 'left',
            title: {
              display: true,
              text: 'Quote (%)',
            },
            min: minValue ?? 0,
            max: maxValue ?? 100,
            ticks: {
              callback: (value: any) => `${value}%`,
            },
          },
        },
        plugins: {
          ...baseOptions.plugins,
          tooltip: {
            callbacks: {
              label: (context: any) => {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                return `${label}${context.parsed.y.toFixed(1)}%`;
              },
            },
          },
        },
      };
    } else {
      this.lineChartOptions = {
        ...baseOptions,
        scales: {
          y: {
            position: 'left',
            title: {
              display: true,
              text: 'Umsatz (€)',
            },
            ticks: {
              callback: (value: any) => `${value.toLocaleString()} €`,
            },
          },
        },
        plugins: {
          ...baseOptions.plugins,
          tooltip: {
            callbacks: {
              label: (context: any) => {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                return `${label}${context.parsed.y.toLocaleString()} €`;
              },
            },
          },
        },
      };
    }
  }

  public lineChartOptions: ChartConfiguration['options'] = {};
  public lineChartType: ChartType = 'line';
}
