import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { PartnerLeadBackend } from '@lib/models/partners/partner-lead-backend';
import { PartnerLead } from '@lib/models/partner-lead';
import { PartnerLeadBackendService } from '@lib/services/partner/partner-lead-backend.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-partner-lead-backend',
  templateUrl: './partner-lead-backend.component.html',
  styleUrls: ['./partner-lead-backend.component.scss'],
})
export class PartnerLeadBackendComponent implements OnInit, OnDestroy {
  @Input() inputsDisabled: boolean = false;
  @Input() partnerLead: PartnerLead = new PartnerLead({});
  partnerLeadBackend: PartnerLeadBackend = new PartnerLeadBackend({});

  private destroy$ = new Subject<void>();

  constructor(private partnerLeadBackendService: PartnerLeadBackendService) {}

  ngOnInit() {
    this.partnerLeadBackendService
      .list({
        filter: {
          lead_type_id: this.partnerLead.lead_type_id,
        },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response) {
          this.partnerLeadBackend = response[0];

          this.partnerLeadBackend.lead_backend_elements =
            this.partnerLeadBackend.lead_backend_elements.filter(
              (element: any) =>
                element.lead_field?.type == 5 || element.lead_field?.type == 1,
            );
          const enhancedElements =
            this.partnerLeadBackend.lead_backend_elements.map(
              (element: any) => {
                let existingSelection = null;

                // Suche nach einer Übereinstimmung
                if (this.partnerLead.lead_details) {
                  existingSelection = this.partnerLead.lead_details.find(
                    (detail: any) =>
                      detail.lead_field_id == element.lead_field.id,
                  );
                }

                return {
                  ...element,
                  // Setze selectedOption basierend auf der vorhandenen Auswahl oder leer, falls keine Übereinstimmung besteht
                  selectedOption: existingSelection
                    ? existingSelection?.value
                    : '',
                };
              },
            );

          this.partnerLeadBackend.lead_backend_elements = enhancedElements;
        }
      });
  }

  onSelectedOptionChange(leadBackendElement: any) {
    if (this.partnerLead.lead_details) {
      const existingSelection = this.partnerLead.lead_details.find(
        (detail: any) =>
          detail.lead_field_id === leadBackendElement.lead_field_id,
      );

      // Übernehme die Option in den bestehenden Datensatz
      if (existingSelection) {
        existingSelection.value = leadBackendElement.selectedOption;
      }
    }
  }

  getLeadBackendElementFieldSize(fieldSize: number) {
    switch (fieldSize) {
      case 1:
        return 12;
      case 2:
        return 6;
      case 3:
        return 4;
      case 4:
        return 3;
      default:
        return 3;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
