<app-card>
  <app-card-head pageTitle="Historie">
    <ng-container
      *ngIf="
        partners.length >= 1 &&
        ApplicationHelper.applicationName === Application.Leadmanager
      "
    >
      <lib-slide-toggle
        [(ngModel)]="filterInternalData"
        (ngModelChange)="onInternalDataChange()"
        class="internal-filter-slide-toggle"
      >
        Nur interne Daten zeigen
      </lib-slide-toggle>
    </ng-container>

    <button
      style="margin-right: 10px"
      mat-stroked-button
      *ngIf="
        ApplicationHelper.applicationName === Application.Leadmanager &&
        lead?.status !== 3
      "
      (click)="createLeadSummary()"
    >
      Zusammenfassung erstellen
    </button>

    <button mat-stroked-button (click)="createOrUpdateReminder()">
      Erinnerung hinzufügen
    </button>
  </app-card-head>
  <app-card-body>
    <div class="newsfeed-container">
      <div class="feed-filter-container">
        <button
          class="feed-filter-button"
          *ngFor="let filter of getDisplayedFilters()"
          [class.active]="(currentFilter | async) === filter"
          (click)="applyFilter(filter)"
          [attr.title]="getFeedLabel(filter)"
        >
          <mat-icon>{{ getFeedIcon(filter) }}</mat-icon>
          <span
            class="filter-label-leadmanager"
            *ngIf="
              ApplicationHelper.applicationName === Application.Leadmanager
            "
            >{{ getFeedLabel(filter) }}</span
          >

          <span
            class="filter-label"
            *ngIf="
              ApplicationHelper.applicationName === Application.Salesrunner
            "
            >{{ getFeedLabel(filter) }}</span
          >
        </button>
      </div>

      <div
        class="feed-filter-container partner-filter"
        fxLayout="row"
        fxLayoutAlign="start center"
        *ngIf="
          partners.length > 0 &&
          !filterInternalData &&
          ApplicationHelper.applicationName === Application.Leadmanager
        "
      >
        <button
          class="feed-filter-button partner-button"
          *ngFor="let partner of partners"
          [class.active]="(currentPartnerFilter | async) === partner.id"
          (click)="applyPartnerFilter(partner.id)"
        >
          <mat-icon>business</mat-icon>
          <span class="filter-label">{{ partner.name }}</span>
        </button>
      </div>

      <div class="create-post-card">
        <lib-comment
          #mainComment
          [relatableId]="(lead$ | async)?.id ?? (partnerLead$ | async)?.id ?? 0"
          [relatableType]="getRelatableTypeForPost()"
          [displayVisibleInSalesrunnerButton]="(lead$ | async)?.status !== 3"
          [lead]="lead$ | async"
          [mainPost]="true"
          [partnerLead]="partnerLead$ | async"
          (commentAdded)="onCommentAdded()"
          (fileAdded)="onFileAdded()"
        ></lib-comment>
      </div>

      <div class="loading-container" *ngIf="aiIsLoading">
        <div class="loading-text">Zusammenfassung wird generiert</div>
        <div class="loading-dots">
          <div class="dot" [@dotState]="dotStates[0]"></div>
          <div class="dot" [@dotState]="dotStates[1]"></div>
          <div class="dot" [@dotState]="dotStates[2]"></div>
        </div>
      </div>

      <div class="feed-container">
        <ng-container *ngIf="filteredFeedItems$ | async as feedItems">
          <div *ngFor="let feedItem of feedItems">
            <div class="feed-item">
              <div class="feed-item-header">
                <div
                  [ngClass]="getEventTypeIconClass(feedItem.relatable_type)"
                  class="event-type-icon"
                >
                  <mat-icon style="padding-left: 3px; padding-top: 2px">{{
                    getFeedIcon(feedItem.relatable_type)
                  }}</mat-icon>
                </div>
                <div
                  class="feed-item-meta"
                  fxLayoutAlign="space-between center"
                >
                  <div>
                    <ng-container
                      *ngIf="
                        ApplicationHelper.applicationName ===
                          Application.Leadmanager &&
                          feedItem.relatable?.partner_user;
                        else noPartnerHeadline
                      "
                    >
                      <span class="feed-item-username">
                        {{ feedItem.relatable?.partner_user?.partner?.name }} -
                        <small
                          ><em>{{ getHeadlineName(feedItem) }}</em></small
                        >
                      </span>
                    </ng-container>

                    <ng-template #noPartnerHeadline>
                      <span class="feed-item-username">{{
                        getHeadlineName(feedItem)
                      }}</span>
                    </ng-template>

                    <span class="feed-description" *ngIf="feedItem.description">
                      <em>({{ feedItem.description }})</em>
                    </span>

                    <span class="feed-item-timestamp">{{
                      feedItem.event_at | customDateTime
                    }}</span>
                  </div>

                  <div
                    class="custom-badge badge-red"
                    *ngIf="
                      ApplicationHelper.applicationName ===
                        Application.Leadmanager &&
                      getFeedSalesrunnerVisibilityHint(feedItem) &&
                      lead?.status == 3
                    "
                  >
                    <span
                      [innerHTML]="getFeedSalesrunnerVisibilityHint(feedItem)"
                    ></span>
                  </div>
                </div>
              </div>
              <div class="feed-item-content">
                <ng-container [ngSwitch]="feedItem.relatable_type">
                  <ng-container *ngSwitchCase="'App\\Comment'">
                    <lib-feed-comment
                      [comment]="feedItem.relatable"
                      [lead]="lead"
                    ></lib-feed-comment>
                  </ng-container>
                  <ng-container *ngSwitchCase="'App\\Call'">
                    <lib-feed-call [call]="feedItem.relatable"></lib-feed-call>
                  </ng-container>
                  <ng-container *ngSwitchCase="'App\\Email'">
                    <lib-feed-email
                      [email]="feedItem.relatable"
                      [feed]="feedItem"
                    ></lib-feed-email>
                  </ng-container>
                  <ng-container *ngSwitchCase="'App\\Document'">
                    <lib-feed-document
                      [document]="feedItem.relatable"
                      [lead]="lead"
                    ></lib-feed-document>
                  </ng-container>
                  <ng-container *ngSwitchCase="'App\\LeadFeedback'">
                    <lib-feed-feedback
                      (feedbackChanged)="loadFeed()"
                      [leadFeedback]="feedItem.relatable"
                    ></lib-feed-feedback>
                  </ng-container>
                  <ng-container *ngSwitchCase="'App\\LeadResponseHistory'">
                    <lib-feed-lead-response
                      [leadResponse]="feedItem.relatable"
                    ></lib-feed-lead-response>
                  </ng-container>
                  <ng-container *ngSwitchCase="'App\\PartnerLeadHistory'">
                    <lib-feed-status-history
                      [partnerLeadHistory]="feedItem.relatable"
                    ></lib-feed-status-history>
                  </ng-container>
                  <ng-container *ngSwitchCase="'App\\Reminder'">
                    <lib-feed-reminder
                      [reminder]="feedItem.relatable"
                      [lead]="lead"
                    ></lib-feed-reminder>
                  </ng-container>
                  <ng-container *ngSwitchCase="'App\\LeadHistory'">
                    <lib-feed-status-history
                      [leadHistory]="feedItem.relatable"
                    ></lib-feed-status-history>
                  </ng-container>
                  <ng-container *ngSwitchCase="'App\\CommentSummary'">
                    <lib-feed-comment-summary
                      [commentSummary]="feedItem.relatable"
                    ></lib-feed-comment-summary>
                  </ng-container>
                  <ng-container *ngSwitchCase="'App\\CommentWebsite'">
                    <lib-feed-comment-website
                      [lead]="lead"
                      [commentWebsite]="feedItem.relatable"
                    ></lib-feed-comment-website>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    {{ feedItem.relatable?.comment || 'Unbekannt' }}
                  </ng-container>
                </ng-container>
              </div>

              <div class="feed-item-actions">
                <div class="left-actions">
                  <lib-reaction
                    [relatableId]="feedItem.relatable_id"
                    [relatableType]="getRelatableType(feedItem)"
                  ></lib-reaction>
                </div>
                <div class="right-actions">
                  <button
                    *ngIf="feedItem.relatable_type === 'App\\Email'"
                    class="action-button comment-button"
                    (click)="displayEmail(feedItem)"
                  >
                    <mat-icon>mail</mat-icon>
                    <span>E-Mail anzeigen und erneut versenden</span>
                  </button>

                  <button
                    *ngIf="feedItem.relatable_type === 'App\\LeadFeedback'"
                    class="action-button comment-button"
                    (click)="showLeadFeedbackDetails(feedItem)"
                  >
                    <mat-icon>pageview</mat-icon>
                    <span>Details anzeigen</span>
                  </button>

                  <button
                    *ngIf="
                      feedItem.relatable_type === 'App\\Reminder' &&
                      feedItem.relatable?.reminder_status_option_id === 1
                    "
                    class="action-button comment-button"
                    (click)="createOrUpdateReminder(feedItem.relatable)"
                  >
                    <mat-icon>edit</mat-icon>
                    <span>Erinnerung bearbeiten</span>
                  </button>

                  <button
                    *ngIf="
                      feedItem.relatable_type === 'App\\Reminder' &&
                      feedItem.relatable?.reminder_status_option_id === 1
                    "
                    class="action-button comment-button"
                    (click)="markReminderAsDone(feedItem.relatable)"
                  >
                    <mat-icon>check</mat-icon>
                    <span>Als erledigt markieren</span>
                  </button>

                  <button
                    *ngIf="
                      ApplicationHelper.applicationName ===
                        Application.Leadmanager &&
                      feedItem.partner_lead_id &&
                      feedItem.relatable?.partner_user
                    "
                    class="action-button comment-button"
                    (click)="openInSalesrunner(feedItem)"
                  >
                    <mat-icon>open_in_browser</mat-icon>
                    <span>Im Salesrunner anzeigen</span>
                  </button>

                  <button
                    *ngIf="feedItem.relatable_type !== 'App\\CommentSummary'"
                    class="action-button comment-button"
                    (click)="toggleCommentInput(feedItem)"
                  >
                    <mat-icon>comment</mat-icon>
                    <span>Kommentar</span>
                  </button>

                  <button
                    *ngIf="
                      feedItem.relatable_type === 'App\\Comment' &&
                      userCanEditRelatable(feedItem.relatable)
                    "
                    class="action-button comment-button"
                    (click)="deleteComment(feedItem.relatable)"
                  >
                    <mat-icon>delete</mat-icon>
                    <span>Löschen</span>
                  </button>

                  <!-- Im Leadmanager soll jeder Kommentar bearbeiten werden können. Betrifft nur das bearbeiten, damit der sichtbarkeitsmodifikator geändert werden kann. Deshlab Prüfugn hier im Template-->
                  <button
                    *ngIf="
                      feedItem.relatable_type === 'App\\Comment' &&
                      (userCanEditRelatable(feedItem.relatable) ||
                        ApplicationHelper.applicationName ===
                          Application.Leadmanager)
                    "
                    class="action-button comment-button"
                    (click)="editComment(feedItem, true)"
                  >
                    <mat-icon>edit</mat-icon>
                    <span>Bearbeiten</span>
                  </button>

                  <button
                    *ngIf="
                      feedItem.relatable_type === 'App\\CommentSummary' &&
                      ApplicationHelper.applicationName ===
                        Application.Leadmanager
                    "
                    class="action-button comment-button"
                    (click)="editCommentSummary(feedItem)"
                  >
                    <mat-icon>edit</mat-icon>
                    <span>Bearbeiten</span>
                  </button>
                </div>
              </div>
              <div
                *ngIf="feedItem.showCommentInput"
                style="
                  padding-left: 10px;
                  padding-right: 10px;
                  padding-bottom: 10px;
                "
              >
                <lib-comment
                  *ngIf="feedItem.showCommentInput || aiIsLoading"
                  [relatableId]="feedItem.relatable_id"
                  [relatableType]="getRelatableType(feedItem)"
                  [commentToEditId]="commentToEditId"
                  [disableCommentInput]="disableCommentInput"
                  [commentSummaryToEdit]="commentSummaryToEdit"
                  [displayVisibleInSalesrunnerButton]="
                    (lead$ | async)?.status !== 3
                  "
                  [lead]="lead$ | async"
                  [partnerLead]="partnerLead$ | async"
                  (commentAdded)="onCommentAdded()"
                ></lib-comment>
              </div>
              <ng-container *ngIf="feedItem.relatable?.comments?.length">
                <div class="comments-section">
                  <ng-container *ngIf="feedItem.relatable?.comments?.length">
                    <button
                      mat-button
                      class="action-button"
                      (click)="minimizeComments(feedItem)"
                    >
                      <mat-icon>unfold_less</mat-icon>
                      <span
                        *ngIf="feedItem.minimizeComments"
                        class="minimize-comments"
                        >erweitern</span
                      >
                      <span
                        *ngIf="!feedItem.minimizeComments"
                        class="minimize-comments"
                        >minimieren</span
                      >
                    </button>

                    <div
                      class="comments-section"
                      *ngIf="!feedItem.minimizeComments"
                    >
                      <ng-container
                        *ngTemplateOutlet="
                          commentTree;
                          context: {
                            comments: feedItem.relatable?.comments,
                            level: 0,
                            feedItemId: feedItem?.id
                          }
                        "
                      ></ng-container>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div></div
        ></ng-container>
      </div>
    </div>
  </app-card-body>
</app-card>

<ng-template #commentTree let-comments="comments" let-level="level">
  <div class="comments-container">
    <div
      *ngFor="let comment of comments"
      class="comment-item"
      [class.nested-comment]="level > 0"
    >
      <div class="comment-content">
        <div class="comment-header">
          <div class="comment-header-left">
            <span class="comment-username">{{
              getCommentUsername(comment)
            }}</span>
            <span class="comment-date">{{
              comment.created_at | customDateTime
            }}</span>
          </div>
          <div class="comment-header-right" fxLayoutGap="10px">
            <span class="comment-edited" *ngIf="comment?.edited_at">
              <em>bearbeitet</em>
            </span>

            <div
              class="custom-badge badge-red"
              *ngIf="
                ApplicationHelper.applicationName === Application.Leadmanager &&
                getFeedSalesrunnerVisibilityHintByComment(comment)
              "
            >
              <span
                [innerHTML]="getFeedSalesrunnerVisibilityHintByComment(comment)"
              ></span>
            </div>
          </div>
        </div>

        <p class="comment-text">{{ comment.comment }}</p>
        <div class="comment-actions" fxLayout="row" fxLayoutAlign="end center">
          <button
            class="action-button reply-button"
            (click)="toggleCommentInput(comment)"
          >
            Antworten...
          </button>

          <button
            *ngIf="userCanEditRelatable(comment)"
            class="action-button reply-button"
            (click)="editComment(comment)"
          >
            Bearbeiten
          </button>

          <button
            *ngIf="userCanEditRelatable(comment)"
            class="action-button reply-button"
            (click)="deleteComment(comment)"
          >
            Löschen
          </button>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" style="height: 20px">
        <lib-reaction
          style="margin-top: -25px"
          [relatableId]="comment.id"
          [relatableType]="'Comment'"
        ></lib-reaction>
      </div>

      <div style="margin-bottom: 10px">
        <lib-comment
          *ngIf="comment.showCommentInput"
          [relatableId]="comment.id"
          [relatableType]="'App\\Comment'"
          [commentToEditId]="commentToEditId"
          [disableCommentInput]="disableCommentInput"
          [displayVisibleInSalesrunnerButton]="(lead$ | async)?.status !== 3"
          [lead]="lead$ | async"
          [partnerLead]="partnerLead$ | async"
          (commentAdded)="onCommentAdded()"
        >
        </lib-comment>
      </div>

      <ng-container *ngIf="comment.comments && comment.comments.length">
        <ng-container
          *ngTemplateOutlet="
            commentTree;
            context: { comments: comment.comments, level: level + 1 }
          "
        >
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
