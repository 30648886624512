import { User } from './user';
import { PartnerUser } from './partners/partner-user';

export class Reaction {
  id?: number = 0;
  user_id?: number = 0;
  user: User = new User({});
  partner_user_id?: number = 0;
  partner_user: PartnerUser = new PartnerUser({});
  relatable_type: string = '';
  relatable_id: number = 0;
  emoji: string = '';
  created_at?: string = '';
  updated_at?: string = '';
}
