import { Pipe, PipeTransform } from '@angular/core';
import {
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
  LowerCasePipe,
  PercentPipe,
  UpperCasePipe,
} from '@angular/common';

@Pipe({
  name: 'dynamicPipe',
})
export class DynamicPipe implements PipeTransform {
  transform(value: any, pipeInfo: any): any {
    if (!pipeInfo) {
      return value;
    }

    let pipeInstance: PipeTransform;

    switch (pipeInfo.name) {
      case 'date':
        pipeInstance = new DatePipe(pipeInfo.args?.locale || 'en-US');
        break;
      case 'currency':
        pipeInstance = new CurrencyPipe(pipeInfo.args?.locale || 'de-DE');
        break;
      case 'decimal':
        pipeInstance = new DecimalPipe(pipeInfo.args?.locale || 'en-US');
        break;
      case 'percent':
        pipeInstance = new PercentPipe(pipeInfo.args?.locale || 'en-US');
        break;
      case 'lowercase':
        pipeInstance = new LowerCasePipe();
        break;
      case 'uppercase':
        pipeInstance = new UpperCasePipe();
        break;
      default:
        throw new Error(`Unbekannte Pipe: ${pipeInfo.name}`);
    }

    return pipeInstance.transform(value, ...(pipeInfo.args || []));
  }
}
