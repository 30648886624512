import { Partner } from '../partner';

export class PartnerUser {
  id: number = 0;
  email: string = '';
  email_verified_at: string = '';
  registration_email_send_at: string = '';
  first_name: string = '';
  last_name: string = '';
  name: string = '';
  phone: string = '';
  remember_token: string = '';
  salutation: string = '';
  sipgate_auth: string = '';
  sipgate_device_id: string = '';
  sipgate_user_id: number = 0;
  group: string = '';
  active: number = 0;
  invoice_response_email: number = 0;
  partner_id: number = 0;
  password: string = '';
  password_confirmation: string = '';
  cost_type: string = '';
  invoice_and_performance_data: number = 0;
  is_admin: boolean = false;
  has_inspector_targetings: boolean = false;

  partner?: Partner = new Partner({});

  constructor(partnerUser: PartnerUser | any = {}) {
    Object.assign(this, partnerUser);
  }
}
