import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-partner-bill-delete-modal',
  templateUrl: './partner-bill-delete-modal.component.html',
  styleUrls: ['./partner-bill-delete-modal.component.scss'],
})
export class PartnerBillDeleteModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  deleteBill() {
    // call back to parent
    this.data.onSubmitEvent(this.data.partnerBill);
  }
}
