import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { SplitViewStateService } from '../../../services/split-view-state.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PanelStateService } from '../../../services/panel-state.service';
import { LeadListDetailViewComponent } from './lead-list-detail-view.component';
import { ModalInputData } from '@lib/models/modal';
import { ModalComponent } from '@lib/components/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-lead-list-new',
  templateUrl: './lead-list-new.component.html',
  styleUrls: ['./lead-list-new.component.scss'],
})
export class LeadListNewComponent implements OnInit, OnDestroy {
  @ViewChild(LeadListDetailViewComponent)
  detailView!: LeadListDetailViewComponent;

  selectedLeadId$ = this.leadStateService.selectedLeadId$;
  private destroy$ = new Subject<void>();

  get initialPanelWidth(): number {
    return this.panelStateService.getCurrentWidth();
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private leadStateService: SplitViewStateService,
    private panelStateService: PanelStateService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        if (params['id']) {
          this.leadStateService.setSelectedLead(Number(params['id']));
          this.panelStateService.setWidth(
            this.panelStateService.getCurrentWidth(),
            true,
          );
        } else {
          this.leadStateService.setSelectedLead(null);
        }
      });

    this.selectedLeadId$.pipe(takeUntil(this.destroy$)).subscribe((id) => {
      if (id !== null) {
        this.panelStateService.ensureDetailViewWidth();
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { id },
          queryParamsHandling: 'merge',
        });
      } else {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {},
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onWidthChange(width: number) {
    this.panelStateService.setWidth(width);
  }

  async onLeadSelectionChange(newLeadId: number | null): Promise<boolean> {
    if (this.detailView?.feedComponent) {
      const hasUnsavedComments =
        await this.detailView.feedComponent.checkForUnsavedComments();
      if (hasUnsavedComments) {
        const shouldProceed = await this.showUnsavedCommentWarning();
        if (!shouldProceed) {
          return false;
        }
      }
    }

    this.leadStateService.setSelectedLead(newLeadId);
    return true;
  }

  private showUnsavedCommentWarning(): Promise<boolean> {
    const inputData: ModalInputData = {
      headline: 'Ungespeicherter Kommentar',
      body: 'Es gibt noch mindestens einen ungespeicherten Kommentar. Möchten Sie trotzdem fortfahren?',
      acceptButtonText: 'Ja',
      declineButtonText: 'Nein',
    };

    const dialogRef = this.dialog.open(ModalComponent, {
      disableClose: true,
      data: inputData,
    });

    return firstValueFrom(dialogRef.afterClosed());
  }
}
