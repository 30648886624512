import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Lead } from '../../models/leads/lead';
import { ModalService } from '../../services/modal.service';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { PriceNumberFormatHelper } from '../../helpers/price-number-format-helper';
import { LeadFeedBacks } from '../../models/leads/lead-feed-backs';
import { ValidationPatterns } from '../../validations/validation-pattern';
import { LeadFeedbackService } from '../../services/lead-feedback.service';

@Component({
  selector: 'app-lead-feedback-modal',
  templateUrl: './lead-feedback-modal.component.html',
  styleUrls: ['./lead-feedback-modal.component.scss'],
})
export class LeadFeedbackModalComponent implements OnInit {
  feedbackForm: FormGroup = new FormGroup({});
  lead: Lead = new Lead({});
  public show$: Observable<boolean> = this.modalService.show$;
  @Output() handleDelete = new EventEmitter<string>();

  constructor(
    private modalService: ModalService,
    private leadFeedBacksServices: LeadFeedbackService,
    private formBuilder: FormBuilder,
    private snackbarService: SnackBarService,
    public dialogRef: MatDialogRef<LeadFeedbackModalComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: Lead,
  ) {
    this.lead = this.inputData;
  }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.initializeNewFeedback();

    const feedbackFormGroups = this.lead.lead_feedbacks.map(
      (feedback: LeadFeedBacks) => {
        return this.formBuilder.group({
          partnerId: [feedback.partner_id || null],
          partnerLeadId: [feedback.partner_lead_id || null],
          leadId: [feedback.lead_id || null],
          partnerContactedLead: [feedback.contact || null],
          firstContactPartner: [feedback.first_contact || null],
          offerAmount: [
            PriceNumberFormatHelper.replaceDotWithComma(
              feedback.offer_amount,
            ) || null,
            Validators.pattern(ValidationPatterns.pricePattern),
          ],
          partnerLeadDecision: [feedback.decision || null],
          leadDecisionReason: [feedback.decision_reason || null],
        });
      },
    );

    this.feedbackForm = this.formBuilder.group({
      feedbacks: this.formBuilder.array(feedbackFormGroups),
    });
  }

  initializeNewFeedback() {
    for (let partner of this.lead.partner_leads) {
      const partnerFeedback = this.lead.lead_feedbacks.find(
        (feedback: LeadFeedBacks) => feedback.partner_id === partner.partner_id,
      );

      // Wenn Feedback noch nicht gesetzt ist, dann gibt es noch kein Feedback und es soll ein neues angelegt werden
      if (!partnerFeedback) {
        const feedback = new LeadFeedBacks({
          partner_id: partner.partner_id,
          partner_lead_id: partner.id,
          lead_id: this.lead.id,
        });
        this.lead.lead_feedbacks.push(feedback);
      }
    }
  }

  save() {
    if (this.feedbackForm.valid) {
      this.setFeedbackIntoLead();

      this.leadFeedBacksServices
        .create({ lead_feedbacks: this.lead.lead_feedbacks })
        .subscribe((data: any) => {
          if (data != null) {
            this.dialogRef.close('create');
            this.snackbarService.openSnackBar(
              'Feedback ist erfolgreich speichert',
              'success',
            );
          } else {
            this.dialogRef.close('failed');
            this.snackbarService.openSnackBar('Etwas schief gelaufen', 'warn');
          }
        });
    }
  }

  setFeedbackIntoLead() {
    const feedbacksArray = this.feedbackForm.get('feedbacks') as FormArray;

    feedbacksArray.controls.forEach((feedbackGroup, index) => {
      const feedbackData = feedbackGroup.value;
      if (this.lead.lead_feedbacks[index]) {
        this.lead.lead_feedbacks[index].partner_id = feedbackData.partnerId;
        this.lead.lead_feedbacks[index].partner_lead_id =
          feedbackData.partnerLeadId;
        this.lead.lead_feedbacks[index].lead_id = feedbackData.leadId;
        this.lead.lead_feedbacks[index].contact =
          feedbackData.partnerContactedLead;
        this.lead.lead_feedbacks[index].first_contact =
          feedbackData.firstContactPartner;
        this.lead.lead_feedbacks[index].offer_amount =
          PriceNumberFormatHelper.prepareForDatabase(feedbackData.offerAmount);
        this.lead.lead_feedbacks[index].decision =
          feedbackData.partnerLeadDecision;
        this.lead.lead_feedbacks[index].decision_reason =
          feedbackData.leadDecisionReason;
      }
    });
  }

  getTitle(partner_lead_id: any) {
    let partnerInfo;
    partnerInfo = this.lead.partner_leads.find(
      (item: any) => item.id === partner_lead_id,
    );
    if (partnerInfo && partnerInfo.partner) {
      return partnerInfo.partner.name;
    }
    return '';
  }
}
