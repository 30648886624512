import { ErrorHandler, Injectable, isDevMode } from '@angular/core';
import { ErrorLoggingService } from './services/error-logging.service';
import { EnvironmentService } from './services/environment.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private errorLoggingService: ErrorLoggingService,
    private environmentService: EnvironmentService,
  ) {}

  handleError(error: Error) {
    const errorDetails = {
      message: error.message,
      stack: error.stack,
      timestamp: new Date().toISOString(),
      userAgent: navigator.userAgent,
      url: window.location.href,
      type: error.name || 'Error',
      // Zusätzliche Kontext-Informationen
      zone: (error as any)?.ngDebugContext?.view?.component?.constructor?.name,
      componentStack: this.getComponentStack(error),
    };

    this.errorLoggingService.logError(errorDetails).subscribe({
      next: () => {
        if (!this.environmentService.production) {
          console.log('Error logged successfully');
        }
      },
      error: (err) => {
        if (
          !err.message.includes('rate limit') &&
          !this.environmentService.production
        ) {
          console.error('Failed to log error:', err);
        }
      },
    });

    // In Entwicklungsumgebung den Original-Fehler werfen
    if (!this.environmentService.production) {
      throw error;
    }
  }

  private getComponentStack(error: any): string {
    if (error.ngDebugContext) {
      let componentStack = '';
      let currentContext = error.ngDebugContext;
      while (currentContext) {
        if (currentContext.component) {
          componentStack += `\n${currentContext.component.constructor.name}`;
        }
        currentContext = currentContext.parent;
      }
      return componentStack;
    }
    return '';
  }
}
