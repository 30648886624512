<div
  class="feed-item"
  [class.comment-type]="feed.relatable_type === 'App\\Comment'"
  [class.expanded]="isExpanded"
>
  <div
    class="feed-item-container"
    fxLayout="row"
    fxLayoutAlign="space-between start"
  >
    <div
      class="feed-item-left"
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
    >
      <lib-icon
        class="feed-item-icon"
        size="md"
        (click)="onToggleExpand()"
        [badges]="getBadges()"
        [name]="getIcon()"
        [animated]="true"
      ></lib-icon>

      <div class="feed-item-content" [class.no-description]="!shortDescription">
        <b class="feed-item-headline">{{ getHeadline() }}</b>
        <div
          *ngIf="!isExpanded && shortDescription"
          [innerHTML]="shortDescription"
          class="feed-item-short-description"
        ></div>
      </div>
    </div>

    <div
      class="feed-item-right"
      fxLayout="column"
      fxLayoutAlign="space-between end"
    >
      <div fxLayout="row" fxLayoutAlign="end start">
        <div class="feed-item-timestamp">
          {{ feed.event_at | customDateTime }}
        </div>
        <mat-icon class="feed-item-expand-icon" (click)="onToggleExpand()">
          {{ isExpanded ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </div>

      <div
        class="feed-item-quick-actions"
        fxLayoutGap="5px"
        *ngIf="!isExpanded"
      >
        <ng-container *ngFor="let action of availableActions">
          <app-tooltip
            *ngIf="action.type !== ButtonType.THUMBS_UP"
            [text]="action.label ?? ''"
          >
            <lib-button
              [type]="action.type"
              [iconColor]="action.iconColor"
              [disabled]="action.disabled ?? false"
              [count]="action.count"
              (click)="handleClick(action)"
            ></lib-button>
          </app-tooltip>

          <app-tooltip
            *ngIf="action.type === ButtonType.THUMBS_UP"
            [text]="getReactionTooltipText()"
          >
            <lib-button
              cdkOverlayOrigin
              #emojiTrigger="cdkOverlayOrigin"
              [type]="action.type"
              [iconColor]="action.iconColor"
              [disabled]="action.disabled ?? false"
              [emoji]="action.emoji"
              [count]="action.count"
              (click)="handleClick(action)"
            ></lib-button>
          </app-tooltip>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Emoji Picker -->
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="emojiTrigger"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOpen]="showEmojiPicker"
    (backdropClick)="closeEmojiPicker()"
  >
    <div class="emoji-container">
      <emoji-mart
        class="emoji-picker"
        [emojiSize]="20"
        [darkMode]="false"
        [set]="'apple'"
        (emojiSelect)="onEmojiSelect($event)"
      ></emoji-mart>
    </div>
  </ng-template>

  <div class="feed-item-detail-view">
    <lib-feed-item-detail
      [feed]="feed"
      [lead]="lead"
      [commentToEditId]="commentToEditId"
      *ngIf="isExpanded"
      [withOverlay]="false"
      [showCommentInput]="isExpanded && showDetailComment"
    ></lib-feed-item-detail>
  </div>
</div>
