import { Component } from '@angular/core';

@Component({
  selector: 'lib-basic-badge-example',
  template: BasicBadgeExample.HTML_CODE,
})
export class BasicBadgeExample {
  static HTML_CODE = `
     <div class="flex gap-2 flex-wrap">
      <span class="custom-badge badge-blue">Info</span>
      <span class="custom-badge badge-purple">Feature</span>
      <span class="custom-badge badge-yellow">Warning</span>
      <span class="custom-badge badge-red">Error</span>
      <span class="custom-badge badge-green">Success</span>
      <span class="custom-badge badge-gray">Default</span>
    </div>
  `;
}
