<div class="d-flex justify-content-center align-items-center vh-100">
  <div class="col-sm-8 col-md-6 col-lg-4 col-xl-3">
    <div class="card">
      <div class="card-body p-5">
        <form
          class="text-center mb-4"
          [formGroup]="resetPasswordForm"
          fxLayout="column"
          fxLayoutAlign="space-around center"
        >
          <h4 class="fw-bolder fs-3">Salesrunner</h4>
          <h4 class="fw-bolder fs-4">
            <span *ngIf="resetPasswordMode">Passwort zurücksetzen</span>
            <span *ngIf="!resetPasswordMode">Passwort vergeben</span>
          </h4>

          <em style="padding: 20px">
            Das <span *ngIf="resetPasswordMode">neue</span> Passwort muss
            mindestens aus 8 Zeichen, einem Großbuchstaben, einem
            Kleinbuchstaben, einer Zahl und einem Sonderzeichen bestehen.
          </em>

          <mat-form-field fxFill>
            <mat-label>Neues Passwort eingeben</mat-label>
            <input matInput type="password" formControlName="password" />
          </mat-form-field>

          <mat-form-field fxFill>
            <mat-label>Passwort bestätigen</mat-label>
            <input
              matInput
              type="password"
              formControlName="passwordConfirmation"
            />
            <mat-error
              *ngIf="resetPasswordForm.get('passwordConfirmation')?.errors?.['mustMatchPassword']"
            >
              Passwörter stimmen nicht überein!
            </mat-error>
          </mat-form-field>

          <div class="form-group text-center">
            <button
              class="btn btn-success btn-block btn-full-width"
              [disabled]="!resetPasswordForm.valid"
              (click)="submit()"
            >
              <span *ngIf="resetPasswordMode">Zurücksetzen</span>
              <span *ngIf="!resetPasswordMode">Speichern</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
