<div *ngIf="document" class="document-info">
  <a
    href="javascript:void(0)"
    (click)="editFile(document)"
    class="document-link"
  >
    <div class="icon-container" [innerHTML]="getFileIcon()"></div>
    <div class="file-details">
      <span class="file-name">{{ document.original_file_name }}</span>
      <span class="file-size">{{ formatFileSize(document.size) }}</span>
    </div>
  </a>
</div>
