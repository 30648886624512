export const PerformanceTiles = [
  {
    name: 'lead_count',
    value: 0,
    title: 'Erhaltene Leads',
    icon: 'contacts',
    class: 'md-bg-blue-600',
    ranking: 0,
    hide: 1,
  },
  {
    name: 'success_count',
    value: 0,
    title: 'Abgeschlossene Leads',
    icon: 'stars',
    class: 'md-bg-green-600',
    ranking: 0,
    hide: 1,
  },
  {
    name: 'success_quote',
    value: '',
    title: 'Erfolgsquote',
    icon: 'trending_up',
    class: 'md-bg-light-green-600',
    ranking: 0,
    hide: 1,
  },
  {
    name: 'reaction_time_median',
    value: '',
    title: 'Reaktionszeit (Median/Zentralwert)',
    icon: 'speed',
    class: 'md-bg-amber-600',
    ranking: 0,
    hide: 2,
  },
  {
    name: 'accepted_rate',
    value: 0,
    title: 'Annahmequote',
    icon: 'done_outline',
    class: 'md-bg-amber-600',
    ranking: 0,
    hide: 2,
  },
  {
    name: 'revenue',
    value: 0,
    title: 'Umsatz',
    icon: 'euro',
    class: 'md-bg-amber-600',
    ranking: 0,
    hide: 2,
  },
];
