import { Component, Input } from '@angular/core';
import { LeadResponse } from '../../../models/lead-response';

@Component({
  selector: 'lib-feed-lead-response',
  templateUrl: './feed-lead-response.component.html',
  styleUrls: ['./feed-lead-response.component.scss'],
})
export class FeedLeadResponseComponent {
  @Input() leadResponse: LeadResponse | null = null;
}
