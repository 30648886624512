<form [formGroup]="form" class="condition-groups">
  <div formArrayName="conditionGroups" class="groups-container">
    <ng-container
      *ngFor="
        let group of conditionGroups.controls;
        let groupIndex = index;
        trackBy: trackByFn
      "
    >
      <div [formGroupName]="groupIndex" class="group-container">
        <div class="group-header">
          <div class="group-actions">
            <button
              *ngIf="displayCopyGroup"
              mat-icon-button
              class="action-button copy-button"
              (click)="copyGroup(groupIndex, $event)"
            >
              <mat-icon>file_copy</mat-icon>
            </button>
            <button
              mat-icon-button
              class="action-button remove-button"
              (click)="removeGroup(groupIndex, $event)"
            >
              <mat-icon>cancel</mat-icon>
            </button>
          </div>
        </div>

        <div formArrayName="conditions" class="conditions-container">
          <ng-container
            *ngFor="
              let condition of getConditionsArray(groupIndex).controls;
              let conditionIndex = index;
              trackBy: trackByFn
            "
          >
            <div
              [formGroupName]="conditionIndex"
              fxLayout="row"
              fxLayoutAlign="space-between center"
              fxLayoutGap="16px"
              class="condition-row"
            >
              <lib-condition
                fxFlex
                [condition]="condition.value"
                [propertyOptions]="propertyOptions"
                [leadDetailValueOptions]="leadDetailValueOptions"
                [operatorOptions]="operatorOptions"
                (conditionChange)="
                  onConditionChanged($event, groupIndex, conditionIndex)
                "
              >
              </lib-condition>

              <div
                fxLayout="row"
                fxLayoutAlign="end center"
                fxLayoutGap="8px"
                class="actions"
              >
                <span
                  *ngIf="!isLastCondition(groupIndex, conditionIndex)"
                  class="connector"
                  >UND</span
                >
                <button
                  mat-icon-button
                  (click)="removeCondition(groupIndex, conditionIndex)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          </ng-container>

          <div class="add-condition-container">
            <button
              mat-stroked-button
              color="primary"
              (click)="addCondition(groupIndex)"
              class="add-button"
            >
              Bedingung hinzufügen
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="!isLastGroup(groupIndex)" class="group-separator">oder</div>
    </ng-container>
  </div>
</form>

<div class="add-group-container">
  <button type="button" class="btn btn-outline-info" (click)="addGroup($event)">
    Neue Gruppe hinzufügen
  </button>
</div>
