<div class="feed-filter-container">
  <button
    *ngFor="let filter of displayedFilters"
    class="feed-filter-button"
    [class.active]="filter.type === currentFilter"
    (click)="toggleFilter(filter)"
  >
    <lib-icon [name]="filter.icon"></lib-icon>
    <span class="filter-label" [class.filter-label-leadmanager]="isLeadmanager">
      {{ filter.label }}
    </span>
  </button>
</div>
