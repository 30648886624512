// phone-user-status.component.ts
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CallData, UserCallStatus } from '../../models/call';
import { SseService } from '../../services/sse.service';
import { UrlHelper } from '../../helpers/url-helper';

@Component({
  selector: 'lib-phone-user-status',
  templateUrl: './phone-user-status.component.html',
  styleUrls: ['./phone-user-status.component.scss'],
})
export class PhoneUserStatusComponent implements OnInit, OnDestroy {
  users: UserCallStatus[] = [];
  incomingCalls: any[] = [];
  private destroy$ = new Subject<void>();

  constructor(private sseService: SseService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.sseService
      .getCallStatus()
      .pipe(
        takeUntil(this.destroy$),
        tap((callData) => this.sseService.callDataSubject.next(callData)),
      )
      .subscribe();

    this.sseService.callData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((callData: CallData) => {
        if (callData?.users) {
          this.users = callData.users;
        }
        this.incomingCalls = callData?.incoming || [];
        this.cdr.markForCheck();
      });

    interval(1000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.cdr.markForCheck());
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get hasIncomingCalls(): boolean {
    return this.incomingCalls?.length > 0;
  }
  trackByUserId(index: number, user: UserCallStatus): number {
    return user.user_id;
  }

  hasActiveLead(user: UserCallStatus | any): boolean {
    return user?.active_call?.lead?.id || user?.lead?.id;
  }

  getChipClasses(user: UserCallStatus): string[] {
    const classes = ['status-chip'];

    if (this.hasActiveLead(user)) {
      classes.push('has-lead');
    }

    if (user.status === 'ringing_call') {
      classes.push('ringing');
    }

    switch (user.status) {
      case 'available':
        classes.push('status-available');
        break;
      case 'editing':
        classes.push('status-editing');
        break;
      case 'offline':
        classes.push('status-offline');
        break;
      case 'active_call':
      case 'ringing_call':
        classes.push('status-active');
        break;
      default:
        classes.push('status-offline');
    }

    return classes;
  }

  getStatusIcon(user: UserCallStatus): string {
    if (user.direction === 'in') return 'call_received';
    if (user.direction === 'out') return 'call_made';
    return '';
  }

  getTooltipContent(user: UserCallStatus): string {
    const lead = user.lead;
    if (!lead) return `${user.first_name} ${user.last_name}`;

    const details = [];
    if (lead.campaign_name) {
      details.push(`Kampagne: ${lead.campaign_name}`);
    }
    if (lead.domain) {
      details.push(`Domain: ${lead.domain}`);
    }
    if (lead.address) {
      const { company_name, first_name, last_name, address } = lead.address;
      const name = [company_name, first_name, last_name]
        .filter(Boolean)
        .join(' ');
      details.push(`Name: ${name}`);
      if (address) details.push(`Adresse: ${address}`);
    }
    return details.join('\n');
  }

  getCallDisplayName(call: any): string {
    const address = call.lead?.address;
    if (!address) return call.from;

    const name =
      address.company_name ||
      `${address.first_name} ${address.last_name}`.trim();
    return `${name} - ${call.from}`;
  }

  onCallClick(call: any): void {
    if (call.lead?.id) {
      const url = UrlHelper.getUrlWithBasePath(`/leads/edit/${call.lead.id}`);
      window.open(url, '_blank');
    }
  }

  getCallTooltip(call: any): string {
    return this.getTooltipContent({
      active_call: { lead: call.lead },
    } as UserCallStatus);
  }
}
