<div class="phone-status-container">
  <div class="user-chips-container" *ngIf="users?.length">
    <mat-chip-listbox>
      <mat-chip
        *ngFor="let user of users; trackBy: trackByUserId"
        [ngClass]="getChipClasses(user)"
        [matTooltip]="getTooltipContent(user)"
        [matTooltipClass]="'status-tooltip'"
        (click)="onCallClick(user)"
      >
        <mat-icon *ngIf="hasActiveLead(user)" class="user-icon"
          >person</mat-icon
        >
        <mat-icon *ngIf="user.direction" class="status-icon">{{
          getStatusIcon(user)
        }}</mat-icon>
        <mat-icon *ngIf="user.status === 'ringing_call'" class="status-icon"
          >phone_ringing</mat-icon
        >
        <span class="phone-user-name">
          {{ user.first_name }}
          <span class="duration" *ngIf="user.duration">
            ({{ user.duration | readableTime }})
          </span>
        </span>
      </mat-chip>
    </mat-chip-listbox>
  </div>

  <!-- Incoming Calls Section -->
  <div class="incoming-calls-container" *ngIf="hasIncomingCalls">
    <span class="incoming-label">Eingehend:</span>
    <mat-chip-listbox>
      <mat-chip
        *ngFor="let call of incomingCalls"
        class="incoming-chip"
        [matTooltip]="getTooltipContent(call)"
        [matTooltipClass]="'call-tooltip'"
        (click)="onCallClick(call)"
        [ngClass]="{ 'has-lead': hasActiveLead(call) }"
      >
        <mat-icon *ngIf="hasActiveLead(call)" class="user-icon"
          >person</mat-icon
        >
        <mat-icon class="call-icon">call_received</mat-icon>
        <span class="call-info">{{ getCallDisplayName(call) }}</span>
      </mat-chip>
    </mat-chip-listbox>
  </div>
</div>
