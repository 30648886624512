<div class="sidenav-container">
  <div
    #sidenav
    class="sidenav"
    [ngClass]="{ expanded: isExpanded, locked: isLocked }"
    (mouseenter)="handleMouseEnter()"
    (mouseleave)="handleMouseLeave()"
  >
    <button class="lock-button" (click)="toggleLock()">
      <mat-icon>{{ isLocked ? 'lock' : 'lock_open' }}</mat-icon>
    </button>
    <mat-nav-list>
      <ng-container *ngFor="let menuItem of menuItems">
        <mat-list-item
          [routerLink]="menuItem.route"
          (click)="handleMenuItemClick(menuItem)"
          class="sidenav-menu-item"
        >
          <div class="menu-item-content">
            <mat-icon class="menu-icon">{{ menuItem.iconName }}</mat-icon>
            <span class="menu-text" *ngIf="isExpanded">{{
              menuItem.name
            }}</span>
            <span class="flex-spacer"></span>
            <mat-icon
              class="expand-icon"
              *ngIf="menuItem.children?.length && isExpanded"
              [ngClass]="{ rotated: menuItem.expanded }"
              >expand_more</mat-icon
            >
            <span class="badge" *ngIf="menuItem.badgeCount">{{
              menuItem.badgeCount
            }}</span>
          </div>
        </mat-list-item>

        <div
          class="sidenav-submenu"
          [ngClass]="{ expanded: menuItem.expanded }"
          *ngIf="menuItem.children?.length && isExpanded"
        >
          <a
            mat-list-item
            *ngFor="let child of menuItem.children"
            [routerLink]="child.route"
            (click)="handleMenuItemClick(child)"
            class="submenu-item"
          >
            {{ child.name }}
            <span class="flex-spacer"></span>
            <span class="badge" *ngIf="child.badgeCount">{{
              child.badgeCount
            }}</span>
          </a>
        </div>
      </ng-container>
    </mat-nav-list>

    <div
      class="resizer"
      (mousedown)="startResize($event)"
      *ngIf="isLocked"
    ></div>
  </div>

  <div class="content-wrapper">
    <div class="main-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
