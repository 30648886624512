import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PartnerBillService } from '../../../services/partner-bill.service';
import { MatDialog } from '@angular/material/dialog';
import { PartnerBillDeleteModalComponent } from '../partner-bill-delete-modal/partner-bill-delete-modal.component';
import { PartnerBill } from '@lib/models/partners/partner-bill';
import { SnackBarService } from '@lib/services/snack-bar/snack-bar.service';
import { TableComponent } from '@lib/components/table/table.component';
import { FilterService } from '@lib/services/filter/filter.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Price } from '@lib/models/prices';
import { takeUntil } from 'rxjs/operators';
import { BillStatus } from '@lib/enum-collection';

@Component({
  selector: 'app-partner-bills',
  templateUrl: './partner-bills.component.html',
  styleUrls: ['./partner-bills.component.scss'],
})
export class PartnerBillsComponent implements OnInit, OnDestroy {
  pageTitle = 'Rechnungen';
  @ViewChild(TableComponent) tableComponent!: TableComponent;
  restriction = {};
  filter = {};
  @Input() partnerLeadId: number = 0;
  @Input() pageTitelChange: string = '';
  @Input() showCreateButton: boolean = false;
  @Input() partnerLeadPrice: Price = new Price({});

  private destroy$ = new Subject<void>();

  constructor(
    private partnerBillService: PartnerBillService,
    private dialog: MatDialog,
    private snackbarService: SnackBarService,
    public filterService: FilterService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    if (this.partnerLeadId > 0) {
      this.restriction = { partner_lead_id: this.partnerLeadId };
    }
    if (this.pageTitelChange != '') {
      this.pageTitle = this.pageTitelChange;
    }
    this.initFilter();
  }

  initFilter() {
    this.filterService.filter$
      .pipe(takeUntil(this.destroy$))
      .subscribe((filter) => {
        this.setFilter(filter);
      });

    this.filterService.getFilter();
  }

  setFilter(filter: any) {
    this.filter = {
      ...this.filter, // Kopiert alle vorhandenen Filter
    };
    this.restriction = {
      ...this.restriction,
    };
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  reloadTableData() {
    this.tableComponent.reloadData();
  }

  deleteBill(partnerBill: PartnerBill) {
    this.dialog.open(PartnerBillDeleteModalComponent, {
      width: '900px',
      data: {
        dialogTitle: 'Die Rechnung löschen',
        partnerBill: partnerBill,
        onSubmitEvent: (partnerBillDel: PartnerBill) => {
          this.partnerBillService
            .delete(partnerBillDel.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
              this.reloadTableData();
              if (data != null) {
                this.snackbarService.openSnackBar(
                  'Die Rechnung wurde erfolgreich gelöscht.',
                  'success',
                );
              }
            });
        },
      },
    });
  }

  editPartnerBill(partnerBill: PartnerBill) {
    this.router.navigate([
      '/partner_bills/edit/' +
        partnerBill.partner_lead_id +
        '/' +
        partnerBill.id,
    ]);
  }

  enterInvoice() {
    this.router.navigate(['/partner_bills/create/' + this.partnerLeadId]);
  }

  protected readonly BillStatus = BillStatus;
}
