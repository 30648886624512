import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApplicationHelper } from '../helpers/application-helper';
import { Application } from '../enum-collection';
import { User } from '../models/user';
import { PartnerUser } from '../models/partners/partner-user';

interface CacheContent {
  expiry: number;
  value: any;
}
enum StorageType {
  Token = 'Token',
  User = 'User',
}

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private cache = new Map<string, CacheContent>();

  private static readonly CACHE_DURATION = 60 * 60 * 1000; // 1 hour
  private static readonly TOKEN_EXPIRY_DAYS = 7;
  private static readonly TOKEN_PATH = '/';
  private static readonly LOCAL_STORAGE_EXPIRY = 24 * 60 * 60 * 1000; // 24 hou

  private static readonly STORAGE_KEYS = {
    [Application.Leadmanager]: {
      [StorageType.Token]: 'BearerLeadManager',
      [StorageType.User]: 'UserLeadManager',
    },
    [Application.Salesrunner]: {
      [StorageType.Token]: 'BearerSalesrunner',
      [StorageType.User]: 'UserSalesrunner',
    },
  };

  constructor(private cookieService: CookieService) {}

  /**
   * Token management
   */
  getToken(
    application: Application = ApplicationHelper.applicationName as Application,
  ): string | null {
    return this.getCookie(this.getKey(application, StorageType.Token));
  }

  setToken(token: string, application: Application): void {
    this.setCookie(this.getKey(application, StorageType.Token), token);
  }

  removeToken(
    application: Application = ApplicationHelper.applicationName as Application,
  ): void {
    this.removeCookie(this.getKey(application, StorageType.Token));
    if (application === Application.Leadmanager) {
      this.removeCookie(
        this.getKey(Application.Salesrunner, StorageType.Token),
      );
    }
  }

  hasValidToken(
    application: Application = ApplicationHelper.applicationName as Application,
  ): boolean {
    return !!this.getToken(application);
  }

  /**
   * User management
   */
  getUser(
    application: Application = ApplicationHelper.applicationName as Application,
  ): User | PartnerUser | null {
    const userData = this.getCookie(this.getKey(application, StorageType.User));
    return userData ? JSON.parse(userData) : null;
  }

  setUser(user: User | PartnerUser, application: Application): void {
    if (user) {
      this.setCookie(
        this.getKey(application, StorageType.User),
        JSON.stringify(user),
      );
    }
  }

  removeUser(
    application: Application = ApplicationHelper.applicationName as Application,
  ): void {
    this.removeCookie(this.getKey(application, StorageType.User));
    if (application === Application.Leadmanager) {
      this.removeCookie(this.getKey(Application.Salesrunner, StorageType.User));
    }
  }

  /**
   * Local storage
   */
  setInLocalStorage(key: string, value: any): void {
    const now = new Date().getTime();
    const expiresIn = 86400000; // 24 Stunden in Millisekunden
    const data = {
      expiry: now + expiresIn,
      data: value,
    };
    localStorage.setItem(key, JSON.stringify(data));
  }

  getFromFromLocalStorage(key: string): any {
    const item = localStorage.getItem(key);
    if (!item) {
      return null;
    }

    const storedData = JSON.parse(item);
    const now = new Date().getTime();

    if (storedData.expiry < now) {
      localStorage.removeItem(key);
      return null;
    }

    return storedData.data;
  }

  /**
   * Service storage
   */
  setInServiceStorage(key: string, value: any): void {
    const expiry = Date.now() + StorageService.CACHE_DURATION;
    this.cache.set(key, { expiry, value });
  }

  getFromServiceStorage(key: string): any {
    const content = this.cache.get(key);

    if (!content) return null;

    const { expiry, value } = content;
    if (expiry < Date.now()) {
      this.cache.delete(key);
      return null;
    }

    return value;
  }

  private getKey(application: Application, type: StorageType): string {
    return StorageService.STORAGE_KEYS[application]?.[type] || '';
  }

  private setCookie(key: string, value: string): void {
    this.cookieService.set(
      key,
      value,
      StorageService.TOKEN_EXPIRY_DAYS,
      StorageService.TOKEN_PATH,
      undefined,
      true,
      'Strict',
    );
  }

  private getCookie(key: string): string | null {
    return this.cookieService.get(key) || null;
  }

  private removeCookie(key: string): void {
    if (this.cookieService.check(key)) {
      this.cookieService.delete(key, StorageService.TOKEN_PATH);
    }
  }
}
