import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../services/loading/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  loading = false;
  private subscription: Subscription;

  progress = 0;
  private progressSubscription: Subscription;

  constructor(private loadingService: LoadingService) {
    this.subscription = this.loadingService.loading$.subscribe(
      (status: boolean) => {
        setTimeout(() => (this.loading = status));
      },
    );

    this.progressSubscription = this.loadingService.progress$.subscribe(
      (newProgress) => {
        this.progress = newProgress;
      },
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.progressSubscription.unsubscribe();
  }
}
