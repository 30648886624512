import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '../auth.service';
import { StorageService } from '../storage.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private router: Router,
    private storageService: StorageService,
    private authService: AuthService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      route.url[0]?.path === 'accounting' ||
      route.url[0]?.path === 'styleguide'
    ) {
      return this.authService.getCurrentUser().pipe(
        map((user) => {
          if (!user?.name) {
            this.handleUnauthorized(state.url);
            return false;
          }
          return user.name.includes('Bode') || user.name.includes('Offermann');
        }),
        catchError(() => {
          this.handleUnauthorized(state.url);
          return of(false);
        }),
      );
    }

    // Check token for other routes
    if (this.storageService.hasValidToken()) {
      return true;
    }

    return this.handleUnauthorized(state.url);
  }

  private handleUnauthorized(returnUrl: string): UrlTree {
    this.authService.logout();
    return this.router.createUrlTree(['/login'], {
      queryParams: { returnUrl },
    });
  }
}
