import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { map, Observable, Subject, switchMap, tap } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { FilterSetting } from '@lib/models/filter-setting';
import { PartnerLead } from '@lib/models/partner-lead';
import { PartnerLeadBackend } from '@lib/models/partners/partner-lead-backend';
import { PartnerLeadDetail } from '@lib/models/partner-lead-detail';
import { LeadBackend } from '@lib/models/lead-backend';
import { SelectList } from '@lib/models/select-list';
import { FilterService } from '@lib/services/filter/filter.service';
import { SnackBarService } from '@lib/services/snack-bar/snack-bar.service';
import { PartnerLeadBackendService } from '@lib/services/partner/partner-lead-backend.service';
import { PartnerLeadService } from '@lib/services/partner-lead/partner-lead.service';
import { PartnerLeadDragDrop } from '@lib/data/partner-lead-drag-drop';
import { formatCurrency } from '@lib/helpers/numberHelpers';
import { FormField, ModalInputData } from '@lib/models/modal';
import { ModalComponent } from '@lib/components/modal/modal.component';
import {
  ModalFormFieldType,
  PartnerCancelReason,
  PartnerLeadStatus,
} from '@lib/enum-collection';
import { CreateFormGroupHelper } from '@lib/helpers/create-form-group-helper';
import { ReminderService } from '@lib/services/reminder.service';
import { Reminder } from '@lib/models/reminder';
import { Comment } from '@lib/models/comment';
import { AuthService } from '@lib/services/auth.service';
import { User } from '@lib/models/user';
import { PartnerUser } from '@lib/models/partners/partner-user';
import { LeadListViewService } from '../../services/lead-list-view.service';

@Component({
  selector: 'app-task-board',
  templateUrl: './task-board.component.html',
  styleUrls: ['./task-board.component.scss'],
})
export class TaskBoardComponent implements OnInit, OnDestroy {
  // Constants
  readonly pageTitle = 'Lead-Pipe';
  readonly INITIAL_PARTNER_STATUS = 1;
  readonly DEFAULT_PAGE_SIZE = 10000;
  protected readonly formatCurrency = formatCurrency;
  protected readonly SelectList = SelectList;
  authUser: User | PartnerUser | null = null;

  // ViewChildren
  @ViewChildren('cols') cols!: QueryList<ElementRef>;

  // State Management
  private readonly destroy$ = new Subject<void>();

  // Data Properties
  filter = {
    lead_type_id: [],
    lead_details: [] as any[],
  };

  flags = {
    taskboard: true,
  };

  // Data Collections
  partnerLeads: PartnerLead[] = [];
  partnerLeadBackend: PartnerLeadBackend = new PartnerLeadBackend({});
  partnerLeadDetail: PartnerLeadDetail[] = [];
  partnerLeadDragDrop = PartnerLeadDragDrop;
  filterBarSettings: FilterSetting[] = [];

  // Counters
  amountPartnerWithStatus = this.INITIAL_PARTNER_STATUS;

  constructor(
    private filterService: FilterService,
    private partnerLeadService: PartnerLeadService,
    private snackBarService: SnackBarService,
    private router: Router,
    private reminderService: ReminderService,
    private partnerLeadBackendService: PartnerLeadBackendService,
    private authService: AuthService,
    private leadListViewService: LeadListViewService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.authService
      .getCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.authUser = user;
      });

    this.initializeComponent();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Initialization Methods
  private initializeComponent(): void {
    this.initFilter();
    this.subscribeToFilterChanges();
  }

  private initFilter(): void {
    this.filterBarSettings = [
      new FilterSetting({
        filter: SelectList.PartnerLeadDetail,
        multiSelect: true,
      }),
      new FilterSetting({
        filter: SelectList.LeadType,
        multiSelect: true,
      }),
    ];
    this.filterService.getFilter();
  }

  private subscribeToFilterChanges(): void {
    this.filterService.filter$
      .pipe(takeUntil(this.destroy$))
      .subscribe((filter) => this.handleFilterUpdate(filter));
  }

  // Filter Handling
  private handleFilterUpdate(filter: any): void {
    this.updateFilter(filter);
    this.getPartnerLeads();
    if (filter.filter?.leadTypeId) {
      this.getLeadsBackends();
    }
  }

  private updateFilter(filter: any): void {
    this.filter = {
      ...this.filter,
      lead_type_id: filter.filter?.leadTypeId,
      lead_details: filter.filter?.partnerLeadDetail,
    };
  }

  // Data Fetching
  private getPartnerLeads(): void {
    this.partnerLeadService
      .listPartnerLeads({
        filter: this.filter,
        flags: this.flags,
        pageSize: this.DEFAULT_PAGE_SIZE,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response.data) {
          this.updatePartnerLeadsData(response.data);
        }
      });
  }

  private getLeadsBackends(): void {
    this.partnerLeadBackendService
      .list({
        filter: this.createLeadBackendFilter(),
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: LeadBackend) => {
        if (response) {
          this.handleLeadBackendResponse(response);
        }
      });
  }

  // UI Updates
  private updatePartnerLeadsData(data: PartnerLead[]): void {
    this.partnerLeads = data;
    this.updateDragDropData();
    this.setEqualHeight();
  }

  private updateDragDropData(): void {
    this.partnerLeadDragDrop.forEach((element) => {
      const { data, summe } = this.calculateSumForOptionId(
        element.status_fixed,
      );
      element.data = data;
      element.summe = summe;
      this.updatePartnerStatusCount(data.length);
    });
  }

  private updatePartnerStatusCount(count: number): void {
    if (count > this.amountPartnerWithStatus) {
      this.amountPartnerWithStatus = count;
    }
  }

  // Drag and Drop Handling
  drop(event: CdkDragDrop<PartnerLead[]>, status: number): void {
    if (event.previousContainer === event.container) {
      this.handleSameContainerDrop(event);
    } else {
      this.handleDifferentContainerDrop(event, status);
    }
  }

  private handleSameContainerDrop(event: CdkDragDrop<PartnerLead[]>): void {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex,
    );
  }

  private handleDifferentContainerDrop(
    event: CdkDragDrop<PartnerLead[]>,
    status: number,
  ): void {
    transferArrayItem(
      event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex,
    );

    const droppedItem = event.container.data[event.currentIndex];
    if (droppedItem && droppedItem.status.option_id !== status) {
      this.updatePartnerLeadStatus(droppedItem, status);
    }
  }

  // Helper Methods
  private createLeadBackendFilter(): any {
    return {
      active: 1,
      lead_type_id: this.filter.lead_type_id,
      field_type: [5],
    };
  }

  calculateSumForOptionId(optionId: number): {
    data: PartnerLead[];
    summe: number;
  } {
    const filteredData = this.partnerLeads.filter(
      (lead) => lead.status.option_id === optionId,
    );

    const sum = filteredData.reduce((acc, curr) => {
      return curr.offer_value !== null
        ? acc + parseInt(curr.offer_value.toString(), 10)
        : acc;
    }, 0);

    return { data: filteredData, summe: sum };
  }

  // UI Methods
  setEqualHeight(): void {
    this.cols?.forEach((col) => {
      col.nativeElement.style.minHeight = `${
        146 * this.amountPartnerWithStatus
      }px`;
    });
  }

  goToLeadDetail(partnerLead: PartnerLead): void {
    this.leadListViewService.openLeadById(partnerLead.id);
  }

  // Update Methods
  updatePartnerLeadOfferValue(partnerLead: PartnerLead): void {
    this.updateOffValueFe(partnerLead, 'offer_value');
  }

  offerNumberChanged(partnerLeadToSave: PartnerLead): void {
    this.partnerLeadService
      .show(partnerLeadToSave.id)
      .subscribe((partnerLead) => {
        partnerLead.offer_number = partnerLeadToSave.offer_number;
        this.partnerLeadService.update(partnerLead).subscribe(() => {
          this.showSuccessMessage('Angebotsnummer wurde gespeichert');
        });
      });
  }

  private updatePartnerLeadStatus(
    partnerLead: PartnerLead,
    status: number,
  ): void {
    partnerLead.status = status;
    this.partnerLeadService
      .update(partnerLead)
      .pipe(takeUntil(this.destroy$))
      .subscribe((updatedLead) => {
        if (updatedLead) {
          this.handleStatusUpdateSuccess(updatedLead);
        }
      });
  }

  private handleStatusUpdateSuccess(partnerLead: PartnerLead): void {
    this.updateMaxPartnerCount();
    this.setEqualHeight();
    this.fetchAndUpdatePartnerLead(partnerLead);
    this.showSuccessMessage('Status wurde erfolgreich geändert');
  }

  private updateMaxPartnerCount(): void {
    this.amountPartnerWithStatus = Math.max(
      ...this.partnerLeadDragDrop.map((item) => item.data.length),
    );
  }

  private fetchAndUpdatePartnerLead(partnerLead: PartnerLead): void {
    this.partnerLeadService
      .show(partnerLead.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response) {
          this.updateOffValueFe(response, 'transfer_array_item');
        }
      });
  }

  private updateOffValueFe(partnerLead: PartnerLead, key: string): void {
    const index = this.partnerLeads.findIndex(
      (item) => item.id === partnerLead.id,
    );

    if (index !== -1) {
      if (key === 'offer_value') {
        this.partnerLeads[index].offer_value = partnerLead.offer_value;
      } else {
        this.partnerLeads[index].status = partnerLead.status;
        this.partnerLeads[index].status = partnerLead.status;
      }

      this.updateDragDropData();
    }
  }

  private handleLeadBackendResponse(response: LeadBackend): void {
    this.partnerLeadBackend = response;
    if (this.filter.lead_details) {
      this.createLeadDetailsMap();
    }
  }

  private createLeadDetailsMap(): void {
    return this.filter.lead_details.reduce((map: any, item: any) => {
      if (!map[item.lead_field_id]) {
        map[item.lead_field_id] = {
          ...item,
          option_id_arr: [item.option_id],
        };
      } else {
        map[item.lead_field_id].option_id_arr.push(item.option_id);
      }
      return map;
    }, {});
  }

  private showSuccessMessage(text: string): void {
    this.snackBarService.openSnackBar(text, 'success');
  }

  changeStatus(partnerLead: PartnerLead): void {
    const formFields: FormField[] = [
      {
        type: ModalFormFieldType.select,
        label: 'Status',
        name: 'partner_status',
        optionlistKeyword: 'partner_status',
        validation: { required: true },
      },
      {
        type: ModalFormFieldType.select,
        label: 'Verlustgrund',
        name: 'cancel_reason',
        optionlistKeyword: 'cancel_reason',
        condition: {
          conditions: [
            { dependsOn: 'partner_status', value: PartnerLeadStatus.Verloren },
          ],
        },
      },
      {
        type: ModalFormFieldType.text,
        label: 'Verlustgrund (manuelle Eingabe)',
        name: 'cancel_reason_text',
        condition: {
          logicalOperator: 'AND',
          conditions: [
            { dependsOn: 'partner_status', value: PartnerLeadStatus.Verloren },
            {
              dependsOn: 'cancel_reason',
              value: PartnerCancelReason['Sonstiges (manuelle Eingabe)'],
            },
          ],
        },
      },
      {
        type: ModalFormFieldType.text,
        label: 'Name Wettbewerber',
        name: 'competitor_name',
        condition: {
          logicalOperator: 'AND',
          conditions: [
            { dependsOn: 'partner_status', value: PartnerLeadStatus.Verloren },
            {
              dependsOn: 'cancel_reason',
              value: PartnerCancelReason['Wettbewerber beauftragt'],
            },
          ],
        },
      },
    ];

    const inputData: ModalInputData = {
      headline: 'Status ändern',
      formGroup: CreateFormGroupHelper.createFormGroupForModal(formFields),
      formFields: formFields,
      body: 'Bitte wählen Sie den neuen Status aus:',
      acceptButtonText: 'Speichern',
      declineButtonText: 'Abbrechen',
    };

    this.dialog
      .open(ModalComponent, {
        disableClose: true,
        data: inputData,
      })
      .afterClosed()
      .subscribe((modalResponse) => {
        if (!modalResponse) return;

        partnerLead.status = modalResponse.partner_status;
        if (modalResponse.cancel_reason) {
          partnerLead.cancel_reason_id = modalResponse.cancel_reason;
        }
        if (modalResponse.cancel_reason_text) {
          partnerLead.cancel_reason = modalResponse.cancel_reason_text;
        }
        if (modalResponse.competitor_name) {
          partnerLead.cancel_reason = modalResponse.competitor_name;
        }

        this.partnerLeadService
          .update(partnerLead)
          .pipe(takeUntil(this.destroy$))
          .subscribe((updatedLead) => {
            if (updatedLead) {
              this.showSuccessMessage('Lead wurde erfolgreich aktualisiert');
              this.handleStatusUpdateSuccess(updatedLead);

              if (
                partnerLead.status ===
                PartnerLeadStatus['Angebot versendet & Wiedervorlage']
              ) {
                this.addReminder(updatedLead);
              }
            }
          });
      });
  }

  addReminder(partnerLead: PartnerLead) {
    const inputData = this.reminderService.getReminderModalInputData();

    this.dialog
      .open(ModalComponent, { disableClose: true, data: inputData })
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap((modalResponse) =>
          this.processReminderFromResponse(modalResponse, partnerLead),
        ),
        tap({
          next: () =>
            this.snackBarService.openSnackBar(
              'Erinnerung gespeichert',
              'success',
            ),
          error: () =>
            this.snackBarService.openSnackBar(
              'Fehler beim Speichern der Erinnerung',
              'warn',
            ),
        }),
      )
      .subscribe(() => {
        this.handleStatusUpdateSuccess(partnerLead);
      });
  }

  private processReminderFromResponse(
    modalResponse: any,
    partnerLead: PartnerLead,
  ): Observable<Reminder> {
    const reminder =
      this.reminderService.createOrUpdateReminderObject(modalResponse);

    reminder.partner_lead_id = partnerLead.id;
    reminder.visible_in_salesrunner = true;

    return this.reminderService.createReminder(reminder).pipe(
      filter((reminderRet: Reminder) => !!reminderRet?.id),
      switchMap((reminderRet: Reminder) =>
        this.reminderService.handleCommentCreation(
          reminderRet,
          modalResponse,
          this.authUser,
        ),
      ),
      map((result) => {
        if (result instanceof Comment) {
          return reminder;
        }
        return result as Reminder;
      }),
    );
  }

  formatDate(date: Date | string | undefined): string {
    return this.reminderService.formatDate(date);
  }
}
