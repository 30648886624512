import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { Reaction } from '../models/reaction';
import { Feed } from '../models/feed';

@Injectable({
  providedIn: 'root',
})
export class ReactionService {
  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
  ) {}

  createReaction(reaction: Reaction): Observable<Reaction> {
    return this.http.post<Reaction>(
      `${this.environmentService.apiUrl}reactions`,
      reaction,
    );
  }

  getReactionsForModel(
    modelType: string,
    modelId: number,
  ): Observable<Reaction[]> {
    return this.http.get<Reaction[]>(
      `${this.environmentService.apiUrl}reactions/${modelType}/${modelId}`,
    );
  }

  deleteReaction(id: number | undefined): Observable<void> {
    return this.http.delete<void>(
      `${this.environmentService.apiUrl}reactions/${id}`,
      {
        responseType: 'json',
      },
    );
  }
}
