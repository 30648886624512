import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ChartOptions } from 'chart.js';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit, OnChanges {
  title = 'ng2-charts-demo';

  @Input() labels: any = [];
  @Input() datasets: any = [];
  @Input() height: string = '100%';
  containsData: boolean = false;
  containerWidth: number = 300;
  containerHeight: number = 300;

  // Pie
  public pieChartOptions: ChartOptions<'pie'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
      },
    },
  };
  public pieChartLabels = this.labels;
  public pieChartDatasets = [
    {
      data: [1, 2, 3, 4, 5, 6],
    },
  ];
  public pieChartLegend = true;
  public pieChartPlugins = [];

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['datasets'] || changes['labels']) {
      this.updateChartData();
    }
  }

  updateChartData(): void {
    this.pieChartLabels = this.labels;
    this.pieChartDatasets = [
      {
        data: this.datasets,
      },
    ];

    this.containsData = !!this.datasets?.some((value: number) => value !== 0);
  }
}
