<div class="mat-elevation-z8" style="position: relative">
  <div *ngIf="isLoadingResults" class="spinner-container">
    <mat-spinner class="spinner-layout"></mat-spinner>
  </div>

  <div
    *ngIf="showSearchbar && data && columns && columns.length"
    class="search-container"
  >
    <div class="search-section">
      <mat-form-field
        class="searchbar"
        appearance="outline"
        subscriptSizing="dynamic"
      >
        <mat-icon matPrefix class="search-icon">search</mat-icon>
        <input
          matInput
          (keyup)="applySearch($event)"
          placeholder="Suche..."
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <div class="sort-section">
      <mat-form-field class="sort-dropdown">
        <mat-label>Sortieren nach</mat-label>
        <mat-select
          [disabled]="isLoadingResults"
          [(ngModel)]="selectedSortColumn"
          (selectionChange)="applySortFromDropdowns()"
        >
          <mat-option [value]="''">Keine</mat-option>
          <mat-option
            *ngFor="let column of getSortableColumns()"
            [value]="column.key"
          >
            {{ column.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="sort-direction-buttons" *ngIf="selectedSortColumn">
        <button
          mat-mini-fab
          [class.active]="selectedSortDirection === 'asc'"
          (click)="setSortDirection('asc')"
          [disabled]="isLoadingResults"
        >
          <mat-icon>arrow_upward</mat-icon>
        </button>
        <button
          mat-mini-fab
          [class.active]="selectedSortDirection === 'desc'"
          (click)="setSortDirection('desc')"
          [disabled]="isLoadingResults"
        >
          <mat-icon>arrow_downward</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div [ngStyle]="{ 'max-height.px': tableMaxHeightPx, overflow: 'auto' }">
    <table
      *ngIf="data && columns?.length"
      mat-table
      matSort
      [dataSource]="dataSource"
      [class.striped]="designStriped"
      [class.hover-effect]="designHoverEffect"
      cdkDropList
      [cdkDropListData]="dataSource.data"
      (cdkDropListDropped)="dragDropAble ? emitDropDragTable($event) : null"
    >
      <!-- Selection Spalte -->
      <ng-container *ngIf="tableWithSelection" matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            [disabled]="isLoadingResults"
            (change)="toggleAllRows()"
            [checked]="
              selection.hasValue() &&
              areAllSelectableRowsSelected(dataSource.data)
            "
            [indeterminate]="
              selection.hasValue() &&
              !areAllSelectableRowsSelected(dataSource.data)
            "
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            [disabled]="isLoadingResults || !isRowSelectable(row)"
            (click)="$event.stopPropagation()"
            (change)="toggleSingleRow(row)"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
          <ng-container *ngIf="hasSummableColumns()">
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container></td
      ></ng-container>

      <!-- dynamische übergebene Spalten -->
      <ng-container
        *ngFor="let column of columns; let isFirst = first; trackBy: trackByFn"
        [matColumnDef]="column.key"
      >
        <ng-container *ngIf="column.isSortable">
          <th
            mat-header-cell
            [class.disabled]="isLoadingResults"
            (click)="isLoadingResults ? $event.stopPropagation() : null"
            *matHeaderCellDef
            [mat-sort-header]="column.isSortable ? column.key : ''"
          >
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <span>{{ column.title }}</span>
              <i
                *ngIf="column.headlineTooltipText"
                class="bi bi-question-circle"
                title="{{ column.headlineTooltipText }}"
              ></i>
            </div>
          </th>
        </ng-container>
        <ng-container *ngIf="!column.isSortable">
          <th mat-header-cell *matHeaderCellDef>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <span>{{ column.title }}</span>
              <i
                *ngIf="column.headlineTooltipText"
                class="bi bi-question-circle"
                title="{{ column.headlineTooltipText }}"
              ></i>
            </div>
          </th>
        </ng-container>

        <td
          mat-cell
          *matCellDef="let element"
          [class.pointer]="column.designPointer"
          [style.background-color]="
            column.backgroundColorFn ? column.backgroundColorFn(element) : ''
          "
          class="overflow-visible"
          [style.width]="column.width"
          [style.vertical-align]="column.verticalAlign"
        >
          <ng-container>
            <span *ngIf="column.prefix">{{ column.prefix }}</span>

            <!--Feldanzeige aus dem Model-->
            <ng-container *ngIf="!column.customTemplate">
              <ng-container *ngIf="column.pipe; else noPipe">
                {{
                  resolveNestedPath(element, column.key)
                    | dynamicPipe : column.pipe
                }}
              </ng-container>
              <ng-template #noPipe>
                {{ resolveNestedPath(element, column.key) }}
              </ng-template>
              <span
                *ngIf="column.suffix && resolveNestedPath(element, column.key)"
                >{{ column.suffix }}</span
              >
            </ng-container>

            <!--Anzeige eines Custom Templates-->
            <ng-container *ngIf="column.customTemplate">
              <ng-container
                *ngTemplateOutlet="
                  column.customTemplate;
                  context: { $implicit: element }
                "
              >
              </ng-container>
            </ng-container>
          </ng-container>
        </td>

        <ng-container *ngIf="hasSummableColumns()">
          <ng-container *ngIf="column.summableColumnName">
            <td mat-footer-cell *matFooterCellDef>
              <span *ngIf="isFirst" class="table-sum">Summe:</span>
              <ng-container *ngIf="column.summableColumnName">
                <ng-container *ngIf="column.summableColumnUnit == 'price'">
                  {{ sumCallbackData[column.summableColumnName] | priceFormat }}
                </ng-container>
                <ng-container *ngIf="column.summableColumnUnit == 'percent'">
                  {{ sumCallbackData[column.summableColumnName] | percent }}
                </ng-container>
                <ng-container *ngIf="column.summableColumnUnit == 'dynamic'">
                  {{ sumCallbackData[column.summableColumnName] }}
                </ng-container>
              </ng-container>
            </td>
          </ng-container>

          <ng-container *ngIf="!column.summableColumnName">
            <td mat-footer-cell *matFooterCellDef>
              <span *ngIf="isFirst" class="table-sum">Summe:</span>
            </td>
          </ng-container>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnKeys"></tr>
      <ng-container *ngIf="dragDropAble; else noDragDrop">
        <tr
          mat-row
          *matRowDef="let row; columns: columnKeys"
          (click)="emitClickedElement(row)"
          (mouseenter)="emitHoveredEnterElement(row)"
          (mouseleave)="emitHoveredLeaveElement(row)"
          [ngClass]="{
            'selected-row':
              selectedId === row.id ||
              (highlightedRowIds && highlightedRowIds.includes(row.id))
          }"
        ></tr>
      </ng-container>
      <ng-template #noDragDrop>
        <ng-container *ngIf="showContextMenu">
          <tr
            mat-row
            *matRowDef="let row; columns: columnKeys"
            (click)="emitClickedElement(row)"
            (contextmenu)="onContextMenu($event, row)"
            (mouseenter)="emitHoveredEnterElement(row)"
            (mouseleave)="emitHoveredLeaveElement(row)"
            [ngClass]="{
              'selected-row':
                selectedId === row.id ||
                (highlightedRowIds && highlightedRowIds.includes(row.id))
            }"
            [cdkContextMenuTriggerFor]="contextMenu"
          ></tr>
        </ng-container>
        <ng-container *ngIf="!showContextMenu">
          <tr
            mat-row
            *matRowDef="let row; columns: columnKeys"
            (click)="emitClickedElement(row)"
            (mouseenter)="emitHoveredEnterElement(row)"
            (mouseleave)="emitHoveredLeaveElement(row)"
            [ngClass]="{
              'selected-row':
                selectedId === row.id ||
                (highlightedRowIds && highlightedRowIds.includes(row.id))
            }"
          ></tr>
        </ng-container>
      </ng-template>
      <ng-container *ngIf="hasSummableColumns()">
        <tr mat-footer-row *matFooterRowDef="columnKeys"></tr>
      </ng-container>
    </table>
  </div>

  <div
    class="no-data-message"
    *ngIf="!isLoadingResults && dataSource.data.length === 0"
  >
    Keine Daten gefunden.
  </div>

  <mat-paginator
    [length]="data.length"
    [pageSize]="initialPageSize"
    [showFirstLastButtons]="true"
    [pageSizeOptions]="pageSizeOptions"
  >
  </mat-paginator>
</div>

<ng-template #contextMenu>
  <lib-context-menu-items
    *ngIf="showContextMenu"
    cdkMenu
    class="context-menu"
    [untyped]="contextMenuData"
  ></lib-context-menu-items>
</ng-template>
