import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { DateHelper } from '../../helpers/date-helper';

@Injectable({
  providedIn: 'root',
})
export class NewFilterService {
  private filter: {
    filter: Record<string, any>;
    negativeFilter: Record<string, any>;
    daterange: { start: string; end: string };
  };
  private filterRoutes = new Map<string, any>();

  private filterSubject = new BehaviorSubject<any>({});
  public filter$ = this.filterSubject.asObservable();

  constructor(private router: Router) {
    this.filter = {
      filter: {},
      negativeFilter: {},
      daterange: {
        start: DateHelper.formatDate(DateHelper.getStartOfMonth()),
        end: DateHelper.formatDate(DateHelper.getEndOfMonth()),
      },
    };
  }

  private initFilter(): void {
    this.filter = {
      filter: {},
      negativeFilter: {},
      daterange: {
        start: DateHelper.formatDate(DateHelper.getStartOfMonth()),
        end: DateHelper.formatDate(DateHelper.getEndOfMonth()),
      },
    };
  }

  public getFilter(): any {
    if (this.filterRoutes.has(this.router.url)) {
      this.filter = this.filterRoutes.get(this.router.url);
    } else {
      this.initFilter();
    }
    // Diese Zeile war auskommentiert - Warum? Die wird benötigt, damit die Filter nur für die jeweilige URL gilt.
    this.filterSubject.next(this.filter);
    return this.filter;
  }

  public setFilter(newFilter: {
    filter?: Record<string, any>;
    negativeFilter?: Record<string, any>;
  }): void {
    if (newFilter.filter) {
      this.filter.filter = { ...this.filter.filter, ...newFilter.filter };
    }
    if (newFilter.negativeFilter) {
      this.filter.negativeFilter = {
        ...this.filter.negativeFilter,
        ...newFilter.negativeFilter,
      };
    }
    this.updateFilterRoutes();
  }

  public setDaterange(daterange: any): void {
    this.filter.daterange = { ...this.filter.daterange, ...daterange };
    this.updateFilterRoutes();
  }

  public initDaterange(daterange: any): void {
    this.filter.daterange = { ...this.filter.daterange, ...daterange };
  }

  private updateFilterRoutes(): void {
    this.filterRoutes.set(
      this.router.url,
      JSON.parse(JSON.stringify(this.filter)),
    );
    this.filterSubject.next(this.filter);
  }
}
