import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ChartConfiguration } from 'chart.js';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit, OnChanges {
  title = 'ng2-charts-demo';
  @Input() labels: any = [];
  @Input() datasets: any = [];
  @Input() height: string = '100%';
  containsData: boolean = false;
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartConfiguration<'bar'>['data'] = {
    datasets: this.datasets,
    labels: this.labels,
  };

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['datasets'] || changes['labels']) {
      this.updateChartData();
    }
  }

  updateChartData(): void {
    this.barChartData = {
      datasets: this.datasets,
      labels: this.labels,
    };

    this.containsData = !!this.datasets[0].data?.some(
      (value: number) => value !== 0,
    );
  }
}
