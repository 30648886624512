import { LeadType } from '../lead-type';

export class PartnerLeadBackend {
  id: number = 0;
  active: number = 1;
  lead_type_id: number = 0;
  lead_backend_elements: any = [];
  lead_type: LeadType = new LeadType({});

  constructor(partner_lead_backend: PartnerLeadBackend | {}) {
    Object.assign(this, partner_lead_backend);

    if (this.lead_backend_elements == null) {
      Object.assign(this, {
        ...partner_lead_backend,
        lead_backend_elements: this.lead_backend_elements || {},
      });
    }
  }
}
