import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommentService } from '../../services/comment.service';
import { Comment } from '../../models/comment';
import { ApplicationHelper } from '../../helpers/application-helper';
import { Application, BillStatus } from '../../enum-collection';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { forkJoin, Observable, of, Subject, take } from 'rxjs';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { PartnerBill } from '../../models/partners/partner-bill';
import { FileUpload } from '../../models/fileUpload';
import { FileUploadService } from '../../services/file-upload/file-upload.service';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { Lead } from '../../models/leads/lead';
import { PartnerLead } from '../../models/partner-lead';
import { CommentSummary } from '../../models/comment-summary';

@Component({
  selector: 'lib-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild(FileUploadComponent)
  fileUploadComponent!: FileUploadComponent;
  @ViewChild('commentTextarea') commentTextarea: ElementRef | undefined;

  @Input() relatableId: number = 0;
  @Input() mainPost: boolean = false;
  @Input() relatableType: string = '';
  @Input() commentToEditId: number = 0;
  @Input() commentSummaryToEdit?: CommentSummary;
  @Input() displayVisibleInSalesrunnerSlideToggle: boolean = true;
  @Input() lead: Lead | null = new Lead({});
  @Input() partnerLead: PartnerLead | null = new PartnerLead({});
  @Output() commentAdded = new EventEmitter<Comment>();

  private destroy$ = new Subject<void>();

  visibleInSalesrunner: number = 0;
  displayFileUpload: boolean = false;
  dataLoaded: boolean = false;
  commentForm: FormGroup;
  isDragging: boolean = false;
  selectedFiles: File[] = [];

  private userSetMinHeight: number | null = null;
  private isResizing: boolean = false;

  flagAllCommentsAreVisible: boolean = false;

  constructor(
    private fb: FormBuilder,
    private commentService: CommentService,
    private authService: AuthService,
    private snackbarService: SnackBarService,
    private fileUploadService: FileUploadService,
  ) {
    this.commentForm = this.fb.group({
      content: ['', [Validators.required, Validators.minLength(1)]],
      visibleInSalesrunner: [false],
    });
  }

  ngOnInit(): void {
    this.setInitialVisibleInSalesrunnerValue();

    if (this.commentSummaryToEdit) {
      this.commentToEditId = 0;
      this.commentForm
        .get('content')
        ?.setValue(this.convertHtmlToText(this.commentSummaryToEdit.summary));
      this.relatableId = this.commentSummaryToEdit.relatable_id;
      this.relatableType = this.commentSummaryToEdit.relatable_type;
    }

    if (this.commentToEditId) {
      this.commentService
        .getComment(this.commentToEditId)
        .pipe(
          take(1),
          tap((comment) => {
            this.commentForm
              .get('content')
              ?.setValue(this.convertHtmlToText(comment.comment));
            this.relatableId = comment.relatable_id;
            this.relatableType = comment.relatable_type;
          }),
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    }
  }

  ngAfterViewChecked() {
    if (this.commentTextarea && !this.isResizing) {
      this.autoGrow({ target: this.commentTextarea.nativeElement });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private convertPlainTextToHtml(text: string): string {
    return text
      .replace(/\n/g, '<br>')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/&/g, '&amp;');
  }

  setInitialVisibleInSalesrunnerValue() {
    if (
      ApplicationHelper.applicationName === Application.Salesrunner ||
      !this.displayVisibleInSalesrunnerSlideToggle
    ) {
      this.displayVisibleInSalesrunnerSlideToggle = false;
      this.dataLoaded = true;
      return;
    }

    if (
      ApplicationHelper.applicationName === Application.Leadmanager &&
      this.lead?.status != 3
    ) {
      // Wenn der Lead noch nicht weitergeleitet wurde, dann soll der Kommentar initial sichtbar sein
      // this.visibleInSalesrunner = 1;
      // soll doch nicht initial sichtbar sein
      this.visibleInSalesrunner = 0;
    }

    if (
      ApplicationHelper.applicationName === Application.Leadmanager &&
      this.relatableId
    ) {
      this.flagAllCommentsAreVisible = true;
      this.validateSlideToggleForCommentChain(this.relatableId);
    } else {
      this.dataLoaded = true;
    }
  }

  validateSlideToggleForCommentChain(commentId: number) {
    if (this.mainPost && this.lead?.status != 3) {
      this.displayVisibleInSalesrunnerSlideToggle = true;
      this.visibleInSalesrunner = 0;
      this.dataLoaded = true;
      return;
    }

    this.commentService
      .getComment(commentId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((comment: Comment) => {
        if (comment.relatable_type === 'App\\Lead') {
          // Wenn es der oberste Kommentar ist, dann wird der Schieberegler sichtbar
          this.visibleInSalesrunner = comment.visible_in_salesrunner ? 1 : 0;

          this.displayVisibleInSalesrunnerSlideToggle =
            !!this.visibleInSalesrunner;

          this.dataLoaded = true;

          if (this.commentToEditId && !this.flagAllCommentsAreVisible) {
            this.visibleInSalesrunner = 0;
            this.displayVisibleInSalesrunnerSlideToggle = false;
          }

          return;
        }

        // Ja, eine kompliziertere Logik. Der Grundgedanke ist, dass wenn ein Kommentar bearbeitet wird geprüft werden muss, ob irgendwo in der Kommentarkette ein Kommentar nicht sichtbar ist.
        if (this.commentToEditId) {
          if (
            this.commentToEditId !== comment.id &&
            !comment.visible_in_salesrunner
          ) {
            this.flagAllCommentsAreVisible = false;
          }
        }

        if (!comment.visible_in_salesrunner && !this.commentToEditId) {
          this.visibleInSalesrunner = 0;
          this.displayVisibleInSalesrunnerSlideToggle = false;
          this.dataLoaded = true;
          return;
        }

        // Wenn es noch nicht der oberste Kommentar ist, dann wird der nächste Kommentar geladen
        if (comment.relatable_type !== 'App\\Lead') {
          this.validateSlideToggleForCommentChain(comment.relatable_id);
        } else {
          this.dataLoaded = true;
        }
      });
  }

  private createCommentObject(
    user: any,
    relatableType: string,
    relatableId: number,
  ): Comment {
    const newComment = new Comment();
    newComment.comment = this.commentForm
      .get('content')
      ?.value.replace(/\n/g, '<br>');
    newComment.relatable_id = relatableId;
    newComment.relatable_type = relatableType;

    if (ApplicationHelper.applicationName === Application.Leadmanager) {
      newComment.user_id = user?.id;
      newComment.visible_in_salesrunner = this.visibleInSalesrunner === 1;
    } else if (ApplicationHelper.applicationName === Application.Salesrunner) {
      newComment.partner_user_id = user?.id;
      newComment.visible_in_salesrunner = true;
    }

    return newComment;
  }

  private createCommentSummaryObject(
    relatableType: string,
    relatableId: number,
  ): CommentSummary {
    const commentSummary = new CommentSummary();
    commentSummary.summary = this.commentForm
      .get('content')
      ?.value.replace(/\n/g, '<br>');
    commentSummary.relatable_id = relatableId;
    commentSummary.relatable_type = relatableType;

    return commentSummary;
  }

  autoGrow(event: any): void {
    if (this.isResizing) return;

    const textarea = event.target;
    const currentHeight = textarea.offsetHeight;

    textarea.style.height = 'auto';

    const newHeight = Math.max(
      currentHeight,
      textarea.scrollHeight,
      this.userSetMinHeight || 0,
    );

    textarea.style.height = `${newHeight}px`;
  }

  onResizeStart(): void {
    this.isResizing = true;
  }

  onResizeEnd(event: any): void {
    this.isResizing = false;
    this.userSetMinHeight = event.target.offsetHeight;
    this.autoGrow(event);
  }

  convertHtmlToText(html: string): string {
    // Ersetzen Sie <br> und <br/> durch Zeilenumbrüche
    let text = html.replace(/<br\s*\/?>/gi, '\n');

    // Entfernen Sie alle anderen HTML-Tags
    text = text.replace(/<[^>]+>/g, '');

    // Dekodieren Sie HTML-Entities
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
  }

  onFileListChanged(files: File[]) {
    if (files.length > 0) {
      this.selectedFiles = files;
    } else {
      this.selectedFiles = [];
    }
  }

  onSubmit() {
    if (this.commentForm.valid) {
      this.authService
        .getCurrentUser()
        .pipe(
          take(1),
          switchMap((user) => {
            return this.uploadFiles().pipe(
              switchMap((uploadedFiles) => {
                let relatableType = this.relatableType;
                let relatableId = this.relatableId;

                if (uploadedFiles && uploadedFiles.length > 0) {
                  const file = uploadedFiles[0];
                  relatableId = file.id;
                  relatableType = 'App\\Document';
                }

                if (this.commentSummaryToEdit) {
                  const commentSummary = this.createCommentSummaryObject(
                    relatableType,
                    relatableId,
                  );
                  return this.commentService.updateCommentSummary(
                    this.commentSummaryToEdit.id,
                    commentSummary,
                  );
                }

                const newComment = this.createCommentObject(
                  user,
                  relatableType,
                  relatableId,
                );

                if (this.commentToEditId) {
                  return this.commentService.updateComment(
                    this.commentToEditId,
                    newComment,
                  );
                }

                return this.commentService.createComment(newComment);
              }),
              tap(() => {
                this.fileUploadComponent?.clearAllFiles();
                this.selectedFiles = [];
              }),
            );
          }),
          tap((createdComment) => {
            this.snackbarService.openSnackBar(
              'Kommentar erfolgreich gespeichert.',
              'success',
            );

            // Wenn der Dateiupload sichtbar war, dann soll dieser zurückgesetzt werden
            if (this.displayFileUpload) {
              this.toggleFileUpload();
            }

            let type = 'Lead';
            let id = this.lead?.id;

            if (ApplicationHelper.applicationName === Application.Salesrunner) {
              type = 'PartnerLead';
              id = this.partnerLead?.id;
            }

            this.userSetMinHeight = null;
            if (this.commentTextarea) {
              this.commentTextarea.nativeElement.style.height = 'auto';
            }

            if (
              id &&
              (this.visibleInSalesrunner ||
                ApplicationHelper.applicationName === Application.Salesrunner)
            ) {
              // Wenn es ein Unterkommentar ist, darf erst emittet werden, wenn die Rückmeldung da ist.
              if (this.relatableType !== 'App\\Comment') {
                this.commentAdded.emit(createdComment);
                this.commentForm.reset();
              }

              this.commentService.aiIsLoadingSubject.next(true);

              this.commentService
                .getCommentSummaryForModel(type, id)
                .subscribe(() => {
                  this.commentService.aiIsLoadingSubject.next(false);
                  this.commentAdded.emit(createdComment);
                });
            } else {
              this.commentAdded.emit(createdComment);
              this.commentForm.reset();
            }
          }),
          catchError((error) => {
            this.snackbarService.openSnackBar(
              'Kommentar konnte nicht gespeichert werden.',
              'warn',
            );
            console.error('Error creating comment:', error);
            return of(null);
          }),
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    }
  }

  uploadFiles(): Observable<any[]> {
    if (this.selectedFiles.length > 0 && this.relatableId) {
      let key = 'PartnerLead';
      let visibleInSalesrunner = this.visibleInSalesrunner === 1 ? '1' : '0';

      if (ApplicationHelper.applicationName === Application.Leadmanager) {
        key = 'lead';
      }

      if (ApplicationHelper.applicationName === Application.Salesrunner) {
        visibleInSalesrunner = '1';
      }

      return forkJoin(
        this.selectedFiles.map((file) =>
          this.fileUploadService.upload(
            file,
            this.relatableId,
            key,
            visibleInSalesrunner,
          ),
        ),
      );
    }
    return of([]);
  }

  toggleFileUpload() {
    this.displayFileUpload = !this.displayFileUpload;

    if (!this.displayFileUpload) {
      this.selectedFiles = [];
    }
  }

  protected readonly Application = Application;
  protected readonly ApplicationHelper = ApplicationHelper;
}
