import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataTablesParameters } from '../../interfaces/optionen.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Domain } from '../../models/domain';
import { HttpHandlerService } from '../http-handler.service';

@Injectable({
  providedIn: 'root',
})
export class DomainsService {
  endpoint: string = 'domains/';

  constructor(
    private httpHandler: HttpHandlerService,
    protected http: HttpClient,
  ) {}

  datatable(dataTablesParams: DataTablesParameters): Observable<any> {
    return this.httpHandler.datatable(this.endpoint, dataTablesParams).pipe(
      map((response) => {
        return {
          recordsFiltered: response.recordsFiltered,
          recordsTotal: response.recordsTotal,
          data: response.data.map((item: Domain) => new Domain(item)),
        };
      }),
    );
  }

  list(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  getList(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  show(id: number): Observable<any> {
    return this.httpHandler.show(this.endpoint, id);
  }

  create(domain: Domain): Observable<any> {
    return this.httpHandler.create(this.endpoint, domain);
  }

  update(domain: Domain): Observable<any> {
    return this.httpHandler.update(this.endpoint, domain);
  }

  delete(id: number): Observable<any> {
    return this.httpHandler.delete(this.endpoint, id);
  }
}
