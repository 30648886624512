import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, shareReplay } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

export enum IconColor {
  SUCCESS = '#0D6B12',
  WARNING = '#F6CA51',
  DANGER = '#FF6060',
  DEFAULT = '',
}

export type BadgePosition =
  | 'top-right'
  | 'top-left'
  | 'bottom-right'
  | 'bottom-left'
  | 'center-right'
  | null;

export interface IconBadge {
  name: string;
  position: BadgePosition;
  color?: IconColor | string;
}

@Component({
  selector: 'lib-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnChanges, OnInit {
  @Input() name?: string = '';
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() variant: 'default' | 'circle' | 'shadow' = 'default';
  @Input() color: IconColor | string = IconColor.DEFAULT;
  @Input() badges: IconBadge[] = [];
  @Input() animated: boolean = false;

  private static iconCache = new Map<string, Observable<SafeHtml>>();
  icon$!: Observable<SafeHtml>;
  private badgeIcons = new Map<string, Observable<SafeHtml>>();

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.loadIcon();
    this.loadBadgeIcons();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['color'] || changes['name']) {
      this.loadIcon();
    }
    if (changes['badges']) {
      this.loadBadgeIcons();
    }
  }

  private loadIcon() {
    const cacheKey = this.color ? `${this.name}-${this.color}` : this.name;
    if (this.name && cacheKey)
      this.icon$ = this.getIconObservable(this.name, this.color, cacheKey);
  }

  private loadBadgeIcons() {
    this.badgeIcons.clear();
    this.badges.forEach((badge) => {
      const cacheKey = badge.color
        ? `${badge.name}-${badge.color}`
        : badge.name;
      this.badgeIcons.set(
        badge.name,
        this.getIconObservable(
          badge.name,
          badge.color || IconColor.DEFAULT,
          cacheKey,
        ),
      );
    });
  }

  getBadgeIcon$(badge: IconBadge): Observable<SafeHtml> {
    return (
      this.badgeIcons.get(badge.name) ||
      this.getIconObservable(
        badge.name,
        badge.color || IconColor.DEFAULT,
        badge.name,
      )
    );
  }

  private getIconObservable(
    name: string,
    color: string,
    cacheKey: string,
  ): Observable<SafeHtml> {
    if (!IconComponent.iconCache.has(cacheKey)) {
      const icon$ = this.http
        .get(`assets/icons/${name}.svg`, { responseType: 'text' })
        .pipe(
          map((svg) => {
            if (color && svg.includes('stroke=')) {
              svg = svg.replace(/stroke="[^"]+"/g, `stroke="${color}"`);
            }

            // width und height Attribute entfernen, falls vorhanden
            svg = svg.replace(/width="[^"]+"/g, '');
            svg = svg.replace(/height="[^"]+"/g, '');

            // viewBox beibehalten aber width/height auf 100% setzen
            svg = svg.replace('<svg', '<svg width="100%" height="100%"');

            return this.sanitizer.bypassSecurityTrustHtml(svg);
          }),
          shareReplay(1),
        );
      IconComponent.iconCache.set(cacheKey, icon$);
    }
    return IconComponent.iconCache.get(cacheKey)!;
  }

  getBadgeSize(): string {
    switch (this.size) {
      case 'sm':
        return '16px';
      case 'lg':
        return '24px';
      default:
        return '20px';
    }
  }
}
