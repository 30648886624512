import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'readableTime',
})
export class ReadableTimePipe implements PipeTransform {
  transform(value: number): string {
    if (value == undefined) {
      return `0 Sek.`; // Weniger als 1 Minute
    }
    if (value < 60) {
      return `${value} Sek.`; // Weniger als 1 Minute
    } else if (value < 3600) {
      const minutes = Math.floor(value / 60);
      return `${minutes} Min.`; // Weniger als 1 Stunde
    } else {
      const hours = Math.floor(value / 3600);
      return `${hours} Std.`; // Stunden
    }
  }
}
