import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-partner-bill-file-delete',
  templateUrl: './partner-bill-file-delete.component.html',
  styleUrls: ['./partner-bill-file-delete.component.scss'],
})
export class PartnerBillFileDeleteComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  deleteFile() {
    // call back to parent
    this.data.onSubmitEvent(this.data.document);
  }
}
