<div class="call-item">
  <mat-icon
    style="margin-right: 16px"
    [ngClass]="{
      outgoing: call.direction === 'out',
      incoming: call.direction !== 'out'
    }"
  >
    {{ call.direction === 'out' ? 'arrow_upward' : 'arrow_downward' }}
  </mat-icon>

  <div class="call-details">
    <ng-container [ngSwitch]="call.hangUpCause">
      <ng-container *ngSwitchCase="'normalClearing'">Erreicht</ng-container>
      <ng-container *ngSwitchCase="'cancel'">Nicht erreicht</ng-container>
      <ng-container *ngSwitchCase="'forwarded'">Weitergeleitet</ng-container>
      <ng-container *ngSwitchCase="'notFound'"
        >falsche Telefonnummer</ng-container
      >
      <ng-container *ngSwitchCase="'busy'">Besetzt</ng-container>
      <ng-container *ngSwitchCase="'noAnswer'">Keine Antwort</ng-container>
    </ng-container>

    <span class="call-participants">
      <span *ngIf="call.direction === 'out'">
        {{ call.answeringNumber }}
      </span>
      <span *ngIf="call.direction === 'in'">
        {{ call.from }}
      </span>
    </span>
    <span class="call-duration"
      >Dauer: {{ Math.round(call.duration / 60) }} Min
    </span>
  </div>
</div>
