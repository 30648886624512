import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ChartConfiguration, ChartEvent, ChartType } from 'chart.js';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit, OnChanges {
  title = 'Umsatz';
  private newLabel? = 'New label';
  @Input() labels: any = [];
  @Input() datasets: any = [];
  containsData: boolean = false;
  lineChartData: ChartConfiguration['data'] = { datasets: [], labels: [] };

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['datasets'] || changes['labels']) {
      this.updateChartData();
    }
  }

  updateChartData(): void {
    this.lineChartData = {
      datasets: this.datasets,
      labels: this.labels,
    };

    this.containsData = !!this.lineChartData?.datasets[0]?.data?.some(
      (value) => value !== 0,
    );
  }

  // @ts-ignore
  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5,
      },
    },
    scales: {
      y: {},
    },

    plugins: {
      legend: { display: true },
    },
    maintainAspectRatio: false,
  };

  public lineChartType: ChartType = 'line';

  private static generateNumber(i: number): number {
    return Math.floor(Math.random() * (i < 2 ? 100 : 1000) + 1);
  }

  // events
  public chartClicked({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {}

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {}
}
