import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, shareReplay } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

export enum ButtonType {
  CHECK = 'button-check-square',
  COMMENT = 'button-comment',
  DELETE = 'button-delete',
  EDIT = 'button-edit',
  EXTERNAL_LINK = 'button-external-link',
  EYE = 'button-eye',
  MAIL = 'button-mail',
  THUMBS_UP = 'button-thumbs-up',
  FLAG = 'flag',
  RSS = 'rss',
}

export enum ButtonVariant {
  DEFAULT = 'default',
  SHADOW = 'shadow',
}

export enum ButtonIconColor {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  DEFAULT = 'default',
}

interface ColorConfig {
  icon: string;
  background: string;
}

@Component({
  selector: 'lib-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input() type!: ButtonType;
  @Input() label?: string;
  @Input() variant: ButtonVariant = ButtonVariant.DEFAULT;
  @Input() disabled: boolean = false;
  @Input() iconColor: ButtonIconColor = ButtonIconColor.DEFAULT;
  @Input() emoji?: string;
  @Input() count?: number;

  private static iconCache = new Map<string, Observable<SafeHtml>>();
  icon$!: Observable<SafeHtml>;

  private readonly colorMap = new Map<ButtonIconColor, ColorConfig>([
    [
      ButtonIconColor.SUCCESS,
      {
        icon: '#EDFBED',
        background: '#1ca323',
      },
    ],
    [
      ButtonIconColor.WARNING,
      {
        icon: '#FFF8E7',
        background: '#F6CA51',
      },
    ],
    [
      ButtonIconColor.DANGER,
      {
        icon: '#FFE8E8',
        background: '#FF6060',
      },
    ],
    [
      ButtonIconColor.DEFAULT,
      {
        icon: '',
        background: '',
      },
    ],
  ]);

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.loadIcon();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['iconColor'] || changes['type']) {
      this.loadIcon();
    }
  }

  get currentColors(): ColorConfig {
    return (
      this.colorMap.get(this.iconColor) ||
      this.colorMap.get(ButtonIconColor.DEFAULT)!
    );
  }

  private loadIcon() {
    const cacheKey = `${this.type}-${this.iconColor}`;

    if (!ButtonComponent.iconCache.has(cacheKey)) {
      const icon$ = this.http
        .get(`assets/icons/${this.type}.svg`, { responseType: 'text' })
        .pipe(
          map((svg) => {
            const iconColor = this.currentColors.icon;
            if (iconColor) {
              svg = svg.replace(/stroke="[^"]+"/g, `stroke="${iconColor}"`);
              svg = svg.replace(/fill="[^"]+"/g, `fill="${iconColor}"`);
            }
            svg = svg.replace(/width="[^"]+"/g, '');
            svg = svg.replace(/height="[^"]+"/g, '');
            svg = svg.replace('<svg', '<svg width="100%" height="100%"');
            return this.sanitizer.bypassSecurityTrustHtml(svg);
          }),
          shareReplay(1),
        );
      ButtonComponent.iconCache.set(cacheKey, icon$);
    }
    this.icon$ = ButtonComponent.iconCache.get(cacheKey)!;
  }
}
