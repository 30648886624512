import { OptionlistOption } from '../optionlist/optionlist-option';
import { PartnerUser } from './partner-user';
import { Partner } from '../partner';

export class PartnerLeadHistory {
  id: number = 0;
  partner_lead_id: number = 0;
  partner_user_id: number = 0;
  status: number = 0;
  created_at: Date = new Date();
  updated_at: Date = new Date();
  cancel_reason_id: number = 0;
  cancel_reason: string = '';
  created_at_format: string = '';
  status_option: PartnerLeadHistoryStatusOption =
    new PartnerLeadHistoryStatusOption();
  cancel_reason_option: OptionlistOption = new OptionlistOption({});
  partner_user: PartnerUser = new PartnerUser();
  partner: Partner = new Partner({});
}

export class PartnerLeadHistoryStatusOption {
  id: number = 0;
  optionlist_id: number = 0;
  option_id: number = 0;
  name: string = '';
  created_at: Date = new Date();
  updated_at: Date = new Date();
  order: number = 0;
  disabled: number = 0;
}
