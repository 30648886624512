<ng-container *ngIf="comments">
  <div
    *ngFor="let comment of comments"
    class="comments-wrapper"
    [class.nested]="currentLevel > 0"
  >
    <div class="comment-container">
      <!-- Kommentar Header -->
      <div
        class="comment-header"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <b class="feed-item-headline">{{ comment.user?.name }}</b>
        <div
          fxLayout="row"
          fxLayoutAlign="end center"
          fxLayoutGap="5px"
          class="comment-date"
        >
          <em *ngIf="comment.edited_at">(bearbeitet)</em>
          <div *ngIf="comment.created_at">
            {{ comment.created_at | customDateTime }}
          </div>
        </div>
      </div>

      <!-- Kommentar Inhalt -->
      <div class="comment-content">{{ comment.comment }}</div>

      <!-- Kommentar Footer -->
      <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="5px">
        <ng-container *ngFor="let action of getActionsForComment(comment)">
          <lib-button
            *ngIf="action.type !== ButtonType.THUMBS_UP"
            [type]="action.type"
            [iconColor]="action.iconColor"
            [disabled]="action.disabled ?? false"
            [emoji]="action.emoji"
            [count]="action.count"
            (click)="handleClick(action, comment)"
          ></lib-button>

          <app-tooltip
            *ngIf="action.type === ButtonType.THUMBS_UP"
            [text]="getReactionTooltipText(comment)"
          >
            <div cdkOverlayOrigin #emojiTrigger="cdkOverlayOrigin">
              <lib-button
                [type]="action.type"
                [iconColor]="action.iconColor"
                [disabled]="action.disabled ?? false"
                [emoji]="action.emoji"
                [count]="action.count"
                (click)="handleClick(action, comment)"
              ></lib-button>
            </div>
          </app-tooltip>
        </ng-container>
      </div>

      <!-- Emoji Picker Template -->
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="emojiTrigger"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayOpen]="
          showEmojiPickerMap.get(comment.id ?? -1) ?? false
        "
        (backdropClick)="closeEmojiPicker(comment)"
        [cdkConnectedOverlayPositions]="[
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
          }
        ]"
      >
        <div class="emoji-container">
          <emoji-mart
            class="emoji-picker"
            [emojiSize]="20"
            [darkMode]="false"
            [set]="'apple'"
            (emojiSelect)="onEmojiSelect($event, comment)"
          ></emoji-mart>
        </div>
      </ng-template>
    </div>

    <div *ngIf="isCommentInputVisible(comment.id)" class="nested-comment-input">
      <lib-comment
        *ngIf="comment.id"
        [relatableId]="comment.id"
        [relatableType]="'Comment'"
        [displayVisibleInSalesrunnerButton]="lead?.status !== 3"
        [lead]="lead || null"
        (commentAdded)="onCommentAdded(comment.id)"
      ></lib-comment>
    </div>

    <div *ngIf="isEditMode(comment.id)" class="nested-comment-input">
      <lib-comment
        *ngIf="comment.id"
        [commentToEditId]="comment.id"
        [relatableId]="comment.relatable_id"
        [relatableType]="comment.relatable_type"
        [displayVisibleInSalesrunnerButton]="lead?.status !== 3"
        [lead]="lead || null"
        (commentAdded)="onCommentEdited(comment.id)"
      ></lib-comment>
    </div>

    <!-- Verschachtelte Kommentare -->
    <lib-feed-comment-history
      *ngIf="comment.comments?.length"
      [comments]="comment.comments"
      [lead]="lead"
      [parentLevel]="currentLevel"
    ></lib-feed-comment-history>
  </div>
</ng-container>
