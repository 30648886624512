import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { LeadFeedBacks } from '../../../models/leads/lead-feed-backs';
import { MatDialog } from '@angular/material/dialog';
import { LeadService } from '../../../services/lead/lead.service';
import { takeUntil } from 'rxjs/operators';
import { SnackBarService } from '../../../services/snack-bar/snack-bar.service';
import { LeadFeedbackModalComponent } from '../../lead-feedback/lead-feedback-modal.component';

@Component({
  selector: 'lib-feed-feedback',
  templateUrl: './feed-feedback.component.html',
  styleUrls: ['./feed-feedback.component.scss'],
})
export class FeedFeedbackComponent implements OnInit, OnDestroy {
  @Input() leadFeedback: LeadFeedBacks | null = null;
  @Output() feedbackChanged = new EventEmitter<any>();

  private destroy$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private leadService: LeadService,
    private snackbarService: SnackBarService,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openLeadFeedbackModal(): void {
    if (this.leadFeedback) {
      this.leadService
        .show(this.leadFeedback.lead_id)
        .pipe(takeUntil(this.destroy$))
        .subscribe((lead) => {
          console.log(lead);

          const dialogRef = this.dialog.open(LeadFeedbackModalComponent, {
            width: '95%',
            data: lead,
          });
          dialogRef
            .afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {
              this.feedbackChanged.emit();

              if (result === 'failed') {
                this.snackbarService.openSnackBar(
                  'Etwas ist schief gelaufen',
                  'warn',
                );
              }
            });
        });
    }
  }
}
