import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalFormFieldType } from '../../../../../enum-collection';
import { CreateFormGroupHelper } from '../../../../../helpers/create-form-group-helper';
import { ModalComponent } from '../../../../modal/modal.component';

@Component({
  selector: 'lib-conditional-modal-example',
  template: ConditionalModalExample.HTML_CODE,
})
export class ConditionalModalExample {
  static HTML_CODE = `
    <button class="btn btn-primary" (click)="openConditionalModal()">
      Bedingtes Modal öffnen
    </button>
  `;

  constructor(private dialog: MatDialog) {}

  openConditionalModal() {
    const formFields = [
      {
        type: ModalFormFieldType.select,
        label: 'Typ auswählen',
        name: 'type',
        options: [
          { label: 'Option A', value: 'a' },
          { label: 'Option B', value: 'b' },
        ],
      },
      {
        type: ModalFormFieldType.text,
        label: 'Zusatzfeld für Option A',
        name: 'additionalField',
        condition: {
          conditions: [{ dependsOn: 'type', value: 'a' }],
        },
      },
    ];

    const inputData = {
      headline: 'Bedingte Felder',
      formGroup: CreateFormGroupHelper.createFormGroupForModal(formFields),
      formFields: formFields,
    };

    this.dialog.open(ModalComponent, {
      data: inputData,
    });
  }
}
