import { Component } from '@angular/core';

@Component({
  selector: 'lib-custom-tooltip-example',
  template: CustomTooltipExample.HTML_CODE,
})
export class CustomTooltipExample {
  static HTML_CODE = `
    <div class="example-container">
      <app-tooltip
        [text]="'<b>Wichtig:</b><br>Mehrere Zeilen<br>mit Formatierung'"
      >
        <button mat-button>Hover für formatierte Info</button>
      </app-tooltip>
    </div>
  `;
}
