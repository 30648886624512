import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { mustMatchPassword } from '../../../validations/mustMatchPassword';
import { SnackBarService } from '../../../services/snack-bar/snack-bar.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Address } from '../../../models/address';

@Component({
  selector: 'lib-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  resetPasswordMode: boolean = true;
  resetPasswordForm: FormGroup = new FormGroup({});
  @Output() newPasswordSavedEmitter = new EventEmitter<void>();

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbarService: SnackBarService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.resetPasswordMode = !params['new_password'];
      });

    this.resetPasswordForm = this.formBuilder.group(
      {
        password: ['', [Validators.required, this.passwordValidator]],
        passwordConfirmation: ['', Validators.required],
      },
      {
        validator: mustMatchPassword('password', 'passwordConfirmation'),
      },
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  passwordValidator(control: FormControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return null;
    }
    const hasNumeric = /[0-9]/.test(value);
    const hasUpper = /[A-Z]/.test(value);
    const hasLower = /[a-z]/.test(value);
    const hasSpecial = /[!@#$%^&*(),.?":{}|<>+\-_=\[\]\\;'/]/.test(value);
    const isValid =
      hasNumeric && hasUpper && hasLower && hasSpecial && value.length >= 8;
    return isValid ? null : { passwordStrength: true };
  }

  submit() {
    if (this.resetPasswordForm.valid) {
      this.route.queryParams.subscribe((routeParams) => {
        const token = routeParams['token'];
        const email = routeParams['email'];

        this.authService
          .resetPassword(
            token,
            email,
            this.resetPasswordForm.get('password')?.value,
            this.resetPasswordForm.get('passwordConfirmation')?.value,
          )
          .subscribe((data: any) => {
            this.snackbarService.openSnackBar(
              'Das Passwort wurde erfolgreich aktualisiert',
              'success',
            );
            this.newPasswordSavedEmitter.emit();
            this.authService.logout().subscribe(() => {
              this.router.navigate(['/login']);
            });
          });
      });
    }
  }
}
