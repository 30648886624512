import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHandlerService } from './http-handler.service';
import { TargetingBudget } from '../models/targeting/targeting-budget';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class TargetingBudgetService {
  endpoint = 'targeting_budgets/';

  constructor(
    private httpHandler: HttpHandlerService,
    private environmentService: EnvironmentService,
    protected http: HttpClient,
  ) {}

  list(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  getListByTargetingId(targetingId: number): Observable<any> {
    let params = new HttpParams();

    return this.http.get(
      `${this.environmentService.apiUrl}${this.endpoint}?targeting_id=${targetingId}`,
      {
        responseType: 'json',
        params,
      },
    );
  }

  show(id: number): Observable<any> {
    return this.httpHandler.show(this.endpoint, id);
  }

  create(targetingBudget: TargetingBudget): Observable<any> {
    return this.httpHandler.create(this.endpoint, targetingBudget);
  }

  update(targetingBudget: TargetingBudget): Observable<any> {
    return this.httpHandler.update(this.endpoint, targetingBudget);
  }

  delete(id: number): Observable<any> {
    return this.httpHandler.delete(this.endpoint, id);
  }
}
