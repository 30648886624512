import { ApiEntryFields } from './api-entry-fields';

export class ApiEntry {
  id: number = 0;
  content_type: string = '';

  content: { fields: ApiEntryFields[] } = { fields: [] };
  lead_id: number = 0;
  api_id: number = 0;
  error_msg: string = '';

  constructor(api_entry: ApiEntry | {}) {
    // Initialize any other properties or perform additional setup if needed
    Object.assign(this, api_entry);
  }
}
