<app-card>
  <app-card-body>
    <div class="text-end" *ngIf="this.isDuplicate">
      <span class="badge bg-danger" style="color: white"
        >Achtung: Duplikat gefunden</span
      >
    </div>

    <mat-tab-group disablePagination="true">
      <ng-container *ngFor="let address of addresses">
        <mat-tab
          label="Adresse"
          class="duplicate-table"
          *ngIf="address.type == 1"
        >
          <div class="row mt-1">
            <app-address
              [address]="address"
              [inputsDisabled]="inputsDisabled"
              (addressChanged)="onAddressChanged($event)"
            ></app-address>
          </div>
        </mat-tab>
        <mat-tab label="Abweichende Rechnungsadresse" *ngIf="address.type == 2">
          <div class="row">
            <div class="row bd-example m-0 border-0 my-2">
              <div class="d-grid gap-2 col mx-auto">
                <button
                  class="btn btn-primary btn-sm mx-1"
                  (click)="copyAddress()"
                >
                  Adresse übernehmen
                </button>
              </div>
              <div class="d-grid gap-2 col mx-auto">
                <button
                  class="btn btn-danger btn-sm mx-1"
                  (click)="deleteAddress(address)"
                >
                  Abweichende Rechnungsadresse Löschen
                </button>
              </div>
            </div>
            <app-address
              [address]="address"
              [inputsDisabled]="inputsDisabled"
              (addressChanged)="onAddressChanged($event)"
            ></app-address>
          </div>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </app-card-body>
</app-card>
