export const MENU_DATA = [
  {
    name: 'Dashboard',
    slug: '/dashboard',
    route: '/dashboard',
    iconName: 'dashboard',
    activeParent: false,
    expanded: false,
  },
  {
    name: 'Leads',
    slug: '/leads-new',
    route: '/leads-new',
    iconName: 'groups',
    activeParent: false,
    expanded: false,
  },
  {
    name: 'Lead-Pipe',
    slug: '/task_board',
    route: '/task_board',
    iconName: 'line_style',
    activeParent: false,
    expanded: false,
  },
  {
    name: 'Chancenliste',
    slug: '/chance_list',
    route: '/chance_list',
    iconName: 'trending_up',
    activeParent: false,
    expanded: false,
  },
  {
    name: 'Erinnerungen',
    slug: '/next_best_action',
    route: '/next_best_action',
    iconName: 'date_range',
    activeParent: false,
    expanded: false,
  },
  {
    name: 'Rechnungen',
    slug: '/partner_bills',
    route: '/partner_bills',
    iconName: 'euro_symbol',
    activeParent: false,
    expanded: false,
  },
  {
    name: 'Targeting',
    slug: '/targeting',
    route: '/targeting',
    iconName: 'pageview',
    activeParent: false,
    expanded: false,
  },
  {
    name: 'Einstellung',
    slug: '/partner_user',
    route: '/partner_user',
    iconName: 'settings',
    activeParent: false,
    expanded: false,
  },
];
