<div class="demo-example">
  <mat-tab-group>
    <mat-tab *ngFor="let file of files" [label]="file.language">
      <div class="code-block">
        <button
          mat-icon-button
          class="copy-button"
          (click)="copyCode(file.content)"
          matTooltip="In Zwischenablage kopieren"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
        <pre
          class="language-{{ file.language }}"
        ><code>{{file.content}}</code></pre>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
