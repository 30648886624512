import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Comment } from '../../../models/comment';
import { ApplicationHelper } from '../../../helpers/application-helper';
import { Application } from '../../../enum-collection';
import { LeadService } from '../../../services/lead/lead.service';
import { Lead } from '../../../models/leads/lead';
import { FeedService } from '../../../services/feed.service';

@Component({
  selector: 'lib-feed-comment',
  templateUrl: './feed-comment.component.html',
  styleUrls: ['./feed-comment.component.scss'],
})
export class FeedCommentComponent implements OnInit, OnDestroy {
  @Input() comment: Comment = new Comment();
  @Input() lead?: Lead = new Lead({});
  private destroy$ = new Subject<void>();

  protected readonly ApplicationHelper = ApplicationHelper;
  protected readonly Application = Application;

  constructor(private feedService: FeedService) {}

  ngOnInit(): void {}

  getFeedSalesrunnerVisibilityHint(): string {
    return this.feedService.getFeedSalesrunnerVisibilityHint(
      this.comment.visible_in_salesrunner,
      this.comment.partner,
      this.lead,
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
