import { Address } from '../models/address';

export function getInvoiceAddressFromAddresses(
  addresses: Address[],
): Address | undefined {
  const invoiceAddress = addresses.find(
    (address: Address) => address.type === 2,
  );
  if (!invoiceAddress) {
    return undefined;
  }
  return invoiceAddress;
}
