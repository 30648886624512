import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { Lead } from '../../models/leads/lead';
import { Comment } from '../../models/comment';
import { FeedItemActionButton } from '../../models/feed';
import { Reaction } from '../../models/reaction';
import { FeedItemService } from '../../services/feed-item.service';
import { EmojiPickerService } from '../../services/emoji-picker.service';
import { ReactionService } from '../../services/reaction.service';
import { AuthService } from '../../services/auth.service';
import { ApplicationHelper } from '../../helpers/application-helper';
import { Application } from '../../enum-collection';
import { ButtonType } from '../button/button.component';

@Component({
  selector: 'lib-feed-comment-history',
  templateUrl: './feed-comment-history.component.html',
  styleUrls: ['./feed-comment-history.component.scss'],
})
export class FeedCommentHistoryComponent
  implements OnInit, OnDestroy, OnChanges
{
  // Input Properties
  @Input() comments: Comment[] = [];
  @Input() lead?: Lead = new Lead({});
  @Input() parentLevel: number = 0;

  // ViewChild
  @ViewChild('emojiTrigger', { read: CdkOverlayOrigin, static: false })
  emojiTrigger!: CdkOverlayOrigin;

  // Public Maps für Template-Zugriff
  commentInputMap = new Map<number, boolean>();
  showEmojiPickerMap = new Map<number, boolean>();
  currentUserReactionsMap = new Map<number, Reaction | null>();
  commentActions = new Map<number, FeedItemActionButton[]>();
  editModeMap = new Map<number, boolean>();

  // Destroy Subject für Subscription Cleanup
  private destroy$ = new Subject<void>();

  // Öffentliche Readonly Properties
  protected readonly ApplicationHelper = ApplicationHelper;
  protected readonly Application = Application;
  protected readonly ButtonType = ButtonType;

  constructor(
    private reactionService: ReactionService,
    private feedItemService: FeedItemService,
    private emojiPickerService: EmojiPickerService,
    private authService: AuthService,
  ) {}

  // Getter für aktuelle Verschachtelungsebene
  get currentLevel(): number {
    return this.parentLevel + 1;
  }

  // Lifecycle Hooks
  ngOnInit(): void {
    this.initializeCommentActions();
  }

  ngOnDestroy() {
    this.cleanup();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['comments']) {
      this.initializeCommentActions();
    }
  }

  // Initialisierungsmethoden
  private initializeCommentActions(): void {
    if (!this.comments) return;

    this.comments.forEach((comment) => {
      if (!comment?.id) return;

      this.commentActions.set(
        comment.id,
        this.feedItemService.getAvailableCommentActions(comment, this.lead),
      );
      this.showEmojiPickerMap.set(comment.id, false);
      this.loadCurrentUserReaction(comment);
    });
  }

  // Reaktions-Handler
  private loadCurrentUserReaction(comment: Comment) {
    if (!comment?.id) return;

    this.reactionService
      .getReactionsForModel('Comment', comment.id)
      .subscribe((reactions) => {
        this.authService.getCurrentUser().subscribe((user) => {
          const isLeadManager =
            ApplicationHelper.applicationName === Application.Leadmanager;
          const reaction =
            reactions.find((r) =>
              isLeadManager
                ? r.user_id === user?.id
                : r.partner_user_id === user?.id,
            ) ?? null;

          this.currentUserReactionsMap.set(comment.id!, reaction);
          this.updateAvailableActions(comment);
        });
      });
  }

  private updateAvailableActions(comment: Comment) {
    if (!comment?.id) return;

    this.commentActions.set(
      comment.id,
      this.feedItemService.getAvailableCommentActions(comment, this.lead),
    );
  }

  // Action Handlers
  handleClick(action: FeedItemActionButton, comment: Comment) {
    if (!comment.id) return;

    switch (action.type) {
      case ButtonType.COMMENT:
        this.toggleCommentInput(comment.id);
        break;
      case ButtonType.EDIT:
        this.toggleEditMode(comment.id);
        break;
      case ButtonType.THUMBS_UP:
        this.handleThumbsUp(comment);
        break;
      default:
        if (!action.disabled && action.onClick) {
          action.onClick();
        }
    }
  }

  private handleThumbsUp(comment: Comment) {
    if (!comment.id) return;

    const currentReaction = this.currentUserReactionsMap.get(comment.id);
    if (currentReaction) {
      this.removeReaction(currentReaction, comment);
      return;
    }
    this.toggleEmojiPicker(comment);
  }

  // UI State Management
  toggleCommentInput(commentId: number): void {
    // Schließe alle anderen Kommentareingaben
    this.commentInputMap.forEach((_, key) => {
      if (key !== commentId) {
        this.commentInputMap.set(key, false);
      }
    });

    // Toggle ausgewählten Kommentar
    this.commentInputMap.set(
      commentId,
      !(this.commentInputMap.get(commentId) || false),
    );
  }

  toggleEditMode(commentId: number): void {
    // Schließe andere Edit-Modi und Kommentareingaben
    this.editModeMap.forEach((_, key) => {
      if (key !== commentId) this.editModeMap.set(key, false);
    });
    this.commentInputMap.forEach((_, key) => {
      this.commentInputMap.set(key, false);
    });

    // Toggle Edit-Modus
    this.editModeMap.set(
      commentId,
      !(this.editModeMap.get(commentId) || false),
    );
  }

  // Emoji & Reaction Handlers
  toggleEmojiPicker(comment: Comment) {
    if (!comment.id) return;

    const currentValue = this.showEmojiPickerMap.get(comment.id) ?? false;
    this.showEmojiPickerMap.set(comment.id, !currentValue);
  }

  onEmojiSelect(event: any, comment: Comment) {
    if (!comment.id) return;

    this.emojiPickerService.onEmojiSelect(
      event,
      comment.id,
      'App\\Comment',
      () => {
        this.closeEmojiPicker(comment);
        this.reloadFeed();
      },
    );
  }

  removeReaction(reaction: Reaction, comment: Comment) {
    this.emojiPickerService.removeReaction(reaction, () => {
      if (comment.id) {
        this.currentUserReactionsMap.set(comment.id, null);
      }
      this.reloadFeed();
    });
  }

  // Event Handlers
  onCommentAdded(commentId: number): void {
    this.commentInputMap.set(commentId, false);
    this.reloadFeed();
  }

  onCommentEdited(commentId: number): void {
    this.editModeMap.set(commentId, false);
    this.reloadFeed();
  }

  // Utility Methods
  getActionsForComment(comment: Comment): FeedItemActionButton[] {
    return comment?.id ? this.commentActions.get(comment.id) || [] : [];
  }

  getReactionTooltipText(comment: Comment): string {
    if (!comment.reactions?.length) return '';

    // Emoji-Zählung
    const emojiCount = new Map<string, number>();
    comment.reactions.forEach((reaction: Reaction) => {
      emojiCount.set(reaction.emoji, (emojiCount.get(reaction.emoji) || 0) + 1);
    });

    // Erstelle Zusammenfassung
    const reactionSummary = Array.from(emojiCount.entries())
      .map(([emoji, count]) => `${emoji} ${count}`)
      .join(' · ');

    let tooltipText = `<b>${reactionSummary}</b><br><br>`;

    // Gruppiere Benutzer nach Emojis
    const groupedUsers = comment.reactions.reduce((acc, reaction: Reaction) => {
      const user = reaction.user || reaction.partner_user;
      const name =
        user?.name ||
        (user?.first_name && user?.last_name
          ? `${user.first_name} ${user.last_name}`.trim()
          : 'Unbekannt');

      if (!acc[reaction.emoji]) {
        acc[reaction.emoji] = [];
      }
      acc[reaction.emoji].push(name);
      return acc;
    }, {} as Record<string, string[]>);

    // Formatiere Ausgabe
    Object.entries(groupedUsers).forEach(([emoji, names]) => {
      tooltipText += `${emoji} ${names.join(', ')}<br>`;
    });

    return tooltipText;
  }

  closeEmojiPicker(comment: Comment): void {
    if (comment.id) {
      this.showEmojiPickerMap.set(comment.id, false);
    }
  }

  // State Checkers
  isCommentInputVisible(commentId?: number): boolean {
    if (!commentId) return false;
    return this.commentInputMap.get(commentId) || false;
  }

  isEditMode(commentId?: number): boolean {
    if (!commentId) return false;
    return this.editModeMap.get(commentId) || false;
  }

  // Helper Methods
  private cleanup() {
    this.destroy$.next();
    this.destroy$.complete();

    // Cleanup aller Maps
    this.commentInputMap.clear();
    this.showEmojiPickerMap.clear();
    this.currentUserReactionsMap.clear();
    this.commentActions.clear();
    this.editModeMap.clear();
  }

  private reloadFeed() {
    this.feedItemService.emitFeedUpdated();
  }
}
