<h1 mat-dialog-title>SMS senden</h1>
<mat-dialog-content [formGroup]="smsForm">
  <mat-form-field fxFill>
    <mat-label>Absender Name</mat-label>
    <input matInput formControlName="senderName" />
    <mat-error *ngIf="smsForm.get('senderName')?.hasError('required')">
      Absendername ist <strong>erforderlich</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFill>
    <mat-label>Absender Rufnummer</mat-label>
    <input matInput formControlName="senderNumber" />
    <mat-error *ngIf="smsForm.get('senderNumber')?.hasError('required')">
      Rufnummer ist <strong>erforderlich</strong>
    </mat-error>
    <mat-error *ngIf="smsForm.get('senderNumber')?.hasError('pattern')">
      Ungültige <strong>Rufnummer</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFill>
    <mat-label>Empfänger Rufnummer</mat-label>
    <input matInput formControlName="recipientNumber" />
    <mat-error *ngIf="smsForm.get('recipientNumber')?.hasError('required')">
      Rufnummer ist <strong>erforderlich</strong>
    </mat-error>
    <mat-error *ngIf="smsForm.get('recipientNumber')?.hasError('pattern')">
      Ungültige <strong>Rufnummer</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFill>
    <mat-label>Nachricht</mat-label>
    <textarea
      matInput
      formControlName="message"
      placeholder="Inhalt"
    ></textarea>
    <mat-error *ngIf="smsForm.get('message')?.hasError('required')">
      Nachricht ist <strong>erforderlich</strong>
    </mat-error>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Abbrechen</button>
  <button
    mat-button
    (click)="sendSms()"
    class="btn btn-success btn-success"
    [disabled]="!smsForm.valid"
  >
    Senden
  </button>
</mat-dialog-actions>
