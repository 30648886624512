<div *ngIf="!containsData" fxLayout="row" fxLayoutAlign="center center">
  <em>Mit den hinterlegten Filtern konnten keine Daten gefunden werden.</em>
</div>
<div *ngIf="containsData" class="chart">
  <canvas
    baseChart
    [height]="this.height"
    [data]="barChartData"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    [type]="'bar'"
  >
  </canvas>
</div>
