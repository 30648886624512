Neuer Status:
<b *ngIf="partnerLeadHistory && partnerLeadHistory.status === 7"
  >Warten auf Annahme</b
>
<b *ngIf="partnerLeadHistory && partnerLeadHistory.status_option">{{
  partnerLeadHistory.status_option.name
}}</b
><b *ngIf="leadHistory && leadHistory.status_option">{{
  leadHistory.status_option.name
}}</b>
