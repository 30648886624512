<app-card>
  <app-card-head pageTitle="Leads">
    <div class="headline-container">
      <!-- Linke Seite -->
      <div class="left-section">
        <div class="headline-element">
          <lib-slide-toggle
            style="margin-bottom: 10px"
            [value]="(useNewView$ | async)!"
            (valueChange)="toggleView()"
          >
            Testen Sie die neue Lead-Liste
          </lib-slide-toggle>
        </div>
      </div>

      <!-- Rechte Seite -->
      <div class="right-section">
        <div class="headline-element">
          <button
            mat-stroked-button
            color="accent"
            [routerLink]="['/next_best_action']"
          >
            Erinnerungsliste anzeigen
          </button>
        </div>

        <div class="headline-element">
          <button
            mat-stroked-button
            color="primary"
            (click)="fetchDataAndSaveExcel()"
          >
            <i class="bi bi-cloud-download"></i> Excel herunterladen
          </button>
        </div>

        <div class="headline-element">
          <app-tooltip
            [text]="
              'Der Score zeigt für welches Leadpotenzial Sie sich qualifizieren.<br /><br />Je höher der Score, desto mehr Leads erhalten Sie von uns.<br /><br />Den Score können Sie erhöhen durch schnelle Reaktionszeiten und Rückmeldungen zu den einzelnen Leads.<br /><br /><b>Leads mit dem Status -Neu- wirken sich negativ auf Ihren Score aus</b>'
            "
          >
            <div class="lead-list-headline-element">
              <mat-progress-bar
                mode="determinate"
                [value]="partnerScore"
                color="primary"
              ></mat-progress-bar>
              <div>{{ partnerScore }}%</div>
            </div>
          </app-tooltip>
        </div>
      </div>
    </div>

    <!-- Filter Bar -->
    <lib-filter-bar [filterSettings]="filterBarSettings"></lib-filter-bar>
  </app-card-head>
  <app-card-body>
    <div
      *ngIf="
        (getHasMissingLastBill() || missingBillsInitialValue) &&
        invoiceAndPerformanceData
      "
      fxLayout="column"
      fxLayoutAlign="space-around stretch"
      class="lead-list-missing-bill-hint badge bg-danger fs-6 fw-normal"
    >
      Ggf. sind noch nicht alle Rechnungen erfasst, bitte prüfen Sie die Leads
      im Status "Auftrag erhalten" mit der Meldung "Bitte Rechnung eintragen".
    </div>

    <lib-table
      [filter]="filter"
      [tableId]="'partnerLeadList'"
      [initialPageSize]="50"
      [endpoint]="'partner_leads/'"
      [showSearchbar]="true"
      (displayedDataChanged)="onDisplayedDataChanged($event)"
      [additionalSearchFields]="[
        'addresses.full_text_index',
        'lead_number',
        'offer_number'
      ]"
    >
      <lib-table-column
        [defaultSort]="'desc'"
        [columnName]="'send_at'"
        [isSearchable]="false"
        [title]="'Anfrage'"
        [verticalAlign]="'top'"
        [customTemplate]="leadTemplate"
      >
        <ng-template #leadTemplate let-lead>
          <div
            fxLayout="column"
            class="lead-list-entry-margin"
            fxLayoutAlign="space-around start"
          >
            <div fxLayout="row" fxLayoutAlign="space-between center">
              {{
                lead.send_at
                  | dynamicPipe : { name: 'date', args: ['dd.MM.yyyy HH:mm'] }
              }}
              Uhr
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              #{{ lead.lead_number }}
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              {{ lead.lead_type.name }}
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="space-around stretch"
              style="width: 100%"
            >
              <app-partner-lead-offer-value
                [partnerLead]="lead"
              ></app-partner-lead-offer-value>

              <mat-form-field (change)="offerNumberChange(lead)">
                <mat-label
                  >Referenznummer
                  <mat-icon
                    matTooltip="In diesem Feld können Sie z.B. die Kundennummer oder Ihre interne Angebotsnummer hinterlegen. Die Referenznummer wird im Export der Lead-Liste angezeigt und soll Ihnen die Zuordnung zu Ihrem System und Ihren Kundendaten erleichtern."
                    >info
                  </mat-icon></mat-label
                >
                <input matInput [(ngModel)]="lead.offer_number" />
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="space-between center"
              *ngIf="lead.targeting && lead.targeting.description_partner"
            >
              Gebiet: {{ lead.targeting.description_partner }}
            </div>
          </div>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [columnName]="'address.company_name'"
        [title]="'Projekt'"
        [isSortable]="false"
        [isSearchable]="false"
        [customTemplate]="addressTemplate"
        [verticalAlign]="'top'"
      >
        <ng-template #addressTemplate let-lead>
          <div
            fxLayout="column"
            fxLayoutAlign="space-around start"
            class="lead-list-entry-margin"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="10px"
              style="margin-bottom: 10px"
            >
              <button
                mat-stroked-button
                color="primary"
                [routerLink]="['/leads/edit', lead.id]"
              >
                Anfrage öffnen
              </button>
              <button
                mat-stroked-button
                color="accent"
                (click)="addReminder(lead)"
              >
                Erinnerung erstellen
              </button>
            </div>

            <div
              fxLayout="row"
              fxLayoutAlign="space-between center"
              class="lead-list-headline"
            >
              Standardadresse
            </div>
            <app-partner-lead-address
              class="lead-list-address"
              [address]="lead.address"
            ></app-partner-lead-address>

            <!-- Loop durch Adressen für abweichende Adressen -->
            <ng-container *ngFor="let address of lead.addresses">
              <ng-container *ngIf="address.type === 2">
                <div
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  class="lead-list-headline lead-list-entry-margin"
                >
                  Abweichende Angebots-/Rechnungsadresse
                </div>

                <app-partner-lead-address
                  class="lead-list-address"
                  [address]="address"
                ></app-partner-lead-address>
              </ng-container>
            </ng-container>
          </div>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [isSortable]="false"
        [isSearchable]="false"
        [columnName]="'status.name'"
        [title]="'Status / Notizen'"
        [customTemplate]="statusTemplate"
        [verticalAlign]="'top'"
      >
        <ng-template #statusTemplate let-partnerLead>
          <div fxLayout="column" fxLayoutAlign="space-around start">
            <div
              fxLayout="row"
              fxLayoutAlign="space-between center"
              class="lead-list-entry-margin"
            >
              <app-select
                class="lead-list-select"
                [list]="SelectList.PartnerLeadStatus"
                [appendClass]="'body'"
                [placeholder]="'Status'"
                [clearable]="false"
                [class.blue-background]="
                  partnerLead.status.option_id === PartnerLeadStatus.Neu
                "
                [class.purple-background]="
                  partnerLead.status.option_id ===
                  PartnerLeadStatus['Kontakt hergestellt']
                "
                [class.orange-background]="
                  partnerLead.status.option_id ===
                  PartnerLeadStatus['Angebot versendet & Wiedervorlage']
                "
                [class.light-red-background]="
                  partnerLead.status.option_id === PartnerLeadStatus.Verloren
                "
                [class.yellow-background]="
                  partnerLead.status.option_id ===
                    PartnerLeadStatus['Auftrag erhalten'] ||
                  partnerLead.status.option_id ===
                    PartnerLeadStatus['Kundenseitige Auftragsmeldung'] ||
                  partnerLead.status.option_id ===
                    PartnerLeadStatus['Auftrag erhalten & Projekt beendet']
                "
                [selectedValue]="partnerLead.status.option_id"
                (valueChanged)="
                  setLeadStatus($event, partnerLead, partnerLead.status)
                "
              ></app-select>
            </div>

            <div
              fxLayout="row"
              fxLayoutAlign="space-between center"
              class="lead-list-entry-margin"
              *ngIf="
                partnerLead.status.option_id === PartnerLeadStatus.Verloren
              "
            >
              <app-select
                class="lead-list-select"
                [list]="SelectList.PartnerLeadCancelReason"
                [appendClass]="'body'"
                [placeholder]="'Verlustgrund'"
                [clearable]="false"
                [selectedValue]="partnerLead.cancel_reason_id"
                (valueChanged)="setLeadCancelReason($event, partnerLead)"
              ></app-select>
            </div>

            <div
              fxLayout="row"
              fxLayoutAlign="space-between center"
              class="lead-list-entry-margin"
              *ngIf="
                partnerLead.status.option_id === PartnerLeadStatus.Verloren &&
                (partnerLead.cancel_reason_id ===
                  PartnerCancelReason['Sonstiges (manuelle Eingabe)'] ||
                  partnerLead.cancel_reason_id ===
                    PartnerCancelReason['Wettbewerber beauftragt'])
              "
            >
              <mat-form-field fxFill class="lead-list-input">
                <mat-label>
                  <span
                    *ngIf="
                      partnerLead.cancel_reason_id ===
                      PartnerCancelReason['Sonstiges (manuelle Eingabe)']
                    "
                    >Verlustgrund</span
                  >
                  <span
                    *ngIf="
                      partnerLead.cancel_reason_id ===
                      PartnerCancelReason['Wettbewerber beauftragt']
                    "
                    >Name Wettbewerber</span
                  >
                </mat-label>
                <input
                  matInput
                  [(ngModel)]="partnerLead.cancel_reason"
                  (change)="onCancelReasonChange(partnerLead)"
                />
              </mat-form-field>
            </div>

            <div
              class="lead-list-entry-margin lead-response-success-box"
              *ngIf="
                partnerLead.lead?.lead_response === 1 &&
                partnerLead.lead?.lead_response_success_partner_lead_id ===
                  partnerLead.id
              "
            >
              Auftragsmeldung vom Lead
              {{ partnerLead.lead?.lead_response_at | customDate }}
            </div>

            <div
              class="lead-list-entry-margin lead-response-info-box"
              *ngIf="
                partnerLead.lead?.lead_response === 2 ||
                (partnerLead.lead?.lead_response === 1 &&
                  partnerLead.lead?.lead_response_success_partner_lead_id !==
                    partnerLead.id)
              "
            >
              Rückmeldung vom Lead: Anderer Anbieter
              {{ partnerLead.lead?.lead_response_at | customDate }}
            </div>

            <div
              class="lead-list-entry-margin lead-response-info-box"
              *ngIf="partnerLead.lead?.lead_response === 3"
            >
              Rückmeldung vom Lead: Noch nicht entschieden
              {{ partnerLead.lead?.lead_response_at | customDate }}
            </div>

            <div
              class="lead-list-entry-margin lead-response-danger-box"
              *ngIf="partnerLead.lead?.lead_response === 4"
            >
              Rückmeldung vom Lead: Projekt abgesagt
              {{ partnerLead.lead?.lead_response_at | customDate }}
            </div>

            <ng-container
              *ngIf="partnerLead.reminders?.length > 0; else noReminder"
            >
              <div
                [ngClass]="getBadgeClass(partnerLead.reminders[0])"
                class="lead-list-entry-margin"
              >
                Nächste Erinnerung:
                {{ formatDate(partnerLead.reminders[0].reminder_date) }}
                &nbsp;({{ partnerLead.reminders[0].reminder_type_option.name }})
              </div>
            </ng-container>

            <ng-template #noReminder>
              <div class="lead-list-entry-margin">
                <em>Keine Erinnerung vorhanden</em>
              </div>
            </ng-template>

            <ng-container
              *ngIf="partnerLead.comment_summaries?.length > 0; else noSummary"
            >
              <div class="lead-list-entry-margin" style="max-width: 500px">
                {{ partnerLead.comment_summaries[0].summary }}
              </div>
            </ng-container>

            <ng-template #noSummary>
              <div class="lead-list-entry-margin" style="max-width: 500px">
                <em>Keine Zusammenfassung vorhanden</em>
              </div>
            </ng-template>

            <button
              style="margin-bottom: 10px"
              mat-stroked-button
              color="primary"
              [routerLink]="['/leads/edit', partnerLead.id]"
            >
              Kommentar erstellen
            </button>
          </div>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        *ngIf="(partnerUser$ | async)?.invoice_and_performance_data"
        [isSortable]="false"
        [isSearchable]="false"
        [title]="'Rechnung erfassen'"
        [customTemplate]="invoiceTemplate"
        [verticalAlign]="'top'"
      >
        <ng-template #invoiceTemplate let-partnerLead>
          <div
            fxLayout="column"
            fxLayoutAlign="space-around start"
            fxLayoutGap="10px"
            style="margin-top: 10px"
          >
            <button
              class="btn btn-success btn-success btn-sm"
              (click)="captureInvoice(partnerLead)"
            >
              Rechnung erfassen
            </button>
            <div
              *ngIf="
                partnerLead.bills &&
                partnerLead.bills.length > 0 &&
                partnerLead.umsatz != null
              "
            >
              Bisher erfasst: {{ partnerLead.umsatz | priceFormat }}
            </div>
            <ng-container
              *ngIf="
                partnerLead.status.option_id ===
                PartnerLeadStatus['Auftrag erhalten']
              "
            >
              <div [innerHTML]="getMissingLastBill(partnerLead)"></div>
            </ng-container>
          </div>
        </ng-template>
      </lib-table-column>
    </lib-table>
  </app-card-body>
</app-card>
