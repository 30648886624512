import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { SelectList } from '../../models/select-list';
import { LeadType } from '../../models/lead-type';
import { OptionlistOption } from '../../models/optionlist/optionlist-option';
import { Partner } from '../../models/partner';
import { LeadTypeService } from '../../services/lead-type/lead-type.service';
import { OptionlistsService } from '../../services/option-lists/optionlists.service';
import { LeadFieldService } from '../../services/leadfields/leadfields.service';
import { PartnersService } from '../../services/partners.service';
import { DomainsService } from '../../services/domains/domains.service';
import { AccountingAccountService } from '../../services/accounting_account.service';
import { Option } from '../../models/option';
import { LeadField } from '../../models/leads/lead-field';
import { AccountingAccount } from '../../models/accounting_account';
import { Domain } from '../../models/domain';
import { PartnerUserService } from '../../services/partner/partner-user.service';
import { PartnerUser } from '../../models/partners/partner-user';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AccountingService } from '../../../../leadmanager/src/app/_services/accounting/accounting.service';
import { StorageService } from '../../services/storage.service';

/**
 * @deprecated Diese Komponente ist veraltet, bitte nutze lib-select
 */
@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedValue: any = '';
  @Input() list: SelectList = SelectList.None;
  @Input() placeholder: string = '';
  @Input() filterRelations: { lead_type_id: number[] } = { lead_type_id: [] };
  @Input() multiSelect: boolean = false;
  @Input() clearable: boolean = true;
  @Input() appendClass: string = '';
  @Input() disableItem: number = 0;
  @Input() disabled: boolean = false;

  @Output() valueChanged = new EventEmitter<void>();
  @Output() valueAdded = new EventEmitter<void>();
  @Output() valueRemoved = new EventEmitter<void>();

  options: Option[] = [];
  optionsTemps: Option[] = [];
  SelectList = SelectList;

  private destroy$ = new Subject<void>();

  constructor(
    private leadTypeService: LeadTypeService,
    private optionlistService: OptionlistsService,
    private leadFieldService: LeadFieldService,
    private partnerService: PartnersService,
    private domainService: DomainsService,
    private accountingAccountService: AccountingAccountService,
    private partnerUserService: PartnerUserService,
    private accountingService: AccountingService,
    private storageService: StorageService,
  ) {}

  ngOnInit(): void {
    if (this.list === SelectList.None) {
      console.error('Select list is not set');
    }

    if (this.list === SelectList.LeadType) {
      this.options = this.storageService.getFromServiceStorage(
        'select_options_lead_types',
      );
      if (!this.options) {
        this.leadTypeService
          .getList()
          .pipe(takeUntil(this.destroy$))
          .subscribe((leadTypes: LeadType[]) => {
            this.options = leadTypes.map(
              (leadType) =>
                new Option({ value: leadType.id, name: leadType.name }),
            );
            this.storageService.setInServiceStorage(
              'select_options_lead_types',
              this.options,
            );
          });
      }
    }

    if (this.list === SelectList.LeadStatus) {
      this.getOptionsByKeyword('lead_status');
    }

    if (this.list === SelectList.PartnerLeadCancelReason) {
      this.getOptionsByKeyword('cancel_reason');
    }

    if (this.list === SelectList.PartnerLeadStatus) {
      this.getOptionsByKeyword('partner_status');
    }

    if (this.list === SelectList.TargetingStatus) {
      this.getOptionsByKeyword('targeting_status');
    }

    if (this.list === SelectList.LeadResponse) {
      this.getOptionsByKeyword('lead_response');
    }

    if (this.list === SelectList.PartnerResponse) {
      this.getOptionsByKeyword('partner_response');
    }

    if (this.list === SelectList.PartnerBillStatus) {
      this.getOptionsByKeyword('bill_status');
    }

    if (this.list === SelectList.PartnerAcquisitionStatus) {
      this.getOptionsByKeyword('partner_acquisition_status');
    }

    if (this.list === SelectList.PartnerCancelReason) {
      this.getOptionsByKeyword('partner_cancel_reason');
    }

    if (this.list === SelectList.LeadDetail) {
      this.options = this.storageService.getFromServiceStorage(
        'select_options_lead_detail',
      );
      if (!this.options) {
        this.leadFieldService
          .list({ filter: { type: 5 } })
          .pipe(takeUntil(this.destroy$))
          .subscribe((leadFields: LeadField[]) => {
            this.options = leadFields?.reduce((acc, leadField) => {
              const fieldOptions = leadField.optionlist?.options?.map(
                (option: OptionlistOption) =>
                  new Option({
                    value: {
                      lead_type_id: leadField.lead_type_id,
                      lead_type_name: leadField.lead_type?.name,
                      lead_field_id: leadField.id,
                      lead_field_name: leadField.name,
                      option_id: option.option_id,
                      option_name: option.name,
                    },
                    name:
                      leadField.lead_type?.name +
                      '<br/>' +
                      leadField.name +
                      ' | ' +
                      option.name,
                  }),
              );

              return acc.concat(fieldOptions);
            }, [] as Option[]);
            this.storageService.setInServiceStorage(
              'select_options_lead_detail',
              this.options,
            );
            this.optionsTemps = this.options;
          });
      }

      if (this.filterRelations.lead_type_id.length > 0) {
        this.optionsTemps = this.getLeadDetailFilterRelations();
      } else {
        this.optionsTemps = this.options;
      }
    }

    if (this.list === SelectList.Partner) {
      this.options = this.storageService.getFromServiceStorage(
        'select_options_partners',
      );

      if (!this.options) {
        this.partnerService
          .list({ pageSize: 10000, start: 0, page: 1 })
          .pipe(takeUntil(this.destroy$))
          .subscribe((partners: Partner[]) => {
            partners.sort((a, b) => a.name.localeCompare(b.name));

            this.options = partners.map((partner) => {
              const option = new Option({
                value: partner.id,
                name: partner.name,
              });
              // Check if the partner.id is in the disableitem array

              if (this.disableItem == partner.id) {
                option.disabled = true;
              }
              return option;
            });
            this.storageService.setInServiceStorage(
              'select_options_partners',
              this.options,
            );
          });
      } else {
        this.options = this.options.map((partner) => {
          if (this.disableItem == partner.value) {
            partner.disabled = true;
          } else {
            partner.disabled = false;
          }
          return partner;
        });
        this.storageService.setInServiceStorage(
          'select_options_partners',
          this.options,
        );
      }
    }

    if (this.list === SelectList.Domain) {
      this.options = this.storageService.getFromServiceStorage(
        'select_options_domain',
      );
      if (!this.options) {
        this.domainService
          .getList()
          .pipe(takeUntil(this.destroy$))
          .subscribe((domains: Domain[]) => {
            this.options = domains.map(
              (domain) =>
                new Option({ value: domain.domain, name: domain.domain }),
            );
          });
      }
    }

    if (this.list === SelectList.PartnerStatus) {
      this.options.push(new Option({ value: 0, name: 'Inaktiv' }));
      this.options.push(new Option({ value: 1, name: 'Aktiv' }));
    }

    if (this.list === SelectList.LeadCalledStatus) {
      this.options.push(new Option({ value: 0, name: 'Alle' }));
      this.options.push(
        new Option({ value: 1, name: 'Ohne aktives Gespräch' }),
      );
      this.options.push(new Option({ value: 2, name: 'Mit aktivem Gespräch' }));
      this.options.push(new Option({ value: 3, name: 'Ohne Anrufversuch' }));
      this.options.push(new Option({ value: 4, name: 'Mit Anrufversuch' }));
      this.options.push(
        new Option({ value: 5, name: 'Mindestens 1 eingehender Anruf' }),
      );
      this.options.push(
        new Option({ value: 6, name: 'Erster Anruf ist eingehend' }),
      );
      this.options.push(
        new Option({ value: 7, name: 'Erster Anruf ist ausgehend' }),
      );
    }

    if (this.list === SelectList.BillStatus) {
      this.options.push(
        new Option({ value: 'verarbeitet', name: 'verarbeitet' }),
      );
      this.options.push(new Option({ value: 'verbucht', name: 'verbucht' }));
      this.options.push(
        new Option({ value: 'verbucht & bezahlt', name: 'verbucht & bezahlt' }),
      );
    }

    if (this.list === SelectList.AccountingAccount) {
      this.options = this.storageService.getFromServiceStorage(
        'select_accounting_accounts',
      );
      if (!this.options) {
        this.accountingAccountService
          .getList()
          .pipe(takeUntil(this.destroy$))
          .subscribe((accounting_accounts: AccountingAccount[]) => {
            this.options = accounting_accounts.map(
              (accounting_account) =>
                new Option({
                  value: accounting_account.account_number,
                  name: accounting_account.account_name,
                }),
            );
          });
      }
    }

    if (this.list === SelectList.PartnerUser) {
      this.options = this.storageService.getFromServiceStorage(
        'select_options_partner_users',
      );
      if (!this.options) {
        this.partnerUserService
          .getList()
          .pipe(takeUntil(this.destroy$))
          .subscribe((partnerUsers: PartnerUser[]) => {
            if (partnerUsers) {
              this.options = partnerUsers.map(
                (partnerUser) =>
                  new Option({
                    value: partnerUser.id,
                    name: partnerUser.first_name + ' ' + partnerUser.last_name,
                  }),
              );
              this.storageService.setInServiceStorage(
                'select_options_partner_users',
                this.options,
              );
            }
          });
      }
    }

    if (this.list === SelectList.PartnerLeadDetail) {
      this.getPartnerLeadDetail();
    }

    if (this.list === SelectList.PartnerUserSalesrunner) {
      this.options = this.storageService.getFromServiceStorage(
        'select_options_partner_user_salesrunners',
      );
      if (!this.options) {
        this.partnerUserService
          .getPartnerUsersForAdmin()
          .pipe(takeUntil(this.destroy$))
          .subscribe((partnerUsers: PartnerUser[]) => {
            if (partnerUsers) {
              this.options = partnerUsers.map(
                (partnerUser) =>
                  new Option({
                    value: partnerUser.id,
                    name: partnerUser.first_name + ' ' + partnerUser.last_name,
                  }),
              );
              this.storageService.setInServiceStorage(
                'select_options_partner_user_salesrunners',
                this.options,
              );
            }
          });
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['filterRelations'] &&
      (this.list === SelectList.LeadType || this.list === SelectList.LeadDetail)
    ) {
      this.optionsTemps = this.getLeadDetailFilterRelations();
    }
    if (
      changes['filterRelations'] &&
      (this.list === SelectList.LeadType ||
        this.list === SelectList.PartnerLeadDetail)
    ) {
      this.getPartnerLeadDetail();
    }
  }

  getLeadDetailFilterRelations() {
    if (this.filterRelations.lead_type_id) {
      return this.options.filter((option: Option) => {
        return this.filterRelations.lead_type_id.includes(
          option.value.lead_type_id,
        );
      });
    } else {
      return this.options;
    }
  }

  getPartnerLeadDetail() {
    this.leadFieldService
      .list({
        filter: { type: 5, lead_type_id: this.filterRelations.lead_type_id },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((leadFields: LeadField[]) => {
        this.options = leadFields?.reduce((acc, leadField) => {
          const fieldOptions = leadField.optionlist?.options?.map(
            (option: OptionlistOption) =>
              new Option({
                value: {
                  lead_type_id: leadField.lead_type_id,
                  lead_type_name: leadField.lead_type?.name,
                  lead_field_id: leadField.id,
                  lead_field_name: leadField.name,
                  option_id: option.option_id,
                  option_name: option.name,
                },
                name:
                  leadField.lead_type?.name +
                  '<br/>' +
                  leadField.name +
                  ' | ' +
                  option.name,
              }),
          );

          return acc.concat(fieldOptions);
        }, [] as Option[]);
      });
  }

  /*
   * Diese Funktion wird benötigt damit das Select für LeadDetails (OptionlistOption) korrekt funktioniert
   * Eine OptionlistOption ist nur eindeutig identifizierbar durch die Kombination von option_id und lead_field_id
   */
  compareFn(o1: any, o2: any): boolean {
    return o1.value?.option_id
      ? o1.value.option_id == o2.option_id &&
          o1.value.lead_field_id == o2.lead_field_id
      : o1.value === o2;
  }

  emitValueChanged() {
    this.valueChanged.emit(this.selectedValue);
  }

  onAdd(event: any) {
    this.valueAdded.emit(event);
  }

  onRemove(event: any) {
    this.valueRemoved.emit(event);
  }

  getOptionsByKeyword(keyword: string) {
    this.options = this.storageService.getFromServiceStorage(
      'select_options_' + keyword,
    );

    if (!this.options) {
      this.optionlistService
        .optionlistByKeyword(keyword)
        .pipe(takeUntil(this.destroy$))
        .subscribe((optionlistOptions: OptionlistOption[]) => {
          if (optionlistOptions.length > 0) {
            this.options = optionlistOptions.map(
              (optionlistOption) =>
                new Option({
                  value: optionlistOption.option_id,
                  name: optionlistOption.name,
                }),
            );
            this.storageService.setInServiceStorage(
              'select_options_' + keyword,
              this.options,
            );
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
