import { Component, EventEmitter, HostBinding, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { DateTimePickerService } from '../../../services/date-time-picker/date-time-picker.service';

const customPresets = [
  'Heute',
  'Gestern',
  'Diese Woche',
  'Letzte 7 Tage',
  'Letzte 30 Tage',
  'Dieser Monat',
  'Letzter Monat',
  'Dieses Jahr',
  'Letztes Jahr',
  'Gesamte Zeit',
] as const;

type CustomPreset = (typeof customPresets)[number];

@Component({
  selector: 'app-daterange-preset',
  templateUrl: './daterange-preset.component.html',
  styleUrls: ['./daterange-preset.component.scss'],
})
export class DaterangePresetComponent<D> {
  @Output() pickPreset = new EventEmitter<void>();
  readonly customPresets = customPresets;

  constructor(
    private dateAdapter: DateAdapter<D>,
    private picker: MatDateRangePicker<D>,
    private dateTimePickerService: DateTimePickerService,
  ) {}

  selectRange(rangeName: CustomPreset): void {
    const [start, end] = this.calculateDateRange(rangeName);

    this.picker.open();
    this.picker.select(start);
    this.picker.select(end);
    this.picker.close();

    this.dateTimePickerService.triggerEvent({ start, end });
  }

  private calculateDateRange(rangeName: CustomPreset): [start: D, end: D] {
    const today = this.today;
    const year = this.dateAdapter.getYear(today);

    switch (rangeName) {
      case 'Heute':
        return [today, today];
      case 'Gestern':
        return [
          this.dateAdapter.addCalendarDays(today, -1),
          this.dateAdapter.addCalendarDays(today, -1),
        ];
      case 'Diese Woche':
        return this.calculateWeek(today);
      case 'Letzte 7 Tage':
        return [this.dateAdapter.addCalendarDays(today, -6), today];
      case 'Letzte 30 Tage':
        return [this.dateAdapter.addCalendarDays(today, -29), today];
      case 'Letzter Monat':
        return this.calculateMonth(
          this.dateAdapter.addCalendarMonths(today, -1),
        );
      case 'Dieser Monat':
        return this.calculateMonth(today);
      case 'Letztes Jahr':
        return [
          this.dateAdapter.createDate(year - 1, 0, 1),
          this.dateAdapter.createDate(year - 1, 11, 31),
        ];
      case 'Dieses Jahr':
        return [
          this.dateAdapter.createDate(year, 0, 1),
          this.dateAdapter.createDate(year, 11, 31),
        ];
      case 'Gesamte Zeit':
        return [this.dateAdapter.createDate(2020, 2, 1), today];
      default:
        return [today, today] as never;
    }
  }

  private calculateMonth(forDay: D): [start: D, end: D] {
    const year = this.dateAdapter.getYear(forDay);
    const month = this.dateAdapter.getMonth(forDay);
    const start = this.dateAdapter.createDate(year, month, 1);
    const end = this.dateAdapter.addCalendarDays(
      start,
      this.dateAdapter.getNumDaysInMonth(forDay) - 1,
    );
    return [start, end];
  }

  private calculateWeek(forDay: D): [start: D, end: D] {
    const deltaStart =
      this.dateAdapter.getFirstDayOfWeek() -
      this.dateAdapter.getDayOfWeek(forDay);
    const start = this.dateAdapter.addCalendarDays(forDay, deltaStart);
    const end = this.dateAdapter.addCalendarDays(start, 6);
    return [start, end];
  }

  private get today(): D {
    const today = this.dateAdapter.today();
    if (today === null) {
      throw new Error('Date creation failed');
    }
    return today;
  }
}
