import {
  Component,
  Input,
  ElementRef,
  NgZone,
  Inject,
  Optional,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Overlay, OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() text: string = '';
  visible: boolean = false;
  isPositioned: boolean = false;

  constructor(
    private elementRef: ElementRef,
    private ngZone: NgZone,
    private overlay: Overlay,
    @Inject(DOCUMENT) private document: Document,
    @Optional() private overlayContainer: OverlayContainer,
  ) {}

  showTooltip() {
    if (!this.text) return;

    this.visible = true;
    this.isPositioned = false;

    // Wait for tooltip to be rendered
    setTimeout(() => {
      const tooltipElement =
        this.elementRef.nativeElement.querySelector('.tooltip-content');
      const triggerElement =
        this.elementRef.nativeElement.querySelector('.tooltip-container');
      if (!tooltipElement || !triggerElement) return;

      // Move tooltip to overlay container if we're in an overlay context
      const overlayContainerElement =
        this.overlayContainer?.getContainerElement();
      if (overlayContainerElement && this.isInOverlay(triggerElement)) {
        overlayContainerElement.appendChild(tooltipElement);
      }

      const triggerRect = triggerElement.getBoundingClientRect();
      const tooltipRect = tooltipElement.getBoundingClientRect();

      // Calculate initial position (centered below trigger)
      let top = triggerRect.bottom + 5;
      let left =
        triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2;

      // Get the overlay bounds if we're in an overlay
      let boundingContainer = this.document.documentElement;
      const overlayElement = this.findOverlayElement(triggerElement);
      if (overlayElement) {
        boundingContainer = overlayElement;
      }

      // Adjust for viewport/overlay boundaries
      const containerRect = boundingContainer.getBoundingClientRect();
      const maxWidth = containerRect.width;
      const maxHeight = containerRect.height;

      // Prevent horizontal overflow
      if (left + tooltipRect.width > containerRect.right) {
        left = containerRect.right - tooltipRect.width - 10;
      }
      if (left < containerRect.left + 10) {
        left = containerRect.left + 10;
      }

      // Always show below, adjust left/right position only
      top = triggerRect.bottom + 5;

      // Apply position
      tooltipElement.style.top = `${top}px`;
      tooltipElement.style.left = `${left}px`;

      // Show tooltip after positioning
      this.isPositioned = true;
    });
  }

  hideTooltip() {
    this.visible = false;
    this.isPositioned = false;

    // Clean up any appended tooltip elements
    const tooltipElement =
      this.elementRef.nativeElement.querySelector('.tooltip-content');
    if (
      tooltipElement &&
      tooltipElement.parentElement !== this.elementRef.nativeElement
    ) {
      this.elementRef.nativeElement.appendChild(tooltipElement);
    }
  }

  private isInOverlay(element: HTMLElement): boolean {
    return !!this.findOverlayElement(element);
  }

  private findOverlayElement(element: HTMLElement): HTMLElement | null {
    let current = element;
    while (current && current !== this.document.body) {
      if (current.classList.contains('cdk-overlay-pane')) {
        return current;
      }
      current = current.parentElement as HTMLElement;
    }
    return null;
  }
}
