import { Component } from '@angular/core';
import {
  ButtonType,
  ButtonVariant,
  ButtonIconColor,
} from '../../../../button/button.component';

@Component({
  selector: 'lib-basic-badge-example',
  template: BasicFeedButtonExample.HTML_CODE,
})
export class BasicFeedButtonExample {
  ButtonVariant = ButtonVariant;
  ButtonType = ButtonType;
  ButtonIconColor = ButtonIconColor;

  static HTML_CODE = `
    <section class="space-y-4">
      <h3>Mit/Ohne Schatten</h3>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <lib-button [type]="ButtonType.CHECK" label="Abgeschlossen"> </lib-button>
        <lib-button
          [type]="ButtonType.CHECK"
          [variant]="ButtonVariant.SHADOW"
          label="Abgeschlossen"
        >
        </lib-button>
      </div>
    </section>
   
    <section class="space-y-4">
      <h3>Verfügbare Button</h3>
      <div fxLayout="column" fxLayoutAlign="space-around start" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <lib-button [type]="ButtonType.CHECK" label="Abgeschlossen"></lib-button>
          <lib-button [type]="ButtonType.COMMENT" label="Kommentar"></lib-button>
          <lib-button [type]="ButtonType.DELETE" label="Löschen"></lib-button>
          <lib-button [type]="ButtonType.EDIT" label="Bearbeiten"></lib-button>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <lib-button
            [type]="ButtonType.EXTERNAL_LINK"
            label="SR Ansicht"
          ></lib-button>
          <lib-button [type]="ButtonType.EYE" label="Sichtbar im SR"></lib-button>
          <lib-button [type]="ButtonType.MAIL" label="Anzeigen"></lib-button>
          <lib-button [type]="ButtonType.THUMBS_UP" label="Reagieren"></lib-button>
        </div>
      </div>
    </section> 
    
    <section class="space-y-4">
      <h3>Button ohne label</h3>
      <div fxLayout="column" fxLayoutAlign="space-around start" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <lib-button [type]="ButtonType.CHECK" ></lib-button>
          <lib-button [type]="ButtonType.COMMENT"></lib-button>
          <lib-button [type]="ButtonType.DELETE" ></lib-button>
          <lib-button [type]="ButtonType.EDIT" ></lib-button>
                  <lib-button
            [type]="ButtonType.EXTERNAL_LINK"
          ></lib-button>
             <lib-button [type]="ButtonType.EYE"></lib-button>
          <lib-button [type]="ButtonType.MAIL"></lib-button>
          <lib-button [type]="ButtonType.THUMBS_UP"></lib-button>
        </div>
      </div>
    </section>
    
    
    <section class="space-y-4">
      <h3>Button mit Aktion</h3>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <lib-button 
          [type]="ButtonType.THUMBS_UP"
          emoji="😀"
          [count]="2">
        </lib-button>
        
        <lib-button 
          [type]="ButtonType.THUMBS_UP"
          [count]="20">
        </lib-button>
        
        <lib-button 
          [type]="ButtonType.EYE"
          [iconColor]="ButtonIconColor.SUCCESS">
        </lib-button>
        
        <lib-button 
          [type]="ButtonType.EYE"
          [iconColor]="ButtonIconColor.WARNING">
        </lib-button>
        
        <lib-button 
          [type]="ButtonType.EYE"
          [iconColor]="ButtonIconColor.DANGER">
        </lib-button>
        
        <lib-button 
          [type]="ButtonType.COMMENT"
          emoji="🔥"
          [count]="2"
          label="Kommentar">
        </lib-button>
        
        <lib-button 
          [type]="ButtonType.COMMENT"
          [count]="2"
          label="Kommentar">
        </lib-button>
        
        
        <lib-button 
          [type]="ButtonType.COMMENT"
          label="Kommentar"
          [disabled]="true">
        </lib-button>
      </div>
    </section>
  `;
}
