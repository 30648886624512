import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PartnerBill } from '@lib/models/partners/partner-bill';
import { HttpHandlerService } from '@lib/services/http-handler.service';

@Injectable({
  providedIn: 'root',
})
export class PartnerBillService {
  endpoint: string = 'partner_bills/';
  endpointInitPartnerBill: string = 'init_bill_positions/';

  constructor(private httpHandler: HttpHandlerService) {}

  list(): Observable<PartnerBill[]> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  getList(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  show(id: number): Observable<any> {
    return this.httpHandler.show(this.endpoint, id);
  }

  create(partnerBill: PartnerBill): Observable<PartnerBill> {
    return this.httpHandler.create(this.endpoint, partnerBill);
  }

  update(partnerBill: PartnerBill): Observable<PartnerBill> {
    return this.httpHandler.update(this.endpoint, partnerBill);
  }

  delete(id: number): Observable<any> {
    return this.httpHandler.delete(this.endpoint, id);
  }

  initBillPositions(partner_lead_id: number): Observable<any> {
    return this.httpHandler
      .list(this.endpointInitPartnerBill, { partner_lead_id })
      .pipe(map((response) => response.data));
  }
}

// Path: partner-bill.component.ts
