import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Lead } from '../../models/leads/lead';
import { HttpHandlerService } from '../http-handler.service';
import { EnvironmentService } from '../environment.service';
import { PartnerPrice } from '../../models/partner-price';
import { tap, catchError } from 'rxjs/operators';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { PriceNumberFormatHelper } from '../../helpers/price-number-format-helper';
import { UrlHelper } from '../../helpers/url-helper';

@Injectable({
  providedIn: 'root',
})
export class LeadService {
  endpoint: string = 'leads/';
  endpointTargetingByLead: string = 'targetingbylead/';
  endpointgetForward: string = 'leads/sell/';
  endpointCallHistory: string = 'show_call_history/';
  endpointDsgvoDelete: string = 'dsgvo_delete_lead/';
  endpointImportanceScore: string = 'update_importance_score/';

  private leadSubject = new BehaviorSubject<Lead>(new Lead({}));
  leadObservable = this.leadSubject.asObservable();

  private leadTargetingPartnersSubject = new BehaviorSubject<any>(null);
  leadTargetingPartners$ = this.leadTargetingPartnersSubject.asObservable();

  private newLeadsSubject = new BehaviorSubject<Lead[]>([]);
  newLeads$ = this.newLeadsSubject.asObservable();

  constructor(
    private httpHandler: HttpHandlerService,
    protected http: HttpClient,
    private environmentService: EnvironmentService,
    private snackbarService: SnackBarService,
  ) {}

  notifyLeadChangeObservable(lead: Lead) {
    this.leadSubject.next(lead);
  }

  handleNewLead(lead: Lead) {
    const currentLeads = this.newLeadsSubject.value;
    this.newLeadsSubject.next([...currentLeads, lead]);

    this.snackbarService.showAngularSnackbar({
      data: {
        title: 'Neuer Lead',
        content: [
          `#${lead.number}`,
          `Leadart: ${lead.lead_type.name}`,
          `Leadwert: ${PriceNumberFormatHelper.formatWithThousandsSeparator(
            lead.provision_per_lead_in,
          )} €`,
        ],
        actionButton: {
          label: 'Öffnen',
          callback: () => {
            const finalUrl = UrlHelper.getUrlWithBasePath(
              `/leads/edit/${lead.id}`,
            );
            window.open(finalUrl, '_blank');
          },
        },
        gradientColor: {
          color: '60, 179, 113',
          opacity:
            0.2 + Math.min(200 / Number(lead.provision_per_lead_in), 0.8),
        },
      },
      duration: 20000,
    });
  }

  clearNewLeadsNotifications() {
    this.newLeadsSubject.next([]);
  }

  clearLeadTargetingPartnerObservable() {
    this.leadTargetingPartnersSubject.next(null);
  }

  list(httpParams: any): Observable<any> {
    return this.httpHandler.list(this.endpoint, httpParams);
  }

  targetingByLead(
    leadData: { lead: Partial<Lead> },
    forceReload = false,
  ): Observable<any> {
    if (!forceReload && (!leadData.lead.status || leadData.lead.status == 3)) {
      return new Observable();
    }

    const params = new HttpParams().set('lead', JSON.stringify(leadData.lead));

    return this.http
      .get(`${this.environmentService.apiUrl}${this.endpointTargetingByLead}`, {
        params: params,
      })
      .pipe(
        tap((response: any) => {
          this.leadTargetingPartnersSubject.next(response);
        }),
        catchError((error) => {
          console.error('Fehler beim Abrufen der Targeting-Partner:', error);
          throw error;
        }),
      );
  }

  reloadTargetingPartners(lead: Lead, forceReload = false): void {
    this.targetingByLead(
      {
        lead: {
          id: lead.id,
          status: lead.status,
          address: lead.address,
          addresses: lead.addresses,
          lead_type_id: lead.lead_type_id,
          lead_details: lead.lead_details,
        },
      },
      forceReload,
    ).subscribe({
      next: (response: any) => this.leadTargetingPartnersSubject.next(response),
    });
  }

  show(id: number): Observable<any> {
    return this.httpHandler.show(this.endpoint, id);
  }

  create(lead: Lead): Observable<any> {
    return this.httpHandler.create(this.endpoint, lead);
  }

  update(lead: Lead): Observable<any> {
    return this.httpHandler.update(this.endpoint, lead);
  }

  delete(id: number): Observable<any> {
    return this.httpHandler.delete(this.endpoint, id);
  }

  forward(
    lead: Lead,
    targetings: any,
    selectedPrices?: PartnerPrice[],
  ): Observable<any> {
    return this.http.post(
      `${this.environmentService.apiUrl}${this.endpointgetForward}${lead.id}`,
      { targetings, selectedPrices },
      { responseType: 'json' },
    );
  }

  updateImportanceScore(lead: Lead): Observable<any> {
    return this.http.get(
      `${this.environmentService.apiUrl}${this.endpointImportanceScore}${lead.id}`,
      { responseType: 'json' },
    );
  }

  leadCallHistory(id: number): Observable<any> {
    return this.httpHandler.list(`${this.endpointCallHistory}${id}`, {});
  }

  export(httpParams: any): Observable<any> {
    return this.httpHandler.export(this.endpoint, httpParams);
  }

  dsgvoDelete(leadId: number, leadNumber: string): Observable<any> {
    return this.http.post(
      `${this.environmentService.apiUrl}${this.endpointDsgvoDelete}${leadId}`,
      { lead_number: leadNumber },
      { responseType: 'json' },
    );
  }
}
