import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PartnerLead } from '@lib/models/partner-lead';

@Injectable({
  providedIn: 'root',
})
export class LeadStateService {
  private selectedLeadId = new BehaviorSubject<number | null>(null);
  selectedLeadId$ = this.selectedLeadId.asObservable();

  private partnerLeads = new BehaviorSubject<PartnerLead[]>([]);
  partnerLeads$ = this.partnerLeads.asObservable();

  private leadUpdated = new Subject<void>();
  leadUpdated$ = this.leadUpdated.asObservable();

  setSelectedLead(id: number | null) {
    this.selectedLeadId.next(id);
  }

  updatePartnerLeads(leads: PartnerLead[]) {
    this.partnerLeads.next(leads);
  }

  getPartnerLeads(): PartnerLead[] {
    return this.partnerLeads.getValue();
  }

  updateSingleLead(updatedLead: PartnerLead) {
    const currentLeads = this.partnerLeads.getValue();
    const updatedLeads = currentLeads.map((lead) =>
      lead.id === updatedLead.id ? updatedLead : lead,
    );
    this.partnerLeads.next(updatedLeads);
    this.notifyLeadUpdated();
  }

  notifyLeadUpdated() {
    this.leadUpdated.next();
  }
}
