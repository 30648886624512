<app-card>
  <app-card-head pageTitle="Budget"> </app-card-head>
  <app-card-body>
    <div class="col-12 pb-3">
      <lib-partner-budget
        [targetingId]="targetingId"
        [displaySaveButton]="true"
        [displayBorder]="false"
        [targetingBudgets]="targetingBudgets$ | async"
      ></lib-partner-budget>
    </div>
  </app-card-body>
</app-card>
<app-delete-modal></app-delete-modal>
