import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '@lib/services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class PanelStateService {
  private static readonly PANEL_WIDTH_STORAGE_KEY = 'panel_width';
  private static readonly MIN_PANEL_WIDTH = 300;

  private leftPanelWidth = new BehaviorSubject<number>(window.innerWidth);

  constructor(private storageService: StorageService) {
    this.initialize();
  }

  private initialize() {
    const storedWidth = this.storageService.getFromFromLocalStorage(
      PanelStateService.PANEL_WIDTH_STORAGE_KEY,
    );
    if (storedWidth) {
      this.leftPanelWidth.next(storedWidth);
    }
  }

  private getInitialDetailWidth(): number {
    // Nimm die Hälfte der Fensterbreite, aber mindestens MIN_PANEL_WIDTH
    return Math.max(
      Math.floor(window.innerWidth / 2),
      PanelStateService.MIN_PANEL_WIDTH,
    );
  }

  setWidth(width: number, hasDetailView: boolean = false) {
    // Wenn keine Breite im Storage ist und ein Detail View geöffnet wird,
    // setzen wir die Hälfte der Fensterbreite
    if (
      hasDetailView &&
      !this.storageService.getFromFromLocalStorage(
        PanelStateService.PANEL_WIDTH_STORAGE_KEY,
      )
    ) {
      width = this.getInitialDetailWidth();
    }

    this.leftPanelWidth.next(width);
    this.storageService.setInLocalStorage(
      PanelStateService.PANEL_WIDTH_STORAGE_KEY,
      width,
    );
  }

  ensureDetailViewWidth() {
    const currentWidth = this.getCurrentWidth();
    const storedWidth = this.storageService.getFromFromLocalStorage(
      PanelStateService.PANEL_WIDTH_STORAGE_KEY,
    );

    if (currentWidth >= window.innerWidth && !storedWidth) {
      this.setWidth(this.getInitialDetailWidth(), true);
    }
  }

  getCurrentWidth(): number {
    return this.leftPanelWidth.getValue();
  }

  resetToFullWidth() {
    const width = window.innerWidth;
    this.leftPanelWidth.next(width);
    this.storageService.setInLocalStorage(
      PanelStateService.PANEL_WIDTH_STORAGE_KEY,
      null,
    );
  }
}
