import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StorageService } from './storage.service';
import {
  TableColumn,
  TableDataStorage,
} from '../components/table/table-data.interface';
import { EnvironmentService } from './environment.service';
import { TABLE_ID_TOKEN } from './table-injection-token';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  private _columns: TableColumn[] = [];
  private _tableStates = new Map<string, TableDataStorage>();
  private readonly STORAGE_PREFIX = 'TABLE_STATE_';

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private environmentService: EnvironmentService,
    @Inject(TABLE_ID_TOKEN) private tableId: string,
  ) {}

  updateTableState(tableId: string, state: TableDataStorage) {
    this._tableStates.set(tableId, { ...state });
    this.saveTableState(tableId);
  }

  getTableState(tableId: string): TableDataStorage | undefined {
    if (!this._tableStates.has(tableId)) {
      this.loadTableState(tableId);
    }
    return this._tableStates.get(tableId);
  }

  private saveTableState(tableId: string) {
    const state = this._tableStates.get(tableId);
    if (state) {
      this.storageService.setInServiceStorage(
        `${this.STORAGE_PREFIX}${tableId}`,
        JSON.stringify(state),
      );
    }
  }

  private loadTableState(tableId: string) {
    const storedState = this.storageService.getFromServiceStorage(
      `${this.STORAGE_PREFIX}${tableId}`,
    );
    if (storedState) {
      this._tableStates.set(tableId, JSON.parse(storedState));
    }
  }

  registerColumn(column: TableColumn) {
    this._columns.push(column);
  }

  get columns(): TableColumn[] {
    this._columns.forEach((column, index) => {
      column.key = column.key || `empty-column-${index}`;
    });
    return this._columns;
  }

  set columns(value: TableColumn[]) {
    this._columns = value;
  }

  loadTableData(
    endpoint: string,
    page: number,
    pageSize: number,
    sortField: string,
    sortOrder: string,
    filter: any,
    restriction: any,
    searchValue: string,
    searchableColumns: string[],
    httpParams: HttpParams,
  ): Observable<any> {
    if (!endpoint) {
      return of(null);
    }

    let params = new HttpParams()
      .set('sortField', sortField)
      .set('sortOrder', sortOrder)
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set(
        'searchValue',
        searchValue
          ? JSON.stringify({ value: searchValue, regex: false })
          : JSON.stringify({}),
      )
      .set('filter', filter ? JSON.stringify(filter) : JSON.stringify({}))
      .set(
        'restriction',
        restriction ? JSON.stringify(restriction) : JSON.stringify({}),
      );

    // Füge die übergebenen HttpParams hinzu
    httpParams.keys().forEach((key) => {
      const value = httpParams.get(key);
      if (value !== null) {
        params = params.set(key, value);
      }
    });

    if (searchableColumns && searchableColumns.length) {
      params = params.set(
        'searchableColumns',
        JSON.stringify(searchableColumns),
      );
    }

    return this.http.get<any>(`${this.environmentService.apiUrl}${endpoint}`, {
      params,
    });
  }
}
