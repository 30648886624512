import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmailEditorComponent } from '../../../../communication/email-editor/email-editor.component';

@Component({
  selector: 'lib-email-editor-example',
  template: EmailEditorExample.HTML_CODE,
})
export class EmailEditorExample {
  static HTML_CODE = `
    <div class="example-container">
      <button mat-raised-button color="primary" (click)="openEmailEditor()">
        E-Mail Editor öffnen
      </button>

      <div class="mt-4" *ngIf="lastSentEmail">
        <p>Letzte gesendete E-Mail:</p>
        <pre>{{ lastSentEmail | json }}</pre>
      </div>
    </div>
  `;

  lastSentEmail: any = null;

  constructor(private dialog: MatDialog) {}

  openEmailEditor() {
    const dialogRef = this.dialog.open(EmailEditorComponent, {
      width: '600px',
      data: { emailAddress: 'empfaenger@beispiel.de' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.lastSentEmail = result;
      }
    });
  }
}
