import { Injectable } from '@angular/core';
import { HttpHandlerService } from '../http-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { PartnerLead } from '../../models/partner-lead';
import { EnvironmentService } from '../environment.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { ReminderService } from '../reminder.service';
import { User } from '../../models/user';
import { PartnerUser } from '../../models/partners/partner-user';
import {
  ModalFormFieldType,
  PartnerCancelReason,
  PartnerLeadStatus,
} from '../../enum-collection';
import { FormField, ModalInputData } from '../../models/modal';
import { CreateFormGroupHelper } from '../../helpers/create-form-group-helper';
import { ModalComponent } from '../../components/modal/modal.component';
import { Reminder } from '../../models/reminder';
import { LeadProjectFinishedModalComponent } from '../../components/lead-project-finished-modal/lead-project-finished-modal.component';

@Injectable({
  providedIn: 'root',
})
export class PartnerLeadService {
  endpoint: string = 'get_lead_partner_leads/';
  endpoint2: string = 'partner_leads/';
  endpointScore: string = 'partner_lead_score/';
  endpointMissingBills: string = 'check_missing_bills/';

  constructor(
    private httpHandler: HttpHandlerService,
    protected http: HttpClient,
    private environmentService: EnvironmentService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private reminderService: ReminderService,
  ) {}

  list(httpParams: any): Observable<any> {
    return this.httpHandler.list(this.endpoint, httpParams);
  }

  listPartnerLeads(httpParams: any): Observable<any> {
    return this.httpHandler.list(this.endpoint2, httpParams);
  }

  getList(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  show(id: number): Observable<any> {
    return this.httpHandler.show(this.endpoint2, id);
  }

  update(partnerLead: PartnerLead): Observable<any> {
    return this.httpHandler.update(this.endpoint2, partnerLead);
  }

  delete(id: number): Observable<any> {
    return this.httpHandler.delete(this.endpoint, id);
  }

  showPartnerLead(id: number): Observable<any> {
    return this.httpHandler.show(this.endpoint2, id);
  }

  hasMissingBills(): Observable<any> {
    return this.http.get(
      this.environmentService.apiUrl + this.endpointMissingBills + '?',
      {
        responseType: 'json',
      },
    );
  }

  export(httpParams: any): Observable<any> {
    return this.httpHandler.export(this.endpoint2, httpParams);
  }

  getPartnerLeadScoreByAuthenticatedPartnerUser(): Observable<any> {
    return this.http.get(
      this.environmentService.apiUrl + this.endpointScore + '?',
      {
        responseType: 'json',
      },
    );
  }

  acceptPartnerLead(partnerLead: PartnerLead): Observable<any> {
    return this.http.get(
      this.environmentService.apiUrl + 'accept_lead/' + partnerLead.id + '?',
      {
        responseType: 'json',
      },
    );
  }

  declinePartnerLead(
    partnerLead: PartnerLead,
    optionlist_option_id: number,
  ): Observable<any> {
    return this.http.get(
      this.environmentService.apiUrl +
        'decline_lead/' +
        partnerLead.lead_auth_token +
        '/' +
        partnerLead.id +
        '/' +
        optionlist_option_id +
        '?',
      {
        responseType: 'json',
      },
    );
  }

  getPartnerLeadChanceAmount(): Observable<any> {
    return this.http.get<{ count: number }>(
      this.environmentService.apiUrl + 'partner_lead_chances_amount/?',
      {
        responseType: 'json',
      },
    );
  }

  handleStatusChange(
    newStatus: string | number,
    partnerLead: PartnerLead,
    authUser: User | PartnerUser | null,
    onLeadUpdated: (lead: PartnerLead) => void,
    onReminderCreated?: () => void,
  ): void {
    const newStatusId = Number(newStatus);
    const currentStatusId = partnerLead.status?.option_id;

    const isProjectFinished =
      newStatusId === PartnerLeadStatus['Auftrag erhalten & Projekt beendet'];
    const isLost = newStatusId === PartnerLeadStatus.Verloren;
    const isReminderRequired =
      newStatusId === PartnerLeadStatus['Angebot versendet & Wiedervorlage'];

    if (isProjectFinished) {
      this.handleProjectFinished(
        partnerLead,
        newStatusId,
        currentStatusId,
        onLeadUpdated,
      );
    } else if (isLost) {
      this.handleLeadLost(partnerLead, newStatusId, onLeadUpdated);
    } else {
      this.handleOtherStatus(
        partnerLead,
        newStatusId,
        currentStatusId,
        authUser,
        onLeadUpdated,
        onReminderCreated,
        isReminderRequired,
      );
    }
  }

  private handleProjectFinished(
    partnerLead: PartnerLead,
    newStatusId: number,
    fallbackStatusId: number,
    onLeadUpdated: (lead: PartnerLead) => void,
  ): void {
    const dialogRef = this.dialog.open(LeadProjectFinishedModalComponent, {
      width: '500px',
      data: { partnerLead },
    });

    dialogRef.afterClosed().subscribe((partnerAccepted: boolean) => {
      const statusId = partnerAccepted ? newStatusId : fallbackStatusId;
      this.updateLeadStatus(partnerLead, statusId, onLeadUpdated);
    });
  }

  private handleLeadLost(
    partnerLead: PartnerLead,
    newStatusId: number,
    onLeadUpdated: (lead: PartnerLead) => void,
  ): void {
    this.updateLeadStatus(partnerLead, newStatusId, onLeadUpdated);

    if (partnerLead.cancel_reason_id) {
      const shouldShowTextField =
        partnerLead.cancel_reason_id ===
          PartnerCancelReason['Sonstiges (manuelle Eingabe)'] ||
        partnerLead.cancel_reason_id ===
          PartnerCancelReason['Wettbewerber beauftragt'];

      if (shouldShowTextField) {
        return;
      }
    }

    this.openCancelReasonDialog(partnerLead, onLeadUpdated);
  }

  private handleOtherStatus(
    partnerLead: PartnerLead,
    newStatusId: number,
    currentStatusId: number,
    authUser: User | PartnerUser | null,
    onLeadUpdated: (lead: PartnerLead) => void,
    onReminderCreated?: () => void,
    isReminderRequired?: boolean,
  ): void {
    this.showPartnerLead(partnerLead.id).subscribe((partnerLeadResponse) => {
      if (currentStatusId === PartnerLeadStatus.Verloren) {
        partnerLeadResponse.cancel_reason_id = null;
        partnerLeadResponse.cancel_reason = '';
      }

      this.updateLeadStatus(partnerLeadResponse, newStatusId, onLeadUpdated);

      if (isReminderRequired) {
        this.addReminder(partnerLeadResponse, authUser, onReminderCreated);
      }
    });
  }

  private updateLeadStatus(
    partnerLead: PartnerLead,
    newStatusId: number,
    onLeadUpdated: (lead: PartnerLead) => void,
  ): void {
    const leadToUpdate = { ...partnerLead };
    leadToUpdate.status = newStatusId;
    this.updatePartnerLead(leadToUpdate, onLeadUpdated);
  }

  private updatePartnerLead(
    partnerLead: PartnerLead,
    onLeadUpdated: (lead: PartnerLead) => void,
  ): void {
    this.update(partnerLead).subscribe({
      next: (updatedLead) => {
        onLeadUpdated(updatedLead);
      },
      error: () => {
        this.snackBarService.openSnackBar(
          'Status konnte nicht aktualisiert werden',
          'warn',
        );
      },
    });
  }

  private openCancelReasonDialog(
    partnerLead: PartnerLead,
    onLeadUpdated: (lead: PartnerLead) => void,
  ): void {
    const formFields: FormField[] = [
      {
        type: ModalFormFieldType.select,
        label: 'Grund für Verlust',
        name: 'cancel_reason_id',
        optionlistKeyword: 'cancel_reason',
      },
      {
        type: ModalFormFieldType.text,
        label: 'Sonstiger Grund',
        name: 'manual_reason',
        condition: {
          conditions: [
            {
              dependsOn: 'cancel_reason_id',
              value: PartnerCancelReason['Sonstiges (manuelle Eingabe)'],
            },
          ],
        },
      },
      {
        type: ModalFormFieldType.text,
        label: 'Name des Wettbewerbers',
        name: 'competitor_name',
        condition: {
          conditions: [
            {
              dependsOn: 'cancel_reason_id',
              value: PartnerCancelReason['Wettbewerber beauftragt'],
            },
          ],
        },
      },
    ];

    const inputData: ModalInputData = {
      headline: 'Grund für Verlust (Optional)',
      formGroup: CreateFormGroupHelper.createFormGroupForModal(formFields),
      formFields: formFields,
      acceptButtonText: 'Speichern',
      declineButtonText: 'Abbrechen',
      showDeclineButton: true,
    };

    this.dialog
      .open(ModalComponent, {
        disableClose: false,
        data: inputData,
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((modalResponse) => {
        if (modalResponse.cancel_reason_id) {
          const cancelReason =
            modalResponse.cancel_reason_id ===
            PartnerCancelReason['Sonstiges (manuelle Eingabe)']
              ? modalResponse.manual_reason
              : modalResponse.competitor_name;

          this.showPartnerLead(partnerLead.id).subscribe((response) => {
            response.cancel_reason_id = modalResponse.cancel_reason_id;
            response.cancel_reason = cancelReason || '';
            this.updatePartnerLead(response, onLeadUpdated);
          });
        }
      });
  }

  private addReminder(
    partnerLead: PartnerLead,
    authUser: User | PartnerUser | null,
    onReminderCreated?: () => void,
  ): void {
    const inputData = this.reminderService.getReminderModalInputData(
      undefined,
      authUser,
      undefined,
    );

    this.dialog
      .open(ModalComponent, { disableClose: true, data: inputData })
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap((modalResponse) =>
          this.processReminderFromResponse(modalResponse, partnerLead),
        ),
      )
      .subscribe({
        next: () => {
          this.snackBarService.openSnackBar(
            'Erinnerung erfolgreich erstellt',
            'success',
          );
          if (onReminderCreated) {
            onReminderCreated();
          }
        },
        error: () => {
          this.snackBarService.openSnackBar(
            'Fehler beim Erstellen der Erinnerung',
            'warn',
          );
        },
      });
  }

  private processReminderFromResponse(
    modalResponse: any,
    partnerLead: PartnerLead,
  ): Observable<Reminder> {
    const reminder = this.reminderService.createOrUpdateReminderObject(
      modalResponse,
      undefined,
    );
    reminder.partner_lead_id = partnerLead.id;
    reminder.visible_in_salesrunner = true;

    return this.reminderService.createReminder(reminder).pipe(
      filter((reminderRet: Reminder) => !!reminderRet?.id),
      switchMap((reminderRet) =>
        this.reminderService.handleCommentCreation(
          reminderRet,
          modalResponse,
          null,
        ),
      ),
      map((result) => {
        if (result instanceof Comment) {
          return reminder;
        }
        return result as Reminder;
      }),
    );
  }
}
