import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { CardHeadComponent } from './components/card/card-head.component';
import { CardBodyComponent } from './components/card/card-body.component';
import { CardComponent } from './components/card/card.component';
import { DaterangePresetComponent } from './components/date/daterange-preset/daterange-preset.component';
import { DaterangePickerHelperComponent } from './components/date/daterange-picker-helper/daterange-picker-helper.component';
import { DaterangePickerComponent } from './components/date/daterange-picker/daterange-picker.component';
import { FilterBarComponent } from './components/filter-bar/filter-bar.component';
import { CustomDatePipe } from './pipes/CustomDatePipe';
import { LoginComponent } from './components/login/login.component';
import { NgxEditorModule } from 'ngx-editor';
import { FlexModule } from '@angular/flex-layout';
import { NgChartsModule } from 'ng2-charts';
import { NgSelectModule } from '@ng-select/ng-select';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DataTablesModule } from 'angular-datatables';
import { TableModule } from './components/table/table.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import localeDe from '@angular/common/locales/de';
import { MatSliderModule } from '@angular/material/slider';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { SelectComponent } from './components/select/select.component';
import { MapComponent } from './components/map/map.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LineChartComponent } from './components/chart/line-chart/line-chart.component';
import { BarChartComponent } from './components/chart/bar-chart/bar-chart.component';
import { PieChartComponent } from './components/chart/pie-chart/pie-chart.component';
import { AddressComponent } from './components/address/address/address.component';
import { EmailEditorComponent } from './components/communication/email-editor/email-editor.component';
import { SmsEditorComponent } from './components/communication/sms-editor/sms-editor.component';
import { AddressesComponent } from './components/address/addresses/addresses.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { PercentPipe } from './pipes/PercentPipe';
import { LeadDetailFilterModalComponent } from './components/filter-bar/lead-detail-filter-modal/lead-detail-filter-modal.component';
import { MatBadgeModule } from '@angular/material/badge';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { ResetPasswordComponent } from './components/login/reset-password/reset-password.component';
import { ModalComponent } from './components/modal/modal.component';
import { PartnerBudgetComponent } from './components/partner-budget/partner-budget.component';
import { PartnerBudgetFormComponent } from './components/partner-budget/partner-budget-form.component';
import { SemanticSearchSelectionComponent } from './components/semantic-search-selection/semantic-search-selection.component';
import { CustomDateTimePipe } from './pipes/CustomDateTimePipe';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { RouterLink } from '@angular/router';
import { PhoneUserStatusComponent } from './components/phone-user-status/phone-user-status.component';
import { NewSelectComponent } from './components/select/new-select.component';
import { NewFilterBarComponent } from './components/filter-bar/new-filter-bar.component';
import { SlideToggleComponent } from './components/slide-toggle/slide-toggle.component';
import { PartnerLeadStatusBadgeComponent } from './components/badge/partner-lead-status-badge.component';
import { LeadStatusBadgeComponent } from './components/badge/lead-status-badge.component';
import { FeedComponent } from './components/feed/feed.component';
import { ReactionComponent } from './components/reaction/reaction.component';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { CommentComponent } from './components/comment/comment.component';
import { FeedCommentComponent } from './components/feed/feed-details/feed-comment.component';
import { FeedCallComponent } from './components/feed/feed-details/feed-call.component';
import { FeedDocumentComponent } from './components/feed/feed-details/feed-document.component';
import { FeedEmailComponent } from './components/feed/feed-details/feed-email.component';
import { FeedStatusHistoryComponent } from './components/feed/feed-details/feed-status-history.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FeedFeedbackComponent } from './components/feed/feed-details/feed-feedback.component';
import { LeadFeedbackModalComponent } from './components/lead-feedback/lead-feedback-modal.component';
import { FeedCommentSummaryComponent } from './components/feed/feed-details/feed-comment-summary.component';
import { FeedReminderComponent } from './components/feed/feed-details/feed-reminder.component';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { ErrorLoggingService } from './services/error-logging.service';
import { GlobalErrorHandler } from './global-error-handler';
import { ConditionComponent } from './components/condition/condition.component';
import { ConditionGroupsComponent } from './components/condition/condition-groups.component';
import { SplitViewComponent } from './components/split-view/split-view.component';
import { LeadProjectFinishedModalComponent } from './components/lead-project-finished-modal/lead-project-finished-modal.component';
import { PartnerLeadAddressComponent } from './components/lead-project-finished-modal/partner-lead-address.component';

registerLocaleData(localeDe);

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    LoginComponent,
    ResetPasswordComponent,
    CustomDatePipe,
    CustomDateTimePipe,
    SelectComponent,
    NewSelectComponent,
    SemanticSearchSelectionComponent,
    FilterBarComponent,
    NewFilterBarComponent,
    FeedComponent,
    FeedCallComponent,
    FeedCommentComponent,
    FeedDocumentComponent,
    FeedEmailComponent,
    FeedStatusHistoryComponent,
    FeedFeedbackComponent,
    FeedCommentSummaryComponent,
    FeedReminderComponent,
    SplitViewComponent,
    CommentComponent,
    SlideToggleComponent,
    DaterangePickerComponent,
    DaterangePickerHelperComponent,
    DaterangePresetComponent,
    CardComponent,
    CardBodyComponent,
    CardHeadComponent,
    DeleteModalComponent,
    ModalComponent,
    ConditionComponent,
    ConditionGroupsComponent,
    TooltipComponent,
    PhoneUserStatusComponent,
    ToolbarComponent,
    SidenavComponent,
    LoadingComponent,
    MapComponent,
    LineChartComponent,
    BarChartComponent,
    PieChartComponent,
    AddressComponent,
    AddressesComponent,
    EmailEditorComponent,
    LeadDetailFilterModalComponent,
    SmsEditorComponent,
    FileUploadComponent,
    PercentPipe,
    PartnerBudgetComponent,
    PartnerBudgetFormComponent,
    LeadStatusBadgeComponent,
    PartnerLeadStatusBadgeComponent,
    ReactionComponent,
    LeadFeedbackModalComponent,
    LeadProjectFinishedModalComponent,
    PartnerLeadAddressComponent,
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    BrowserAnimationsModule,
    TableModule,
    DataTablesModule,

    MatTooltipModule,
    MatCardModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatGridListModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatTableModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatNativeDateModule,
    MatTabsModule,
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatBadgeModule,
    DragDropModule,
    CKEditorModule,
    CollapseModule,
    NgSelectModule,
    NgChartsModule,
    FlexModule,
    NgxEditorModule,
    RouterLink,
    PickerComponent,
    MatExpansionModule,
    NgxMatTimepickerModule.setLocale('de-DE'),
  ],
  exports: [
    LoginComponent,
    ResetPasswordComponent,
    FileUploadComponent,
    CustomDatePipe,
    CustomDateTimePipe,
    SelectComponent,
    NewSelectComponent,
    SemanticSearchSelectionComponent,
    FilterBarComponent,
    NewFilterBarComponent,
    FeedComponent,
    FeedCallComponent,
    FeedCommentComponent,
    FeedDocumentComponent,
    FeedEmailComponent,
    FeedStatusHistoryComponent,
    FeedFeedbackComponent,
    FeedCommentSummaryComponent,
    FeedReminderComponent,
    SplitViewComponent,
    CommentComponent,
    SlideToggleComponent,
    DaterangePickerComponent,
    DaterangePickerHelperComponent,
    DaterangePresetComponent,
    CardComponent,
    CardBodyComponent,
    CardHeadComponent,
    DeleteModalComponent,
    ModalComponent,
    ConditionComponent,
    ConditionGroupsComponent,
    TableModule,
    TooltipComponent,
    PhoneUserStatusComponent,
    ToolbarComponent,
    SidenavComponent,
    LoadingComponent,
    MapComponent,
    LineChartComponent,
    BarChartComponent,
    PieChartComponent,
    AddressComponent,
    AddressesComponent,
    EmailEditorComponent,
    LeadDetailFilterModalComponent,
    SmsEditorComponent,
    PartnerBudgetComponent,
    PartnerBudgetFormComponent,
    LeadStatusBadgeComponent,
    PartnerLeadStatusBadgeComponent,
    ReactionComponent,
    LeadFeedbackModalComponent,
    LeadProjectFinishedModalComponent,
    PartnerLeadAddressComponent,

    MatTooltipModule,
    MatCardModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatGridListModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatTableModule,
    MatToolbarModule,
    MatSidenavModule,
    MatSliderModule,
    MatListModule,
    MatNativeDateModule,
    MatTabsModule,
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatToolbarModule,
    DragDropModule,
    CKEditorModule,
    CollapseModule,
    NgSelectModule,
    NgChartsModule,
    FlexModule,
    NgxEditorModule,
    PercentPipe,
    PickerComponent,
    NgxMatTimepickerModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    ErrorLoggingService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS,
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    DatePipe,
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
  ],
})
export class SharedModule {}
