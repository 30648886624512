import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Address } from '../../../models/address';
import { Subject, takeUntil } from 'rxjs';
import { AddressService } from '../../../services/address/address.service';
import { AddressType, Application } from '../../../enum-collection';
import { FileUpload } from '../../../models/fileUpload';
import { ModalInputData } from '../../../models/modal';
import { ModalComponent } from '../../modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationHelper } from '../../../helpers/application-helper';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss'],
})
export class AddressesComponent implements OnInit, OnDestroy {
  @Input() set addresses(value: Address[]) {
    this._addresses = value.map((addr) => new Address(addr));
    this.initializeAddresses();
  }
  get addresses(): Address[] {
    return this._addresses;
  }
  private _addresses: Address[] = [];

  @Input() inputsDisabled = false;
  @Output() addressesChanged = new EventEmitter<Address[]>();

  defaultAddress!: Address;
  additionalAddresses: Address[] = [];
  availableAddressTypes: AddressType[] = Object.keys(AddressType)
    .filter((key) => isNaN(Number(key)))
    .map((key) => AddressType[key as keyof typeof AddressType])
    .filter((type) => type !== AddressType.DEFAULT);

  private destroy$ = new Subject<void>();

  constructor(private dialog: MatDialog) {
    if (ApplicationHelper.applicationName === Application.Salesrunner) {
      this.availableAddressTypes = this.availableAddressTypes.filter(
        (type) => type === AddressType.DEFAULT || type === AddressType.INVOICE,
      );
    }
  }

  ngOnInit(): void {
    this.initializeAddresses();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initializeAddresses(): void {
    this.defaultAddress =
      this.addresses.find((a) => a.type === AddressType.DEFAULT) ||
      new Address({ type: AddressType.DEFAULT });

    this.additionalAddresses = this.addresses.filter(
      (a) => a.type !== AddressType.DEFAULT,
    );

    if (!this.addresses.find((a) => a.type === AddressType.DEFAULT)) {
      this.addresses.unshift(this.defaultAddress);
    }
  }

  addAddress(type: AddressType): void {
    if (this.additionalAddresses.some((a) => a.type === type)) return;

    const newAddress = new Address({
      type,
      lead_id: this.defaultAddress.lead_id,
    });
    this.additionalAddresses.push(newAddress);
    this.addresses.push(newAddress);
    this.emitChanges();
  }

  copyDefaultToAdditional(): void {
    if (this.additionalAddresses.length > 0) {
      const inputData: ModalInputData = {
        headline: 'Adressen überschreiben',
        body: 'Möchten Sie wirklich die Daten der Zusatzadresse mit den Daten der Standardadresse überschreiben?',
        acceptButtonText: 'Ja',
        declineButtonText: 'Nein',
      };

      this.dialog
        .open(ModalComponent, {
          disableClose: true,
          data: inputData,
        })
        .afterClosed()
        .subscribe((modalResponse) => {
          if (modalResponse) {
            // Alle Zusatzadressen mit der Standardadresse überschreiben
            this.additionalAddresses.forEach((address) => {
              this.copyFromDefault(address);
            });
          }
        });
    } else if (this.hasAvailableAddressTypes()) {
      const availableType = this.availableAddressTypes.find(
        (type) => !this.hasAddressType(type),
      );

      if (availableType) {
        this.addAddress(availableType);
        const newAddress =
          this.additionalAddresses[this.additionalAddresses.length - 1];
        this.copyFromDefault(newAddress);
      }
    }
  }

  copyFromDefault(address: Address): void {
    const index = this.additionalAddresses.findIndex(
      (a) => a.type === address.type,
    );
    if (index !== -1) {
      // Die ID und den Typ der Zieladresse speichern
      const originalId = this.additionalAddresses[index].id;
      const originalType = this.additionalAddresses[index].type;

      // Adresse klonen und ID/Typ wiederherstellen
      this.additionalAddresses[index] = this.defaultAddress.clone();
      this.additionalAddresses[index].id = originalId;
      this.additionalAddresses[index].type = originalType;

      // Aktualisieren der globalen Adressliste
      const globalIndex = this.addresses.findIndex(
        (a) => a.type === address.type,
      );
      if (globalIndex !== -1) {
        this.addresses[globalIndex] = this.additionalAddresses[index];
      }

      this.emitChanges();
    }
  }

  onAddressDeleted(address: Address): void {
    if (address.type === AddressType.DEFAULT) {
      // Optional: Behandlung wenn die Standard-Adresse gelöscht wird
      // In den meisten Fällen sollte man die Standard-Adresse nicht löschen können
      console.warn('Die Standard-Adresse kann nicht gelöscht werden');
      return;
    }

    const index = this.additionalAddresses.findIndex(
      (a) => a.type === address.type,
    );
    if (index !== -1) {
      this.removeAddressFromList(index);
    }
  }

  onAddressChanged(updatedAddress: Address): void {
    if (updatedAddress.type === AddressType.DEFAULT) {
      this.defaultAddress = updatedAddress;
    } else {
      const index = this.additionalAddresses.findIndex(
        (a) => a.type === updatedAddress.type,
      );
      if (index !== -1) {
        this.additionalAddresses[index] = updatedAddress;
      }
    }
    const addrIndex = this.addresses.findIndex(
      (a) => a.type === updatedAddress.type,
    );
    this.addresses[addrIndex] = updatedAddress;
    this.emitChanges();
  }

  hasAddressType(type: AddressType): boolean {
    return (
      type === AddressType.DEFAULT ||
      this.additionalAddresses.some((a) => a.type === type)
    );
  }

  hasAvailableAddressTypes(): boolean {
    return this.availableAddressTypes.some(
      (type) => !this.hasAddressType(type),
    );
  }

  private removeAddressFromList(index: number): void {
    const removedType = this.additionalAddresses[index]?.type;
    this.additionalAddresses.splice(index, 1);
    this.addresses = this.addresses.filter((a) => a.type !== removedType);
    this.emitChanges();
  }

  private emitChanges(): void {
    this.addressesChanged.emit([...this.addresses]);
  }

  getAddressTypeLabel(type: AddressType): string {
    switch (type) {
      case AddressType.DEFAULT:
        return 'Standardadresse';
      case AddressType.INVOICE:
        return 'Abweichende Rechnungsadresse';
      case AddressType.IMPRINT:
        return 'Impressum';
      default:
        return 'Unbekannter Typ';
    }
  }
}
