import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommentService } from '../../services/comment.service';
import { Comment } from '../../models/comment';
import { ApplicationHelper } from '../../helpers/application-helper';
import { Application } from '../../enum-collection';
import {
  catchError,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { firstValueFrom, Observable, of, Subject, take } from 'rxjs';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { FileUploadService } from '../../services/file-upload/file-upload.service';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { Lead } from '../../models/leads/lead';
import { PartnerLead } from '../../models/partner-lead';
import { CommentSummary } from '../../models/comment-summary';
import { HttpResponse } from '@angular/common/http';
import { ButtonIconColor, ButtonType } from '../button/button.component';

@Component({
  selector: 'lib-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild(FileUploadComponent) fileUploadComponent!: FileUploadComponent;
  @ViewChild('commentTextarea') commentTextarea?: ElementRef;

  @Input() relatableId: number = 0;
  @Input() relatableType: string = '';
  @Input() commentToEditId: number = 0;
  @Input() commentSummaryToEdit?: CommentSummary;
  @Input() disableCommentInput: boolean = false;
  @Input() mainPost: boolean = false;
  @Input() displayVisibleInSalesrunnerButton: boolean = true;
  @Input() lead: Lead | null = new Lead({});
  @Input() partnerLead: PartnerLead | null = new PartnerLead({});

  @Output() commentAdded = new EventEmitter<Comment>();
  @Output() fileAdded = new EventEmitter<void>();

  private destroy$ = new Subject<void>();

  visibleInSalesrunner: number = 0;
  visibleInLeadEmail: boolean = false;
  displayFileUpload: boolean = false;
  dataLoaded: boolean = false;
  commentForm: FormGroup;
  comment: Comment = new Comment();
  selectedFiles: File[] = [];
  hasUnsavedChanges: boolean = false;

  private userSetMinHeight: number | null = null;
  private isResizing: boolean = false;

  flagAllCommentsAreVisible: boolean = false;
  private initialHeightSet = false;

  constructor(
    private fb: FormBuilder,
    private commentService: CommentService,
    private authService: AuthService,
    private snackbarService: SnackBarService,
    private fileUploadService: FileUploadService,
  ) {
    this.commentForm = this.fb.group({
      content: ['', []],
      visibleInSalesrunner: [false],
      visibleInLeadEmail: [false],
    });

    this.commentForm.get('content')?.setValidators(this.getContentValidators());

    this.commentForm.valueChanges.subscribe(() => {
      const content = this.commentForm.get('content')?.value;
      this.hasUnsavedChanges = content && content.trim() !== '';
    });
  }

  ngOnInit(): void {
    this.setInitialVisibleInSalesrunnerValue();

    if (this.commentSummaryToEdit) {
      this.commentToEditId = 0;
      this.commentForm
        .get('content')
        ?.setValue(this.convertHtmlToText(this.commentSummaryToEdit.summary));
      this.relatableId = this.commentSummaryToEdit.relatable_id;
      this.relatableType = this.commentSummaryToEdit.relatable_type;

      this.initialHeightSet = false;
    }

    if (this.commentToEditId) {
      this.commentService
        .getComment(this.commentToEditId)
        .pipe(
          take(1),
          tap((comment) => {
            this.comment = comment;
            this.commentForm
              .get('content')
              ?.setValue(this.convertHtmlToText(comment.comment));
            this.relatableId = comment.relatable_id;
            this.relatableType = comment.relatable_type;

            this.initialHeightSet = false;
          }),
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    }

    if (this.disableCommentInput) {
      this.commentForm.get('content')?.disable();
    } else {
      this.commentForm.get('content')?.enable();
    }
  }

  ngAfterViewChecked() {
    if (
      !this.initialHeightSet &&
      this.commentTextarea &&
      this.commentForm.get('content')?.value
    ) {
      this.autoGrow({ target: this.commentTextarea.nativeElement });
      this.initialHeightSet = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getContentValidators() {
    return this.selectedFiles.length > 0
      ? []
      : [Validators.required, Validators.minLength(1)];
  }

  setInitialVisibleInSalesrunnerValue() {
    if (
      ApplicationHelper.applicationName === Application.Salesrunner ||
      !this.displayVisibleInSalesrunnerButton
    ) {
      this.displayVisibleInSalesrunnerButton = false;
      this.dataLoaded = true;
      return;
    }

    if (
      ApplicationHelper.applicationName === Application.Leadmanager &&
      this.lead?.status != 3
    ) {
      // Wenn der Lead noch nicht weitergeleitet wurde, dann soll der Kommentar initial sichtbar sein
      // this.visibleInSalesrunner = 1;
      // soll doch nicht initial sichtbar sein
      this.visibleInSalesrunner = 0;
      this.visibleInLeadEmail = false;
    }

    if (
      ApplicationHelper.applicationName === Application.Leadmanager &&
      this.relatableId
    ) {
      this.flagAllCommentsAreVisible = true;
      if (this.relatableType === 'Document') {
        this.dataLoaded = true;
      } else {
        this.validateSlideToggleForCommentChain(this.relatableId);
      }
    } else {
      this.dataLoaded = true;
    }
  }

  validateSlideToggleForCommentChain(commentId: number) {
    if (this.mainPost && this.lead?.status != 3) {
      this.displayVisibleInSalesrunnerButton = true;
      this.visibleInSalesrunner = 0;
      this.visibleInLeadEmail = false;
      this.dataLoaded = true;
      return;
    }

    this.commentService
      .getComment(commentId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((comment: Comment) => {
        if (comment.relatable_type === 'App\\Lead') {
          // Wenn es der oberste Kommentar ist, dann wird der Schieberegler sichtbar
          this.visibleInSalesrunner = comment.visible_in_salesrunner ? 1 : 0;
          this.visibleInLeadEmail = comment.visible_in_lead_email;

          this.dataLoaded = true;

          if (this.commentToEditId && !this.flagAllCommentsAreVisible) {
            this.visibleInSalesrunner = 0;
            this.visibleInLeadEmail = false;
            this.displayVisibleInSalesrunnerButton = false;
          }

          return;
        }

        // Ja, eine kompliziertere Logik. Der Grundgedanke ist, dass wenn ein Kommentar bearbeitet wird geprüft werden muss, ob irgendwo in der Kommentarkette ein Kommentar nicht sichtbar ist.
        if (this.commentToEditId) {
          if (
            this.commentToEditId !== comment.id &&
            !comment.visible_in_salesrunner
          ) {
            this.flagAllCommentsAreVisible = false;
          }
        }

        if (!comment.visible_in_salesrunner && !this.commentToEditId) {
          this.visibleInSalesrunner = 0;
          this.visibleInLeadEmail = false;
          this.displayVisibleInSalesrunnerButton = false;
          this.dataLoaded = true;
          return;
        }

        // Wenn es noch nicht der oberste Kommentar ist, dann wird der nächste Kommentar geladen
        if (comment.relatable_type !== 'App\\Lead') {
          this.validateSlideToggleForCommentChain(comment.relatable_id);
        } else {
          this.dataLoaded = true;
        }
      });
  }

  private createCommentObject(
    creatorUserId: number | undefined,
    relatableType: string,
    relatableId: number,
  ): Comment {
    const newComment = new Comment();
    newComment.comment = this.commentForm.get('content')?.value;
    newComment.relatable_id = relatableId;
    newComment.relatable_type = relatableType;

    if (ApplicationHelper.applicationName === Application.Leadmanager) {
      newComment.user_id = creatorUserId;
      if (this.lead?.status !== 3) {
        newComment.visible_in_salesrunner = this.visibleInSalesrunner === 1;
        newComment.visible_in_lead_email = this.visibleInLeadEmail;
      } else {
        newComment.visible_in_salesrunner = false;
        newComment.visible_in_lead_email = false;
      }
    } else if (ApplicationHelper.applicationName === Application.Salesrunner) {
      newComment.partner_user_id = creatorUserId;
      newComment.visible_in_salesrunner = true;
      newComment.visible_in_lead_email = false;
    }

    return newComment;
  }

  private createCommentSummaryObject(
    relatableType: string,
    relatableId: number,
  ): CommentSummary {
    const commentSummary = new CommentSummary();
    commentSummary.summary = this.commentForm.get('content')?.value;
    commentSummary.relatable_id = relatableId;
    commentSummary.relatable_type = relatableType;

    return commentSummary;
  }

  onResizeStart(): void {
    this.isResizing = true;
  }

  onResizeEnd(event: any): void {
    this.isResizing = false;
    this.userSetMinHeight = event.target.offsetHeight;
  }

  autoGrow(event: any): void {
    if (this.isResizing) return;

    const textarea = event.target;
    const minHeight = this.userSetMinHeight || 38;

    textarea.style.height = 'auto';
    textarea.style.height = `${Math.max(textarea.scrollHeight, minHeight)}px`;

    // Speichere die aktuelle Scroll-Position
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // Setze die Höhe zurück und lass den Browser die benötigte Höhe berechnen
    textarea.style.height = 'auto';

    // Setze die neue Höhe basierend auf dem Inhalt
    const newHeight = Math.max(textarea.scrollHeight, minHeight);
    textarea.style.height = `${newHeight}px`;

    // Stelle die Scroll-Position wieder her
    window.scrollTo(0, scrollTop);
  }

  convertHtmlToText(html: string): string {
    // Ersetzen Sie <br> und <br/> durch Zeilenumbrüche
    let text = html.replace(/<br\s*\/?>/gi, '\n');

    // Entfernen Sie alle anderen HTML-Tags
    text = text.replace(/<[^>]+>/g, '');

    // Dekodieren Sie HTML-Entities
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
  }

  onFileListChanged(files: File[]) {
    this.selectedFiles = files;
    // Aktualisiere Validatoren wenn sich die Dateiliste ändert
    this.commentForm.get('content')?.setValidators(this.getContentValidators());
    this.commentForm.get('content')?.updateValueAndValidity();
  }

  onSubmit() {
    if (this.commentForm.valid) {
      this.authService
        .getCurrentUser()
        .pipe(
          take(1),
          switchMap((user) => {
            return this.uploadFiles().pipe(
              switchMap((uploadedFiles) => {
                const hasCommentText = this.commentForm
                  .get('content')
                  ?.value?.trim();

                if (!uploadedFiles.length && !hasCommentText) {
                  return of(undefined);
                }

                let relatableType = this.relatableType;
                let relatableId = this.relatableId;

                if (uploadedFiles && uploadedFiles.length > 0) {
                  const file = uploadedFiles[0];
                  relatableId = file.id;
                  relatableType = 'App\\Document';
                }

                if (hasCommentText) {
                  if (this.commentSummaryToEdit) {
                    const commentSummary = this.createCommentSummaryObject(
                      relatableType,
                      relatableId,
                    );
                    return this.commentService.updateCommentSummary(
                      this.commentSummaryToEdit.id,
                      commentSummary,
                    );
                  }

                  let creatorUserId = user?.id;

                  // Wenn es bereits einen Kommentar gibt, dann soll der Autor nicht überschrieben werden
                  if (this.comment?.user_id) {
                    creatorUserId = this.comment.user_id;
                  }

                  const newComment = this.createCommentObject(
                    creatorUserId,
                    relatableType,
                    relatableId,
                  );

                  if (this.commentToEditId) {
                    return this.commentService.updateComment(
                      this.commentToEditId,
                      newComment,
                    );
                  }

                  this.hasUnsavedChanges = false;

                  return this.commentService.createComment(newComment);
                }

                return of(undefined);
              }),
            );
          }),
          tap((createdComment) => {
            if (createdComment) {
              this.snackbarService.openSnackBar(
                'Kommentar erfolgreich gespeichert.',
                'success',
              );
              this.commentAdded.emit(createdComment);
            }

            // Wenn der Dateiupload sichtbar war, dann soll dieser zurückgesetzt werden
            if (this.displayFileUpload) {
              this.toggleFileUpload();
              this.fileUploadComponent?.clearAllFiles();
              this.selectedFiles = [];
            }

            let type = 'Lead';
            let id = this.lead?.id;

            if (ApplicationHelper.applicationName === Application.Salesrunner) {
              type = 'PartnerLead';
              id = this.partnerLead?.id;
            }

            if (
              id &&
              (this.visibleInSalesrunner ||
                ApplicationHelper.applicationName === Application.Salesrunner)
            ) {
              // Wenn es ein Unterkommentar ist, darf erst emittet werden, wenn die Rückmeldung da ist.
              if (this.relatableType !== 'App\\Comment') {
                if (createdComment) {
                  this.commentAdded.emit(createdComment);
                }
                this.commentForm.reset();
              }

              if (type === 'PartnerLead') {
                this.commentService.aiIsLoadingSubject.next(true);

                this.commentService
                  .getCommentSummaryForModel(type, id)
                  .subscribe(() => {
                    this.commentService.aiIsLoadingSubject.next(false);
                    if (createdComment) {
                      this.commentAdded.emit(createdComment);
                    }
                    this.commentForm.reset();
                  });
              }
            } else {
              if (createdComment) {
                this.commentAdded.emit(createdComment);
              }
              this.commentForm.reset();
            }
          }),
          catchError((error) => {
            this.snackbarService.openSnackBar(
              'Kommentar konnte nicht gespeichert werden.',
              'warn',
            );
            console.error('Error creating comment:', error);
            return of(undefined);
          }),
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    }
  }

  uploadFiles(): Observable<any[]> {
    if (!this.selectedFiles?.length) {
      return of([]);
    }

    let key = 'PartnerLead';
    let visibleInSalesrunner = this.visibleInSalesrunner === 1 ? '1' : '0';

    if (ApplicationHelper.applicationName === Application.Leadmanager) {
      key = 'lead';
    }

    if (ApplicationHelper.applicationName === Application.Salesrunner) {
      visibleInSalesrunner = '1';
    }

    return new Observable((subscriber) => {
      const processFiles = async () => {
        const results: any[] = [];

        try {
          // Erste Datei hochladen
          const firstUploadObservable = this.fileUploadService
            .upload(
              this.selectedFiles[0],
              this.relatableId,
              key,
              visibleInSalesrunner,
            )
            .pipe(
              map((event) => {
                if (event instanceof HttpResponse) {
                  return event.body;
                }
                if (
                  typeof event === 'object' &&
                  event !== null &&
                  'id' in event
                ) {
                  return event;
                }
                return null;
              }),
              filter((response) => response !== null && 'id' in response),
            );

          const firstUpload = await firstValueFrom(firstUploadObservable);

          if (firstUpload) {
            results.push(firstUpload);

            // Upload remaining files
            if (this.selectedFiles.length > 1) {
              for (let i = 1; i < this.selectedFiles.length; i++) {
                try {
                  const nextUploadObservable = this.fileUploadService
                    .upload(
                      this.selectedFiles[i],
                      this.relatableId, // Verwende die ursprüngliche relatableId
                      key, // Verwende den ursprünglichen key (lead oder PartnerLead)
                      visibleInSalesrunner,
                      firstUpload.id, // Verknüpfe mit der ersten Datei
                    )
                    .pipe(
                      map((event) => {
                        if (event instanceof HttpResponse) {
                          return event.body;
                        }
                        if (
                          typeof event === 'object' &&
                          event !== null &&
                          'id' in event
                        ) {
                          return event;
                        }
                        return null;
                      }),
                      filter(
                        (response) => response !== null && 'id' in response,
                      ),
                    );

                  const nextUpload = await firstValueFrom(nextUploadObservable);
                  if (nextUpload) {
                    results.push(nextUpload);
                  }
                } catch (uploadError) {
                  console.error(`Error uploading file ${i + 1}:`, uploadError);
                }
              }
            }
          }

          subscriber.next(results);
          this.fileAdded.emit();
          subscriber.complete();
        } catch (error) {
          console.error('Critical error in upload process:', error);
          if (results.length > 0) {
            subscriber.next(results);
          } else {
            subscriber.next([]);
          }
          subscriber.complete();
        }
      };

      processFiles();
    });
  }

  toggleFileUpload() {
    this.displayFileUpload = !this.displayFileUpload;

    if (!this.displayFileUpload) {
      this.selectedFiles = [];
    }
  }

  toggleVisibility() {
    this.visibleInSalesrunner = this.visibleInSalesrunner === 1 ? 0 : 1;

    this.visibleInLeadEmail = !!this.visibleInSalesrunner;
  }

  protected readonly Application = Application;
  protected readonly ApplicationHelper = ApplicationHelper;
  protected readonly ButtonType = ButtonType;
  protected readonly ButtonIconColor = ButtonIconColor;
}
