<h1 mat-dialog-title>
  Lead Detail Filter
  <em style="color: darkred" *ngIf="inputData?.selectedLeadTypes?.isNegative"
    >Achtung: Negativfilter werden ignoriert.</em
  >
</h1>
<mat-dialog-content>
  <lib-select
    [placeholder]="'Lead-Arten'"
    [multiSelect]="true"
    (valueChanged)="selectedLeadTypesChanged($event)"
    [options]="leadTypes$ | async"
    [hasNegativeValues]="false"
    [selectedOptions]="selectedOptions"
  ></lib-select>

  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    *ngIf="!dataLoaded"
    style="margin-top: 30px"
  >
    <mat-spinner></mat-spinner>
  </div>

  <ng-container *ngIf="leadBackendList && leadBackendList.length && dataLoaded">
    <div *ngFor="let leadBackend of leadBackendList">
      <div style="font-weight: bold; margin-top: 20px">
        Lead-Art: {{ leadBackend.lead_type.name }}
      </div>

      <div class="row pb-3">
        <div
          class="col-md-{{
            getLeadBackendElementFieldSize(leadBackendElement.field_size)
          }} pb-4 px-2"
          *ngFor="let leadBackendElement of leadBackend.lead_backend_elements"
        >
          <div>
            <div>
              <label class="form-label p-2 lead-backend-label">{{
                leadBackendElement.title
              }}</label>
              <div>
                <select
                  class="form-select"
                  [(ngModel)]="leadBackendElement.selectedOption"
                >
                  <option value="">Auswählen</option>
                  <option
                    *ngFor="
                      let option of leadBackendElement.lead_field.optionlist
                        .options
                    "
                    [value]="option.option_id"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div
    *ngIf="(!leadBackendList || !leadBackendList.length) && dataLoaded"
    fxLayout="row"
    fxLayoutAlign="space-around center"
  >
    <em style="margin-bottom: 60px; margin-top: 80px">
      Um den Detailfilter verwenden zu können müssen Sie zuerst eine Lead-Art
      auswählen.
    </em>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>Abbrechen</button>
  <button
    (click)="saveFilter()"
    class="btn btn-success btn-success"
    style="margin-left: 10px"
  >
    Filter anwenden
  </button>
</mat-dialog-actions>
