import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-demo-section',
  templateUrl: './demo-section.component.html',
  styleUrls: ['./demo-section.component.scss'],
})
export class DemoSectionComponent {
  @Input() title?: string;
}
