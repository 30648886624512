import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Feed } from '../../../models/feed';
import { Reminder } from '../../../models/reminder';
import { Application } from '../../../enum-collection';
import { ApplicationHelper } from '../../../helpers/application-helper';
import { FeedService } from '../../../services/feed.service';
import { Lead } from '../../../models/leads/lead';
import { ReminderService } from '../../../services/reminder.service';

@Component({
  selector: 'lib-feed-reminder',
  templateUrl: './feed-reminder.component.html',
  styleUrls: ['./feed-reminder.component.scss'],
})
export class FeedReminderComponent implements OnInit, OnDestroy {
  @Input() reminder: Reminder = {} as Reminder;
  @Input() feed?: Feed;
  @Input() lead?: Lead;

  private destroy$ = new Subject<void>();

  constructor(
    private reminderService: ReminderService,
    private feedService: FeedService,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFeedSalesrunnerVisibilityHintByComment(): string {
    return this.feedService.getFeedSalesrunnerVisibilityHint(
      this.reminder.visible_in_salesrunner,
      this.reminder?.partner_user?.partner,
      this.lead,
    );
  }

  formatDate(date: Date | string | undefined): string {
    return this.reminderService.formatDate(date);
  }

  getBadgeClass(reminder: Reminder): string {
    if (reminder.reminder_status_option_id === 2) {
      return 'bg-secondary'; // Grau für abgeschlossene Erinnerungen
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const reminderDate = new Date(reminder.reminder_date);
    reminderDate.setHours(0, 0, 0, 0);

    if (reminderDate.getTime() === today.getTime()) {
      return 'bg-warning'; // Orange für heutige Erinnerungen
    } else if (reminderDate < today) {
      return 'bg-danger'; // Rot für vergangene Erinnerungen
    } else {
      return 'bg-success'; // Grün für zukünftige Erinnerungen
    }
  }

  protected readonly Application = Application;
  protected readonly ApplicationHelper = ApplicationHelper;
}
