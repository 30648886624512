import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators'; // Wir behalten takeUntil, da es in anderen Teilen nützlich ist
import { Address } from '../../../models/address';
import { CountriesConst } from '../../../data/countries.const';
import { AddressService } from '../../../services/address/address.service';
import { SnackBarService } from '../../../services/snack-bar/snack-bar.service';
import { SipgateService } from '../../../services/sipgate/sipgate.service';
import { LeadService } from '../../../services/lead/lead.service';
import { Lead } from '../../../models/leads/lead';
import { EmailEditorComponent } from '../../communication/email-editor/email-editor.component';
import { SmsEditorComponent } from '../../communication/sms-editor/sms-editor.component';
import { ApplicationHelper } from '../../../helpers/application-helper';
import { AddressType, Application } from '../../../enum-collection';
import { DateHelper } from '../../../helpers/date-helper';
import { ModalInputData } from '../../../models/modal';
import { ModalComponent } from '../../modal/modal.component';

interface GeocodeResponse {
  title: string;
  position: { lat: number; lng: number };
  address: {
    street?: string;
    houseNumber?: string;
    city?: string;
    postalCode?: string;
    district?: string;
    state?: string;
    countryName?: string;
  };
}

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnDestroy {
  @Input() set address(value: Address) {
    this._address = new Address(value);
    this.separateAddress = this._address.separateAddress || false;
  }

  get address(): Address {
    return this._address;
  }

  private _address: Address = new Address();

  @Input() inputsDisabled = false;
  @Output() addressChanged = new EventEmitter<Address>();
  @Output() addressDeleted = new EventEmitter<Address>();

  separateAddress = false;
  autocompleteSuggestions: any[] = [];
  countries = CountriesConst;

  private destroy$ = new Subject<void>();
  delayTimeout: any; // Für die alte Autocomplete-Logik

  constructor(
    private addressService: AddressService,
    private snackBarService: SnackBarService,
    private sipgateService: SipgateService,
    private leadService: LeadService,
    public dialog: MatDialog,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Alte Logik für Autocomplete-Suche
  onAutocompleteSearchInputChange() {
    clearTimeout(this.delayTimeout);
    this.delayTimeout = setTimeout(() => {
      this.addressService
        .search2(this.address.address)
        .subscribe((data: any) => {
          if (data && data.items && data.items.length > 0) {
            this.autocompleteSuggestions = data.items;
          }
        });
    }, 250);
  }

  // Alte Logik für Adressauswahl
  onAutocompleteAddressChange() {
    this.geoCodeAddress(this.address.address, (geocodeResp: any) => {
      this.address.address = geocodeResp.title;
      this.address.lat = geocodeResp.position.lat;
      this.address.lng = geocodeResp.position.lng;
      this.address.street = geocodeResp.address.street;

      if (geocodeResp.address.houseNumber) {
        this.address.street += ' ' + geocodeResp.address.houseNumber;
      }

      this.address.city = geocodeResp.address.city;
      this.address.postcode = geocodeResp.address.postalCode;
      this.address.district = geocodeResp.address.district;
      this.address.federal_state = geocodeResp.address.state;

      const country = CountriesConst.find(
        (c) => c.name == geocodeResp.address.countryName,
      );

      if (country) {
        this.address.country_code = country.code;
        this.address.land = geocodeResp.address.countryName;
      }

      this.notifyChange();
    });
  }

  // Alte Logik für Geocoding
  geoCodeAddress(searchTerm: string, callback: any = null) {
    clearTimeout(this.delayTimeout);
    this.delayTimeout = setTimeout(() => {
      this.addressService
        .geoCodeSearch(searchTerm, this.address.country_code)
        .subscribe((geocodeResp: any) => {
          if (
            geocodeResp.items == undefined ||
            geocodeResp.items.length == 0 ||
            geocodeResp.items[0].scoring.queryScore != 1
          ) {
            this.notifyChange();
            this.snackBarService.openSnackBar(
              'Adresse nicht gefunden.',
              'warn',
            );
          } else {
            callback(geocodeResp.items[0]);
          }
        });
    }, 100);
  }

  onAddressChange(): void {
    const country = CountriesConst.find(
      (c) => c.code === this.address.country_code,
    );

    const searchTerm = this.buildSearchTerm(country?.name);

    if (this.separateAddress) {
      this.address.address = searchTerm;
    }

    this.geoCodeAddress(searchTerm, (resp: GeocodeResponse) => {
      this.address.lat = resp.position.lat;
      this.address.lng = resp.position.lng;
      this.address.land = resp.address.countryName || '';
      this.address.federal_state = resp.address.state || '';
      this.notifyChange();
    });
  }

  private buildSearchTerm(countryName?: string): string {
    return [
      this.address.street,
      this.address.postcode,
      this.address.city,
      countryName,
    ]
      .filter(Boolean)
      .join(', ')
      .replace(/,+$/, '');
  }

  notifyChange(): void {
    this.addressChanged.emit(this.address.clone());
  }

  onNameChange(): void {
    this.address.name = `${this.address.first_name || ''} ${
      this.address.last_name || ''
    }`.trim();
    this.notifyChange();
  }

  onDuplicateCheck(): void {
    this.notifyChange();
  }

  onLeadCallClick(phoneNumber: string): void {
    this.leadService.show(this.address.lead_id).subscribe((lead: Lead) => {
      this.sipgateService
        .callUser({
          leadTypeId: lead.lead_type_id,
          calleeNumber: phoneNumber,
        })
        .subscribe();
    });
  }

  onLeadEmailClick(emailAddress: string): void {
    const dialog = this.dialog.open(EmailEditorComponent, {
      width: '900px',
      disableClose: true,
      data: { emailAddress },
    });
    dialog.afterClosed().subscribe(() => this.notifyChange());
  }

  onLeadSmsClick(phoneNumber: string): void {
    this.dialog.open(SmsEditorComponent, {
      minWidth: '400px',
      disableClose: true,
      data: { phoneNumber },
    });
  }

  removeAddress(address: Address): void {
    const inputData: ModalInputData = {
      headline: 'Adresse löschen',
      body: 'Möchten Sie die Adresse wirklich löschen?',
      acceptButtonText: 'Ja',
      declineButtonText: 'Nein',
    };

    this.dialog
      .open(ModalComponent, {
        disableClose: true,
        data: inputData,
      })
      .afterClosed()
      .subscribe((modalResponse) => {
        if (modalResponse) {
          if (address.id) {
            this.addressService
              .delete(address.id)
              .pipe(takeUntil(this.destroy$))
              .subscribe(() => {
                this.addressDeleted.emit(address);
              });
          } else {
            this.addressDeleted.emit(address);
          }
        }
      });
  }

  getIconTooltipText(type: 'phone' | 'phone2' | 'email'): string {
    let text = '';
    if (type === 'email') {
      if (this.address.email_checked_at) {
        text += `Geprüft am: ${DateHelper.getFormattedDateTime(
          new Date(this.address.email_checked_at),
        )} `;
      }
    } else {
      const prefix = type === 'phone' ? '' : '2';
      if (this.address[`phone${prefix}_checked_at`]) {
        text += `Geprüft am: ${DateHelper.getFormattedDateTime(
          new Date(this.address[`phone${prefix}_checked_at`]),
        )} `;
      }
      if (this.address[`phone${prefix}_provider`]) {
        text += `Provider: ${this.address[`phone${prefix}_provider`]} `;
      }
      if (this.address[`phone${prefix}_type`]) {
        text += `Typ: ${this.address[`phone${prefix}_type`]} `;
      }
    }
    return text;
  }

  getAddressTypeLabel(type: AddressType): string {
    switch (type) {
      case AddressType.DEFAULT:
        return 'Standardadresse (Lieferadresse)';
      case AddressType.INVOICE:
        return 'Abweichende Rechnungsadresse';
      case AddressType.IMPRINT:
        return 'Impressum';
      default:
        return 'Unbekannter Typ';
    }
  }

  onSeparateAddressChange(): void {
    this._address.separateAddress = this.separateAddress;
    this.notifyChange();
  }

  isFieldInvalid(field: string): boolean {
    switch (field) {
      case 'address':
        return !this.separateAddress && !this.address.address;
      case 'street':
        return this.separateAddress && !this.address.street;
      case 'postcode':
        return this.separateAddress && !this.address.postcode;
      case 'city':
        return this.separateAddress && !this.address.city;
      default:
        return false;
    }
  }

  protected readonly AddressType = AddressType;
  protected readonly Application = Application;
  protected readonly ApplicationHelper = ApplicationHelper;
}
