import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CountriesConst } from '../../../data/countries.const';
import { SnackBarService } from '../../../services/snack-bar/snack-bar.service';
import { ApplicationHelper } from '../../../helpers/application-helper';
import { AddressService } from '../../../services/address/address.service';
import { SipgateService } from '../../../services/sipgate/sipgate.service';
import { EmailEditorComponent } from '../../communication/email-editor/email-editor.component';
import { SmsEditorComponent } from '../../communication/sms-editor/sms-editor.component';
import { Lead } from '../../../models/leads/lead';
import { LeadService } from '../../../services/lead/lead.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent {
  @Input() address: any = {};
  @Input() inputsDisabled: boolean = false;
  @Output() addressChanged = new EventEmitter<any>();

  appName: string = ApplicationHelper.applicationName;
  separateAddress = false;
  delayTimeout: any;

  autocompleteSuggestions: any = [];
  countries = CountriesConst;

  constructor(
    private addressService: AddressService,
    private snackServices: SnackBarService,
    private sipgateService: SipgateService,
    private leadService: LeadService,
    public dialog: MatDialog,
  ) {}

  onAutocompleteSearchInputChange() {
    clearTimeout(this.delayTimeout);
    this.delayTimeout = setTimeout(() => {
      this.addressService
        .search2(this.address.address)
        .subscribe((data: any) => {
          if (data && data.items && data.items.length > 0) {
            this.autocompleteSuggestions = data.items;
          }
        });
    }, 250);
  }

  onAutocompleteAddressChange() {
    this.geoCodeAddress(this.address.address, (geocodeResp: any) => {
      this.address.address = geocodeResp.title;
      this.address.lat = geocodeResp.position.lat;
      this.address.lng = geocodeResp.position.lng;
      this.address.street = geocodeResp.address.street;

      if (geocodeResp.address.houseNumber) {
        this.address.street += ' ' + geocodeResp.address.houseNumber;
      }

      this.address.city = geocodeResp.address.city;
      this.address.postcode = geocodeResp.address.postalCode;
      this.address.district = geocodeResp.address.district;
      this.address.federal_state = geocodeResp.address.state;

      const country = CountriesConst.find(
        (c) => c.name == geocodeResp.address.countryName,
      );

      if (country) {
        this.address.country_code = country.code;
        this.address.land = geocodeResp.address.countryName;
      }

      this.notifyAddressChange();
    });
  }

  onAddressChange() {
    const country = CountriesConst.find(
      (c) => c.code == this.address.country_code,
    );

    let searchTerm =
      (this.address.street ? this.address.street + ', ' : '') +
      (this.address.postcode ? this.address.postcode + ' ' : '') +
      (this.address.city ? this.address.city + ', ' : '');
    if (country != undefined) {
      searchTerm += country.name;
    }
    this.address.address = searchTerm;
    this.geoCodeAddress(searchTerm, (geocodeResp: any) => {
      this.address.lat = geocodeResp.position.lat;
      this.address.lng = geocodeResp.position.lng;
      this.address.land = geocodeResp.address.countryName;
      this.address.federal_state = geocodeResp.address.state;
      this.notifyAddressChange();
    });
  }

  geoCodeAddress(searchTerm: string, callback: any = null) {
    clearTimeout(this.delayTimeout);
    this.delayTimeout = setTimeout(() => {
      this.addressService
        .geoCodeSearch(searchTerm, this.address.country_code)
        .subscribe((geocodeResp: any) => {
          if (
            geocodeResp.items == undefined ||
            geocodeResp.items.length == 0 ||
            geocodeResp.items[0].scoring.queryScore != 1
          ) {
            this.snackServices.openSnackBar('Adresse nicht gefunden.', 'warn');
          } else {
            callback(geocodeResp.items[0]);
          }
        });
    }, 100);
  }

  notifyAddressChange() {
    this.addressChanged.emit(this.address);
  }

  onLeadCallClick(phoneNumber: string) {
    this.leadService.show(this.address.lead_id).subscribe((lead: Lead) => {
      this.sipgateService
        .callUser({
          leadTypeId: lead.lead_type_id,
          calleeNumber: phoneNumber,
        })
        .subscribe(() => {});
    });
  }

  onLeadEmailClick(emailAddress: string) {
    const dialog = this.dialog.open(EmailEditorComponent, {
      width: '900px',
      disableClose: true,
      data: { emailAddress: emailAddress },
    });

    dialog.afterClosed().subscribe((result) => {
      this.notifyAddressChange();
    });
  }

  onLeadSmsClick(phoneNumber: string) {
    this.dialog.open(SmsEditorComponent, {
      minWidth: '400px',
      disableClose: true,
      data: { phoneNumber: phoneNumber },
    });
  }

  onDuplicateCheck() {
    this.notifyAddressChange();
  }

  onNameChange() {
    this.address.name = this.address.first_name + ' ' + this.address.last_name;
    this.notifyAddressChange();
  }
}
