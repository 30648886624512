import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FeedInteractionCounts,
  FeedItemActionButton,
  NewFeed,
} from '../../models/feed';
import { ButtonType } from '../button/button.component';
import {
  CdkOverlayOrigin,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { FeedItemService } from '../../services/feed-item.service';
import { Lead } from '../../models/leads/lead';
import { Reaction } from '../../models/reaction';
import { ApplicationHelper } from '../../helpers/application-helper';
import { Application } from '../../enum-collection';
import { ReactionService } from '../../services/reaction.service';
import { AuthService } from '../../services/auth.service';
import { EmojiPickerService } from '../../services/emoji-picker.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'lib-feed-item-detail',
  templateUrl: './feed-item-detail.component.html',
  styleUrls: ['./feed-item-detail.component.scss'],
})
export class FeedItemDetailComponent
  implements OnDestroy, AfterViewInit, OnInit
{
  @Input() lead?: Lead = new Lead({});
  @Input() feed!: NewFeed;
  @Input() withOverlay: boolean = false;
  @Input() triggerElement?: ElementRef;
  @Input() showCommentInput = false;
  @Output() closeOverlay = new EventEmitter<void>();
  @ViewChild('contentTemplate') contentTemplate!: TemplateRef<any>;
  @ViewChild(CdkPortal) portal!: CdkPortal;

  @ViewChild('emojiTrigger', { read: CdkOverlayOrigin })
  emojiTrigger!: CdkOverlayOrigin;
  private positionStrategy: any;
  showEmojiPicker = false;

  interactionCounts: FeedInteractionCounts = {
    commentCount: 0,
    reactionCount: 0,
  };

  protected readonly ButtonType = ButtonType;
  private overlayRef: OverlayRef | null = null;
  availableActions: FeedItemActionButton[] = [];
  currentUserReaction: Reaction | null = null;
  description: string = '';
  sanitizedDescription: SafeHtml = '';

  @HostListener('document:mousedown', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.overlayRef) return;

    const clickTarget = event.target as HTMLElement;
    const isInOverlayContainer = clickTarget.closest('.cdk-overlay-container');

    if (isInOverlayContainer) {
      return;
    }

    this.close();
  }

  constructor(
    private overlay: Overlay,
    private positionBuilder: OverlayPositionBuilder,
    private feedItemService: FeedItemService,
    private reactionService: ReactionService,
    private authService: AuthService,
    private emojiPickerService: EmojiPickerService,
    private changeDetectorRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
  ) {}

  ngAfterViewInit() {
    if (this.withOverlay && this.triggerElement) {
      Promise.resolve().then(() => {
        this.showOverlay();
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  ngOnInit() {
    this.description = this.getDescription();
    this.availableActions = this.getAvailableActions();
    this.loadCurrentUserReaction();
    this.interactionCounts = this.feedItemService.getInteractionCounts(
      this.feed,
    );
  }

  private loadCurrentUserReaction() {
    if (!this.feed?.relatable?.id) return;

    this.reactionService
      .getReactionsForModel(this.getRelatableType(), this.feed.relatable.id)
      .subscribe((reactions) => {
        this.authService.getCurrentUser().subscribe((user) => {
          if (ApplicationHelper.applicationName === Application.Leadmanager) {
            this.currentUserReaction =
              reactions.find((r) => r.user_id === user?.id) ?? null;
          } else {
            this.currentUserReaction =
              reactions.find((r) => r.partner_user_id === user?.id) ?? null;
          }
          this.updateAvailableActions();
        });
      });
  }

  private updateAvailableActions() {
    this.availableActions = this.getAvailableActions();
  }

  getAvailableActions(): FeedItemActionButton[] {
    return this.feedItemService.getAvailableActions(this.feed, this.lead);
  }

  private showOverlay() {
    if (!this.triggerElement?.nativeElement || !this.portal) return;

    this.positionStrategy = this.positionBuilder
      .flexibleConnectedTo(this.triggerElement.nativeElement)
      .withPositions([
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
          offsetX: -15,
          offsetY: 35,
        },
      ]);

    const overlayConfig = {
      hasBackdrop: false,
      panelClass: ['overlay-panel', 'feed-item-detail-overlay'],
      positionStrategy: this.positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    };

    this.overlayRef = this.overlay.create(overlayConfig);
    this.overlayRef.attach(this.portal);
    this.updateOverlayPosition();
  }

  private updateOverlayPosition() {
    if (!this.overlayRef || !this.triggerElement) return;

    requestAnimationFrame(() => {
      const triggerRect =
        this.triggerElement?.nativeElement.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const overlayWidth = viewportWidth - triggerRect.right - 32;

      const overlayElement = this.overlayRef?.overlayElement;
      if (overlayElement) {
        overlayElement.style.width = `${overlayWidth}px`;
      }
    });
  }

  close() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
      this.closeOverlay.emit();
    }
  }

  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  getDescription(): string {
    const description = this.feedItemService.getDescription(this.feed);
    this.sanitizedDescription =
      this.sanitizer.bypassSecurityTrustHtml(description);
    return description;
  }
  getInteractionText(): string {
    const { commentCount, reactionCount } = this.interactionCounts;

    if (commentCount === 0 && reactionCount === 0) {
      return 'keine Reaktionen | keine Kommentare';
    }

    const parts = [];
    if (reactionCount > 0) {
      parts.push(
        `${reactionCount} ${reactionCount === 1 ? 'Reaktion' : 'Reaktionen'}`,
      );
    } else {
      parts.push('keine Reaktionen');
    }
    if (commentCount > 0) {
      parts.push(
        `${commentCount} ${commentCount === 1 ? 'Kommentar' : 'Kommentare'}`,
      );
    } else {
      parts.push('keine Kommentare');
    }

    return parts.join(' | ');
  }

  handleClick(action: FeedItemActionButton) {
    if (action.type === ButtonType.COMMENT) {
      this.showCommentInput = !this.showCommentInput;
      return;
    }

    if (action.type === ButtonType.THUMBS_UP) {
      if (this.currentUserReaction) {
        this.removeReaction();
        return;
      }
      this.showEmojiPicker = !this.showEmojiPicker;
      return;
    }
    if (!action.disabled && action.onClick) {
      action.onClick();
    }
  }

  removeReaction() {
    this.emojiPickerService.removeReaction(this.currentUserReaction, () => {
      this.currentUserReaction = null;
      this.reloadFeed();
    });
  }

  closeEmojiPicker(): void {
    this.showEmojiPicker = false;
  }

  onEmojiSelect(event: any) {
    this.emojiPickerService.onEmojiSelect(
      event,
      this.feed.relatable_id,
      this.feed.relatable_type,
      () => {
        this.showEmojiPicker = false;
        this.reloadFeed();
      },
    );
  }
  reloadFeed() {
    this.feedItemService.emitFeedUpdated();
  }

  getRelatableType(): string {
    return this.feed.relatable_type.split('App\\')[1];
  }

  getReactionTooltipText(): string {
    return this.reactionService.getReactionTooltipText(this.feed);
  }
}
