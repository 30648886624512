import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { EmailConfigurationMailServer } from '../../models/email-configuration';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '@lib/services/environment.service';
import { EmailTemplate } from '@lib/models/email-template';
import { EmailObject } from '@lib/models/email';
import { ModalInputData } from '@lib/models/modal';
import { DomSanitizer } from '@angular/platform-browser';

interface EmailField {
  key: keyof EmailObject;
  label: string;
  format?: (value: any) => string;
}

@Injectable({
  providedIn: 'root',
})
export class EmailConfigurationService {
  private emailServersSubject = new BehaviorSubject<
    EmailConfigurationMailServer[]
  >([]);
  public emailServers$ = this.emailServersSubject.asObservable();

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private sanitizer: DomSanitizer,
  ) {}

  loadConfiguration(): Observable<EmailConfigurationMailServer[]> {
    return this.http
      .get<EmailConfigurationMailServer[]>(
        `${this.environmentService.apiUrl}email-configuration`,
      )
      .pipe(
        tap((mailServers) => {
          this.emailServersSubject.next(mailServers);
        }),
        catchError((error) => {
          console.error('Error loading configuration:', error);
          return throwError(
            () => new Error('Failed to load email configuration'),
          );
        }),
      );
  }

  addNewServer(
    newServer: EmailConfigurationMailServer,
  ): Observable<EmailConfigurationMailServer> {
    return this.http
      .post<any>(
        `${this.environmentService.apiUrl}email-configuration`,
        newServer,
      )
      .pipe(
        map((response) => response.server),
        tap((savedServer) => {
          const currentServers = this.emailServersSubject.value;
          this.emailServersSubject.next([...currentServers, savedServer]);
        }),
        catchError((error) => {
          console.error('Error adding new server:', error);
          return throwError(() => new Error('Failed to add new email server'));
        }),
      );
  }

  updateMailServer(updatedServer: EmailConfigurationMailServer): void {
    const currentServers = this.emailServersSubject.value;
    const updatedServers = currentServers.map((server) =>
      server.id === updatedServer.id ? updatedServer : server,
    );
    this.emailServersSubject.next(updatedServers);
  }

  saveConfiguration(): Observable<EmailConfigurationMailServer[]> {
    return this.http
      .put<{ message: string; servers: EmailConfigurationMailServer[] }>(
        // hier kann fix die 1 mitgeben werden, es werden immer alle Daten aktualisiert
        `${this.environmentService.apiUrl}email-configuration/1`,
        this.emailServersSubject.value,
      )
      .pipe(
        map((response) => response.servers),
        tap((savedServers) => {
          this.emailServersSubject.next(savedServers);
        }),
        catchError((error) => {
          console.error('Error saving configuration:', error);
          return throwError(
            () => new Error('Failed to save email configuration'),
          );
        }),
      );
  }

  deleteServer(serverId: number): Observable<void> {
    return this.http
      .delete<void>(
        `${this.environmentService.apiUrl}email-configuration/${serverId}`,
      )
      .pipe(
        tap(() => {
          const currentServers = this.emailServersSubject.value;
          const updatedServers = currentServers.filter(
            (server) => server.id !== serverId,
          );
          this.emailServersSubject.next(updatedServers);
        }),
        catchError((error) => {
          console.error('Error deleting server:', error);
          return throwError(() => new Error('Failed to delete email server'));
        }),
      );
  }

  ignoreError(emailId: number): Observable<{ message: string }> {
    return this.http
      .post<{ message: string }>(
        `${this.environmentService.apiUrl}email-configuration/ignore-error/${emailId}`,
        {},
      )
      .pipe(
        catchError((error) => {
          console.error('Error ignoring server error:', error);
          return throwError(
            () => new Error('Failed to ignore email server error'),
          );
        }),
      );
  }

  sendTestEmail(testEmailData: any): Observable<any> {
    return this.http.post(
      `${this.environmentService.apiUrl}email-configuration/test-email`,
      testEmailData,
    );
  }

  getEmailStats(startDate: string, endDate: string): Observable<any> {
    return this.http
      .get<any>(`${this.environmentService.apiUrl}email-configuration/stats`, {
        params: { start_date: startDate, end_date: endDate },
      })
      .pipe(
        catchError((error) => {
          console.error('Error fetching email stats:', error);
          return throwError(() => new Error('Failed to fetch email stats'));
        }),
      );
  }

  getEmailTemplates(): Observable<[]> {
    return this.http
      .get<[]>(`${this.environmentService.apiUrl}email_templates`)
      .pipe(
        catchError((error) => {
          console.error('Error fetching email stats:', error);
          return throwError(() => new Error('Failed to fetch email stats'));
        }),
      );
  }

  updateEmailTemplate(
    id: number,
    emailTemplate: Partial<EmailTemplate>,
  ): Observable<EmailTemplate> {
    return this.http
      .put<EmailTemplate>(
        `${this.environmentService.apiUrl}email_templates/${id}`,
        emailTemplate,
      )
      .pipe(
        catchError((error) => {
          console.error('Error fetching email stats:', error);
          return throwError(() => new Error('Failed to fetch email stats'));
        }),
      );
  }

  createEmailTemplate(emailTemplate: EmailTemplate): Observable<EmailTemplate> {
    return this.http
      .post<EmailTemplate>(
        `${this.environmentService.apiUrl}email_templates`,
        emailTemplate,
      )
      .pipe(
        catchError((error) => {
          console.error('Error fetching email stats:', error);
          return throwError(() => new Error('Failed to fetch email stats'));
        }),
      );
  }

  getEmailPreview(data: any): Observable<any> {
    let dataRequest = {
      preview: {
        email_template_id: data.email_template_id,
        sender_email: data.sender_email,
        sms_device_id: data.sms_device_id,
        subject: data.subject,
        body: data.body,
      },
      lead_type_id: data.lead_type_id,
    };
    return this.http.post(
      this.environmentService.apiUrl + 'email_preview/',
      dataRequest,
      {
        responseType: 'json',
      },
    );
  }

  deleteEmailTemplate(id: number): Observable<void> {
    return this.http
      .delete<void>(`${this.environmentService.apiUrl}email_templates/${id}`)
      .pipe(
        catchError((error) => {
          console.error('Error deleting email template:', error);
          return throwError(() => new Error('Failed to delete email template'));
        }),
      );
  }

  getResendEmailData(emailObject: EmailObject): ModalInputData {
    const fields: EmailField[] = [
      { key: 'subject', label: 'Betreff' },
      { key: 'mail_to', label: 'An' },
      {
        key: 'mail_from',
        label: 'Von',
        format: (value: string) => `${value} (${emailObject.mail_from_name})`,
      },
      {
        key: 'mail_cc',
        label: 'CC',
        format: (value: string) => value || 'Nicht angegeben',
      },
      {
        key: 'mail_bcc',
        label: 'BCC',
        format: (value: string) => value || 'Nicht angegeben',
      },
      { key: 'lead_id', label: 'Lead ID' },
      {
        key: 'partner_lead_id',
        label: 'Partner Lead ID',
      },
      { key: 'email_type_id', label: 'E-Mail Typ ID' },
      {
        key: 'email_opened_at',
        label: 'Zuletzt geöffnet',
        format: (value: string) => value || 'Nicht geöffnet',
      },
      { key: 'response_code', label: 'Letzter Antwort-Code' },
    ];

    const bodyContent = fields
      .filter(
        (field) =>
          emailObject[field.key] !== undefined &&
          emailObject[field.key] !== null &&
          emailObject[field.key] !== '',
      )
      .map(
        (field) =>
          `<li><strong>${field.label}:</strong> ${
            field.format
              ? field.format(emailObject[field.key])
              : emailObject[field.key]
          }</li>`,
      )
      .join('');

    let emailBody = emailObject.body
      ? `<h4>E-Mail Body:</h4><div style="max-height: 500px; overflow-y: auto; border: 1px solid #ccc; padding: 10px;">${emailObject.body}</div>`
      : '';

    emailBody = emailBody.replace('<a href=', '<a target="_blank" href=');

    return {
      headline: 'E-Mail erneut senden?',
      body: this.sanitizer.bypassSecurityTrustHtml(`
        <h3>E-Mail Details:</h3>
        <ul style="list-style-type: none; padding-left: 0;">
          ${bodyContent}
        </ul>
        ${emailBody}
        <p style="margin-top: 10px"><b>Möchten Sie diese E-Mail erneut senden?</b></p>
      `),
      acceptButtonText: 'Ja, senden',
      declineButtonText: 'Nein, abbrechen',
    };
  }
}
