<app-card>
  <app-card-head pageTitle="Leads">
    <div class="headline-container">
      <!-- Linke Seite -->
      <div class="left-section">
        <div class="headline-element">
          <lib-slide-toggle
            style="margin-bottom: 10px"
            [value]="(useNewView$ | async) ?? false"
            (valueChange)="toggleView()"
          >
            Testen Sie die neue Lead-Liste
          </lib-slide-toggle>
        </div>
      </div>

      <div class="center-section">
        <div class="bill-info bill-warning" *ngIf="hasMissingBills$ | async">
          <mat-icon class="bill-info-icon">warning</mat-icon>
          <span>Es gibt Leads mit fehlenden Rechnungen</span>
        </div>

        <div class="headline-element">
          <button mat-stroked-button color="primary" (click)="onExportExcel()">
            <i class="bi bi-cloud-download"></i> Excel herunterladen
          </button>
        </div>
      </div>

      <!-- Rechte Seite -->
      <div class="right-section">
        <div class="headline-element">
          <app-tooltip
            [text]="
              'Der Score zeigt für welches Leadpotenzial Sie sich qualifizieren.<br /><br />Je höher der Score, desto mehr Leads erhalten Sie von uns.<br /><br />Den Score können Sie erhöhen durch schnelle Reaktionszeiten und Rückmeldungen zu den einzelnen Leads.<br /><br /><b>Leads mit dem Status -Neu- wirken sich negativ auf Ihren Score aus</b>'
            "
          >
            <div class="lead-list-headline-element">
              <mat-progress-bar
                mode="determinate"
                [value]="partnerScore$ | async"
                color="primary"
              ></mat-progress-bar>
              <div>{{ partnerScore$ | async }}%</div>
            </div>
          </app-tooltip>
        </div>
      </div>
    </div>

    <!-- Filter Bar -->
    <lib-filter-bar [filterSettings]="filterBarSettings"></lib-filter-bar>
  </app-card-head>
  <app-card-body>
    <lib-table
      [filter]="filter"
      [tableId]="'partnerLeadListNew'"
      [initialPageSize]="50"
      [endpoint]="'partner_leads/'"
      [showSearchbar]="true"
      (displayedDataChanged)="onDisplayedDataChanged($event)"
      (clickedElementChanged)="onLeadSelected($event)"
      [selectedId]="selectedLeadId$ | async"
      [additionalSearchFields]="[
        'addresses.full_text_index',
        'lead_number',
        'offer_number'
      ]"
    >
      <lib-table-column
        [columnName]="'address.company_name'"
        [title]="'Name'"
        [isSortable]="true"
        [isSearchable]="false"
        [designPointer]="true"
        [customTemplate]="nameTemplate"
        [headlineTooltipText]="
          'Name des Unternehmens oder der Privatperson. Bei Firmen wird der Unternehmensname angezeigt, bei Privatpersonen der persönliche Name.'
        "
      >
        <ng-template #nameTemplate let-partnerLead>
          <div class="lead-number-container">
            <div class="warning-wrapper" *ngIf="isBillOverdue(partnerLead)">
              <mat-icon
                class="bill-warning-icon"
                [matTooltip]="getBillWarningTooltip(partnerLead)"
                matTooltipClass="multiline-tooltip"
              >
                warning
              </mat-icon>
            </div>
            <span class="lead-number">
              <span>
                {{
                  addressService.getDisplayName(
                    partnerLead.address.company_name,
                    partnerLead.address.name
                  )
                }}
              </span></span
            >
          </div>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [columnName]="'address.postcode'"
        [title]="'PLZ'"
        [isSortable]="true"
        [isSearchable]="true"
        [designPointer]="true"
        [headlineTooltipText]="
          'Postleitzahl und Stadt der Lieferadresse des Leads.'
        "
        [customTemplate]="postcodeTemplate"
      >
        <ng-template #postcodeTemplate let-partnerLead>
          {{ partnerLead.address.postcode }} {{ partnerLead.address.city }}
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [defaultSort]="'desc'"
        [columnName]="'send_at'"
        [pipe]="{ name: 'date', args: ['dd.MM.yyyy HH:mm'] }"
        [suffix]="'Uhr'"
        [isSearchable]="false"
        [designPointer]="true"
        [title]="'Eingangsdatum'"
        [headlineTooltipText]="
          'Zeitpunkt, zu dem der Lead an Sie weitergeleitet wurde - NICHT der Zeitpunkt an dem Sie den Lead angenommen haben.'
        "
      >
      </lib-table-column>

      <lib-table-column
        [columnName]="'offer_number'"
        [title]="'Referenznummer'"
        [isSortable]="true"
        [isSearchable]="true"
        [designPointer]="true"
        [headlineTooltipText]="
          'Ihre individuelle Referenznummer für diesen Lead. Kann für die interne Zuordnung verwendet werden z.B. Ihre Angebots- oder Interessentennummer.'
        "
      >
      </lib-table-column>

      <lib-table-column
        [columnName]="'offer_value'"
        [title]="'Angebotswert'"
        [isSortable]="true"
        [isSearchable]="false"
        [designPointer]="true"
        [summableColumnName]="'offer_value_sum'"
        [summableColumnUnit]="'price'"
        [pipe]="{ name: 'currency', args: ['€'] }"
      >
      </lib-table-column>

      <lib-table-column
        [columnName]="'umsatz'"
        [title]="'Umsatz'"
        [isSortable]="true"
        [isSearchable]="false"
        [designPointer]="true"
        [summableColumnName]="'umsatz_sum'"
        [summableColumnUnit]="'price'"
        [pipe]="{ name: 'currency', args: ['€'] }"
        [headlineTooltipText]="
          'Geschätzter oder angegebener Wert des potenziellen Auftrags. Hilft bei der Priorisierung der Leads.'
        "
      >
      </lib-table-column>

      <lib-table-column
        [columnName]="'assigned_user_id'"
        [title]="'Zugewiesen'"
        [isSortable]="true"
        [isSearchable]="false"
        [designPointer]="true"
        [customTemplate]="assignedUserTemplate"
        [headlineTooltipText]="
          'Zuständiger Benutzer für diesen Lead. Nutzen Sie das Dropdown, um den Lead einem Benutzer zuzuweisen.'
        "
      >
        <ng-template #assignedUserTemplate let-partnerLead>
          <lib-user-assignment
            style="margin-left: 20px"
            [users]="getTargetingUsers(partnerLead)"
            [assignedUser]="partnerLead.assigned_user"
            (assignUser)="assignUserToLead($event, partnerLead)"
            (removeUser)="removeUserAssignment(partnerLead)"
          ></lib-user-assignment>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [columnName]="'status'"
        [title]="'Status'"
        [isSortable]="true"
        [isSearchable]="false"
        [designPointer]="true"
        [customTemplate]="statusTemplate"
        [headlineTooltipText]="
          'Aktueller Bearbeitungsstatus des Leads. Beeinflusst Ihren Partner-Score und sollte gepflegt werden.'
        "
      >
        <ng-template #statusTemplate let-partnerLead>
          <div class="status-select-wrapper">
            <div
              [class]="
                'status-select ' + getStatusClass(partnerLead.status.option_id)
              "
              [matMenuTriggerFor]="statusMenu"
              (click)="$event.stopPropagation()"
            >
              <div class="status-trigger">
                <span class="status-dot"></span>
                {{ partnerLead.status.name }}
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>

            <mat-menu #statusMenu="matMenu" class="status-dropdown">
              <button
                mat-menu-item
                *ngFor="let option of statusOptions$ | async"
                (click)="
                  $event.stopPropagation(); setLeadStatus(option, partnerLead)
                "
                [class]="getStatusClass(option.value)"
              >
                <div class="status-option">
                  <span class="status-dot"></span>
                  {{ option.name }}
                </div>
              </button>
            </mat-menu>
          </div>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [columnName]="'latest_lead_response_at'"
        [title]="'Lead Rückmeldung'"
        [isSortable]="false"
        [isSearchable]="false"
        [designPointer]="true"
        [customTemplate]="leadResponseTemplate"
        [headlineTooltipText]="
          'Letzte Rückmeldung vom Lead. Zeigt an, welche Rückmeldung uns der Lead zu der Anfrage gegeben hat.'
        "
      >
        <ng-template #leadResponseTemplate let-partnerLead>
          <div
            class="lead-response-badge"
            *ngIf="partnerLead.latest_lead_response?.response"
            [matTooltip]="getLeadResponseTooltip(partnerLead)"
            matTooltipClass="multiline-tooltip"
          >
            <div
              class="lead-response-content"
              [ngClass]="getLeadResponseClass(partnerLead)"
            >
              <mat-icon [inline]="true">
                {{ getLeadResponseIcon(partnerLead) }}
              </mat-icon>
              <span class="response-date">
                {{
                  partnerLead.latest_lead_response?.response_at
                    | date : 'dd.MM.yyyy'
                }}
              </span>
            </div>
          </div>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [columnName]="'next_reminder_date'"
        [title]="'Nächste Erinnerung'"
        [isSortable]="true"
        [isSearchable]="false"
        [designPointer]="true"
        [headlineTooltipText]="
          'Datum und Art der nächsten geplanten Aktion für diesen Lead. Hilft bei der Terminplanung und Nachverfolgung. Sollte bei jedem Lead gesetzt werden, um die Nachverfolgung bestmöglich zu gestalten.'
        "
        [customTemplate]="remindersTemplate"
      >
        <ng-template #remindersTemplate let-partnerLead>
          <ng-container
            *ngIf="partnerLead.reminders?.length > 0; else noReminder"
          >
            <div
              class="reminder-badge"
              [ngClass]="getBadgeClass(partnerLead.reminders[0])"
            >
              <div class="reminder-content">
                <mat-icon [inline]="true">
                  {{ getReminderIcon(partnerLead.reminders[0]) }}
                </mat-icon>
                {{ formatDate(partnerLead.reminders[0].reminder_date) }}
                <span class="truncated-reason"
                  >({{
                    partnerLead.reminders[0].reminder_type_option.name
                  }})</span
                >
              </div>
            </div>
          </ng-container>

          <ng-template #noReminder>
            <div class="reminder-badge no-reminder">
              <div class="reminder-content">
                <mat-icon [inline]="true">notifications_off</mat-icon>
                Keine Erinnerung
              </div>
            </div>
          </ng-template>
        </ng-template>
      </lib-table-column>
    </lib-table>
  </app-card-body>
</app-card>
