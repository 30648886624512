import { Lead } from './leads/lead';

export class Call {
  id: number = 0;
  lead_id: number = 0;
  callId: string = '';
  direction: string = '';
  duration: number = 0;
  event: string = '';
  event_at: string = '';
  hangUpCause: string = '';
  answeringNumber: string = '';
  from: string = '';
}

export interface ActiveCall {
  direction: string;
  from: string;
  duration: number;
  lead: Lead;
}

export interface UserCallStatus {
  user_id: number;
  first_name: string;
  last_name: string;
  status: 'available' | 'ringing_call' | 'active_call' | 'editing' | 'offline';
  direction: string;
  duration: number;
  lead: Lead;
  active_call: ActiveCall | null;
  last_call_since?: string;
}

export class CallData {
  incoming: ActiveCall[] = [];
  users: UserCallStatus[] = [];
}
