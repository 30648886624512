import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Feed, FeedItemActionButton, NewFeed } from '../../models/feed';
import { ButtonType } from '../button/button.component';
import { IconBadge } from '../icon/icon.component';
import { FeedItemService } from '../../services/feed-item.service';
import { Lead } from '../../models/leads/lead';
import { ReactionService } from '../../services/reaction.service';
import { Reaction } from '../../models/reaction';
import { ApplicationHelper } from '../../helpers/application-helper';
import { Application } from '../../enum-collection';
import { AuthService } from '../../services/auth.service';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { EmojiPickerService } from '../../services/emoji-picker.service';

@Component({
  selector: 'lib-feed-item',
  templateUrl: './feed-item.component.html',
  styleUrls: ['./feed-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedItemComponent implements OnInit, AfterViewInit {
  @Input() lead?: Lead = new Lead({});
  @Input() feed!: NewFeed;
  @Input() isExpanded = false;
  @Output() action = new EventEmitter<{ type: string; feed: Feed }>();
  @Output() toggleExpand = new EventEmitter<Feed>();
  @ViewChild('emojiTrigger', { read: CdkOverlayOrigin, static: false })
  emojiTrigger!: CdkOverlayOrigin;

  shortDescription: string | null = null;
  availableActions: FeedItemActionButton[] = [];
  currentUserReaction: Reaction | null = null;
  showEmojiPicker = false;
  showDetailComment = false;

  constructor(
    private feedItemService: FeedItemService,
    private reactionService: ReactionService,
    private authService: AuthService,
    private emojiPickerService: EmojiPickerService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.shortDescription = this.getShortDescription();
    this.availableActions = this.getAvailableActions();
    this.loadCurrentUserReaction();
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  private loadCurrentUserReaction() {
    if (!this.feed?.relatable?.id) return;

    this.reactionService
      .getReactionsForModel(this.getRelatableType(), this.feed.relatable.id)
      .subscribe((reactions) => {
        this.authService.getCurrentUser().subscribe((user) => {
          if (ApplicationHelper.applicationName === Application.Leadmanager) {
            this.currentUserReaction =
              reactions.find((r) => r.user_id === user?.id) ?? null;
          } else {
            this.currentUserReaction =
              reactions.find((r) => r.partner_user_id === user?.id) ?? null;
          }
          this.updateAvailableActions();
          this.cd.markForCheck();
        });
      });
  }

  private getRelatableType(): string {
    return this.feed.relatable_type.split('App\\')[1];
  }

  getIcon(): string {
    return this.feedItemService.getIcon(this.feed.relatable_type);
  }

  getBadges(): IconBadge[] {
    return this.feedItemService.getBadges(this.feed);
  }

  getAvailableActions(): FeedItemActionButton[] {
    return this.feedItemService.getAvailableActions(this.feed, this.lead);
  }

  private updateAvailableActions() {
    this.availableActions = this.getAvailableActions();
  }

  getHeadline(): string {
    return this.feedItemService.getHeadline(this.feed);
  }

  getShortDescription(): string {
    return this.feedItemService.getShortDescription(this.feed);
  }

  onToggleExpand(): void {
    this.toggleExpand.emit(this.feed);
    if (!this.isExpanded) {
      this.showDetailComment = false;
    }
    this.cd.markForCheck();
  }

  handleClick(action: FeedItemActionButton) {
    if (action.type === ButtonType.THUMBS_UP) {
      if (this.currentUserReaction) {
        this.removeReaction();
        return;
      }
      this.showEmojiPicker = !this.showEmojiPicker;
      return;
    }

    if (action.type === ButtonType.COMMENT) {
      if (!this.isExpanded) {
        this.onToggleExpand();
      }
      this.showDetailComment = true;
      this.cd.markForCheck();
      this.action.emit({ type: 'comment', feed: this.feed });
      return;
    }

    if (!action.disabled && action.onClick) {
      action.onClick();
    }
  }

  removeReaction() {
    this.emojiPickerService.removeReaction(this.currentUserReaction, () => {
      this.currentUserReaction = null;
      this.feedItemService.emitFeedUpdated();
      this.cd.markForCheck();
    });
  }

  closeEmojiPicker(): void {
    this.showEmojiPicker = false;
    this.cd.markForCheck();
  }

  onEmojiSelect(event: any) {
    this.emojiPickerService.onEmojiSelect(
      event,
      this.feed.relatable_id,
      this.feed.relatable_type,
      () => {
        this.showEmojiPicker = false;
        this.feedItemService.emitFeedUpdated();
        this.cd.markForCheck();
      },
    );
  }

  getReactionTooltipText(): string {
    return this.reactionService.getReactionTooltipText(this.feed);
  }

  protected readonly ButtonType = ButtonType;
}
