import { BehaviorSubject, combineLatest, Observable, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { CallData } from '../models/call';
import { filter, map, switchMap } from 'rxjs/operators';
import { HttpHandlerService } from './http-handler.service';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class SseService {
  public callDataSubject = new BehaviorSubject<CallData>(new CallData());
  public callData$ = this.callDataSubject.asObservable();

  constructor(
    private httpHandler: HttpHandlerService,
    private environmentService: EnvironmentService,
  ) {}

  getProgressBar(progressBarName: string): Observable<any> {
    // const url = `${this.environmentService.apiUrl}sse_channel?processName=${encodeURIComponent(progressBarName)}&groupId=2`;
    const url = `sse_channel?processName=${encodeURIComponent(
      progressBarName,
    )}&groupId=2`;
    return this.createEventSource(url, progressBarName);
  }

  getCallStatus(): Observable<any> {
    // const url = `${this.environmentService.apiUrl}sse_channel?processName=call_status&groupId=1`;
    const url = `sse_channel?processName=call_status&groupId=1`;
    return this.createEventSource(url, 'call_status');
  }

  getBlockedLeads(): Observable<any> {
    // const url = `${this.environmentService.apiUrl}sse_channel?processName=blocked_leads&groupId=1`;
    const url = `sse_channel?processName=blocked_leads&groupId=1`;
    return this.createEventSource(url, 'heartbeat');
  }

  // private createEventSource(
  //   urlWithParam: string,
  //   eventType: string,
  // ): Observable<any> {
  //   return new Observable((observer) => {
  //     const eventSource = new EventSourcePolyfill(urlWithParam, {
  //       headers: {
  //         Authorization: `Bearer ${this.storageService.getToken()}`,
  //       },
  //     });
  //
  //     eventSource.onopen = (event: any) => {
  //       console.log('EventSource opened:', event);
  //     };
  //
  //     eventSource.addEventListener(eventType, (event: any) => {
  //       this._zone.run(() => {
  //         const value = JSON.parse(event.data);
  //         // console.log(`EventSource data received for ${eventType}:`, value);
  //
  //         if (eventType.includes('progress_bar') && value) {
  //           this.loadingService.setProgress(value);
  //         }
  //
  //         observer.next(value);
  //       });
  //     });
  //
  //     eventSource.onerror = (error: any) => {
  //       this._zone.run(() => {
  //         console.error('EventSource error:', error);
  //         observer.error(error);
  //       });
  //     };
  //
  //     return () => {
  //       if (eventSource.readyState !== EventSource.CLOSED) {
  //         eventSource.close();
  //       }
  //     };
  //   });
  // }

  private createEventSource(
    urlWithParam: string,
    eventType: string,
  ): Observable<any> {
    let interval = 2000;

    if (
      !this.environmentService.production &&
      !window.location.origin.includes('staging')
    ) {
      interval = 60000;
    }

    return timer(0, interval).pipe(
      filter(() => document.visibilityState === 'visible'),
      switchMap(() =>
        this.httpHandler.list(urlWithParam, { responseType: 'text' }),
      ),
      map((response) => {
        if (response?.event === eventType) {
          return JSON.parse(response.data);
        }
      }),
    );
  }
}
