import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '@lib/services/auth.service';
import { map } from 'rxjs';
import { PartnerUserCostType } from '@lib/enum-collection';
import { PartnerUser } from '@lib/models/partners/partner-user';

@Injectable({ providedIn: 'root' })
export class CostTypeSaleGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.partnerUser$.pipe(
      map((partnerUser: PartnerUser | null) => {
        return partnerUser?.invoice_and_performance_data === 1;
      }),
    );
  }
}
