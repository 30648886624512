import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  MapArea,
  MapHeatmapPoint,
  MapLabel,
  MapMarker,
} from '../../interfaces/map.interface';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnChanges, OnDestroy {
  @Input() mapId: string = 'map-component-map';
  @Input() mapHeadline: string = '';
  @Input() labels: MapLabel[] = [];
  @Input() markers: MapMarker[] = [];
  @Input() heatmapPoints: MapHeatmapPoint[] = [];
  @Input() areas: MapArea[] = [];
  @Input() highlightAreaId: number = 0;
  map: any;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.map?.remove();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes['areas'] && !changes['areas'].isFirstChange()) ||
      (changes['markers'] && !changes['markers'].isFirstChange()) ||
      (changes['labels'] && !changes['labels'].isFirstChange()) ||
      (changes['heatmapPoints'] && !changes['heatmapPoints'].isFirstChange())
    ) {
      this.reloadMapContent();
    }

    if (changes['highlightAreaId']) {
      this.resetAreaOpacity();
      if (this.highlightAreaId && this.highlightAreaId !== 0) {
        this.highlightArea(this.highlightAreaId);
      }
    }
  }

  reloadMapContent() {
    this.initializeMap();
  }

  initializeMap() {
    if (this.map && this.map.loaded()) {
      this.map.remove();
    }

    // @ts-ignore
    this.map = new mapboxgl.Map({
      container: this.mapId,
      style: 'https://maps.lindenfield.de/maps/style-cdn.json',
      attributionControl: true,
      hash: false,
      zoom: 5,
      center: [10.451526, 51.165691],
    });

    // @ts-ignore
    const scale = new mapboxgl.ScaleControl({ maxWidth: 80, unit: 'metric' });
    this.map.addControl(scale);

    // Überprüfe, ob das Bild bereits geladen ist, bevor es hinzugefügt wird
    this.map.on('load', () => {
      if (!this.map.hasImage('custom-marker')) {
        this.map.loadImage(
          'https://maps.fluessiggas1.de/maps/img/location-pin.png',
          (error: any, image: any) => {
            if (error) throw error;
            this.map.addImage('custom-marker', image);
          },
        );
      }

      this.addAreasToMap(this.areas);
      this.addHeatmapPointsToMap(this.heatmapPoints);
      this.addLabelsToMap(this.labels);
      this.addMarkerToMap(this.markers);
    });
  }

  addLabelsToMap(labels: MapLabel[]) {
    if (labels.length === 0) {
      return;
    }

    labels.forEach((label) => {
      if (!label.coordinates || label.coordinates.length !== 2) {
        return;
      }

      var el = document.createElement('div');
      el.innerHTML = label.html;
      el.className = 'map-label-marker';

      // @ts-ignore
      new mapboxgl.Marker(el).setLngLat(label.coordinates).addTo(this.map);
    });
  }

  addMarkerToMap(markers: MapMarker[]) {
    if (markers.length === 0) {
      return;
    }

    let features: any = [];

    markers.forEach((marker) => {
      features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: marker.coordinates,
        },
        properties: {
          description: marker.html,
        },
      });
    });

    this.map.addSource('markers', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: features,
      },
    });

    this.map.addLayer({
      id: 'markers',
      type: 'symbol',
      source: 'markers',
      layout: {
        'icon-image': 'custom-marker',
        'icon-allow-overlap': true, // Erlaubt Überlappung der Marker
        'icon-ignore-placement': true, // Verhindert das Ausblenden bei Überlappung
        visibility: 'visible', // Stellt sicher, dass Layer immer sichtbar ist
      },
    });

    this.map.on('click', 'markers', (e: any) => {
      const coordinates = e.features[0].geometry.coordinates.slice();
      const description = e.features[0].properties.description;

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // @ts-ignore
      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(this.map);
    });

    this.map.on('mouseenter', 'markers', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });

    this.map.on('mouseleave', 'markers', () => {
      this.map.getCanvas().style.cursor = '';
    });
  }

  addAreasToMap(areas: MapArea[]) {
    if (areas.length === 0) {
      return;
    }

    areas.forEach((area) => {
      let dataSourceId = 'area_' + area.id;

      if (this.map.getSource(dataSourceId)) {
        return;
      }

      this.map.addSource(dataSourceId, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: area.geometry,
            },
          ],
        },
      });

      this.map.addLayer({
        id: dataSourceId + '_fill',
        type: 'fill',
        source: dataSourceId,
        paint: {
          'fill-color': area.color,
          'fill-opacity': area.opacity,
        },
      });

      // Add a black outline around the polygon.
      this.map.addLayer({
        id: dataSourceId + '_outline',
        type: 'line',
        source: dataSourceId,
        layout: {},
        paint: {
          'line-color': '#000',
          'line-width': 0.5,
        },
      });
    });
  }

  highlightArea(areaId: number) {
    this.areas.forEach((area) => {
      this.map.setPaintProperty('area_' + area.id, 'fill-opacity', 0.0);
    });

    this.map.setPaintProperty('area_' + areaId, 'fill-opacity', 0.4);
  }

  resetAreaOpacity() {
    this.areas.forEach((area) => {
      this.map.setPaintProperty(
        'area_' + area.id,
        'fill-opacity',
        area.opacity,
      );
    });
  }

  addHeatmapPointsToMap(heatmapPoints: MapHeatmapPoint[]) {
    if (heatmapPoints.length === 0) {
      return;
    }
    let features: any = [];
    heatmapPoints.forEach((point) => {
      features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: point.coordinates,
        },
      });
    });

    if (this.map.getSource('heatmap-data')) {
      // Aktualisiere die Daten der bestehenden Quelle
      this.map
        .getSource('heatmap-data')
        .setData({ features: features, type: 'FeatureCollection' });
    } else {
      // Füge eine neue Quelle hinzu, wenn sie noch nicht existiert
      this.map.addSource('heatmap-data', {
        type: 'geojson',
        data: { features: features, type: 'FeatureCollection' },
      });

      this.map.addLayer({
        id: this.mapId,
        type: 'heatmap',
        source: 'heatmap-data',
        maxzoom: 11,
        paint: {
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(33,102,172,0)',
            0.2,
            'rgb(103,169,207)',
            0.4,
            'rgb(209,229,240)',
            0.6,
            'rgb(253,219,199)',
            0.8,
            'rgb(239,138,98)',
            1,
            'rgb(178,24,43)',
          ],
          'heatmap-radius': 20,
        },
      });
    }
  }
}
