import { Optionlist } from '../optionlist/optionlist';
import { LeadType } from '../lead-type';

export class LeadField {
  id: number = 0;
  active: number = 0;
  lead_type_id: number = 0;
  name: string = '';
  type: number = 0;
  optionlist_id: number = 0;
  question: string = '';
  partner_dashboard_status: number = 0;
  partner_dashboard_title: string = '';
  forecast: number = 0;
  forecast_weight: number = 0;
  visible_in_salesrunner: number = 0;
  used_in_lead_backend_elements_count: number = 0;
  optionlist = new Optionlist({});
  lead_type = new LeadType({});

  constructor(lead_field: {}) {
    Object.assign(this, lead_field);

    if (this.lead_type == null) {
      Object.assign(this, {
        ...lead_field,
        lead_type: new LeadType(this.lead_type || {}),
      });
    }
  }

  getFieldType(): string {
    switch (this.type) {
      case 1:
        return 'Einzeiliges Textfeld';
      case 2:
        return 'Mehrzeiliges Textfeld';
      case 3:
        return 'Ganzzahl';
      case 4:
        return 'Dezimalzahl';
      case 5:
        return 'Optionsliste';
      case 6:
        return 'Semantische Suchauswahl';
    }

    return '';
  }
}
