import { PartnerLead } from '../partner-lead';
import { Price } from '../prices';
import { FileUpload } from '../fileUpload';
import { Partner } from '../partner';

export class PartnerBill {
  id: number = 0;
  bill_date: string | null = '';
  bill_date_format: string = '';
  bill_number: string = '';
  bill_status: number = 1;
  bill_sum: number = 0;
  partner_lead_id: number = 0;
  comment: string = '';
  comment_format: string = '';
  commission_amount: string = '';
  commission_amount_sum: string = '';
  commission_rate: string = '';
  partner_bill_positions: any[] = [];
  created_at: any = '';
  partner_lead: PartnerLead = new PartnerLead({});
  documents: FileUpload[] = [];
  price: Price = new Price({});
  partner: Partner = new Partner({});

  constructor(partnerBill: PartnerBill | {}) {
    // Initialize any other properties or perform additional setup if needed
    Object.assign(this, partnerBill);
  }
}
