import { Injectable } from '@angular/core';
import { DataTablesParameters } from '../interfaces/optionen.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TargetingDetail } from '../models/targeting/targeting-detail';
import { HttpHandlerService } from './http-handler.service';

@Injectable({
  providedIn: 'root',
})
export class TargetingDetailService {
  endpoint = 'targeting_details/';
  constructor(private httpHandler: HttpHandlerService) {}
  datatable(dataTablesParams: DataTablesParameters): Observable<any> {
    return this.httpHandler.datatable(this.endpoint, dataTablesParams).pipe(
      map((response) => {
        return {
          recordsFiltered: response.recordsFiltered,
          recordsTotal: response.recordsTotal,
          data: response.data.map(
            (item: TargetingDetail) => new TargetingDetail(item),
          ),
        };
      }),
    );
  }

  list(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  getList(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  show(id: number): Observable<any> {
    return this.httpHandler.show(this.endpoint, id);
  }

  create(targetingDetail: TargetingDetail): Observable<any> {
    return this.httpHandler.create(this.endpoint, targetingDetail);
  }

  update(targetingDetail: TargetingDetail): Observable<any> {
    return this.httpHandler.update(this.endpoint, targetingDetail);
  }

  delete(id: number): Observable<any> {
    return this.httpHandler.delete(this.endpoint, id);
  }
}
