<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
  <span class="comment-edited" *ngIf="commentSummary.edited_at">
    <em>bearbeitet</em>
  </span>

  <mat-chip *ngIf="commentSummary.relatable_type === 'App\\Lead'">
    In Lead-Email
  </mat-chip>
</div>
<div [innerHTML]="commentSummary.summary"></div>
