import { SelectList } from './select-list';

export interface FilterSettingConfig {
  filter?: SelectList;
  placeholder?: string;
  multiSelect?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  allowNegative?: boolean;
}

export class FilterSetting {
  readonly filter: SelectList;
  readonly placeholder: string;
  readonly multiSelect: boolean;
  readonly disabled: boolean;
  readonly clearable: boolean;
  readonly allowNegative: boolean;

  constructor({
    filter = SelectList.None,
    placeholder = 'Bitte wählen',
    multiSelect = false,
    disabled = false,
    clearable = true,
    allowNegative = false,
  }: FilterSettingConfig = {}) {
    this.filter = filter;
    this.placeholder = placeholder;
    this.multiSelect = multiSelect;
    this.disabled = disabled;
    this.clearable = clearable;
    this.allowNegative = allowNegative;
  }
}
