import { Injectable } from '@angular/core';
import { HttpHandlerService } from '@lib/services/http-handler.service';
import { PartnerBill } from '@lib/models/partners/partner-bill';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  endpoint = 'performance_tiles';
  endpointLeadFieldDashboard = 'lead_field_dashboard_tables';

  constructor(private httpHandler: HttpHandlerService) {}

  list(httpParams: any = {}): Observable<any[]> {
    return this.httpHandler
      .list(this.endpoint, httpParams)
      .pipe(map((response) => response.data));
  }

  leadFieldDashboard(httpParams: any = {}): Observable<any[]> {
    return this.httpHandler
      .list(this.endpointLeadFieldDashboard, httpParams)
      .pipe(map((response) => response.data));
  }

  getList(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  show(id: number): Observable<any> {
    return this.httpHandler.show(this.endpoint, id);
  }

  create(partnerBill: PartnerBill): Observable<PartnerBill> {
    return this.httpHandler.create(this.endpoint, partnerBill);
  }

  update(partnerBill: PartnerBill): Observable<PartnerBill> {
    return this.httpHandler.update(this.endpoint, partnerBill);
  }

  delete(id: number): Observable<any> {
    return this.httpHandler.delete(this.endpoint, id);
  }
}
