import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ChartConfiguration, ChartEvent, ChartType } from 'chart.js';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit, OnChanges {
  title = 'Umsatz';
  private newLabel? = 'New label';
  @Input() labels: any = [];
  @Input() datasets: any = [];
  @Input() isPercentage = false;
  containsData = false;
  lineChartData: ChartConfiguration['data'] = { datasets: [], labels: [] };

  constructor() {
    this.updateChartOptions();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['datasets'] || changes['labels']) {
      this.updateChartData();
    }
    if (changes['isPercentage']) {
      this.updateChartOptions();
    }
  }

  updateChartData(): void {
    if (this.isPercentage) {
      const formattedDatasets = this.datasets.map((dataset: any) => ({
        ...dataset,
        yAxisID: 'y',
      }));

      this.lineChartData = {
        datasets: formattedDatasets,
        labels: this.labels,
      };
    } else {
      this.lineChartData = {
        datasets: this.datasets,
        labels: this.labels,
      };
    }

    this.containsData = !!this.lineChartData?.datasets[0]?.data?.some(
      (value) => value !== 0,
    );
  }

  private updateChartOptions(): void {
    const baseOptions: ChartConfiguration['options'] = {
      elements: {
        line: {
          tension: 0.5,
        },
      },
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
        },
      },
    };

    if (this.isPercentage) {
      this.lineChartOptions = {
        ...baseOptions,
        scales: {
          y: {
            position: 'left',
            title: {
              display: true,
              text: 'Quote (%)',
            },
            min: 0,
            max: 100,
            ticks: {
              callback: (value: any) => `${value}%`,
            },
          },
        },
        plugins: {
          ...baseOptions.plugins,
          tooltip: {
            callbacks: {
              label: (context: any) => {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                return `${label}${context.parsed.y.toFixed(1)}%`;
              },
            },
          },
        },
      };
    } else {
      this.lineChartOptions = {
        ...baseOptions,
        scales: {
          y: {
            position: 'left',
            title: {
              display: true,
              text: 'Umsatz (€)',
            },
            ticks: {
              callback: (value: any) => `${value.toLocaleString()} €`,
            },
          },
        },
        plugins: {
          ...baseOptions.plugins,
          tooltip: {
            callbacks: {
              label: (context: any) => {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                return `${label}${context.parsed.y.toLocaleString()} €`;
              },
            },
          },
        },
      };
    }
  }

  public lineChartOptions: ChartConfiguration['options'] = {};
  public lineChartType: ChartType = 'line';
}
