<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
  <button
    mat-raised-button
    color="primary"
    (click)="addReminder()"
    [disabled]="!lead?.id"
  >
    Erinnerung hinzufügen
  </button>
</div>
