<div *ngIf="dataLoaded" class="comment-container">
  <form [formGroup]="commentForm" (ngSubmit)="onSubmit()" class="comment-form">
    <div class="comment-input-wrapper">
      <textarea
        #commentTextarea
        formControlName="content"
        placeholder="Schreibe einen Kommentar..."
        rows="1"
        class="comment-input"
        (input)="autoGrow($event)"
        (mousedown)="onResizeStart()"
        (mouseup)="onResizeEnd($event)"
      ></textarea>
    </div>

    <button
      type="submit"
      [disabled]="!commentForm.valid"
      class="post-comment-button"
    >
      <mat-icon>send</mat-icon>
    </button>
  </form>

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <a
      *ngIf="
        (relatableType === 'Lead' || relatableType === 'PartnerLead') &&
        !commentToEditId &&
        !commentSummaryToEdit
      "
      class="action-button"
      (click)="toggleFileUpload()"
    >
      <mat-icon>attach_file</mat-icon>
      Anhang
    </a>

    <div
      *ngIf="
        ApplicationHelper.applicationName === Application.Leadmanager &&
        displayVisibleInSalesrunnerButton
      "
      fxLayout="row"
      fxLayoutAlign="end center"
      fxLayoutGap="5px"
    >
      <lib-button
        class="action-button"
        [type]="ButtonType.EYE"
        [iconColor]="
          visibleInSalesrunner === 1
            ? ButtonIconColor.SUCCESS
            : ButtonIconColor.DEFAULT
        "
        (click)="toggleVisibility()"
      >
      </lib-button>

      <lib-button
        class="action-button"
        [type]="ButtonType.RSS"
        [iconColor]="
          visibleInLeadEmail ? ButtonIconColor.SUCCESS : ButtonIconColor.DEFAULT
        "
        (click)="visibleInLeadEmail = !visibleInLeadEmail"
      >
      </lib-button>
    </div>
  </div>

  <div *ngIf="displayFileUpload" class="file-upload">
    <lib-file-upload
      (fileListEmitter)="onFileListChanged($event)"
    ></lib-file-upload>
  </div>
</div>
