export class PriceNumberFormatHelper {
  static prepareForDatabase(value: string): number {
    if (!value) {
      return 0;
    }
    // Ersetze Kommas durch Punkte
    let formattedValue = value.replace(/,/g, '.');

    // Entferne alle Zeichen, die nicht Ziffern, ein Punkt oder ein führendes Minus sind
    formattedValue = formattedValue.replace(/([^\d.-]|(?<!^)-)/g, '');

    // Füge ".00" hinzu, wenn keine Nachkommastellen vorhanden sind
    if (!formattedValue.includes('.')) {
      formattedValue += '.00';
    }

    // Konvertiere den String in eine Zahl und gebe ihn zurück
    return Number(formattedValue);
  }

  static replaceDotWithComma(value: string | number): string {
    if (!value) {
      return '0';
    }
    const numberValue = parseFloat(value.toString());
    const formattedNumber = numberValue.toFixed(2);

    return formattedNumber.replace(/\./g, ',');
  }

  static formatWithThousandsSeparator(
    value: string | number,
    decimalPlaces = 2,
  ): string {
    // Runden auf die angegebene Anzahl von Dezimalstellen
    const roundedValue = Number(value).toFixed(decimalPlaces);

    const parts = roundedValue.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join(',');
  }
  static formatUserInputPrice(value: string | number): number {
    if (!value) {
      return 0;
    }

    if (typeof value === 'string') {
      value = value.replace('€', '');
    }

    if (typeof value === 'string') {
      value = value.replace(/[^\d,.-]/g, ''); // Entfernt alles außer Zahlen, Komma, Punkt und Minus
      value = value.replaceAll('.', '').replace(',', '.');
    }

    return parseFloat(value.toString().replace(',', '.').trim());
  }

  /*Salesrunner: für die NettoBerechnung und Betrag in Partner Bill*/
  static formatInputNetPrice(value: string | number): string {
    if (typeof value === 'number') {
      value = value.toString();
    }
    /*Entfernt alle nicht-numerischen Zeichen außer Punkten und Kommas, es sei denn, sie stehen am Anfang.
      Entfernt Bindestriche, die auf Ziffern folgen.
      Entfernt Punkte oder Kommas, die im Kontext von Tausendertrennzeichen falsch platziert sind
      (d. h., sie sind nicht Teil eines ordnungsgemäßen Dezimal- oder Tausendertrennzeichens).*/
    value = value.replace(/(?!^-)[^\d.,]|(?<=\d)-|(?<=\d)[.,](?=\d{3,})/g, '');
    // replace all commas with dots
    return value.replace(/,/g, '.');
  }

  static formatForDB(value: string): number {
    if (!value) {
      return 0.0;
    }

    // Entferne alle Zeichen, die nicht Ziffern, ein Punkt oder ein führendes Minus sind
    let formattedValue = this.formatInputNetPrice(value);

    // Konvertiere den String in eine Zahl und gebe ihn zurück
    return Number(formattedValue);
  }
}
