import { Component } from '@angular/core';

@Component({
  selector: 'lib-basic-slide-toggle-example',
  template: BasicSlideToggleExample.HTML_CODE,
})
export class BasicSlideToggleExample {
  static HTML_CODE = `
    <div class="example-container">
      <lib-slide-toggle
        [(value)]="toggleValue"
        (valueChange)="onValueChange($event)"
      >
        Status aktiv
      </lib-slide-toggle>

      <div *ngIf="toggleValue" class="mt-4">Option ist aktiviert!</div>
    </div>
  `;

  toggleValue = 0;

  onValueChange(value: number): void {
    console.log('Toggle Status:', value);
  }
}
