import { Injectable } from '@angular/core';
import { HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHandlerService } from '../http-handler.service';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  endpoint: string = 'documents/';

  constructor(private httpHandler: HttpHandlerService) {}

  upload(
    file: File,
    objectId: any,
    key: string,
    visibleInSalesrunner = '0',
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    if (key === 'PartnerLead') {
      formData.append('partner_lead_id', objectId);
    }
    if (key === 'lead') {
      formData.append('lead_id', objectId);
    }
    if (key === 'partner_bill') {
      formData.append('partner_bill_id', objectId);
    }
    formData.append('file', file);
    formData.append('key', key);
    formData.append('visibleInSalesrunner', visibleInSalesrunner);

    return this.httpHandler.create(this.endpoint, formData);
  }

  getFiles(): Observable<any> {
    return this.httpHandler.list(this.endpoint);
  }

  delete(id: number): Observable<any> {
    return this.httpHandler.delete(this.endpoint, id);
  }
}
