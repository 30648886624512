import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { DateHelper } from '../../helpers/date-helper';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  filter: { filter: {}; daterange: { start: string; end: string } };
  filterRoutes = new Map<string, any>();

  private filterSubject = new BehaviorSubject<any>({});
  public filter$ = this.filterSubject.asObservable();

  constructor(private router: Router) {
    this.filter = {
      filter: {},
      daterange: {
        start: DateHelper.formatDate(DateHelper.getStartOfMonth()),
        end: DateHelper.formatDate(DateHelper.getEndOfMonth()),
      },
    };
  }

  private initFilter(): void {
    this.filter = {
      filter: {},
      daterange: {
        start: DateHelper.formatDate(DateHelper.getStartOfMonth()),
        end: DateHelper.formatDate(DateHelper.getEndOfMonth()),
      },
    };
  }

  public getFilter(): any {
    this.initFilter();

    if (this.filterRoutes.has(this.router.url)) {
      this.filter = this.filterRoutes.get(this.router.url);
    }
    this.filterSubject.next(this.filter);
  }

  public setFilter(filter: any): any {
    Object.assign(this.filter.filter, filter);
    this.filterRoutes.set(
      this.router.url,
      JSON.parse(JSON.stringify(this.filter)),
    );
    this.filterSubject.next(this.filter);
  }

  public setDaterange(daterange: any): any {
    Object.assign(this.filter.daterange, daterange);
    this.filterRoutes.set(
      this.router.url,
      JSON.parse(JSON.stringify(this.filter)),
    );
    this.filterSubject.next(this.filter);
  }
}
