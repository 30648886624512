import { Component } from '@angular/core';
import { NewSelectOption } from '../../../../../models/new-select-option';

@Component({
  selector: 'lib-multi-select-example',
  template: MultiSelectExample.HTML_CODE,
})
export class MultiSelectExample {
  static HTML_CODE = `
    <div class="example-container">
      <lib-select
        [options]="options"
        [multiSelect]="true"
        placeholder="Mehrere Optionen auswählen"
        (valueChanged)="onValueChanged($event)"
      >
      </lib-select>

      <div class="mt-4" *ngIf="selectedOptions?.length">
        <p>Ausgewählte Optionen:</p>
        <ul>
          <li *ngFor="let option of selectedOptions">
            {{ option.name }}
          </li>
        </ul>
      </div>
    </div>
  `;

  options: NewSelectOption[] = [
    { name: 'Option 1', value: '1' },
    { name: 'Option 2', value: '2' },
    { name: 'Option 3', value: '3' },
    { name: 'Option 4', value: '4' },
  ];

  selectedOptions: NewSelectOption[] = [];

  onValueChanged(event: any): void {
    console.log('Multi selection changed:', event);
  }
}
