import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { DataTablesParameters } from '../../interfaces/optionen.interface';
import { map } from 'rxjs/operators';
import { HttpHandlerService } from '../http-handler.service';
import { Optionlist } from '../../models/optionlist/optionlist';

@Injectable({
  providedIn: 'root',
})
export class OptionlistsService {
  endpoint: string = 'optionlists/';
  private keywordCache: Map<string, Observable<any>> = new Map<
    string,
    Observable<any>
  >();

  constructor(private httpHandler: HttpHandlerService) {}

  datatable(dataTablesParams: DataTablesParameters): Observable<any> {
    return this.httpHandler.datatable(this.endpoint, dataTablesParams).pipe(
      map((response) => {
        return {
          recordsFiltered: response.recordsFiltered,
          recordsTotal: response.recordsTotal,
          data: response.data.map((item: Optionlist) => new Optionlist(item)),
        };
      }),
    );
  }

  list(httpParams: any = {}): Observable<any> {
    return this.httpHandler
      .list(this.endpoint, httpParams)
      .pipe(map((response) => response.data));
  }

  getList(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  show(id: number): Observable<any> {
    return this.httpHandler.show(this.endpoint, id);
  }

  create(optionList: Optionlist): Observable<any> {
    return this.httpHandler.create(this.endpoint, optionList);
  }

  update(optionList: Optionlist): Observable<any> {
    return this.httpHandler.update(this.endpoint, optionList);
  }

  delete(id: number): Observable<any> {
    return this.httpHandler.delete(this.endpoint, id);
  }

  optionlistByKeyword(keyword: string): Observable<any> {
    if (this.keywordCache.has(keyword)) {
      return this.keywordCache.get(keyword)!;
    }

    const observable = this.httpHandler
      .list(this.endpoint, { filter: { keyword: keyword } })
      .pipe(
        map((response) =>
          response.data && response.data[0] && response.data[0].options
            ? response.data[0].options
            : [],
        ),
        shareReplay(1),
      );

    this.keywordCache.set(keyword, observable);
    return observable;
  }
}
