import { Injectable } from '@angular/core';
import { HttpHandlerService } from '../http-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root',
})
export class PartnerUserService {
  endpoint: string = 'get_partner_users/';
  endpointPartnerUserSalesrunner: string = 'get_partner_users_for_user/';

  constructor(
    private httpHandler: HttpHandlerService,
    protected http: HttpClient,
  ) {}

  list(httpParams: any): Observable<any> {
    return this.httpHandler.list(this.endpoint, httpParams);
  }

  getList(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  show(id: number): Observable<any> {
    return this.httpHandler.show(this.endpoint, id);
  }

  delete(id: number): Observable<any> {
    return this.httpHandler.delete(this.endpoint, id);
  }

  // get partner users with partner_user->group = admin in salesrunner
  // Todo wird das hier noch benötigt? Verstehe nicht wofür das gut ist
  getPartnerUsersForAdmin(): Observable<any> {
    return this.httpHandler.get(this.endpointPartnerUserSalesrunner, {});
  }
}
