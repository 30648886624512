import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FileUpload } from '../../../models/fileUpload';
import { EnvironmentService } from '../../../services/environment.service';
import { ApplicationHelper } from '../../../helpers/application-helper';
import { Application } from '../../../enum-collection';
import { FeedService } from '../../../services/feed.service';
import { Lead } from '../../../models/leads/lead';

@Component({
  selector: 'lib-feed-document',
  templateUrl: './feed-document.component.html',
  styleUrls: ['./feed-document.component.scss'],
})
export class FeedDocumentComponent implements OnDestroy {
  @Input() document: FileUpload | null = null;
  @Input() lead?: Lead;

  private destroy$ = new Subject<void>();

  constructor(
    private environmentService: EnvironmentService,
    private feedService: FeedService,
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFeedSalesrunnerVisibilityHint(): string {
    if (!this.document) return '';

    return this.feedService.getFeedSalesrunnerVisibilityHint(
      this.document.visible_in_salesrunner,
      this.document.partner,
      this.lead,
    );
  }

  getFileIcon(type: string): string {
    if (!this.document || !type) return '<i class="fas fa-file fa-fw"></i>';

    switch (type.toLowerCase()) {
      case 'pdf':
        return '<i class="fas fa-file-pdf fa-fw"></i>';
      case 'doc':
      case 'docx':
        return '<i class="fas fa-file-word fa-fw"></i>';
      case 'xls':
      case 'xlsx':
        return '<i class="fas fa-file-excel fa-fw"></i>';
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return '<i class="fas fa-file-image fa-fw"></i>';
      default:
        return '<i class="fas fa-file fa-fw"></i>';
    }
  }

  formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(1)) + ' ' + sizes[i];
  }

  editFile(leadDocument: FileUpload) {
    window.open(this.environmentService.apiUrl + leadDocument.file_access_link);
  }

  protected readonly ApplicationHelper = ApplicationHelper;
  protected readonly Application = Application;
}
