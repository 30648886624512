import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { getInvoiceAddressFromAddresses } from '@lib/helpers/address-helper';
import { ReminderService } from '@lib/services/reminder.service';
import { Reminder } from '@lib/models/reminder';
import { ModalInputData } from '@lib/models/modal';
import { ModalComponent } from '@lib/components/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '@lib/services/snack-bar/snack-bar.service';
import { TableComponent } from '@lib/components/table/table.component';
import { FilterSetting } from '@lib/models/filter-setting';
import { Subject, takeUntil } from 'rxjs';
import { NewFilterService } from '@lib/services/filter/new-filter.service';
import { SelectList } from '@lib/models/select-list';
import { Application } from '@lib/enum-collection';
import { ApplicationHelper } from '@lib/helpers/application-helper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-next-best-action-list',
  templateUrl: './next-best-action-list.component.html',
  styleUrls: ['./next-best-action-list.component.scss'],
})
export class NextBestActionListComponent implements OnInit, OnDestroy {
  @ViewChild(TableComponent) tableComponent!: TableComponent;

  filter = {
    'partner_user.id': null,
  };
  filterBarSettings: FilterSetting[] = [];
  private destroy$ = new Subject<void>();

  constructor(
    private reminderService: ReminderService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private filterService: NewFilterService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.initFilter();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initFilter() {
    this.filterService.filter$
      .pipe(takeUntil(this.destroy$))
      .subscribe((filter) => {
        this.setFilter(filter);
      });

    this.filterBarSettings = [
      new FilterSetting({
        filter: SelectList.PartnerUser,
        multiSelect: true,
        placeholder: 'User',
        allowNegative: false,
      }),
    ];

    this.filterService.getFilter();
  }

  setFilter(filter: any) {
    const filterData = filter?.filter ?? filter;

    this.filter = {
      ...this.filter,
      'partner_user.id': filterData?.[SelectList.PartnerUser],
    };
  }

  markReminderAsDone(reminder: Reminder): void {
    const inputData: ModalInputData = {
      headline: 'Erinnerung abschließen',
      body: 'Soll die Erinnerung wirklich als erledigt markiert werden?',
      acceptButtonText: 'Ja',
      declineButtonText: 'Nein',
    };

    this.dialog
      .open(ModalComponent, {
        disableClose: true,
        data: inputData,
      })
      .afterClosed()
      .subscribe((modalResponse) => {
        if (!modalResponse || !reminder.id) return;

        reminder.reminder_status_option_id = 2; // Done
        this.reminderService
          .updateReminder(reminder.id, reminder)
          .subscribe(() => {
            this.snackBarService.openSnackBar(
              'Erinnerung abgeschlossen',
              'success',
            );
            this.tableComponent.reloadData();
          });
      });
  }

  formatDate(date: Date | string | undefined): string {
    return this.reminderService.formatDate(date);
  }

  getBackgroundColor(reminder: Reminder) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const reminderDate = new Date(reminder.reminder_date);
    reminderDate.setHours(0, 0, 0, 0);

    if (reminderDate.getTime() === today.getTime()) {
      return 'rgba(253,190,123,0.5)';
    } else if (reminderDate < today) {
      return 'rgba(246,84,84,0.5)';
    } else {
      return 'white';
    }
  }

  navigateToLeads() {
    const route =
      ApplicationHelper.applicationName === Application.Leadmanager
        ? '/leads'
        : '/leads-new';
    this.router.navigate([route]);
  }

  protected readonly getInvoiceAddressFromAddresses =
    getInvoiceAddressFromAddresses;
  protected readonly Application = Application;
}
