<app-card>
  <app-card-head pageTitle="Ihre offenen Chancen">
    <lib-filter-bar [filterSettings]="filterBarSettings"></lib-filter-bar>
  </app-card-head>

  <app-card-body>
    <lib-table
      [filter]="filter"
      [tableId]="'chanceList'"
      [initialPageSize]="50"
      [endpoint]="'partner_lead_chances/'"
      [showSearchbar]="true"
      [selectedId]="selectedId$ | async"
      (displayedDataChanged)="onDisplayedDataChanged($event)"
      (clickedElementChanged)="onChanceSelected($event)"
    >
      <lib-table-column
        [columnName]="'view_data'"
        [isSearchable]="false"
        [isSortable]="false"
        [designPointer]="true"
        [title]="''"
        [customTemplate]="viewTemplate"
      >
        <ng-template #viewTemplate let-lead>
          <button mat-raised-button color="primary">Details anzeigen</button>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [defaultSort]="'desc'"
        [columnName]="'send_at'"
        [pipe]="{ name: 'date', args: ['dd.MM.yyyy HH:mm'] }"
        [suffix]="'Uhr'"
        [isSearchable]="false"
        [designPointer]="true"
        [title]="'Eingangsdatum'"
      >
      </lib-table-column>

      <lib-table-column
        [columnName]="'status'"
        [title]="'Status'"
        [isSortable]="true"
        [designPointer]="true"
        [customTemplate]="statusTemplate"
      >
        <ng-template #statusTemplate let-lead>
          <span *ngIf="lead.status === 8" class="custom-badge badge-red"
            >Abgelehnt</span
          >
          <span *ngIf="lead.status === 7" class="custom-badge badge-yellow"
            >Warten auf Annahme</span
          >
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [columnName]="'lead_number'"
        [isSearchable]="true"
        [isSortable]="false"
        [designPointer]="true"
        [title]="'Leadnummer'"
        [customTemplate]="leadNumberTemplate"
      >
        <ng-template #leadNumberTemplate let-partnerLead>
          <span class="lead-number">#{{ partnerLead.lead_number }}</span>
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [columnName]="'address.postcode'"
        [isSortable]="true"
        [isSearchable]="true"
        [title]="'Projektstandort'"
        [customTemplate]="addressTemplate"
        [designPointer]="true"
      >
        <ng-template #addressTemplate let-lead>
          {{ lead.address.postcode }} {{ lead.address.city }}
        </ng-template>
      </lib-table-column>

      <lib-table-column
        [columnName]="'lead_type.name'"
        [isSearchable]="false"
        [isSortable]="true"
        [designPointer]="true"
        [title]="'Leadart'"
      >
      </lib-table-column>

      <lib-table-column
        [columnName]="'actions'"
        [title]="''"
        [isSearchable]="false"
        [isSortable]="false"
        [designPointer]="true"
        [customTemplate]="actionsTemplate"
      >
        <ng-template #actionsTemplate let-lead>
          <div class="action-buttons">
            <button
              class="btn btn-success"
              (click)="$event.stopPropagation(); acceptLead(lead)"
            >
              Annehmen
            </button>
            <button
              *ngIf="lead.status === 7"
              class="btn btn-danger"
              (click)="$event.stopPropagation(); declineLead(lead)"
            >
              Ablehnen
            </button>
          </div>
        </ng-template>
      </lib-table-column>
    </lib-table>
  </app-card-body>
</app-card>
