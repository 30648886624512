import { Component, Input } from '@angular/core';
import { ApiProperty } from '../../shared/interfaces/demo-config.interface';

@Component({
  selector: 'lib-api-reference',
  templateUrl: './api-reference.component.html',
  styleUrls: ['./api-reference.component.scss'],
})
export class ApiReferenceComponent {
  @Input() properties: ApiProperty[] = [];
}
