import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PartnerUserService } from '../../services/partner-user.service';
import { mustMatchPassword } from '@lib/validations/mustMatchPassword';
import { AuthService } from '@lib/services/auth.service';
import { PartnerUser } from '@lib/models/partners/partner-user';
import { SnackBarService } from '@lib/services/snack-bar/snack-bar.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-partner-users',
  templateUrl: './partner-users.component.html',
  styleUrls: ['./partner-users.component.scss'],
})
export class PartnerUsersComponent implements OnInit, OnDestroy {
  partnerUserForm: FormGroup = new FormGroup({});
  partnerUser: PartnerUser | null = null;
  pageTitle = 'Stammdaten';

  private destroy$ = new Subject<void>();

  constructor(
    private snackbarService: SnackBarService,
    private formBuilder: FormBuilder,
    private partnerUserService: PartnerUserService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.authService.partnerUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe((partnerUser: PartnerUser | null) => {
        this.partnerUser = partnerUser;
      });
  }

  private initForm() {
    this.partnerUserForm = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(8)]],
        passwordConfirmation: [],
      },
      {
        validator: mustMatchPassword('password', 'passwordConfirmation'),
      },
    );
  }

  prepareDataToSave() {
    if (this.partnerUser) {
      this.partnerUser.password = this.partnerUserForm.get('password')?.value;
      this.partnerUser.password_confirmation = this.partnerUserForm.get(
        'passwordConfirmation',
      )?.value;
    }
  }

  onSubmit() {
    if (this.partnerUserForm.valid) {
      this.prepareDataToSave();
      if (this.partnerUser) {
        this.partnerUserService
          .changePassword(this.partnerUser)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (data: PartnerUser) => {
              this.partnerUserForm.reset();
              this.snackbarService.openSnackBar(
                'Neues Passwort wurde erfolgreich gespeichert',
                'success',
              );
            },
            (error: any) => {
              this.snackbarService.openSnackBar(
                'Fehler beim Speichern des neuen Passworts',
                'error',
              );
            },
          );
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
