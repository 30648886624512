import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TargetingBudgetService } from '../../services/targeting-budget.service';
import { TargetingBudget } from '../../models/targeting/targeting-budget';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PriceNumberFormatHelper } from '../../helpers/price-number-format-helper';
import { ValidationPatterns } from '../../validations/validation-pattern';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'lib-partner-budget',
  templateUrl: './partner-budget.component.html',
  styleUrls: ['./partner-budget.component.scss'],
})
export class PartnerBudgetComponent implements OnInit {
  @Input() targetingBudgets: TargetingBudget[] | null = [];
  @Input() disableForm: boolean = false;
  @Input() displayBorder: boolean = true;
  @Input() displaySaveButton: boolean = false;
  @Input() targetingId: number = 0;
  @Output() onSave = new EventEmitter<boolean>();
  targetingBudget: TargetingBudget = new TargetingBudget();
  originalBudget: TargetingBudget = new TargetingBudget();
  partnerBudgetForm: FormGroup = new FormGroup({});

  constructor(
    private targetingBudgetService: TargetingBudgetService,
    private snackbarService: SnackBarService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    if (!this.targetingId) {
      throw new Error('targetingId is required');
    } else {
      this.initForm();

      this.targetingBudgetService
        .getListByTargetingId(this.targetingId)
        .subscribe((targetingBudgets) => {
          this.targetingBudgets = targetingBudgets;
          this.targetingBudget = this.getBudgetElement();
          this.originalBudget = { ...this.targetingBudget };
          this.patchFormData();
        });
    }
  }

  private initForm() {
    this.partnerBudgetForm = this.formBuilder.group({
      budgetStatus: [
        { value: false, disabled: this.disableForm },
        [Validators.required],
      ],
      budgetAmount: [
        {
          value: 0,
          disabled: this.disableForm,
        },
        [
          Validators.required,
          Validators.pattern(ValidationPatterns.pricePattern),
        ],
      ],
      budgetType: [
        { value: '', disabled: this.disableForm },
        [Validators.required],
      ],
    });
  }

  patchFormData() {
    const budgetAmount = this.getBudgetAmount();
    const budgetType = this.getBudgetType();

    if (budgetAmount && budgetType) {
      this.partnerBudgetForm.get('budgetStatus')?.setValue(true);
      this.partnerBudgetForm.get('budgetType')?.setValue(this.getBudgetType());
      this.partnerBudgetForm
        .get('budgetAmount')
        ?.setValue(
          PriceNumberFormatHelper.replaceDotWithComma(this.getBudgetAmount()),
        );
    }
  }

  private getBudgetElement(): TargetingBudget {
    return (
      this.targetingBudgets?.filter((budget) => budget.status === 1).pop() ??
      new TargetingBudget()
    );
  }

  private getBudgetAmount(): number {
    let budgetAmount = 0;

    if (this.targetingBudget) {
      for (const type of ['daily', 'weekly', 'monthly']) {
        const budgetTypeValue = this.targetingBudget[type + '_budget'];
        if (budgetTypeValue && budgetTypeValue > 0) {
          budgetAmount = budgetTypeValue;
        }
      }
    }
    return budgetAmount;
  }

  private getBudgetType(): string {
    let budgetType = '';

    if (this.targetingBudget) {
      for (const type of ['daily', 'weekly', 'monthly']) {
        const budgetTypeValue = this.targetingBudget[type + '_budget'];
        if (budgetTypeValue && budgetTypeValue > 0) {
          budgetType = type;
        }
      }
    }
    return budgetType;
  }

  onBudgetStatusChange() {
    if (this.partnerBudgetForm.get('budgetStatus')?.value) {
      const targetingBudget = new TargetingBudget();
      targetingBudget.targeting_id = this.targetingId;
      targetingBudget.status = 1;
      this.targetingBudget = targetingBudget;
      this.partnerBudgetForm.get('budgetType')?.setValue('monthly');
    } else {
      this.targetingBudget.status = 0;
      this.onSubmit(false, true);
    }
  }

  private saveBudgetData() {
    const budgetAmount = PriceNumberFormatHelper.formatUserInputPrice(
      this.partnerBudgetForm.get('budgetAmount')?.value,
    );
    const budgetType = this.partnerBudgetForm.get('budgetType')?.value;
    if (this.targetingBudget) {
      ['daily', 'weekly', 'monthly'].forEach((type) => {
        if (budgetType === type) {
          this.targetingBudget[type + '_budget'] = budgetAmount;
        } else {
          this.targetingBudget[type + '_budget'] = 0;
        }
      });
    }
  }

  private updateStatusBasedOnBudgets(
    budgetInfo: TargetingBudget,
  ): TargetingBudget {
    // Prüfe, ob alle Budgets 0 sind
    if (
      budgetInfo.daily_budget === 0 &&
      budgetInfo.weekly_budget === 0 &&
      budgetInfo.monthly_budget === 0
    ) {
      // Setze den Status auf 0, falls alle Budgets 0 sind
      budgetInfo.status = 0;
    }
    return budgetInfo;
  }

  onSubmit(saveInParentComponent: boolean = false, statusChangeSave = false) {
    if (
      !this.disableForm &&
      (this.partnerBudgetForm.valid || this.targetingBudget.status === 0)
    ) {
      this.saveBudgetData();

      if (this.hasChanges()) {
        const saveOrUpdateObservable = this.targetingBudget.id
          ? this.targetingBudgetService.update(this.targetingBudget)
          : this.targetingBudgetService.create(this.targetingBudget);

        saveOrUpdateObservable.subscribe((response) => {
          this.saveSuccess(response, saveInParentComponent, statusChangeSave);
        });
      }
    }
  }

  private hasChanges(): boolean {
    return (
      JSON.stringify(this.originalBudget) !==
      JSON.stringify(this.targetingBudget)
    );
  }

  private saveSuccess(
    response: TargetingBudget,
    saveInParentComponent: boolean,
    statusChangeSave: boolean,
  ) {
    if (response) {
      this.targetingBudget = response;
      this.originalBudget = { ...this.targetingBudget };

      // aktualisiere das Budget in der Liste
      const index = this.targetingBudgets?.findIndex(
        (budget) => budget.id === this.targetingBudget.id,
      );
      if (index && index !== -1 && this.targetingBudgets) {
        this.targetingBudgets[index] = this.targetingBudget;
      }

      // Parent soll nur benachrichtigt werden, wenn der Aufruf nicht aus der Parent gestartet wurde und wenn nicht nur das Budget deaktiviert wurde
      if (!saveInParentComponent && !statusChangeSave) {
        this.onSave.emit(true);
      }

      this.snackbarService.openSnackBar(
        'Die Daten wurden erfolgreich gespeichert',
        'success',
      );
    }
  }

  protected readonly environment = environment;
}
