export class AccountingAccount {
  id: number = 0;
  account_number: string = '';
  account_name: string = '';
  account_type: string = '';
  created_at: Date = new Date();
  updated_at: Date = new Date();
  deleted_at?: Date;
  opening_balances?: number; // Angenommen, es handelt sich um einen numerischen Wert

  constructor(init?: Partial<AccountingAccount>) {
    Object.assign(this, init);
  }
}
