<div class="card-header">
  <div class="d-flex align-items-center">
    <div class="me-auto p-2 bd-highlight">
      <h5 class="me-auto pe-4">
        {{ this.pageTitle }}
      </h5>
      <span *ngIf="this.pageSubtitle" class="text-muted">{{
        this.pageSubtitle
      }}</span>
    </div>

    <ng-content></ng-content>
  </div>
</div>
